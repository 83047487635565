import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import PathwayFlow from "./pathway_flow";
import { Copy, User } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserData } from "utils/funcs/browser/getUserData";
import { Link } from "react-router-dom";
import * as Tooltip from "@radix-ui/react-tooltip";

const PathwayModal = ({ show, onClose, pathway }) => {
  const [prevPathwayID, setPrevPathwayID] = React.useState(null);
  const tagColors = {
    "Customer Service": "bg-indigo-100 text-indigo-800 border-indigo-300",
    AI: "bg-blue-100 text-blue-800 border-blue-300",
    Support: "bg-purple-100 text-purple-800 border-purple-300",
    Sales: "bg-yellow-100 text-yellow-800 border-yellow-300",
    Booking: "bg-pink-100 text-pink-800 border-pink-300",
    FAQ: "bg-indigo-200 text-indigo-900 border-indigo-400",
    "Knowledge Base": "bg-gray-100 text-gray-800 border-gray-300",
    "Lead Qualification": "bg-cyan-100 text-cyan-800 border-cyan-300",
  };
  const user = getUserData();

  const handleClone = async () => {
    if (!user) {
      toast.warn("Please login to clone the pathway.", {
        autoClose: 3000,
      });
      return;
    }

    const res = await fetch(`https://us.api.bland.ai/v1/convo_pathway/clone`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: user?.api_key,
      },
      body: JSON.stringify({ pathway_id: pathway.pathway_id }),
    });

    if (res.ok) {
      const data = await res.json();
      toast.success("Pathway cloned successfully", {
        autoClose: 3000,
      });

      const new_pathway_id = data.pathway_id;
      setPrevPathwayID(new_pathway_id);
      setIsCloned(true);
    }
  };

  const [isCloned, setIsCloned] = React.useState(false);
  const navigate = useNavigate();

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-[80%] p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <div className="flex justify-between items-center">
                <Dialog.Title
                  as="h3"
                  className="text-2xl font-medium leading-6 text-gray-900"
                >
                  {pathway.title}
                </Dialog.Title>
                {/* <button
                  type="button"
                  className="text-gray-400"
                  onClick={onStar}
                >
                  <Star size={20} className={pathway.stars > 0 ? 'text-yellow-400' : 'text-gray-400'} />
                </button> */}
              </div>
              <div className="mt-2 flex gap-2">
                <User size={16} className="text-gray-400" />
                <p className="text-[12px] text-gray-600">{pathway.author}</p>
              </div>
              <div className="mt-4">
                <div className="flex justify-between items-center">
                  <div>
                    <div className="flex flex-wrap gap-2 mt-2">
                      {pathway.tags.map((tag, index) => (
                        <span
                          key={index}
                          className={`text-[11px] px-2 py-1 rounded-full border ${tagColors[tag] || "bg-gray-200 text-gray-700 border-gray-300"}`}
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                  {user ? (
                    isCloned ? (
                      <button
                        onClick={() => {
                          navigate(
                            `/dashboard?page=convo-pathways&id=${prevPathwayID}`,
                          );
                        }}
                        className="inline-flex justify-center px-4 py-2.5 text-[12px] font-medium text-green-500 bg-green-100 border border-gray-200 rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500 gap-2"
                      >
                        View Cloned Pathway
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="inline-flex justify-center px-4 py-2.5 text-[12px] font-medium text-indigo-900 bg-indigo-100 border border-transparent rounded-md hover:bg-indigo-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500 gap-2"
                        onClick={handleClone}
                      >
                        <Copy size={16} />
                        Clone Pathway
                      </button>
                    )
                  ) : (
                    <a
                      href="/signup"
                      className="inline-flex justify-center px-4 py-2.5 text-[12px] font-medium text-gray-700 bg-gray-100 border border-gray-300 rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500 gap-2"
                    >
                      <Copy size={16} />
                      Sign Up to Clone
                    </a>
                  )}
                </div>
              </div>
              <div className="mt-4">
                <p className="text-[12px] text-gray-500">
                  {pathway.description}
                </p>
              </div>

              <hr className="my-4 border-gray-200" />

              <div className="mt-4 h-[60vh] mx-auto">
                <PathwayFlow nodes={pathway.nodes} edges={pathway.edges} />
              </div>

              <div className="mt-4 flex justify-end">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-[12px] font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                  onClick={onClose}
                >
                  Close
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PathwayModal;
