import Switch from "components/core/Switch";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LightTooltip from "./ToolTipDesc";

export default function BlockInterruptions({ fieldName }) {
  const longText = (
    <span>
      Enabling this feature will <strong>not</strong> allow the user to
      interrupt the agent, while the agent is speaking.
      <br />
      This leads to a more controlled, but less natural conversation, as the
      user will have to wait for the agent to finish speaking before they can
      speak.
    </span>
  );
  return (
    <Tooltip>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Switch
          sx={{ m: 5 }}
          fieldName={fieldName}
          label="Block Interruptions"
        />
        <LightTooltip title={longText} placement="right-start" arrow>
          <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
        </LightTooltip>
      </div>
    </Tooltip>
  );
}
