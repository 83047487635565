// SimpleEditModal.js
import React, { useContext, useState } from "react";
import {
  X,
  RefreshCw,
  Check,
  ChevronRight,
  ArrowLeft,
  Plus,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { getUserData } from "utils/funcs/browser/getUserData";
import { toast } from "react-toastify";
import FlowContext from "../../contextFlow";
import StepOne from "./StepOne";
import HallucinationContent from "./HallucinationContent";
import WrongNodeContent from "./WrongNodeContent";
import {
  addToFinetuningExample,
  getDialogTitle,
  getNodeOptions,
  handleGenerateAlternatives,
  handleSubmit,
} from "./modalHelpers";
import { Loading } from "components/core/Loading";

const FineTuneModal = ({ isOpen, onClose, message, graphID, callID }) => {
  const [step, setStep] = useState(1);
  const [issueType, setIssueType] = useState(null);
  const [alternativeResponses, setAlternativeResponses] = useState([]);
  const [selectedResponseIndex, setSelectedResponseIndex] = useState(null);
  const [customResponse, setCustomResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isConditionCorrect, setIsConditionCorrect] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [hallucinationExampleAdded, setHallucinationExampleAdded] =
    useState(false);
  const [wrongNodeExampleAdded, setWrongNodeExampleAdded] = useState(false);
  const { elements, triggerUpdate } = useContext(FlowContext);
  const user = getUserData();

  const nodeOptions = getNodeOptions(elements, message);

  const handleIssueTypeSelect = (type) => {
    setIssueType(type);
    if (type === "hallucination") {
      handleGenerateAlternatives(
        setIsLoading,
        setAlternativeResponses,
        setStep,
        user,
        message,
        toast,
      );
    } else if (type === "wrong_node") {
      setStep(2);
    }
  };

  const handleAddToFinetuningExample = () => {
    if (issueType === "hallucination" && hallucinationExampleAdded) return;
    if (issueType === "wrong_node" && wrongNodeExampleAdded) return;
    const updatedNodes = addToFinetuningExample(
      issueType,
      elements.nodes,
      message,
      selectedResponseIndex,
      alternativeResponses,
      customResponse,
      isConditionCorrect,
      selectedNode,
      nodeOptions,
    );
    triggerUpdate({ nodes: updatedNodes }, false);
    toast.success("Example added to node's fine-tuning examples.", {
      position: "bottom-right",
    });
    if (issueType === "hallucination") setHallucinationExampleAdded(true);
    else if (issueType === "wrong_node") setWrongNodeExampleAdded(true);
  };

  const isSubmitDisabled = () => {
    if (isLoading) return true;
    if (issueType === "hallucination") {
      return selectedResponseIndex === null && customResponse.trim() === "";
    } else if (issueType === "wrong_node") {
      return (
        isConditionCorrect === null ||
        (isConditionCorrect && selectedNode === null)
      );
    }
    return true;
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <StepOne
            message={message}
            handleIssueTypeSelect={handleIssueTypeSelect}
          />
        );
      case 2:
        return issueType === "hallucination" ? (
          <HallucinationContent
            message={message}
            alternativeResponses={alternativeResponses}
            selectedResponseIndex={selectedResponseIndex}
            setSelectedResponseIndex={setSelectedResponseIndex}
            customResponse={customResponse}
            setCustomResponse={setCustomResponse}
            handleAddToFinetuningExample={handleAddToFinetuningExample}
            hallucinationExampleAdded={hallucinationExampleAdded}
          />
        ) : (
          <WrongNodeContent
            message={message}
            isConditionCorrect={isConditionCorrect}
            setIsConditionCorrect={setIsConditionCorrect}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            nodeOptions={nodeOptions}
            handleAddToFinetuningExample={handleAddToFinetuningExample}
            wrongNodeExampleAdded={wrongNodeExampleAdded}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose} className="!z-[100]">
      <DialogContent className="flex flex-col bg-white rounded-xl shadow-2xl max-w-6xl flex-wrap">
        <DialogHeader>
          <DialogTitle className="text-2xl font-semibold text-gray-800">
            {getDialogTitle(step, issueType)}
          </DialogTitle>
        </DialogHeader>
        <div className="py-6 bg-white hover:bg-white">
          {isLoading ? (
            <div className="flex justify-center items-center h-40">
              <Loading loading={isLoading} />
            </div>
          ) : (
            <div className="w-full overflow-x-auto overflow-y-auto max-w-full">
              {renderStepContent()}
            </div>
          )}
        </div>
        <DialogFooter className="flex justify-between">
          {step > 1 && (
            <Button
              onClick={() => setStep(step - 1)}
              className="bg-gray-200 text-gray-800 hover:bg-gray-300"
            >
              <ArrowLeft className="mr-2 h-4 w-4" />
              Back
            </Button>
          )}
          {step === 2 && (
            <Button
              type="submit"
              onClick={() =>
                handleSubmit(
                  issueType,
                  callID,
                  graphID,
                  message,
                  selectedResponseIndex,
                  alternativeResponses,
                  customResponse,
                  user,
                  isConditionCorrect,
                  selectedNode,
                  setIsLoading,
                  onClose,
                  toast,
                )
              }
              disabled={isSubmitDisabled()}
              className={`font-semibold py-2 px-4 rounded-lg shadow-md transition-all duration-200 ease-in-out ${
                isSubmitDisabled()
                  ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                  : "bg-indigo-500 hover:bg-indigo-600 text-white"
              }`}
            >
              {isLoading ? (
                <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                "Submit"
              )}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default FineTuneModal;
