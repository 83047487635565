import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import CodeIcon from "../../../../assets/icons/CodeIcon.svg";
import CloseIcon from "../../../../assets/icons/close-icon-black.svg";

export default function PayloadDropdown({
  keypairs,
  methods,
  fieldname,
  label,
  placeholder = "Enter Value",
  prompt = false,
}) {
  const watch = methods.watch();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleInputChange = (e) => {
    methods.setValue(`${fieldname}.using_key`, false);
    methods.setValue(`${fieldname}.value`, e.target.value);
    setIsOpen(false); // Open dropdown when user types
  };

  const handleOptionClick = (optionValue) => {
    methods.setValue(`${fieldname}.value`, optionValue);
    methods.setValue(`${fieldname}.using_key`, true);
    setIsOpen(false); // Close dropdown on option select
  };

  const handleOptionClear = () => {
    methods.setValue(`${fieldname}.value`, "");
    methods.setValue(`${fieldname}.using_key`, false);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      {watch[fieldname].using_key ? (
        <InputContainer>
          <Label style={{ marginBottom: 10 }}>{label}</Label>
          <StyledOption
            onClick={() => setIsOpen(!isOpen)}
            style={{ border: "1px solid #eeeeee" }}
          >
            <IconContainer>
              <img src={CodeIcon} style={{ height: 16, width: 16 }} alt="" />
            </IconContainer>

            <div>
              <p style={{ fontWeight: "300", fontSize: 10 }}>Key</p>
              <p style={{ fontWeight: "550", fontSize: 13 }}>
                {watch[fieldname].value}
              </p>
            </div>

            <div onClick={handleOptionClear} style={{ marginLeft: "auto" }}>
              <img src={CloseIcon} alt="close" />
            </div>
          </StyledOption>
        </InputContainer>
      ) : (
        <>
          {prompt ? (
            <InputContainer>
              <Label style={{ marginBottom: 10 }}>{label}</Label>
              <StyledTextarea
                style={{ marginBottom: -5 }}
                placeholder={"Enter your prompt"}
                value={methods.getValues("task.value")}
                onChange={handleInputChange}
                onFocus={() => setIsOpen(true)} // Open dropdown on input focus
                autoresize={true}
              />
            </InputContainer>
          ) : (
            <InputContainer>
              <Label style={{ marginBottom: 10 }}>{label}</Label>
              <StyledInput
                type="text"
                placeholder={placeholder}
                value={watch[fieldname].value}
                onChange={handleInputChange}
                onFocus={() => setIsOpen(true)} // Open dropdown on input focus
              />
            </InputContainer>
          )}
        </>
      )}

      {isOpen && keypairs?.length > 0 && (
        <DropdownContent>
          {keypairs.map((doc, ind) => (
            <StyledOption
              key={ind}
              style={{
                borderBottomLeftRadius: ind === keypairs.length - 1 ? 5 : 0,
                borderBottomRightRadius: ind === keypairs.length - 1 ? 5 : 0,
                borderBottom:
                  ind === keypairs.length - 1 ? "1px solid #eeeeee" : "none",
              }}
              onMouseDown={() => handleOptionClick(Object.keys(doc)[0])} // Set input value on option click
            >
              <IconContainer>
                <img src={CodeIcon} style={{ height: 16, width: 16 }} alt="" />
              </IconContainer>

              <div>
                <p style={{ fontWeight: "550", fontSize: 14 }}>
                  {Object.keys(doc)[0]}
                </p>
                <p style={{}}>
                  {typeof Object.values(doc)[0] === "boolean"
                    ? Object.values(doc)[0]
                      ? "true"
                      : "false"
                    : typeof Object.values(doc)[0] === "number"
                      ? Object.values(doc)[0]
                      : Object.values(doc)[0]?.substring(0, 100)}{" "}
                  {Object.values(doc)[0]?.length >= 100 && "..."}
                </p>
              </div>
            </StyledOption>
          ))}
        </DropdownContent>
      )}
    </DropdownContainer>
  );
}

const DropdownContainer = styled.div`
  width: 100%;
`;

const InputContainer = styled.div`
  padding: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 14px;
`;

const DropdownContent = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  margin-top: 5px; /* Add a little space between the input and dropdown */
  margin-bottom: 10px;
`;

const StyledOption = styled.div`
  border-top: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 25px;
  border-top-left-radius: ${({ ind }) => (ind === 0 ? 5 : 0)}px;
  border-top-right-radius: ${({ ind }) => (ind === 0 ? 5 : 0)}px;
  &:hover {
    background-color: #f0f0f0; /* Change this to your desired hover background color */
  }
`;

const IconContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px;
`;

const Label = styled.p`
  margin: 0px;
  padding: 0px;
  color: #000;
  font-size: 13px;
  font-weight: 500;
`;

const StyledTextarea = styled.textarea`
  width: 100%;
  height: ${({ autoresize }) => (autoresize ? "auto" : "100%")};
  min-height: ${({ autoresize }) => (autoresize ? "200px" : "auto")};
  max-height: ${({ autoresize }) => (autoresize ? "500px" : "auto")};
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 12px 15px;
  background-color: #fbfbfa;
  color: black;
  font-weight: 300;
  resize: ${({ autoresize }) => (autoresize ? "none" : "vertical")};
  &::-webkit-resizer {
    background: transparent;
  }
  &:hover {
    cursor: text;
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #a8a8a1;
  }

  ${({ autoresize }) =>
    !autoresize &&
    `
      overflow-y: scroll;
   `}
`;
