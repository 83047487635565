import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Dialog, Switch } from "@headlessui/react";
import FlowContext from "../contextFlow";
import { getUserData } from "utils/funcs/browser/getUserData";
import PathwayResponse from "./WebhookResponsePathway";
import GlobalNodeOptions from "./globalSelect";
import ModelOptions from "./modelOptions";
import { toast } from "react-toastify";

const CustomToolModal = ({ id, data, setIsOpen }) => {
  const user = useMemo(() => getUserData(), []);
  const [tools, setTools] = useState([]);
  const [selectedTool, setSelectedTool] = useState(data?.tool || {});
  const [responseVars, setResponseVars] = useState([]);
  const [name, setName] = useState(data.name || "");

  const [responsePathways, setResponsePathways] = useState(
    data.responsePathways || [
      ["Default/Webhook Completion", "", "", { id: "", name: "" }],
    ],
  );

  const [enableGlobalAutoReturn, setEnableGlobalAutoReturn] = useState(
    data.enableGlobalAutoReturn === false ? false : true,
  );

  const [prevNodePathwayLabel, setPrevNodePathwayLabel] = useState(
    data.prevNodePathwayLabel || "",
  );

  const [prevNodePathwayDescription, setPrevNodePathwayDescription] = useState(
    data.prevNodePathwayDescription || "",
  );

  const [modelOptions, setModelOptions] = useState(
    data.modelOptions || {
      modelType: "smart",
      temperature: 0.2,
    },
  );

  const [isGlobal, setIsGlobal] = useState(data.isGlobal);
  const [globalPathwayLabel, setGlobalPathwayLabel] = useState(
    data.globalLabel || "",
  );

  const [globalPathwayDescription, setGlobalPathwayDescription] = useState(
    data.globalDescription || "",
  );

  const generateUniqueEdgeId = (source, target, edgeData) => {
    const baseId = `reactflow__edge-${source}-${target}`;
    const dataHash = JSON.stringify(edgeData);
    return `${baseId}-${dataHash}`;
  };

  const [isForwardingEnabled, setIsForwardingEnabled] = useState(false);
  const [forwardingNode, setForwardingNode] = useState("");

  const getTools = async () => {
    console.log("fetching tools");
    const response = await fetch(`https://us.api.bland.ai/v1/tools`, {
      method: "GET",
      headers: {
        authorization: user.api_key,
      },
    });
    console.log("response", response);
    if (response.ok) {
      const data = await response.json();
      console.log("tools data", data);
      setTools(data.tools);
    } else {
      console.error("Failed to fetch tools data");
    }
  };

  useEffect(() => {
    getTools();
  }, [id]);

  const { exportFlow, triggerUpdate, elements } = useContext(FlowContext);

  return (
    <Dialog.Description className="space-y-4">
      <>
        <div className="space-y-4">
          <div>Name:</div>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border border-gray-300 p-2 rounded-md w-full"
          />
          <label className="block text-[12px] font-medium text-gray-700 mt-4">
            Custom Tool{" "}
            <span className="text-gray-400 text-[11px]">
              (Invoke a Custom Tool at this node)
            </span>
          </label>
          <select
            value={JSON.stringify(selectedTool)}
            onChange={(e) => {
              console.log("selected tool", e.target.value);
              if (!e.target.value) {
                setSelectedTool({});
              }
              setSelectedTool(JSON.parse(e.target.value));
              let responses = JSON.parse(e.target.value).tool?.response;
              // const vars = responses ? {"name" : Object.keys(responses)} : [];

              // wrap each response key in an array name, of responses which is an object
              const mappedResponses = responses
                ? Object.keys(responses).map((el) => ({ name: el }))
                : [];
              setResponseVars(mappedResponses);
            }}
            className="block w-full pl-3 pr-10 py-4 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md text-[12px]"
          >
            <option value="{}">Select Custom Tool</option>
            {tools.map((el) => (
              <option
                key={el?.tool_id}
                value={JSON.stringify(el)}
                className="text-[14px]"
              >
                {el?.tool_id} - {el.tool.url}
              </option>
            ))}
          </select>

          {selectedTool?.tool && (
            <div className="mt-4">
              <PathwayResponse
                name="tool"
                responseVariables={responseVars}
                responsePathways={responsePathways}
                setResponsePathways={setResponsePathways}
              />
            </div>
          )}

          <ModelOptions
            modelOptions={modelOptions}
            setModelOptions={setModelOptions}
          />

          <GlobalNodeOptions
            isGlobal={isGlobal}
            setIsGlobal={setIsGlobal}
            globalPathwayLabel={globalPathwayLabel}
            setGlobalPathwayLabel={setGlobalPathwayLabel}
            globalPathwayDescription={globalPathwayDescription}
            setGlobalPathwayDescription={setGlobalPathwayDescription}
            isForwardingEnabled={isForwardingEnabled}
            setIsForwardingEnabled={setIsForwardingEnabled}
            forwardingNode={forwardingNode}
            setForwardingNode={setForwardingNode}
            enableGlobalAutoReturn={enableGlobalAutoReturn}
            setEnableGlobalAutoReturn={setEnableGlobalAutoReturn}
            prevNodePathwayLabel={prevNodePathwayLabel}
            setPrevNodePathwayLabel={setPrevNodePathwayLabel}
            prevNodePathwayDescription={prevNodePathwayDescription}
            setPrevNodePathwayDescription={setPrevNodePathwayDescription}
          />
        </div>

        <button
          onClick={() => {
            const node = elements.nodes.find((el) => el.id === id);
            console.log("node", node);

            const data = {
              name: name,
              tool: selectedTool,
              modelOptions: modelOptions,
              isGlobal: isGlobal,
              globalLabel: globalPathwayLabel,
              globalDescription: globalPathwayDescription,
              isForwardingEnabled: isForwardingEnabled,
              forwardingNode: forwardingNode,
              enableGlobalAutoReturn: enableGlobalAutoReturn,
              prevNodePathwayLabel: prevNodePathwayLabel,
              prevNodePathwayDescription: prevNodePathwayDescription,
            };

            if (!isGlobal) {
              delete data.globalLabel;
              delete data.globalDescription;
              delete data.isForwardingEnabled;
              delete data.forwardingNode;
              delete data.enableGlobalAutoReturn;
              delete data.prevNodePathwayLabel;
              delete data.prevNodePathwayDescription;
            }

            if (responsePathways.length > 0 && responsePathways[0][0] !== "") {
              data.responsePathways = responsePathways;
            } else {
              delete data.responsePathways;
            }

            if (modelOptions) {
              if (modelOptions.temperature) {
                modelOptions.temperature = parseFloat(
                  modelOptions.temperature.toFixed(1),
                );
              }
              if (modelOptions.interruptionThreshold) {
                modelOptions.interruptionThreshold = parseInt(
                  modelOptions.interruptionThreshold,
                );
              }
              data.modelOptions = modelOptions;
            } else {
              delete data.modelOptions;
            }

            let isWarnToast = false;
            let newEdges = [];

            const res_nodes = elements.nodes.map((el) => {
              if (el.id === id) {
                const node = structuredClone(el);
                for (const responsePathway of responsePathways) {
                  console.log("Response pathway", responsePathway);
                  if (responsePathway[0] === "Default/Webhook Completion") {
                    if (
                      responsePathway[3].id === "" ||
                      responsePathway[3].name === ""
                    ) {
                      toast.warn(
                        "Please select the default node to path to after webhook completion.",
                      );
                      isWarnToast = true;
                      return node;
                    }
                    const edge = {
                      id: `reactflow__edge-${id}-${responsePathway[3].id}`,
                      animated: true,
                      sourceHandle: null,
                      targetHandle: null,
                      type: "custom",
                      source: id,
                      target: responsePathway[3].id,
                      data: {
                        label: `${responsePathway[0]}`,
                      },
                    };
                    newEdges.push(edge);
                    continue;
                  }
                  if (
                    !responsePathway[1] ||
                    !responsePathway[2] ||
                    !responsePathway[3].id ||
                    !responsePathway[3].name
                  ) {
                    toast.warn(
                      "Fields in 'Pathway after Webhook Response' not filled out. Please fill in all fields.",
                    );
                    isWarnToast = true;
                    break;
                  }

                  let edge = {
                    animated: true,
                    sourceHandle: null,
                    targetHandle: null,
                    type: "custom",
                    source: id,
                    target: responsePathway[3].id,
                    data: {
                      label: `${responsePathway[0]} ${responsePathway[1]} ${responsePathway[2]}`,
                    },
                  };
                  edge.id = generateUniqueEdgeId(id, responsePathway[3].id, edge.data)
                  
                  newEdges.push(edge);
                }

                const currentEdges = elements.edges;

                const filteredEdges = currentEdges.filter(
                  (edge) => edge.source !== id,
                );

                // newEdges = [...filteredEdges, ...newEdges];

                const edgeMap = new Map();

                // Add updatedEdges to the map
                filteredEdges.forEach(edge => edgeMap.set(edge.id, edge));

                // Add or update newEdges in the map
                newEdges.forEach(edge => {
                if (!edgeMap.has(edge.id)) {
                    edgeMap.set(edge.id, edge);
                }
                // If the edge ID already exists, the new edge will replace the old one
                });

                // Convert the map back to an array
                newEdges = Array.from(edgeMap.values());

                return {
                  ...el,
                  data: data,
                  type: "Custom Tool",
                };
              } else {
                return el;
              }
            });

            setIsOpen(false);
            if (isWarnToast) {
              return;
            }

            triggerUpdate(
              {
                nodes: res_nodes,
                edges: newEdges,
              },
              false,
            );
          }}
          className="bg-indigo-500 text-white py-2 px-4 rounded-md mt-4"
        >
          Save
        </button>
      </>
    </Dialog.Description>
  );
};

export default CustomToolModal;
