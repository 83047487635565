import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import CodeIcon from "../../../../assets/icons/CodeIcon.svg";
import CloseIcon from "../../../../assets/icons/close-icon-black.svg";
import TwilioIcon from "assets/images/TwilioCircle.png";

export default function HookInputDropdown({
  labelValueList,
  value,
  onChange,
  label,
  placeholder = null,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const [isUsingDDItem, setUsingDDItem] = useState(false);

  const handleOptionClear = () => {
    setIsOpen(false);
    setUsingDDItem(false);
  };

  const handleOptionClick = (optionValue) => {
    //methods.setValue(`${fieldname}.value`, optionValue);
    //methods.setValue(`${fieldname}.using_key`, true);
    setUsingDDItem(true);
    onChange(optionValue);
    setIsOpen(false); // Close dropdown on option select
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef}>
      {isUsingDDItem ? (
        <InputContainer>
          <Label style={{ marginBottom: 10 }}>{label}</Label>
          <StyledOption
            onClick={() => setIsOpen(!isOpen)}
            style={{ border: "1px solid #eeeeee" }}
          >
            <IconContainer>
              <img src={TwilioIcon} style={{ height: 16, width: 16 }} alt="" />
            </IconContainer>

            <div>
              <p style={{ fontWeight: "300", fontSize: 10 }}>Key</p>
              <p style={{ fontWeight: "550", fontSize: 13 }}>{value}</p>
            </div>

            <div onClick={handleOptionClear} style={{ marginLeft: "auto" }}>
              <img src={CloseIcon} alt="close" />
            </div>
          </StyledOption>
        </InputContainer>
      ) : (
        <InputContainer>
          <Label style={{ marginBottom: 10 }}>{label}</Label>
          <StyledInput
            type="text"
            placeholder={placeholder ?? "Enter Value"}
            value={value}
            onChange={(event) => onChange(event.target.value)}
            onFocus={() => setIsOpen(true)} // Open dropdown on input focus
          />
        </InputContainer>
      )}

      {isOpen && labelValueList?.length > 0 && (
        <DropdownContent>
          {labelValueList?.map((doc, ind) => (
            <StyledOption
              key={ind}
              style={{
                borderBottomLeftRadius:
                  ind === labelValueList.length - 1 ? 5 : 0,
                borderBottomRightRadius:
                  ind === labelValueList.length - 1 ? 5 : 0,
                borderBottom:
                  ind === labelValueList.length - 1
                    ? "1px solid #eeeeee"
                    : "none",
              }}
              onMouseDown={() => handleOptionClick(doc.value)} // Set input value on option click
            >
              <div>
                <p style={{ fontWeight: "550", fontSize: 14 }}>{doc.label}</p>
                <p style={{}}>{doc.value}</p>
              </div>
            </StyledOption>
          ))}
        </DropdownContent>
      )}
    </DropdownContainer>
  );
}

const StyledOption = styled.div`
  border-top: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 25px;
  border-top-left-radius: ${({ ind }) => (ind === 0 ? 5 : 0)}px;
  border-top-right-radius: ${({ ind }) => (ind === 0 ? 5 : 0)}px;
  &:hover {
    background-color: #f0f0f0; /* Change this to your desired hover background color */
  }
`;

const IconContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px;
`;

const Label = styled.p`
  margin: 0px;
  padding: 0px;
  color: #000;
  font-size: 13px;
  font-weight: 500;
`;

const DropdownContainer = styled.div`
  width: 100%;
`;

const InputContainer = styled.div`
  padding: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 14px;
`;

const DropdownContent = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  margin-top: 5px; /* Add a little space between the input and dropdown */
  margin-bottom: 10px;
`;
