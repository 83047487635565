import React, { useState, useEffect, useCallback, useMemo } from "react";
import { motion, AnimatePresence } from "framer-motion";

import {
  Play,
  Pause,
  RotateCcw,
  ChevronRight,
  ChevronLeft,
  User,
  Bot,
  Clock,
  Filter,
  BarChart2,
  FastForward,
  Rewind,
  X,
} from "lucide-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const ReplayLogs = ({
  dialogue,
  replayIndex,
  isPlaying,
  replaySpeed,
  onTogglePlay,
  onReset,
  onSpeedChange,
  onReplayIndexChange,
  onClose,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const [showAnalytics, setShowAnalytics] = useState(false);
  const itemsPerPage = 5;

  const filteredDialogue = useMemo(() => {
    if (filter === "all") return dialogue;
    return dialogue.filter((message) => message.role === filter);
  }, [dialogue, filter]);

  const totalPages = useMemo(
    () => Math.ceil(filteredDialogue.length / itemsPerPage),
    [filteredDialogue.length],
  );

  const paginatedDialogue = useMemo(
    () =>
      filteredDialogue.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage,
      ),
    [filteredDialogue, currentPage],
  );

  const handlePrevPage = useCallback(
    () => setCurrentPage((prev) => Math.max(prev - 1, 1)),
    [],
  );
  const handleNextPage = useCallback(
    () => setCurrentPage((prev) => Math.min(prev + 1, totalPages)),
    [totalPages],
  );

  const handleFilterChange = useCallback((newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
  }, []);

  const jumpToMessage = useCallback(
    (index) => {
      onReplayIndexChange(index);
      setCurrentPage(Math.floor(index / itemsPerPage) + 1);
    },
    [onReplayIndexChange],
  );

  useEffect(() => {
    const targetPage = Math.min(
      Math.floor(replayIndex / itemsPerPage) + 1,
      totalPages,
    );
    if (targetPage !== currentPage) {
      setCurrentPage(targetPage);
    }
  }, [replayIndex, itemsPerPage, totalPages, currentPage]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key === "j") handleNextPage();
      if (e.key === "k") handlePrevPage();
      if (e.key === " ") onTogglePlay();
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => window.removeEventListener("keydown", handleKeyPress);
  }, [handleNextPage, handlePrevPage, onTogglePlay]);

  const analyticsData = useMemo(() => {
    const data = dialogue.reduce((acc, message, index) => {
      const minute = Math.floor(index / 2);
      if (!acc[minute]) {
        acc[minute] = { minute, userWords: 0, aiWords: 0 };
      }
      const wordCount = message.text.split(" ").length;
      if (message.role === "user") {
        acc[minute].userWords += wordCount;
      } else {
        acc[minute].aiWords += wordCount;
      }
      return acc;
    }, {});
    return Object.values(data);
  }, [dialogue]);

  if (filteredDialogue.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-64 bg-white rounded-lg shadow-sm border border-gray-200 ">
        <Bot size={48} className="text-gray-400 mb-4" />
        <p className="text-xl font-semibold text-gray-600">
          No dialogue available
        </p>
        <p className="text-sm text-gray-500 mt-2">
          Try adjusting your filters or loading a different conversation.
        </p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 relative">
      <h3 className="text-2xl font-semibold mb-6 text-gray-800 flex items-center justify-between">
        <span>Call Replay</span>
        <div className="flex items-center space-x-2">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setShowAnalytics(!showAnalytics)}
            className="p-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
          >
            <BarChart2 size={20} />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() =>
              handleFilterChange(
                filter === "all"
                  ? "user"
                  : filter === "user"
                    ? "assistant"
                    : "all",
              )
            }
            className="p-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
          >
            <Filter size={20} />
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => onClose(false)}
            className="p-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
          >
            <X size={24} />
          </motion.button>
        </div>
      </h3>

      <div className="flex items-center space-x-4 mb-6">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={onTogglePlay}
          className="px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors flex items-center"
        >
          {isPlaying ? (
            <Pause size={16} className="mr-2" />
          ) : (
            <Play size={16} className="mr-2" />
          )}
          {isPlaying ? "Pause" : "Play"}
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={onReset}
          className="px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors flex items-center"
        >
          <RotateCcw size={16} className="mr-2" />
          Reset
        </motion.button>
        <select
          value={replaySpeed}
          onChange={onSpeedChange}
          className="px-3 py-2 border border-gray-300 rounded-md text-gray-700 focus:ring-2 focus:ring-black focus:border-transparent"
        >
          <option value={2000}>Slow</option>
          <option value={1000}>Normal</option>
          <option value={500}>Fast</option>
        </select>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => jumpToMessage(Math.max(0, replayIndex - 1))}
          className="p-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
        >
          <Rewind size={20} />
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() =>
            jumpToMessage(Math.min(dialogue.length - 1, replayIndex + 1))
          }
          className="p-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
        >
          <FastForward size={20} />
        </motion.button>
      </div>

      {showAnalytics && (
        <div className="mb-6 p-4 bg-gray-50 rounded-lg">
          <h4 className="text-lg font-semibold mb-4">Conversation Analytics</h4>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart data={analyticsData}>
              <XAxis dataKey="minute" />
              <YAxis />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="userWords"
                stroke="#3b82f6"
                name="User Words"
              />
              <Line
                type="monotone"
                dataKey="aiWords"
                stroke="#10b981"
                name="AI Words"
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}

      <div className="space-y-4 mb-6">
        <AnimatePresence initial={false}>
          {paginatedDialogue.map((message, index) => (
            <motion.div
              key={`${currentPage}-${index}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className={`p-4 rounded-lg ${
                message.role === "assistant"
                  ? "bg-gray-50 border-l-4 border-black"
                  : "bg-blue-50 border-l-4 border-blue-500"
              } ${(currentPage - 1) * itemsPerPage + index <= replayIndex ? "opacity-100" : "opacity-50"}`}
            >
              <div className="flex items-center mb-2">
                {message.role === "assistant" ? (
                  <Bot size={20} className="mr-2 text-black" />
                ) : (
                  <User size={20} className="mr-2 text-blue-500" />
                )}
                <p className="font-medium text-gray-800">
                  {message.role === "assistant" ? "AI" : "User"}
                </p>
                <Clock size={16} className="ml-auto mr-2 text-gray-400" />
                <p className="text-xs text-gray-500">
                  {new Date(message.timestamp).toLocaleTimeString()}
                </p>
              </div>
              <p className="text-sm text-gray-700 ml-7">{message.text}</p>
              <div className="flex justify-between mt-2 text-xs text-gray-500 ml-7">
                <span>Node: {message.nodeId}</span>
                <span>{message.text.split(" ").length} words</span>
              </div>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      <div className="flex justify-between items-center">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={`flex items-center ${currentPage === 1 ? "text-gray-400 cursor-not-allowed" : "text-black hover:text-gray-700"}`}
        >
          <ChevronLeft size={20} className="mr-1" />
          Previous (K)
        </motion.button>
        <span className="text-sm text-gray-600">
          Page {currentPage} of {totalPages}
        </span>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={`flex items-center ${currentPage === totalPages ? "text-gray-400 cursor-not-allowed" : "text-black hover:text-gray-700"}`}
        >
          Next (J)
          <ChevronRight size={20} className="ml-1" />
        </motion.button>
      </div>
    </div>
  );
};

export default ReplayLogs;
