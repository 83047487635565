import React from "react";
import { Dialog } from "@headlessui/react";
import { Loader2 } from "lucide-react";

const DeleteConfirmationDialog = ({
  isOpen,
  onClose,
  onDelete,
  isDeleting,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-md rounded-lg bg-white p-6 shadow-xl">
          <Dialog.Title className="text-2xl font-bold mb-4 text-center">
            This will delete your pathway permanently.
          </Dialog.Title>

          <Dialog.Description className="mb-6 text-center text-gray-600">
            Are you sure you want to delete this pathway? All of your pathway
            data will be permanently removed. This action cannot be undone.
          </Dialog.Description>

          <div className="flex justify-between space-x-4">
            <button
              disabled={isDeleting}
              className="bg-gray-200 text-gray-800 px-4 py-2 rounded-md text-lg font-semibold w-1/2 hover:bg-gray-300 transition-colors"
              onClick={onClose}
            >
              Cancel
            </button>

            <button
              className="bg-red-600 text-white px-4 py-2 rounded-md text-lg font-semibold w-1/2 hover:bg-red-700 transition-colors relative"
              disabled={isDeleting}
              onClick={onDelete}
            >
              {isDeleting ? (
                <Loader2 className="animate-spin h-5 w-5 mx-auto" />
              ) : (
                "Delete"
              )}
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
