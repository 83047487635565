import React from "react";
import styled from "styled-components";
import { ErrorMessage } from "@hookform/error-message";
import { ErrorText } from "./ErrorText";
import { useFormContext } from "react-hook-form";
import Label from "./Label";

export default function Textarea({
  label,
  fieldName,
  tooltipText,
  tooltipTitle,
  autoResize = false,
  ...props
}) {
  const {
    // getValues,
    register,
    // setValue,
    formState: { errors },
  } = useFormContext();

  // const [text, setText] = useState('');
  // const textareaRef = useRef(null);

  // useEffect(() => {
  //    if (autoResize) {
  //       adjustTextareaHeight();
  //    }
  // }, [text, autoResize]);

  // const handleInputChange = (event) => {
  //    setText(event.target.value);
  //    setValue(fieldName, event.target.value);
  //    if (!autoResize) {
  //       adjustTextareaHeight();
  //    }
  // };

  // const adjustTextareaHeight = () => {
  //    if (textareaRef.current && autoResize) {
  //       textareaRef.current.style.height = 'auto';
  //       textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
  //    }
  // };

  // const handlePaste = (event) => {
  //    setTimeout(adjustTextareaHeight, 0);
  // };

  // useEffect(() => {
  //    setText(getValues("task"))
  // }, [getValues("task")]);

  return (
    <div className="flex flex-col gap-2 w-full">
      <Label>{label}</Label>
      <StyledTextarea
        {...props}
        {...register(fieldName)}
        // value={text}
        // onChange={handleInputChange}
        // onPaste={handlePaste}
        // ref={textareaRef}
        autoresize={autoResize}
      />
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => <ErrorText>{message}</ErrorText>}
      />
    </div>
  );
}

const StyledTextarea = styled.textarea`
  width: 100%;
  height: ${({ autoresize }) => (autoresize ? "auto" : "100%")};
  min-height: ${({ autoresize }) => (autoresize ? "200px" : "auto")};
  max-height: ${({ autoresize }) => (autoresize ? "500px" : "auto")};
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 12px 15px;
  background-color: #fbfbfa;
  color: black;
  font-weight: 300;
  resize: ${({ autoresize }) => (autoresize ? "none" : "vertical")};
  &::-webkit-resizer {
    background: transparent;
  }
  &:hover {
    cursor: text;
  }
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #a8a8a1;
  }

  ${({ autoresize }) =>
    !autoresize &&
    `
      overflow-y: scroll;
   `}
`;
