import { Editor } from "@monaco-editor/react";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { KeyValuesToObj } from "utils/formatting/KeyValuesToObj";
import "utils/stylesheets/JSONMode.css";

export default function JSONMode() {
  const { watch, reset } = useFormContext();

  //remove fields we don't want for json editor
  const {
    isCallActive,
    json_mode_enabled,
    transfer_list,
    metadata,
    tools,
    analysis_schema,
    calendly_url,
    calendly_timezone,
    ...formState
  } = watch();

  const request_data = KeyValuesToObj(watch().request_data);

  function handleEditorChange(value) {
    try {
      const newFieldValues = JSON.parse(value);
      let { ...data } = newFieldValues;
      // modify requestData into request_data
      reset({ json_mode_enabled: true, ...data });
      console.log("form state", formState);
    } catch (error) {
      return;
    }
  }

  const data = `${JSON.stringify({ ...formState, request_data, analysis_schema, calendly: { url: calendly_url, timezone: calendly_timezone } }, null, 2)}`;

  return (
    <Wrapper>
      <Editor
        height="calc(100vh - 213px)"
        value={data}
        language="json"
        theme="vs-dark"
        onChange={handleEditorChange}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  padding: 20px 0px;
  border-radius: 12px;
`;
