import React, { useState, forwardRef, useRef, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Lightbulb, Loader2 } from "lucide-react";
import { getUserData } from "utils/funcs/browser/getUserData";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";

const withSuggestion = (WrappedComponent) => {
  const user = getUserData();
  return forwardRef(function WithSuggestion(props, ref) {
    const [suggestion, setSuggestion] = useState(null);
    const [value, setValue] = useState(props.value || "");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const containerRef = useRef(null);

    useEffect(() => {
      setValue(props.value || "");
    }, [props.value]);

    const getSuggestion = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/pathway/improvePrompt`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authorization: user.api_key,
            },
            body: JSON.stringify({
              prompt: value,
            }),
          },
        );

        const data = await response.json();

        if (data.status === "success") {
          setSuggestion({
            original: value,
            improved: data.response,
          });
        } else {
          throw new Error("Failed to get suggestion");
        }
      } catch (err) {
        setError("Failed to fetch suggestion. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    const applySuggestion = () => {
      const newValue = suggestion.improved;
      setValue(newValue);
      setSuggestion(null);
      if (props.onChange) {
        props.onChange(newValue);
      }
    };

    const highlightRegex = /{{.*?}}/g;

    const handleChange = (newValue) => {
      setValue(newValue);
      if (props.onChange) {
        props.onChange(newValue);
      }
    };

    const textareaRef = useRef(null);

    // enable entire div to be clickable to focus textarea
    const handleDivClick = () => {
      if (textareaRef.current) {
        textareaRef.current.focus();

        console.log("textareaRef.current", textareaRef.current);
        if (textareaRef.current.setToLastBlock) {
          textareaRef.current.setToLastBlock();
        }
      }
    };

    return (
      <div className="space-y-2">
        <div className="flex justify-between mb-2">
          <div>Prompt:</div>
          <Button
            onClick={getSuggestion}
            className="py-1 px-2 h-8"
            variant="outline"
            size="sm"
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader2 className="h-4 w-4 animate-spin mr-2" />
            ) : (
              <Lightbulb className="h-4 w-4 mr-2" />
            )}
            Improve
          </Button>
        </div>
        <div
          onClick={handleDivClick}
          ref={containerRef}
          className={`relative border border-gray-300 rounded-md ${props.className}`}
          style={{
            minHeight: "150px",
            maxHeight: "300px",
            overflow: "auto",
            resize: "vertical",
            padding: "0.5rem",
            cursor: "text",
          }}
        >
          <HighlightWithinTextarea
            ref={textareaRef}
            value={typeof value === "string" ? value : ""}
            onChange={handleChange}
            highlight={[
              {
                highlight: highlightRegex,
                className: "bg-blue-100",
              },
            ]}
            placeholder={props.placeholder}
          />
        </div>

        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {suggestion && (
          <div className="bg-white p-4 rounded-md mt-2 border border-gray-200 shadow-sm">
            <h4 className="text-sm font-semibold mb-2 text-gray-700">
              Improved Prompt
            </h4>
            <div
              className="max-h-40 overflow-y-auto"
              style={{
                fontSize: "1rem",
                lineHeight: "1.25rem",
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
                fontFamily: "inherit",
                border: "1px solid #e2e8f0",
                borderRadius: "0.375rem",
                padding: "0.5rem",
              }}
            >
              <HighlightWithinTextarea
                value={suggestion.improved}
                highlight={[
                  {
                    highlight: highlightRegex,
                    className: "bg-blue-100",
                  },
                ]}
                readOnly
              />
            </div>
            <div className="flex justify-end space-x-2 mt-4">
              <Button
                onClick={() => setSuggestion(null)}
                size="sm"
                variant="ghost"
                className="text-gray-500 text-xs"
              >
                Dismiss
              </Button>
              <Button
                onClick={applySuggestion}
                size="sm"
                variant="default"
                className="text-xs bg-indigo-600 hover:bg-indigo-700"
              >
                Apply
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  });
};

const TextAreaWithSuggestion = withSuggestion(HighlightWithinTextarea);

export { withSuggestion, TextAreaWithSuggestion };
