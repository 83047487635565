// ProgressBar.js
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 7px;
  background-color: #edeaf0;
  border-radius: 50px;
  overflow: hidden;
`;

const Filler = styled.div`
  height: 100%;
  width: ${(props) => props.percent}%;
  background-color: #6a3ee8;
  border-radius: inherit;
  text-align: right;
  transition: width 0.2s ease-in;
`;

const Label = styled.span`
  padding: 5px;
  color: white;
  font-weight: bold;
`;

const ProgressBar = ({ percent }) => {
  return (
    <Container>
      <Filler percent={percent} />
    </Container>
  );
};

export default ProgressBar;
