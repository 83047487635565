import React, { useState, useEffect } from "react";
import {
  useForm,
  FormProvider,
  useFieldArray,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import { FaTrash } from "react-icons/fa";
import ReactModal from "react-modal";
import Input from "components/core/Input";
import Textarea from "components/core/Textarea";
import Button from "components/core/Button";
import Label from "components/core/Label";

const fieldTypes = [
  { value: "string", label: "Text" },
  { value: "number", label: "Number" },
  { value: "boolean", label: "True or False" },
  { value: "json", label: "JSON" },
  { value: "datetime", label: "Date and Time" },
  { value: "disposition", label: "Disposition" },
  { value: "other", label: "Other" },
];

const dateFormats = [
  { value: "MM/DD/YYYY", label: "MM/DD/YYYY" },
  { value: "DD/MM/YYYY", label: "DD/MM/YYYY" },
  { value: "YYYY/MM/DD", label: "YYYY/MM/DD" },
  { value: "MM-DD-YYYY", label: "MM-DD-YYYY" },
  { value: "DD-MM-YYYY", label: "DD-MM-YYYY" },
  { value: "YYYY-MM-DD", label: "YYYY-MM-DD" },
  { value: "MMMM DD, YYYY", label: "MMMM DD, YYYY" }, // Word option
  { value: "DD MMMM YYYY", label: "DD MMMM YYYY" }, // Word option
  { value: "YYYY MMMM DD", label: "YYYY MMMM DD" }, // Word option
];

const timeFormats = [
  { value: "", label: "No hours" }, // No hours option
  { value: "HH:mm", label: "HH:mm" },
  { value: "HH:mm:ss", label: "HH:mm:ss" },
  { value: "HH:mm:ss.SSS", label: "HH:mm:ss.SSS" },
];

const FieldModal = ({
  isOpen,
  onRequestClose,
  onSave,
  field,
  modalFormMethods,
}) => {
  const [validationError, setValidationError] = useState("");

  const fieldType = useWatch({
    control: modalFormMethods.control,
    name: "field_type",
  });

  const {
    fields: dispositionFields,
    append: appendDisposition,
    remove: removeDisposition,
  } = useFieldArray({
    control: modalFormMethods.control,
    name: "dispositions",
  });

  useEffect(() => {
    modalFormMethods.reset(field);
  }, [field, modalFormMethods]);

  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    modalFormMethods.setValue(name, value);
  };

  const validateJson = (json) => {
    try {
      JSON.parse(json);
      return true;
    } catch (e) {
      return false;
    }
  };

  const validateForm = (data) => {
    if (!data.field_name || !data.field_type) {
      return "Field name and type are required.";
    }
    if (data.field_type === "boolean" && (!data.true_if || !data.false_if)) {
      return "Both True if and False if conditions are required for boolean fields.";
    }
    if (data.field_type === "disposition") {
      for (const disposition of data.dispositions) {
        if (!disposition.option || !disposition.condition) {
          return "All disposition options and conditions must be filled out.";
        }
      }
    }
    if (data.field_type === "json" && !validateJson(data.field_desc)) {
      return "Invalid JSON format.";
    }
    return "";
  };

  const onSubmit = (data) => {
    const error = validateForm(data);
    if (error) {
      setValidationError(error);
    } else {
      setValidationError("");
      onSave(data);
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={customStyles}
    >
      <FormProvider {...modalFormMethods}>
        <ModalFormWrapper onSubmit={modalFormMethods.handleSubmit(onSubmit)}>
          <ModalContent>
            <Label>Type</Label>
            <TypeSelect
              defaultValue={field.type}
              {...modalFormMethods.register("field_type")}
              onChange={handleFieldChange}
            >
              <option disabled>Select Type</option>
              {fieldTypes.map((type) => (
                <option key={type.value} value={type.value}>
                  {type.label}
                </option>
              ))}
            </TypeSelect>

            <Input
              fieldName="field_name"
              label={`${fieldType ? fieldTypes.find((type) => type.value === fieldType)?.label : "Field"} Name`}
              defaultValue={field.name}
              placeholder="Name"
              onChange={handleFieldChange}
            />

            {fieldType !== "boolean" && (
              <Textarea
                fieldName="field_desc"
                label={`${fieldType ? fieldTypes.find((type) => type.value === fieldType)?.label : "Field"} Description`}
                defaultValue={field.description}
                placeholder="Description"
                onChange={handleFieldChange}
              />
            )}

            {fieldType === "boolean" && (
              <>
                <Input
                  fieldName="true_if"
                  label="True if:"
                  defaultValue={field.true_if}
                  placeholder="True if:"
                  onChange={handleFieldChange}
                />
                <Input
                  fieldName="false_if"
                  label="False if:"
                  defaultValue={field.false_if}
                  placeholder="False if:"
                  onChange={handleFieldChange}
                />
              </>
            )}

            {fieldType === "disposition" && (
              <div>
                <Label>Dispositions</Label>
                {dispositionFields.map((disposition, index) => (
                  <DispositionRow key={disposition.id}>
                    <InputWrapper>
                      <Input
                        fieldName={`dispositions[${index}].option`}
                        defaultValue={disposition.option}
                        placeholder="Option"
                        onChange={handleFieldChange}
                      />
                      <DeleteIcon onClick={() => removeDisposition(index)} />
                    </InputWrapper>
                    <Textarea
                      fieldName={`dispositions[${index}].condition`}
                      defaultValue={disposition.condition}
                      placeholder="Condition"
                      onChange={handleFieldChange}
                    />
                  </DispositionRow>
                ))}
                <Button
                  type="button"
                  onClick={() =>
                    appendDisposition({ option: "", condition: "" })
                  }
                >
                  Add Disposition
                </Button>
              </div>
            )}

            {fieldType === "datetime" && (
              <>
                <Label>Date and Time Format</Label>
                <FormatWrapper>
                  <FormatSelect
                    defaultValue={field.date_format}
                    {...modalFormMethods.register("date_format")}
                    onChange={handleFieldChange}
                  >
                    <option disabled>Select Date Format</option>
                    {dateFormats.map((format) => (
                      <option key={format.value} value={format.value}>
                        {format.label}
                      </option>
                    ))}
                  </FormatSelect>
                  <FormatSelect
                    defaultValue={field.time_format}
                    {...modalFormMethods.register("time_format")}
                    onChange={handleFieldChange}
                  >
                    <option disabled>Select Time Format</option>
                    {timeFormats.map((format) => (
                      <option key={format.value} value={format.value}>
                        {format.label}
                      </option>
                    ))}
                  </FormatSelect>
                </FormatWrapper>
              </>
            )}

            {validationError && <Error>{validationError}</Error>}

            <Button
              type="button"
              onClick={modalFormMethods.handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </ModalContent>
        </ModalFormWrapper>
      </FormProvider>
    </ReactModal>
  );
};

const ModalFormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TypeSelect = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
`;

const FormatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FormatSelect = styled.select`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
`;

const DispositionRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  margin-bottom: 20px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const DeleteIcon = styled(FaTrash)`
  cursor: pointer;
  position: absolute;
  right: 10px;
`;

const Error = styled.div`
  color: red;
  font-size: 14px;
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "none",
    borderRadius: "5px",
    width: "100%",
    height: "auto",
    maxHeight: "500px",
    maxWidth: "500px",
    padding: "25px",
    overflow: "scroll",
    zIndex: "6000",
  },
  overlay: {
    backgroundColor: "#00000099",
    zIndex: "5000",
  },
};

export default FieldModal;
