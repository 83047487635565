import React, { useEffect, useState } from "react";
import { PageWrapper } from "components/core/PageWrapper";
import { getUserData } from "utils/funcs/browser/getUserData";
import ActionBar from "components/core/ActionBar";
import Modal from "components/core/Modal";
import Tabs from "components/core/Tabs";
import BlandVoices from "./BlandVoices";
import UserVoices from "./UserVoices";
import CloneVoice from "./Forms/CloneVoice";
import RenameVoice from "./Forms/RenameVoice";
import DeleteVoice from "./Forms/DeleteVoice";
import Button from "components/core/Button";
import { PageTitle } from "components/core/PageTitle";
import "./VoicesPage.css";

export default function Voices() {
  const { voice_options, user } = getUserData();
  const [userVoices, setUserVoices] = useState([]);
  const [blandVoices, setBlandVoices] = useState([]);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [triggerFetchVoiceOptions, setTriggerFetchVoiceOptions] =
    useState(false);
  //  const voiceCharLimit = 30;

  // get user's custom voices
  useEffect(() => {
    console.log("Voice options: ", voice_options);
    if (voice_options) {
      setUserVoices(voice_options);
      setBlandVoices(voice_options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerFetchVoiceOptions]);

  return (
    <>
      <PageWrapper
        style={{ paddingTop: "140px" }}
        className="customPageWrapper"
      >
        <ActionBar top spaceBetween>
          <PageTitle>Voices</PageTitle>
          <Button onClick={() => setShowCloneModal(true)}>
            Upload Voice Clone
          </Button>
        </ActionBar>
        <Tabs
          fixed
          tabs={[
            {
              item: "Bland & Private Voices",
              panel: (
                <UserVoices
                  user={user}
                  voices={userVoices}
                  setShowCloneModal={setShowCloneModal}
                  setFetch={setTriggerFetchVoiceOptions}
                />
              ),
            },
            {
              item: "Public Voices",
              panel: (
                <BlandVoices
                  voices={blandVoices}
                  setFetch={setTriggerFetchVoiceOptions}
                  user={user}
                />
              ),
            },
          ]}
        />
        <Modal
          height="auto"
          open={showCloneModal}
          onClose={() => setShowCloneModal(false)}
        >
          <CloneVoice />
        </Modal>
        <Modal open={showRenameModal} onClose={() => setShowRenameModal(false)}>
          <RenameVoice />
        </Modal>
        <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
          <DeleteVoice />
        </Modal>
      </PageWrapper>
    </>
  );
}
