import React, { useEffect, useState } from "react";
import { Search, Star, User, Copy, Eye, Edit } from "lucide-react";
import { getUserData } from "utils/funcs/browser/getUserData";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { format } from "date-fns";

const tagColors = {
  "Customer Service": "bg-indigo-100 text-indigo-800 border-indigo-300",
  AI: "bg-blue-100 text-blue-800 border-blue-300",
  Support: "bg-purple-100 text-purple-800 border-purple-300",
  Sales: "bg-yellow-100 text-yellow-800 border-yellow-300",
  Booking: "bg-pink-100 text-pink-800 border-pink-300",
  FAQ: "bg-indigo-200 text-indigo-900 border-indigo-400",
  "Knowledge Base": "bg-gray-100 text-gray-800 border-gray-300",
  "Lead Qualification": "bg-cyan-100 text-cyan-800 border-cyan-300",
};

const PathwayCard = ({
  id,
  title,
  description,
  author,
  stars,
  tags,
  clones,
  onPreview,
  starred,
  isOwner,
  onEdit,
  created_at,
}) => {
  const [totalStars, setTotalStars] = useState(stars);
  const [totalClones, setTotalClones] = useState(clones);
  const [star, setStar] = useState(false);
  const navigate = useNavigate();

  const formattedDate = format(new Date(created_at), "PPP");
  useEffect(() => {
    setStar(starred);
  }, [starred]);

  const onClone = async (id) => {
    const user = getUserData();
    if (!user) {
      toast.warn(
        "Please login to clone the pathway. Redirecting to login page...",
        {
          autoClose: 3000,
        },
      );
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } else {
      try {
        const response = await fetch(`/api/showcase/pathway/clone`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: window.localStorage.getItem("auth"),
          },
          body: JSON.stringify({ showcase_id: id }),
        });

        if (response.ok) {
          toast.success(`Pathway cloned successfully`);
          setTotalClones(totalClones + 1);
        } else {
          const errorData = await response.json();
          toast.error(`Failed to clone the pathway: ${errorData.message}`);
        }
      } catch (error) {
        console.error("Error cloning pathway:", error);
        toast.error("An error occurred while cloning the pathway");
      }
    }
  };

  const onStar = async (id) => {
    const user = getUserData();
    if (!user) {
      toast.warn(
        "Please login to star the pathway. Redirecting to login page...",
        {
          autoClose: 3000,
        },
      );
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } else {
      try {
        const response = await fetch(`/api/showcase/pathway/star`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: window.localStorage.getItem("auth"),
          },
          body: JSON.stringify({ showcase_id: id, star: !star }),
        });

        if (response.ok) {
          toast.success(
            `Pathway ${!star ? "starred" : "unstarred"} successfully`,
          );
          setStar(!star);
          setTotalStars(!star ? totalStars + 1 : totalStars - 1);
        } else {
          const errorData = await response.json();
          toast.error(
            `Failed to star/unstar the pathway: ${errorData.message}`,
          );
        }
      } catch (error) {
        console.error("Error starring pathway:", error);
        toast.error("An error occurred while starring the pathway");
      }
    }
  };

  return (
    <div
      onClick={onPreview}
      className="border-l-2 border-t-2 border-b-4 rounded-sm p-6 shadow-sm hover:shadow-md hover:border-r-[3.5px] hover:border-b-[3.5px] transition transition-all duration-500 cursor-pointer transition-shadow flex flex-col h-full relative border-purple-900 border-r-4"
    >
      <div className="flex justify-between items-center mb-3">
        <h3 className="font-bold text-[20px]">{title}</h3>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => onStar(id)}
            className="flex items-center space-x-1 text-yellow-500 hover:text-yellow-600"
          >
            {Boolean(star) ? (
              <AiFillStar className="text-yellow-500" />
            ) : (
              <AiOutlineStar />
            )}
            <span className="text-[12px]">{totalStars}</span>
          </button>
          <div className="flex items-center space-x-1 text-gray-600 hover:text-gray-800">
            <Copy size={16} />
            <span className="text-[12px]">{totalClones}</span>
          </div>
        </div>
      </div>
      <div className="text-[12px] text-gray-500 mb-3">{formattedDate}</div>
      <p className="text-[12px] text-gray-600 mb-4 flex-grow line-clamp-3">
        {description}
      </p>
      <div className="flex flex-wrap mb-4">
        {tags.slice(0, 3).map((tag, index) => (
          <span
            key={index}
            className={`text-[10px] px-2 py-1 rounded-full border mr-2 mb-2 ${tagColors[tag] || "bg-gray-200 text-gray-700 border-gray-300"}`}
          >
            {tag}
          </span>
        ))}
      </div>
      <div className="flex items-center justify-between mt-auto">
        <div className="flex items-center space-x-2">
          <User size={16} className="text-gray-400" />
          <span className="text-[12px] text-gray-600">{author}</span>
        </div>
        <div className="flex space-x-2">
          <button
            onClick={onPreview}
            className="flex items-center space-x-1 text-indigo-500 hover:text-indigo-600 border border-indigo-500 px-3 py-2 rounded-md hover:bg-indigo-500 hover:text-white duration-300"
          >
            <Eye size={16} />
            <span className="text-[12px]">View Pathway</span>
          </button>
          {isOwner && (
            <button
              onClick={() => onEdit(id)}
              className="flex items-center space-x-1 text-green-500 hover:text-green-600 border border-green-500 px-3 py-2 rounded-md"
            >
              <Edit size={16} />
              <span className="text-[12px]">Edit</span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PathwayCard;
