import { useFieldArray, useFormContext } from "react-hook-form";
import { useState } from "react";
import { DeleteIcon } from "assets/icons/navigationIcons";
import { Divider } from "components/core/Divider";
import Input from "components/core/Input";
import Select from "components/core/Select";
import SectionHeader from "../../SectionHeader";
import Button from "components/core/Button";
import styled from "styled-components";
import Variables from "./Nested/Variables";
import KeyValues from "./Nested/KeyValues";
import Switch from "components/core/Switch";
import TestRequest from "./Nested/Test/TestRequest";

export default function DynamicData({ fieldName }) {
  const [openIndex, setOpenIndex] = useState(0);

  const { control } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  const handleAccordianOpen = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <SectionHeader>
        <h4 className="font-semibold">Dynamic Data</h4>
        <Button
          onClick={() => {
            setOpenIndex(fields.length);
            append({
              url: "",
              method: "GET",
              body: {},
              headers: {
                "Content-Type": "application/json",
              },
              query: {},
              cache: true,
              response_data: [{ context: null, data: "$", name: "" }],
            });
          }}
        >
          + API Request
        </Button>
      </SectionHeader>
      {fields.length > 0 ? (
        fields.map((field, fieldIndex) => (
          <Wrapper key={field.id}>
            <Head onClick={() => handleAccordianOpen(fieldIndex)}>
              <SectionHeader>
                <RequestTitle>API Request {fieldIndex + 1}</RequestTitle>
                <Button
                  appearance="outline"
                  onClick={() => {
                    remove(fieldIndex);
                  }}
                  style={{ width: "auto !important" }}
                >
                  <DeleteIcon
                    onClick={() => {
                      remove(fieldIndex);
                    }}
                  />
                </Button>
              </SectionHeader>
            </Head>
            {fieldIndex === openIndex && (
              <Panel>
                <UrlWrap>
                  <Select
                    fieldName={`${fieldName}[${fieldIndex}].method`}
                    style={{
                      width: "100px",
                      borderRadius: "4px 0px 0px 4px",
                      borderWidth: "1px 0px 1px 1px",
                    }}
                  >
                    <option>GET</option>
                    <option>POST</option>
                  </Select>
                  <Input
                    fieldName={`${fieldName}[${fieldIndex}].url`}
                    style={{ borderRadius: "0px 4px 4px 0px" }}
                    placeholder="https://api.example.com/endpoint"
                  />
                </UrlWrap>
                <KeyValues
                  fieldName={fieldName}
                  nestedFieldName="headers"
                  requestIndex={fieldIndex}
                />
                <KeyValues
                  fieldName={fieldName}
                  nestedFieldName="body"
                  requestIndex={fieldIndex}
                />
                <KeyValues
                  fieldName={fieldName}
                  nestedFieldName="query"
                  requestIndex={fieldIndex}
                />
                <Switch
                  fieldName={`${fieldName}[${fieldIndex}].cache`}
                  label="Cache/Save Response"
                />
                <Variables fieldName={fieldName} requestIndex={fieldIndex} />
                <Divider />
                <TestRequest requestIndex={fieldIndex} />
              </Panel>
            )}
          </Wrapper>
        ))
      ) : (
        <p>No API requests added yet.</p>
      )}
    </>
  );
}

const RequestTitle = styled.h6`
  text-transform: uppercase;
  font-family: "Roboto Mono", monospace;
  font-weight: 600;
  letter-spacing: 1.1px;
  color: #676775;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: none;
  border-radius: 5px;
  overflow: hidden;
  border-color: #eeeeee;
  border-width: 1px;
  @media screen and (max-width: 900px) {
    gap: 0px;
  }
`;

const Head = styled.div`
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #fbfbfa;
  }
`;

const Panel = styled.div`
  padding: 40px;
  justify-content: start;
  overflow: hidden;
  border-color: #eeeeee;
  border-width: 1px 0px 0px 0px;
  row-gap: 40px;
  display: flex;
  flex-direction: column;
`;

const UrlWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;
