import * as yup from "yup";

export const signupSchema = yup.object().shape({
  first_name: yup.string().required("First name is required"),
  last_name: yup.string().required("Last name is required"),
  email: yup.string().required("Email is required"),
  phone: yup
    .string()
    .matches(/^(\d{10,13})$/, "Phone number must be 10 or 11 digits")
    .required("Phone is required"),
  agreed_tos: yup
    .boolean()
    .oneOf([true], "Please agree to our terms of service.")
    .required(),
  code: yup
    .string()
    // when isCod feStage = true, then require "code" field, otherwise make it optional.
    .when("isCodeStage", {
      is: true,
      then: () =>
        yup
          .string()
          .min(6, "Code must be at least 6 digits")
          .required("Code is required"),
      otherwise: () => yup.string().nullable(),
    }),
  // for conditionally requiring the "code" field
  isCodeStage: yup.boolean(),
});
