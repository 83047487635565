import axios from "axios";
import { getAuthToken } from "../browser/getAuthToken";

export const getAvailableNumbers = async (user_id, area_code, country_code) => {
  const token = getAuthToken();
  try {
    if (area_code?.length >= 3) {
      const { data } = await axios.post(
        "/api/numbers/get_available_numbers",
        {
          user_id: user_id,
          area_code: area_code,
          country_code: country_code,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      if (data) {
        return data.phone_numbers;
      } else {
        console.error(`HTTP error fetching available numbers.`, data);
        return data;
      }
    }
  } catch (error) {
    console.error(error);
    return error.message;
  }
};
