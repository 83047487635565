import { PageWrapper } from "components/core/PageWrapper";
import { useEffect, useState } from "react";
import { listBatches } from "utils/funcs/batch-call/listBatches";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loading } from "components/core/Loading";
import { getApiKey } from "utils/funcs/auth/getApiKey";
import { BatchDropdown } from "./BatchDropdown";
import BatchForm from "./BatchForm";
import ActionBar from "components/core/ActionBar";
import "react-toastify/dist/ReactToastify.css";
import { PageTitle } from "components/core/PageTitle";
import { Button, Flex, Switch, Text } from "@radix-ui/themes";

export default function BatchCall() {
  const [batches, setBatches] = useState([]);
  const [currBatch, setCurrBatch] = useState();
  const [apiKey, setApiKey] = useState();
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const selectedBatchId = searchParams.get("batch-id");

  // get users api key
  useEffect(() => {
    const getKey = async () => {
      const key = await getApiKey();
      setApiKey(key);
    };
    getKey();
  }, []);

  // list all batches beloging to the user
  // navigate user to the first batch in the list.
  useEffect(() => {
    if (apiKey) {
      const list = async () => {
        setLoading(true);
        const batches = await listBatches(apiKey, 50);
        if (batches && batches.length > 0) {
          setBatches(batches);
          setLoading(false);

          console.log({
            setBatches: true,
            isLoading: false,
            length: batches?.length,
          });
          // find the current batch based on selected batch id, set it.
          if (selectedBatchId) {
            const targetBatch = selectedBatchId
              ? batches.find((batch) => batch.batch_id === selectedBatchId)
              : batches[0];
            setCurrBatch(targetBatch);
          }
          // If there is no batch id in search params, then navigate user to the first batch in the batches array
          if (!selectedBatchId) {
            navigate(`?page=batch-call&batch-id=${batches[0]?.batch_id}`);
            setCurrBatch(batches[0]);
          }
        } else {
          setBatches(batches);
          setLoading(false);
        }
      };
      list();
    }
  }, [apiKey, navigate, selectedBatchId]);

  return (
    <PageWrapper>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <BatchForm
          batches={batches}
          selectedBatchId={selectedBatchId}
          apiKey={apiKey}
          currBatch={currBatch}
        />
      )}
    </PageWrapper>
  );
}
