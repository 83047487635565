import React, { useState } from "react";
import {
  AlertCircle,
  AlertTriangle,
  Info,
  ArrowRight,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import { CardDescription, CardTitle } from "@/components/ui/card";
import { Card } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";

export const ErrorLogTable = ({ logs = [], logsPerPage = 5, chartRange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const totalPages = Math.ceil(logs.length / logsPerPage);
  const getStatusColor = (level) => {
    switch (level.toLowerCase()) {
      case "error":
        return "text-red-500";
      case "warn":
        return "text-yellow-500";
      default:
        return "text-gray-500";
    }
  };

  const getStatusIcon = (level) => {
    switch (level.toLowerCase()) {
      case "error":
        return <AlertCircle className="w-4 h-4" />;
      case "warn":
        return <AlertTriangle className="w-4 h-4" />;
      default:
        return null;
    }
  };

  const paginatedLogs = logs.slice(
    (currentPage - 1) * logsPerPage,
    currentPage * logsPerPage,
  );

  return (
    <Card size={"1"}>
      <CardTitle style={{}}>{"Error Log"}</CardTitle>
      <CardDescription style={{ marginTop: 5, fontSize: 12 }}>
        {chartRange}
      </CardDescription>

      <div className="overflow-x-auto mt-10">
        <table className="min-w-full">
          <thead>
            <tr className="text-left text-gray-500 text-sm">
              <th className="py-2 px-3">Call ID</th>
              <th className="py-2 px-3">Level</th>
              <th className="py-2 px-3">Time</th>
              <th className="py-2 px-3">Message</th>
              <th className="py-2 px-3"></th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {paginatedLogs.map((log) => (
              <tr key={log.entity_id} className="border-t border-gray-100">
                <td className="py-2 px-3 font-medium">
                  {log?.entity_id || "-"}
                </td>
                <td className="py-2 px-3">
                  <span
                    className={`flex items-center ${getStatusColor(log.log_level)}`}
                  >
                    {getStatusIcon(log.log_level)}
                    <span className="ml-1 capitalize">{log.log_level}</span>
                  </span>
                </td>
                <td className="py-2 px-3 text-gray-500">
                  {new Date(log.created_at).toLocaleString("en-us")}
                </td>
                <td
                  className="py-2 px-3 text-gray-700"
                  style={{ fontSize: 12 }}
                >
                  {log.message}
                </td>
                <td className="py-2 px-3">
                  <button
                    onClick={() =>
                      console.log(`Viewing details for: ${log.entity_id}`)
                    }
                    className="text-blue-500 hover:text-blue-700"
                  >
                    <ArrowRight
                      onClick={() => {
                        navigate(
                          `/dashboard?page=call-logs&currPage=1&ref_c_id=${log.entity_id}`,
                        );
                      }}
                      className="w-4 h-4"
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4 flex items-center justify-between">
        <span className="text-sm text-gray-700">
          Showing {(currentPage - 1) * logsPerPage + 1} to{" "}
          {Math.min(currentPage * logsPerPage, logs.length)} of {logs.length}{" "}
          entries
        </span>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-3 py-1 bg-gray-200 text-gray-700 rounded-md disabled:opacity-50"
          >
            <ChevronLeft className="w-4 h-4" />
          </button>
          <span className="text-sm text-gray-700">
            Page {currentPage} of {totalPages}
          </span>
          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className="px-3 py-1 bg-gray-200 text-gray-700 rounded-md disabled:opacity-50"
          >
            <ChevronRight className="w-4 h-4" />
          </button>
        </div>
      </div>
    </Card>
  );
};
