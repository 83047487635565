export const actionItems = [
  {
    highlight: true,
    title: "Conversational Pathways",
    description: "Design infinitely complex, branching conversational flows",
    link: "/dashboard?page=convo-pathways",
  },
  {
    highlight: true,
    title: "Send Phone Call",
    description: "Use our visual editor to dispatch phone calls",
    link: "/dashboard?page=send-call",
  },

  {
    title: "Send Bulk Calls",
    description:
      "Upload a CSV or JSON file to send out a large number of calls all at once",
    beta: false,
    link: "/dashboard?page=batch-call",
  },
  {
    title: "Buy Phone Number",
    description:
      "Instantly purchase and configure a phone number for your business",
    beta: false,
    link: "/dashboard?page=phone-numbers",
  },
  {
    title: "Voices & Voice Cloning",
    description: "View the voices you have access to or clone a new voice.",
    beta: false,
    link: "/dashboard?page=voices",
  },
  {
    title: "Purchase Credits",
    description:
      "Purchase more credits to send out more calls and support our platform.",
    beta: false,
    link: "/dashboard?page=pay",
  },
];
