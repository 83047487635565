import { Switch } from "@headlessui/react";
import SwitchOption from "../utils/switchOption";
import React, { forwardRef, useImperativeHandle, useState } from "react";
import { RxCross2 } from "react-icons/rx";

const UnitTest = forwardRef(({initialTestData}, ref) => {
    const [localTestData, setLocalTestData] = useState(initialTestData || {
        isEnabled: false,
        activeTestTypes: [],
        promptBasedTest: "",
        cosineSimilarityThreshold: 0.8,
        cosineSimilarityReference: "",
        requiredKeywords: [],
        forbiddenKeywords: []
      });
    
      useImperativeHandle(ref, () => ({
        getCurrentTestData: () => localTestData
      }));
    
      const updateLocalTestData = (updates) => {
        setLocalTestData(prevData => ({
          ...prevData,
          ...updates
        }));
      };
    
      const testTypes = [
        "Prompt Based Unit Tests",
        "Cosine Similarity",
        "Keyword Tests"
      ];
    
      const toggleTestType = (type) => {
        const updatedTypes = localTestData.activeTestTypes.includes(type)
          ? localTestData.activeTestTypes.filter(t => t !== type)
          : [...localTestData.activeTestTypes, type];
        updateLocalTestData({ activeTestTypes: updatedTypes });
      };
    
      const addKeyword = (type) => {
        if (type === 'required') {
          updateLocalTestData({ requiredKeywords: [...localTestData.requiredKeywords, ''] });
        } else {
          updateLocalTestData({ forbiddenKeywords: [...localTestData.forbiddenKeywords, ''] });
        }
      };
    
      const updateKeyword = (index, value, type) => {
        if (type === 'required') {
          const updatedKeywords = [...localTestData.requiredKeywords];
          updatedKeywords[index] = value;
          updateLocalTestData({ requiredKeywords: updatedKeywords });
        } else {
          const updatedKeywords = [...localTestData.forbiddenKeywords];
          updatedKeywords[index] = value;
          updateLocalTestData({ forbiddenKeywords: updatedKeywords });
        }
      };
    
      const removeKeyword = (index, type) => {
        if (type === 'required') {
          const updatedKeywords = localTestData.requiredKeywords.filter((_, i) => i !== index);
          updateLocalTestData({ requiredKeywords: updatedKeywords });
        } else {
          const updatedKeywords = localTestData.forbiddenKeywords.filter((_, i) => i !== index);
          updateLocalTestData({ forbiddenKeywords: updatedKeywords });
        }
      };


  const getTestTypeDescription = (type) => {
    switch (type) {
      case "Prompt Based Unit Tests":
        return "Prompt Based Unit Tests allow you to input natural language instructions to evaluate whether the agent's response is good or bad based on your criteria.";
      case "Cosine Similarity":
        return "Cosine Similarity tests measure the similarity between the agent's response and a reference text. You can set a threshold to determine what constitutes a good response.";
      case "Keyword Tests":
        return "Keyword Tests let you specify certain words or phrases to look for in the agent's response, marking them as indicators of good or bad responses.";
      default:
        return "";
    }
  };

  return (
    <div className="border border-gray-20 p-4 rounded-lg shadow-sm overflow-hidden">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-2xl text-gray-900">Unit Tests</h3>
          <p className="text-lg text-gray-500 mt-1">Evaluate the agent's responses at this node</p>
        </div>
        <Switch
          checked={localTestData.isEnabled}
          onChange={(isEnabled) => updateLocalTestData({ isEnabled })}
          className={`${
            localTestData.isEnabled ? "bg-indigo-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
        >
          <span className="sr-only">Enable Unit Test</span>
          <span
            className={`${
              localTestData.isEnabled ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
          />
        </Switch>
      </div>

      {localTestData.isEnabled && (
        <div className="py-4 space-y-6">
          <div className="space-y-4">
            <h4 className="text-base font-medium">Select Unit Test Types</h4>
            {testTypes.map((type) => (
              <SwitchOption
                key={type}
                checked={localTestData.activeTestTypes.includes(type)}
                onChange={() => toggleTestType(type)}
                label={type}
                description={getTestTypeDescription(type)}
              />
            ))}
          </div>

          {localTestData.activeTestTypes.includes("Prompt Based Unit Tests") && (
            <div className="border-t border-gray-200 pt-4">
              <h5 className="text-sm font-medium text-gray-900 mb-1">Prompt Based Unit Tests</h5>
              <p className="text-xs text-gray-500 mb-3">{getTestTypeDescription("Prompt Based Unit Tests")}</p>
              <textarea
                value={localTestData.promptBasedTest}
                onChange={(e) => updateLocalTestData({ promptBasedTest: e.target.value })}
                placeholder="Enter your prompt-based test instructions here..."
                className="w-full p-2 border border-gray-300 rounded-md text-sm focus:ring-indigo-500 focus:border-indigo-500"
                rows={4}
              />
            </div>
          )}

          {localTestData.activeTestTypes.includes("Cosine Similarity") && (
            <div className="border-t border-gray-200 pt-4">
              <h5 className="text-sm font-medium text-gray-900 mb-1">Cosine Similarity</h5>
              <p className="text-xs text-gray-500 mb-3">{getTestTypeDescription("Cosine Similarity")}</p>
              <label className="block text-sm font-medium text-gray-700 mb-1">Similarity Threshold:</label>
              <input
                type="number"
                min="0"
                max="1"
                step="0.01"
                value={localTestData.cosineSimilarityThreshold}
                onChange={(e) => updateLocalTestData({ cosineSimilarityThreshold: parseFloat(e.target.value) })}
                className="w-full p-2 border border-gray-300 rounded-md text-sm focus:ring-indigo-500 focus:border-indigo-500 mb-3"
              />
              <label className="block text-sm font-medium text-gray-700 mb-1">Reference Sentence:</label>
              <textarea
                value={localTestData.cosineSimilarityReference}
                onChange={(e) => updateLocalTestData({ cosineSimilarityReference: e.target.value })}
                placeholder="Enter the reference sentence for cosine similarity comparison..."
                className="w-full p-2 border border-gray-300 rounded-md text-sm focus:ring-indigo-500 focus:border-indigo-500"
                rows={3}
              />
            </div>
          )}

          {localTestData.activeTestTypes.includes("Keyword Tests") && (
            <div className="border-t border-gray-200 pt-4">
              <h5 className="text-sm font-medium text-gray-900 mb-1">Keyword Tests</h5>
              <p className="text-xs text-gray-500 mb-3">{getTestTypeDescription("Keyword Tests")}</p>
              
              <div className="mb-4">
                <h6 className="text-xs font-medium text-gray-700 mb-2">Required Keywords</h6>
                <p className="text-xs text-gray-500 mb-2">Flag if response does not contain these keywords</p>
                {localTestData.requiredKeywords.map((keyword, index) => (
                  <div key={`required-${index}`} className="flex items-center space-x-2 mb-2">
                    <input
                      value={keyword}
                      onChange={(e) => updateKeyword(index, e.target.value, 'required')}
                      placeholder="Enter required keyword"
                      className="flex-grow p-2 border border-gray-300 rounded-md text-sm focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    <button
                      onClick={() => removeKeyword(index, 'required')}
                      className="text-red-600 hover:text-red-800 transition-colors"
                    >
                      <RxCross2 className="w-5 h-5" />
                    </button>
                  </div>
                ))}
                <button
                  onClick={() => addKeyword('required')}
                  className="mt-2 px-3 py-1 bg-indigo-100 text-indigo-700 text-sm rounded-md hover:bg-indigo-200 transition-colors"
                >
                  Add Required Keyword
                </button>
              </div>

              <div>
                <h6 className="text-xs font-medium text-gray-700 mb-2">Forbidden Keywords</h6>
                <p className="text-xs text-gray-500 mb-2">Flag if response contains these keywords</p>
                {localTestData.forbiddenKeywords.map((keyword, index) => (
                  <div key={`forbidden-${index}`} className="flex items-center space-x-2 mb-2">
                    <input
                      value={keyword}
                      onChange={(e) => updateKeyword(index, e.target.value, 'forbidden')}
                      placeholder="Enter forbidden keyword"
                      className="flex-grow p-2 border border-gray-300 rounded-md text-sm focus:ring-indigo-500 focus:border-indigo-500"
                    />
                    <button
                      onClick={() => removeKeyword(index, 'forbidden')}
                      className="text-red-600 hover:text-red-800 transition-colors"
                    >
                      <RxCross2 className="w-5 h-5" />
                    </button>
                  </div>
                ))}
                <button
                  onClick={() => addKeyword('forbidden')}
                  className="mt-2 px-3 py-1 bg-indigo-100 text-indigo-700 text-sm rounded-md hover:bg-indigo-200 transition-colors"
                >
                  Add Forbidden Keyword
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
});

export default UnitTest;