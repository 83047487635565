import Input from "components/core/Input";
import LightTooltip from "./ToolTipDesc";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"; // Import the question mark icon

export default function Voicemail_message({ fieldName }) {
  const description = (
    <span>
      When the AI encounters a voicemail, it will leave this message after the
      beep and then immediately end the call.
      <br /> <br />
      <i>Warning:</i> If amd is set to true or voicemail_action is set to
      ignore, then this will still work for voicemails, but it will not hang up
      for IVR systems.
    </span>
  );
  return (
    <Input
      fieldName={fieldName}
      label={
        <span>
          Voice Mail Message
          <LightTooltip title={description} placement="right-start" arrow>
            <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
          </LightTooltip>
        </span>
      }
      type="text"
      placeholder="Hey, Just checking in!"
    />
  );
}
