import { useState, useEffect, useCallback, useMemo } from "react";
import { getUserData } from "utils/funcs/browser/getUserData";
import axios from "axios";

const usePhoneNumbers = () => {
  const [inboundNumbers, setInboundNumbers] = useState([]);
  const [twilioNumbers, setTwilioNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Using useMemo for getUserData() to avoid unnecessary recalculations
  // This is beneficial if getUserData() is an expensive operation
  const user = useMemo(() => getUserData(), []);

  const fetchInboundNumbers = useCallback(async () => {
    try {
      const response = await axios.get("/api/inbound/get_inbound", {
        headers: { Authorization: user.api_key },
      });
      return response.data || { inboundNumbers: [] };
    } catch (err) {
      console.error("Error fetching inbound numbers:", err);
      return [];
    }
  }, [user.api_key]);

  const fetchTwilioNumbers = useCallback(async () => {
    try {
      const response = await axios.get("/api/byot/get", {
        headers: { Authorization: user.api_key },
      });
      return response.data || []; // Ensure we always return an array
    } catch (err) {
      console.error("Error fetching Twilio numbers:", err);
      return []; // Return an empty array on error
    }
  }, [user.api_key]);

  const fetchAllNumbers = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [inboundData, twilioData] = await Promise.all([
        fetchInboundNumbers(),
        fetchTwilioNumbers(),
      ]);

      setInboundNumbers(inboundData.inboundNumbers || []);
      setTwilioNumbers(twilioData || []);
    } catch (err) {
      setError(err.message || "An error occurred while fetching phone numbers");
    } finally {
      setLoading(false);
    }
  }, [fetchInboundNumbers, fetchTwilioNumbers]);

  useEffect(() => {
    fetchAllNumbers();
  }, [fetchAllNumbers]);

  return {
    inboundNumbers,
    twilioNumbers,
    loading,
    setLoading,
    error,
    refreshNumbers: fetchAllNumbers,
  };
};

export default usePhoneNumbers;
