import Button from "components/core/Button";
import { TiTick } from "react-icons/ti";

const PopUp = ({ setPopUpVisible }) => {
  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 border border-gray-300 bg-white p-8 rounded-lg shadow-lg w-[500px] space-y-4">
      <h1 className=" text-indigo-500 font-bold mb-4 text-center">
        Conversational Pathways is Out!
      </h1>
      <p className=" mb-4">
        Conversational pathways are our new way of prompting Bland that has led
        to major breakthroughs in realism.
      </p>
      <div>
        <div className="grid grid-cols-[auto_1fr] gap-2 items-start mb-2">
          <TiTick className="text-indigo-500" size={24} />
          <p className="">
            Greater control over the conversational flow dialogue (Choose
            between static text, or a prompt)
          </p>
        </div>
        <div className="grid grid-cols-[auto_1fr] gap-2 items-start mb-2">
          <TiTick className="text-indigo-500" size={24} />
          <p className="">
            Route the conversation based on the user's responses.
          </p>
        </div>
        <div className="grid grid-cols-[auto_1fr] gap-2 items-start mb-2">
          <TiTick className="text-indigo-500" size={24} />
          <p className="">
            Execute custom tools/webhooks and send dialogue during, and after
            the webhook execution (Data collected from webhook can be used in
            the dialogue)
          </p>
        </div>
      </div>
      <p>More features and video tutorial coming soon!</p>
      <p className="">Pro Tip: Hit backspace to delete nodes/edges</p>
      <div className="flex justify-center">
        <Button
          onClick={() => {
            localStorage.setItem("popUpShown", "true");
            setPopUpVisible(false);
          }}
        >
          Get Started
        </Button>
      </div>
    </div>
  );
};

export default PopUp;
