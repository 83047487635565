import axios from "axios";
import { getAuthToken } from "../browser/getAuthToken";

export const cancelRecharge = async (userId) => {
  const token = getAuthToken();
  const headers = {
    authorization: `${token}`,
  };
  try {
    const response = await axios.post(
      "/api/recharge/cancel",
      {
        user_id: userId,
      },
      { headers },
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
