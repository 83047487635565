import styled from "styled-components";

export const ErrorText = ({ message }) => {
  return <Error>{message}</Error>;
};

const Error = styled.p`
  color: red;
  font-size: 1.2rem;
`;
