import { createContext, useState } from "react";

const ClientSecretContext = createContext();

const ClientSecretProvider = ({ children }) => {
  const [clientSecret, setClientSecret] = useState("");

  return (
    <ClientSecretContext.Provider value={[clientSecret, setClientSecret]}>
      {children}
    </ClientSecretContext.Provider>
  );
};

export { ClientSecretContext, ClientSecretProvider };
