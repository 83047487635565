import { useEffect, useState, useRef, useCallback } from "react";
import { PageWrapper } from "../../core/PageWrapper.js";
import { fetchCalls } from "utils/funcs/call-logs/fetchCalls.js";
import ActionBar from "../../core/ActionBar.js";
import { convertToMinutesAndSeconds } from "utils/formatting/secsToMins.js";
import { toDtmy } from "utils/formatting/toDtmy.js";
import Modal from "components/core/Modal.js";
import { useNavigate, useSearchParams } from "react-router-dom";
import Variables from "./Variables.jsx";
import Metadata from "./Metadata.jsx";
import { PageTitle } from "components/core/PageTitle.js";
import Summary from "./Summary.jsx";
import { getUserData } from "utils/funcs/browser/getUserData.js";
import styled from "styled-components";
import Button from "components/core/Button.js";
import PathwayLogs from "../ConvoPathways/Components/PathwayLogs.js";
import Fuse from "fuse.js";
import ExpandIcon from "../../../assets/icons/expand-icon.svg";
import Call from "./Call.jsx";
import AudioComponent from "./AudioComponent.jsx";
import {
  Button as RadixButton,
  Card,
  DropdownMenu,
  Flex,
  Popover,
  TextField,
  IconButton,
  Badge,
} from "@radix-ui/themes";
import * as Icons from "@radix-ui/react-icons";
import { AdvancedTable } from "./TableView/AdvTable.jsx";
import { getAuthToken } from "utils/funcs/browser/getAuthToken.js";
import { fetchTranscript } from "utils/funcs/call-logs/fetchTranscript.js";
import TranscriptSlideOut from "./SlideOutCabinet.js";
import { Box } from "@mui/material";

import {
  Select as SSelect,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "@/components/ui/select.jsx";
import { Filter, PlusIcon, X } from "lucide-react";
import { toast } from "react-toastify";

const TYPE_MAPS = [
  { label: "True/False", value: "boolean" },
  { label: "Text", value: "string" },
  { label: "Number", value: "int" },
];

const FILTER_CONFIG = {
  "Call ID": { operators: ["eq"] }, // done
  To: { operators: ["eq"] }, // done
  From: { operators: ["eq"] }, // done
  Direction: { operators: ["eq"] }, // done
  "Transferred To": { operators: ["eq", "neq"] }, // done
  Recording: { operators: ["eq"] }, // done
  Cost: { operators: ["eq", "gt", "lt"] },
  "Call Length": { operators: ["eq", "gt", "lt"] },
  "Error Message": { operators: ["eq"] }, // done
  "Answered By": { operators: ["eq"] }, // done
  "Call Ended By": { operators: ["eq"] }, // done
  "Batch ID": { operators: ["eq"] }, // done
  "Pathway ID": { operators: ["eq"] }, // done
  "Pathway Name": { operators: ["contains"] }, // done
};

const OPERATOR_LABELS = {
  eq: "Equals",
  gt: "Greater Than",
  lt: "Less Than",
  neq: "Not Equal",
  contains: "Contains",
  startsWith: "Starts With",
  endsWith: "Ends With",
};

export default function CallLogs() {
  // Core States
  const { user } = getUserData();
  const [calls, setCalls] = useState([]);
  const [allCallsData, setAllCallsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [recordCount, setRecordCount] = useState();
  const [pageCount, setPageCount] = useState(25);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const currPage = searchParams.get("currPage");
  const navigate = useNavigate();
  const [focusedSummary, setFocusedSummary] = useState(null);
  const [focusedTranscriptCId, setFocusedTranscriptCId] = useState(null);
  const [focusedVariables, setFocusedVariables] = useState(null);
  const [focusedPathwayLogs, setFocusedPathwayLogs] = useState(null);
  const [focusedCall, setFocusedCall] = useState(null);
  const [saved, setSaved] = useState(false);
  const [allCallIds, setAllCallsIds] = useState(null);
  // Modal States
  const [openSummary, setOpenSummary] = useState(false);
  const [openCallData, setOpenCallData] = useState(false);
  const [openPathwayLogs, setOpenPathwayLogs] = useState(false);
  const [openTranscript, setOpenTranscript] = useState(false);
  const [openVariables, setOpenVariables] = useState(false);
  const [openMetadata, setOpenMetadata] = useState(false);
  const [unformattedData, setUnformattedData] = useState([]);
  const [callHistoryMetrics, setCallHistoryMetrics] = useState({
    calls: [],
    timeframe: "day",
  });
  const [filters, setFilters] = useState([]);
  const [asQpStore, setAsQpStore] = useState(null);

  const BASE_ANALYTICS = "https://us.api.bland.ai/v1/analytics";

  const [eventView, setEventView] = useState(false);
  const [events, setEvents] = useState([]);

  const [selected, setSelected] = useState([]);
  const [analysisSidebar, setAnalysisSidebar] = useState(false);
  const [analysisQuestions, setAnalysisQuestions] = useState([
    {
      id: `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
      value: "",
      type: "Text",
    },
  ]);
  const [analysisResData, setAnalysisResData] = useState(null);
  const [loadingAnalysis, setLoadingAnalysis] = useState(false);
  const [loadingLookup, setLoadingLookup] = useState(false);
  const [uSp, setSearchParams] = useSearchParams();

  const { api_key } = getUserData();

  const performQuickAnalysis = async (transcripts) => {
    try {
      const response = await fetch(`/api/analysis/call`, {
        method: "POST",
        headers: {
          authorization: getAuthToken(),
        },
        body: JSON.stringify({ transcripts }),
      });

      if (response.ok && response.status === 200) {
        const data = await response.json();
        return data?.analysis;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  const performCallLookup = async (call = null, callId) => {
    try {
      let c = call || null;
      setLoadingLookup(true);
      const response = await fetch(`/api/user/lookup?id=${callId}`, {
        method: "GET",
        headers: {
          authorization: getAuthToken(),
        },
      });

      if (!c) {
        const callData = await fetchCalls(1, 50, [
          { column: "Call ID", operator: "equals", value: callId },
        ]);
        if (callData?.success && callData?.calls?.length === 1) {
          c = callData?.calls?.[0];
          try {
            c.variables = JSON.stringify(c?.variables);
          } catch (error) {}
        }
      }

      if (response.ok && response.status === 200) {
        const data = await response.json();
        const transcriptData = await fetchTranscript(call?.c_id, user.id);
        const mappedTScript =
          transcriptData?.transcript?.map((item) => ({
            role: item?.user,
            content: item?.text,
          })) || [];
        const b64EncodedMappedTranscripts = window.btoa(
          unescape(encodeURIComponent(JSON.stringify(mappedTScript))),
        );
        const quick_analysis = await performQuickAnalysis(
          b64EncodedMappedTranscripts,
        );
        setFocusedCall({
          call: c,
          errors: data?.errors,
          latency: data?.latency,
          transcripts: transcriptData?.transcript || [],
          quick_analysis,
          perfLatency: data?.perfLatency || null,
        });
      }

      setLoadingLookup(false);
    } catch (error) {
      setLoadingLookup(false);
    }
  };

  const callsToCsvAndDownload = async () => {
    //await fetchAllCalls();
    const allCalls5k = await loadCallLogs(5000, false);
    const labels = Object.keys(allCallsData[0]).join(",");
    const csv = allCalls5k
      .map((row) => {
        return Object.values(row)
          .map((value) => {
            if (typeof value === "object" && value !== null) {
              value = JSON.stringify({ ...value });
            }
            const cleanedValue = (value || "-").toString();
            return `"${cleanedValue.replace(/"/g, '""')}"`; // Escape any quotes in the data
          })
          .join(",");
      })
      .join("\n");
    const csvWithLabels = labels + "\n" + csv;
    const blob = new Blob([csvWithLabels], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `call-logs-${new Date().toISOString().slice(0, 10)}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const handleSelectionModelChange = (sm) => {
    setSelected((prevSelectedCallIds) => {
      const newSelectedCallIds = [...prevSelectedCallIds];

      // Remove any callIds that are no longer selected
      prevSelectedCallIds.forEach((callId) => {
        if (!sm.some((item) => unformattedData[item].c_id === callId)) {
          const index = newSelectedCallIds.indexOf(callId);
          if (index !== -1) {
            newSelectedCallIds.splice(index, 1);
          }
        }
      });

      // Add any new callIds that are selected
      sm.forEach((item) => {
        const callId = unformattedData[item].c_id;
        if (!newSelectedCallIds.includes(callId)) {
          newSelectedCallIds.push(callId);
        }
      });

      return newSelectedCallIds;
    });
  };

  const getEvents = async () => {
    const data = await fetch(`https://us.api.bland.ai/v1/events`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: api_key,
      },
    });
    const eventData = await data.json();

    const currentEventsCounts = events?.length;
    const newEventsCounts = eventData?.events.length;

    if (newEventsCounts !== currentEventsCounts || !currentEventsCounts) {
      containerRef.current?.scrollIntoView({ behavior: "smooth" });
    }

    if (eventData?.events) {
      setEvents(eventData.events);
    }
  };

  const handleUpdateQuestion = (idToUpdate, newValue, key) => {
    setAnalysisQuestions((prevQuestions) =>
      prevQuestions.map((question) =>
        question.id === idToUpdate
          ? { ...question, [key]: newValue }
          : question,
      ),
    );
  };

  const containerRef = useRef(null);

  const onFilterChange = useCallback((filters) => {
    setFilters(filters);
  }, []);

  const updateFilter = useCallback((index, field, value) => {
    setFilters((prevFilters) => {
      const newFilters = [...prevFilters];
      newFilters[index] = { ...newFilters[index], [field]: value };

      if (field === "column") {
        newFilters[index].operator = "";
        newFilters[index].value = "";
      }

      return newFilters;
    });
  }, []);

  const removeFilter = useCallback((index) => {
    setFilters((prevFilters) => prevFilters.filter((_, i) => i !== index));
    // loadCallLogs();
  }, []);

  // useEffect(() => {
  //   fetchCallHistoryMetrics();
  // }, [callHistoryMetrics.timeframe]);

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      if (eventView) {
        getEvents();
      }
    }, 5000);

    //Clearing the interval
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, eventView]);

  useEffect(() => {
    containerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [eventView]);

  useEffect(() => {
    if (!currPage) {
      navigate(`/dashboard?page=call-logs&currPage=1`);
    }
  }, [currPage, navigate]);

  useEffect(() => {
    setSaved(false);
  }, [filters]);

  const formatTableData = (data) => {
    if (!data || data?.length === 0) {
      setCalls([]);
      return [];
    }
    let tableData = [];
    for (const call of data) {
      // Remove unwanted keys
      const { objective, api_key, request_data, variables, ...rest } = call;
      // Reformat property names and values
      let formattedCallData = {
        Expand: (
          <div
            onClick={() => {
              setOpenCallData(true);
              setFocusedCall(call);
            }}
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            <img src={ExpandIcon} height={15} width={15} alt="expand" />
          </div>
        ),
        "Created On": toDtmy(new Date(rest.created_at)),
        Direction: rest?.inbound || false ? "Inbound" : "Outbound",
        To: rest.to,
        From: rest.from,
        Recording: (
          <AudioComponent
            recordingUrl={call.recording_url?.replace(".mp3", ".wav")}
          />
        ),
        "Call Length": convertToMinutesAndSeconds(rest.call_length),
        Cost: `$ ${rest.price ? parseFloat(rest.price).toFixed(2) : "0.00"}`,
        Status: rest.completed ? "Completed" : "In Progress",
        Summary: (
          <p
            onClick={() => {
              if (!call.summary) return;
              setFocusedSummary(call.summary);
              setOpenSummary(true);
            }}
            style={{ width: "100%" }}
          >
            {call.summary ? "View" : "N/A"}
          </p>
        ),
        "Pathway Logs": (
          <p
            onClick={() => {
              if (!call.pathway_logs) return;
              setFocusedPathwayLogs(call.pathway_logs);
              setOpenPathwayLogs(true);
            }}
            style={{ width: "100%" }}
          >
            {call.pathway_logs ? "View" : "N/A"}
          </p>
        ),

        Transcript: (
          <p
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "35px",
            }}
            onClick={() => {
              setOpenTranscript(
                (currentOpenTranscript) => !currentOpenTranscript,
              );
              navigate(
                `/dashboard?page=call-logs&currPage=${currPage}&callId=${call.c_id}`,
              );
            }}
          >
            View
          </p>
        ),
        Variables: (
          <p
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "35px",
            }}
            onClick={() => {
              setOpenVariables((currentOpenVariables) => !currentOpenVariables);
              navigate(
                `/dashboard?page=call-logs&currPage=${currPage}&callId=${call.c_id}`,
              );
            }}
          >
            View
          </p>
        ),
        "Call ID": rest.c_id,
        Metadata: (
          <p
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "35px",
            }}
            onClick={() => {
              setOpenMetadata((currentOpenMetadata) => !currentOpenMetadata);
              navigate(
                `/dashboard?page=call-logs&currPage=${currPage}&callId=${call.c_id}`,
              );
            }}
          >
            View
          </p>
        ),
        "Error Message": rest.error_message ? rest.error_message : <p>N/A</p>,
        "Answered By": rest.answered_by ? rest.answered_by : <p>N/A</p>,
        "Call Ended By": rest.call_ended_by ? rest.call_ended_by : <p>N/A</p>,
        "Batch ID": rest.batch_id ? rest.batch_id : <p>N/A</p>,
        "Pathway ID": rest.pathway_id ? rest.pathway_id : <p>N/A</p>,
        "Pathway Name": rest.pathway_name ? rest.pathway_name : <p>N/A</p>,
      };

      if (user.local_enabled) {
        formattedCallData["Local Dialing"] = rest.local_dialing
          ? "True"
          : "False";
      }

      // Set call length to 0 if it's not defined
      tableData.push(formattedCallData);
      setCalls(tableData);
      return tableData;
    }
  };

  const fetchAnalysis = async () => {
    try {
      setLoadingAnalysis(true);
      const queries = [];
      const query = {
        goal: "Run analysis as instructed based on the data provided.",
        call_ids: selected,
        analysis_schema: {},
      };

      for (const question of analysisQuestions) {
        const key = question?.value;
        const type = TYPE_MAPS.find((doc) => doc.label == question.type)?.value;
        query.analysis_schema[key] = type;
      }

      queries.push(query);

      const response = await fetch(
        "https://us.api.bland.ai/api/analytics/analysis",
        {
          method: "POST",
          headers: {
            authorization: api_key,
            "content-type": "application/json",
          },
          body: JSON.stringify({
            queries: queries,
          }),
        },
      );

      if (response.ok && response.status === 200) {
        const data = await response.json();
        if (data.status === "success") {
          setAnalysisResData({
            ...data?.data?.[0],
          });
        }
      }
      setLoadingAnalysis(false);
    } catch (error) {
      setLoadingAnalysis(false);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("ref_c_id")) {
      const refCId = urlParams.get("ref_c_id");
      urlParams.delete("ref_c_id");
      window.history.pushState(
        {},
        "",
        `${window.location.pathname}?${urlParams}`,
      );
      performCallLookup(null, refCId);
      setOpenCallData(true);
    }
  }, []);

  const [loadingCallData, setLoadingCallData] = useState(false);

  const parseFilters = () => {
    const oldFilters = [...filters];
    const qpfilters = {};

    oldFilters.forEach((filter) => {
      if (filter.column === "From" && filter.value) {
        qpfilters.from_number = filter.value;
      }

      if (filter.column === "To" && filter.value) {
        qpfilters.to_number = filter.value;
      }

      if (filter.column === "Answered By" && filter.value) {
        qpfilters.answered_by = filter.value;
      }

      if (filter.column === "Direction" && filter.value) {
        qpfilters.direction = filter.value;
      }

      if (filter.column === "Call ID" && filter.value) {
        qpfilters.call_id = filter.value;
      }

      if (filter.column === "Transferred To" && filter.value) {
        qpfilters.transferred_to = filter.value;
      }

      if (filter.column === "Batch ID" && filter.value) {
        qpfilters.batch_id = filter.value;
      }

      if (filter.column === "Pathway ID" && filter.value) {
        qpfilters.pathway_id = filter.value;
      }

      if (filter.column === "Pathway Name" && filter.value) {
        qpfilters.pathway_name = filter.value;
      }

      if (filter.column === "Recording" && filter.value) {
        qpfilters.record = filter.value;
      }

      if (filter.column === "Call Ended By" && filter.value) {
        qpfilters.call_ended_by = filter.value;
      }

      if (filter.column === "Error Message" && filter.value) {
        qpfilters.error_message = filter.value;
      }

      if (filter.column === "Cost" && filter.value && filter.operator) {
        if (filter.operator === "gt") {
          qpfilters.cost_gt = filter.value;
        } else if (filter.operator === "lt") {
          qpfilters.cost_lt = filter.value;
        }
      }

      if (filter.column === "Call Length" && filter.value && filter.operator) {
        if (filter.operator === "gt") {
          qpfilters.duration_gt = filter.value;
        } else if (filter.operator === "lt") {
          qpfilters.duration_lt = filter.value;
        }
      }

      // if (filter.column === "Record" && filter.value) {
      //   qpfilters.record = filter.value;
      // };
    });

    return qpfilters;
  };

  const loadCallLogs = async (pageSize = 25, setDataOnLoad = true) => {
    try {
      setLoadingCallData(true);
      const response = await fetch(
        `/api/user/logs?${new URLSearchParams({
          pageSize: pageSize,
          page: parseInt(currPage) || 1,
          ...parseFilters(),
        })}`,
        {
          method: "GET",
          headers: {
            Authorization: getAuthToken(),
          },
          // validateStatus: false
        },
      );

      setLoadingCallData(false);

      if (response.status === 200) {
        const data = await response.json();

        console.log("logs data", data);

        if (setDataOnLoad) {
          setRecordCount(data?.totalCount);
          setPageCount(data?.totalPages);
          setUnformattedData(data?.calls);
          formatTableData(data?.calls);
          const callIds = data.calls?.map((call) => call.c_id);
          setAllCallsIds(callIds);
          setAllCallsData(data?.calls);
          // setLoadingCallData(false);
          return data.calls;
        }
        return data.calls;
      }
    } catch (error) {
      setLoadingCallData(false);
    }
  };

  useEffect(() => {
    loadCallLogs();
  }, [currPage]);

  useEffect(() => {
    const options = {
      keys: ["from", "to", "call_id"], // Add more keys based on what you want to search for
    };
    const fuse = new Fuse(allCallsData, options);

    if (searchTerm.trim()) {
      const result = fuse
        .search(searchTerm)
        .slice(0, 50)
        .map(({ item }) => item);
      formatTableData(result);
    } else {
      formatTableData(unformattedData);
    }
  }, [searchTerm]);

  useEffect(() => {
    onFilterChange(filters);
  }, [onFilterChange]);

  return (
    <PageWrapper>
      <ActionBar top>
        <div className="flex justify-between items-center w-full">
          <PageTitle>Call Logs</PageTitle>
          <div className="flex items-center" style={{ columnGap: "10px" }}>
            <Button
              onClick={() => callsToCsvAndDownload()}
              appearance={"outline"}
              style={{ height: 35, borderRadius: 6 }}
            >
              Export Call Logs to CSV
            </Button>
            <Button
              submit
              onClick={() => {
                navigate("/dashboard?page=analytics&tab=realtime_logs");
              }}
              style={{ height: 35, borderRadius: 6 }}
            >
              Event Stream
            </Button>
            <Button
              onClick={() => {
                navigate("/dashboard?page=analytics&tab=calls");
              }}
              style={{ height: 35, borderRadius: 6 }}
            >
              Analytics
            </Button>
          </div>
        </div>
      </ActionBar>
      {/* Event Stream */}
      {eventView && (
        <EventStream>
          {events
            ?.slice()
            .reverse()
            .map((event, index) => (
              <div
                key={index}
                className="grid grid-cols-12 gap-4 w-full bg-black text-white p-4"
              >
                <p className="col-span-1 truncate">{event?.log_level}</p>
                <p className="col-span-5 truncate">{event?.message}</p>
                <p className="col-span-4">{event?.entity_id}</p>
                <p className="col-span-2">{event?.created_at.slice(11, 24)}</p>
                {/*<p className="col-span-1">{event?.category}</p>*/}
              </div>
            ))}
          <div ref={containerRef} />
        </EventStream>
      )}
      {!eventView && (
        // create a search bar here to filter calls
        <Box
          sx={{
            position: "fixed",
            overflowY: "scroll",
          }}
        >
          {!loading && (
            <div>
              <Flex gap={"3"} align={"center"} style={{ marginBottom: "15px" }}>
                <Popover.Root>
                  <Popover.Trigger>
                    <RadixButton variant="outline" type="button">
                      <Filter size={12} />
                      {filters.length > 0
                        ? `Filtered by ${filters.length} rules`
                        : "Add Filter"}
                    </RadixButton>
                  </Popover.Trigger>
                  <Popover.Content size={"1"} minWidth={"368px"}>
                    {filters.length === 0 ? (
                      <div>
                        <p style={{ fontSize: 12, fontWeight: "400" }}>
                          No Filters applied to this view
                        </p>
                        <p
                          style={{
                            fontSize: 10,
                            fontWeight: "300",
                            color: "GrayText",
                          }}
                        >
                          Add a column below to filter the view.
                        </p>
                      </div>
                    ) : (
                      <div className="space-y-4">
                        {filters.map((filter, index) => (
                          <div
                            key={index}
                            className="flex items-center space-x-2"
                          >
                            <SSelect
                              value={filter.column}
                              className="focus-visible:ring-transparent"
                              onValueChange={(value) => {
                                if (
                                  filters.find((item) => item.column === value)
                                ) {
                                  toast.error("Cannot use duplicate filters.");
                                  return removeFilter(index);
                                } else {
                                  updateFilter(index, "column", value);
                                }
                              }}
                            >
                              <SelectTrigger
                                className="w-[180px] h-[30px] focus:outline-none focus-visible:ring-transparent"
                                style={{
                                  border: "1px solid #eeeeee",
                                  fontSize: 11,
                                }}
                              >
                                <SelectValue placeholder="Select Field" />
                              </SelectTrigger>
                              <SelectContent>
                                {Object.keys(FILTER_CONFIG).map((field) => (
                                  <SelectItem key={field} value={field}>
                                    {field}
                                  </SelectItem>
                                ))}
                              </SelectContent>
                            </SSelect>

                            <SSelect
                              disabled={filter.column === ""}
                              value={filter.operator}
                              onValueChange={(value) =>
                                updateFilter(index, "operator", value)
                              }
                            >
                              <SelectTrigger
                                value={filter.operator}
                                className="w-[180px] h-[30px] focus-visible:ring-transparent"
                                style={{
                                  border: "1px solid #eeeeee",
                                  fontSize: 11,
                                }}
                              >
                                <SelectValue placeholder="Select Operator" />
                              </SelectTrigger>
                              <SelectContent>
                                {FILTER_CONFIG[filter?.column]?.operators?.map(
                                  (op) => (
                                    <SelectItem key={op} value={op}>
                                      {OPERATOR_LABELS[op]}
                                    </SelectItem>
                                  ),
                                )}
                              </SelectContent>
                            </SSelect>

                            <TextField.Root
                              style={{
                                height: 30,
                                border: "1px solid #eeeeee",
                                boxShadow: "none",
                                fontSize: 11,
                              }}
                              value={filter.value}
                              onChange={(ev) =>
                                updateFilter(index, "value", ev.target.value)
                              }
                              placeholder={
                                filter.column === "Recording"
                                  ? "True/False"
                                  : filter.column === "Direction"
                                    ? "inbound"
                                    : "Enter Value"
                              }
                              className="focus:outline-none border-none"
                            />

                            <IconButton
                              onClick={() => removeFilter(index)}
                              type="button"
                              variant="ghost"
                              color="gray"
                              size={"3"}
                              style={{ cursor: "pointer" }}
                            >
                              <X size={13} />
                            </IconButton>
                          </div>
                        ))}
                      </div>
                    )}

                    <Flex
                      direction={"row"}
                      justify={"between"}
                      align={"center"}
                      className="mt-[15px]"
                    >
                      <RadixButton
                        style={{ cursor: "pointer" }}
                        size={"1"}
                        color="gray"
                        variant="ghost"
                        onClick={() => {
                          setFilters((prevFilters) => [
                            ...prevFilters,
                            { column: "", operator: "", value: "" },
                          ]);
                        }}
                      >
                        <PlusIcon size={12} />
                        Add Filter
                      </RadixButton>
                      <RadixButton
                        style={{ cursor: "pointer" }}
                        variant="soft"
                        color="gray"
                        size={"1"}
                        type="button"
                        onClick={() => {
                          loadCallLogs();
                          setSearchParams((params) => {
                            params.set("currPage", 1);
                            return params;
                          });
                        }}
                      >
                        Apply Filters
                      </RadixButton>
                    </Flex>
                  </Popover.Content>
                </Popover.Root>

                {selected?.length > 0 && (
                  <RadixButton
                    onClick={() => setAnalysisSidebar(true)}
                    disabled={selected.length === 0}
                    size={"2"}
                    variant="outline"
                    color={analysisSidebar ? "iris" : "gray"}
                    style={{ cursor: "pointer" }}
                  >
                    <Icons.PlayIcon />
                    Run Analysis ({selected?.length})
                  </RadixButton>
                )}

                <RadixButton
                  onClick={() => navigate("/dashboard?page=analytics")}
                  size={"2"}
                  variant="soft"
                  style={{ cursor: "pointer" }}
                >
                  View Analytics & Call Insights
                  <Icons.ArrowRightIcon />
                </RadixButton>

                {asQpStore && (
                  <Badge size={"2"}>
                    <Icons.CrossCircledIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const urlParams = new URLSearchParams(
                          window.location.search,
                        );
                        if (urlParams.has("analysis_schema")) {
                          setAsQpStore(null);
                          urlParams.delete("analysis_schema");
                          const newUrl = `${
                            window.location.pathname
                          }?${urlParams.toString()}`;
                          window.history.replaceState({}, "", newUrl);
                        }
                      }}
                    />
                    Analysis Schema: {asQpStore ?? JSON.stringify(asQpStore)}
                  </Badge>
                )}
              </Flex>
            </div>
          )}
          {/* <TableView
            tableCalls={calls}
            recordCount={recordCount}
            pageCount={pageCount}
            currPage={currPage}
            loading={loading}
            pageSize={50}
          /> */}

          {/* {loadingCallData && (
            <div style={{ marginTop: 10 }}>
              <Skeleton height={"890px"} />
            </div>
          )} */}

          {calls && calls.length > 0 && (
            <AdvancedTable
              tableCalls={unformattedData}
              recordCount={recordCount}
              loading={loadingCallData}
              selected={selected}
              currPage={currPage - 1}
              pageSizeP={25}
              onChangeSelected={handleSelectionModelChange}
              onExpandedClicked={(call_data) => {
                setFocusedCall(null);
                performCallLookup(call_data, call_data?.c_id);
                setOpenCallData(true);
              }}
              onOpenModal={(modalName, callId) => {
                switch (modalName) {
                  case "variables":
                    const variables = unformattedData.find(
                      (call) => call.c_id === callId,
                    )?.variables;
                    setFocusedVariables(variables);
                    setOpenVariables(!openVariables);
                    break;
                  case "summary":
                    const summary = unformattedData.find(
                      (call) => call.c_id === callId,
                    )?.summary;
                    setFocusedSummary(summary);
                    setOpenSummary(!openSummary);
                    break;
                  case "pathway_logs":
                    const pathwayLogs = unformattedData.find(
                      (call) => call.c_id === callId,
                    )?.pathway_logs;
                    setFocusedPathwayLogs(pathwayLogs);
                    setOpenPathwayLogs(true);
                    break;
                  case "transcripts":
                    setOpenTranscript(!openTranscript);
                    setFocusedTranscriptCId(callId);
                    break;
                  default:
                    break;
                }
              }}
              focusedTranscriptCId={focusedTranscriptCId}
            />
          )}
        </Box>
      )}

      <Modal
        height={"auto"}
        maxWidth={"80%"}
        open={openCallData}
        onClose={() => setOpenCallData(false)}
      >
        <Call
          onClose={() => setOpenCallData(false)}
          loading={loadingLookup}
          call={focusedCall}
          modal
        />
      </Modal>

      {/* <Modal height={"auto"} maxWidth={"50%"} open={true} onClose={() => {}}>
        <div style={{}}>
          <div id='row' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <p className="text-2xl font-medium">Export Call Logs.</p>
            <div  style={{cursor:'pointer'}} onClick={() => {}}>
              <X />
            </div>
          </div>
          <p className="text-sm text-gray-500">Export your call logs to a CSV file.</p>
        </div>
      </Modal> */}

      <Modal open={openSummary} onClose={() => setOpenSummary(false)}>
        <Summary summary={focusedSummary} modal />
      </Modal>
      <Modal open={openPathwayLogs} onClose={() => setOpenPathwayLogs(false)}>
        <PathwayLogs callLogs={focusedPathwayLogs} modal />
      </Modal>
      <TranscriptSlideOut
        isOpen={openTranscript}
        onClose={() => setOpenTranscript(false)}
        callIds={allCallIds}
        initialCallId={focusedTranscriptCId}
        setFocusedTranscriptCId={setFocusedTranscriptCId}
      />
      <Modal open={openVariables} onClose={() => setOpenVariables(false)}>
        <Variables focusedVariables={focusedVariables} modal />
      </Modal>
      <Modal open={openMetadata} onClose={() => setOpenMetadata(false)}>
        <Metadata calls={unformattedData} modal />
      </Modal>

      {analysisSidebar && (
        <SidePopout>
          <div style={{ marginTop: 110 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                padding: "15px",
              }}
            >
              <div>
                <p style={{ fontWeight: "550", fontSize: 16 }}>Run Analysis</p>
                <p style={{ color: "gray" }}>
                  {selected.length} included in analysis.
                </p>
              </div>
              <IconButton
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setAnalysisResData(null);
                  setAnalysisSidebar(false);
                }}
                size={"1"}
                variant="surface"
                color="gray"
              >
                <Icons.Cross1Icon />
              </IconButton>
            </div>

            <div style={{ height: 1, width: "100%", background: "#eeeeee" }} />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                marginTop: "5px",
                padding: "15px",
              }}
            >
              {analysisQuestions?.map((question, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: "1%",
                  }}
                >
                  <TextField.Root
                    onChange={(event) =>
                      handleUpdateQuestion(
                        question.id,
                        event.target.value,
                        "value",
                      )
                    }
                    value={question.value}
                    placeholder="What was the sentiment of the person called?"
                    size={"1"}
                    style={{ width: "80%" }}
                  />
                  <DropdownMenu.Root>
                    <DropdownMenu.Trigger style={{ width: "20%" }}>
                      <RadixButton size={"1"} variant="outline" color="gray">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          {question.type}
                          <DropdownMenu.TriggerIcon />
                        </div>
                      </RadixButton>
                    </DropdownMenu.Trigger>
                    <DropdownMenu.Content size={"1"}>
                      {TYPE_MAPS.map((type, dni) => (
                        <DropdownMenu.Item
                          onClick={() => {
                            handleUpdateQuestion(
                              question.id,
                              type.label,
                              "type",
                            );
                          }}
                          key={dni}
                        >
                          {type.label}
                        </DropdownMenu.Item>
                      ))}
                    </DropdownMenu.Content>
                  </DropdownMenu.Root>
                  <IconButton
                    disabled={index === 0}
                    onClick={() => {
                      setAnalysisQuestions((prevState) => {
                        return prevState?.filter(
                          (doc) => doc.id !== question.id,
                        );
                      });
                    }}
                    variant="soft"
                    color="red"
                    size={"1"}
                  >
                    <Icons.TrashIcon />
                  </IconButton>
                </div>
              ))}

              <RadixButton
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setAnalysisQuestions([
                    ...analysisQuestions,
                    {
                      id: `${Date.now()}-${Math.random()
                        .toString(36)
                        .substr(2, 9)}`,
                      value: "",
                      type: "Text",
                    },
                  ]);
                }}
                size={"2"}
                variant="surface"
                color="gray"
              >
                <Icons.PlusIcon />
                Add New Analysis Item
              </RadixButton>

              <RadixButton
                loading={loadingAnalysis}
                style={{ cursor: "pointer" }}
                size={"2"}
                variant={"surface"}
                onClick={() => fetchAnalysis()}
              >
                Run Analysis
              </RadixButton>

              {analysisResData && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {Object.keys(analysisResData?.totals)?.map((key, idd) => {
                    const question = key;
                    const valuesObj = analysisResData?.totals[question];
                    const valueKeys = Object.keys(valuesObj);
                    return (
                      <Card key={idd} size={"1"}>
                        <div
                          style={{
                            borderBottom: "1.5px solid #e8e8e8",
                            paddingBottom: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <p style={{ fontSize: 14, fontWeight: "500" }}>
                            {question}
                          </p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "10px",
                          }}
                        >
                          {valueKeys?.map((valKey, iddkey) => (
                            <div
                              key={iddkey}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                                paddingBottom:
                                  valueKeys.length - 1 === iddkey
                                    ? "0px"
                                    : "10px",
                                borderBottom:
                                  valueKeys.length - 1 === iddkey
                                    ? "none"
                                    : "1px solid #eeeeee",
                              }}
                            >
                              <p style={{ maxWidth: "70%", overflowX: "clip" }}>
                                {valKey}
                              </p>
                              <p>{valuesObj[valKey]}</p>
                            </div>
                          ))}
                        </div>
                      </Card>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </SidePopout>
      )}
    </PageWrapper>
  );
}
const EventStream = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100vh;
  border-radius: 1rem;
`;

const SidePopout = styled.div`
  position: fixed;
  overflow-y: scroll;
  height: calc(100% + 54px);
  width: 40%;
  right: 0;
  max-width: 400px;
  top: -54px;
  border-left: 1px solid #eeeeee;
  background: #fff;
  transition: width 0.3s ease;
  z-index: 3999;

  @media (max-width: 1200px) {
    width: 30%;
  }

  @media (max-width: 900px) {
    width: 20%;
  }

  @media (max-width: 600px) {
    width: 15%;
  }

  @media (max-width: 400px) {
    width: 10%;
  }

  @media (max-width: 300px) {
    display: none; /* Hide SidePopout on very small screens */
  }
`;
