import { DataCard } from "components/Dashboard/CallLogs/Styles";
import React from "react";

const PathwayLogs = ({ callLogs, modal }) => {
  return (
    <DataCard
      modal={modal}
      style={{
        height: "400px",
        position: "relative",
      }}
    >
      <h2 className="font-bold mb-4 sticky top-0 bg-white z-10 p-2">
        Pathway Logs
      </h2>
      <div
        style={{
          height: "calc(100% - 40px)", // Adjust based on the height of your header
          overflowY: "scroll",
          position: "absolute",
          top: "40px", // Should match the height of your header
          left: 0,
          right: 0,
          bottom: 0,
        }}
      >
        {callLogs?.length === 0 ? (
          <div className="flex items-center justify-center h-full">
            <p className="text-center">No Pathway Logs</p>
          </div>
        ) : (
          callLogs?.map((log) => (
            <div
              key={log.id}
              className="p-2 border-b border-gray-200 last:border-b-0"
            >
              {log.text ? (
                <p>
                  <span
                    className={`text-black rounded px-2 py-1 text-center uppercase mr-2 ${
                      log?.role === "assistant" ? "bg-tertiary" : "bg-light"
                    } `}
                  >
                    {log.role}
                  </span>
                  : {log.text}
                </p>
              ) : log.pathway_info ? (
                <div>
                  <span className="text-black rounded px-2 py-1 text-center uppercase mr-2 bg-indigo-200">
                    PATHWAY INFO
                  </span>
                  {log.pathway_info &&
                    Object.keys(JSON.parse(log.pathway_info))?.map((key) => (
                      <p key={key} className="mt-1">
                        <span className="capitalize font-semibold">{key}:</span>{" "}
                        {JSON.stringify(JSON.parse(log.pathway_info)[key])}
                      </p>
                    ))}
                </div>
              ) : (
                log.decision && (
                  <div>
                    <span className="text-black rounded px-2 py-1 text-center uppercase mr-2 bg-indigo-200">
                      PATHWAY DECISION INFO
                    </span>
                    {log.decision &&
                      Object.keys(JSON.parse(log.decision))
                        ?.filter(
                          (key) =>
                            ![
                              "Current Node ID",
                              "Previous Node ID",
                              "Conversation History",
                            ].includes(key),
                        )
                        ?.map((key) => (
                          <p key={key} className="mt-1">
                            <span className="capitalize font-semibold">
                              {key}:
                            </span>{" "}
                            {JSON.stringify(JSON.parse(log.decision)[key])}
                          </p>
                        ))}
                  </div>
                )
              )}
            </div>
          ))
        )}
      </div>
    </DataCard>
  );
};

export default PathwayLogs;
