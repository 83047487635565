import {
  BugIcon,
  CreditCardIcon,
  KeyIcon,
  LogoutIcon,
  SettingsIcon,
} from "../../../assets/icons/navigationIcons";

export const accountItems = [
  {
    link: "/dashboard?page=billing-details",
    name: "Billing Details",
    icon: <CreditCardIcon />,
  },
  {
    link: "/dashboard?page=settings",
    name: "Account Settings",
    icon: <SettingsIcon />,
  },
  {
    link: "/dashboard?page=settings",
    name: "API Keys",
    icon: <KeyIcon />,
  },
  {
    link: "/dashboard?page=report",
    name: "Report Bug or Feature",
    icon: <BugIcon />,
  },
  {
    link: null,
    name: "Logout",
    icon: <LogoutIcon />,
  },
];
