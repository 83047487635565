import React from "react";
import Label from "./Label";
import Button from "./Button";
import styled from "styled-components";

const DragDropUpload = ({ onFilesAdded, uploadedFiles, label }) => {
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files).filter((file) =>
      file.type.startsWith("audio/"),
    );
    onFilesAdded(newFiles);
  };

  return (
    <div className="flex flex-col gap-2 w-full">
      {label && (
        <Label className="font-sm mb-1 mt-4 font-medium">{label}</Label>
      )}
      <DragDropWrapper onDragOver={handleDragOver} onDrop={handleDrop}>
        <p className="text-gray-700 mb-2">Drag and drop audio files here</p>
        <Button
          appearance="outline"
          className="bg-dark hover:bg-black text-white font-bold py-2 px-4 rounded"
          onClick={() => document.getElementById("file-upload").click()}
        >
          Select Files
        </Button>
        <input
          id="file-upload"
          type="file"
          multiple={true} // Enable multiple file selection
          accept="audio/*"
          onChange={(e) => onFilesAdded(Array.from(e.target.files))}
          className="hidden"
        />
        {uploadedFiles.length > 0 && (
          <div className="mt-4">
            <h3 className="text-dark mb-2">Uploaded Files:</h3>
            <ul>
              {uploadedFiles.map((file, index) => (
                <li key={index} className="text-sm text-gray-600">
                  {file.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </DragDropWrapper>
    </div>
  );
};

export default DragDropUpload;

const DragDropWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
  padding: 30px;
  align-items: center;
`;
