import * as yup from "yup";

export const loginSchema = yup.object().shape({
  // phone field
  phone: yup
    .string()
    .matches(/^(\d{10,13})$/, "Phone number must be 10 or 11 digits")
    .required("Phone is required"),
  // code field
  code: yup
    .string()
    // when isCodeStage = true, then require "code" field, otherwise make it optional.
    .when("isCodeStage", {
      is: true,
      then: () =>
        yup
          .string()
          .min(6, "Code must be at least 6 digits")
          .required("Code is required"),
      otherwise: () => yup.string().nullable(),
    }),
  // for conditionally requiring the "code" field
  isCodeStage: yup.boolean(),
});
