import { FaCopy, FaDollarSign, FaUsers, FaChartLine } from "react-icons/fa";

export default function Affiliate({ user }) {
  const affiliateDetails = [
    {
      label: "Lifetime Earnings",
      value: user?.affiliate_info?.lifetime_earnings || 0,
      icon: <FaDollarSign className="text-green-500" />,
      description: "Total earnings since joining the program",
    },
    {
      label: "Unredeemed Earnings",
      value: user?.affiliate_info?.unredeemed_earnings || 0,
      icon: <FaDollarSign className="text-blue-500" />,
      description: "Earnings available for redemption",
    },
    {
      label: "Redeemed Earnings",
      value: user?.affiliate_info?.redeemed_earnings || 0,
      icon: <FaDollarSign className="text-purple-500" />,
      description: "Total earnings you've already redeemed",
    },
    {
      label: "Referral Count",
      value: user?.affiliate_info?.earnings_count || 0,
      icon: <FaUsers className="text-orange-500" />,
      description: "Number of successful referrals",
    },
  ];

  return (
    <div className="flex flex-col space-y-8">
      {user?.affiliate_code && (
        <>
          <div className="flex flex-col space-y-5">
            <div className="flex flex-col space-y-2">
              <h6 className="text-xl font-semibold text-gray-800">
                Affiliate Link
              </h6>
              <p className="text-gray-600">
                Refer someone new to Bland using this link and they'll receive
                25% off their first credits purchase, and you'll receive 10% of
                their purchase credited to your account.
              </p>
            </div>
            <div className="flex justify-between items-center p-4 bg-gray-50 border border-gray-200 rounded-lg shadow-sm">
              <span className="text-sm font-medium text-gray-700 truncate">
                {`https://app.bland.ai/signup?ref=${user?.affiliate_code}`}
              </span>
              <button
                onClick={() => {
                  navigator.clipboard.writeText(
                    `https://app.bland.ai/signup?ref=${user?.affiliate_code}`,
                  );
                }}
                className="ml-2 p-2 text-gray-500 hover:text-gray-700 transition-colors duration-200"
              >
                <FaCopy />
              </button>
            </div>
          </div>
          {user?.affiliate_info && (
            <div className="space-y-4">
              <h6 className="text-xl font-semibold text-gray-800">
                Affiliate Statistics
              </h6>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {affiliateDetails.map((detail, index) => (
                  <div
                    key={index}
                    className="bg-white p-4 rounded-lg shadow-sm border border-gray-200 flex items-start space-x-4"
                  >
                    <div className="p-3 bg-gray-100 rounded-full">
                      {detail.icon}
                    </div>
                    <div className="flex-1">
                      <h3 className="text-lg font-medium text-gray-800">
                        {detail.label}
                      </h3>
                      <p className="text-2xl font-bold text-gray-900">
                        {detail.value}
                      </p>
                      <p className="text-sm text-gray-500 mt-1">
                        {detail.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className="bg-blue-50 border border-blue-200 rounded-lg p-4 flex items-center space-x-3">
                <FaChartLine className="text-blue-500" />
                <p className="text-sm text-blue-700">
                  Your affiliate performance is looking great! Keep sharing your
                  link to increase your earnings.
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
}
