import { useEffect, useState } from "react";
import { Loading } from "./Loading";
import { toast } from "react-toastify";
import styled from "styled-components";
import Pagination from "./Pagination";
import ActionBar from "./ActionBar";
import "react-toastify/dist/ReactToastify.css";

export default function Table({
  data,
  currPage,
  pageCount,
  recordCount,
  pgSize,
  fixedPagination,
  loading,
  layout,
}) {
  const [currentPage, setCurrentPage] = useState(currPage || 1);
  const [pageSize, setPageSize] = useState(pgSize || 50);
  const [totalPages, setTotalPages] = useState(pageCount || null);
  const [totalRecords, setTotalRecords] = useState(recordCount || null);
  const [chunkedData, setChunkedData] = useState([]);

  useEffect(() => {
    if (data && !currPage) {
      // if there is no currPage prop, then we can assume the data is already
      // in fetched in batches and therefore there is no need to calculate
      // the start and end index, and each fetch will provide use with the
      // chunked data.
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = Math.min(startIndex + pageSize, data.length);
      setChunkedData(
        data
          .slice(startIndex, endIndex)
          .map((row, index) => ({ "": startIndex + index + 1, ...row })),
      );
    } else {
      // data is already fetched in chunks, just set the chunked data to the chunkedData state.
      setChunkedData(data.map((row, index) => ({ "": index + 1, ...row })));
    }
  }, [data, currentPage, pageSize, currPage]);

  const handleCopyClick = (e, value) => {
    const ignoreValues = [
      "View",
      "Recommend",
      "Rated",
      "api.twilio.com",
      "N/A",
      "None",
      "[object Object]",
      "[{...}, {...}]",
    ];
    if (
      ignoreValues?.includes(value?.props?.children) ||
      ignoreValues?.includes(value?.toString())
    ) {
      return;
    } else {
      navigator.clipboard
        .writeText(value)
        .then(() => toast.success(`Copied "${value}" to clipboard!`));
    }
  };

  useEffect(() => {
    setTotalPages(pageCount || Math.ceil(data.length / pageSize));
    setTotalRecords(recordCount || data.length);
  }, [data, pageCount, recordCount, pageSize]);

  return (
    <>
      <Wrapper>
        {loading ? (
          <Loading loading={loading} />
        ) : (
          <TableWrapper>
            <MainTable layout={layout}>
              <Head>
                <Row>
                  {Object.keys(chunkedData[0] || {}).map((header, index) => (
                    <CellHead key={index}>{header}</CellHead>
                  ))}
                </Row>
              </Head>
              <Body>
                {chunkedData.map((row, rowIndex) => (
                  <Row key={rowIndex}>
                    {Object.values(row).map((value, index) => (
                      <CellBody
                        onClick={(e) => handleCopyClick(e, value)}
                        key={index}
                      >
                        <p style={{ paddingLeft: "9px" }}>{value}</p>
                      </CellBody>
                    ))}
                  </Row>
                ))}
              </Body>
            </MainTable>
          </TableWrapper>
        )}
        {!fixedPagination && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            pageSize={pageSize}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
            records={totalRecords}
          />
        )}
        {fixedPagination && (
          <ActionBar bottom>
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              pageSize={pageSize}
              setCurrentPage={setCurrentPage}
              setPageSize={setPageSize}
              records={totalRecords}
            />
          </ActionBar>
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: start;
  width: 100%;
`;

const TableWrapper = styled.div`
  overflow-x: auto;
  outline: 0.5px solid #eeeeee;
  border-radius: 5px;
  width: 100%;
`;

const MainTable = styled.table`
  width: 100%;
  height: 100%;
  outline: 0.5px solid #eeeeee;
  border-collapse: separate;
  border-spacing: 0;
  border-radius: 5px;
  table-layout: ${({ layout }) => (layout === "fixed" ? "fixed" : "auto")};
`;

const Head = styled.thead`
  height: 40px;
  position: sticky;
  background-color: #fbfbfa;
  top: -30px;
  z-index: 1;
`;

const Body = styled.tbody`
  overflow-x: hidden;
`;

const Row = styled.tr`
  height: 35px;
  & td:first-child {
    text-align: center;
    color: #686767;
    position: sticky;
    left: -30px;
    z-index: 1;
    background-color: white;
  }
  &:last-child {
    & > td:first-child {
      border-bottom-left-radius: 5px;
    }
    & > td:last-child {
      border-bottom-right-radius: 5px;
    }
  }
`;

const CellHead = styled.th`
  border: 0.5px solid #eeeeee;
  text-align: left;
  padding: 6px 9px;
  background-color: #fbfbfa;
  white-space: nowrap;
  overflow: hidden;
  &:first-child {
    width: 60px !important;
    min-width: 60px;
    border-top-left-radius: 5px;
    position: sticky;
    left: -30px;
  }
  &:last-child {
    border-top-right-radius: 5px;
  }
`;

const CellBody = styled.td`
  overflow: hidden;
  border: 0.5px solid #eeeeee;
  text-align: left;
  white-space: nowrap;
  color: #686767;
  height: 100%;
  cursor: pointer;
  padding: 0px 12px 0px 0px;
  &:first-child {
    width: 60px !important;
    min-width: 60px;
    padding: 9px 12px;
  }
  &:first-child:last-child {
    border-radius: 5px;
  }
  &:hover {
    background: #fbfbfa;
  }
`;
