import { ErrorText } from "components/core/ErrorText";
import { ErrorMessage } from "@hookform/error-message";
import Label from "components/core/Label";
import styled from "styled-components";
import Button from "components/core/Button";

export default function ModalInput({
  onChange,
  value,
  label,
  placeholder = null,
  description = null,
  submitBtn = false,
  isborder = true,
  submitBtnLabel = null,
  submitBtnLoading = false,
  submitBtnOnClick = null,
  methodDropdown = false,
  methodDropdownOnChange = null,
}) {
  return (
    <div className="flex flex-col gap-2 w-full">
      {label && <Label>{label}</Label>}
      {description && (
        <p className="text-secondary mb-2" style={{ fontSize: 12 }}>
          {description}
        </p>
      )}
      <div className="flex justify-between items-center">
        {methodDropdown && (
          <Select onChange={methodDropdownOnChange}>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
          </Select>
        )}
        <StyledInput
          style={{ border: isborder ? "1px solid #eeeeee" : "none" }}
          value={value}
          placeholder={placeholder ?? "Enter text..."}
          onChange={onChange}
        />
        {submitBtn && (
          <Button
            loading={submitBtnLoading}
            onClick={submitBtnOnClick}
            style={{
              backgroundColor: "#f35b04",
              width: "70px",
              height: "40px",
            }}
            className="btn"
          >
            {submitBtnLabel ? submitBtnLabel : "Submit"}
          </Button>
        )}
      </div>
      {/*<ErrorMessage
                render={({ message }) => <ErrorText message={message} />}
            />*/}
    </div>
  );
}

const StyledInput = styled.input`
  text-align: left;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  width: 100%;
  padding: 0px 12px;
  background-color: #ffffff;
  height: 40px !important;
  cursor: pointer;
  &::placeholder {
    color: #a8a8a1;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: text;
  }
  &:disabled {
    opacity: 45%;
    cursor: not-allowed;
  }
`;

const Select = styled.select`
  text-align: left;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  width: 100px;
  padding: 0px 12px;
  background-color: #fbfbfa;
  height: 40px !important;
  cursor: pointer;
  &::placeholder {
    color: #a8a8a1;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
  &:disabled {
    opacity: 45%;
    cursor: not-allowed;
  }
`;
