import Input from "components/core/Input";

export default function Label({ fieldName }) {
  return (
    <Input
      fieldName={fieldName}
      label="Batch Label"
      placeholder="Give this batch a name..."
    />
  );
}
