import axios from "axios";

export const uploadVoiceClone = async (files, voiceName, apiKey) => {
  try {
    const formData = new FormData();
    formData.append("name", voiceName);

    files.forEach((file, index) => {
      formData.append(`audio_samples`, file);
    });

    const { data } = await axios.post(
      "https://cloning.bland.ai/clone",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: apiKey,
        },
      },
    );
    return data;
  } catch (error) {
    console.error("Error uploading voice clone:", error);
    throw error;
  }
};
