import styled from "styled-components";
import Textarea from "components/core/Textarea";
import { useFormContext } from "react-hook-form";
import { telehealthTask } from "utils/data/prompts/telehealthTask";
import { smallBusinessTask } from "utils/data/prompts/smallBusinessTask";
import { stadiumTask } from "utils/data/prompts/stadiumTask";
import { salesTask } from "utils/data/prompts/salesTask";
import { Label } from "recharts";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import { blue } from "@mui/material/colors";
import Button from "components/core/Button";
import { Button as RadixButton } from "@radix-ui/themes";
import { File, Folder } from "lucide-react";

export default function PromptSendCallPage({
  fieldName,
  examples,
  autoResize = false,
}) {
  const { setValue, getValues } = useFormContext();

  const handleExampleClick = (task) => {
    setValue(fieldName, task);
  };

  const handleOpenPromptModal = () => {
    setValue("prompt_modal", true);
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center" style={{}}>
        <h3 style={{ color: "#444" }}>Prompt</h3>

        <span className="ml-auto">
          <a
            href="https://www.bland.ai/blog/prompting-guide-ai-phone-calls"
            target="_blank"
            className="text-blue-500 flex items-center"
          >
            <LaunchOutlinedIcon
              style={{ color: blue[500], marginRight: "4px" }}
            />
            Prompting Guide
          </a>
        </span>

        {(getValues("task")?.length || 0 > 0) && (
          <RadixButton
            type="button"
            style={{
              marginLeft: "25px",
              padding: "0px 12px",
              borderRadius: "5px",
            }}
            variant={"outline"}
            onClick={getValues("save_prompt")}
          >
            <p>Save Prompt</p>
          </RadixButton>
        )}
      </div>
      <div>
        <h6 style={{ color: "#999" }}>
          Try one of these pre-written options out to start
        </h6>
      </div>
      <div
        className="flex flex-row gap-2"
        style={{
          overflowX: "scroll",
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
        }}
      >
        <RadixButton
          style={{ cursor: "pointer" }}
          highContrast
          type="button"
          onClick={() => handleOpenPromptModal()}
          variant="surface"
          color="gray"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: 8,
            }}
          >
            <Folder size={13} />
            <p style={{ fontSize: 13, fontWeight: "500" }}>Saved prompts</p>
          </div>
        </RadixButton>
        <RadixButton
          style={{ cursor: "pointer" }}
          highContrast
          type="button"
          onClick={() => handleExampleClick(telehealthTask)}
          variant="surface"
          color="gray"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: 8,
            }}
          >
            <File size={13} />
            <p style={{ fontSize: 13, fontWeight: "500" }}>Telehealth</p>
          </div>
        </RadixButton>
        <RadixButton
          style={{ cursor: "pointer" }}
          highContrast
          type="button"
          onClick={() => handleExampleClick(smallBusinessTask)}
          variant="surface"
          color="gray"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: 8,
            }}
          >
            <File size={13} />
            <p style={{ fontSize: 13, fontWeight: "500" }}>Small business</p>
          </div>
        </RadixButton>
        <RadixButton
          style={{ cursor: "pointer" }}
          highContrast
          type="button"
          onClick={() => handleExampleClick(stadiumTask)}
          variant="surface"
          color="gray"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: 8,
            }}
          >
            <File size={13} />
            <p style={{ fontSize: 13, fontWeight: "500" }}>Stadium venues</p>
          </div>
        </RadixButton>
        <RadixButton
          style={{ cursor: "pointer" }}
          highContrast
          type="button"
          onClick={() => handleExampleClick(salesTask)}
          variant="surface"
          color="gray"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              columnGap: 8,
            }}
          >
            <File size={13} />
            <p style={{ fontSize: 13, fontWeight: "500" }}>Inbound sales</p>
          </div>
        </RadixButton>
      </div>

      <Textarea
        autoResize={autoResize}
        //label="Prompt"
        fieldName={fieldName}
        rows={10}
        placeholder="Enter a prompt for the AI..."
      />
    </div>
  );
}

const PromptPreset = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 4px 12px; /* Reduced padding */
  border: 1px solid #dddddd;
  border-radius: 4px;
  background-color: #f0f0f0;
  font-size: 12px;
  &:hover {
    background-color: #d0d0d0; /* Darker grey on hover */
    cursor: pointer;
  }
`;
