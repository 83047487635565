import { useEffect, useState } from "react";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { PageWrapper } from "components/core/PageWrapper";
import { PageTitle } from "components/core/PageTitle";
import { Loading } from "components/core/Loading";
import ViewPathway from "./ViewPathway";
import GeneratePathwayCard from "./Components/GeneratePathwayCard";
import axios from "axios";
import ActionBar from "components/core/ActionBar";
import Button from "components/core/Button";
import styled from "styled-components";
import TemplatePathwayCard from "./Components/template_card";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import NewFolderDialog from "./Components/createFolderDialog";
import DuplicatePathwayDialog from "./Components/duplicatePathwayDialog";
import DraggablePathwayCard from "./Components/DraggablePathwayCard";
import FolderSidebar from "./Components/FolderSidebar";
import { Loader2 } from "lucide-react";
import { motion } from "framer-motion";
import MovePathwayDialog from "./Components/MovePathwaysDialog";
import DeleteConfirmationDialog from "./Components/DeleteConfirmationDialog";

export default function ConvoPathways() {
  const [isTemplateCollapsed, setIsTemplateCollapsed] = useState(false);
  const [items, setItems] = useState([]);
  const [templatePathways, setTemplatePathways] = useState([]);
  const [open, setOpen] = useState(false);
  const [isTemplateDuplicate, setIsTemplateDuplicate] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [currId, setCurrId] = useState("");
  const [refreshPathways, setRefreshPathways] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const convoId = searchParams.get("id");
  const [selectedFolderId, setSelectedFolderId] = useState(null);
  const [openNewFolder, setOpenNewFolder] = useState(false);
  const [isMoving, setIsMoving] = useState(false);
  const [allPathways, setAllPathways] = useState([]);
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const navigate = useNavigate();

  const getFilteredItems = () => {
    if (!selectedFolderId) {
      // sort pathways by their created_at date in descending order
      const sortedPathways = allPathways.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return sortedPathways;
    }

    const findPathways = (items) => {
      let pathways = [];
      for (let item of items) {
        if (item.type === "folder" && item.id === selectedFolderId) {
          return item.children.filter((child) => child.type === "pathway");
        } else if (item.type === "folder" && item.children) {
          pathways = pathways.concat(findPathways(item.children));
        }
      }
      const sortedPathways = pathways.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return sortedPathways;
    };

    return findPathways(items);
  };

  const handleDeleteFolder = async (folderId) => {
    try {
      const response = await axios.post(
        "/api/convo_pathway/delete_folder",
        {
          folder_id: folderId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: localStorage.getItem("auth"),
          },
        },
      );

      if (response.status === 200) {
        // Update the local state
        setItems((prevItems) => removeFolderFromState(prevItems, folderId));
        toast.success("Folder deleted successfully");
      }
    } catch (error) {
      console.error("Error deleting folder:", error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Failed to delete folder");
      }
    }
  };

  // Helper function to remove the folder from the nested state structure
  const removeFolderFromState = (items, folderId) => {
    return items.filter((item) => {
      if (item.type === "folder") {
        if (item.id === folderId) {
          return false;
        } else if (item.children) {
          item.children = removeFolderFromState(item.children, folderId);
        }
      }
      return true;
    });
  };

  const handleEditFolder = async (folderId, newName) => {
    try {
      const response = await axios.post(
        "/api/convo_pathway/edit_folder",
        {
          folder_id: folderId,
          new_name: newName,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: window.localStorage.getItem("auth"),
          },
        },
      );

      if (response.status === 200) {
        setItems((prevItems) => updateFolderName(prevItems, folderId, newName));
        toast.success("Folder name updated successfully");
      }
    } catch (error) {
      console.error("Error updating folder name:", error);
      toast.error("Error updating folder name");
    }
  };

  const updateFolderName = (items, folderId, newName) => {
    return items.map((item) => {
      if (item.type === "folder") {
        if (item.id === folderId) {
          return { ...item, name: newName };
        } else if (item.children) {
          return {
            ...item,
            children: updateFolderName(item.children, folderId, newName),
          };
        }
      }
      return item;
    });
  };

  const findFolderById = (folders, folderId) => {
    for (const folder of folders) {
      if (folder.type === "folder" && folder.id === folderId) {
        return folder;
      }
      // If the folder has children, recursively search in the children
      if (folder.children && folder.children.length > 0) {
        const foundFolder = findFolderById(folder.children, folderId);
        if (foundFolder) {
          return foundFolder;
        }
      }
    }
    return null; // Return null if no folder is found
  };

  const createFolder = async (folderName) => {
    setIsCreatingFolder(true);
    try {
      const response = await axios.post(
        "/api/convo_pathway/create_folder",
        {
          name: folderName,
          parent_folder_id: selectedFolderId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: window.localStorage.getItem("auth"),
          },
        },
      );
      // Ensure the new folder has the correct structure
      const newFolder = {
        ...response.data.data,
        type: "folder",
        children: [],
      };

      setItems((prevItems) => {
        const insertFolder = (items) => {
          return items.map((item) => {
            if (item.type === "folder") {
              if (item.id === selectedFolderId) {
                // Insert the new folder into the children of the selected folder
                return {
                  ...item,
                  children: [...item.children, newFolder],
                };
              } else if (item.children) {
                // Recursively search in children
                return {
                  ...item,
                  children: insertFolder(item.children),
                };
              }
            }
            return item;
          });
        };

        // If no folder is selected, add the new folder at the root level
        if (!selectedFolderId) {
          return [...prevItems, newFolder];
        }

        // Otherwise, insert the folder at the correct nested location
        return insertFolder(prevItems);
      });
      setOpenNewFolder(false);
    } catch (error) {
      console.error("Error creating folder:", error);
      toast.error("Error creating folder");
    } finally {
      setIsCreatingFolder(false);
    }
  };

  const [movingPathwayId, setMovingPathwayId] = useState(null);

  const handleMovePathway = (pathwayId) => {
    setMovingPathwayId(pathwayId);
  };

  const handleMoveConfirm = async (pathwayId, folderId) => {
    if (pathwayId) {
      await movePathway(pathwayId, folderId);
      setMovingPathwayId(null);
    }
  };

  const movePathway = async (pathwayId, folderId) => {
    try {
      setIsMoving(true);
      const response = await axios.post(
        "/api/convo_pathway/move_pathway",
        {
          pathwayId,
          folderId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            authorization: window.localStorage.getItem("auth"),
          },
        },
      );

      if (response.status === 200) {
        const { pathwayId, oldFolderId, newFolderId } = response.data.data;

        setItems((prevItems) => {
          // Create a deep copy of the previous items
          const newItems = JSON.parse(JSON.stringify(prevItems));

          // Function to find and remove the pathway from its old location
          const removePathway = (items) => {
            for (let i = 0; i < items.length; i++) {
              if (items[i].type === "pathway" && items[i].id === pathwayId) {
                return items.splice(i, 1)[0];
              } else if (
                items[i].type === "folder" &&
                items[i].id === oldFolderId
              ) {
                return removePathway(items[i].children);
              } else if (items[i].type === "folder" && items[i].children) {
                const removed = removePathway(items[i].children);
                if (removed) return removed;
              }
            }
          };

          // Remove the pathway from its old location
          const pathwayToMove = removePathway(newItems);

          // Function to add the pathway to its new location
          const addPathway = (items) => {
            if (!newFolderId) {
              items.push(pathwayToMove);
              return true;
            }

            const updatedPathway = {
              ...pathwayToMove,
              folder_id: newFolderId,
            };

            for (let item of items) {
              if (item.type === "folder" && item.id === newFolderId) {
                item.children.push(updatedPathway);
                return true;
              } else if (item.type === "folder" && item.children) {
                if (addPathway(item.children)) return true;
              }
            }
            return false;
          };

          // Add the pathway to its new location
          addPathway(newItems);

          return newItems;
        });

        setAllPathways((prevAllPathways) => {
          const updatedAllPathways = prevAllPathways.map((pathway) =>
            pathway.id === pathwayId
              ? { ...pathway, folder_id: newFolderId }
              : pathway,
          );
          return updatedAllPathways;
        });

        toast.success("Pathway moved successfully");
      }
    } catch (error) {
      console.error("Error moving pathway:", error);
      toast.error("Error moving pathway");
    } finally {
      setIsMoving(false);
    }
  };

  const deletePathway = async (id) => {
    try {
      const response = await fetch("/api/convo_pathway/delete", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: window.localStorage.getItem("auth"),
        },
        body: JSON.stringify({
          id: id,
        }),
      });
      if (!response.ok) {
        // If the response is not ok, throw an error with the status
        throw new Error("Please try again later.");
      }

      // If we get here, the response was successful
      console.log("Pathway deleted successfully");
      setRefreshPathways(!refreshPathways);
      toast.success("Pathway deleted successfully");
    } catch (error) {
      console.error("Error deleting pathway:", error);
      toast.error(`Error deleting pathway: ${error.message}`);
    }
  };

  useEffect(() => {
    async function getTemplatePathways() {
      const response = await axios.get("/api/convo_pathway/get_template", {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setTemplatePathways(response.data.data);
    }

    async function getConvoPathways() {
      const response = await axios.get("/api/convo_pathway/get", {
        headers: {
          "Content-Type": "application/json",
          authorization: window.localStorage.getItem("auth"),
        },
      });
      setItems(response.data.data);

      const extractAllPathways = (items) => {
        let pathways = [];
        for (let item of items) {
          if (item.type === "pathway") {
            pathways.push(item);
          } else if (item.type === "folder" && item.children) {
            pathways = pathways.concat(extractAllPathways(item.children));
          }
        }
        return pathways;
      };

      setAllPathways(extractAllPathways(response.data.data));
    }

    const loadData = async () => {
      setIsLoading(true);
      await getTemplatePathways();
      await getConvoPathways();
      setIsLoading(false);
    };

    loadData();
  }, [refreshPathways]);

  const renderContent = () => {
    const filteredItems = getFilteredItems();

    if (!Array.isArray(filteredItems) || filteredItems.length === 0) {
      return (
        <div className="flex justify-center">
          <p className="text-lg text-secondary mt-16">
            You currently have no conversational pathways or folders
          </p>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {renderPathways(filteredItems)}
      </div>
    );
  };

  const renderPathways = (param_items, level = 0) => {
    return param_items
      .map((item) => {
        if (!item) {
          console.error("Encountered undefined item in renderPathways");
          return null;
        }

        if (typeof item !== "object") {
          console.error("Encountered non-object item in renderPathways:", item);
          return null;
        }

        if (!("type" in item)) {
          console.error("Item is missing 'type' property:", item);
          return null;
        }

        if (item.type === "pathway") {
          if (item.folder_id) {
          }
          return (
            <DraggablePathwayCard
              key={item.id}
              id={item.id}
              name={item.name}
              description={item.description}
              setCurrId={setCurrId}
              setOpenDelete={setOpenDelete}
              setIsDuplicate={setIsDuplicate}
              setOpen={setOpen}
              folder={findFolderById(items, item.folder_id)}
              onMovePathway={handleMovePathway}
              setRefreshPathways={setRefreshPathways}
            />
          );
        } else {
          console.error("Unknown item type:", item.type);
          return null;
        }
      })
      .filter(Boolean);
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {!convoId ? (
          <PageWrapper>
            <ActionBar top spaceBetween>
              <PageTitle>Conversational Pathways</PageTitle>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <Button
                  style={{
                    marginRight: "7px",
                    background: "#F9FAFB",
                    border: "2px solid #6366F1",
                    color: "#1D2024",
                  }}
                  onClick={() => navigate("/showcase/pathway")}
                >
                  Pathway Showcase
                </Button>
                <Button onClick={() => navigate("/generate-pathway")}>
                  Create Pathway
                </Button>
              </div>
            </ActionBar>
            <div className="flex mt-58">
              <FolderSidebar
                folders={items.filter((item) => item.type === "folder")}
                onCreateFolder={() => setOpenNewFolder(true)}
                onSelectFolder={(folder) => setSelectedFolderId(folder?.id)}
                selectedFolderId={selectedFolderId}
                onEditFolder={handleEditFolder}
                onMovePathway={movePathway}
                onDeleteFolder={handleDeleteFolder}
                isCreatingFolder={isCreatingFolder}
              />
              <div className="flex-1 overflow-auto p-8">
                {isMoving && (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="fixed inset-0 bg-gray-900/50 backdrop-blur-sm flex items-center justify-center z-50"
                  >
                    <motion.div
                      initial={{ scale: 0.9, opacity: 0 }}
                      animate={{ scale: 1, opacity: 1 }}
                      transition={{
                        type: "spring",
                        damping: 20,
                        stiffness: 300,
                      }}
                      className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full mx-4"
                    >
                      <div className="flex items-center space-x-4">
                        <Loader2 className="h-8 w-8 text-indigo-600 animate-spin" />
                        <div>
                          <h3 className="text-lg font-semibold text-gray-900">
                            Moving pathway
                          </h3>
                          <p className="text-sm text-gray-500">
                            Please wait while we process your request...
                          </p>
                        </div>
                      </div>
                      <div className="mt-4 overflow-hidden">
                        <motion.div className="h-1 w-full bg-gray-200 relative">
                          <motion.div
                            className="absolute top-0 left-0 bottom-0 bg-indigo-600"
                            initial={{ width: "40%", x: "-100%" }}
                            animate={{
                              x: ["0%", "100%"],
                            }}
                            transition={{
                              repeat: Infinity,
                              repeatType: "loop",
                              duration: 1.5,
                              ease: "linear",
                            }}
                          />
                        </motion.div>
                      </div>
                    </motion.div>
                  </motion.div>
                )}

                {isLoading ? (
                  <Loading loading={isLoading} />
                ) : (
                  <>
                    <section>
                      <div
                        className="flex mb-4 items-center cursor-pointer"
                        onClick={() =>
                          setIsTemplateCollapsed(!isTemplateCollapsed)
                        }
                      >
                        <h2 className="text-4xl font-bold text-gray-800">
                          Get Started
                        </h2>
                        {isTemplateCollapsed ? (
                          <IoMdArrowDropdown
                            className="text-indigo-600 text-2xl"
                            size={24}
                          />
                        ) : (
                          <IoMdArrowDropup
                            className="text-indigo-600 text-2xl"
                            size={24}
                          />
                        )}
                      </div>
                      {!isTemplateCollapsed && (
                        <Grid className="flex flex-row w-full flex-wrap mb-8">
                          {templatePathways.map((pathway, index) => (
                            <TemplatePathwayCard
                              key={index}
                              id={pathway.id}
                              name={pathway.name}
                              description={pathway.description}
                              setIsTemplateDuplicate={setIsTemplateDuplicate}
                              setCurrId={setCurrId}
                              setOpen={setOpen}
                            />
                          ))}
                          <GeneratePathwayCard />
                        </Grid>
                      )}
                    </section>
                    <section className="mt-12">
                      <div className="flex items-center mb-8">
                        <h2 className="text-4xl font-bold text-gray-800 mr-4">
                          Your Pathways
                        </h2>
                      </div>

                      {renderContent()}
                    </section>
                    <DuplicatePathwayDialog
                      open={open}
                      onClose={() => {
                        setOpen(false);
                        setIsDuplicate(false);
                        setIsTemplateDuplicate(false);
                      }}
                      isDuplicate={isDuplicate}
                      isTemplateDuplicate={isTemplateDuplicate}
                      currId={currId}
                      setRefreshPathways={setRefreshPathways}
                    />

                    <DeleteConfirmationDialog
                      isOpen={openDelete}
                      onClose={() => setOpenDelete(false)}
                      onDelete={async () => {
                        setIsDeleting(true);
                        await deletePathway(currId);
                        setOpenDelete(false);
                        setIsDeleting(false);
                      }}
                      isDeleting={isDeleting}
                    />
                    <NewFolderDialog
                      open={openNewFolder}
                      onClose={() => setOpenNewFolder(false)}
                      onCreate={createFolder}
                    />
                    <MovePathwayDialog
                      movingPathwayId={movingPathwayId}
                      isOpen={movingPathwayId !== null}
                      onClose={() => setMovingPathwayId(null)}
                      folders={items.filter((item) => item.type === "folder")}
                      onMove={handleMoveConfirm}
                    />
                  </>
                )}
              </div>
            </div>
          </PageWrapper>
        ) : (
          <ViewPathway />
        )}
      </DndProvider>
    </>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
`;
