import axios from "axios";
import { addPlus, sanitizePhone } from "../../../formatting/phone";

export const verifyOtp = async (
  code,
  phone,
  firstName,
  lastName,
  email,
  referrerCode,
) => {
  const sanitizedPhone = sanitizePhone(phone);
  const phoneE64 = addPlus(sanitizedPhone);

  try {
    // submit the code to twillio.
    const response = await axios.post("/api/signup/verifyOtp", {
      code: code,
      phoneNumber: phoneE64,
      signupData: {
        first_name: firstName,
        last_name: lastName,
        email: email,
        referrer_code: referrerCode,
      },
    });

    if (response.data.error) {
      console.log("Error in response data:", response.data.error);
      return {
        error: {
          message: response.data.error,
        },
      };
    }
    const { jwt } = response.data;

    return {
      recieved_code_sent: true,
      jwt: jwt,
    };
  } catch (error) {
    // set response & log any errors
    console.error("Caught error hitting /api/signup/verifyOtp:", error);
    return { error: error?.response?.data?.error };
  }
};
