import LineChart from "./latency_line_chart";
import styled from "styled-components";

export const ServiceStatus = ({ historical_latency }) => {
  if (!historical_latency || historical_latency?.length === 0) return <div />;

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <ChartBox>
        <LineChart
          data={historical_latency?.map((doc) => ({
            day: doc.day,
            val: doc.median_total,
          }))}
          chart_title={"Median Total Latency"}
          data_label={"Total Latency"}
          bgColor={"rgba(131, 56, 236, 0.7)"}
          borderColor={"rgba(131, 56, 236, 1)"}
        />
      </ChartBox>
      <ChartBox>
        <LineChart
          data={historical_latency?.map((doc) => ({
            day: doc.day,
            val: doc.median_text,
          }))}
          chart_title={"Median Text Latency"}
          data_label={"Text Latency"}
          bgColor={"rgba(32,119,254, 0.7)"}
          borderColor={"#2077fe"}
        />
      </ChartBox>
      <ChartBox>
        <LineChart
          data={historical_latency?.map((doc) => ({
            day: doc.day,
            val: doc.median_audio,
          }))}
          chart_title={"Median Audio Latency"}
          data_label={"Audio Latency"}
          bgColor={"rgba(205,153,1,0.7)"}
          borderColor={"#cd9901"}
        />
      </ChartBox>
    </div>
  );
};

const ChartBox = styled.div`
  width: 100%;
  padding: 20px;
  border: 2px solid #eeeeee;
  border-radius: 1px;
`;
