import styled from "styled-components";

export const PageWrapper = styled.div`
  height: auto;
  padding: ${({ sideMenu }) =>
    sideMenu ? "84px 30px 100px 30px" : "84px 30px 100px 84px"};
  overflow-y: scroll;
  position: relative;
  width: 100%;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;
