import React, { useState, useRef, useEffect, memo, useContext } from "react";
import FlowContext from "../contextFlow";
import {
  BaseEdge,
  EdgeLabelRenderer,
  getBezierPath,
  useReactFlow,
} from "reactflow";
import { EditIcon } from "assets/icons/navigationIcons";
import { MdSaveAlt } from "react-icons/md";

const CustomEdge = ({
  id,
  data,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  ...props
}) => {
  const setIsSideBarOpen = data?.setIsSideBarOpen ?? null;
  const {
    exportFlow,
    isEditingEdge,
    setIsEditingEdge,
    elements,
    triggerUpdate,
  } = useContext(FlowContext);
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const [inputLabel, setInputLabel] = useState(data.label);
  const [isExpanded, setIsExpanded] = useState(false);

  const isSelfConnecting = source === target;
  let edgePath, labelX, labelY;
  let circularEdge;

  const edges = elements.edges;

  useEffect(() => {
    setInputLabel(data.label);
  }, [data.label]);

  useEffect(() => {
    setIsExpanded(isEditingEdge === id);
  }, [isEditingEdge, id]);

  if (edges) {
    circularEdge = edges.find(
      (edge) =>
        edge.target === source && edge.source === target && edge.id !== id,
    );
  }

  const getEdgeOffset = () => {
    if (!edges) {
      // No edges from the current context. No need to calculate offset
      return 0;
    }
    const parallelEdges = edges.filter(
      (e) => e.source === source && e.target === target,
    );
    const index = parallelEdges.findIndex((e) => e.id === id);
    const maxOffset = 50; // Maximum offset in pixels
    const edgeCount = parallelEdges.length;

    if (edgeCount === 1) {
      return 0; // No offset for single edge
    } else if (edgeCount % 2 === 0) {
      // Even number of edges
      const position = index - (edgeCount - 1) / 2;
      return (position * maxOffset) / (edgeCount / 2);
    } else {
      // Odd number of edges
      const position = index - Math.floor(edgeCount / 2);
      return (position * maxOffset) / Math.floor(edgeCount / 2);
    }
  };

  const offset = getEdgeOffset();

  if (isSelfConnecting) {
    const radiusX = 30;
    const radiusY = 30;
    edgePath = `M ${sourceX - 5} ${sourceY} A ${radiusX} ${radiusY} 0 1 0 ${sourceX + 2} ${sourceY}`;
    labelX = sourceX;
    labelY = sourceY + 60;
  } else {
    [edgePath, labelX, labelY] = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
      curvature: 0.25,
    });
    if (circularEdge) {
      labelX = sourceX + 0.25 * (targetX - sourceX);
      labelY = sourceY + 0.25 * (targetY - sourceY);
    }
  }

  // Adjust path for offset
  if (!isSelfConnecting && offset !== 0) {
    const dx = targetX - sourceX;
    const dy = targetY - sourceY;
    const angle = Math.atan2(dy, dx);
    const offsetX = offset * Math.sin(angle);
    const offsetY = -offset * Math.cos(angle);

    sourceX += offsetX;
    sourceY += offsetY;
    targetX += offsetX;
    targetY += offsetY;

    [edgePath, labelX, labelY] = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
      curvature: 0.25,
    });
  }

  // Adjust label position for offset
  const adjustedLabelX = labelX + offset / 2;
  const adjustedLabelY = labelY - offset / 2;

  const handleEdit = () => {
    setIsEditingEdge(id);
    setIsExpanded(true);
    if (setIsSideBarOpen) {
      setIsSideBarOpen(true);
    }
  };

  const handleSubmit = () => {
    const updatedEdges = elements.edges.map((edge) => {
      if (edge.id === id) {
        return {
          ...edge,
          data: {
            ...edge.data,
            label: inputLabel.trim(),
          },
        };
      }
      return edge;
    });
    triggerUpdate({ edges: updatedEdges }, false);
    setIsEditingEdge(null);
    setIsExpanded(false);
    if (setIsSideBarOpen) {
      setIsSideBarOpen(false);
    }
  };

  useEffect(() => {
    if (isEditingEdge === id && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditingEdge, id]);

  const handleInputChange = (e) => {
    setInputLabel(e.target.value);
  };

  return (
    <>
      <BaseEdge
        id={id}
        path={edgePath}
        style={{
          // when isHighlighted is true, the edge will be thicker and shadowed
          strokeWidth: data.isHighlighted ? 3 : 1,
          stroke: data.isHighlighted ? "#2563EB" : "#A0AEC0",
        }}
        className={`${
          data.isHighlighted
            ? "stroke-blue-500 stroke-[3px] shadow-lg shadow-blue-200"
            : "stroke-blue-600 stroke-2"
        } transition-all duration-200`}
      />
      <EdgeLabelRenderer>
        <div
          ref={containerRef}
          className={`absolute ${
            data.condition ? "bg-slate-100" : "bg-white"
          } border ${
            data.isHighlighted
              ? "border-blue-500 ring-2 ring-blue-500 shadow-lg shadow-blue-200"
              : "border-gray-300"
          } transition-all duration-200`}
          style={{
            transform: `translate(-50%, -50%) translate(${adjustedLabelX}px,${adjustedLabelY}px)`,
            pointerEvents: "all",
            maxWidth: isExpanded ? "900px" : "200px",
          }}
        >
          {isExpanded ? (
            <div className="flex flex-col p-2">
              <textarea
                className="w-full bg-white px-3 py-4 text-sm border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
                ref={inputRef}
                value={inputLabel}
                onChange={handleInputChange}
                onKeyDown={(e) =>
                  e.key === "Enter" && !e.shiftKey && handleSubmit()
                }
                rows={3}
              />
              <button
                onClick={handleSubmit}
                className="mt-2 bg-blue-500 text-white px-3 py-1 text-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 flex items-center justify-center gap-2"
              >
                Save
                <MdSaveAlt size={14} />
              </button>
            </div>
          ) : (
            <div className="flex items-center justify-between px-3 py-1 space-x-2">
              <span
                className={`flex-auto text-sm font-medium ${
                  data.isHighlighted ? "text-blue-600" : "text-gray-700"
                } truncate max-w-[150px]`}
              >
                {inputLabel}
              </span>
              <button
                onClick={handleEdit}
                className="text-blue-600 hover:text-blue-700 focus:outline-none"
              >
                <EditIcon size={14} />
              </button>
            </div>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default memo(CustomEdge);
