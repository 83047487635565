import React from 'react';
import { Switch } from "@headlessui/react";

const SwitchOption = ({ checked, onChange, label, description }) => (
  <div className="flex items-center space-x-4 py-4 border-b border-gray-200 last:border-b-0">
    <Switch
      checked={checked}
      onChange={onChange}
      className={`${
        checked ? "bg-indigo-600" : "bg-gray-200"
      } relative inline-flex h-6 w-12 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
    >
      <span
        className={`${
          checked ? "translate-x-6" : "translate-x-1"
        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
      />
    </Switch>
    <div>
      <label className="text-base font-medium">{label}</label>
      <p className="text-sm text-gray-500">{description}</p>
    </div>
  </div>
);

export default SwitchOption;