import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { STRIPE_PUBLIC_KEY } from "config/stripe";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";
import axios from "axios";
import PaymentMethodForm from "./PaymentMethodForm";
import { Loading } from "components/core/Loading";

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export default function AddPaymentMethod() {
  const [clientSecret, setClientSecret] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchClientSecret = async () => {
      setLoading(true);
      const token = getAuthToken();
      const headers = {
        Authorization: `${token}`,
      };
      try {
        const res = await axios.post("/api/pay/createDetails", {}, { headers });
        setClientSecret(res.data?.clientSecret);
        if (res.data?.clientSecret) {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching client secret:", error);
        setLoading(false);
      }
    };

    fetchClientSecret();
  }, []);

  return (
    <>
      {loading || !clientSecret ? (
        <Loading loading={loading} />
      ) : (
        clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <PaymentMethodForm />
          </Elements>
        )
      )}
    </>
  );
}
