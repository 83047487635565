import axios from "axios";

export const generateEncryptedKey = async (
  api_key,
  account_sid,
  auth_token,
) => {
  const headers = {
    authorization: api_key,
  };
  try {
    console.log({ api_key, account_sid, auth_token });
    const { data } = await axios.post(
      `https://us.api.bland.ai/v1/accounts`,
      { account_sid, auth_token },
      { headers },
    );
    console.log("encrypted key", data);
    return data;
  } catch (error) {
    console.log("generate encrypted key", error);
    return error.message;
  }
};
