import React, { useState, useEffect } from "react";
import {
  X,
  RefreshCw,
  ChevronDown,
  ChevronUp,
  AlertTriangle,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { getUserData } from "utils/funcs/browser/getUserData";
import { toast } from "react-toastify";

const FlagEditModal = ({
  flaggedBySystem,
  graphID,
  callID,
  isOpen,
  onClose,
  decision,
  nodes,
  setChatConversationHistory,
  setChatStartNode,
  triggerUpdate,
}) => {
  const user = getUserData();
  const [isReportMode, setIsReportMode] = useState(false);
  const [expectedResponse, setExpectedResponse] = useState("");
  const [globalPrompt, setGlobalPrompt] = useState(
    decision["Global Prompt"] || "",
  );
  const [nodePrompt, setNodePrompt] = useState(
    decision["Current Node Prompt/Text"] || "",
  );
  const [dialogueExample, setDialogueExample] = useState(
    decision["Dialogue Example"] || "",
  );
  const [originalAssistantResponse, setOriginalAssistantResponse] = useState(
    decision["Assistant Response"] || "",
  );
  const [newAssistantResponse, setNewAssistantResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isGlobalPromptOpen, setIsGlobalPromptOpen] = useState(false);
  const [isDialogueExampleOpen, setIsDialogueExampleOpen] = useState(false);
  const [isNodePromptOpen, setIsNodePromptOpen] = useState(true);
  const [isConversationHistoryOpen, setIsConversationHistoryOpen] =
    useState(true);
  const [isFlagSubmitted, setIsFlagSubmitted] = useState(false);

  let history = "";
  if (decision["Conversation History"]) {
    for (let i = 0; i < decision["Conversation History"].length; i++) {
      history +=
        decision["Conversation History"][i].role +
        ": " +
        decision["Conversation History"][i].content.trim() +
        "\n";
    }
  }
  const [conversationHistory, setConversationHistory] = useState(history);

  const curr_node_id = decision["Current Node ID"];

  useEffect(() => {
    if (isOpen) {
      setIsReportMode(flaggedBySystem || false);
      setExpectedResponse("");
      setGlobalPrompt(decision["Global Prompt"] || "");
      setNodePrompt(decision["Current Node Prompt/Text"] || "");
      setDialogueExample(decision["Dialogue Example"] || "");
      setOriginalAssistantResponse(decision["Assistant Response"] || "");
      setNewAssistantResponse("");
    }
  }, [isOpen, decision]);

  const resetChat = () => {
    if (decision["Conversation History"]) {
      setChatConversationHistory(decision["Conversation History"]);
      setChatStartNode(curr_node_id);
    }
    onClose();
  };

  const handleSave = async () => {
    const updatedNodes = nodes.map((node) => {
      if (node.id === curr_node_id) {
        let clonedNode = structuredClone(node);
        return {
          ...clonedNode,
          data: {
            ...clonedNode.data,
            prompt: nodePrompt,
          },
        };
      } else {
        return node;
      }
    });

    triggerUpdate({ nodes: updatedNodes }, false);
    toast.success("Node Prompt Updated Successfully");
  };

  const handleUpdate = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/pathway/respond`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: user.api_key,
          },
          body: JSON.stringify({
            globalPrompt,
            dialoguePrompt: nodePrompt,
            dialogueExamples: dialogueExample,
            chatHistory: decision["Conversation History"],
          }),
        },
      );

      const data = await response.json();

      if (response.ok) {
        setNewAssistantResponse(data.response);
      } else {
        console.error("Error updating response:", data);
        toast.error("Failed to generate new response");
      }
    } catch (error) {
      console.error("Error updating response:", error);
      toast.error("Failed to generate new response");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmitFlag = async () => {
    const data = {
      call_id: callID,
      pathway_id: graphID,
      bad_output: originalAssistantResponse,
      expected_output: expectedResponse,
      global_prompt: globalPrompt,
      prompt: nodePrompt,
      dialogue_example: dialogueExample,
      chat_history: decision["Conversation History"],
    };

    console.log("data", data);

    const response = await fetch(
      `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/pathway/flag_node`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: user.api_key,
        },
        body: JSON.stringify(data),
      },
    );

    console.log("response", response);

    if (!response.ok) {
      console.error("Error flagging response:", response.statusText);
      toast.error("Failed to flag response");
      return;
    }
    toast.success("Response flagged successfully");
    setIsReportMode(false);
    setIsFlagSubmitted(true);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-6xl">
        <DialogHeader>
          {flaggedBySystem ? (
            <>
              <DialogTitle>
                <div className="flex items-center space-x-2">
                  <AlertTriangle className="w-5 h-5 text-yellow-500" />
                  <span>Potential Issue Detected</span>
                </div>
              </DialogTitle>
              <p className="text-sm text-gray-600 mt-2">
                We have detected a potential issue with this response, as it
                looks like it is not following the instructions provided in the
                node's prompt. Please review the response and flag it as
                incorrect if necessary, and provide the expected response. Our
                team will review and address the problem to improve the system's
                performance. Your feedback is valuable in enhancing the quality
                of responses.
              </p>
            </>
          ) : (
            <>
              <DialogTitle>Flag and Edit Response</DialogTitle>
              <p className="text-sm text-gray-600 mt-2">
                Reporting this field as incorrect will inform BlandAI about the
                issue. Our team will review and address the problem to improve
                the system's performance. Your feedback is valuable in enhancing
                the quality of responses.
              </p>
            </>
          )}
        </DialogHeader>
        <div className="pb-4">
          <div className="flex items-center space-x-2">
            <Checkbox
              id="report-mode"
              checked={isFlagSubmitted || isReportMode} // Show as checked if flag is submitted
              onCheckedChange={(checked) =>
                !isFlagSubmitted && setIsReportMode(checked)
              }
              disabled={isFlagSubmitted} // Disable if flag is submitted
            />
            <label
              htmlFor="report-mode"
              className={`text-sm ${isFlagSubmitted ? "text-gray-500" : "text-gray-700"}`}
            >
              {isFlagSubmitted
                ? "Flag Submitted"
                : "Flag this response as incorrect"}
            </label>
          </div>

          {isReportMode && (
            <div className="mt-4 grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Original Response:
                </label>
                <Textarea
                  value={originalAssistantResponse}
                  readOnly
                  className="w-full"
                  rows={4}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Expected Response:
                </label>
                <Textarea
                  value={expectedResponse}
                  onChange={(e) => setExpectedResponse(e.target.value)}
                  placeholder="Enter the expected response here..."
                  className="w-full"
                  rows={4}
                />
              </div>
            </div>
          )}

          {isReportMode && (
            <Button onClick={handleSubmitFlag} className="mt-2">
              Submit Flag
            </Button>
          )}

          <div className="space-y-4 mt-6 border-t pt-4">
            <DialogHeader>
              <DialogTitle>Edit Prompt and Generate Response</DialogTitle>
            </DialogHeader>
            <p className="text-sm text-gray-600">
              The following prompts are used in unison to generate the
              assistant's response at this node. You may edit each prompt, and
              test the effect on the generated response. You can then save the
              prompt directly from here, and continue testing the pathway from
              this node onwards.
            </p>
            <h3 className="text-lg font-semibold mb-2">Input Section</h3>
            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => setIsGlobalPromptOpen(!isGlobalPromptOpen)}
              >
                <label className="block text-sm font-medium text-gray-700">
                  Global Prompt:
                </label>
                {isGlobalPromptOpen ? (
                  <ChevronUp className="h-6 w-6" />
                ) : (
                  <ChevronDown className="h-6 w-6" />
                )}
              </div>
              {isGlobalPromptOpen && (
                <Textarea
                  placeholder="No global prompt provided..."
                  value={globalPrompt}
                  onChange={(e) => setGlobalPrompt(e.target.value)}
                  className="w-full mt-2"
                  rows={3}
                />
              )}
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => setIsNodePromptOpen(!isNodePromptOpen)}
              >
                <label className="block text-sm font-medium text-gray-700">
                  {" "}
                  Node Prompt: (Test a different prompt and view the response
                  immediately)
                </label>
                {isNodePromptOpen ? (
                  <ChevronUp className="h-6 w-6" />
                ) : (
                  <ChevronDown className="h-6 w-6" />
                )}
              </div>
              {isNodePromptOpen && (
                <Textarea
                  value={nodePrompt}
                  onChange={(e) => setNodePrompt(e.target.value)}
                  className="w-full mt-2"
                  rows={3}
                />
              )}
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() => setIsDialogueExampleOpen(!isDialogueExampleOpen)}
              >
                <label className="block text-sm font-medium text-gray-700">
                  Dialogue Example:
                </label>
                {isDialogueExampleOpen ? (
                  <ChevronUp className="h-6 w-6" />
                ) : (
                  <ChevronDown className="h-6 w-6" />
                )}
              </div>
              {isDialogueExampleOpen && (
                <Textarea
                  placeholder="No dialogue example provided"
                  value={dialogueExample}
                  onChange={(e) => setDialogueExample(e.target.value)}
                  className="w-full mt-2"
                  rows={3}
                />
              )}
            </div>

            <div>
              <div
                className="flex justify-between items-center cursor-pointer"
                onClick={() =>
                  setIsConversationHistoryOpen(!isConversationHistoryOpen)
                }
              >
                <label className="block text-sm font-medium text-gray-700">
                  Conversation History:
                </label>
                {isConversationHistoryOpen ? (
                  <ChevronUp className="h-6 w-6" />
                ) : (
                  <ChevronDown className="h-6 w-6" />
                )}
              </div>
              {isConversationHistoryOpen && (
                <Textarea
                  value={conversationHistory}
                  onChange={(e) => setConversationHistory(e.target.value)}
                  className="w-full"
                  rows={5}
                />
              )}
            </div>

            <Button onClick={handleUpdate} disabled={isLoading}>
              {isLoading ? (
                <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
              ) : (
                <RefreshCw className="mr-2 h-4 w-4" />
              )}
              Generate New Response
            </Button>
          </div>

          <div className="mt-6 border-t pt-4">
            <h3 className="text-lg font-semibold mb-2">Output Section</h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Original Assistant Response:
                </label>
                <Textarea
                  value={originalAssistantResponse}
                  className="w-full"
                  rows={5}
                  readOnly
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  New Assistant Response:
                </label>
                <Textarea
                  value={newAssistantResponse}
                  className="w-full"
                  rows={5}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>
        <DialogFooter
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Button variant="outline" onClick={onClose}>
              Cancel
            </Button>
          </div>
          <div className="flex gap-8">
            <Button onClick={handleSave} disabled={isLoading}>
              Save Prompt
            </Button>
            <Button onClick={resetChat} disabled={isLoading}>
              Test Pathway from here
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default FlagEditModal;
