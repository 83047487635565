import { Mixpanel } from "components/Mixpanel";
import { useRef, useState } from "react";
import { FaCheck, FaCopy, FaEye, FaEyeSlash } from "react-icons/fa";
import styled from "styled-components";

export default function ApiKey({ user }) {
  const [hidden, setHidden] = useState(true);
  const [copied, setCopied] = useState(false);
  const [generating, setGenerating] = useState(false);
  const textRef = useRef();

  const handleRevealClick = () => setHidden(!hidden);
  const handleCopyClick = () => {
    navigator.clipboard.writeText(user?.api_key).then(() => setCopied(true));
  };

  const hiddenApiKey =
    user?.api_key?.substring(0, 4) +
    " " +
    "·".repeat(user?.api_key?.length - 4);

  const GenerateNewKey = async () => {
    setGenerating(true);
    const headers = {
      Authorization: localStorage.getItem("auth"),
    };

    try {
      const data = await fetch("/api/user/newKey", {
        method: "POST",
        headers: headers,
      }).then((res) => res.json());

      if (data?.api_key) {
        Mixpanel.track("Generated new API key");
        user.api_key = data.api_key;
        setHidden(false);
        setCopied(false);
        setGenerating(false);
      }
    } catch (error) {
      console.error(error);
      setGenerating(false);
    }
  };

  return (
    <Wrapper>
      <div className="flex flex-col gap-3">
        <div className="text-2xl font-bold">API Key</div>
        <KeyWrapper>
          <Key ref={textRef}>{hidden ? `${hiddenApiKey}` : user?.api_key}</Key>
          <Actions>
            <div onClick={handleRevealClick} className="my-auto mr-2">
              {hidden ? <FaEye /> : <FaEyeSlash />}
            </div>
            <div onClick={handleCopyClick} className="my-auto ml-2">
              {copied ? <FaCheck /> : <FaCopy />}
            </div>
          </Actions>
        </KeyWrapper>
        <TipReplace>
          <p className="text-lg">Never share this publicly.</p>
          <Link
            onClick={() => {
              GenerateNewKey();
            }}
          >
            {generating ? "Replacing..." : "Replace Key"}
          </Link>
        </TipReplace>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;

const KeyWrapper = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  background-color: #fafafb;
  border: 1px solid #eeeeee;
  border-radius: 5px;
`;

const Key = styled.div`
  color: black;
  opacity: 50%;
  cursor: default;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  cursor: pointer;
  color: black;
  opacity: 60%;
`;

const Link = styled.div`
  color: black;
  font-weight: 500;
  font-size: 12px;
  cursor: pointer;
  &:hover {
  }
`;

const TipReplace = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
