import React, { useState, Fragment, useEffect } from "react";
import { Tab } from "@headlessui/react";
import { Dialog, Transition } from "@headlessui/react";
import {
  Plus,
  X,
  Phone,
  MessageSquare,
  ArrowLeftRight,
  Edit,
  Eye,
} from "lucide-react";
import { PageWrapper } from "components/core/PageWrapper";
import ActionBar from "components/core/ActionBar";
import { PageTitle } from "components/core/PageTitle";
import { FaSortNumericDownAlt, FaSortNumericUpAlt } from "react-icons/fa";
import { getUserData } from "utils/funcs/browser/getUserData";
import { toast } from "react-toastify";
import { Button, Slider, Spinner } from "@radix-ui/themes";
import usePhoneNumbers from "hooks/usePhoneNumbers";
import { Loading } from "components/core/Loading";

const EmptyState = ({ title, description, actionText, onAction }) => (
  <div className="text-center py-12">
    <h3 className="mt-2 text-[18px] font-semibold text-gray-900">{title}</h3>
    <p className="mt-1 text-[14px] text-gray-500">{description}</p>
    <div className="mt-6">
      <button
        type="button"
        onClick={onAction}
        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-[14px] font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        <Plus className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
        {actionText}
      </button>
    </div>
  </div>
);

const SMSDashboard = () => {
  const user = getUserData();
  const [conversations, setConversations] = useState([]);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingPhoneNumber, setEditingPhoneNumber] = useState(null);
  const [newNumber, setNewNumber] = useState("");
  const [newPrompt, setNewPrompt] = useState("");
  const [newWebhook, setNewWebhook] = useState("");
  const [newHumanMode, setNewHumanMode] = useState(false);
  const [newTemperature, setNewTemperature] = useState(1);
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: "ascending",
  });
  const [filterConfig, setFilterConfig] = useState({ from: "", to: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingFetch, setLoadingFetch] = useState(false);

  const { inboundNumbers, loading } = usePhoneNumbers();

  useEffect(() => {
    // fetch phone numbers
    const fetchPhoneNumbers = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/sms/numbers`,
        {
          headers: {
            Authorization: `${user.api_key}`,
          },
        },
      );
      const data = await response.json();

      if (data.status === "success" && data.message) {
        setPhoneNumbers(data?.message);
        await fetchSMSConversations(
          data?.message.map((phone) => phone.phone_number),
        );
      }
    };

    const fetchSMSConversations = async (phone_numbers) => {
      const response = await fetch("/api/sms/get_conversations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth"),
        },
        body: JSON.stringify({
          phone_numbers: phone_numbers,
        }),
      });

      const data = await response.json();

      if (data.status === "success") {
        const collatedConversations = collateConversations(data.data);
        setTotalPages(Number(((data?.count || 0) / 50)?.toFixed(0)));
        setConversations(collatedConversations);
        // setConversations(data.conversations);
      }
    };
    fetchPhoneNumbers();
  }, [user.api_key]);

  useEffect(() => {
    const fetchSMSConversations = async (phone_numbers, range) => {
      setLoadingFetch(true);
      const response = await fetch("/api/sms/get_conversations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth"),
        },
        body: JSON.stringify({
          phone_numbers: phone_numbers,
          from: range.from,
          to: range.to,
        }),
      });

      const data = await response.json();

      if (data.status === "success") {
        const collatedConversations = collateConversations(data.data);
        setTotalPages(Number(((data?.count || 0) / 49)?.toFixed(0)));
        setConversations(collatedConversations);
        // setConversations(data.conversations);
      }

      setLoadingFetch(false);
    };

    const pn = phoneNumbers?.map((item) => item.phone_number);
    const offset = (currentPage - 1) * 50;
    const range = { from: offset, to: offset + 49 };

    // console.log({pn});
    fetchSMSConversations(pn, range);
  }, [currentPage]);

  const collateConversations = (messages) => {
    const conversationMap = new Map();

    messages.forEach((message) => {
      const conversationKey = [message.from, message.to].sort().join("-");
      if (!conversationMap.has(conversationKey)) {
        conversationMap.set(conversationKey, {
          id: conversationKey,
          from: message.from,
          to: message.to,
          messages: [],
          lastMessage: "",
          timestamp: "",
          messageCount: 0,
        });
      }
      const conversation = conversationMap.get(conversationKey);
      conversation.messages.push(message);
      conversation.messageCount++;
    });

    return Array.from(conversationMap.values()).map((conversation) => {
      const lastMessage =
        conversation.messages[conversation.messages.length - 1];
      return {
        ...conversation,
        lastMessage: lastMessage.message,
        timestamp: lastMessage.created_at,
      };
    });
  };

  const addPhoneNumber = async () => {
    if (newNumber && newPrompt) {
      const newNumberResponse = await fetch(
        `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/sms/add/${newNumber}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${user.api_key}`,
          },
          body: JSON.stringify({
            prompt: newPrompt,
            webhook: newWebhook,
            human_mode: newHumanMode,
          }),
        },
      );

      const newNumberData = await newNumberResponse.json();

      setPhoneNumbers([
        ...phoneNumbers,
        {
          id: Date.now(),
          phone_number: newNumber,
          prompt: newPrompt,
          webhook: newWebhook,
          humanMode: newHumanMode,
        },
      ]);
      setNewNumber("");
      setNewPrompt("");
      setNewWebhook("");
      setNewHumanMode(false);
      setIsModalOpen(false);
    }
  };

  const editPhoneNumber = async () => {
    if (editingPhoneNumber) {
      setPhoneNumbers(
        phoneNumbers.map((phone) =>
          phone.phone_number === editingPhoneNumber.phone_number
            ? editingPhoneNumber
            : phone,
        ),
      );

      const editNumberResponse = await fetch(
        `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/sms/update`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${user.api_key}`,
          },
          body: JSON.stringify({
            prompt: editingPhoneNumber?.prompt,
            webhook: editingPhoneNumber?.webhook,
            human_mode: editingPhoneNumber?.humanMode,
            temperature: Number(editingPhoneNumber?.temperature || 0),
            phone_number: editingPhoneNumber.phone_number,
          }),
        },
      );

      const editNumberData = await editNumberResponse.json();

      if (editNumberData.status === "success") {
        toast("Phone number updated successfully", {
          type: "success",
          autoClose: 2000,
          position: "bottom-right",
        });
      } else {
        toast("Failed to update phone number", {
          type: "error",
          autoClose: 2000,
          position: "bottom-right",
        });
      }

      setEditingPhoneNumber(null);
      setIsModalOpen(false);
    }
  };

  const sortedConversations = React.useMemo(() => {
    let sortableItems = [...conversations];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === "ascending" ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [conversations, sortConfig]);

  const filteredConversations = React.useMemo(() => {
    return sortedConversations.filter(
      (conv) =>
        conv.from.includes(filterConfig.from) &&
        conv.to.includes(filterConfig.to),
    );
  }, [sortedConversations, filterConfig]);

  const requestSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <PageWrapper>
      <ActionBar top>
        <PageTitle>SMS</PageTitle>
      </ActionBar>

      <div className="h-screen flex flex-col bg-gray-50">
        <main className="flex-grow">
          <div className="mx-auto py-6 sm:px-6 lg:px-32">
            <Tab.Group>
              <Tab.List className="flex p-1 space-x-1 bg-gray-200 rounded-xl">
                <Tab
                  className={({ selected }) =>
                    `w-full py-2.5 text-[12px] leading-5 font-medium rounded-lg
                   focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-400 ring-white ring-opacity-60
                   ${selected ? "bg-white shadow text-indigo-700" : "text-gray-700 hover:bg-white/[0.12] hover:text-indigo-700"}`
                  }
                >
                  <div className="flex items-center justify-center">
                    <Phone className="w-5 h-5 mr-2" />
                    Phone Numbers
                  </div>
                </Tab>
                <Tab
                  className={({ selected }) =>
                    `w-full py-2.5 text-[12px] leading-5 font-medium rounded-lg
                   focus:outline-none focus:ring-2 ring-offset-2 ring-offset-indigo-400 ring-white ring-opacity-60
                   ${selected ? "bg-white shadow text-indigo-700" : "text-gray-700 hover:bg-white/[0.12] hover:text-indigo-700"}`
                  }
                >
                  <div className="flex items-center justify-center py-2">
                    <MessageSquare className="w-5 h-5 mr-2" />
                    Conversations
                  </div>
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-4">
                <Tab.Panel className="bg-white rounded-xl p-4 shadow-sm">
                  {phoneNumbers.length === 0 ? (
                    <EmptyState
                      title="No phone numbers added"
                      description="Add a phone number to start receiving messages."
                      actionText="Add Phone Number"
                      onAction={() => {
                        setEditingPhoneNumber(null);
                        setIsModalOpen(true);
                      }}
                    />
                  ) : (
                    <>
                      <div className="flex justify-between items-center mb-6">
                        <h2 className="text-[16px] font-semibold text-gray-800">
                          Phone Numbers
                        </h2>
                        <button
                          onClick={() => {
                            setEditingPhoneNumber(null);
                            setIsModalOpen(true);
                          }}
                          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition-colors"
                        >
                          <Plus className="h-5 w-5 mr-2 inline" />
                          Add Number
                        </button>
                      </div>
                      <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th className="px-6 py-3 text-left text-[11px] font-medium text-gray-500 uppercase tracking-wider">
                                Number
                              </th>
                              <th className="px-6 py-3 text-left text-[11px] font-medium text-gray-500 uppercase tracking-wider">
                                Prompt
                              </th>
                              <th className="px-6 py-3 text-left text-[11px] font-medium text-gray-500 uppercase tracking-wider">
                                Webhook
                              </th>
                              <th className="px-6 py-3 text-left text-[11px] font-medium text-gray-500 uppercase tracking-wider">
                                Temperature
                              </th>
                              <th className="px-6 py-3 text-left text-[11px] font-medium text-gray-500 uppercase tracking-wider">
                                Human Mode
                              </th>
                              <th className="px-6 py-3 text-left text-[11px] font-medium text-gray-500 uppercase tracking-wider">
                                Status
                              </th>
                              <th className="px-6 py-3 text-left text-[11px] font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white divide-y divide-gray-200">
                            {phoneNumbers.map((phone) => (
                              <tr
                                key={phone.id}
                                className="hover:bg-gray-50 transition-colors"
                              >
                                <td className="px-6 py-4 whitespace-nowrap text-[12px] text-gray-900 truncate max-w-72">
                                  {phone.phone_number}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-[12px] text-gray-500 truncate max-w-72">
                                  {phone.prompt}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-[12px] text-gray-500 truncate max-w-72">
                                  {phone.webhook}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-[12px] text-gray-500 truncate max-w-72">
                                  {phone.temperature}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-[12px] text-gray-500 truncate max-w-72">
                                  {phone?.humanMode ? "On" : "Off"}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-[12px]">
                                  {phone.a2p_verified ? (
                                    <span className="px-2 inline-flex text-[10px] leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                      Verified
                                    </span>
                                  ) : (
                                    <span className="px-2 inline-flex text-[10px] leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                      Not Verified
                                    </span>
                                  )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                  <button
                                    onClick={() => {
                                      setEditingPhoneNumber(phone);
                                      setIsModalOpen(true);
                                    }}
                                    className="text-indigo-600 hover:text-indigo-900"
                                  >
                                    <Edit className="h-5 w-5" />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}
                </Tab.Panel>
                <Tab.Panel className="bg-white rounded-xl p-4 shadow-sm">
                  {conversations.length === 0 ? (
                    <EmptyState
                      title="No conversations yet"
                      description="Start by adding a phone number to receive messages."
                      actionText="Add Phone Number"
                      onAction={() => {
                        setEditingPhoneNumber(null);
                        setIsModalOpen(true);
                      }}
                    />
                  ) : (
                    <>
                      <div className="flex justify-between items-center mb-6">
                        <h2 className="text-[16px] font-semibold text-gray-800">
                          Conversations
                        </h2>
                      </div>
                      <div className="overflow-x-auto max-h-[60vh] overflow-y-auto">
                        {" "}
                        {/* Add this class */}
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead className="bg-gray-50">
                            <tr>
                              <th className="px-6 py-3 text-left text-[11px] font-medium text-gray-500 uppercase tracking-wider">
                                From
                                <input
                                  className="ml-2 p-1 border rounded text-[10px]"
                                  placeholder="Filter From"
                                  value={filterConfig.from}
                                  onChange={(e) =>
                                    setFilterConfig({
                                      ...filterConfig,
                                      from: e.target.value,
                                    })
                                  }
                                />
                                <button
                                  onClick={() => requestSort("from")}
                                  className="ml-1"
                                >
                                  {sortConfig.key === "from" &&
                                  sortConfig.direction === "ascending" ? (
                                    <FaSortNumericDownAlt size={12} />
                                  ) : (
                                    <FaSortNumericUpAlt size={12} />
                                  )}
                                </button>
                              </th>
                              <th className="px-6 py-3 text-left text-[11px] font-medium text-gray-500 uppercase tracking-wider">
                                To
                                <input
                                  className="ml-2 p-1 border rounded text-[10px]"
                                  placeholder="Filter To"
                                  value={filterConfig.to}
                                  onChange={(e) =>
                                    setFilterConfig({
                                      ...filterConfig,
                                      to: e.target.value,
                                    })
                                  }
                                />
                                <button
                                  onClick={() => requestSort("to")}
                                  className="ml-1"
                                >
                                  {sortConfig.key === "to" &&
                                  sortConfig.direction === "ascending" ? (
                                    <FaSortNumericDownAlt size={12} />
                                  ) : (
                                    <FaSortNumericUpAlt size={12} />
                                  )}
                                </button>
                              </th>
                              <th className="px-6 py-3 text-left text-[11px] font-medium text-gray-500 uppercase tracking-wider">
                                Last Message
                              </th>
                              <th className="px-6 py-3 text-left text-[11px] font-medium text-gray-500 uppercase tracking-wider">
                                Timestamp
                                <button
                                  onClick={() => requestSort("timestamp")}
                                  className="ml-1 mt-1"
                                >
                                  {sortConfig.key === "timestamp" &&
                                  sortConfig.direction === "ascending" ? (
                                    <FaSortNumericDownAlt size={12} />
                                  ) : (
                                    <FaSortNumericUpAlt size={12} />
                                  )}
                                </button>
                              </th>
                              <th className="px-6 py-3 text-left text-[11px] font-medium text-gray-500 uppercase tracking-wider">
                                Messages
                              </th>
                              <th className="px-6 py-3 text-left text-[11px] font-medium text-gray-500 uppercase tracking-wider">
                                Actions
                              </th>
                            </tr>
                          </thead>
                          <tbody className="bg-white w-full divide-y divide-gray-200">
                            {!loadingFetch ? (
                              filteredConversations.map((conv) => (
                                <tr
                                  key={conv.id}
                                  className="hover:bg-gray-50 transition-colors"
                                >
                                  <td className="px-6 py-4 whitespace-nowrap text-[12px] text-gray-900">
                                    {conv.from}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-[12px] text-gray-900">
                                    {conv.to}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-[12px] text-gray-500 max-w-72 line-clamp-1 truncate">
                                    {conv.lastMessage}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-[12px] text-gray-500">
                                    {new Date(conv.timestamp).toLocaleString()}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap text-[12px] text-gray-500">
                                    {conv.messageCount}
                                  </td>
                                  <td className="px-6 py-4 whitespace-nowrap">
                                    <button
                                      onClick={() =>
                                        setSelectedConversation(conv)
                                      }
                                      className="text-indigo-600 hover:text-indigo-900 mr-2"
                                    >
                                      <Eye className="h-5 w-5" />
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr style={{ height: 553.19 }}>
                                <td
                                  colSpan="6"
                                  className="px-14 py-4 whitespace-nowrap text-[12px] text-gray-900"
                                >
                                  <div className="flex justify-center w-full">
                                    <Spinner
                                      size={"3"}
                                      style={{ marginLeft: 30 }}
                                    />
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div
                        id="footer"
                        className="flex justify-between items-center p-4"
                      >
                        <Button
                          onClick={() => setCurrentPage(currentPage - 1)}
                          disabled={currentPage === 1 || loadingFetch}
                          style={{ cursor: "pointer" }}
                        >
                          Previous
                        </Button>
                        <p
                          style={{
                            fontWeight: "500",
                            fontSize: 14,
                            color: "GrayText",
                          }}
                        >
                          {currentPage} of {totalPages}
                        </p>
                        <Button
                          onClick={() => setCurrentPage(currentPage + 1)}
                          disabled={currentPage === totalPages || loadingFetch}
                          style={{ cursor: "pointer" }}
                        >
                          Next
                        </Button>
                      </div>
                    </>
                  )}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </main>
        {selectedConversation && (
          <aside className="fixed right-0 top-[60px] w-1/3 h-[92%] bg-white border-l border-gray-200 flex flex-col shadow-lg">
            <div className="p-4 border-b border-gray-200 flex justify-between items-center">
              <div>
                <h3 className="font-semibold text-gray-900">Conversation</h3>
                <div className="flex items-center text-[12px] text-gray-500 mt-1">
                  <ArrowLeftRight className="h-4 w-4 mr-1" />
                  <span>
                    {selectedConversation.from} ↔ {selectedConversation.to}
                  </span>
                </div>
              </div>
              <button
                onClick={() => setSelectedConversation(null)}
                className="text-gray-500 hover:text-gray-700 focus:outline-none transition-colors"
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            <div className="flex-grow overflow-auto p-4 space-y-4">
              {selectedConversation.messages.map((msg) => (
                <div
                  key={msg.id}
                  className={`max-w-xs mx-2 p-3 rounded-lg ${
                    msg.sender === "USER"
                      ? "bg-gray-100 text-gray-800"
                      : "bg-indigo-500 text-white ml-auto"
                  }`}
                >
                  <p className="text-[12px]">{msg.message}</p>
                  <p
                    className={`text-[11px] mt-1 ${msg.sender === "USER" ? "text-gray-500" : "text-indigo-100"}`}
                  >
                    {new Date(msg.created_at).toLocaleString()}
                  </p>
                </div>
              ))}
            </div>
            <div className="p-4 border-t border-gray-200 flex items-center gap-4">
              <input
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-colors"
                disabled
                placeholder="Type a message..."
              />
              <button
                className="w-[80px] py-[6px] bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 transition-colors"
                disabled
              >
                Send
              </button>
            </div>
          </aside>
        )}
      </div>
      <Transition appear show={isModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsModalOpen(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-[14px] font-medium leading-6 text-gray-900 mb-4"
                  >
                    {editingPhoneNumber ? "Edit Number" : "Add New Number"}
                  </Dialog.Title>
                  <div className="space-y-4">
                    <div>
                      <label
                        htmlFor="phoneNumber"
                        className="block text-[12px] font-medium text-gray-700 mb-1"
                      >
                        Phone Number
                      </label>
                      {editingPhoneNumber ? (
                        <input
                          id="phoneNumber"
                          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                          value={editingPhoneNumber.phone_number}
                          disabled
                        />
                      ) : (
                        <>
                          {loading ? (
                            <Loading loading={loading} />
                          ) : (
                            <select
                              id="phoneNumber"
                              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                              value={newNumber}
                              onChange={(e) => setNewNumber(e.target.value)}
                            >
                              <option value="">Select a phone number</option>
                              {inboundNumbers &&
                                inboundNumbers.length > 0 &&
                                inboundNumbers
                                  .filter(
                                    (phone) => phone.phone_number !== null,
                                  )
                                  .map((phone) => (
                                    <option
                                      key={phone.phone_number}
                                      value={phone.phone_number}
                                    >
                                      {phone.phone_number}
                                    </option>
                                  ))}
                            </select>
                          )}
                        </>
                      )}
                    </div>
                    <div>
                      <label
                        htmlFor="prompt"
                        className="block text-[12px] font-medium text-gray-700 mb-1"
                      >
                        Prompt
                      </label>
                      <textarea
                        id="prompt"
                        className="w-full px-3 py-2 h-[200px] border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 align-text-top"
                        placeholder="Enter prompt"
                        value={
                          editingPhoneNumber
                            ? editingPhoneNumber.prompt
                            : newPrompt
                        }
                        onChange={(e) =>
                          editingPhoneNumber
                            ? setEditingPhoneNumber({
                                ...editingPhoneNumber,
                                prompt: e.target.value,
                              })
                            : setNewPrompt(e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="webhook"
                        className="block text-[12px] font-medium text-gray-700 mb-1"
                      >
                        Webhook URL
                      </label>
                      <input
                        id="webhook"
                        className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        placeholder="Enter webhook URL"
                        value={
                          editingPhoneNumber
                            ? editingPhoneNumber.webhook
                            : newWebhook
                        }
                        onChange={(e) =>
                          editingPhoneNumber
                            ? setEditingPhoneNumber({
                                ...editingPhoneNumber,
                                webhook: e.target.value,
                              })
                            : setNewWebhook(e.target.value)
                        }
                      />
                    </div>
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <label
                          htmlFor="webhook"
                          className="block text-[12px] font-medium text-gray-700 mb-1"
                        >
                          Temperature
                        </label>
                        <p>
                          {editingPhoneNumber
                            ? editingPhoneNumber.temperature
                            : newTemperature}
                        </p>
                      </div>
                      <input
                        onChange={(e) =>
                          editingPhoneNumber
                            ? setEditingPhoneNumber({
                                ...editingPhoneNumber,
                                temperature: e.target.value,
                              })
                            : setNewTemperature(e.target.value)
                        }
                        type="range"
                        id="slider"
                        min={0}
                        max={1.0}
                        step={0.1}
                        value={
                          editingPhoneNumber
                            ? editingPhoneNumber.temperature
                            : newTemperature
                        }
                        class="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer"
                      />
                    </div>
                    <div className="flex items-center">
                      <input
                        type="checkbox"
                        id="humanMode"
                        checked={
                          editingPhoneNumber
                            ? editingPhoneNumber.humanMode
                            : newHumanMode
                        }
                        onChange={(e) =>
                          editingPhoneNumber
                            ? setEditingPhoneNumber({
                                ...editingPhoneNumber,
                                humanMode: e.target.checked,
                              })
                            : setNewHumanMode(e.target.checked)
                        }
                        className="mr-2"
                      />
                      <label
                        htmlFor="humanMode"
                        className="text-[12px] text-gray-700"
                      >
                        Human Mode
                      </label>
                    </div>
                  </div>

                  <div className="mt-6 flex justify-end space-x-2">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-gray-200 px-4 py-2 text-[12px] font-medium text-gray-700 hover:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2"
                      onClick={() => setIsModalOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-[12px] font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                      onClick={
                        editingPhoneNumber ? editPhoneNumber : addPhoneNumber
                      }
                    >
                      {editingPhoneNumber ? "Save" : "Add"}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </PageWrapper>
  );
};

export default SMSDashboard;
