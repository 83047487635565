import Button from "../../core/Button";

export default function Welcome({ loading }) {
  // const user
  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-3">
        <h1 className="text-2xl text-center font-medium">Welcome to Bland!</h1>
        <p className="text-center">
          We've added $2 free testing credits to your account to get started.
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <Button loading={loading} submit>
          Hear from Bland AI
        </Button>
        <a className="text-center" href="/dashboard?page=home">
          Go to my dashboard
        </a>
      </div>
    </div>
  );
}
