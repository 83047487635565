import Table from "components/core/Table";

export default function TableView({
  tableCalls,
  recordCount,
  pageCount,
  pageSize,
  loading,
  currPage,
}) {
  return (
    <Table
      data={tableCalls}
      currPage={currPage}
      pgSize={pageSize}
      pageCount={pageCount}
      recordCount={recordCount}
      loading={loading}
      fixedPagination
    />
  );
}
