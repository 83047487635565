import styled from "styled-components";
import Button from "components/core/Button";
import Checkbox from "components/core/Checkbox";
import DragDropUpload from "components/core/DragDropUpload";
import Input from "components/core/Input";
import { Mixpanel } from "components/Mixpanel";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getUserData } from "utils/funcs/browser/getUserData";
import { uploadVoiceClone } from "utils/funcs/voices/uploadVoiceClone";
import { toast } from "react-toastify";

export default function CloneVoice() {
  const { user, api_key } = getUserData();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const methods = useForm();
  const isValid = methods.formState.isValid;
  const [loading, setLoading] = useState(false);

  const handleFilesAdded = (newFiles) => {
    const MAX_FILE_SIZE_MB = 11 * 1024 * 1024;
    if (newFiles[0].size > MAX_FILE_SIZE_MB) {
      toast.error(
        "File size exceeds 11MB limit. Please upload a smaller file or split the audio into smaller files.",
      );
      return;
    }
    const fileType = newFiles[0].type;
    const compatibleAudioTypes = [
      "audio/m4a",
      "audio/mp3",
      "audio/mpeg",
      "audio/x-m4a",
    ];
    if (!compatibleAudioTypes.includes(fileType?.toLowerCase())) {
      toast.error(
        `${fileType?.split("audio/")[1]?.toUpperCase() + " "}File type not supported. Please upload a compatible audio file (.m4a).`,
      );
      return;
    }
    setUploadedFiles((prevFiles) => [...prevFiles, ...newFiles]);
  };

  const formReady = isValid && uploadedFiles.length > 0;

  const onSubmit = async (formData) => {
    setLoading(true);
    try {
      const clonedVoice = await uploadVoiceClone(
        uploadedFiles,
        formData.voice_name,
        api_key,
      );
      console.log("Voice clone uploaded:", clonedVoice);
      toast.success("Voice clone created!");
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast("Error creating voice clone.", {
        type: "error",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    Mixpanel.track("Cloning page");
  }, []);

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <h2 className="font-bold text-center">Create a Voice Clone</h2>
        <p>Guidelines</p>
        <ul className="list-disc list-inside mb-4 text-secondary list-disc list-outside ms-8">
          <li>
            Audio files with a duration of about 5 minutes work best (min. 5
            minutes, max 1 hour)
          </li>
          <li>
            Recordings from a quiet environment will have the least artifacts
            and best quality.
          </li>
          <li>
            Speech patterns such as pauses, laughter, tone and inflection will
            transfer as well.
          </li>
          <li>
            Can upload Multiple Files. Each File size limit: 11MB <br />
            Format supported: .m4a / .mp3 / .mpeg / .x-m4a
          </li>
        </ul>
        <Input fieldName="voice_name" label="Voice Name" />
        <DragDropUpload
          label="File Upload"
          onFilesAdded={handleFilesAdded}
          uploadedFiles={uploadedFiles}
        />
        <Checkbox
          fieldName="tos_accepted"
          label="I have read and agree to the terms of service"
        />
        <Button submit disabled={!formReady} loading={loading}>
          Clone voice
        </Button>
      </Form>
    </FormProvider>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;
