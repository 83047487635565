import React, { useEffect, useMemo, useState } from "react";
import ReactFlow, { Background, Controls } from "reactflow";
import DefaultNode from "../../components/Dashboard/ConvoPathways/Components/nodes/node";
import CustomEdge from "../../components/Dashboard/ConvoPathways/Components/label_edge";

const PathwayFlow = ({ nodes: pathwayNodes, edges: pathwayEdges }) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);

  const edgeTypes = useMemo(() => ({ custom: CustomEdge }), []);

  const nodeTypes = useMemo(
    () => ({
      Default: (props) => <DefaultNode {...props} context="preview" />,
      "Knowledge Base": (props) => <DefaultNode {...props} context="preview" />,
      "Transfer Call": (props) => <DefaultNode {...props} context="preview" />,
      "End Call": (props) => <DefaultNode {...props} context="preview" />,
      Webhook: (props) => <DefaultNode {...props} context="preview" />,
      "Wait for Response": (props) => (
        <DefaultNode {...props} context="preview" />
      ),
      "Vector DB Knowledge Base": (props) => (
        <DefaultNode {...props} context="preview" />
      ),
      "Transfer Pathway": (props) => (
        <DefaultNode {...props} context="preview" />
      ),
      "Custom Tool": (props) => <DefaultNode {...props} context="preview" />,
      SMS: (props) => <DefaultNode {...props} context="preview" />,
      "Press Button": (props) => <DefaultNode {...props} context="preview" />,
    }),
    [],
  );

  useEffect(() => {
    async function fetchPathwayData() {
      // const res = await fetch("/api/convo_pathway/get_one", {
      //     method: "POST",
      //     body: JSON.stringify({id}),
      //     headers: {
      //         "Content-Type": "application/json"
      //     }
      // })

      // if (res.ok) {
      //     const data = await res.json()
      //     console.log("pathway data", data)
      //     if (!data.data) {
      //         console.error("No data returned from fetch")
      //         return
      //     }
      //     const pathway_data = data?.data[0]
      if (pathwayNodes && pathwayEdges) {
        setNodes(pathwayNodes);
        setEdges(pathwayEdges);
      } else {
        console.error("Failed to fetch pathway data");
      }
    }
    fetchPathwayData();
  }, [pathwayNodes, pathwayEdges]);

  return (
    <div
      className="w-full h-full max-w-full max-h-full m-6"
      style={{ width: "100%", height: "100%" }}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        fitView
        minZoom={0.1}
        edgeTypes={edgeTypes}
        proOptions={{ hideAttribution: true }}
      >
        <Background variant="dots" gap={12} size={1} />
        <Controls position="bottom-left" />
      </ReactFlow>
    </div>
  );
};

export default PathwayFlow;
