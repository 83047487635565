import styled from "styled-components";
import Textarea from "components/core/Textarea";

export default function SMSForm({ fieldName, autoResize = false }) {
  return (
    <>
      <Textarea
        autoResize={autoResize}
        label="SMS Tool - (Agent will use this tool when told to do so in the prompt, and send the below message to the user.)"
        fieldName={fieldName}
        rows={5}
        placeholder="Enter the SMS message to send..."
      />
    </>
  );
}
