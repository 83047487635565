import { Card } from "@/components/ui/card";
import { Button } from "@radix-ui/themes";
import React, { useEffect, useState } from "react";

function validateAndAggregatePhoneNumbers(phoneNumbers) {
  // Regular expression for E.164 format
  const e164Regex = /^\+[1-9]\d{1,14}$/;

  // Object to store invalid numbers by error type
  const invalidNumbers = {
    missingPlus: [],
    invalidCountryCode: [],
    tooShort: [],
    tooLong: [],
    containsNonDigits: [],
  };

  phoneNumbers.forEach((number) => {
    if (!number.startsWith("+")) {
      invalidNumbers.missingPlus.push(number);
    } else if (!/^\+[1-9]/.test(number)) {
      invalidNumbers.invalidCountryCode.push(number);
    } else if (number.length < 6) {
      // Minimum length: + and at least 5 digits
      invalidNumbers.tooShort.push(number);
    } else if (number.length > 16) {
      // Maximum length: + and 15 digits
      invalidNumbers.tooLong.push(number);
    } else if (!/^\+\d+$/.test(number)) {
      invalidNumbers.containsNonDigits.push(number);
    }
  });

  // Filter out empty categories
  const result = Object.fromEntries(
    Object.entries(invalidNumbers).filter(([_, numbers]) => numbers.length > 0),
  );

  return result;
}

const EditableSpreadsheet = ({ data, setData }) => {
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [errors, setErrors] = useState({});
  const [paginatedData, setPaginatedData] = useState([]);

  function validateE164PhoneNumber(phoneNumber) {
    // Regular expression for E.164 format
    const e164Regex = /^\+[1-9]\d{1,14}$/;

    return e164Regex.test(phoneNumber);
  }

  const handleChange = (id, field, value) => {
    const newData = data.map((row) => {
      if (row.id === id) {
        const updatedRow = { ...row, [field]: value };

        if (field === "phone_number") {
          if (!validateE164PhoneNumber(value)) {
            setErrors((prev) => ({
              ...prev,
              [id]: "Invalid phone number format. Use XXX-XXX-XXXX",
            }));
          } else {
            setErrors((prev) => {
              const newErrors = { ...prev };
              delete newErrors[id];
              return newErrors;
            });
          }
        }

        return updatedRow;
      }
      return row;
    });

    setData(newData);
  };

  useEffect(() => {
    const startIndex = page * pageSize;
    const endIndex = startIndex + pageSize;
    const paginatedData = data.slice(startIndex, endIndex);
    setPaginatedData(paginatedData);
  }, [page, data]);

  return (
    <div className="p-4">
      {Object.keys(errors).length > 0 && (
        <Card>
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">
            <strong className="font-bold">Errors:</strong>
            <ul className="list-disc list-inside">
              {Object.entries(
                validateAndAggregatePhoneNumbers(
                  data.map((row) => row.phone_number),
                ),
              ).map(([errorType, numbers]) => (
                <div key={errorType}>
                  <p>{errorType}:</p>
                  <ul>
                    {numbers?.map((number, index) => (
                      <li key={index}>
                        at ID:{" "}
                        {
                          paginatedData?.find(
                            (item) => item?.phone_number === number,
                          )?.id
                        }
                        , {number}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </ul>
          </div>
        </Card>
      )}

      <table
        className="w-full p-0 border mt-[10px]"
        style={{ overflowX: "scroll", maxWidth: "100%" }}
      >
        <thead>
          <tr className="w-[40px]">
            {Object.keys(data[0])?.map((key, i) => (
              <th
                key={i}
                className="bg-gray-200 border border-gray-300 p-2 text-left"
                style={{
                  textAlign: key === "id" ? "center" : "left",
                  width: key === "id" ? "70px" : "auto",
                }}
              >
                {key}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {paginatedData?.map((row) => (
            <tr
              key={row.id}
              className={
                !validateE164PhoneNumber(row?.phone_number) ? "bg-red-100" : ""
              }
              style={{ border: "1px solid #f3f3f3" }}
            >
              {Object.entries(row)?.map(([key, value], i) => (
                <td key={i} style={{ width: key === "id" ? "70px" : "auto" }}>
                  <input
                    disabled={key === "id"}
                    value={value}
                    onChange={(event) => {
                      console.log({
                        id: row.id,
                        key,
                        value: event.target.value,
                      });
                      handleChange(row.id, key, event.target.value);
                    }}
                    className="focus:outline-none p-3"
                    style={{
                      fontWeight: "400",
                      width: key === "id" ? "70px" : "100%",
                      textAlign: key === "id" ? "center" : "left",
                      fontWeight: key === "id" ? "550" : "400",
                    }}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="flex justify-between items-center mt-4">
        <Button
          style={{ cursor: "pointer" }}
          type="button"
          onClick={() => setPage(page - 1)}
          disabled={page === 0}
          className={`px-4 py-2 rounded ${page === 0 ? "bg-gray-300" : "bg-blue-500 text-white"}`}
        >
          Previous
        </Button>
        <span style={{ fontSize: 12, fontWeight: "600" }}>
          Page {page + 1} of {Math.ceil(data.length / pageSize)}
        </span>
        <Button
          style={{ cursor: "pointer" }}
          type="button"
          onClick={() => setPage(page + 1)}
          disabled={(page + 1) * pageSize >= data.length}
          className={`px-4 py-2 rounded ${(page + 1) * pageSize >= data.length ? "bg-gray-300" : "bg-blue-500 text-white"}`}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default EditableSpreadsheet;
