import { useEffect, useState } from "react";
import {
  AiOutlineCheckCircle,
  AiOutlineClose,
  AiOutlineExclamationCircle,
} from "react-icons/ai";
import { BiSolidErrorAlt } from "react-icons/bi";

export default function EventNotification({
  NotificationType,
  MessageHeader,
  MessageBody,
  dispatch,
  id,
}) {
  const [timeOutprogress, setTimeoutProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(null);
  const [exit, setExitStatus] = useState(true);

  const HandlePauseTimer = () => {
    console.log("CALLED");
    clearInterval(intervalId);
  };

  const HandleCloseNotification = () => {
    HandlePauseTimer();
    setExitStatus(true);
    setTimeout(() => {
      dispatch({
        type: "REMOVE_NOTIFICATION",
        id: id,
      });
    }, 3000);
  };

  const HandleStartTimer = () => {
    const id = setInterval(() => {
      setTimeoutProgress((prev) => {
        if (prev < 100) {
          return prev + 0.1;
        }

        clearInterval(id);
        return prev;
      });
    }, 30);
    setIntervalId(id);
  };

  useEffect(() => {
    if (timeOutprogress === 100 || timeOutprogress > 100) {
      HandleCloseNotification();
    }
  }, [dispatch, id, intervalId, timeOutprogress]);

  useEffect(() => {
    HandleStartTimer();
    setExitStatus(false);
  }, []);

  return (
    <div
      onMouseEnter={HandlePauseTimer}
      onMouseLeave={HandleStartTimer}
      className={`flex flex-col w-[calc(25vw)] pt-2 bg-gray-200 shadow-2xl shadow-gray-950 rounded-sm transition-all duration-300 relative ${
        !exit ? "ml-0" : "ml-[calc(120%)] z-[99999]"
      }`}
    >
      <div className="flex w-11/12 rounded-tr-sm rounded-tl-sm py-1">
        <div className="p-3 border-r-2 border-r-sub-medium rounded-bl-lg">
          {NotificationType === "ERROR" ? (
            <BiSolidErrorAlt className="text-3xl text-danger" />
          ) : null}
          {NotificationType === "SUCCESS" ? (
            <AiOutlineCheckCircle className="text-4xl text-success" />
          ) : null}
          {NotificationType === "INFO" ? (
            <AiOutlineExclamationCircle className="text-4xl text-information" />
          ) : null}
        </div>
        <div className="relative grow pl-3 my-auto">
          <div className="text-sm font-bold">{MessageHeader || "Head"}</div>
          <div className="text-sm fond-light text-gray-500 italic">
            {MessageBody || "This is the body"}
          </div>
        </div>
        <AiOutlineClose
          onClick={() => HandleCloseNotification()}
          className="absolute top-2 right-2 cursor-pointer"
        />
      </div>
      <div
        style={{
          width: `${timeOutprogress}%`,
        }}
        className={`h-1 rounded-bl-sm ${
          NotificationType === "ERROR"
            ? "bg-red-500"
            : NotificationType === "SUCCESS"
              ? "bg-green-200"
              : "bg-blue-200"
        }`}
      ></div>
    </div>
  );
}
