import styled from "styled-components";
import Subscriptions from "./Subscriptions";
import AutoRecharge from "./AutoRecharge";
import PaymentMethod from "./PaymentMethod";
import PaymentHistory from "./PaymentHistory";
import ActionBar from "components/core/ActionBar";
import { PageWrapper } from "../../core/PageWrapper";
import { Divider } from "../../core/Divider";
import { PageTitle } from "components/core/PageTitle";

export default function Billing() {
  return (
    <PageWrapper>
      <Content>
        <ActionBar top>
          <PageTitle>Billing Details</PageTitle>
        </ActionBar>
        <Section>
          <PaymentMethod />
        </Section>
        <Divider />
        <Section>
          <AutoRecharge />
        </Section>
        <Divider />
        <Section>
          <PaymentHistory />
        </Section>
        <Divider />
        <Section>
          <Subscriptions />
        </Section>
        <Divider />
      </Content>
    </PageWrapper>
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
