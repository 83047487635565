import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getUserData } from "../../../utils/funcs/browser/getUserData";
import { cancelRecharge } from "../../../utils/funcs/billing/cancelRecharge";
import { updateRechargeAmount } from "../../../utils/funcs/billing/updateRechargeAmount";
import { Switch } from "@headlessui/react";
import { Transition } from "@headlessui/react";

const Input = ({ fieldName, label, placeholder, type, register }) => (
  <div className="flex flex-col space-y-1">
    <label htmlFor={fieldName} className="text-sm font-medium text-gray-700">
      {label}
    </label>
    <input
      id={fieldName}
      type={type}
      placeholder={placeholder}
      {...register(fieldName)}
      className="px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
    />
  </div>
);

const Button = ({ children, loading, ...props }) => (
  <button
    {...props}
    className="inline-flex justify-center items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition duration-150 ease-in-out"
    disabled={loading}
  >
    {loading ? (
      <svg
        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          className="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    ) : null}
    {children}
  </button>
);

const Message = ({ show, message, type }) => (
  <Transition
    show={show}
    enter="transition-opacity duration-75"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition-opacity duration-150"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div
      className={`fixed bottom-4 right-4 ${type === "success" ? "bg-green-500" : "bg-red-500"} text-white px-4 py-2 rounded-md shadow-lg`}
    >
      {message}
    </div>
  </Transition>
);

export default function AutoRecharge() {
  const [loading, setLoading] = useState(false);
  const [userLoading, setUserLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [userData, setUserData] = useState(null);

  const methods = useForm({
    defaultValues: {
      recharge_enabled: false,
      refill_amt: 0,
    },
  });

  const { register, handleSubmit, watch, setValue } = methods;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await getUserData();
        console.log(data);
        setUserData(data);
        setValue("recharge_enabled", data.public_billing_data.refill_to > 0);
        setValue("refill_amt", data.public_billing_data.refill_to);
      } catch (error) {
        console.error("Failed to fetch user data:", error);
        setShowError(true);
        setTimeout(() => setShowError(false), 3000);
      } finally {
        setUserLoading(false);
      }
    };

    fetchUserData();
  }, [setValue]);

  const rechargeEnabled = watch("recharge_enabled");
  const currRefillAmt = watch("refill_amt");

  useEffect(() => {
    if (!userLoading && userData) {
      const updateRecharge = async () => {
        try {
          if (rechargeEnabled) {
            await updateRechargeAmount(
              parseInt(currRefillAmt),
              userData.user.id,
            );
          } else {
            await cancelRecharge(userData.user.id);
          }
        } catch (error) {
          console.error("Failed to update recharge settings:", error);
          setShowError(true);
          setTimeout(() => setShowError(false), 3000);
        }
      };

      updateRecharge();
    }
  }, [rechargeEnabled, currRefillAmt, userData, userLoading]);

  const onSaveRefill = async (formData) => {
    setLoading(true);
    try {
      await updateRechargeAmount(
        parseInt(formData.refill_amt),
        userData.user.id,
      );
      setShowSuccess(true);
      setTimeout(() => setShowSuccess(false), 3000);
    } catch (error) {
      console.error("Failed to update recharge amount:", error);
      setShowError(true);
      setTimeout(() => setShowError(false), 3000);
    } finally {
      setLoading(false);
    }
  };

  if (userLoading) {
    return (
      <div className="flex justify-center items-center h-24">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSaveRefill)} className="space-y-6">
        <div className="flex items-center justify-between">
          <h5 className="text-lg font-semibold text-gray-900">Auto Recharge</h5>
          <Switch
            checked={rechargeEnabled}
            onChange={(checked) => setValue("recharge_enabled", checked)}
            className={`${
              rechargeEnabled ? "bg-indigo-600" : "bg-gray-200"
            } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
          >
            <span
              className={`${
                rechargeEnabled ? "translate-x-6" : "translate-x-1"
              } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
            />
          </Switch>
        </div>
        {rechargeEnabled && (
          <div className="flex items-end space-x-4">
            <div className="flex-grow">
              <Input
                type="number"
                fieldName="refill_amt"
                label="Refill Amount ($)"
                placeholder="0.00"
                register={register}
              />
            </div>
            <Button type="submit" loading={loading}>
              {loading ? "Saving..." : "Save"}
            </Button>
          </div>
        )}
      </form>
      <Message
        show={showSuccess}
        message="Refill amount saved successfully!"
        type="success"
      />
      <Message
        show={showError}
        message="An error occurred. Please try again."
        type="error"
      />
    </FormProvider>
  );
}
