import React from "react";
import styled from "styled-components";

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ccc;
`;

const TableCell = styled.td`
  padding: 10px;
  text-align: left;
`;

const CampaignTable = ({ data }) => {
  return (
    <Table>
      <tbody>
        {data.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <TableCell key={`${rowIndex}-${cellIndex}`}>
                {Array.isArray(cell)
                  ? cell.map((subCell, subCellIndex) => (
                      <div key={`${rowIndex}-${cellIndex}-${subCellIndex}`}>
                        {subCell}
                      </div>
                    ))
                  : cell}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

export default CampaignTable;
