import { PageTitle } from "components/core/PageTitle";
import styled from "styled-components";
import ActivityBarGraph from "../../CallLogs/ActivityBarGraph";
import { formatNumberWithCommas } from "../../CallLogs/CallActivityBox";
import { Button, Card, IconButton, Text } from "@radix-ui/themes";
import * as Icons from "@radix-ui/react-icons";

export default function CallAnalytics({
  data,
  tooltip,
  value_label,
  icon,
  onClickView,
  viewBtn = true,
  calc_type = "sum",
  grouping = "day",
}) {
  return (
    <Wrapper>
      <div style={{ display: "flex", alignItems: "center", marginBottom: 0 }}>
        <IconButton disabled variant="surface">
          {icon}
        </IconButton>
        <div style={{ marginLeft: 15 }}>
          <PageTitle style={{ fontSize: 16, fontWeight: "450" }}>
            {data.label}
          </PageTitle>
          <p style={{ color: "grey", fontWeight: "450", fontSize: 12 }}>
            {calc_type === "sum"
              ? formatNumberWithCommas(
                  data?.chartData
                    ?.reduce((acc, curr) => acc + curr?.value, 0)
                    ?.toFixed(0) || 0,
                )
              : calc_type === "avg"
                ? Number(
                    data?.chartData?.reduce(
                      (acc, curr) => acc + curr?.value,
                      0,
                    ) / data?.chartData?.length,
                  )?.toFixed(2) || 0
                : "N/A"}{" "}
            {value_label}
          </p>
        </div>

        {viewBtn && (
          <div style={{ marginLeft: "auto" }}>
            <Button
              onClick={onClickView}
              variant="surface"
              size={"1"}
              color="iris"
              highContrast
              style={{ cursor: "pointer" }}
            >
              <Text color="iris">View</Text>{" "}
              <Icons.ArrowTopRightIcon color="blue" height={12} width={12} />
            </Button>
          </div>
        )}
      </div>
      <ActivityBarGraph
        timeframe={"week"}
        data={data?.chartData?.map((doc) => ({
          count: doc.value,
          time: doc.period,
        }))}
        toolTipLabel={tooltip ?? null}
      />
    </Wrapper>
  );
}

const IconContainer = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  padding: 5px;
  color: #e5e7eb;
`;

const Wrapper = styled.div`
  margin-top: 0px;
`;

const ChartBox = styled.div`
  width: 100%;
  padding: 20px;
  border: 2px solid #eeeeee;
  border-radius: 1px;
  background: #fbfafb;
`;
