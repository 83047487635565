import { LuMegaphone } from "react-icons/lu";
import { VscDebugStart } from "react-icons/vsc";
import { FaMobileScreenButton, FaPhoneVolume } from "react-icons/fa6";
import { SiKnowledgebase } from "react-icons/si";
import { MdCallSplit, MdOutlineTextsms } from "react-icons/md";
import { MdOutlineWebhook } from "react-icons/md";
import { FcEndCall } from "react-icons/fc";

const NodeIcon = ({ type, isStart, isGlobal }) => {
  const typeIconMap = {
    Start: <VscDebugStart size={12} />,
    Default: <FaPhoneVolume size={12} />,
    KnowledgeBase: <SiKnowledgebase size={12} />,
    "Transfer Call": <MdCallSplit size={12} />,
    Webhook: <MdOutlineWebhook size={12} />,
    "End Call": <FcEndCall size={14} />,
    SMS: <MdOutlineTextsms size={12} />,
    "Press Button": <FaMobileScreenButton size={12} />,
  };

  let icon = <LuMegaphone size={12} />;

  icon = isStart ? typeIconMap["Start"] : typeIconMap[type] || icon;

  return (
    <div
      className={`bg-gray-50 border border-gray-200 w-[24px] h-[24px] rounded-lg flex items-center justify-center ${isGlobal ? "bg-yellow-200" : ""}`}
    >
      {icon}
    </div>
  );
};

export default NodeIcon;
