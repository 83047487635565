import React from "react";

const FlowContext = React.createContext({
  isEditingEdge: null,
  setIsEditingEdge: () => {},
  elements: {},
  triggerUpdate: () => {},
  setElements: () => {},
  deleteElement: () => {},
  duplicateNode: () => {},
  exportFlow: () => {}, // Initial placeholder function
  setNodes: () => {}, // You might still want to pass these if needed elsewhere
  setEdges: () => {},
  variables: {},
  setVariables: () => {},
  selectedVersion: {},
  setSelectedVersion: () => {},
});

export default FlowContext;
