import React from "react";

const Table = ({ variables, tableName = "Table" }) => {
  return (
    <div style={{}}>
      <table
        border={1}
        style={{
          width: "100%",
          marginTop: "10px",
          border: "1px solid #edede9",
          tableLayout: "fixed",
        }}
      >
        <thead
          style={{ borderBottom: "1px solid #edede9", background: "#adb5bd" }}
        >
          <tr>
            <th style={{ width: "50%", wordWrap: "break-word" }}>Variable</th>
            <th style={{ width: "50%", wordWrap: "break-word" }}>Value</th>
          </tr>
        </thead>
        <tbody style={{ overflow: "scroll" }}>
          {Object.keys(variables).map((key, index) => (
            <tr
              style={{
                borderBottom:
                  Object.keys(variables).length === index + 1
                    ? "none"
                    : "0.5px solid #fff",
              }}
              key={key}
            >
              <td
                style={{
                  textAlign: "center",
                  background: "#f8f9fa",
                  wordWrap: "break-word",
                }}
              >
                {key}
              </td>
              <td
                style={{
                  textAlign: "center",
                  background: "#e9ecef",
                  wordWrap: "break-word",
                }}
              >
                {JSON.stringify(variables[key]) || variables[key]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
