import React, { useState, useEffect, useCallback, useMemo } from "react";
import ReplayControls from "./ReplayControls";
import DialogueDisplay from "./DialogueDisplay";

const PathwayReplay = ({ callId, onClose, onNodesFocus }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [replayIndex, setReplayIndex] = useState(0);
  const [replaySpeed, setReplaySpeed] = useState(500);
  const [callData, setCallData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchCallData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `/api/analytics/pathways/call_path?callId=${callId}`,
        {
          headers: { Authorization: `${localStorage.getItem("auth")}` },
        },
      );
      if (!response.ok) throw new Error("Failed to fetch call data");
      const data = await response.json();
      setCallData(data.data);
    } catch (err) {
      console.error(err);
      setError("Failed to load replay data. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [callId]);

  useEffect(() => {
    fetchCallData();
  }, [fetchCallData]);

  const filteredDialogue = useMemo(() => {
    if (!callData || !callData.dialogue) return [];
    return callData.dialogue.filter(
      (message) => message.text && message.text.trim() !== "",
    );
  }, [callData]);

  const callPath = useMemo(() => {
    return callData?.callPath || [];
  }, [callData]);

  // when callid changes, reset replay state
  useEffect(() => {
    setIsPlaying(false);
    setReplayIndex(0);
    setError(null);
  }, [callId]);

  const togglePlay = useCallback(() => {
    setIsPlaying((prev) => !prev);
  }, []);

  const reset = useCallback(() => {
    setReplayIndex(0);
    setIsPlaying(false);
  }, []);

  const handleSpeedChange = useCallback((e) => {
    setReplaySpeed(Number(e.target.value));
  }, []);

  const jumpToMessage = useCallback(
    (index) => {
      setReplayIndex(Math.min(Math.max(0, index), filteredDialogue.length - 1));
    },
    [filteredDialogue],
  );

  useEffect(() => {
    let intervalId;
    if (isPlaying && filteredDialogue.length > 0) {
      if (replayIndex === filteredDialogue.length - 1) {
        setReplayIndex(0);
      }
      intervalId = setInterval(() => {
        setReplayIndex((prevIndex) => {
          if (prevIndex < filteredDialogue.length - 1) {
            return prevIndex + 1;
          } else {
            setIsPlaying(false);
            return prevIndex;
          }
        });
      }, replaySpeed);
    }
    return () => clearInterval(intervalId);
  }, [isPlaying, filteredDialogue, replaySpeed]);

  useEffect(() => {
    const activePath = callPath.slice(0, replayIndex + 1);
    onNodesFocus(activePath);
  }, [replayIndex, callPath, onNodesFocus]);

  return (
    <>
      <ReplayControls
        isPlaying={isPlaying}
        togglePlay={togglePlay}
        reset={reset}
        replaySpeed={replaySpeed}
        handleSpeedChange={handleSpeedChange}
        jumpToMessage={jumpToMessage}
        replayIndex={replayIndex}
        totalSteps={filteredDialogue.length}
        onClose={onClose}
        loading={loading}
        error={error}
        onRetry={fetchCallData}
        noData={!callData || callPath.length === 0}
      />
      {!loading && !error && callData && (
        <DialogueDisplay
          dialogue={filteredDialogue}
          replayIndex={replayIndex}
          onReplayIndexChange={jumpToMessage}
          callPath={callPath}
        />
      )}
    </>
  );
};

export default PathwayReplay;
