import React, { useState } from "react";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Button from "components/core/Button";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";
import { Loading } from "components/core/Loading";

export default function EditPathwayDetailsDialog({
  open,
  onClose,
  pathwayId,
  pathwayName,
  pathwayDescription,
  setRefreshPathways,
}) {
  const initialPathwayName = pathwayName;
  const initialPathwayDescription = pathwayDescription;

  const [name, setName] = useState(pathwayName);
  const [description, setDescription] = useState(pathwayDescription);

  const [loading, setLoading] = useState(false);

  const isEdited =
    name !== initialPathwayName || description !== initialPathwayDescription;

  const handleEditPathwayDetails = async () => {
    // TODO
    try {
      setLoading(true);
      fetch("/api/convo_pathway/update_pathway_name", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: getAuthToken(),
        },
        body: JSON.stringify({
          pathway_id: pathwayId,
          name: name,
          description: description,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.error) {
            toast.error(`Error updating pathway name. ${data.error}`, {
              position: "bottom-right",
            });
            onClose();
            setLoading(false);
            return;
          }
          setRefreshPathways((prev) => !prev);
          toast.success(data.message, {
            position: "bottom-right",
          });
          onClose();
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      onClose();
      console.error("Error updating pathway name", error);
      toast.error("Error updating pathway name", {
        position: "bottom-right",
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 border border-gray-300 bg-white p-8 rounded-lg shadow-lg w-[500px] space-y-4">
        <h1 className="text-2xl text-indigo-500 font-bold mb-4 text-center">
          Edit Pathway Details
        </h1>
        <div className="space-y-2">
          <label>Name:</label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5"
            placeholder="Pathway Name"
          />
        </div>
        <div className="space-y-2">
          <label>Description:</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-indigo-500 focus:border-indigo-500 block w-full p-2.5"
            placeholder="Pathway Description"
          />
        </div>
        <div
          className="pt-2
        flex justify-between space-x-4
        "
        >
          <Button onClick={onClose}>Cancel</Button>
          <div className="flex items-center gap-4">
            <Loading loading={loading} />
            <Button
              onClick={handleEditPathwayDetails}
              disabled={!isEdited || loading}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
