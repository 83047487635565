import axios from "axios";

export const getDetails = async (number, apiKey) => {
  try {
    console.log("number", number);
    const headers = {
      Authorization: `${apiKey}`,
      "Content-Type": "application/json",
      "cache-control": "no-cache",
    };
    const response = await axios.get(
      `https://us.api.bland.ai/v1/inbound/${number}`,
      { headers },
    );
    if (response.data) {
      console.log({ data: response.data, src: true });
      return response.data;
    } else {
      console.error(
        `HTTP error fetching inbound details for ${number}:`,
        response.data,
      );
      return response.data;
    }
  } catch (error) {
    console.error(`Error fetching inbound details for ${number}:`, error);
    return error;
  }
};
