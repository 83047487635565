import axios from "axios";
import { getAuthToken } from "../browser/getAuthToken";

export const getApiKey = async () => {
  try {
    const token = getAuthToken();
    const headers = {
      Authorization: `${token}`,
    };

    const response = await axios.get("/api/user/key", { headers });
    if (response.data.key) {
      return response.data.key;
    }
  } catch (error) {
    console.error("Error getting API key from getApiKey():", error);
    return {
      key: null,
      error: "Error getting API key from getApiKey()",
      messaage: error,
    };
  }
};
