import { deleteVoice } from "utils/funcs/voices/deleteVoice";

export default function DeleteVoice({ voice }) {
  return (
    <>
      <div>
        <h4 className="font-bold">
          Are you sure you want to delete "{voice.name}"?
        </h4>
      </div>
      <p className="text-secondary mb-4">
        Note, once you delete a custom voice, you cannot recover it. Confirm
        you're positive you want to delete this voice before proceeding.
      </p>
      <div className="flex space-x-4">
        <button
          className="bg-dark hover:bg-black text-white font-bold py-2 px-4 rounded"
          onClick={async () => await deleteVoice(voice.id)}
        >
          Confirm
        </button>
      </div>
    </>
  );
}
