import axios from "axios";
import { addPlus, sanitizePhone } from "utils/formatting/phone";

export const verifyOtp = async (code, phone) => {
  const sanitizedPhone = sanitizePhone(phone);
  const phoneE64 = addPlus(sanitizedPhone);

  try {
    // submit the code to twillio.
    const response = await axios.post("/api/login/verifyOtp", {
      code: code,
      phoneNumber: phoneE64,
    });

    const { jwt, authorized_accounts, error } = response.data;

    if (error) {
      console.log("Error hitting /api/login/verifyOtp:", error);
    }

    // if there are authorized accounts, return authorized_accounts.
    if (authorized_accounts?.length > 0) {
      console.log(
        `The following are authorized accounts associated with ${sanitizedPhone}:`,
        authorized_accounts,
      );
      return {
        recieved_code_sent: true,
        authorized_accounts: authorized_accounts,
      };

      // if there are no authorized accounts, set "auth (jwt)" item in local storage.
    } else {
      console.log(
        `There are no authorized accounts associated with ${sanitizedPhone}:`,
        authorized_accounts,
      );
      return {
        recieved_code_sent: true,
        authorized_accounts: authorized_accounts,
        jwt: jwt,
      };
    }
  } catch (error) {
    // set response & log any errors
    console.error("Caught error hitting /api/login/verifyOtp:", error);
    return { error: error.message };
  }
};
