import React, { useState, useContext } from "react";
import { useReactFlow } from "reactflow";
import FlowContext from "../contextFlow";
import { toast } from "react-toastify";
import { X, HelpCircle, Plus, ChevronDown } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Switch } from "@/components/ui/switch";

const EdgeSideBar = ({ edgeData, setIsSideBarOpen, setEdgeData }) => {
  const { setEdges } = useReactFlow();
  const { exportFlow, setIsEditingEdge, triggerUpdate, elements } =
    useContext(FlowContext);

  const maxChars = 100;
  const [pathwayLabel, setPathwayLabel] = useState(edgeData?.data?.label || "");
  const [description, setDescription] = useState(
    edgeData?.data?.description || "",
  );
  const [mode, setMode] = useState(
    edgeData?.data?.condition ? "condition" : "label",
  );
  const [conditions, setConditions] = useState(
    edgeData?.data?.condition || [
      { operator: "AND", field: "", conditionOperator: "contains", value: "" },
    ],
  );

  const [alwaysPick, setAlwaysPick] = useState(
    edgeData?.data?.alwaysPick || false,
  );

  const onSubmit = () => {
    let isWarnToast = false;
    const updatedEdges = elements.edges.map((element_edge) => {
      if (element_edge.id === edgeData.id) {
        const edge = { ...element_edge };
        const sourceId = edge.source;
        const sourceEdges = elements.edges.filter((e) => e.source === sourceId);
        const hasCondition = sourceEdges.some((e) => e.data?.condition);
        const doesNotHaveCondition = sourceEdges.some(
          (e) => !e.data?.condition,
        );

        if (mode === "label") {
          if (!pathwayLabel || pathwayLabel.trim() === "") {
            isWarnToast = true;
            toast.warn("Please enter a pathway label");
            return element_edge;
          }

          if (hasCondition) {
            isWarnToast = true;
            toast.warn(
              "You cannot have pathways with both the 'Variable Comparison' mode and 'Pathway Label' from the same node. Please remove the pathways using the 'Variable Comparison' from the source node first, before adding a new pathway with 'Pathway Label'.",
            );
            return element_edge;
          }

          if (pathwayLabel.length > maxChars) {
            isWarnToast = true;
            toast.warn(
              `Pathway label must be less than ${maxChars} characters`,
            );
            return element_edge;
          }
          edge.data.label = pathwayLabel.trim();
          if (description && description.trim() !== "") {
            edge.data.description = description.trim();
          }
          if (alwaysPick) {
            edge.data.alwaysPick = true;
          } else {
            delete edge.data.alwaysPick;
          }
          delete edge.data.condition;
        } else if (mode === "condition") {
          if (doesNotHaveCondition && sourceEdges.length > 1) {
            isWarnToast = true;
            toast.warn(
              "You cannot have pathways with both the 'Variable Comparison' mode and 'Pathway Label' from the same node. Please remove the pathways using the 'Pathway Label' from the source node first, before adding a new pathway with 'Variable Comparison' mode.",
            );
            return element_edge;
          }

          const validConditions = conditions.every(
            (cond) => cond.field && cond.value,
          );
          if (!validConditions) {
            isWarnToast = true;
            toast.warn("Please complete all condition fields");
            return element_edge;
          }

          edge.data.condition = conditions;
          edge.data.label = conditions
            .map(
              (cond, index) =>
                `${index > 0 ? cond.operator + " " : ""}${cond.field} ${cond.conditionOperator} ${cond.value}`,
            )
            .join(" ");
          delete edge.data.description;
        }
        return edge;
      }
      return element_edge;
    });

    if (isWarnToast) {
      return;
    }

    if (alwaysPick) {
      updatedEdges.forEach((edge) => {
        if (edge.source === edgeData.source && edge.id !== edgeData.id) {
          delete edge.data.alwaysPick;
        }
        if (edge.id === edgeData.id) {
          edge.data.alwaysPick = true;
        }
      });
    }

    triggerUpdate({ edges: updatedEdges });
    setIsEditingEdge(null);
    setIsSideBarOpen(false);
  };

  const deleteCondition = (index) => {
    setConditions(conditions.filter((_, i) => i !== index));
  };

  const handlePathwayLabelChange = (event) => {
    if (event.target.value.length <= maxChars) {
      setPathwayLabel(event.target.value);
    }
  };

  const handleConditionChange = (index, field, value) => {
    const newConditions = [...conditions];
    newConditions[index][field] = value;
    setConditions(newConditions);
  };

  const addCondition = () => {
    setConditions([
      ...conditions,
      { operator: "AND", field: "", conditionOperator: "contains", value: "" },
    ]);
  };

  return (
    <div className="fixed right-0 w-1/4 h-screen bg-slate-100 shadow-lg z-50 overflow-y-auto border-l border-slate-200">
      <div className="p-8 space-y-8">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold text-slate-800">Edit Pathway</h2>
          <button
            onClick={() => {
              setIsSideBarOpen(false);
              setIsEditingEdge(null);
            }}
            className="text-slate-500 hover:text-slate-700 transition-colors"
          >
            <X className="w-6 h-6" />
          </button>
        </div>

        <div className="space-y-4">
          <label className="block text-lg font-semibold text-slate-700">
            How should the agent decide?
          </label>
          <div className="flex text-base text-slate-600 items-center">
            Choose how the agent should decide which pathway to take.
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <HelpCircle className="w-5 h-5 ml-2 text-slate-400" />
                </TooltipTrigger>
                <TooltipContent className="bg-white p-4 rounded-md shadow-lg max-w-xs">
                  <p className="text-sm mb-2">
                    <strong>Pathway label:</strong> Uses natural language to
                    decide (e.g. User said yes)
                  </p>
                  <p className="text-sm">
                    <strong>Variable Comparison:</strong> Uses Mathematical
                    expressions / logic operators to proceed to this pathway if
                    the variable values meets a certain criteria (e.g. variable
                    &gt; 5)
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
          <div className="flex bg-white rounded-lg shadow-sm">
            <button
              className={`flex-1 py-3 px-6 text-base font-medium rounded-l-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                mode === "label"
                  ? "bg-indigo-100 text-indigo-700 border-indigo-200"
                  : "text-slate-600 hover:text-slate-800 hover:bg-slate-50"
              }`}
              onClick={() => setMode("label")}
            >
              Pathway Label
            </button>
            <button
              className={`flex-1 py-3 px-6 text-base font-medium rounded-r-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                mode === "condition"
                  ? "bg-indigo-100 text-indigo-700 border-indigo-200"
                  : "text-slate-600 hover:text-slate-800 hover:bg-slate-50"
              }`}
              onClick={() => setMode("condition")}
            >
              Advanced Settings
            </button>
          </div>
        </div>

        {mode === "condition" && (
          <div className="space-y-6">
            <div className="flex justify-between items-center">
              <h3 className="text-xl font-semibold text-slate-800">
                Value Comparison
              </h3>
              <button
                onClick={addCondition}
                className="text-indigo-600 hover:text-indigo-800 text-base font-medium flex items-center transition-colors"
              >
                <Plus className="w-5 h-5 mr-2" /> Add Condition
              </button>
            </div>
            <p className="text-base text-slate-600">
              Compare the value of existing variables. If it meets the criteria
              (evaluates to true), the agent will proceed to this pathway.
            </p>
            {conditions.map((condition, index) => (
              <div
                key={index}
                className="bg-white p-6 rounded-lg shadow-sm space-y-4"
              >
                {index > 0 && (
                  <div className="flex items-center space-x-3">
                    <label className="text-base font-medium text-slate-700">
                      Logic operator:
                    </label>
                    <select
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-slate-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                      value={condition.operator}
                      onChange={(e) =>
                        handleConditionChange(index, "operator", e.target.value)
                      }
                    >
                      <option value="AND">AND</option>
                      <option value="OR">OR</option>
                    </select>
                  </div>
                )}
                <div className="flex items-center space-x-3">
                  <input
                    type="text"
                    placeholder="Variable to compare e.g. age"
                    className="mt-1 block w-full px-4 py-3 bg-white border border-slate-300 rounded-md text-base shadow-sm placeholder-slate-400
                      focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                    value={condition.field}
                    onChange={(e) =>
                      handleConditionChange(index, "field", e.target.value)
                    }
                  />
                  <button
                    onClick={() => deleteCondition(index)}
                    className="text-slate-400 hover:text-slate-600 transition-colors"
                  >
                    <X className="w-6 h-6" />
                  </button>
                </div>
                <select
                  className="mt-1 block w-full pl-3 pr-10 py-3 text-base border-slate-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
                  value={condition.conditionOperator}
                  onChange={(e) =>
                    handleConditionChange(
                      index,
                      "conditionOperator",
                      e.target.value,
                    )
                  }
                >
                  <option value="contains">contains</option>
                  <option value="does not contain">does not contain</option>
                  <option value="equals">equals</option>
                  <option value="not equals">does not equal</option>
                  <option value="greater than">is greater than</option>
                  <option value="less than">is less than</option>
                  <option value="is">
                    is [supported values: null, not null, true, false]
                  </option>
                </select>
                <input
                  type="text"
                  placeholder="Value"
                  className="mt-1 block w-full px-4 py-3 bg-white border border-slate-300 rounded-md text-base shadow-sm placeholder-slate-400
                    focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                  value={condition.value}
                  onChange={(e) =>
                    handleConditionChange(index, "value", e.target.value)
                  }
                />
              </div>
            ))}
          </div>
        )}

        {mode === "label" && (
          <div className="space-y-6">
            <div>
              <label
                htmlFor="label"
                className="block text-lg font-semibold text-slate-700"
              >
                Pathway Label
              </label>
              <p className="mt-2 text-base text-slate-600">
                Enter a label that describes when this pathway should be chosen.
                Keep it short and succinct e.g. user said yes
              </p>
              <textarea
                id="label"
                className="mt-2 block w-full px-4 py-3 bg-white border border-slate-300 rounded-md text-base shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                rows={3}
                value={pathwayLabel}
                onChange={handlePathwayLabelChange}
                maxLength={maxChars}
                placeholder="Enter pathway label"
              />
              <p className="mt-2 text-sm text-slate-500">
                {pathwayLabel.length}/{maxChars}
              </p>
            </div>

            <div>
              <label
                htmlFor="description"
                className="block text-lg font-semibold text-slate-700"
              >
                Description
              </label>
              <p className="mt-2 text-base text-slate-600">
                Provide more information and describe when this pathway should
                be chosen. This is optional but gives the agent more context
                about when to choose this pathway.
              </p>
              <textarea
                id="description"
                className="mt-2 block w-full px-4 py-3 bg-white border border-slate-300 rounded-md text-base shadow-sm placeholder-slate-400
                  focus:outline-none focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500"
                rows={4}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Describe when this pathway should be chosen..."
              />
            </div>

            <div className="flex items-center space-x-2">
              <Switch
                // make the enable color be indigo-600
                className="bg-indigo-600"
                id="always-pick"
                checked={alwaysPick}
                onCheckedChange={(checked) => setAlwaysPick(checked)}
              />
              <label
                htmlFor="always-pick"
                className="text-base font-medium text-slate-700"
              >
                Always pick this pathway
              </label>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <HelpCircle className="w-5 h-5 text-slate-400" />
                  </TooltipTrigger>
                  <TooltipContent className="bg-white p-4 rounded-md shadow-lg max-w-xs">
                    <p className="text-sm">
                      When enabled, the agent will always choose this pathway,
                      ignoring other options. Only one pathway from a source
                      node can have this option enabled.
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        )}

        <div className="pt-6">
          <div className="flex justify-end space-x-4">
            <button
              type="button"
              className="bg-white py-3 px-6 border border-slate-300 rounded-md shadow-sm text-base font-medium text-slate-700 hover:bg-slate-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => {
                setIsSideBarOpen(false);
                setIsEditingEdge(null);
              }}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={onSubmit}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EdgeSideBar;
