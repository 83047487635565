import {
  BookIcon,
  CreditCardIcon,
  DiscordIcon,
  EnterpriseIcon,
  HomeIcon,
  TestTubesIcon,
  PhoneCallIcon,
  MegaphoneIcon,
  TableIcon,
  VoiceIcon,
  PhoneOutgoingIcon,
  NetworkIcon,
  NodesIcon,
  AddOnIcon,
  CampaignIcon,
  EventIcon,
  ServerIcon,
  AnalyticsIcon,
  PresetsIcon,
  SidebarToolsIcon,
  // MessageIcon,
} from "../../../assets/icons/navigationIcons";

export const dashboard = [
  {
    query: "home",
    text: "Home",
    icon: <HomeIcon />,
  },
  {
    query: "analytics",
    text: "Analytics",
    icon: <AnalyticsIcon />,
  },
  // {
  //    query: "analysis-preset",
  //    text: "Analysis Presets",
  //    icon: <PresetsIcon />
  // },
  {
    query: "call-logs",
    text: "Call Logs",
    icon: <TableIcon />,
  },
  {
    query: "send-call",
    text: "Send Call",
    icon: <PhoneOutgoingIcon />,
  },
  {
    query: "convo-pathways",
    text: "Conversational Pathways",
    icon: <NetworkIcon />,
  },
  {
    query: "campaigns",
    text: "Campaigns & Batches",
    icon: <CampaignIcon />,
  },
  {
    query: "all-tools",
    text: "Tools",
    icon: <SidebarToolsIcon />,
  },
  {
    query: "all-events",
    text: "Events",
    icon: <EventIcon />,
  },
  {
    query: "pay",
    text: "Purchase Credits",
    icon: <CreditCardIcon />,
  },
  {
    query: "phone-numbers",
    text: "Phone Numbers",
    icon: <PhoneCallIcon />,
  },
  // {
  //    query: "batch-call",
  //    text: "Batch Call",
  //    icon: <MegaphoneIcon />,
  // },
  // {
  //    query: "sms",
  //    text: "SMS",
  //    icon: <MessageIcon />,
  // },
  {
    query: "voices",
    text: "Voices",
    icon: <VoiceIcon />,
  },
  {
    query: "infrastructure",
    text: "Infrastructure",
    icon: <ServerIcon />,
  },
  {
    query: "add-ons",
    text: "Add Ons",
    icon: <AddOnIcon />,
  },
  // {
  //    text: "Enterprises",
  //    href: "https://app.bland.ai/enterprise",
  //    icon: <EnterpriseIcon />,
  // },
  // {
  //    href: "https://chat.bland.ai",
  //    text: "Bland Web",
  //    icon: <NodesIcon />,
  // },
  {
    href: "https://docs.bland.ai/api-v1/post/calls",
    text: "Documentation",
    icon: <BookIcon />,
  },
  // {
  //    query: "",
  //    href: "https://discord.com/invite/QvxDz8zcKe",
  //    text: "Discord Community",
  //    icon: <DiscordIcon />,
  // },
];
