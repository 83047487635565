import axios from "axios";

export const getActivePathwayCallLogs = async (callId, apiKey) => {
  const headers = {
    authorization: `${apiKey}`,
  };
  try {
    const { data } = await axios.get(
      `${"https://us.api.bland.ai"}/v1/pathway_calls/${callId}`,
      { headers },
    );

    return data;
  } catch (error) {
    console.error(
      "Error fetching call transcript from getActivePathwayCallLogs()",
      error,
    );
  }
};
