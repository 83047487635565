import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Loading } from "components/core/Loading";
import { getDetails } from "utils/funcs/phone-numbers/inbound/getDetails";
import { Divider } from "components/core/Divider";
import DynamicData from "./DynamicData/DynamicData";
import Button from "components/core/Button";
import ActionBar from "components/core/ActionBar";
import Pathway from "components/Dashboard/Parameters/Pathway";
import Prompt from "components/Dashboard/Parameters/Prompt";
import Voice from "components/Dashboard/Parameters/Voice";
import Model from "components/Dashboard/Parameters/Model";
import InterruptionThresh from "components/Dashboard/Parameters/InterruptionThresh";
import Temperature from "components/Dashboard/Parameters/Temperature";
import FirstSetence from "components/Dashboard/Parameters/FirstSentence";
import Webhook from "components/Dashboard/Parameters/Webhook";
import EncryptedKey from "components/Dashboard/Parameters/EncryptedKey";
import CustomDeployment from "components/Dashboard/Parameters/CustomDeployment";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import TransferList from "components/Dashboard/Parameters/TransferList";
import { objectToKeyValue } from "utils/formatting/objToKeyValues";
import Record from "components/Dashboard/Parameters/Record";
import Language from "components/Dashboard/Parameters/Language";
import AnalysisSchemaCo from "components/Dashboard/Parameters/AnalysisSchema";
import Pronunciation from "components/Dashboard/Parameters/Pronunciation";

export default function Inbound({ submitting, selectedNumber, apiKey }) {
  const [loading, setLoading] = useState(true);
  const { reset, watch } = useFormContext();

  useEffect(() => {
    if (selectedNumber) {
      const getNumberDetails = async () => {
        setLoading(true);
        const details = await getDetails(selectedNumber, apiKey, "inbound");
        if (details) {
          reset({
            prompt: details.prompt || "",
            voice: details.voice,
            language: details.language || "en-US",
            pathway_id: details.pathway_id || null,
            interruption_threshold: details.interruption_threshold || 100,
            transfer_phone_number: details.transfer_phone_number || null,
            first_sentence: details.first_sentence || null,
            max_duration: details.max_duration || 30,
            record: details.record || false,
            model: details.model || "enhanced",
            transfer_list: objectToKeyValue(details?.transfer_list || {}) || [],
            temperature: details.temperature || null,
            dynamic_data: details.dynamic_data || null,
            tools: details.tools || null,
            pronunciation_guide: details.pronunciation_guide || null,
            webhook: details.webhook || null,
            analysis_schema:
              objectToKeyValue(details.analysis_schema || {}) || [],
          });
          setLoading(false);
        }
      };
      getNumberDetails();
    }
  }, [selectedNumber, apiKey, reset]);

  return (
    <>
      <Section>
        {loading ? (
          <Loading loading={loading} />
        ) : (
          <Wrapper>
            <FieldWrapper>
              <Grid>
                <h4 className="font-semibold">Basic</h4>
                <Model fieldName="model" />
                <Voice fieldName="voice" />
                <FirstSetence fieldName="first_sentence" />
                <Prompt fieldName="prompt" />
                <Language fieldName="language" />
                <Pathway fieldName="pathway_id" />
                <TransferList fieldName="transfer_list" />
                <Pronunciation fieldName="pronunciation_guide" />
                <Record fieldName="record" />
                <Divider />
                <h4 className="font-semibold">Ranges</h4>
                <InterruptionThresh fieldName="interruption_threshold" />
                <Temperature fieldName="temperature" />
                <Divider />
                <h4 className="font-semibold">Webhook</h4>
                <Webhook fieldName="webhook" />
                <Divider />
                <DynamicData fieldName="dynamic_data" />
                <AnalysisSchemaCo fieldName="analysis_schema" />
                {/* <p>{JSON.stringify(watch('analysis_schema') || {})}</p> */}
                <CustomDeployment fieldName="endpoint" />
                <EncryptedKey fieldName="encrypted_key" />
              </Grid>
            </FieldWrapper>
            <ActionBar bottom>
              <Button
                appearance={"outline"}
                onClick={() => {
                  window.history.back();
                }}
              >
                Back
              </Button>
              <Button
                submit
                loading={submitting}
                style={{ marginLeft: "auto" }}
              >
                Save Changes
              </Button>
            </ActionBar>
          </Wrapper>
        )}
      </Section>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 30px;
  width: 100%;
  position: relative;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 45px;
  height: 100%;
  width: 100%;
  position: relative;
`;

const Section = styled.div`
  display: flex;
  width: 100%;
  row-gap: 30px;
  flex-direction: column;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
`;
