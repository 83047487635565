import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getUserData } from "../../../utils/funcs/browser/getUserData";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import { toast } from "react-toastify";
import { RepeatIcon } from "assets/icons/repeat-icon";

export default function Subscriptions() {
  const { subscriptions, user } = getUserData();
  const [activeTab, setActiveTab] = useState("active");
  const [canceling, setCanceling] = useState(false);
  const apiKey = getApiKey();

  const methods = useForm({
    defaultValues: {
      subscriptions: subscriptions,
    },
  });

  const ss = methods.watch("subscriptions");

  const handleCancelSubscription = async (sub) => {
    try {
      setCanceling(true);
      await fetch("https://billing.bland.ai/customer/subscription/cancel", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: apiKey,
        },
        body: JSON.stringify({ subscription_id: sub.id, user_id: user.id }),
      });

      const localUser = localStorage.getItem("user");
      let userData = JSON.parse(localUser)?.user || null;
      if (
        typeof userData === "object" &&
        userData !== null &&
        userData.subscriptions
      ) {
        userData.subscriptions = userData.subscriptions.filter(
          (doc) => doc.item !== sub.item,
        );
        localStorage.setItem("user", JSON.stringify({ user: userData }));
        methods.setValue("subscriptions", userData.subscriptions);
      }

      toast.success("Subscription canceled successfully");
    } catch (error) {
      console.error("Error canceling subscription", error);
      toast.error("Failed to cancel subscription");
    } finally {
      setCanceling(false);
    }
  };

  const SubscriptionCard = ({ sub }) => (
    <div className="bg-white rounded-sm shadow-md overflow-hidden">
      <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-3">
            <span className="px-3 py-1 bg-indigo-100 text-indigo-800 rounded-sm text-sm font-medium">
              {sub.type ? sub.type.split(" ")[0] : ""}
            </span>
            <h3 className="text-lg font-semibold text-gray-900">{sub.item}</h3>
          </div>
          <span
            className={`px-2 py-1 rounded-sm text-sm ${
              sub.status === "active"
                ? "bg-green-100 text-green-800"
                : "bg-gray-100 text-gray-800"
            }`}
          >
            {sub.status.charAt(0).toUpperCase() + sub.status.slice(1)}
          </span>
        </div>
      </div>
      <div className="px-6 py-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center space-x-4">
            <p className="text-lg font-bold text-gray-900">$15.00 / month</p>
            {sub.status === "canceled" ? (
              <p className="text-sm text-gray-500">
                Expires: {sub.expiration_date}
              </p>
            ) : (
              <div className="flex items-center space-x-1 text-sm text-gray-500">
                <RepeatIcon className="w-4 h-4" />
                <p>Renews: {sub.expiration_date}</p>
              </div>
            )}
          </div>
          {sub.status === "active" && (
            <button
              onClick={() => handleCancelSubscription(sub)}
              disabled={canceling}
              className="px-4 py-2 bg-red-500 text-white rounded-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition-colors duration-200"
            >
              {canceling ? "Canceling..." : "Cancel Subscription"}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <FormProvider {...methods}>
      <div className="space-y-6">
        <h5 className="text-lg font-semibold text-gray-900">Subscriptions</h5>
        <div className="flex space-x-4 border-b border-gray-200">
          <button
            onClick={() => setActiveTab("active")}
            className={`pb-2 px-1 ${
              activeTab === "active"
                ? "border-b-2 border-indigo-500 text-indigo-600"
                : "text-gray-500"
            }`}
          >
            Active
          </button>
          <button
            onClick={() => setActiveTab("canceled")}
            className={`pb-2 px-1 ${
              activeTab === "canceled"
                ? "border-b-2 border-indigo-500 text-indigo-600"
                : "text-gray-500"
            }`}
          >
            Canceled
          </button>
        </div>
        <div className="space-y-4">
          {ss
            .filter((sub) => sub.status === activeTab)
            .map((sub, index) => (
              <SubscriptionCard key={index} sub={sub} />
            ))}
          {ss.filter((sub) => sub.status === activeTab).length === 0 && (
            <p className="text-gray-500 text-center py-4">
              No {activeTab} subscriptions found.
            </p>
          )}
        </div>
      </div>
    </FormProvider>
  );
}
