export function RepeatIcon(props) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.9718 12.5402H5.89137V10.4161L3.0592 13.2482L5.89137 16.0804V13.9563H14.3879V9.70802H12.9718M5.89137 5.45977H12.9718V7.58389L15.804 4.75172L12.9718 1.91956V4.04368H4.47529V8.29193H5.89137V5.45977Z"
        fill="currentColor"
      />
    </svg>
  );
}
