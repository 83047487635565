import React, { useEffect, useRef, useState } from "react";
import { FolderIcon, MoreVertical } from "lucide-react";
import { Input } from "@/components/ui/input";
import { toast } from "react-toastify";
import { useDrop } from "react-dnd";

const ItemTypes = {
  PATHWAY: "pathway",
  FOLDER: "folder",
};

const DroppableFolderItem = ({
  folder,
  onSelectFolder,
  selectedFolderId,
  onMovePathway,
  onEditFolder,
  onDeleteFolder,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState(folder.name);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef(null);

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: ItemTypes.PATHWAY,
    drop: (item) => onMovePathway(item.id, folder.id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  }));

  const isActive = isOver && canDrop;

  const handleEditClick = () => {
    setIsEditing(true);
    setIsPopoverOpen(false);
  };

  const handleDeleteClick = () => {
    const hasPathways = folder.children.some(
      (child) => child.type === "pathway",
    );
    const hasFolders = folder.children.some((child) => child.type === "folder");
    if (hasPathways) {
      toast.error("Cannot delete folder containing pathways");
    } else if (hasFolders) {
      toast.error("Cannot delete folder containing subfolders");
    } else {
      onDeleteFolder(folder.id);
    }
    setIsPopoverOpen(false);
  };

  const handleSaveEdit = () => {
    onEditFolder(folder.id, editedName);
    setIsEditing(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
        // if user clicks on popover button, don't close the popover
        if (event.target.closest(".group")) return;

        setIsPopoverOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverRef]);

  return (
    <div
      ref={drop}
      style={{
        backgroundColor: isActive
          ? "#e0e7ff"
          : canDrop
            ? "#f3f4f6"
            : "transparent",
        transition: "background-color 0.2s ease",
      }}
      className="relative group"
    >
      <div className="flex items-center w-full justify-between pr-2">
        {isEditing ? (
          <Input
            value={editedName}
            onChange={(e) => setEditedName(e.target.value)}
            onBlur={handleSaveEdit}
            onKeyPress={(e) => e.key === "Enter" && handleSaveEdit()}
            className="ml-4 flex-grow"
            autoFocus
          />
        ) : (
          <button
            onClick={() => onSelectFolder(folder)}
            className={`flex items-center text-left p-2 hover:bg-gray-100 rounded flex-grow ${
              selectedFolderId === folder.id ? "bg-gray-200" : ""
            }`}
          >
            <FolderIcon className="mr-2 flex-shrink-0" size={16} />
            <span className="truncate flex-grow">{folder.name}</span>
          </button>
        )}
        <div>
          <button
            onClick={() => setIsPopoverOpen((prev) => !prev)}
            className="p-1 hover:bg-gray-200 rounded ml-2 flex-shrink-0"
            aria-label="More options"
          >
            {MoreVertical ? <MoreVertical size={16} /> : "•••"}
          </button>
          {isPopoverOpen && (
            <div
              ref={popoverRef}
              className="absolute z-[100000] right-0 top-full mt-1 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
            >
              <div className="z-[1000000] py-1">
                <button
                  onClick={handleEditClick}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                >
                  Edit
                </button>
                <button
                  onClick={handleDeleteClick}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
                >
                  Delete
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {isActive && (
        <div className="absolute inset-0 border-2 border-indigo-500 rounded pointer-events-none"></div>
      )}
    </div>
  );
};

export default DroppableFolderItem;
