"use client";
import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useFormContext } from "react-hook-form";
import { ErrorText } from "./ErrorText";
import Label from "./Label";
import styled from "styled-components";

export const Select = ({
  fieldName,
  label,
  onChange,
  noDefault,
  children,
  ...props
}) => {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <div className="flex flex-col gap-2">
      {label && <Label htmlFor={fieldName}>{label}</Label>}
      <StyledSelect
        {...register?.(fieldName, {
          onChange: onChange,
        })}
        {...props}
      >
        {noDefault ? null : (
          <option value="" hidden>
            Select {label}
          </option>
        )}

        {/* Options are children of <Select/> */}
        {children}
      </StyledSelect>
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => <ErrorText message={message} />}
      />
    </div>
  );
};

export default Select;

const StyledSelect = styled.select`
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  width: 100%;
  padding: 4px 12px;
  cursor: pointer;
  background-color: #fbfbfa;
  height: 35px !important;
  &:focus {
    outline: none;
    border: 1px solid #eeeeee;
  }
  &::placeholder {
    color: #a8a8a1;
  }
`;
