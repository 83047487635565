import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import {
  Play,
  Pause,
  RotateCcw,
  FastForward,
  Rewind,
  X,
  RefreshCw,
} from "lucide-react";
import { Loading } from "components/core/Loading";

const ControlsWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 50;
  padding: 1rem;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  min-width: 300px;
`;

const ReplayControls = ({
  isPlaying,
  togglePlay,
  reset,
  replaySpeed,
  handleSpeedChange,
  jumpToMessage,
  replayIndex,
  totalSteps,
  onClose,
  loading,
  error,
  onRetry,
  noData,
}) => {
  return (
    <ControlsWrapper
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
    >
      {loading ? (
        <div className="flex justify-center items-center h-20">
          <Loading loading={loading} />
        </div>
      ) : error ? (
        <div className="flex flex-col items-center justify-center h-20">
          <p className="text-red-500 mb-2">{error}</p>
          <button
            onClick={onRetry}
            className="p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors flex items-center"
          >
            <RefreshCw size={16} className="mr-2" />
            Retry
          </button>
        </div>
      ) : noData ? (
        <div className="flex justify-center items-center h-20">
          <p className="text-gray-500">
            No pathway data available for this call
          </p>
        </div>
      ) : (
        <>
          <div className="flex items-center space-x-4">
            <button
              onClick={togglePlay}
              className="p-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors"
            >
              {isPlaying ? <Pause size={20} /> : <Play size={20} />}
            </button>
            <button
              onClick={reset}
              className="p-2 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
            >
              <RotateCcw size={20} />
            </button>
            <select
              value={replaySpeed}
              onChange={handleSpeedChange}
              className="p-2 border rounded-md"
            >
              <option value={2000}>Slow</option>
              <option value={1000}>Normal</option>
              <option value={500}>Fast</option>
            </select>
            <button
              onClick={() => jumpToMessage(replayIndex - 1)}
              className="p-2 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
            >
              <Rewind size={20} />
            </button>
            <button
              onClick={() => jumpToMessage(replayIndex + 1)}
              className="p-2 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
            >
              <FastForward size={20} />
            </button>
            <button
              onClick={onClose}
              className="p-2 bg-gray-200 rounded-md hover:bg-gray-300 transition-colors ml-auto"
            >
              <X size={20} />
            </button>
          </div>
          <div className="mt-2 text-sm text-gray-600">
            {`Replaying step ${replayIndex + 1} of ${totalSteps}`}
          </div>
        </>
      )}
    </ControlsWrapper>
  );
};

export default ReplayControls;
