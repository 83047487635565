import axios from "axios";

export const getActiveCallLogs = async (callId, apiKey) => {
  const headers = {
    authorization: `${apiKey}`,
  };
  try {
    const { data } = await axios.get(
      `https://us.api.bland.ai/v1/calls/${callId}`,
      { headers },
    );
    console.log("transcript from getActiveCallLogs()", data?.transcripts);
    return data?.transcripts;
  } catch (error) {
    console.error(
      "Error fetching call transcript from getActiveCallLogs()",
      error,
    );
  }
};
