import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { useForm, FormProvider } from "react-hook-form";
import { FaClipboardList, FaFileAlt, FaPencilAlt } from "react-icons/fa";
import Textarea from "components/core/Textarea";
import Button from "components/core/Button";
import axios from "axios";
import { toast } from "react-toastify";
import { Loading } from "components/core/Loading"; // Assuming you have a Loading component
import { getUserData } from "utils/funcs/browser/getUserData";

export default function GeneratePathway() {
  const [selectedOption, setSelectedOption] = useState(null);
  const methods = useForm();
  const [loading, setLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleOptionClick = async (option) => {
    setSelectedOption(option);
    if (option === "scratch") {
      await createNewPathway();
    } else {
      setTimeout(() => {
        setShowForm(true);
      }, 500);
    }
  };

  const [progressIndex, setProgressIndex] = useState(0);

  // mwahahaha
  const progressArray = [
    1, 1, 2, 2, 3, 3, 4, 4, 5, 5, 6, 6, 7, 7, 7, 8, 8, 9, 9, 10, 11, 11, 11, 12,
    12, 13, 13, 14, 14, 15, 15, 15, 16, 17, 17, 18, 18, 19, 19, 20, 21, 22, 22,
    23, 23, 24, 25, 26, 27, 28, 29, 29, 29, 30, 31, 31, 32, 32, 33, 33, 34, 34,
    35, 35, 36, 36, 37, 37, 38, 38, 39, 39, 40, 41, 42, 42, 43, 44, 45, 46, 47,
    48, 48, 48, 49, 49, 51, 51, 52, 52, 53, 53, 53, 54, 54, 55, 55, 56, 56, 57,
    57, 57, 58, 58, 59, 59, 60, 61, 61, 62, 63, 64, 64, 65, 66, 67, 68, 69, 70,
    71, 71, 72, 72, 73, 73, 73, 74, 74, 75, 75, 76, 76, 76, 77, 77, 78, 78, 79,
    79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 90, 90, 90, 90, 91, 91, 91,
    91, 91, 91, 91, 92, 92, 92, 92, 92, 92, 92, 93, 93, 93, 93, 93, 93, 94, 94,
    94, 94, 94, 94, 95, 95, 95, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96,
    96, 96, 96, 96, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97, 97,
    97, 97, 97, 97, 97, 97, 97, 97, 97, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98,
    98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98,
    98, 98, 98, 98, 98, 98, 98, 99, 99,
  ];

  const incrementProgress = () => {
    setProgressIndex((prevIndex) => {
      const newIndex = prevIndex + 1;
      if (newIndex < progressArray.length) {
        setProgress(progressArray[newIndex]);
        return newIndex;
      }
      return prevIndex;
    });
  };

  useEffect(() => {
    let interval;
    if (showLoadingScreen) {
      setProgress(0);
      setProgressIndex(0);
      interval = setInterval(incrementProgress, 100);
    }
    return () => clearInterval(interval);
  }, [showLoadingScreen]);

  const createNewPathway = async () => {
    setLoading(true);
    setShowLoadingScreen(true);

    const { api_key: apiKey } = getUserData();

    try {
      const response = await axios.post(
        "https://us.api.bland.ai/v1/convo_pathway/create",
        {
          name: "New Pathway",
          description: "A new pathway created from scratch",
        },
        {
          headers: {
            Authorization: apiKey,
            "Content-Type": "application/json",
          },
        },
      );

      if (response.data.status === "success" && response.data.pathway_id) {
        setProgress(99);
        setTimeout(() => {
          setProgress(100);
          const redirectUrl = `/dashboard?page=convo-pathways&id=${response.data.pathway_id}&format=true`;
          setTimeout(() => {
            window.location.href = redirectUrl;
          }, 500);
        }, 150);
      } else {
        throw new Error("Failed to create new pathway");
      }
    } catch (error) {
      toast.error(
        error.message || "An error occurred while creating the pathway",
      );
      setLoading(false);
      setShowLoadingScreen(false);
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setShowLoadingScreen(true);

    const { api_key: apiKey } = getUserData();

    const headers = {
      Authorization: apiKey,
      "Content-Type": "application/json",
    };
    const prompt = data.prompt;

    data = {
      input_prompt: prompt,
    };

    const apiUrl = "https://us.api.bland.ai";

    try {
      const response = await axios.post(`${apiUrl}/v1/generate_pathway`, data, {
        headers,
      });
      setProgress(99);
      if (response.status === 200 && response.data.pathway_id) {
        toast.success("Pathway generated!");
        setTimeout(() => {
          setProgress(100);
          const redirectUrl = `/dashboard?page=convo-pathways`;
          setTimeout(() => {
            window.location.href = `${redirectUrl}&id=${response.data.pathway_id}&format=true`;
          }, 500); // Slight delay before redirecting
        }, 150);
      }
    } catch (error) {
      toast(
        error?.response?.data?.errors?.toString() ||
          error?.response?.data?.message?.toString(),
        {
          type: "error",
        },
      );
      console.log(
        "Error submitting send call form:",
        error.response.data.errors,
      );
      setLoading(false);
      setShowLoadingScreen(false);
    }
  };

  return (
    <GradientBackground className={showLoadingScreen ? "show-loading" : ""}>
      <PageWrapper className={showLoadingScreen ? "fade-out" : "fade-in"}>
        {!showForm ? (
          <>
            <HeaderText>
              How do you want to start building your pathway?
            </HeaderText>
            <OptionsContainer>
              <OptionCard onClick={() => handleOptionClick("use-case")}>
                <FaClipboardList size={48} className="icon" />
                <OptionText>Use Case</OptionText>
              </OptionCard>
              <OptionCard onClick={() => handleOptionClick("script")}>
                <FaFileAlt size={48} className="icon" />
                <OptionText>Script</OptionText>
              </OptionCard>
              <OptionCard onClick={() => handleOptionClick("scratch")}>
                <FaPencilAlt size={48} className="icon" />
                <OptionText>From Scratch</OptionText>
              </OptionCard>
            </OptionsContainer>
          </>
        ) : (
          <FormProvider {...methods}>
            <FormWrapper onSubmit={methods.handleSubmit(onSubmit)}>
              {selectedOption === "use-case" && (
                <StyledTextarea
                  label="Describe your use case"
                  autoResize={true}
                  fieldName="prompt"
                  rows={15}
                  placeholder="Enter a description of the conversation you want to create, including key actions and responses. Describe specific scenarios, such as handling a reservation call, customer support interaction, or any specific situation where the AI needs to guide the user through a series of steps."
                />
              )}
              {selectedOption === "script" && (
                <StyledTextarea
                  label="Paste your script"
                  autoResize={true}
                  fieldName="prompt"
                  rows={15}
                  placeholder="Paste your script here. Ensure that the script includes detailed dialogues, actions, and responses. Clearly outline the flow of the conversation and any specific instructions or key points that the AI needs to follow."
                />
              )}
              {selectedOption === "scratch" && (
                <StyledTextarea
                  label="Start from scratch"
                  autoResize={true}
                  fieldName="prompt"
                  rows={15}
                  placeholder="Begin crafting your pathway from scratch. You can start by outlining the main steps or objectives of your conversation, then gradually add more details, potential user responses, and branching paths."
                />
              )}
              <StyledButton loading={loading} submit>
                ✨Generate✨
              </StyledButton>
            </FormWrapper>
          </FormProvider>
        )}
      </PageWrapper>
      {showLoadingScreen && (
        <LoadingScreen>
          <LoadingText>Loading... {progress}%</LoadingText>
          <LoadingIcon>
            <Loading />
          </LoadingIcon>
          <ProgressBar>
            <Progress style={{ width: `${progress}%` }} />
          </ProgressBar>
        </LoadingScreen>
      )}
    </GradientBackground>
  );
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const GradientBackground = styled.div`
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 2rem;

  &.show-loading {
    background: linear-gradient(
      145deg,
      #ffafbd,
      #ffc3a0,
      #ff9a9e,
      #fad0c4,
      #fbc2eb
    );
    background-size: 200% 200%;
    animation: ${gradientAnimation} 4s ease infinite;
  }
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  transition: opacity 0.5s ease-in-out;

  &.fade-out {
    animation: ${fadeOut} 0.5s forwards;
  }

  &.fade-in {
    animation: ${fadeIn} 0.5s forwards;
  }
`;

const FormWrapper = styled.form`
  animation: ${fadeIn} 0.5s ease-in-out;
  width: 100%; /* Make the form take full width */
  max-width: 800px; /* Limit the form's width */
`;

const HeaderText = styled.h1`
  color: #4c51bf;
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const OptionsContainer = styled.div`
  display: flex;
  gap: 20px;
  animation: ${fadeIn} 0.5s ease-in-out;
`;

const OptionCard = styled.div`
  background: white;
  border: 1px solid #ddd;
  padding: 40px;
  border-radius: 8px;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;

  .icon {
    color: #4c51bf;
    transition: color 0.3s ease;
  }

  &:hover {
    background: linear-gradient(
      145deg,
      #ffafbd,
      #ffc3a0,
      #ff9a9e,
      #fad0c4,
      #fbc2eb
    );
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);

    .icon {
      color: white;
    }

    h3 {
      color: white;
    }
  }
`;

const OptionText = styled.h3`
  color: #4c51bf;
  font-size: 1.5rem;
  margin-top: 10px;
`;

const StyledTextarea = styled(Textarea)`
  font-size: 1.5rem;
  width: 100%;
  max-width: 800px;
  height: 400px;
`;

const StyledButton = styled(Button)`
  background: white;
  border: 1px solid #ddd;
  display: flex;
  margin-top: 10px;
  width: 600px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  text-align: center;
  cursor: pointer;
  transition:
    transform 0.3s ease,
    box-shadow 0.3s ease;
  position: relative;
  overflow: hidden;
  color: black;

  &:hover {
    background: linear-gradient(
      145deg,
      #ffafbd,
      #ffc3a0,
      #ff9a9e,
      #fad0c4,
      #fbc2eb
    );
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: scale(1.05);
    color: white;
  }
`;

const LoadingScreen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  z-index: 100;
`;

const LoadingText = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
`;

const LoadingIcon = styled.div`
  margin-bottom: 20px;
`;

const ProgressBar = styled.div`
  width: 80%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  overflow: hidden;
  margin-top: 20px;
`;

const glowing = keyframes`
  0% {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  }
`;

const Progress = styled.div`
  height: 10px;
  background: white;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
  animation: ${glowing} 2s infinite;
  box-shadow: 0 0 10px white;
`;
