import React from "react";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Filler,
);

const LineChart = ({ data, chart_title, data_label, bgColor, borderColor }) => {
  const config = {
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
          display: false,
        },
        title: {
          display: true,
          text: chart_title,
          position: "top",
        },
      },
      scales: {
        x: {
          title: {
            display: false,
            text: "Date",
          },
          ticks: {
            font: {
              size: 10,
              align: "center",
              color: "#e5e5e5",
            },
          },
          grid: {
            display: false,
          },
        },
        y: {
          max: Math.max(...data.flatMap((doc) => doc.val)) * 1.6,
          min: 0,
          title: {
            display: true,
            text: "Seconds",
          },
          grid: {
            color: "#eeeeee",
            display: true,
          },
          ticks: {
            font: {
              size: 10,
              align: "center",
              color: "#e5e5e5",
            },
          },
        },
      },
    },
    data: {
      labels: data?.flatMap((doc) => doc.day),
      datasets: [
        {
          data: data?.flatMap((doc) => doc.val),
          backgroundColor: bgColor,
          borderColor: borderColor,
          borderWidth: 2.3,
          fill: true,
          tension: 0.4,
          pointStyle: "rectRot",
          pointRadius: 2.5,
          pointHoverRadius: 5,
        },
      ],
    },
  };

  return (
    <Line
      data={config.data}
      options={config.options}
      style={{ maxHeight: 200 }}
    />
  );
};

export default LineChart;
