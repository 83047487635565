import { IoReturnDownBackOutline } from "react-icons/io5";
import { setAuthToken } from "../../../utils/funcs/browser/setAuthToken";
import Label from "components/core/Label";
import styled from "styled-components";

export default function AccountSelect({ authorizedAccounts }) {
  return (
    <Wrapper>
      <Label>Select an Account</Label>
      <Accounts>
        {authorizedAccounts.map((account) => (
          <Account
            key={account.id}
            onClick={() => {
              setAuthToken(account.jwt);
              window.location.href = "/dashboard?page=home";
            }}
          >
            <Avatar>
              <div className="w-12 h-12 bg-grayblue rounded-full mr-2" />
              <div className="flex flex-col">
                <div className=" font-medium">
                  {account.first_name + "  " + account.last_name}
                </div>
                <div className="text-xs text-gray-500">{account.email}</div>
              </div>
            </Avatar>
            <IoReturnDownBackOutline className="text-gray-500" />
          </Account>
        ))}
      </Accounts>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 30px;
  border: 1px solid #eeeeee;
  border-radius: 12px;
  height: 100%;
  max-height: 500px;
  overflow-y: scroll;
`;

const Avatar = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: center;
`;

const Accounts = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;
const Account = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 9px 12px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #fbfbfa;
  }
`;
