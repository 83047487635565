import React, { useEffect, useState, useCallback } from "react";
import { ChevronDown, ChevronUp, Save } from "lucide-react";
import { toast } from "react-toastify";

const ConfigurationSection = ({ encryptedKey }) => {
  const [selectedHeader, setSelectedHeader] = useState(null);
  const [dialerEnabled, setDialerEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDialerEnabled = useCallback(async () => {
    if (!encryptedKey) return;
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("api/byot/get_dialer_enabled", {
        method: "GET",
        headers: { encrypted_key: encryptedKey },
      });
      if (!response.ok) throw new Error("Failed to fetch dialer setting");
      const data = await response.json();
      setDialerEnabled(data[0].enable_dialer);
    } catch (err) {
      setError("Failed to fetch dialer setting. Please try again.");
      console.error("Failed to fetch dialer setting:", err);
    } finally {
      setLoading(false);
    }
  }, [encryptedKey]);

  useEffect(() => {
    fetchDialerEnabled();
  }, [fetchDialerEnabled]);

  const handleSave = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("/api/byot/save_dialer_enabled", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          encrypted_key: encryptedKey,
        },
        body: JSON.stringify({ dialer_enabled: dialerEnabled }),
      });
      if (!response.ok) throw new Error("Failed to save dialer setting");
      const data = await response.json();
      if (data.status === "success") {
        toast.success("Dialer setting saved successfully");
      } else {
        throw new Error(data.message || "Failed to save dialer setting");
      }
    } catch (err) {
      setError("Failed to save dialer setting. Please try again.");
      console.error("Failed to save dialer setting:", err);
      toast.error("Failed to save dialer setting");
    } finally {
      setLoading(false);
    }
  };

  const toggleHeader = (header) => {
    setSelectedHeader(selectedHeader === header ? null : header);
  };

  const configSections = [
    {
      key: "dialerFunctionality",
      title: "Dialer Functionality",
      content: (
        <div className="space-y-4">
          <p className="text-sm text-gray-600">
            We rotate through the numbers that you have imported into Bland,
            from Twilio, to ensure spread-out usage across all numbers, keeping
            the reputation of the numbers healthy, preventing it from getting
            marked as 'Spam'.
          </p>
          <div className="flex items-center justify-between">
            <span className="text-sm font-medium text-gray-700">
              Enable Dialer
            </span>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={dialerEnabled}
                onChange={() => setDialerEnabled(!dialerEnabled)}
                disabled={loading}
              />
              <div
                className={`w-11 h-6 rounded-full peer ${dialerEnabled ? "bg-blue-600" : "bg-gray-200"} peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600`}
              ></div>
            </label>
          </div>
          <button
            onClick={handleSave}
            disabled={loading}
            className="w-full px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? "Saving..." : "Save Changes"}
          </button>
        </div>
      ),
    },
    {
      key: "localDialing",
      title: "Local Dialing and Number Allocation",
      content:
        "If local dialing is enabled and there is no number from the callee's area code in your inbound numbers list, we will purchase a new number from your Twilio account and use it for the call.",
    },
    {
      key: "recordingURLs",
      title: "Recording URLs Authentication",
      content:
        "Recording URLs are hidden by authentication by default in Twilio. To disable HTTP authentication, you need to change the settings under Twilio Console > Voice > Settings > General > HTTP Basic Authentication for media access > Disable.",
    },
    {
      key: "numberReputation",
      title: "Remove Spam Likely tagged numbers",
      content: (
        <p className="text-sm text-gray-600">
          No longer deal with 'Spam Likely' tagged numbers causing lower pick-up
          rates. Contact us to do so, by upgrading to our Enterprise Plan{" "}
          <a
            href="https://app.bland.ai/enterprise"
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 hover:underline"
          >
            here
          </a>
          .
        </p>
      ),
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow-sm overflow-hidden">
      {error && (
        <div className="p-4 bg-red-50 border-l-4 border-red-400">
          <p className="text-sm text-red-700">{error}</p>
        </div>
      )}
      {configSections.map((section) => (
        <div
          key={section.key}
          className="border-b border-gray-200 last:border-b-0"
        >
          <button
            className="flex justify-between items-center w-full px-6 py-4 text-left focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
            onClick={() => toggleHeader(section.key)}
          >
            <h3 className="text-sm font-medium text-gray-900">
              {section.title}
            </h3>
            {selectedHeader === section.key ? (
              <ChevronUp className="h-5 w-5 text-gray-400" />
            ) : (
              <ChevronDown className="h-5 w-5 text-gray-400" />
            )}
          </button>
          {selectedHeader === section.key && (
            <div className="px-6 py-4 bg-gray-50">
              {typeof section.content === "string" ? (
                <p className="text-sm text-gray-600">{section.content}</p>
              ) : (
                section.content
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ConfigurationSection;
