import { DataGrid } from "@mui/x-data-grid";
import { Box, Card, Table } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import styled from "styled-components";
import ActivityBarGraph from "../CallLogs/ActivityBarGraph";
import { toast } from "react-toastify";
import { PageTitle } from "components/core/PageTitle";
const ANALYTICS_URI = "https://us.api.bland.ai/api/analytics";

export const ExpandedAnalytics = ({ chartData, query, chartTitle, apiKey }) => {
  const [tableData, setTableData] = useState([]);

  const post_processing = async (data) => {
    // console.log(data);
    const fuckData = data?.[0];
    if (!fuckData) return;
    const data2 = fuckData?.data;

    function extractObjects(data) {
      // Initialize an empty array to store the extracted objects
      let extractedObjects = [];

      // Iterate through each object in the input array
      data.forEach((item) => {
        // Check if the current item has a "value" property that is an array
        if (Array.isArray(item.value)) {
          // Concatenate the array of objects to the result array
          extractedObjects = extractedObjects.concat(item.value);
        }
      });

      // Return the final array of objects
      return extractedObjects;
    }

    const mapped = extractObjects(data2);
    mapped.sort(
      (a, b) => new Date(b["Created At"]) - new Date(a["Created At"]),
    );
    setTableData(mapped);
  };

  const load_analytics = async () => {
    try {
      const response = await fetch(ANALYTICS_URI, {
        method: "POST",
        headers: {
          authorization: apiKey,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          queries: query ?? [],
        }),
      });

      if (response.ok && response.status === 200) {
        const json = await response.json();
        const resData = json.data;

        console.log({ json, resData });
        if (json && json?.status === "success" && resData) {
          post_processing(resData);
        }
      }
    } catch (error) {}
  };

  const getLocalDateTimeFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  useEffect(() => {
    load_analytics();
    console.log("h0");
  }, []);

  useEffect(() => {
    load_analytics();
    console.log("h1");
  }, [chartData]);

  if (tableData?.length == 0) return <div />;
  return (
    <Box>
      <Box
        style={{
          marginTop: 25,
          border: "none",
          borderTopLeftRadius: 6,
          borderTopRightRadius: 6,
          border: "1px solid #eeeeee",
          padding: "12px 12px",
        }}
      >
        <PageTitle
          style={{ fontSize: 20, fontWeight: "500", marginBottom: -25 }}
        >
          {chartTitle}
        </PageTitle>
        <ActivityBarGraph
          timeframe={"week"}
          data={chartData?.map((doc) => ({
            count: doc.value,
            time: doc.period,
          }))}
          toolTipLabel={"Calls"}
          displayYAxis={true}
        />
      </Box>

      <DataGrid
        style={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderTop: "none",
        }}
        rows={tableData.map((doc, ind) => {
          if (doc.Analysis) {
            doc.Analysis = JSON.stringify(doc.Analysis);
          }
          if (doc["Created At"]) {
            doc["Created At"] = getLocalDateTimeFromTimestamp(
              doc["Created At"],
            );
          }
          return { ...doc, id: Math.random() * 10 };
        })}
        columns={Object.keys(tableData?.[0]).map((key, ind) => {
          return {
            field: key,
            headerName: key,
            width: 100,
            onclick: (val) => console.log(val),
          };
        })}
        sx={{
          "& .MuiCheckbox-root svg": {
            width: 12,
            height: 12,
            backgroundColor: "transparent",
            border: `1px solid #d9d9d9`,
            borderRadius: 0.75,
          },
          "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
            {
              outline: "none !important",
            },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none !important",
            background: "transparent !important",
          },
          "& .MuiCheckbox-root svg path": {
            display: "none",
          },
          "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg":
            {
              backgroundColor: "#1890ff",
              borderColor: "#1890ff",
            },
          "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
            position: "absolute",
            display: "table",
            border: "2px solid #fff",
            borderTop: 0,
            borderLeft: 0,
            transform: "rotate(45deg) translate(-50%,-50%)",
            opacity: 1,
            transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
            content: '""',
            top: "50%",
            left: "39%",
            width: 5.71428571,
            height: 9.14285714,
          },
          "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after":
            {
              width: 8,
              height: 8,
              backgroundColor: "#1890ff",
              transform: "none",
              top: "39%",
              border: 0,
            },
          letterSpacing: "normal",
          "& .MuiDataGrid-columnsContainer": {
            backgroundColor: "#fafafa",
          },
          "& .MuiDataGrid-iconSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
            borderRight: `1px solid #f0f0f0`,
          },
          "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
            borderBottom: `1px solid #f0f0f0`,
          },
          "& .MuiDataGrid-cell": {
            color: "rgba(0,0,0,.85)",
          },
          "& .MuiPaginationItem-root": {
            borderRadius: 0,
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 600,
          },
          ".MuiDataGrid-columnSeparator": {
            display: "none",
          },
          "& .MuiDataGrid-cell": {
            fontSize: "10px",
            fontWeight: "250",
          },
        }}
        headerHeight={35}
        rowHeight={42}
        checkboxSelection={false}
        autoHeight
        pageSize={10}
        onCellClick={(val) => {
          if (val.field?.toLowerCase() === "call id") {
            window.navigator.clipboard.writeText(val.formattedValue);
            toast.success("Copied Call ID to clipboard.");
          }
        }}
      />
    </Box>
  );
};
