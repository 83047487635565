import { useFieldArray, useFormContext } from "react-hook-form";
import { useEffect } from "react";
import styled from "styled-components";
import Input from "components/core/Input";
import Label from "components/core/Label";
import Button from "components/core/Button";
import LightTooltip from "./ToolTipDesc";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import ReactPhoneInput from "components/core/PhoneInput";

export default function TransferList({ fieldName, isTransferKeyPair = false }) {
  const { control, getValues, setValue, watch } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  });

  useEffect(() => {
    const currRequestData = getValues(fieldName);
    setValue(fieldName, currRequestData);
  }, [fieldName, getValues, setValue, watch]);

  const onAdd = () => {
    append({ key: "", value: "" });
  };

  const description = (
    <span>
      Give your agent the ability to transfer calls to a set of phone numbers.
      <br></br>
      <br></br>
      Overrides <b>transfer_phone_number</b> if a transfer_list.default is
      specified.
      <br></br>
      <br></br>
      Will default to <b>transfer_list.default</b>, or the chosen phone number.
    </span>
  );

  return (
    <Wrapper>
      <span>
        <Label>Transfer List</Label>
      </span>

      <p className="text-secondary mb-2">
        Give your agent the ability to transfer calls to a set of phone numbers.
        Overrides transfer_phone_number if a transfer_list.default is specified.
        Will default to transfer_list.default, or the chosen phone number.
      </p>
      {fields.length > 0 ? (
        <>
          <KeyValueWrapper>
            {fields.map((param, index) => (
              <KeyValueGrid key={param.id}>
                <KeyValueItem>
                  <Input
                    fieldName={`${fieldName}[${index}].key`}
                    placeholder="Name"
                    style={{ borderRadius: "0px", border: "none" }}
                  />
                </KeyValueItem>
                <KeyValueItem>
                  {isTransferKeyPair ? (
                    <ReactPhoneInput
                      hideLabel
                      hideAutoFormatToggle
                      fieldName={`${fieldName}[${index}].value`}
                    />
                  ) : (
                    <Input
                      fieldName={`${fieldName}[${index}].value`}
                      placeholder="Phone Number"
                      style={{
                        borderRadius: "0px",
                        border: "none",
                        width: "100%",
                      }}
                    />
                  )}
                </KeyValueItem>
                <KeyValueItem>
                  <Button
                    onClick={() => remove(index)}
                    appearance={"outline"}
                    style={{
                      border: "none",
                      borderRadius: "0px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    -
                  </Button>
                </KeyValueItem>
              </KeyValueGrid>
            ))}
          </KeyValueWrapper>
          <Button
            onClick={onAdd}
            appearance="outline"
            style={{ width: "auto !important" }}
          >
            + Phone Number
          </Button>
        </>
      ) : (
        <Button
          onClick={onAdd}
          appearance="outline"
          style={{ width: "auto !important" }}
        >
          + Phone Number
        </Button>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: start;
`;

const KeyValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eeeeee;
  gap: 1px;
`;

const KeyValueGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 45px;
  background-color: #fbfbfa;
  grid-gap: 1px;
  width: 100%;
`;

const KeyValueItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
  outline: 1px solid #eeeeee;
`;
