import React, { useState, useEffect, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  FaServer,
  FaLock,
  FaChartLine,
  FaUserFriends,
  FaInfinity,
  FaGraduationCap,
} from "react-icons/fa";
import { RiFlowChart } from "react-icons/ri";
import { CgInfinity } from "react-icons/cg";
import { HiLightningBolt } from "react-icons/hi";

const colors = {
  primary: "#4F46E5",
  secondary: "#10B981",
  accent: "#F59E0B",
  text: "#1F2937",
  background: "#F3F4F6",
};

const StepContent = ({ step, isActive }) => {
  const variants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0.3,
        ease: "easeIn",
      },
    },
  };

  switch (step) {
    case 0: // Welcome to Bland AI
      return (
        <motion.div
          variants={variants}
          initial="hidden"
          animate={isActive ? "visible" : "hidden"}
          exit="exit"
          className="w-full h-screen flex items-center justify-center "
        >
          <div className="relative w-[600px] h-[400px] bg-white shadow-lg rounded-lg overflow-hidden">
            {/* Background gradient */}
            <motion.div
              className="absolute inset-0 bg-gradient-to-br from-[#f7f7f7] to-[#e6e6e6]"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
            />

            {/* Content container */}
            <div className="relative z-10 w-full h-full flex flex-col items-center justify-center p-8">
              {/* Bland AI logo (simple infinity symbol) */}
              <motion.svg
                width="80"
                height="40"
                viewBox="0 0 80 40"
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1, delay: 0.5 }}
              >
                <motion.path
                  d="M20,20 A10,10 0 1,1 40,20 A10,10 0 1,1 60,20 A10,10 0 1,1 40,20 A10,10 0 1,1 20,20"
                  fill="none"
                  stroke="#333"
                  strokeWidth="2"
                  initial={{ pathLength: 0 }}
                  animate={{ pathLength: 1 }}
                  transition={{ duration: 1.5, ease: "easeInOut" }}
                />
              </motion.svg>

              {/* Welcome text */}
              <motion.h1
                className="mt-8 text-4xl font-bold text-[#333] tracking-tight"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 1 }}
              >
                Welcome to Bland AI
              </motion.h1>

              {/* Tagline */}
              <motion.p
                className="mt-4 text-xl text-[#666] max-w-md text-center"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 1.3 }}
              >
                Redefining conversational intelligence
              </motion.p>

              {/* CTA Button */}
              <motion.button
                className="mt-8 px-6 py-3 bg-[#333] text-white rounded-md font-medium hover:bg-[#444] transition-colors duration-300"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 1.6 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Explore Bland AI
              </motion.button>
            </div>

            {/* Subtle decorative element */}
            <motion.div
              className="absolute bottom-0 left-0 right-0 h-1 bg-gradient-to-r from-[#333] via-[#666] to-[#333]"
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ duration: 1.5, delay: 2 }}
            />
          </div>
        </motion.div>
      );
    case 1: // Conversational Pathways
      return (
        <motion.div
          variants={variants}
          initial="hidden"
          animate={isActive ? "visible" : "hidden"}
          exit="exit"
          className="w-full h-96 flex items-center justify-center"
        >
          <motion.svg width="400" height="300" viewBox="0 0 400 300">
            {/* Background grid */}
            {Array.from({ length: 20 }).map((_, i) => (
              <motion.line
                key={`vertical-${i}`}
                x1={i * 20}
                y1="0"
                x2={i * 20}
                y2="300"
                stroke="#E5E7EB"
                strokeWidth="1"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 1, delay: i * 0.05 }}
              />
            ))}
            {Array.from({ length: 15 }).map((_, i) => (
              <motion.line
                key={`horizontal-${i}`}
                x1="0"
                y1={i * 20}
                x2="400"
                y2={i * 20}
                stroke="#E5E7EB"
                strokeWidth="1"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 1, delay: i * 0.05 }}
              />
            ))}

            {/* Nodes */}
            {[
              { x: 50, y: 150, label: "Start" },
              { x: 200, y: 80, label: "Process" },
              { x: 200, y: 220, label: "Decision" },
              { x: 350, y: 150, label: "End" },
            ].map((node, index) => (
              <g key={index}>
                <motion.circle
                  cx={node.x}
                  cy={node.y}
                  r="20"
                  fill={colors.primary}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 0.5 + index * 0.2, duration: 0.5 }}
                />
                <motion.text
                  x={node.x}
                  y={node.y + 35}
                  textAnchor="middle"
                  fill={colors.text}
                  fontSize="12"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.7 + index * 0.2, duration: 0.5 }}
                >
                  {node.label}
                </motion.text>
              </g>
            ))}

            {/* Connections */}
            <motion.path
              d="M70 150 C120 150, 130 80, 180 80"
              fill="none"
              stroke={colors.secondary}
              strokeWidth="2"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 1, delay: 1 }}
            />
            <motion.path
              d="M220 80 C270 80, 280 150, 330 150"
              fill="none"
              stroke={colors.secondary}
              strokeWidth="2"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 1, delay: 1.2 }}
            />
            <motion.path
              d="M200 100 L200 200"
              fill="none"
              stroke={colors.secondary}
              strokeWidth="2"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 0.5, delay: 1.4 }}
            />
            <motion.path
              d="M220 220 C270 220, 280 150, 330 150"
              fill="none"
              stroke={colors.secondary}
              strokeWidth="2"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 1, delay: 1.6 }}
            />

            {/* API Integration */}
            <motion.rect
              x="100"
              y="220"
              width="60"
              height="40"
              rx="5"
              fill={colors.accent}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 1.8, duration: 0.5 }}
            />
            <motion.text
              x="130"
              y="245"
              textAnchor="middle"
              fill={colors.text}
              fontSize="12"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2, duration: 0.5 }}
            >
              API
            </motion.text>
            <motion.path
              d="M130 220 L200 220"
              fill="none"
              stroke={colors.secondary}
              strokeWidth="2"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 0.5, delay: 2.2 }}
            />
          </motion.svg>
        </motion.div>
      );
    case 2: // Infinite Scalability
      return (
        <motion.div
          variants={variants}
          initial="hidden"
          animate={isActive ? "visible" : "hidden"}
          exit="exit"
          className="w-full h-96 flex items-center justify-center"
        >
          <motion.svg width="300" height="300" viewBox="0 0 300 300">
            {[0, 1, 2].map((i) => (
              <motion.path
                key={i}
                d={`M75,${150 - i * 30} A${75 + i * 30},${75 + i * 30} 0 1,1 225,${150 - i * 30} A${75 + i * 30},${75 + i * 30} 0 1,1 75,${150 - i * 30}`}
                fill="none"
                stroke={colors.primary}
                strokeWidth="4"
                initial={{ pathLength: 0 }}
                animate={{ pathLength: 1 }}
                transition={{ duration: 2, delay: i * 0.5, ease: "easeInOut" }}
              />
            ))}
            <motion.text
              x="150"
              y="150"
              textAnchor="middle"
              fill={colors.text}
              fontSize="24"
              fontWeight="bold"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 2, duration: 0.5 }}
            >
              ∞
            </motion.text>
          </motion.svg>
        </motion.div>
      );
    case 3: // Dedicated Infrastructure
      return (
        <motion.div
          variants={variants}
          initial="hidden"
          animate={isActive ? "visible" : "hidden"}
          exit="exit"
          className="w-full h-96 flex items-center justify-center"
        >
          <motion.svg width="300" height="300" viewBox="0 0 300 300">
            {/* Server rack */}
            <motion.rect
              x="50"
              y="50"
              width="200"
              height="200"
              fill="#E5E7EB"
              initial={{ scaleY: 0 }}
              animate={{ scaleY: 1 }}
              transition={{ duration: 0.5 }}
            />
            {[0, 1, 2, 3, 4].map((i) => (
              <motion.rect
                key={i}
                x="60"
                y={60 + i * 40}
                width="180"
                height="30"
                fill={colors.primary}
                initial={{ scaleX: 0 }}
                animate={{ scaleX: 1 }}
                transition={{ delay: 0.5 + i * 0.1, duration: 0.5 }}
              />
            ))}
            {/* Blinking lights */}
            {[0, 1, 2, 3, 4].map((i) => (
              <motion.circle
                key={i}
                cx="70"
                cy={75 + i * 40}
                r="5"
                fill={colors.accent}
                initial={{ opacity: 0 }}
                animate={{ opacity: [0, 1, 0] }}
                transition={{
                  delay: 1 + i * 0.1,
                  duration: 1,
                  repeat: Infinity,
                }}
              />
            ))}
          </motion.svg>
        </motion.div>
      );
    case 4: // Enterprise-Grade Security
      return (
        <motion.div
          variants={variants}
          initial="hidden"
          animate={isActive ? "visible" : "hidden"}
          exit="exit"
          className="w-full h-96 flex items-center justify-center"
        >
          <motion.svg width="300" height="300" viewBox="0 0 300 300">
            {/* Safe */}
            <motion.rect
              x="50"
              y="50"
              width="200"
              height="200"
              fill={colors.primary}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5 }}
            />
            <motion.circle
              cx="230"
              cy="150"
              r="15"
              fill={colors.accent}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ delay: 0.5, duration: 0.5 }}
            />
            {/* Safe door */}
            <motion.rect
              x="55"
              y="55"
              width="190"
              height="190"
              fill={colors.background}
              initial={{ rotateY: 0 }}
              animate={{ rotateY: 70 }}
              transition={{ delay: 1, duration: 1 }}
              style={{ transformOrigin: "50px 150px" }}
            />
            {/* Documents */}
            <motion.g
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2, duration: 0.5 }}
            >
              <rect x="70" y="100" width="100" height="30" fill="#FEF3C7" />
              <text
                x="120"
                y="120"
                textAnchor="middle"
                fill={colors.text}
                fontSize="12"
              >
                SOC 2
              </text>
              <rect x="70" y="140" width="100" height="30" fill="#FEE2E2" />
              <text
                x="120"
                y="160"
                textAnchor="middle"
                fill={colors.text}
                fontSize="12"
              >
                HIPAA
              </text>
            </motion.g>
          </motion.svg>
        </motion.div>
      );
    case 5: // Comprehensive Analytics
      return (
        <motion.div
          variants={variants}
          initial="hidden"
          animate={isActive ? "visible" : "hidden"}
          exit="exit"
          className="w-full h-96 flex items-center justify-center"
        >
          <motion.svg width="300" height="300" viewBox="0 0 300 300">
            {/* Chart background */}
            <motion.rect
              x="50"
              y="50"
              width="200"
              height="200"
              fill="#F3F4F6"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
            {/* Chart bars */}
            {[0, 1, 2, 3, 4].map((i) => (
              <motion.rect
                key={i}
                x={70 + i * 40}
                y={250 - i * 30}
                width="30"
                height={i * 30}
                fill={colors.primary}
                initial={{ scaleY: 0 }}
                animate={{ scaleY: 1 }}
                transition={{ delay: 0.5 + i * 0.1, duration: 0.5 }}
              />
            ))}
            {/* Chart line */}
            <motion.path
              d="M70 250 L110 220 L150 190 L190 160 L230 130"
              fill="none"
              stroke={colors.secondary}
              strokeWidth="2"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ delay: 1, duration: 1 }}
            />
            {/* Data points */}
            {[0, 1, 2, 3, 4].map((i) => (
              <motion.circle
                key={i}
                cx={70 + i * 40}
                cy={250 - i * 30}
                r="5"
                fill={colors.accent}
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ delay: 2 + i * 0.1, duration: 0.3 }}
              />
            ))}
          </motion.svg>
        </motion.div>
      );
    case 6: // Low Latency Solutions
      return (
        <motion.div
          variants={variants}
          initial="hidden"
          animate={isActive ? "visible" : "hidden"}
          exit="exit"
          className="w-full h-96 flex items-center justify-center"
        >
          <motion.svg width="300" height="300" viewBox="0 0 300 300">
            {/* Connection line */}
            <motion.path
              d="M50 150 L250 150"
              stroke={colors.primary}
              strokeWidth="4"
              initial={{ pathLength: 0 }}
              animate={{ pathLength: 1 }}
              transition={{ duration: 0.5 }}
            />
            {/* Start and end points */}
            <motion.circle cx="50" cy="150" r="10" fill={colors.primary} />
            <motion.circle cx="250" cy="150" r="10" fill={colors.primary} />
            {/* Data packet */}
            <motion.circle
              cx="50"
              cy="150"
              r="8"
              fill={colors.accent}
              initial={{ x: 0 }}
              animate={{ x: 200 }}
              transition={{ delay: 0.5, duration: 0.3, ease: "easeOut" }}
            />
            {/* Lightning bolt */}
            <motion.path
              d="M140 130 L160 150 L140 170"
              stroke={colors.secondary}
              strokeWidth="3"
              fill="none"
              initial={{ opacity: 0 }}
              animate={{ opacity: [0, 1, 0] }}
              transition={{ delay: 0.8, duration: 0.3, times: [0, 0.5, 1] }}
            />
          </motion.svg>
        </motion.div>
      );
    case 7: // Enterprise-Ready Platform
      return (
        <motion.div
          variants={variants}
          initial="hidden"
          animate={isActive ? "visible" : "hidden"}
          exit="exit"
          className="w-full h-96 flex items-center justify-center"
        >
          <motion.svg width="400" height="400" viewBox="0 0 400 400">
            {/* Enterprise building */}
            <motion.rect
              x="100"
              y="100"
              width="200"
              height="250"
              fill={colors.primary}
              initial={{ scaleY: 0 }}
              animate={{ scaleY: 1 }}
              transition={{ duration: 0.5 }}
            />

            {/* Windows */}
            {[0, 1, 2, 3, 4].map((row) =>
              [0, 1, 2].map((col) => (
                <motion.rect
                  key={`window-${row}-${col}`}
                  x={120 + col * 60}
                  y={120 + row * 50}
                  width="40"
                  height="30"
                  fill={colors.background}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.5 + (row * 3 + col) * 0.1 }}
                />
              )),
            )}

            {/* Platform base */}
            <motion.rect
              x="50"
              y="350"
              width="300"
              height="20"
              fill={colors.secondary}
              initial={{ scaleX: 0 }}
              animate={{ scaleX: 1 }}
              transition={{ delay: 1, duration: 0.5 }}
            />

            {/* Text */}
            <motion.text
              x="200"
              y="390"
              textAnchor="middle"
              fill={colors.text}
              fontSize="24"
              fontWeight="bold"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.5, duration: 0.5 }}
            >
              Enterprise Ready Platform
            </motion.text>
          </motion.svg>
        </motion.div>
      );

    // Corrected Twilio and PayPal Logos
    case 8: // Backed by Industry Leaders
      return (
        <motion.div
          variants={variants}
          initial="hidden"
          animate={isActive ? "visible" : "hidden"}
          exit="exit"
          className="w-full h-96 flex flex-col items-center justify-center bg-white p-8"
        >
          {/* Main heading */}
          <motion.h2
            className="text-3xl font-bold text-gray-800 mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Backed by founders of
          </motion.h2>

          {/* Twilio */}
          <motion.div
            className="mb-6"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <h3 className="text-4xl font-bold text-[#F22F46]">Twilio</h3>
          </motion.div>

          {/* PayPal */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            <h3 className="text-4xl font-bold text-[#003087]">PayPal</h3>
          </motion.div>

          {/* Subtle separator */}
          <motion.div
            className="w-16 h-1 bg-gray-200 my-8"
            initial={{ scaleX: 0 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.6, delay: 0.9 }}
          />

          {/* Additional context */}
          <motion.p
            className="text-xl text-gray-600 text-center max-w-md"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 1.2 }}
          >
            Bringing years of industry expertise and innovation to Bland AI
          </motion.p>
        </motion.div>
      );
    case 9: // Bland University
      return (
        <motion.div
          variants={variants}
          initial="hidden"
          animate={isActive ? "visible" : "hidden"}
          exit="exit"
          className="w-full h-96 flex flex-col items-center justify-center bg-white p-8"
        >
          {/* Main heading */}
          <motion.h2
            className="text-3xl font-bold text-gray-800 mb-8"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Need help? Visit Bland University
          </motion.h2>

          {/* Graduation cap icon */}
          <motion.div
            className="text-6xl text-blue-600 mb-6"
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.3 }}
          >
            <FaGraduationCap />
          </motion.div>

          {/* Description */}
          <motion.p
            className="text-xl text-gray-600 text-center max-w-md mb-8"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.6 }}
          >
            Unlock the full potential of Bland AI with our comprehensive
            learning resources and tutorials.
          </motion.p>

          {/* CTA Button */}
          <motion.a
            href="https://university.bland.ai/"
            target="_blank"
            rel="noopener noreferrer"
            className="px-6 py-3 bg-blue-600 text-white rounded-md font-medium hover:bg-blue-700 transition-colors duration-300"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.9 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Visit Bland University
          </motion.a>
        </motion.div>
      );
    default:
      return null;
  }
};

const steps = [
  {
    title: "",
    description: "",
    icon: FaInfinity,
  },
  {
    title: "Conversational Pathways",
    description:
      "Our revolutionary programming language for complex AI interactions",
    icon: RiFlowChart,
  },
  {
    title: "Infinite Scalability",
    description: "Expand your conversational AI capabilities without limits",
    icon: CgInfinity,
  },
  {
    title: "Dedicated Infrastructure",
    description:
      "Built on robust, self-hosted technology for unparalleled performance",
    icon: FaServer,
  },
  {
    title: "Enterprise-Grade Security",
    description:
      "State-of-the-art protection for your sensitive data and conversations",
    icon: FaLock,
  },
  {
    title: "Comprehensive Analytics",
    description: "Gain deep insights into your AI interactions and performance",
    icon: FaChartLine,
  },
  {
    title: "Low Latency Solutions",
    description: "Lightning-fast response times for seamless conversations",
    icon: HiLightningBolt,
  },
  {
    title: "",
    description: "",
    icon: FaUserFriends,
  },
  {
    title: "Backed by Industry Leaders",
    description: "Trusted and supported by founders of Twilio and PayPal",
    icon: FaInfinity,
  },
  {
    title: "Bland University",
    description: "Your gateway to mastering Bland",
    icon: FaGraduationCap,
  },
];

const Timer = ({ duration, currentTime }) => {
  const progress = (currentTime / duration) * 100;

  return (
    <div className="w-8 h-8 relative">
      <svg className="w-full h-full" viewBox="0 0 100 100">
        <circle
          className="text-gray-200"
          strokeWidth="8"
          stroke="currentColor"
          fill="transparent"
          r="46"
          cx="50"
          cy="50"
        />
        <motion.circle
          className="text-blue-600"
          strokeWidth="8"
          strokeLinecap="round"
          stroke="currentColor"
          fill="transparent"
          r="46"
          cx="50"
          cy="50"
          style={{
            strokeDasharray: `${2 * Math.PI * 46}`,
            strokeDashoffset: `${((100 - progress) / 100) * (2 * Math.PI * 46)}`,
          }}
          initial={{ strokeDashoffset: 0 }}
          animate={{
            strokeDashoffset: `${((100 - progress) / 100) * (2 * Math.PI * 46)}`,
          }}
          transition={{ duration: 0.5, ease: "linear" }}
        />
      </svg>
    </div>
  );
};
const SophisticatedEducationalStage = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isAutoAdvancing, setIsAutoAdvancing] = useState(true);
  const [timer, setTimer] = useState(5); // 15 seconds per step
  const stepDuration = 5;

  const advanceStep = useCallback(() => {
    console.log("advancing");
    if (currentStep < steps.length - 1) {
      setCurrentStep((prevStep) => prevStep + 1);
      setTimer(stepDuration); // Reset timer when advancing
    } else if (currentStep === steps.length - 1) {
      onComplete(4);
    }
  }, [currentStep, onComplete, stepDuration]);

  useEffect(() => {
    let interval;
    if (isAutoAdvancing && timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      advanceStep();
    }
    return () => clearInterval(interval);
  }, [isAutoAdvancing, timer, advanceStep]);

  const handleManualAdvance = () => {
    setIsAutoAdvancing(false);
    advanceStep();
  };

  const handlePrevious = () => {
    setIsAutoAdvancing(false);
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
      setTimer(stepDuration); // Reset timer when going to previous step
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen  font-sans text-gray-900 overflow-hidden">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
        className="w-full max-w-4xl p-8 bg-white rounded-lg shadow-lg relative"
      >
        <AnimatePresence mode="wait">
          <motion.div
            key={currentStep}
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.5 }}
            className="flex flex-col items-center space-y-8"
          >
            <StepContent step={currentStep} isActive={true} />
            <motion.h3
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3, duration: 0.5 }}
              className="text-3xl font-bold text-gray-800 mt-4"
            >
              {steps[currentStep].title}
            </motion.h3>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4, duration: 0.5 }}
              className="text-xl text-gray-600 text-center max-w-2xl"
            >
              {steps[currentStep].description}
            </motion.p>
          </motion.div>
        </AnimatePresence>
      </motion.div>
      <div className="mt-8 flex items-center space-x-4 fixed bottom-4 ">
        <Timer
          duration={stepDuration}
          isActive={isAutoAdvancing}
          onComplete={advanceStep}
        />
        <div className="flex space-x-2">
          {steps.map((_, index) => (
            <motion.div
              key={index}
              className={`w-2 h-2 rounded-full ${index === currentStep ? "bg-blue-600" : "bg-gray-300"}`}
              initial={{ scale: 0.8 }}
              animate={{ scale: index === currentStep ? 1.2 : 1 }}
              transition={{ duration: 0.3 }}
            />
          ))}
        </div>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="px-3 py-1 bg-transparent text-gray-600 rounded-md border border-gray-300 hover:bg-gray-50 transition-colors duration-200 text-sm"
          style={{ boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.1)" }}
          onClick={handlePrevious}
          disabled={currentStep === 0}
        >
          Previous
        </motion.button>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="px-3 py-1 bg-transparent text-blue-600 rounded-md border border-blue-300 hover:bg-blue-50 transition-colors duration-200 text-sm"
          style={{ boxShadow: "1px 1px 0px rgba(59, 130, 246, 0.1)" }}
          onClick={handleManualAdvance}
        >
          {currentStep === steps.length - 1 ? "Finish" : "Next"}
        </motion.button>
      </div>
    </div>
  );
};

const KeyboardNavigation = ({ onNext, onPrevious }) => {
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "ArrowRight") {
        onNext();
      } else if (e.key === "ArrowLeft") {
        onPrevious();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onNext, onPrevious]);

  return null;
};

const FloatingHint = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 1, duration: 0.5 }}
      className="fixed bottom-16 bg-white p-2 rounded-md shadow-sm text-sm text-gray-500"
    >
      Use arrow keys or buttons to navigate
    </motion.div>
  );
};

const BackgroundAnimation = () => {
  return (
    <div className="fixed inset-0 pointer-events-none">
      {[...Array(20)].map((_, i) => (
        <motion.div
          key={i}
          className="absolute w-2 h-2 bg-blue-500 rounded-full opacity-20"
          initial={{
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
          }}
          animate={{
            x: Math.random() * window.innerWidth,
            y: Math.random() * window.innerHeight,
          }}
          transition={{
            duration: Math.random() * 10 + 10,
            repeat: Infinity,
            repeatType: "reverse",
          }}
        />
      ))}
    </div>
  );
};

const EducationalStage = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = useCallback(() => {
    if (currentStep < steps.length - 1) {
      const newStep = currentStep + 1;
      setCurrentStep(newStep);
    } else {
      onComplete(4);
    }
  }, [currentStep, onComplete]);

  const handlePrevious = useCallback(() => {
    if (currentStep > 0) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  }, [currentStep]);

  return (
    <div className="relative min-h-screen overflow-hidden">
      <BackgroundAnimation />
      <SophisticatedEducationalStage
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        onComplete={onComplete}
      />
      <KeyboardNavigation onNext={handleNext} onPrevious={handlePrevious} />
      <FloatingHint />
    </div>
  );
};

// Smooth scroll to top when changing steps
const ScrollToTop = ({ dependencies }) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, dependencies);

  return null;
};

// Main component
const EnhancedEducationalStage = ({ onComplete }) => {
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="relative min-h-screen overflow-hidden"
    >
      <ScrollToTop dependencies={[currentStep]} />
      <EducationalStage
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        onComplete={onComplete}
      />
    </motion.div>
  );
};

// Custom hook for preloading images
const useImagePreloader = (imageUrls) => {
  const [imagesPreloaded, setImagesPreloaded] = useState(false);

  useEffect(() => {
    let isMounted = true;

    const preloadImages = async () => {
      const promises = imageUrls.map((url) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = url;
          img.onload = resolve;
          img.onerror = reject;
        });
      });

      await Promise.all(promises);
      if (isMounted) {
        setImagesPreloaded(true);
      }
    };

    preloadImages();

    return () => {
      isMounted = false;
    };
  }, [imageUrls]);

  return imagesPreloaded;
};

// Loader component
const Loader = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-white">
    <div className="w-16 h-16 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
  </div>
);

// Main export
export default function SophisticatedEducationalStageWithPreloader({
  onComplete,
}) {
  const imageUrls = [
    // Add any image URLs used in your animations here
  ];

  const imagesPreloaded = useImagePreloader(imageUrls);

  if (!imagesPreloaded) {
    return <Loader />;
  }

  return <EnhancedEducationalStage onComplete={onComplete} />;
}
