import React, { useEffect, useState } from "react";
import ReactPhoneInput from "../components/core/PhoneInput";
import CodeInput from "components/core/CodeInput";
import AccountSelect from "../components/Auth/Login/AccountSelect";
import Button from "components/core/Button";
import lockIcon from "assets/icons/lock-icon.svg";
import FormHeader from "components/Auth/FormHeader";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { verifyOtp } from "utils/funcs/auth/login/verifyOtp";
import { sendOtp } from "utils/funcs/auth/login/sendOtp";
import { SignupCat } from "components/Auth/Login/SignupCat";
import { Container2xl, ContainerS } from "../components/core/Container";
import { Promotion } from "../components/Auth/Login/Promotion";
import { loginSchema } from "../utils/validation/loginSchema";
import { Mixpanel } from "components/Mixpanel";
import { useNavigate } from "react-router-dom";
import { setAuthToken } from "utils/funcs/browser/setAuthToken";
import { verifyUser } from "utils/funcs/auth/verifyUser";
import styled from "styled-components";
import { toast } from "react-toastify";

export default function Login() {
  const navigate = useNavigate();

  useEffect(() => {
    const verify = async () => {
      const response = await verifyUser();
      if (!response?.is_user_verified) {
        console.log("User is not verified:", response?.is_user_verified);
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        navigate("/dashboard?page=home");
      }
    };
    verify();
  }, [navigate]);

  const methods = useForm({
    defaultValues: {
      isCodeStage: false,
      rememberMe: false,
    },
    resolver: yupResolver(loginSchema),
  });

  const [loading, setLoading] = useState(false);
  const [codeVerified, setCodeVerified] = useState(false);
  const [authorizedAccounts, setAuthorizedAccounts] = useState([]);
  const [stage, setStage] = useState(1);

  const onSubmit = async (formData) => {
    if (stage === 1) {
      setLoading(true);
      const { code_sent, error } = await sendOtp(formData.phone);
      setLoading(false);
      if (code_sent) {
        setStage(2);
      } else {
        console.error("Error sending code from client side", error);
        if (error.response.data.error === "User does not exist") {
          toast.error("User does not exist.");
        }
      }
    } else if (stage === 2) {
      methods.setValue("isCodeStage", true);
      setLoading(true);
      const { authorized_accounts, jwt, error } = await verifyOtp(
        formData.code,
        formData.phone,
        formData.rememberMe,
      );

      if (error) {
        console.log("error", error);
        toast.error(
          "Authentication failed. Please sign up if you don't have an account or try again.",
        );
        setStage(1);
      }
      setLoading(false);
      setCodeVerified(false);

      if (error) {
        console.log("Authorized account(s) error on client side:", error);
      } else {
        if (authorized_accounts?.length > 0) {
          console.log(
            "Authorized account(s) object client side:",
            authorized_accounts,
          );
          setAuthorizedAccounts(authorized_accounts);
        } else {
          console.log(
            "No authorized accounts. Setting an auth token & navigating user to dashboard...",
          );
          setAuthToken(jwt);
          navigate("/dashboard?page=home");
        }
      }
    }
  };

  useEffect(() => {
    Mixpanel.track("Login page");
  }, []);

  return (
    <Container2xl>
      <div className="flex flex-row h-screen">
        <div className="flex flex-col w-full items-center justify-center">
          <ContainerS>
            <FormProvider {...methods}>
              <Form onSubmit={methods.handleSubmit(onSubmit)}>
                <>
                  {stage === 1 && (
                    <>
                      <FormHeader
                        title="Access your portal"
                        subtitle="Login below"
                      />
                      <FormFields>
                        <ReactPhoneInput fieldName="phone" />
                      </FormFields>
                      <Button submit iconSrc={lockIcon} loading={loading}>
                        Get Code
                      </Button>
                      <SignupCat />
                    </>
                  )}
                  {stage === 2 && authorizedAccounts.length === 0 && (
                    <>
                      {!codeVerified && (
                        <>
                          <FormFields>
                            <CodeInput fieldName="code" />
                            <RememberMeWrapper>
                              <input
                                type="checkbox"
                                id="rememberMe"
                                {...methods.register("rememberMe")}
                              />
                              <label htmlFor="rememberMe">Remember me</label>
                            </RememberMeWrapper>
                          </FormFields>
                          <Button submit iconSrc={lockIcon} loading={loading}>
                            Verify
                          </Button>
                        </>
                      )}
                    </>
                  )}
                  {authorizedAccounts.length > 0 && (
                    <AccountSelect authorizedAccounts={authorizedAccounts} />
                  )}
                  <input type="hidden" {...methods.register("isCodeStage")} />
                </>
              </Form>
            </FormProvider>
          </ContainerS>
        </div>
        <Promotion />
      </div>
    </Container2xl>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const RememberMeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  label {
    font-size: 14px;
    color: #333;
    cursor: pointer;
  }
`;
