import TabSelect from "components/core/TabSelect";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"; // Import the question mark icon
import LightTooltip from "./ToolTipDesc";

export default function Model({ fieldName }) {
  const longText = (
    <span>
      Select a model to use for your call. <br />
      Options: <br />
      <strong>base</strong>, <strong>turbo</strong> and
      <strong> enhanced</strong>. In nearly all cases, <strong>enhanced</strong>{" "}
      is the best choice
      <br></br>
      <i>
        {/* <b>gpt4</b>
        <br></br> - Slow but accurate Supports all features and capabilities.
        <br></br> - Best for complex tasks where latency isnt a priority.
        <br></br> */}
        <b>base</b>
        <br></br> - The original, follows scripts/procedures most effectively.
        <br></br> - Supports all features and capabilities.
        <br></br> - Best for Custom Tools
        <br></br>
        <b>enhanced</b>
        <br></br> - Much faster latency and very conversational, works best with
        objective-based prompts.
        <br></br> -Supports all features and capabilities.
        <br></br>
        <b>turbo</b>
        <br></br> - The absolute fastest latency possible, can be verbose at
        times
        <br></br> - Limited capabilities currently (excludes Transferring, IVR
        navigation, Custom Tools)
        <br></br> - Extremely realistic conversation capabilities
      </i>
    </span>
  );
  return (
    <Tooltip>
      <TabSelect
        fieldName={fieldName}
        label={
          <span>
            Model
            <LightTooltip title={longText} placement="right-start" arrow>
              <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
            </LightTooltip>
          </span>
        }
        items={[
          { name: "Base", value: "base" },
          { name: "Enhanced", value: "enhanced" },
          { name: "Turbo", value: "turbo" },
          // { name: "GPT4", value: "gpt4" },
        ]}
      />
    </Tooltip>
  );
}
