export function getApiKey() {
  let userData = localStorage.getItem("user");
  if (!userData) {
    console.log("No user data set in localStorage api:", userData);
    return;
  } else {
    userData = JSON.parse(userData);
    const apiKey = userData.user.api_key;
    return apiKey;
  }
}
