import Button from "components/core/Button";

export const Promotion = () => {
  return (
    <div className="w-full h-full p-8 hidden lg:block">
      <div className="rounded-md w-full h-full bg-light flex flex-col justify-between p-16">
        <h2 className="text-7xl font-medium">
          <span className="text-altdark">
            Welcome back <br />
          </span>{" "}
          <span className="text-grayblue text-5xl">
            You're building the future
          </span>
        </h2>
        <div className="flex flex-col gap-6">
          <p className="text-altdark">
            Work with a dedicated Bland AI machine learning engineer to create a
            custom phone agent and automate all your organization's phone calls.
          </p>
          <a href="https://app.bland.ai/enterprise">
            <Button>Enterprise inquiry</Button>
          </a>
        </div>
      </div>
    </div>
  );
};
