import React, { useState } from "react";
import styled from "styled-components";
import ModalInput from "./Input";
import { objectToKeyValue } from "utils/formatting/objToKeyValues";
import Button from "components/core/Button";
import Label from "components/core/Label";
import TrashIcon from "../../../../assets/icons/trash-grey-fig.svg";

const KeyValues = ({
  obj,
  onChange,
  nestedKey,
  description = null,
  isProperty = false,
  isAllowedToAdd = true,
  externalOnChange = false,
  addFn = null,
  removeFn = null,
}) => {
  const handleChange = (e, index, key) => {
    if (externalOnChange) {
      return onChange(e, index, key);
    }

    const updatedObj = [...obj];
    updatedObj[index][key] = e.target.value;
    onChange((prevState) => ({ ...prevState, [nestedKey]: updatedObj }));
  };

  const handleAdd = () => {
    if (externalOnChange) {
      return addFn();
    }
    onChange((prevState) => ({
      ...prevState,
      [nestedKey]: [...prevState[nestedKey], { key: "", value: "" }],
    }));
  };

  const handleRemove = (index) => {
    if (index === 0 && obj.length === 1) {
      return;
    }

    if (externalOnChange) {
      return removeFn(index);
    }
    const updatedObj = [...obj];
    updatedObj.splice(index, 1);
    onChange((prevState) => ({ ...prevState, [nestedKey]: updatedObj }));
  };

  return (
    <Wrapper>
      <Label>{nestedKey.charAt(0).toUpperCase() + nestedKey.slice(1)}</Label>
      {description && (
        <p
          className="text-secondary mb-0"
          style={{ fontSize: 12, marginTop: "-5px" }}
        >
          {description}
        </p>
      )}
      <KeyValueWrapper>
        {Array.isArray(obj) &&
          obj?.map((value, key) => (
            <KeyValueGrid key={key} isProperty={isProperty}>
              <KeyValueItem>
                <ModalInput
                  isborder={false}
                  placeholder={isProperty ? "phone_number" : "Key"}
                  value={value.key}
                  onChange={(e) => handleChange(e, key, "key")}
                />
              </KeyValueItem>
              <KeyValueItem>
                <ModalInput
                  isborder={false}
                  placeholder={isProperty ? "string" : "Key"}
                  value={value.value}
                  onChange={(e) => handleChange(e, key, "value")}
                />
              </KeyValueItem>
              {isProperty && (
                <KeyValueItem>
                  <ModalInput
                    isborder={false}
                    placeholder="string +13214442322"
                    value={value.example}
                    onChange={(e) => handleChange(e, key, "example")}
                  />
                </KeyValueItem>
              )}
              <KeyValueItem style={{ borderRight: "none" }}>
                <Button
                  onClick={() => handleRemove(key)}
                  appearance={"outline"}
                  style={{
                    border: "none",
                    borderRadius: "0px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <img src={TrashIcon} alt="trash" />
                </Button>
              </KeyValueItem>
            </KeyValueGrid>
          ))}
      </KeyValueWrapper>

      {isAllowedToAdd && (
        <AddButton onClick={handleAdd} style={{ width: "auto !important" }}>
          <p
            style={{
              margin: 0,
              padding: 0,
              color: "#0F161A",
              fontSize: "12px",
              lineHeight: "18px",
              fontWeight: "500",
            }}
          >
            + Key/Value
          </p>
        </AddButton>
      )}
    </Wrapper>
  );
};

const AddButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f9fafb;
  color: #0f161a;
  border: none;
  border-radius: 4px;
  padding: 5px 8px 5px 8px;
  width: 100%;
  height: 40px;
  border: 1px solid #e5e7eb;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    background: ##f9fafb;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: start;
`;

const KeyValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eeeeee;
  border-bottom: none;
  gap: 1px;
`;

const KeyValueGrid = styled.div`
  display: grid;
  grid-template-columns: ${({ isProperty }) =>
    isProperty ? "1fr 1fr 1fr 45px" : "1fr 1fr 45px"};
  background-color: #ffffff;
  grid-gap: 1px;
  width: 100%;
`;

const KeyValueItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: end;
  box-sizing: border-box;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
`;

const Empty = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: start;
  width: 100%;
  justify-content: start;
`;

export default KeyValues;
