import axios from "axios";

export const sendWelcomeCall = async (phoneNumber, firstName) => {
  try {
    const response = await axios.post("/api/welcome/call", {
      phoneNumber: phoneNumber,
      firstName: firstName,
    });

    if (!response.ok) {
      console.error(
        "HTTP error making request to /api/welcome/call:",
        await response.json(),
      );
    }

    const responseData = await response.json();

    if (responseData) {
      console.log(`Success sending welcome call to ${phoneNumber}`);
      return { call_sent: true };
    }
  } catch (error) {
    console.error(
      "Error hitting /api/welcome/call in sendWelcomeCall()",
      error,
    );
    return { error: error };
  }
};
