import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import Button from "components/core/Button";
import { useState } from "react";
import styled from "styled-components";

export default function PaymentMethodForm() {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      console.error("Stripe has not loaded yet.");
      return;
    }
    setLoading(true);
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${window.location}`,
      },
    });
    setLoading(false);
    if (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <PaymentElement />
        <Button loading={loading} submit>
          Save
        </Button>
      </Form>
    </>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
