import RangeInput from "components/core/RangeInput";
import { useFormContext } from "react-hook-form";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LightTooltip from "./ToolTipDesc";

export default function Temperature({ fieldName }) {
  const { watch } = useFormContext();

  let currTemp = watch(fieldName);

  const description = (
    <span>
      A value between 0 and 1 that controls the randomness of the LLM. 0 will
      cause more deterministic outputs while 1 will cause more random.
      <br />
      Example Values: <b>"0.9", "0.3", "0.5"</b>
    </span>
  );

  return (
    <RangeInput
      fieldName={fieldName}
      label={
        <span>
          {`Temperature: ${currTemp ?? "Model Default"}`}
          <LightTooltip title={description} placement="right-start" arrow>
            <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
          </LightTooltip>
        </span>
      }
      min="0"
      max="1"
      step="0.1"
      value={currTemp}
      sliderValue={currTemp}
      id="tempSlider"
    />
  );
}
