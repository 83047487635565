import Input from "components/core/Input";
import { useFormContext } from "react-hook-form";
import LightTooltip from "./ToolTipDesc";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function MaxDuration({ fieldName }) {
  const { watch } = useFormContext();

  const currentMaxDuration = watch("max_duration");
  const description = (
    <span>
      When the call starts, a timer is set for the <b>max_duration</b> minutes.
      At the end of that timer, if the call is still active it will be
      automatically ended. <br></br>Example Values:
      <b> 20, 2</b>
    </span>
  );
  return (
    <Input
      fieldName={fieldName}
      label={
        <span>
          Max Duration (mins)
          <LightTooltip title={description} placement="right-start" arrow>
            <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
          </LightTooltip>
        </span>
      }
      type="number"
      min="1"
      placeholder="12"
      value={currentMaxDuration}
    />
  );
}
