import { useNavigate } from "react-router-dom";

export const SignupCat = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-row gap-1 mx-auto mt-4 mb-4 sm:mt-8 sm:mb-0 ">
      Need to create an account?
      <p
        onClick={() => {
          navigate("/signup");
        }}
        className="font-bold font-bold hover:cursor-pointer decoration-solid"
      >
        Signup
      </p>
    </div>
  );
};
