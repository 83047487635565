import { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ExamplesSection from "./ExamplesSection"; // Assuming this is the correct import for your ExamplesSection component

const FineTuningExamples = ({
  id,
  dialogueExamples,
  setDialogueExamples,
  pathwayExamples,
  setPathwayExamples,
  conditionExamples,
  setConditionExamples,
}) => {
  const [examplesDropdown, setExamplesDropdown] = useState(false);
  const getTotalExamplesCount = () => {
    return (
      dialogueExamples.length +
      pathwayExamples.length +
      conditionExamples.length
    );
  };
  return (
    <div className="border p-4 rounded-md shadow-sm">
      <div className="flex items-center justify-between cursor-pointer">
        <h3 className="text-xl flex items-center">
          Fine-tuning Examples
          <span className="ml-2 bg-indigo-500 text-white text-sm font-semibold px-2 py-1 rounded-full">
            {getTotalExamplesCount()}
          </span>
        </h3>
        <button
          onClick={() => setExamplesDropdown(!examplesDropdown)}
          className="text-xl"
        >
          {examplesDropdown ? <FaChevronUp /> : <FaChevronDown />}
        </button>
      </div>
      <p className="text-lg text-gray-600 mt-1">
        Add Examples to train the AI on handling edge-cases and improve the
        accuracy of the model. Provide the user's response or the conversation
        history, and the corresponding expected response from the agent.
      </p>
      {examplesDropdown && (
        <div className="space-y-4 mt-4">
          <ExamplesSection
            id={id}
            examples={dialogueExamples}
            setExamples={setDialogueExamples}
            type="Assistant Response"
          />
          <ExamplesSection
            id={id}
            examples={pathwayExamples}
            setExamples={setPathwayExamples}
            type="Chosen Pathway"
          />
          <ExamplesSection
            id={id}
            examples={conditionExamples}
            setExamples={setConditionExamples}
            type="Condition Achieved"
          />
        </div>
      )}
    </div>
  );
};

export default FineTuningExamples;
