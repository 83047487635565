import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useContext,
} from "react";
import styled from "styled-components";
import { motion, AnimatePresence } from "framer-motion";
import {
  User,
  Bot,
  Clock,
  Filter,
  BarChart2,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Loading } from "components/core/Loading";
import FlowContext from "components/Dashboard/ConvoPathways/contextFlow";

const ModalWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 54px;
  width: calc(25% - 54px);
  height: 100%;
  background-color: white;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 50;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const DialogueContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
`;

const MessageWrapper = styled(motion.div)`
  transition: all 0.3s ease-in-out;
`;

const DialogueDisplay = ({
  dialogue,
  replayIndex,
  loading,
  error,
  onReplayIndexChange,
  isInitialLoading,
  callPath,
}) => {
  const { elements } = useContext(FlowContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("all");
  const [showAnalytics, setShowAnalytics] = useState(false);
  const itemsPerPage = 7;

  const filteredDialogue = useMemo(() => {
    if (filter === "all") return dialogue;
    return dialogue.filter((message) => message.role === filter);
  }, [dialogue, filter]);

  const totalPages = useMemo(
    () => Math.ceil(filteredDialogue.length / itemsPerPage),
    [filteredDialogue.length],
  );

  const paginatedDialogue = useMemo(
    () =>
      filteredDialogue.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage,
      ),
    [filteredDialogue, currentPage],
  );

  const handlePrevPage = useCallback(
    () => setCurrentPage((prev) => Math.max(prev - 1, 1)),
    [],
  );
  const handleNextPage = useCallback(
    () => setCurrentPage((prev) => Math.min(prev + 1, totalPages)),
    [totalPages],
  );

  const handleFilterChange = useCallback((newFilter) => {
    setFilter(newFilter);
    setCurrentPage(1);
  }, []);

  const analyticsData = useMemo(() => {
    const data = dialogue.reduce((acc, message, index) => {
      const minute = Math.floor(index / 2);
      if (!acc[minute]) {
        acc[minute] = { minute, userWords: 0, aiWords: 0 };
      }
      const wordCount = message.text.split(" ").length;
      if (message.role === "user") {
        acc[minute].userWords += wordCount;
      } else {
        acc[minute].aiWords += wordCount;
      }
      return acc;
    }, {});
    return Object.values(data);
  }, [dialogue]);

  useEffect(() => {
    const newPage = Math.floor(replayIndex / itemsPerPage) + 1;
    if (newPage !== currentPage) {
      setCurrentPage(newPage);
    }
  }, [replayIndex, itemsPerPage]);

  return (
    <ModalWrapper
      initial={{ x: "-100%" }}
      animate={{ x: 0 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
    >
      <DialogueContent>
        <div className="mb-4 flex justify-between items-center">
          <h3 className="text-xl font-semibold">Call Replay</h3>
          <div className="flex space-x-2">
            <button
              onClick={() => setShowAnalytics(!showAnalytics)}
              className="p-2 bg-gray-100 rounded-md hover:bg-gray-200"
            >
              <BarChart2 size={20} />
            </button>
            <button
              onClick={() =>
                handleFilterChange(
                  filter === "all"
                    ? "user"
                    : filter === "user"
                      ? "assistant"
                      : "all",
                )
              }
              className="p-2 bg-gray-100 rounded-md hover:bg-gray-200"
            >
              <Filter size={20} />
            </button>
          </div>
        </div>

        {showAnalytics && (
          <div className="mb-6 p-4 bg-gray-50 rounded-lg">
            <h4 className="text-lg font-semibold mb-4">
              Conversation Analytics
            </h4>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={analyticsData}>
                <XAxis dataKey="minute" />
                <YAxis />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="userWords"
                  stroke="#3b82f6"
                  name="User Words"
                />
                <Line
                  type="monotone"
                  dataKey="aiWords"
                  stroke="#10b981"
                  name="AI Words"
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}

        {loading && (
          <div className="text-center">
            <Loading loading={loading} />
          </div>
        )}
        {error && <div className="text-center text-red-500">{error}</div>}

        <AnimatePresence initial={false} mode="wait">
          <motion.div
            key={currentPage}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
          >
            {paginatedDialogue.map((message, index) => {
              const globalIndex = (currentPage - 1) * itemsPerPage + index;
              const isHighlighted = globalIndex === replayIndex;

              const currNodeId =
                callPath[Math.min(globalIndex, callPath.length - 1)];

              const currNode = elements.nodes.find(
                (el) => el.id === currNodeId,
              );
              return (
                <MessageWrapper
                  key={`${currentPage}-${index}`}
                  className={`p-4 rounded-lg mb-2 ${
                    message.role === "assistant"
                      ? "bg-gray-50 border-l-4 border-black"
                      : "bg-blue-50 border-l-4 border-blue-500"
                  } ${
                    isHighlighted
                      ? "ring-2 ring-blue-500 shadow-lg shadow-blue-200"
                      : ""
                  } ${
                    globalIndex <= replayIndex ? "opacity-100" : "opacity-50"
                  }`}
                  onClick={() => onReplayIndexChange(globalIndex)}
                >
                  <div className="flex items-center mb-2">
                    {message.role === "assistant" ? (
                      <Bot size={20} className="mr-2 text-black" />
                    ) : (
                      <User size={20} className="mr-2 text-blue-500" />
                    )}
                    <p className="font-medium text-gray-800">
                      {message.role === "assistant" ? "AI" : "User"}
                    </p>
                    <Clock size={16} className="ml-auto mr-2 text-gray-400" />
                    <p className="text-xs text-gray-500">
                      {new Date(message.created_at).toLocaleTimeString()}
                    </p>
                  </div>
                  <p className="text-sm text-gray-700 ml-7">{message.text}</p>
                  <div className="flex justify-between mt-2 text-xs text-gray-500 ml-7">
                    <span>Node: {currNode?.data?.name}</span>
                    <span>{message.text.split(" ").length} words</span>
                  </div>
                </MessageWrapper>
              );
            })}
          </motion.div>
        </AnimatePresence>

        <div className="flex justify-between items-center mt-4">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className={`flex items-center ${
              currentPage === 1
                ? "text-gray-400 cursor-not-allowed"
                : "text-black hover:text-gray-700"
            }`}
          >
            <ChevronLeft size={20} className="mr-1" />
            Previous
          </button>
          <span className="text-sm text-gray-600">
            {isInitialLoading || totalPages === 0
              ? "Loading..."
              : `Page ${currentPage} of ${totalPages}`}
          </span>
          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className={`flex items-center ${
              currentPage === totalPages
                ? "text-gray-400 cursor-not-allowed"
                : "text-black hover:text-gray-700"
            }`}
          >
            Next
            <ChevronRight size={20} className="ml-1" />
          </button>
        </div>
      </DialogueContent>
    </ModalWrapper>
  );
};

export default DialogueDisplay;
