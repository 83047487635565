import styled from "styled-components";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import Label from "./Label";

export default function Switch({ fieldName, label, ...props }) {
  const { setValue, getValues } = useFormContext();
  const [bool, setBool] = useState(getValues(fieldName));

  useEffect(() => {
    setBool(getValues(fieldName));
  }, [getValues, fieldName]);

  const toggleSwitch = () => {
    if (fieldName) {
      const newValue = !bool;
      setBool(newValue);
      setValue(fieldName, newValue);
    }
  };

  return (
    <Wrapper {...props}>
      <SwitchBody on={bool} onClick={toggleSwitch}>
        <Flicker on={bool} />
      </SwitchBody>
      {label && <Label>{label}</Label>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
`;

const SwitchBody = styled.div`
  transition: background-color 0.1s ease;
  background-color: ${({ on }) => (on ? "#433DFF" : "#f0f0ed")};
  position: relative;
  height: 30px;
  width: 50px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
`;

const Flicker = styled.p`
  border-radius: 100px;
  width: 25px;
  height: 25px;
  background-color: white;
  position: absolute;
  transition: transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  right: ${({ on }) => (on ? "3px" : "auto")};
`;
