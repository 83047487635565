import { useEffect, useState, useRef } from "react";
import { Switch } from "@headlessui/react";

const ConditionOptions = ({
  isConditionChecked,
  setIsConditionChecked,
  condition,
  setCondition,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    setIsOpen(isConditionChecked);
  }, [isConditionChecked]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height to get correct scrollHeight
      const newHeight = Math.min(textareaRef.current.scrollHeight, 200);
      textareaRef.current.style.height = `${newHeight}px`;
    }
  }, [condition]);

  const placeHolder = `Example:
  Simple condition -> Greeted the user
  More Complex Example -> You are to ensure you have asked the user how many Bland coins they would like to have, and how much they are willing to invest. You must get an answer for the number of coins, as well as the investment amount. The condition is failed if you do not have both the number of coins wanted by the user, as well as the investment amount.`;

  return (
    <div className="border p-4 rounded-md shadow-sm">
      <div className="flex items-center justify-between">
        <h3 className="text-xl">Loop Condition</h3>
        <Switch
          checked={isConditionChecked}
          onChange={setIsConditionChecked}
          className={`${
            isConditionChecked ? "bg-indigo-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Enable Condition</span>
          <span
            className={`${
              isConditionChecked ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>
      <p className="text-lg text-gray-600 mt-1">
        Describe the condition for when the agent should move to the next node,
        or loop on this node. If the condition is not met, the agent will stay
        on this node until the condition is met.
        <br />
        This allows you to ensure that the agent has received the information
        requried, before moving on to the next node.
        <br />
        Example - The condition is achieved once you have gotten the user's
        name.
      </p>
      {isOpen && (
        <div className="mt-4">
          <textarea
            onClick={() => {
              if (textareaRef.current) {
                textareaRef.current.style.height = "auto"; // Reset height to get correct scrollHeight
                const newHeight = Math.min(
                  textareaRef.current.scrollHeight,
                  200,
                );
                textareaRef.current.style.height = `${newHeight}px`;
              }
            }}
            ref={textareaRef}
            value={condition}
            placeholder={placeHolder}
            onChange={(e) => setCondition(e.target.value)}
            className="border border-gray-300 p-2 rounded-md w-full mt-2 min-[80px]"
          />
        </div>
      )}
    </div>
  );
};

export default ConditionOptions;
