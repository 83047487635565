import { useEffect, useState } from "react";
// Routing Dependencies:
import { useNavigate, useSearchParams } from "react-router-dom";
// Auth Verification Functions:
import { verifyUser } from "../utils/funcs/auth/verifyUser";
import { getUser } from "../utils/funcs/auth/getUser";
// Layout Components:
import SideMenu from "../components/SideMenu/SideMenu";
// Dashboard Components
import {
  BatchCall,
  CallLogs,
  Voices,
  Home,
  PhoneNumbers,
  SendCall,
  Testing,
  AddOns,
} from "../components/Dashboard";
// Account & Support Components
import { Billing, Report, Settings } from "../components/AccountSupport";
import { Loading } from "../components/core/Loading";
import NotFound from "../components/core/NotFound";
import styled from "styled-components";
import PurchaseCredits from "components/Dashboard/PurchaseCredits/PurchaseCredits";
import ConvoPathways from "components/Dashboard/ConvoPathways/ConvoPathways";
import Campaigns from "components/Dashboard/Campaigns/Campaigns";
import CreateCampaign from "components/Dashboard/Campaigns/CreateCampaign";
import PlaygroundHub from "components/Dashboard/Testing/Home";
import RevisedTools from "components/Dashboard/Testing/RevisedTools";
import Event from "components/Dashboard/Events/Events";
import AllEvents from "components/Dashboard/Events/AllEvents";
import Infrastructure from "components/Dashboard/Infrastructure/infrastructure";
import Analytics from "components/Dashboard/Analytics/Analytics";
import AnalysisPresetConfigurator from "components/Dashboard/Analysis/AnalysisPresetConfigurator";
import SMSDashboard from "components/Dashboard/SMS/SMS";
import Tools from "components/Dashboard/Tools/Tools";
import AllTools from "components/Dashboard/Tools/AllTools";
import Secrets from "components/Dashboard/Secrets/Secrets";

export default function Dashboard() {
  const navigate = useNavigate();
  const [loadingUser, setLoadingUser] = useState(true);
  let [searchParams] = useSearchParams();

  // Auth State Checker for Entire Dashboard
  useEffect(() => {
    const verify = async () => {
      setLoadingUser(true);
      const response = await verifyUser();
      if (!response?.is_user_verified) {
        console.log("User is not verified:", response?.is_user_verified);
        localStorage.removeItem("user");
        setLoadingUser(false);
        navigate("/login");
      } else {
        const user = await getUser();
        localStorage.setItem("user", JSON.stringify(user));
        setLoadingUser(false);
      }
    };
    verify();
  }, [navigate]);

  // Dynamically Render Page Content via Search Param (page=)
  function renderPage() {
    switch (searchParams.get("page")) {
      case "home":
        return <Home />;
      case "send-call":
        return <SendCall />;
      case "phone-numbers":
        return <PhoneNumbers />;
      case "batch-call":
        return <BatchCall />;
      case "call-logs":
        return <CallLogs />;
      case "voices":
        return <Voices />;
      case "convo-pathways":
        return <ConvoPathways />;
      case "analytics":
        return <Analytics />;
      case "tools":
        return <Tools />;
      case "all-tools":
        return <AllTools />;
      case "playground":
        return <PlaygroundHub />;
      case "playground-tools":
        return <RevisedTools />;
      // case "playground-prompt":
      //    return "playground-prompt";
      case "playground-chat":
        return <Testing />;
      case "billing-details":
        return <Billing />;
      case "campaigns":
        return <Campaigns />;
      case "create-campaign":
        return <CreateCampaign />;
      case "pay":
        return <PurchaseCredits />;
      case "report":
        return <Report />;
      case "settings":
        return <Settings />;
      case "add-ons":
        return <AddOns />;
      case "events":
        return <Event />;
      case "all-events":
        return <AllEvents />;
      case "secrets":
        return <Secrets />;
      case "infrastructure":
        return <Infrastructure />;
      case "analysis-preset":
        return <AnalysisPresetConfigurator />;
      case "sms":
        return <SMSDashboard />;
      default:
        return <NotFound />;
    }
  }

  return (
    <>
      <Wrapper>
        <SideMenu />
        <ContentWrapper>
          {loadingUser ? <Loading loading={loadingUser} /> : renderPage()}
        </ContentWrapper>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;
