import React, { useRef, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import DecisionLogs from "./Components/decisionLogs";
import { FiCopy, FiCheck, FiChevronDown, FiChevronUp } from "react-icons/fi";

const PathwayLogs = ({
  enableQA,
  graphID,
  showChat,
  callID,
  callLogs,
  elements,
  setChatConversationHistory,
  setChatStartNode,
}) => {
  const callLogsRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [shouldScroll, setShouldScroll] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (callLogsRef.current && shouldScroll) {
      callLogsRef.current.scrollTop = callLogsRef.current.scrollHeight;
    }
  }, [callLogs, shouldScroll]);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = callLogsRef.current;
    const isScrolledToBottom = scrollHeight - scrollTop === clientHeight;
    setShouldScroll(isScrolledToBottom);
  };

  const copyCallID = () => {
    navigator.clipboard.writeText(callID);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  if (!showChat) return null;

  const renderContent = (content) => {
    if (typeof content === "string") {
      try {
        const jsonContent = JSON.parse(content);
        return Object.entries(jsonContent).map(([key, value]) => (
          <div key={key} className="flex items-center space-x-2 text-sm mt-2">
            <span className="font-medium text-gray-600 min-w-[100px]">
              {key}:
            </span>
            <pre className="text-gray-700 font-mono bg-gray-100 px-2 py-1 rounded whitespace-pre-wrap flex-1">
              {typeof value === "object"
                ? JSON.stringify(value, null, 2)
                : value}
            </pre>
          </div>
        ));
      } catch (e) {
        // If it's not valid JSON, render it as a string
        return (
          <pre className="text-gray-700 text-sm font-mono bg-gray-100 px-2 py-1 rounded whitespace-pre-wrap flex-1">
            {content}
          </pre>
        );
      }
    } else if (typeof content === "object") {
      return Object.entries(content).map(([key, value]) => (
        <div key={key} className="flex items-center space-x-2 text-sm mt-2">
          <span className="font-medium text-gray-600 min-w-[100px]">
            {key}:
          </span>
          <pre className="text-gray-700 font-mono bg-gray-100 px-2 py-1 rounded whitespace-pre-wrap flex-1">
            {typeof value === "object" ? JSON.stringify(value, null, 2) : value}
          </pre>
        </div>
      ));
    } else {
      return <span className="text-gray-700 flex-1">{String(content)}</span>;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.2 }}
      className="fixed bottom-4 right-4 w-full max-w-2xl bg-white rounded-lg shadow-lg overflow-hidden z-50 border border-gray-200"
      style={{ fontFamily: "Inter, sans-serif" }}
    >
      <div className="bg-amber-50 border-b border-amber-100 px-4 py-3 flex items-center justify-between">
        <div className="flex items-center">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={toggleCollapse}
            className="mr-3 text-amber-600 hover:text-amber-700 transition-colors duration-200"
          >
            {isCollapsed ? (
              <FiChevronUp size={20} />
            ) : (
              <FiChevronDown size={20} />
            )}
          </motion.button>
          <svg
            className="w-5 h-5 text-amber-600 mr-2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path>
          </svg>
          <span className="text-sm font-semibold text-amber-800">
            Live Call in Progress
          </span>
        </div>
        <div className="flex space-x-4">
          <span className="font-medium text-gray-500">MESSAGES</span>
          <span className="font-mono text-gray-700">
            {callLogs?.length || 0}
          </span>
        </div>
      </div>
      <AnimatePresence>
        {!isCollapsed && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3 }}
          >
            <div className="bg-gray-50 px-4 py-3 border-b border-gray-200 flex justify-between items-center text-sm">
              <div className="flex space-x-4 items-center">
                <span className="font-medium text-gray-500">CALL ID</span>
                <span className="font-mono text-gray-700">{callID}</span>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={copyCallID}
                  className="ml-2 text-gray-400 hover:text-gray-600 transition-colors duration-200"
                >
                  {copied ? (
                    <FiCheck className="w-4 h-4" />
                  ) : (
                    <FiCopy className="w-4 h-4" />
                  )}
                </motion.button>
              </div>
            </div>
            <div
              className="h-[500px] overflow-y-auto bg-white"
              ref={callLogsRef}
              onScroll={handleScroll}
            >
              <AnimatePresence>
                {callLogs?.length === 0 ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    className="flex flex-col items-center justify-center h-full text-gray-400"
                  >
                    <svg
                      className="animate-spin h-6 w-6 mb-3"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <p className="text-sm font-medium">
                      Waiting for messages...
                    </p>
                  </motion.div>
                ) : (
                  callLogs?.map((log, index) => (
                    <motion.div
                      key={log.id}
                      initial={{ opacity: 0, y: 10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.15 }}
                      className={`py-3 px-4 border-b border-gray-100 ${index % 2 === 0 ? "bg-gray-50" : "bg-white"}`}
                    >
                      {log.text ? (
                        <motion.div
                          className="flex items-start"
                          whileHover={{ x: 0 }}
                          transition={{ type: "spring", stiffness: 300 }}
                        >
                          <span
                            className={`px-2 py-1 text-sm font-semibold rounded-md mr-3 ${
                              log.role === "assistant"
                                ? "bg-blue-100 text-blue-700"
                                : "bg-green-100 text-green-700"
                            }`}
                          >
                            {log.role.toUpperCase()}
                          </span>
                          <p className="text-md text-gray-700 flex-grow">
                            {log.text}
                          </p>
                        </motion.div>
                      ) : log.pathway_info ? (
                        <motion.div
                          whileHover={{ scale: 1.0 }}
                          transition={{ type: "spring", stiffness: 900 }}
                        >
                          <span className="px-2 py-1 text-sm font-semibold bg-purple-100 text-purple-700 rounded-md mb-2 inline-block">
                            PATHWAY INFO
                          </span>
                          {typeof log.pathway_info === "string"
                            ? renderContent(log.pathway_info)
                            : Object.entries(log.pathway_info).map(
                                ([key, value]) => (
                                  <div
                                    key={key}
                                    className="flex items-center space-x-2 text-sm mt-2"
                                  >
                                    <span className="font-medium text-gray-600 min-w-[100px]">
                                      {key}:
                                    </span>
                                    <div className="flex-1">
                                      {renderContent(value)}
                                    </div>
                                  </div>
                                ),
                              )}
                        </motion.div>
                      ) : (
                        log.decision && (
                          <DecisionLogs
                            decision={JSON.parse(log.decision)}
                            edges={elements.edges}
                            nodes={elements.nodes}
                            setChatConversationHistory={
                              setChatConversationHistory
                            }
                            setChatStartNode={setChatStartNode}
                            callID={callID}
                            graphID={graphID}
                            enableQA={enableQA}
                          />
                        )
                      )}
                    </motion.div>
                  ))
                )}
              </AnimatePresence>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default PathwayLogs;
