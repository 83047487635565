import React, { useEffect, useState } from "react";
import { getUserData } from "../../../utils/funcs/browser/getUserData";
import { getAuthToken } from "../../../utils/funcs/browser/getAuthToken";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "config/stripe";
import axios from "axios";
import AddPaymentMethod from "components/StripeElements/AddPaymentMethod/PaymentMethodForm";
import { Dialog } from "@headlessui/react";
import { CreditCardIcon, PencilIcon } from "@heroicons/react/24/outline";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const PaymentMethod = () => {
  const token = getAuthToken();
  const { public_billing_data } = getUserData();
  const [clientSecret, setClientSecret] = useState();
  const [stripePromise, setStripePromise] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false);
  const [paymentMethodBrand, setPaymentMethodBrand] = useState();
  const [paymentMethodNumbers, setPaymentMethodNumbers] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (public_billing_data) {
      const {
        payment_method_attached,
        payment_method_public,
        payment_method_brand,
        setup_status,
      } = public_billing_data || {};

      setHasPaymentMethod(
        payment_method_attached && setup_status === "succeeded",
      );

      if (payment_method_public) {
        setPaymentMethodBrand(payment_method_brand);
        setPaymentMethodNumbers(
          payment_method_public.charAt(0).toUpperCase() +
            payment_method_public.slice(1),
        );
      }
    }
  }, [token, public_billing_data]);

  useEffect(() => {
    if (isModalOpen) {
      const getClientSecret = async () => {
        const headers = {
          Authorization: `${token}`,
        };
        setLoading(true);
        try {
          const res = await axios.post(
            "/api/pay/createDetails",
            {},
            { headers },
          );
          setClientSecret(res.data?.clientSecret);
        } catch (error) {
          console.error(error);
          // Handle error here, maybe set an error state
        } finally {
          setLoading(false);
        }
      };
      getClientSecret();
    }
  }, [isModalOpen, token]);

  useEffect(() => {
    if (clientSecret) {
      setStripePromise(loadStripe(STRIPE_PUBLIC_KEY));
    }
  }, [clientSecret]);

  return (
    <div className="space-y-4">
      <h5 className="text-lg font-semibold text-gray-900">Payment Method</h5>
      {hasPaymentMethod ? (
        <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow-sm border border-gray-200">
          <div className="flex items-center space-x-4">
            <img
              src={`/cards/${paymentMethodBrand}.svg`}
              alt="payment-card"
              className="w-10 h-6 object-contain"
            />
            <span className="text-sm font-medium text-gray-700">
              {paymentMethodNumbers}
            </span>
          </div>
          <button
            onClick={() => setIsModalOpen(true)}
            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PencilIcon className="h-4 w-4 mr-2" />
            Edit
          </button>
        </div>
      ) : (
        <div className="flex items-center justify-between p-4 bg-white rounded-lg shadow-sm border border-gray-200">
          <span className="text-sm text-gray-500">
            No payment method added yet.
          </span>
          <button
            onClick={() => setIsModalOpen(true)}
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <CreditCardIcon className="h-4 w-4 mr-2" />
            Add Payment Method
          </button>
        </div>
      )}

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        className="fixed inset-0 z-10 overflow-y-auto w-full h-screen"
      >
        <div className="flex items-center justify-center min-h-screen">
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="relative bg-white rounded-lg  w-1/2 mx-auto p-6">
            <Dialog.Title className="text-md font-medium text-gray-900 mb-4">
              {hasPaymentMethod
                ? "Update Payment Method"
                : "Add Payment Method"}
            </Dialog.Title>
            {loading ? (
              <div className="flex justify-center items-center h-48">
                <AiOutlineLoading3Quarters className="animate-spin "></AiOutlineLoading3Quarters>
              </div>
            ) : stripePromise && clientSecret ? (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <AddPaymentMethod clientSecret={clientSecret} />
              </Elements>
            ) : (
              <div>Error loading payment form. Please try again.</div>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PaymentMethod;
