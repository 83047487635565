import React from "react";
import styled from "styled-components";
import CloseIcon from "../../../../assets/icons/close-icon-black.svg";

const TemplateModal = ({
  show,
  handleClose,
  title,
  children,
  modalWidth,
  height = null,
}) => {
  if (!show) {
    return null;
  }

  return (
    <Backdrop
      onClick={handleClose}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <Modal
        style={{ width: modalWidth, height: height ? height : "50%" }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className="modal-header"
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p className="font-bold" style={{ fontSize: 18, fontWeight: "550" }}>
            {title}
          </p>
          <button type="button" className="close" onClick={handleClose}>
            <img src={CloseIcon} height={15} width={15} alt="" />
          </button>
        </div>
        <div style={{ height: "100%" }}>{children}</div>
      </Modal>
    </Backdrop>
  );
};

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  display: ${(props) => (props.show ? "block" : "none")};
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  animation: fadeInOut 0.2s ease-in-out;
`;

const Modal = styled.div`
  z-index: 10000;
  background: #fff;
  border-radius: 10px;
  padding: 15px;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  max-height: 80%;
  overflow: auto;
`;
export default TemplateModal;
