import Select from "components/core/Select";
import { useFormContext } from "react-hook-form";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

export default function VoicemailActionSelect({ fieldName }) {
  const longText = (
    <span>
      This is processed separately from the AI’s decision making, and overrides
      it. Options:
      <strong>hangup</strong>
      <strong>leave_message</strong>
      ignore Examples: Call is answered by a voicemail (specifically with a beep
      or tone): If voicemail_message is set, that message will be left and then
      the call will end. Otherwise, the call immediately ends (regardless of
      amd) Call is answered by an IVR system or phone tree: If amd is set to
      true, the AI will navigate the system and continue as normal. If
      voicemail_action is set to ignore, the AI will ignore the IVR and continue
      as normal. Otherwise, if voicemail_message is set then it’ll leave that
      message and end the call. Finally, if none of those conditions are met,
      the call will end immediately. Note: If voicemail_message is set, then the
      AI will leave the message regardless of the voicemail_action.
    </span>
  );

  const { watch } = useFormContext();
  const currentLanguage = watch("Action");

  return (
    <Select
      value={currentLanguage}
      fieldName={fieldName}
      label={
        <span>
          Voice Mail Action
          <LightTooltip title={longText} placement="right-start" arrow>
            <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
          </LightTooltip>
        </span>
      }
    >
      <LightTooltip title={longText} placement="right-start" arrow>
        <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
      </LightTooltip>
      <option value={"hangup"}>{"Hangup"}</option>
      <option value={"ignore"}>{"Ignore"}</option>
      <option value={"leave_message"}>{"Leave Message"}</option>
      <option value={"leave_sms"}>{"Leave SMS"}</option>
    </Select>
  );
}
