import { PageTitle } from "components/core/PageTitle";
import { useState } from "react";
import styled from "styled-components";

export const Sidebar = ({ options, label, selected, onChange }) => {
  const [hide, setHide] = useState(false);

  if (hide) return <div />;
  if (!options) return <div />;

  return (
    <SidebarWrapper>
      {label && (
        <PageTitle style={{ fontSize: 13, paddingLeft: 10 }}>{label}</PageTitle>
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          marginTop: 12,
        }}
      >
        {options.map((option, index) => (
          <Item
            onClick={() => onChange(option.value)}
            key={index}
            style={{ background: option?.value === selected && "#e5e5e5" }}
          >
            <p style={{ fontSize: 12, fontWeight: 500, cursor: "pointer" }}>
              {option?.label}
            </p>
          </Item>
        ))}
      </div>
    </SidebarWrapper>
  );
};

const SidebarWrapper = styled.div`
  height: calc(100% - 54px);
  top: 0px;
  margin-top: 54px;
  left: 60px;
  width: 280px;
  position: absolute;
  overflow-y: scroll;
  overflow-x: clip;
  border-right: 1px solid #eeeeee;
  padding: 20px 0px;
`;

const Item = styled.div`
  background: transparent;
  padding: 5px 10px;
  width: calc(100% - 10px);
  border-radius: 0.425rem;

  &:hover {
    background: #eeeeee;
  }
`;
