import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CardContent, CardDescription, CardTitle } from "@/components/ui/card";
import blandlogo from "assets/brand/logo-infinity.svg";
import {
  Flex,
  Skeleton,
  TextField,
  Button as RadixButton,
} from "@radix-ui/themes";
import * as Icons from "@radix-ui/react-icons";
import { Header } from "./components/Header";
import heroImage from "assets/images/ToolsHeroImg.png";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import { useNavigate } from "react-router-dom";
import { ArrowRight, CopyIcon, StarIcon } from "lucide-react";
import { getUserData } from "utils/funcs/browser/getUserData";

const getDescriptionLengthAndTruncate = (description) => {
  if (description.length > 100) {
    return description.substring(0, 100) + "...";
  }
  return description;
};

const Container = styled.div`
  font-family: "Lato", sans-serif;
  margin: 0 auto;
  padding: 0px;
`;

const MainContent = styled.main``;

const SubContent = styled.div`
  display: flex;
  padding: 20px 70px;
  margin-top: 15px;
`;

const Content = styled.div`
  flex: 1;
`;

const AppGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 20px;
  row-gap: 0px;
  width: 100%;
  row-gap: 20px;

  @media (max-width: 1019px) {
    grid-template-columns: 1fr;
  }
`;

const Sidebar = ({ categories, collections }) => {
  const SidebarContainer = styled.aside`
    width: 200px;
  `;

  return (
    <SidebarContainer>
      <CardTitle>Popular Picks</CardTitle>
      <ul style={{ marginTop: 7 }}>
        {categories.map((category, index) => (
          <HighlightOnHoverText key={index}>{category}</HighlightOnHoverText>
        ))}
      </ul>
      {/* <Separator style={{marginTop:20, marginBottom:20}} /> */}
      <CardTitle style={{ marginTop: 20 }}>Collections</CardTitle>
      <ul style={{ marginTop: 7 }}>
        {collections.map((collection, index) => (
          <HighlightOnHoverText key={index}>{collection}</HighlightOnHoverText>
        ))}
      </ul>
    </SidebarContainer>
  );
};

const HeroContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 90px 70px 40px 70px;
`;

const HeroText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const HeroImage = styled.div`
  flex: 1;
  text-align: right;
`;

const HeroLine = styled.div`
  width: 100%;
  height: 1px;
  background: #5151cd;
`;

const Hero = ({ title, description, ctaText, onCtaClick }) => {
  return (
    <>
      <HeroContainer>
        <HeroText>
          <CardTitle style={{ fontWeight: "500", fontSize: 42 }}>
            {title}
          </CardTitle>
          <CardDescription style={{ fontSize: 18, lineHeight: 1 }}>
            {description}
          </CardDescription>

          <div style={{}}>
            <RadixButton
              variant="solid"
              color="iris"
              type="button"
              style={{ cursor: "pointer" }}
              onClick={onCtaClick}
            >
              {ctaText}
              <ArrowRight size={14} />
            </RadixButton>
          </div>
        </HeroText>

        <HeroImage>
          <img
            src={heroImage}
            alt={title}
            width="600"
            height="600"
            style={{ marginLeft: "auto" }}
          />
        </HeroImage>
      </HeroContainer>
      <HeroLine />
    </>
  );
};

const AppCard = ({
  size,
  name,
  imageUrl,
  id,
  features,
  description,
  clones,
  stars,
}) => (
  <div
    onClick={() => {
      window.location.href = `/showcase/tools/${id}`;
    }}
    style={{}}
  >
    {size === "normal" ? (
      <CardContent style={{ padding: "0px" }}>
        <div
          style={{
            height: 130,
            backgroundColor: "#FAFAFA",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderTopRightRadius: "6px",
            borderTopLeftRadius: "6px",
          }}
        >
          <img src={blandlogo} alt={name} width="50" height="50" />
        </div>
        <CardTitle style={{ padding: "25px 15px", fontWeight: "550" }}>
          {name}
        </CardTitle>
      </CardContent>
    ) : (
      <div id="responsive-grid" style={{ overflow: "hidden" }}>
        <HighlightOnHoverSmallAppCard style={{ overflow: "hidden" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            <img
              src={imageUrl}
              alt={name}
              width="40"
              height="40"
              style={{
                objectFit: "contain",
                aspectRatio: "1/1",
              }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 12,
                marginRight: 5,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <StarIcon size={13} color="gold" strokeWidth={3} />
                <p style={{ color: "gold", fontSize: 13, fontWeight: "500" }}>
                  {stars}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 8,
                }}
              >
                <CopyIcon size={13} color="GrayText" strokeWidth={3} />
                <p
                  style={{ color: "GrayText", fontSize: 13, fontWeight: "500" }}
                >
                  {clones}
                </p>
              </div>
            </div>
          </div>

          <div
            style={{
              width: "100%",
              overflow: "hidden",
              overflowY: "hidden",
              marginTop: 20,
            }}
          >
            <p className="text-xl" style={{ fontWeight: "550" }}>
              {name}
            </p>

            <p>{getDescriptionLengthAndTruncate(description || "")}</p>
          </div>
        </HighlightOnHoverSmallAppCard>
      </div>
    )}
  </div>
);

const AppSection = ({ title, showTitle = true, apps }) => (
  <div style={{ marginTop: "20px" }}>
    {showTitle && (
      <CardTitle style={{ fontSize: 18, marginBottom: 10 }}>{title}</CardTitle>
    )}

    {apps.length === 0 && (
      <Flex gap="2" wrap={"wrap"} align={"center"} justify={"center"}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          {[...Array(3)].map((_, index) => (
            <Skeleton
              key={index}
              style={{
                width: "calc(33.333% - 10px)",
                margin: "5px",
                height: "100px",
                background: "#f0f0f0",
                borderRadius: "4px",
              }}
            />
          ))}
        </div>
      </Flex>
    )}
    <AppGrid>
      {apps.map((app, index) => (
        <AppCard
          key={index}
          size={app.box}
          name={app.title}
          imageUrl={app.image_url || blandlogo}
          id={app.tool_id}
          features={app.features}
          description={app.description}
          clones={app?.clones}
          stars={app?.stars}
        />
      ))}
    </AppGrid>
  </div>
);

const Tools = () => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [allTools, setAllTools] = useState({
    featured: [],
  });

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const categories = ["Featured", "Enterprise-ready apps"];
  const collections = ["Bland Curated"];

  const user = getUserData();

  const loadAllTools = async () => {
    const response = await fetch("/api/tools/showcase", {
      method: "POST",
      headers: {
        authorization: getApiKey(),
        "content-type": "application/json",
      },
      body: JSON.stringify({ action: "get_all" }),
    });

    if (response.status !== 200) {
      console.log("Error loading tools");
      return;
    }

    const data = await response.json();
    if (data.status === "error") {
      console.log("Error loading tools");
      return;
    }

    setAllTools((prevState) => ({ ...prevState, featured: data.data }));
  };

  const displayApps =
    allTools?.featured?.filter((app) =>
      app?.title?.toLowerCase()?.includes(search?.toLowerCase()),
    ) || [];

  useEffect(() => {
    loadAllTools();
  }, []);

  return (
    <Container>
      <Header />

      <MainContent>
        {user ? (
          <Hero
            title="Add tools, get work done."
            description="Pull reports, start calls, file tickets, and more live on call — right within Bland."
            ctaText="Create a tool"
            onCtaClick={() => navigate("/dashboard?page=tools")}
          />
        ) : (
          <Hero
            title="Add tools, get work done."
            description="Pull reports, start calls, file tickets, and more live on call — right within Bland."
            ctaText="Sign up to create a tool"
            onCtaClick={() => navigate("/signup")}
          />
        )}

        <SubContent>
          <Sidebar categories={categories} collections={collections} />
          <Content>
            <TextField.Root
              onChange={handleSearch}
              value={search}
              placeholder="Search for tools..."
            >
              <TextField.Slot>
                <Icons.MagnifyingGlassIcon />
              </TextField.Slot>
            </TextField.Root>

            <AppSection title="Featured" showTitle={false} apps={displayApps} />
          </Content>
        </SubContent>
      </MainContent>
    </Container>
  );
};

const HighlightOnHoverText = styled.p`
  color: graytext;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  &:hover {
    color: #000;
    text-decoration: underline;
  }
`;

const HighlightOnHoverSmallAppCard = styled.div`
  border-radius: 6px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  flex-wrap: wrap;
  height: 170px;
  max-height: 170px;
  border: 1px solid #eaeaea;
  align-items: center;

  &:hover {
    background-color: #f9f9f9;
    scale: 0.99;
    transition: all 0.2s ease-in-out;
    border: 1px solid #5151cd;
  }
`;

export default Tools;
