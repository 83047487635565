import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ErrorText } from "./ErrorText";
import ReactInputMask from "react-input-mask";
import "react-phone-input-2/lib/style.css";
import Label from "./Label";

export default function CodeInput({ fieldName }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex flex-col gap-2">
      <Label>Verification code</Label>
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, value } }) => (
          <ReactInputMask
            value={value}
            country="us"
            onChange={onChange}
            mask="999999"
            placeholder="Enter verification code"
            style={{ height: "40px" }}
            className="text-left bg-[#fbfbfa] border border-accent w-full px-4 py-2 rounded placeholder-secondary focus:outline-none focus:text-center"
            autoFocus
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => <ErrorText message={message} />}
      />
    </div>
  );
}
