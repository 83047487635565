import axios from "axios";

export const listBatches = async (apiKey, pageSize) => {
  try {
    const headers = {
      Authorization: `${apiKey}`,
      "Content-Type": "application/json",
    };
    const response = await axios.get(`https://us.api.bland.ai/v1/batches`, {
      headers,
    });
    if (response.data) {
      console.log(`Batches for user have been retrieved:`, response);
      return response.data.batches;
    } else {
      console.error(`HTTP error fetching batches for user:`, response.data);
      return response.data;
    }
  } catch (error) {
    console.error(`Error fetching batches for user:`, error);
    return error;
  }
};
