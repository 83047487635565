export function getUserData() {
  let userData = localStorage.getItem("user");
  if (!userData) {
    console.log("No user data set in localStorage:", userData);

    // Get the current path
    const currentPath = window.location.pathname;

    // List of paths where we don't want to redirect
    const noRedirectPaths = [
      "/login",
      "/signup",
      "/showcase/pathway",
      "/showcase/tools",
    ];

    const shouldNotRedirect = noRedirectPaths.some((path) =>
      currentPath.startsWith(path),
    );

    // Check if we're not on a no-redirect path and not on a page with 'page=home' in the query
    if (!shouldNotRedirect && !window.location.search.includes("page=home")) {
      setTimeout(() => {
        if (!localStorage.getItem("user")) {
          console.log("Redirecting to login");
          window.location.href = "/login";
          localStorage.clear();
        }
      }, 5000);
    }

    return;
  } else {
    userData = JSON.parse(userData);
    const user = userData.user;
    return user;
  }
}
