import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import ReactPhoneInput from "../components/core/PhoneInput";
import tos from "../assets/files/tos.pdf";
import Button from "../components/core/Button";
import lockIcon from "../assets/icons/lock-icon.svg";
import CodeInput from "../components/core/CodeInput";
import Input from "../components/core/Input";
import Checkbox from "../components/core/Checkbox";
import Welcome from "../components/Auth/SignUp/Welcome";
import { yupResolver } from "@hookform/resolvers/yup";
import { signupSchema } from "../utils/validation/signUpSchema";
import { FormProvider, useForm } from "react-hook-form";
import { LoginCat } from "../components/Auth/SignUp/LoginCat";
import { Promotion } from "../components/Auth/SignUp/Promotion";
import { Container2xl, ContainerS } from "../components/core/Container";
import { sendWelcomeCall } from "../utils/funcs/auth/sendWelcomeCall";
import { Mixpanel } from "../components/Mixpanel";
import FormHeader from "../components/Auth/FormHeader";
import styled from "styled-components";
import { sendOtp } from "utils/funcs/auth/signUp/sendOtp";
import { verifyOtp } from "utils/funcs/auth/signUp/verifyOtp";
import { toast } from "react-toastify";
import { setAuthToken } from "utils/funcs/browser/setAuthToken";
import { useSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import EducationalStage from "./PathwaysEducation";

export default function SignUp() {
  const [searchParams] = useSearchParams();
  const refCode = searchParams.get("ref");
  const recaptchaRef = React.createRef();

  const methods = useForm({
    defaultValues: {
      isCodeStage: false,
      isWelcomeStage: false,
    },
    resolver: yupResolver(signupSchema),
  });

  useEffect(() => {
    if (refCode) {
      methods.setValue("referrer_code", refCode);
    }
  }, [methods, refCode]);

  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(1); //IMPORTANT: LEAVE AT 1
  const [showEducationalStage, setShowEducationalStage] = useState(true);

  const onSubmit = async (formData) => {
    if (stage === 1) {
      setLoading(true);
      const token = await recaptchaRef.current.executeAsync();
      const { code_sent, error } = await sendOtp(formData.phone, token);
      setLoading(false);
      if (error) {
        console.error(error);
        if (error.response && error.response.data) {
          toast.error(`Error sending OTP. ${error.response.data.error}`);
        } else {
          toast.error(`Error sending OTP. ${error.message || error}`);
        }
      }
      if (code_sent) {
        setStage(2);
      }
    }
    if (stage === 2) {
      methods.setValue("isCodeStage", true);
      setLoading(true);
      const { recieved_code_sent, jwt, error } = await verifyOtp(
        formData.code,
        formData.phone,
        formData.first_name,
        formData.last_name,
        formData.email,
        formData.referrer_code,
      );
      if (error?.code === "23505") {
        toast.error("User already exists", {
          type: "error",
        });
      } else if (error) {
        toast.error(error.message || error);
      }
      setLoading(false);
      if (recieved_code_sent) {
        setStage(3);
        setAuthToken(jwt);
      }
    }
    if (stage === 3) {
      methods.setValue("isCodeStage", false);
      setLoading(true);
      await sendWelcomeCall(formData.phone, formData.first_name);
      setLoading(false);
    }
  };

  useEffect(() => {
    Mixpanel.track("Signup page");
  }, []);

  const handleEducationalComplete = () => {
    // setShowEducationalStage(false);
    setStage(1);
  };

  return (
    <Container2xl>
      <div className="flex flex-row h-screen">
        <div className="flex flex-col w-full items-center justify-center">
          {stage === 3 && (
            <div className="w-2/3">
              <EducationalStage onComplete={setStage} />
            </div>
          )}
          <ContainerS>
            <FormProvider {...methods}>
              <Form
                onSubmit={methods.handleSubmit(onSubmit)}
                className="w-full"
              >
                {/* {showEducationalStage && (
                           <EducationalStage onComplete={handleEducationalComplete} />
                        )} */}
                {stage === 1 && (
                  <>
                    <FormHeader
                      title="Sign up with Bland AI"
                      subtitle="Start sending AI phone calls"
                    />
                    <FormFields>
                      <Input label="First Name" fieldName="first_name" />
                      <Input label="Last Name" fieldName="last_name" />
                      <Input label="Email" fieldName="email" />
                      <ReactPhoneInput fieldName="phone" />
                      <Input fieldName="referrer_code" type="hidden" />
                      <Checkbox
                        label={
                          <>
                            I agree to the{" "}
                            <a
                              href={tos}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="underline"
                            >
                              terms of service
                            </a>
                          </>
                        }
                        fieldName="agreed_tos"
                      />
                    </FormFields>

                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey="6Lf9dfQpAAAAAAQ1YrwSIcnW9e_y8gXnEWF9b6LZ"
                      size={"invisible"}
                    />
                    <Button submit iconSrc={lockIcon} loading={loading}>
                      Sign Up
                    </Button>
                    <LoginCat />
                  </>
                )}
                {stage === 2 && (
                  <>
                    <FormFields>
                      <CodeInput fieldName="code" />
                    </FormFields>
                    <Button submit iconSrc={lockIcon} loading={loading}>
                      Verify Code
                    </Button>
                  </>
                )}

                {stage === 4 && (
                  <div className="w-full">
                    <Welcome loading={loading} />
                  </div>
                )}
                <input type="hidden" {...methods.register("isCodeStage")} />
              </Form>
            </FormProvider>
          </ContainerS>
        </div>
        <Promotion title1="Welcome back" title2="" />
      </div>
    </Container2xl>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
