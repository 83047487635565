import styled from "styled-components";
import { dashboard } from "../../utils/data/menus/sideMenuItems";
import MenuItem from "./MenuItem";
import blandlogo from "./../../assets/brand/logo-infinity.svg";
import blandfont from "./../../assets/brand/bland-font.svg";
import Avatar from "components/Avatar/Avatar";
import { useState } from "react";

export default function SideMenu() {
  const [hover, setHover] = useState(false);

  return (
    <Wrapper
      hover={hover}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <TopFixed hover={hover}>
        <Logo>
          <BrandIcon src={blandlogo} alt="" width={25} height={25} />
          {hover && <BrandFont src={blandfont} alt="" />}
        </Logo>
      </TopFixed>
      <MenuSection>
        <Menu>
          {dashboard.map((page, key) => (
            <MenuItem key={key} page={page} setHover={setHover} hover={hover}>
              <Icon hover={hover}>{page.icon}</Icon>
              {hover && <Text>{page.text}</Text>}
            </MenuItem>
          ))}
        </Menu>
      </MenuSection>
      <BottomFixed hover={hover} onClick={() => setHover(false)}>
        <Avatar />
      </BottomFixed>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  height: 100%;
  width: ${({ hover }) => (hover ? "250px" : "54px")};
  //    overflow-y: scroll;
  //    overflow-x: hidden;
  border-right: 1px solid #eeeeee;
  padding: 58px 5px 0px 5px;
  position: absolute;
  left: 0;
  z-index: 5000;
  background-color: white;
  transition: width 0.3s cubic-bezier(0.23, 1, 0.32, 1);
`;

const MenuSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  width: 100%;
  overflow: hidden;
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  overflow-y: auto;
`;

const BrandIcon = styled.img`
  width: 27px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.div`
  border-bottom: 1px solid #eeeeee;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  gap: 12px;
`;

const BrandFont = styled.img`
  width: 70px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.div`
  height: 100%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Text = styled.div`
  white-space: nowrap;
  transition: 0.3s ease-in-out;
`;

const TopFixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 100;
  height: 54px;
  width: ${({ hover }) => (hover ? "250px" : "54px")};
  border-right: 1px solid #eeeeee;
  overflow-x: hidden;
`;

const BottomFixed = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  height: 54px;
  width: ${({ hover }) => (hover ? "250px" : "54px")};
  padding: 0px 9px;
  background-color: white;
  border-right: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  overflow: hidden;
`;
