import DroppableFolderItem from "./FolderItem";
import { CgSpinner } from "react-icons/cg";
import { FolderIcon, PlusIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import styled from "styled-components";

const FolderSidebar = ({
  folders,
  onCreateFolder,
  onSelectFolder,
  selectedFolderId,
  onMovePathway,
  onEditFolder,
  onDeleteFolder,
  isCreatingFolder,
}) => {
  const renderFolders = (folderList, level = 0) => {
    return folderList.map((folder) => {
      const folderContent = (
        <DroppableFolderItem
          key={folder.id}
          folder={folder}
          onSelectFolder={onSelectFolder}
          selectedFolderId={selectedFolderId}
          onMovePathway={onMovePathway}
          onEditFolder={onEditFolder}
          onDeleteFolder={onDeleteFolder}
        />
      );

      return (
        <div key={folder.id} className={`ml-${level * 4}`}>
          {folderContent}
          {folder.children &&
            Array.isArray(folder.children) &&
            renderFolders(
              folder.children.filter((child) => child.type === "folder"),
              level + 1,
            )}
        </div>
      );
    });
  };

  const rootItems = Array.isArray(folders) ? folders : [];

  return (
    <SidebarWrapper>
      <Button
        onClick={onCreateFolder}
        className="bg-indigo-500 hover:bg-indigo-700"
        disabled={isCreatingFolder}
        style={{ width: "calc(100% - 20px)", marginRight: 20 }}
      >
        {isCreatingFolder ? (
          <CgSpinner className="mr-2 animate-spin" size={16} />
        ) : (
          <PlusIcon className="mr-2" size={16} />
        )}
        {isCreatingFolder ? "Creating..." : "New Folder"}
      </Button>
      <Item className="overflow-y-auto flex-grow">
        <div className="mb-2">
          <button
            onClick={() => onSelectFolder(null)}
            className={`flex items-center w-full text-left p-2 hover:bg-gray-100 rounded ${
              selectedFolderId === null ? "bg-gray-200" : ""
            }`}
          >
            <FolderIcon className="mr-2" size={16} />
            <span className="truncate">All Pathways</span>
          </button>
        </div>
        {renderFolders(rootItems)}
      </Item>
    </SidebarWrapper>
  );
};

export default FolderSidebar;

const SidebarWrapper = styled.div`
  height: ${window.outerHeight +
  64}px; /* Changed to make the height 100% of the viewport height with no whitespace below */
  top: 0;
  left: 60px;
  margin-top: -54px;
  max-width: 25%;
  min-width: 180px;
  overflow-y: scroll;
  overflow-x: clip;
  border-right: 1px solid #eeeeee; /* Ensures the border on the right spans the entire height */
  padding: 54px 0px 0px 0px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Item = styled.div`
  background: transparent;
  padding: 5px 15px 5px 0px;
  width: 100%;
  border-radius: 0.425rem;
`;
