import axios from "axios";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { batchDetails } from "utils/funcs/batch-call/batchDetails";
// import CampaignId from "../FormFields/BatchCall/CampaignId";
import Label from "../Parameters/BatchCall/Label";
import CallData from "./CallData";
import Prompt from "../Parameters/Prompt";
import MaxDuration from "../Parameters/MaxDuration";
import Language from "../Parameters/Language";

import WaitGreeting from "../Parameters/WaitGreeting";
import TestMode from "../Parameters/BatchCall/TestMode";
import Webhook from "../Parameters/Webhook";
import ActionBar from "components/core/ActionBar";
import Button from "components/core/Button";
import styled from "styled-components";
import { Loading } from "components/core/Loading";
import Model from "../Parameters/Model";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Voice from "../Parameters/Voice";
import Record from "../Parameters/Record";
import InterruptionThresh from "../Parameters/InterruptionThresh";
import FirstSetence from "../Parameters/FirstSentence";
import Temperature from "../Parameters/Temperature";
import { getUserData } from "utils/funcs/browser/getUserData";
import LocalDialing from "../Parameters/LocalDialing";

import StartTime from "../Parameters/StartTime";
import AnsweredBy from "../Parameters/AnsweredBy";
import BlockInterruptions from "../Parameters/BlockInterruptions";
import EncryptedKey from "../Parameters/EncryptedKey";
import Voicemail_message from "../Parameters/voicemailMessage";
import VoicemailActionSelect from "../Parameters/VoicemailAction";
import Pathway from "../Parameters/Pathway";
import CustomDeployment from "../Parameters/CustomDeployment";
import TransferNumber from "../Parameters/TransferNumber";
import TransferList from "../Parameters/TransferList";
import Metadata from "../Parameters/Metadata";
import Pronunciation from "../Parameters/Pronunciation";

import { PageTitle } from "components/core/PageTitle";
import { Button as RadixBtn, Flex, Switch, Text } from "@radix-ui/themes";
import SMSForm from "../Parameters/SMS";
import RequestData from "../Parameters/RequestData";
import AnalysisPreset from "../Parameters/AnalysisPreset";
import AnalysisSchemaCo from "../Parameters/AnalysisSchema";
import { KeyValuesToObj } from "utils/formatting/KeyValuesToObj";
import PromptSendCallPage from "../Parameters/PromptSendCallPage";
import Modal from "components/core/Modal";
import TrashIcon from "../../../assets/icons/trash-grey-fig.svg";
import CloseIcon from "../../../assets/icons/close-icon-black.svg";
import ReactPhoneInput from "components/core/PhoneInput";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { objectToKeyValue } from "utils/formatting/objToKeyValues";
import { ToolsDropdown } from "components/core/Tools";
import usePhoneNumbers from "hooks/usePhoneNumbers";
import PhoneNumberSelector from "components/core/PhoneNumberSelector";

const PromptItem = styled.div`
  cursor: pointer;
  background-color: #fff;
  border-radius: 3px;
  padding: 15px;
  border: 1px solid #f0f0f0;
  &:hover {
    background-color: #eeeeee;
  }
`;

export default function BatchForm({
  batches,
  selectedBatchId,
  apiKey,
  currBatch = null,
}) {
  const { user } = getUserData();
  const methods = useForm();
  const [batch, setBatch] = useState();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [savedPrompts, setSavedPrompts] = useState([]);
  const [promptName, setPromptName] = useState("");
  const [loadingPrompt, setLoadingPrompt] = useState(false);

  const test_mode = methods.watch("test_mode");
  const promptModal = methods.watch("prompt_modal");
  const savePromptModal = methods.watch("save_prompt_modal");

  const {
    inboundNumbers,
    twilioNumbers,
    loading: phoneNumbersLoading,
    error: phoneNumbersError,
  } = usePhoneNumbers();

  const handleDeletePrompt = async (promptId) => {
    const response = await fetch(
      `https://us.api.bland.ai/v1/prompts/${promptId}`,
      {
        method: "DELETE",
        headers: {
          Authorization: apiKey,
        },
      },
    );

    if (response.ok && response.status === 200) {
      toast.success("Prompt deleted successfully.");
      handleGetSavedPrompts();
    } else {
      toast.error("Error deleting prompt. Please try again.");
    }
  };

  const handleGetSavedPrompts = async () => {
    const response = await fetch("https://us.api.bland.ai/v1/prompts", {
      method: "GET",
      headers: {
        Authorization: apiKey,
      },
    });

    if (response.ok && response.status === 200) {
      const data = await response.json();
      const prompts = data.prompts;
      if (prompts.length > 0) {
        setSavedPrompts(prompts);
      }
    }
  };

  const handleUpdatePrompt = async () => {
    setLoadingPrompt(true);
    const promptId = methods.getValues("selected_edit_prompt_id");
    const prompt = savedPrompts.find((prompt) => prompt.id === promptId);
    const response = await fetch(
      `https://us.api.bland.ai/v1/prompts/${prompt.id}`,
      {
        method: "POST",
        headers: {
          Authorization: apiKey,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: methods.getValues("base_prompt"),
        }),
      },
    );

    if (response.ok && response.status === 200) {
      toast.success("Prompt updated successfully.");
      methods.setValue("selected_edit_prompt_id", null);
      methods.setValue("selected_saved_prompt_id", null);
      methods.setValue("save_prompt_modal", false);
      setPromptName("");
      handleGetSavedPrompts();
      setLoadingPrompt(false);
    } else {
      setLoadingPrompt(false);
      toast.error("Error updating prompt. Please try again.");
    }
  };

  const handleCreatePrompt = async () => {
    setLoadingPrompt(true);
    const response = await fetch("https://us.api.bland.ai/v1/prompts", {
      method: "POST",
      headers: {
        Authorization: apiKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prompt: methods.getValues("base_prompt"),
        name: promptName,
      }),
    });

    if (response.ok && response.status === 200) {
      toast.success("Prompt saved successfully.");
      methods.setValue("selected_edit_prompt_id", null);
      methods.setValue("selected_saved_prompt_id", null);
      methods.setValue("prompt_modal", false);
      methods.setValue("save_prompt_modal", false);
      setPromptName("");
      handleGetSavedPrompts();
      setLoadingPrompt(false);
    } else {
      setLoadingPrompt(false);
      toast.error("Error saving prompt. Please try again.");
    }
  };

  useEffect(() => {
    handleGetSavedPrompts();
  }, []);

  // reset fields to selected batch
  useEffect(() => {
    const batch_params = batch ? batch : currBatch;
    if (batch_params) {
      methods.reset({
        model: "base",
        from: batch_params?.call_params?.from || null,
        base_prompt: batch_params?.base_prompt,
        call_data: batch_params?.call_params.call_data,
        label: batch_params.label || "",
        voice_id: batch_params.call_params.voice || "Alexa",
        language: batch_params.language || "en-US",
        webhook: batch_params.webhook || null,
        max_duration: batch_params.call_params.max_duration || 12,
        first_sentence: batch_params.call_params.first_sentence,
        wait_for_greeting: batch_params?.call_params?.wait_for_greeting,
        block_interruptions:
          batch_params.call_params.block_interruptions || false,
        test_mode: batch_params.call_params.test_mode || false,
        interruption_threshold:
          batch_params?.call_params?.interruption_threshold,
        temperature: batch_params?.temperature || 0.5,
        local_dialing: batch_params?.local_dialing || false,
        start_time: batch_params?.start_time || null,
        answered_by_enabled: batch_params?.call_params?.answered_by_enabled,
        encrypted_key: batch_params?.encrypted_key || "",
        voicemail_message: batch_params?.call_params?.voicemail_message
          ? batch_params?.call_params?.voicemail_message
          : null,
        voicemail_action: batch_params?.call_params?.voicemail_action
          ? batch_params?.call_params?.voicemail_action
          : null,
        pathway_id: batch_params?.pathway_id || null,
        record: batch_params?.call_params?.record,
        endpoint: batch_params?.call_params?.endpoint || null,
        transfer_phone_number:
          batch_params?.call_params?.transfer_phone_number || null,
        transfer_list: batch_params?.call_params?.transfer_list
          ? objectToKeyValue(batch_params?.call_params?.transfer_list)
          : [],
        metadata: batch_params?.metadata || null,
        request_data: batch_params?.request_data || null,
        analysis_preset: batch_params?.call_params?.analysis_preset || null,
        analysis_schema: batch_params?.call_params?.analysis_schema
          ? objectToKeyValue(batch_params?.call_params?.analysis_schema)
          : [],
        save_prompt: () => {
          return methods.setValue("save_prompt_modal", true);
        },
        prompt_modal: false,
        selected_saved_prompt_id: null,
        selected_edit_prompt_id: null,
        save_prompt_modal: false,
        tools: batch_params?.call_params?.tools || null,

        // campaign_id: batch_params.campaign_id,
      });
    }

    setBatch(batch);
  }, [apiKey, batch, methods, selectedBatchId]);

  // handle submit to dispatch call
  const onDispatch = async (formData) => {
    const headers = {
      Authorization: apiKey,
    };

    if (methods.getValues("encrypted_key")) {
      const ek = methods.getValues("encrypted_key");
      if (ek && ek.length > 1) {
        headers["encrypted_key"] = ek;
      }
    }

    const data = {
      base_prompt: formData.base_prompt,
      call_data: formData.call_data,
      label: formData.label,
      voice: formData.voice_id,
      language: formData.language,
      webhook: formData.webhook,
      model: formData.model,
      max_duration: formData.max_duration,
      amd: formData.amd,
      wait_for_greeting: formData.wait_for_greeting,
      block_interruptions: formData.block_interruptions,
      test_mode: formData.test_mode,
      record: formData.record,
      interruption_threshold: formData.interruption_threshold,
      first_sentence: formData.first_sentence,
      temperature: Number(formData.temperature),
      local_dialing: formData.local_dialing,
      start_time: formData.start_time
        ? `${new Date(formData.start_time).toISOString().split(".")[0]}Z`
        : null,
      answered_by_enabled: formData.answered_by_enabled,
      voicemail_action: formData.voicemail_action,
      voicemail_message: formData.voicemail_message,
      pathway_id: formData.pathway_id,
      endpoint: formData.endpoint,
      transfer_phone_number: formData.transfer_phone_number,
      transfer_list: KeyValuesToObj(formData.transfer_list),
      metadata: formData.metadata,
      pronunciation_guide: formData.pronunciation_guide,
      sms: formData.sms,
      analysis_preset: formData.analysis_preset,
    };

    if (formData?.tools) {
      if (data["call_data"]) {
        data["call_data"] = formData.call_data.map((item) => {
          return { ...item, tools: [formData.tools] };
        });
      }
    }

    if (methods.getValues("from")) {
      data["from"] = methods.getValues("from");
    }

    let paginated_data = data?.call_data ?? []; // array with object containing keys as columns for each row;
    if (paginated_data.length === 0) return;

    let numbers = paginated_data.map((doc) => doc.phone_number);
    let incorrectFormatNumbers = numbers.filter(
      (doc) => !doc || !doc.includes("+"),
    );

    if (incorrectFormatNumbers.length > 0) {
      return toast.error(
        `${incorrectFormatNumbers.length} phone numbers are missing "+" or formatted incorrectly. Please fix this before continuing.`,
        {
          toastId: "error",
        },
      );
    }

    if (data.voice === "") {
      return toast.error("Select a voice before dispatching a batch call.");
    }

    if (formData?.analysis_schema) {
      data["analysis_schema"] = KeyValuesToObj(formData?.analysis_schema) || {};
    }

    try {
      setSubmitting(true);
      const endpoint = formData.endpoint
        ? `${formData.endpoint}/batch`
        : `https://us.api.bland.ai/batch`;
      const response = await axios.post(endpoint, data, {
        headers,
      });
      if (response.data) {
        setSubmitting(false);
        toast.success("Batch dispatched!");
        window.location.href = "/dashboard?page=call-logs";
      }
    } catch (error) {
      toast(error.response.data.message.toString(), {
        type: "error",
        toastId: "reqError",
      });
      console.log("Error submitting send call form:", error);
      setSubmitting(false);
    }
  };

  return (
    <div>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onDispatch)}>
            <ActionBar top spaceBetween>
              <PageTitle>New Batch</PageTitle>
              <Flex align={"center"} gap={"4"}>
                <Text as="label" size={"3"}>
                  <Flex gap="2">
                    Test Call{" "}
                    <Switch
                      value={test_mode ? true : false}
                      onClick={() => {
                        methods.setValue("test_mode", !test_mode);
                      }}
                      checked={test_mode}
                      style={{ cursor: "pointer" }}
                    />
                  </Flex>
                </Text>
                <RadixBtn
                  size={"2"}
                  loading={submitting}
                  onClick={() => {
                    methods.handleSubmit(onDispatch);
                  }}
                  color="iris"
                  style={{ cursor: "pointer" }}
                >
                  Send Batch
                </RadixBtn>
              </Flex>
              {/* <BatchDropdown
               label="Batch History:"
               options={batches}
               currBatch={currBatch}
               emptyText="No batches dispatched yet."
               loading={loading}
            /> */}
            </ActionBar>

            <p
              className="font-medium text-[#676775]"
              style={{ fontSize: "1.2rem", marginBottom: "-25px" }}
            >
              Use presets from previous batch
            </p>
            <Select
              className="w-[full]"
              style={{ marginTop: "-20px" }}
              onValueChange={(value) => {
                setBatch(batches?.find((item) => item?.batch_id === value));
              }}
              value={batch?.batch_id}
            >
              <SelectTrigger
                style={{ fontSize: 13, border: "1px solid #eeeeee" }}
                className="w-[full] h-[35px] focus:ring-0 focus:border-none focus:outline-none select:border-none"
              >
                <SelectValue
                  className="focus:border-none focus:outline-none select:border-none"
                  placeholder={"Select from previous batch..."}
                  value={batch?.batch_id || currBatch?.batch_id}
                  style={{ fontSize: 13, outline: "none" }}
                />
              </SelectTrigger>
              <SelectContent position={"popper"}>
                {batches &&
                  batches?.length > 0 &&
                  [
                    ...new Map(
                      batches.map((item) => [
                        item.label + item.base_prompt,
                        item,
                      ]),
                    ).values(),
                  ].map((bat, ind) => (
                    <SelectItem
                      style={{ height: 30, fontSize: 13 }}
                      key={ind}
                      value={bat.batch_id}
                    >
                      {bat.label}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
            <Label fieldName="label" />
            <PhoneNumberSelector
              fieldName="from"
              userNumbers={inboundNumbers}
              byotNumbers={twilioNumbers}
              loading={phoneNumbersLoading}
              error={phoneNumbersError}
            />
            <EncryptedKey fieldName="encrypted_key" />
            <CallData fieldName="call_data" />
            {methods.watch("call_data") &&
              methods.watch("call_data")?.length === 0 && (
                <div
                  style={{
                    background: "#f5cac3",
                    border: "1px solid #ef233c",
                    borderRadius: 4,
                    padding: 8,
                    maxWidth: "60%",
                  }}
                >
                  <p style={{ color: "#ef233c", fontWeight: "550" }}>
                    The uploaded file does not contain a "phone_number" column
                    or has no phone numbers.
                  </p>
                  <p style={{ color: "#ef233c", fontWeight: "400" }}>
                    Please upload a different file or correct the current file
                    and try again.
                  </p>
                </div>
              )}
            <PromptSendCallPage fieldName="base_prompt" />
            <Pathway fieldName="pathway_id" />
            <Model fieldName="model" />
            <MaxDuration fieldName="max_duration" />
            <Language fieldName="language" />
            <Voice fieldName="voice_id" />
            <Temperature fieldName={"temperature"} />
            <InterruptionThresh fieldName="interruption_threshold" />
            {/* <AMD fieldName="amd" /> */}
            <StartTime fieldName="start_time" />
            <SMSForm fieldName="sms" />
            <ToolsDropdown fieldName="tools" api_key={apiKey} />
            <ReactPhoneInput
              required={false}
              fieldName="transfer_phone_number"
              label={"Transfer Phone Number"}
            />
            <TransferList isTransferKeyPair fieldName="transfer_list" />
            <RequestData fieldName="request_data" />
            {/* <p>{JSON.stringify(KeyValuesToObj(methods.watch("analysis_schema")))}</p> */}
            <AnalysisPreset fieldName="analysis_preset" />
            <AnalysisSchemaCo fieldName="analysis_schema" label={false} />
            <Metadata fieldName="metadata" />
            <Pronunciation fieldName="pronunciation_guide" />
            <AnsweredBy fieldName="answered_by_enabled" />
            <WaitGreeting fieldName="wait_for_greeting" />
            <BlockInterruptions fieldName="block_interruptions" />
            <Record fieldName="record" />
            {user.local_enabled && <LocalDialing fieldName={"local_dialing"} />}
            <FirstSetence fieldName="first_sentence" />
            <Webhook fieldName="webhook" />
            <CustomDeployment fieldName="endpoint" />
            <VoicemailActionSelect fieldName="voicemail_action" />
            <Voicemail_message fieldName="voicemail_message" />
            {/* <ActionBar bottom>
                     <Button submit loading={submitting}>
                        Send Batch
                     </Button>
                     <div style={{ marginLeft: "20px" }}>
                        <TestMode fieldName="test_mode" />
                     </div>
                  </ActionBar> */}
          </Form>
        </FormProvider>
      )}

      <Modal
        open={promptModal}
        onClose={() => {
          methods.setValue("prompt_modal", false);
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p
              style={{ margin: 0, padding: 0, fontWeight: "500", fontSize: 18 }}
            >
              Saved Prompts ({savedPrompts.length})
            </p>
            <img
              src={CloseIcon}
              onClick={() => {
                methods.setValue("prompt_modal", false);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "25px",
            }}
          >
            {savedPrompts.length > 0 ? (
              savedPrompts.map((prompt, index) => (
                <PromptItem
                  key={index}
                  onClick={() => {
                    methods.setValue("base_prompt", prompt.prompt);
                    methods.setValue("prompt_modal", false);
                    methods.setValue("selected_saved_prompt_id", prompt.id);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <p style={{ fontSize: 14, fontWeight: "500" }}>
                      {prompt?.name || "My Prompt"}
                    </p>
                    <img
                      onClick={() => handleDeletePrompt(prompt.id)}
                      height={14}
                      width={14}
                      src={TrashIcon}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  <p
                    style={{
                      fontSize: 12,
                      fontWeight: "400",
                      marginTop: "2.5px",
                      color: "#555",
                    }}
                  >
                    {prompt?.prompt?.length > 400
                      ? prompt.prompt.slice(0, 400) + "..."
                      : prompt.prompt}
                  </p>
                </PromptItem>
              ))
            ) : (
              <p>No prompts saved yet.</p>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        open={savePromptModal}
        onClose={() => {
          methods.setValue("save_prompt_modal", false);
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p
              style={{ margin: 0, padding: 0, fontWeight: "550", fontSize: 16 }}
            >
              Save Prompt
            </p>
            <img
              src={CloseIcon}
              onClick={() => {
                methods.setValue("save_prompt_modal", false);
              }}
              style={{ cursor: "pointer" }}
            />
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "25px",
            }}
          >
            <input
              onFocus={() => {
                methods.setValue("selected_edit_prompt_id", null);
              }}
              type="text"
              placeholder="Enter a name for your prompt"
              onChange={(e) => setPromptName(e.target.value)}
              style={{
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #f0f0f0",
              }}
            />

            {savedPrompts.length > 0 && (
              <div>
                <p
                  style={{
                    fontSize: 10,
                    fontWeight: "550",
                    color: "grey",
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  - OR -
                </p>
                <p
                  style={{
                    fontSize: 14,
                    fontWeight: "500",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  Update a saved prompt:
                </p>
                {savedPrompts.map((prompt, index) => (
                  <div
                    key={index}
                    onClick={() => {
                      if (
                        methods.getValues("selected_edit_prompt_id") ===
                        prompt.id
                      ) {
                        methods.setValue("selected_edit_prompt_id", null);
                      } else {
                        methods.setValue("selected_edit_prompt_id", prompt.id);
                      }
                    }}
                    style={{
                      cursor: "pointer",
                      border:
                        methods.getValues("selected_edit_prompt_id") ===
                        prompt.id
                          ? "1px solid blue"
                          : "1px solid #f0f0f0",
                      padding: "10px",
                      borderRadius: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    <p style={{ fontSize: 14, fontWeight: "500" }}>
                      {prompt?.name || "My Prompt"}{" "}
                      {methods.getValues("selected_edit_prompt_id") ===
                        prompt.id && "(Currently Editing)"}
                    </p>
                    <p
                      style={{
                        fontSize: 12,
                        fontWeight: "400",
                        marginTop: "2.5px",
                        color: "#555",
                      }}
                    >
                      {prompt?.prompt?.length > 400
                        ? prompt.prompt.slice(0, 400) + "..."
                        : prompt.prompt}
                    </p>
                  </div>
                ))}
              </div>
            )}

            <Button
              loading={loadingPrompt}
              onClick={() => {
                if (methods.getValues("selected_edit_prompt_id") !== null) {
                  // Update the prompt
                  handleUpdatePrompt();
                } else {
                  handleCreatePrompt();
                }
              }}
              style={{ width: "100%", borderRadius: "5px" }}
            >
              Save Prompt
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;
