import axios from "axios";

export const testRequest = async (url, headers, body, query, method) => {
  console.log("headers", headers);

  const requestUrl = query
    ? `${url}${query && "?"}${query
        .map(
          (q) => `${encodeURIComponent(q.key)}=${encodeURIComponent(q.value)}`,
        )
        .join("&")}`
    : url;

  try {
    let response;
    if (method === "get") {
      response = await axios[method](requestUrl, { headers });
    } else {
      response = await axios[method](requestUrl, body, { headers });
    }
    return response;
  } catch (error) {
    console.error("Error in request:", error);
    return { error };
  }
};
