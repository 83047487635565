import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import Select from "components/core/Select";
import Button from "components/core/Button";
import { useNavigate } from "react-router-dom";
import { getUserData } from "utils/funcs/browser/getUserData";
import { toast } from "react-toastify";
import LightTooltip from "./ToolTipDesc";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

export default function AnalysisPreset({ fieldName }) {
  const [presets, setPresets] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setValue, watch } = useFormContext();
  const navigate = useNavigate();

  const currentPreset = watch(fieldName);

  useEffect(() => {
    fetchPresets();
  }, []);

  const fetchPresets = async () => {
    try {
      const { api_key: apiKey } = getUserData();
      const response = await fetch("/api/analysis_preset/load_presets", {
        headers: {
          Authorization: apiKey,
        },
      });
      const data = await response.json();
      if (Array.isArray(data)) {
        setPresets(data);
      } else {
        throw new Error("Invalid data format");
      }
      setLoading(false);
    } catch (error) {
      toast.error("Error fetching analysis presets.");
      setLoading(false);
    }
  };

  const handlePresetChange = (e) => {
    setValue(fieldName, e.target.value);
  };

  const openPresetsInNewTab = () => {
    window.open("/dashboard?page=analytics&tab=analysis_presets", "_blank");
  };

  const description = (
    <span>
      Select an Analysis Preset to use for this call. Analysis Presets define
      the structure for extracting specific information from the call.
    </span>
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex justify-between items-center">
        <Select
          fieldName={fieldName}
          label={
            <span>
              Analysis Preset
              <LightTooltip title={description} placement="right-start" arrow>
                <HelpOutlineIcon
                  color="primary"
                  style={{ paddingLeft: "4px" }}
                />
              </LightTooltip>
            </span>
          }
          onChange={handlePresetChange}
          disabled={loading}
          value={currentPreset || ""}
        >
          <option value="">Select an Analysis Preset</option>
          {presets.map((preset) => (
            <option key={preset.id} value={preset.id}>
              {preset.name}
            </option>
          ))}
        </Select>
        <Button
          appearance="outline"
          onClick={openPresetsInNewTab}
          style={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "4px",
            height: "35px", // Adjust this value to match your design
            padding: "0 12px",
          }}
        >
          <span>Manage Presets</span>
          <OpenInNewIcon style={{ fontSize: "16px" }} />
        </Button>
      </div>
      {loading && <p className="text-sm text-gray-500">Loading presets...</p>}
    </div>
  );
}
