// This function requires the 'libphonenumber-js' library
// Install it using: npm install libphonenumber-js

import { parsePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";

export const validateAndFormatPhoneNumber = (phoneNumber) => {
  try {
    const parsedNumber = parsePhoneNumber(phoneNumber);
    if (parsedNumber && isValidPhoneNumber(phoneNumber)) {
      return {
        params: phoneNumber,
        response: parsedNumber,
        error: null,
      };
    } else {
      throw new Error("Invalid phone number");
    }
  } catch (error) {
    // console.error('Error validating phone number:', error.message)

    let errorMsg;
    switch (error.message) {
      case "NOT_A_NUMBER":
        errorMsg = "Error: Not a number.";
        break;
      case "TOO_SHORT":
        errorMsg = "Error: Not a long enough number.";
        break;
      case "Invalid phone number":
      default:
        errorMsg = "Error: Invalid phone number.";
    }

    return {
      params: phoneNumber,
      response: null,
      error: errorMsg,
    };
  }
};
