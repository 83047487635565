import styled from "styled-components";

export const KeyValues = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: flex-start;
  width: 100%;
`;

export const KeyValue = styled.div`
  color: #676775;
  font-weight: 550;
  width: 100%;
  & > p:first-child {
    display: inline;
    margin-right: 3px;
  }
  & > p:nth-child(2) {
    font-weight: 400;
    display: inline;
  }
`;

export const DataCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  background-color: ${({ modal }) => (modal ? "white" : "#fbfbfa")};
  border-radius: 12px;
  width: 100%;
  overflow-y: auto; /* This line allows scrolling */
  scrollbar-width: none; /* This line hides the scrollbar on Firefox */
  &::-webkit-scrollbar {
    display: none; /* This line hides the scrollbar on Chrome, Safari, Brave, etc */
  }
`;
