import logo from "../../assets/brand/logo-infinity.svg";

export default function FormHeader({ title, subtitle }) {
  return (
    <div className="flex flex-col gap-2">
      <img src={logo} alt="logo" className="w-16" />
      <h1 className="font-medium mt-5">{title}</h1>
      <p className="text-secondaryt">{subtitle}</p>
    </div>
  );
}
