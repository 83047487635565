import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  XMarkIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import {
  fetchTranscript,
  fetchAndUpdateTranscriptStatus,
} from "utils/funcs/call-logs/fetchTranscript";
import { getUserData } from "utils/funcs/browser/getUserData";
import { Button } from "@radix-ui/themes";

const ThumbUpIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-thumbs-up"
  >
    <path d="M7 10v12" />
    <path d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
  </svg>
);

const ThumbDownIcon = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="lucide lucide-thumbs-down"
  >
    <path d="M17 14V2" />
    <path d="M9 18.12 10 14H4.17a2 2 0 0 1-1.92-2.56l2.33-8A2 2 0 0 1 6.5 2H20a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2h-2.76a2 2 0 0 0-1.79 1.11L12 22h0a3.13 3.13 0 0 1-3-3.88Z" />
  </svg>
);

const TranscriptSlideOut = ({
  isOpen,
  onClose,
  callIds,
  initialCallId,
  setFocusedTranscriptCId,
}) => {
  const [currentTranscript, setCurrentTranscript] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentCallId, setCurrentCallId] = useState(null);
  const { user } = getUserData();
  const slideOutRef = useRef(null);

  const loadTranscript = useCallback(
    async (callId) => {
      if (!callId) return;
      setLoading(true);
      try {
        const data = await fetchTranscript(callId, user.id);
        setCurrentTranscript({
          callId: callId,
          transcript: data.transcript || [],
        });
      } catch (error) {
        console.error("Error fetching transcript:", error);
        setCurrentTranscript({ callId: callId, transcript: [] });
      } finally {
        setLoading(false);
      }
    },
    [user.id],
  );

  const handleNavigation = useCallback(
    (direction) => {
      setCurrentIndex((prevIndex) => {
        const newIndex =
          direction === "next"
            ? Math.min(prevIndex + 1, callIds?.length - 1)
            : Math.max(prevIndex - 1, 0);
        const newCallId = callIds[newIndex];
        setCurrentCallId(newCallId);
        setFocusedTranscriptCId(newCallId);
        return newIndex;
      });
    },
    [callIds, setFocusedTranscriptCId],
  );

  useEffect(() => {
    if (isOpen && initialCallId) {
      const index = callIds?.indexOf(initialCallId);
      if (index !== -1) {
        setCurrentIndex(index);
        setCurrentCallId(initialCallId);
        setFocusedTranscriptCId(initialCallId);
      }
    }
  }, [isOpen, initialCallId, callIds, setFocusedTranscriptCId]);

  useEffect(() => {
    if (isOpen && currentCallId) {
      loadTranscript(currentCallId);
    }
  }, [isOpen, currentCallId, loadTranscript]);

  useEffect(() => {
    if (!isOpen) {
      setCurrentTranscript(null);
      setCurrentCallId(null);
      setLoading(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (currentTranscript && currentTranscript.callId !== currentCallId) {
      setCurrentTranscript(null);
      loadTranscript(currentCallId);
    }
  }, [currentCallId, currentTranscript, loadTranscript]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        slideOutRef.current &&
        !slideOutRef.current.contains(event.target) &&
        isOpen
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleSetFeedbackStatus = async (txId, status) => {
    if (!currentTranscript) return;

    setCurrentTranscript((prevTranscript) => ({
      ...prevTranscript,
      transcript: prevTranscript.transcript.map((item) => {
        if (item.id === txId) {
          return { ...item, status: item.status === status ? null : status };
        } else {
          return item;
        }
      }),
    }));

    if (status !== "THUMB_UP" && status !== "THUMB_DOWN") return;

    let s =
      currentTranscript.transcript.find((doc) => doc.id === txId).status ===
      status
        ? null
        : status;

    await fetchAndUpdateTranscriptStatus(currentTranscript.callId, txId, s);
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "j") {
        handleNavigation("next");
      } else if (event.key === "k") {
        handleNavigation("prev");
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleNavigation]);

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-30 transition-opacity ${
        isOpen ? "opacity-100" : "opacity-0 pointer-events-none"
      } z-[9999999]`}
    >
      <div
        ref={slideOutRef}
        className={`fixed inset-y-0 right-0 w-1/3 max-w-4xl bg-white shadow-lg transform ${
          isOpen ? "translate-x-0" : "translate-x-full"
        } transition-transform duration-300 ease-in-out overflow-hidden flex flex-col`}
      >
        <div
          className="flex justify-between items-center px-6 py-4 border-b border-gray-200"
          style={{ height: 54 }}
        >
          <div className="flex flex-col">
            <h2 className="text-lg font-semibold text-gray-800">
              Transcript {currentIndex + 1} of {callIds?.length}
            </h2>
            {currentCallId && (
              <p className="text-xs text-gray-500">Call ID: {currentCallId}</p>
            )}
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out"
          >
            <XMarkIcon className="h-5 w-5" />
          </button>
        </div>

        <div className="flex-grow overflow-y-auto px-6 py-4">
          {loading ? (
            <div className="flex justify-center items-center h-full">
              <svg
                className="animate-spin h-6 w-6 mb-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          ) : currentTranscript && currentTranscript?.transcript?.length > 0 ? (
            <div className="space-y-4">
              {currentTranscript.transcript.map((entry, index) => (
                <div
                  key={index}
                  className={`flex ${
                    entry.user.toLowerCase() === "user"
                      ? "justify-start"
                      : "justify-end"
                  }`}
                >
                  <div
                    className={`max-w-[85%] ${
                      entry.user.toLowerCase() === "user"
                        ? "bg-gray-100"
                        : "bg-blue-50"
                    } rounded-lg p-3 shadow-sm`}
                  >
                    {entry.user.toLowerCase() !== "user" && (
                      <div className="flex items-center mb-1">
                        <span className="bg-blue-100 text-blue-800 text-sm font-medium px-2 py-0.5 rounded">
                          Bland
                        </span>
                      </div>
                    )}
                    <p
                      className="text-sm text-gray-800"
                      style={{ fontSize: 13 }}
                    >
                      {entry.text}
                    </p>
                    {entry.user.toLowerCase() !== "user" && (
                      <div className="flex items-center mt-2 space-x-2">
                        <button
                          onClick={() =>
                            handleSetFeedbackStatus(entry.id, "THUMB_UP")
                          }
                          className="focus:outline-none"
                        >
                          <ThumbUpIcon
                            color={
                              entry.status === "THUMB_UP"
                                ? "#3b82f6"
                                : "#9ca3af"
                            }
                          />
                        </button>
                        <button
                          onClick={() =>
                            handleSetFeedbackStatus(entry.id, "THUMB_DOWN")
                          }
                          className="focus:outline-none"
                        >
                          <ThumbDownIcon
                            color={
                              entry.status === "THUMB_DOWN"
                                ? "#ef4444"
                                : "#9ca3af"
                            }
                          />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-500 text-md">
              No transcript available.
            </p>
          )}
        </div>

        <div className="px-6 py-4 border-t border-gray-200">
          <div className="flex justify-between mb-4">
            <div>
              <button
                onClick={() => handleNavigation("prev")}
                disabled={currentIndex === 0}
                className={`flex items-center px-3 py-2 bg-gray-100 text-sm text-gray-700 rounded ${
                  currentIndex === 0
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-200"
                }`}
              >
                <ChevronLeftIcon className="h-4 w-4 mr-1" />
                Previous
              </button>
              <span style={{ fontSize: 9 }} className="text-gray-600 text-xs">
                Hint: you can also press 'K'
              </span>
            </div>
            <div>
              <button
                onClick={() => handleNavigation("next")}
                disabled={currentIndex === callIds?.length - 1}
                style={{ marginLeft: "auto" }}
                className={`flex items-center text-sm px-3 py-2 bg-gray-100 text-gray-700 rounded ${
                  currentIndex === callIds?.length - 1
                    ? "opacity-50 cursor-not-allowed"
                    : "hover:bg-gray-200"
                }`}
              >
                Next
                <ChevronRightIcon className="h-4 w-4 ml-1" />
              </button>
              <span
                style={{ textAlign: "right", fontSize: 9 }}
                className="text-gray-600 text-xs"
              >
                Hint: you can also press 'J'
              </span>
            </div>
          </div>
          <Button
            type="button"
            style={{ width: "100%" }}
            color="lightblue"
            variant="solid"
            onClick={() =>
              window.open("https://app.bland.ai/enterprise", "_blank")
            }
            className="w-full bg-blue-500 hover:bg-blue-600 text-white text-sm font-medium py-2 px-4 rounded transition duration-150 ease-in-out"
          >
            <p style={{ fontSize: 13 }}>Interested in Fine-Tuning?</p>
          </Button>
          <p style={{ fontSize: 10 }} className="mt-2 text-xs text-gray-500">
            We're constantly improving. For a fine-tuned model tailored to your
            needs, reach out to our team.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TranscriptSlideOut;
