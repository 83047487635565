import React, { useState, useEffect, useContext, createContext } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentStatus from "./old (ignore)/subpages/PaymentStatus";
import { ClientSecretContext } from "../contexts/ClientSecretContext";
import { Link } from "react-router-dom";
import { STRIPE_PUBLIC_KEY } from "../config/stripe";

const ConfirmationPage = () => {
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  const [clientSecret, setClientSecret] = useContext(ClientSecretContext);

  useEffect(() => {
    const tempSecret = new URLSearchParams(window.location.search).get(
      "setup_intent_client_secret",
    );

    if (tempSecret) {
      setClientSecret(tempSecret);
    }
  }, []);

  return (
    <div className="py-16 px-4 md:px-0 mx-auto relative w-full h-screen flex flex-col items-center justify-center">
      <div className="max-w-lg mx-auto bg-white p-8 border border-gray-300 shadow-2xl rounded">
        <h1 className="text-3xl font-bold mb-8 text-gray-900 text-center">
          Confirmation Page
        </h1>
        {clientSecret && stripePromise && (
          <div className="mb-8">
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <PaymentStatus />
            </Elements>
          </div>
        )}
        <Link to="/home" className="text-blue-500 hover:underline">
          Return Home
        </Link>
      </div>
    </div>
  );
};

export default ConfirmationPage;
