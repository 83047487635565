import { useEffect } from "react";

export default function Enterprise() {
  function getCookieValue(cookieName) {
    var name = cookieName + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");

    console.log({
      name,
      cookieName,
      decodedCookie,
      ca,
      cookies: document.cookie,
    });

    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        console.log({ csub: c.substring(name.length, c.length) });
        return c.substring(name.length, c.length);
      }
    }

    return "";
  }

  function extractUTMParams(url) {
    var utmParams = "";
    var urlObj = new URL(url);
    var searchParams = new URLSearchParams(urlObj.search);
    var utmKeys = [
      "utm_source",
      "utm_medium",
      "utm_campaign",
      "utm_term",
      "utm_content",
    ];
    utmKeys.forEach(function (key) {
      if (searchParams.has(key)) {
        utmParams += `${key}=${searchParams.get(key)}&`;
      }
    });

    return utmParams.slice(0, -1);
  }

  window.addEventListener("load", function (event) {
    console.log({ event });
    var landingUrl = window.location.href;
    console.log({ landingUrl });
    var utmParams = "";
    if (landingUrl) {
      utmParams = extractUTMParams(landingUrl);
    }

    console.log({ landingUrl, utmParams });

    var iframe = document.querySelector("iframe");
    var iframeSrc = iframe.src;
    iframe.src = `${iframeSrc}?${utmParams}`;
  });

  var _hsq = (window._hsq = window._hsq || []);
  console.log({ _hsq });

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hs-scripts.com/45282614.js";
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        margin: 0,
        padding: 0,
        overflow: "hidden",
      }}
    >
      <iframe
        title="default-form"
        style={{
          border: "none",
          height: "100%",
          width: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
        src={`https://forms.default.com/361589`}
        allowFullScreen
      ></iframe>
    </div>
  );
}
