import { PhoneCallIcon } from "assets/icons/navigationIcons";
import styled from "styled-components";
import ActivityBarGraph from "./ActivityBarGraph";
import { Button, Card, IconButton } from "@radix-ui/themes";
import { PageTitle } from "components/core/PageTitle";
import * as Icons from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { TrendingUp } from "lucide-react";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";
import { toDtmy } from "utils/formatting/toDtmy";
// import {
//     CardContent,
//     CardDescription,
//     CardFooter,
//     CardHeader,
//     CardTitle
// } from "@/components/ui/card";
// import {
//     ChartConfig,
//     ChartContainer,
//     ChartTooltip,
//     ChartTooltipContent,
// } from "@/components/ui/chart";

const chartConfig = {
  desktop: {
    label: "Desktop",
    color: "hsl(var(--chart-1))",
  },
  mobile: {
    label: "Mobile",
    color: "hsl(var(--chart-2))",
  },
};

function formatTimestamp(timestamp) {
  const date = new Date(timestamp);

  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();

  return `${month} ${day} ${year}`;
}

function formatTo12HourClock(timestamp) {
  const date = new Date(timestamp);

  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  return `${hours}${ampm}`;
}

const chartData = [
  { month: "January", desktop: 186, mobile: 80 },
  { month: "February", desktop: 305, mobile: 200 },
  { month: "March", desktop: 237, mobile: 120 },
  { month: "April", desktop: 73, mobile: 190 },
  { month: "May", desktop: 209, mobile: 130 },
  { month: "June", desktop: 214, mobile: 140 },
];

export function formatNumberWithCommas(number) {
  if (number === 0) return number.toString();
  let numStr = number.toString();
  let parts = numStr.split(".");
  let integerPart = parts[0];
  let decimalPart = parts.length > 1 ? "." + parts[1] : "";
  let integerFormatted = "";
  let count = 0;
  for (let i = integerPart.length - 1; i >= 0; i--) {
    count++;
    integerFormatted = integerPart[i] + integerFormatted;
    if (count % 3 === 0 && i !== 0) {
      integerFormatted = "," + integerFormatted;
    }
  }
  return integerFormatted + decimalPart;
}

export const CallActivity = ({ call_activity_history, timeframe = "week" }) => {
  const navigate = useNavigate();
  const formattedData = call_activity_history.map((item) => ({
    time: formatTo12HourClock(item.time),
    date: formatTimestamp(item.time),
    calls: item.count,
    //voicemail: item.count
  }));

  console.log(formattedData);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        columnGap: 10,
      }}
    >
      <Card style={{ maxWidth: 400, padding: "15px" }}>
        {/* <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <IconContainer style={{ color: "grey" }}>
                        <PhoneCallIcon style={{ height: 14, width: 14 }} />
                    </IconContainer>
                    <p style={{ fontSize: 20, fontWeight: '500', letterSpacing: 0.25, color: "#000" }}>Call Activity</p>
                    <IconButton size={"1"} variant='soft' color={"gray"} style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => navigate("/dashboard?page=analytics")}>
                        <Icons.ArrowRightIcon style={{ height: 14, width: 14 }} />
                    </IconButton>
                </div> */}
        {/* <div style={{ marginTop: "12px" }}>
                    <p style={{ color: "darkgrey", fontSize: 12, fontWeight: '450' }}>Dispatched Calls</p>
                    {call_activity_history?.length > 0 ? (
                        <p style={{ fontWeight: '500', fontSize: 22 }}>{formatNumberWithCommas(call_activity_history?.reduce((acc, curr) => acc + curr?.count, 0) || 0)}</p>
                    ) : (<p>No History</p>)}
                </div> */}
        <div>
          <p style={{ fontSize: 16, fontWeight: "525" }}>Total Calls Sent</p>
          <p style={{ color: "gray", fontSize: 12, fontWeight: "400" }}>
            {formattedData[0]?.date} -{" "}
            {formattedData[formattedData.length - 1]?.date}
          </p>
        </div>
        <div style={{ minHeight: 180, width: "100%", marginTop: "15px" }}>
          <BarChart data={formattedData} width={320} height={180}>
            <CartesianGrid
              strokeWidth={0.5}
              stroke="#F0F0F0"
              vertical={false}
            />
            <XAxis
              dataKey={"time"}
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tick={{ fontSize: 10, fontWeight: "500" }}
              ticks={[
                formattedData[0]?.time,
                formattedData[formattedData.length - 1]?.time,
              ]} // Show only first and last ticks
            />
            <Bar dataKey="calls" fill="#2462ec" radius={2} />
          </BarChart>
        </div>
        <div style={{ marginTop: "5px" }}>
          {call_activity_history?.length > 0 ? (
            <p style={{ fontWeight: "500", fontSize: 14 }}>
              {formatNumberWithCommas(
                call_activity_history?.reduce(
                  (acc, curr) => acc + curr?.count,
                  0,
                ) || 0,
              )}{" "}
              calls today
            </p>
          ) : (
            <p>No History</p>
          )}
          <p style={{ color: "gray", fontSize: 10, fontWeight: "400" }}>
            Last 24 Hours
          </p>
        </div>
      </Card>
    </div>
  );
};

/*

            <Card style={{ maxWidth: 300, padding: "15px" }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <IconContainer style={{ color: "grey" }}>
                        <PhoneCallIcon style={{ height: 14, width: 14 }} />
                    </IconContainer>
                    <p style={{ fontSize: 20, fontWeight: '500', letterSpacing: 0.25, color: "#000" }}>Call Activity</p>
                    <IconButton size={"1"} variant='soft' color={"gray"} style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => navigate("/dashboard?page=analytics")}>
                        <Icons.ArrowRightIcon style={{ height: 14, width: 14 }} />
                    </IconButton>
                </div> 
                <div style={{ marginTop: "12px" }}>
                    <p style={{ color: "darkgrey", fontSize: 12, fontWeight: '450' }}>Dispatched Calls</p>
                    {call_activity_history?.length > 0 ? (
                        <p style={{ fontWeight: '500', fontSize: 22 }}>{formatNumberWithCommas(call_activity_history?.reduce((acc, curr) => acc + curr?.count, 0) || 0)}</p>
                    ) : (<p>No History</p>)}
                </div>
                <div style={{ minHeight: 200, width: "100%" }}>
                    <BarChart data={chartData} width={260} height={200}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                            dataKey="month"
                            tickLine={false}
                            tickMargin={10}
                            axisLine={false}
                            tickFormatter={(value) => value.slice(0, 3)}
                        />
                        <YAxis
                            tickLine={false}
                            tickMargin={10}
                            axisLine={false}
                            tickFormatter={(value) => formatNumberWithCommas(value)}
                            width={40}
                        />
                        <Bar dataKey="desktop" fill="#8884d8" radius={4} />
                        <Bar dataKey="mobile" fill="#82ca9d" radius={4} />
                    </BarChart>
                </div>
            </Card>

*/

const IconContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 0.375rem;
  padding: 5px;
  margin-right: 15px;
  background: #e5e5e5;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Box = styled.div`
  border: 1px solid #eeeeee;
  padding: 15px 15px;
  border-radius: 0.375rem;
  width: 30%;
  max-width: 400px;
  min-width: 240px;
  background: #fbfafb;
`;
