import styled from "styled-components";
import { useEffect, useState } from "react";
import Label from "components/core/Label";

export default function TabSelect({ items, label, onChange, value }) {
  const [active, setActive] = useState(0);
  const [selectedValue, setSelectedValue] = useState(value);

  // Function to handle item click
  const handleChange = (value) => {
    // Update selected value
    setSelectedValue(value);
    onChange(value);
  };

  useEffect(() => {
    // find index of selected value
    const index = items.findIndex((item) => item.value === selectedValue);
    // set active index
    setActive(index);
  }, [selectedValue, items]);

  return (
    <Wrapper>
      <Label>{label}</Label>
      <TabBody>
        {items.map((item, index) => (
          <Item
            key={index}
            active={index === active}
            onClick={() => handleChange(item.value)}
          >
            {item.name}
          </Item>
        ))}
      </TabBody>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;
const TabBody = styled.div`
  background-color: #fbfbfa;
  padding: 3px;
  height: 50px;
  border-radius: 100px;
  display: flex;
  border: 1px solid #eeeeee;
`;
const Item = styled.div`
  border-radius: 100px;
  border: ${({ active }) => (active ? "1px solid #eeeeee" : "none")};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ active }) => (active ? "#FFFFFF" : "transparent")};
`;
