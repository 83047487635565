import React, { useState, useEffect } from "react";
import { PlusIcon, XMarkIcon, UserCircleIcon } from "@heroicons/react/24/solid";
import ReactPhoneInput from "components/core/PhoneInput";
import { FormProvider, useForm } from "react-hook-form";
import { Loader } from "lucide-react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

export default function AuthUsers({ user }) {
  const [secondaryNumbers, setSecondaryNumbers] = useState([]);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const methods = useForm();

  useEffect(() => {
    fetchSecondaryNumbers();
  }, [user]);

  const fetchSecondaryNumbers = async () => {
    setLoading(true);
    setError(null);
    try {
      // Simulating API call
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const sortedNumbers =
        user?.secondary_numbers?.sort(
          (a, b) =>
            b.enabled - a.enabled ||
            a.phone_number.localeCompare(b.phone_number),
        ) || [];
      setSecondaryNumbers(sortedNumbers);
    } catch (err) {
      setError("Failed to fetch authorized users. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDisableNumber = async (number) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("/api/secondary_users/disable", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth"),
        },
        body: JSON.stringify({ phone_number: number }),
      });
      const data = await response.json();
      if (data?.secondary_numbers) {
        setSecondaryNumbers(data.secondary_numbers);
        // Mixpanel.track("Disabled secondary number");
      } else {
        throw new Error("Failed to disable number");
      }
    } catch (err) {
      setError("Failed to disable number. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleAddNumber = async (formData) => {
    setLoading(true);
    setError(null);
    console.log(formData.phone_number);
    try {
      const response = await fetch("/api/secondary_users/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth"),
        },
        body: JSON.stringify({ phone_number: formData.phone_number }),
      });
      const data = await response.json();
      if (data?.secondary_numbers) {
        setSecondaryNumbers(data.secondary_numbers);
        setShowAddUserModal(false);
        methods.reset();
        // Mixpanel.track("Added secondary number");
      } else {
        throw new Error("Failed to add number");
      }
    } catch (err) {
      setError("Failed to add number. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="space-y-8">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-semibold text-gray-900">
            Authorized Users
          </h2>
          <button
            onClick={() => setShowAddUserModal(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <PlusIcon className="h-5 w-5 mr-2" />
            Add User
          </button>
        </div>

        {error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <XMarkIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">Error</h3>
                <div className="mt-2 text-sm text-red-700">
                  <p>{error}</p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 rounded-lg overflow-hidden">
          {loading && secondaryNumbers.length === 0 ? (
            <div className="flex justify-center items-center h-64">
              <AiOutlineLoading3Quarters className="animate-spin text-indigo-600 text-3xl" />
            </div>
          ) : secondaryNumbers.length === 0 ? (
            <div className="text-center py-12">
              <UserCircleIcon className="mx-auto h-12 w-12 text-gray-400" />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                No authorized users
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Add a new user to get started.
              </p>
            </div>
          ) : (
            <ul className="divide-y divide-gray-200">
              {secondaryNumbers.map((number) => (
                <li
                  key={number.phone_number}
                  className="px-6 py-5 flex items-center justify-between"
                >
                  <div className="flex items-center">
                    <div
                      className={`flex-shrink-0 h-3 w-3 rounded-full ${number.enabled ? "bg-green-400" : "bg-gray-300"}`}
                    ></div>
                    <p className="ml-4 text-sm font-medium text-gray-900">
                      {number.phone_number}
                    </p>
                  </div>
                  <button
                    onClick={() => handleDisableNumber(number.phone_number)}
                    className={`ml-4 px-3 py-1.5 rounded-md text-sm font-medium transition-colors ${
                      number.enabled
                        ? "text-red-700 bg-red-50 hover:bg-red-100"
                        : "text-gray-400 bg-gray-50 cursor-not-allowed"
                    }`}
                    disabled={!number.enabled || loading}
                  >
                    {number.enabled ? "Disable" : "Inactive"}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      {showAddUserModal && (
        <div className="fixed inset-0  bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl w-1/3 border border-gray-200">
            <div className="px-6 py-6">
              <div className="flex justify-between items-center mb-6">
                <h3 className="text-lg font-medium text-gray-900">
                  Add New User
                </h3>
                <button
                  onClick={() => setShowAddUserModal(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <XMarkIcon className="h-6 w-6" />
                </button>
              </div>
              <FormProvider {...methods}>
                <form
                  onSubmit={methods.handleSubmit(handleAddNumber)}
                  className="space-y-4"
                >
                  <div>
                    <label
                      htmlFor="phone_number"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Phone Number
                    </label>
                    <ReactPhoneInput fieldName="phone_number" />
                  </div>
                  <div className="flex justify-end space-x-3">
                    <button
                      type="button"
                      className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => setShowAddUserModal(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      disabled={
                        loading ||
                        methods.watch("phone_number")?.toString().length <= 10
                      }
                    >
                      {loading ? (
                        <div className="flex items-center">
                          <Loader className="animate-spin h-4 w-4 mr-2" />
                          Adding...
                        </div>
                      ) : (
                        "Add User"
                      )}
                    </button>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
