import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Dialog, Switch } from "@headlessui/react";
import FlowContext from "../contextFlow";
import { getUserData } from "utils/funcs/browser/getUserData";

const PathwayTransferModal = ({ id, data, setIsOpen }) => {
  const user = useMemo(() => getUserData(), []);
  const [pathways, setPathways] = useState([]);
  const [selectedPathwayNodes, setSelectedPathwayNodes] = useState([]);
  const [name, setName] = useState(data.name || "");

  const [transferPathway, setTransferPathway] = useState(
    data.transferPathway || "",
  );
  const [transferPathwayNode, setTransferPathwayNode] = useState(
    data.transferPathwayNode || "",
  );

  const getSelectedPathwayData = async () => {
    console.log("fetching pathway data");
    const response = await fetch(
      `https://us.api.bland.ai/v1/convo_pathway/${transferPathway}`,
      {
        method: "GET",
        headers: {
          authorization: user.api_key,
        },
      },
    );
    console.log("response", response);
    if (response.ok) {
      const data = await response.json();
      console.log("pathway data", data);
      setSelectedPathwayNodes(data.nodes);
    } else {
      console.error("Failed to fetch pathway data");
    }
  };

  useEffect(() => {
    console.log("running effect", transferPathway);
    if (transferPathway !== "") {
      console.log("selected pathway changed", transferPathway);
      getSelectedPathwayData();
    }
  }, [transferPathway]);

  useEffect(() => {
    const fetchPathways = async () => {
      const response = await fetch(`https://us.api.bland.ai/v1/convo_pathway`, {
        method: "GET",
        headers: {
          authorization: user.api_key,
        },
      });
      if (response.ok) {
        const data = await response.json();
        console.log("pathways", data);
        const pathways = data.map((pathway) => {
          return {
            id: pathway.id,
            name: pathway.name,
          };
        });
        setPathways(pathways);
      } else {
        console.error("Failed to fetch pathways");
      }
    };

    fetchPathways();
  }, [id]);

  const { exportFlow, triggerUpdate, elements } = useContext(FlowContext);

  return (
    <Dialog.Description className="space-y-4">
      <div className="space-y-2">
        <div>Name:</div>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="border border-gray-300 p-2 rounded-md w-full"
        />
        <label className="block text-[12px] font-medium text-gray-700">
          Transfer Pathway{" "}
          <span className="text-gray-400 text-[11px]">
            (Pick the Pathway to transfer this call to)
          </span>
        </label>
        <select
          value={transferPathway}
          onChange={(e) => setTransferPathway(e.target.value)}
          className="block w-full pl-3 pr-10 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md text-[12px]"
        >
          <option value="">Select Pathway</option>
          {pathways.map((el) => (
            <option key={el.id} value={el.id} className="text-[14px]">
              {el.name}
            </option>
          ))}
        </select>

        {transferPathway && (
          <>
            <label className="block text-[12px] font-medium text-gray-700">
              Pathway Transfer Node{" "}
              <span className="text-gray-400 text-[11px]">
                (Pick the Node to transfer this call to, in the selected
                Pathway. By default, the Start Node is selected.)
              </span>
            </label>
            <select
              value={transferPathwayNode}
              onChange={(e) => setTransferPathwayNode(e.target.value)}
              className="block w-full pl-3 pr-10 py-2 border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md text-[12px]"
            >
              <option value="" className="text-[12px]">
                Select Node
              </option>
              {selectedPathwayNodes.map((el) => (
                <option key={el.id} value={el.id} className="text-[12px]">
                  {el.data.name}
                </option>
              ))}
            </select>
          </>
        )}
      </div>

      <button
        onClick={() => {
          console.log("elements", elements);

          const node = elements.nodes.find((el) => el.id === id);
          console.log("node", node);
          const data = {
            transferPathway: transferPathway,
            name: name,
          };
          if (transferPathwayNode) {
            data.transferPathwayNode = transferPathwayNode;
          }

          setIsOpen(false);
          triggerUpdate(
            {
              nodes: elements.nodes.map((el) => {
                if (el.id === id) {
                  return {
                    ...el,
                    data: data,
                    type: "Transfer Pathway",
                  };
                }
                return el;
              }),
            },
            false,
          );
        }}
        className="bg-indigo-500 text-white py-2 px-4 rounded-md mt-4"
      >
        Save
      </button>
    </Dialog.Description>
  );
};

export default PathwayTransferModal;
