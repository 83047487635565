import React, { useEffect, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getAvailableNumbers } from "utils/funcs/phone-numbers/getAvailableNumbers";
import { purchaseNumber } from "utils/funcs/phone-numbers/purchaseNumber";
import { getUserData } from "utils/funcs/browser/getUserData";
import { AiOutlineLoading } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import { IoCheckmark } from "react-icons/io5";

// Set this to true to easily see the confetti effect
const SHOW_CONFETTI_DEMO = false;
const USFlagSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 912 600"
    className="w-4 h-3 mr-2"
  >
    <rect width="912" height="600" fill="#bf0a30" />
    <rect y="46.15" width="912" height="46.15" fill="#fff" />
    <rect y="138.45" width="912" height="46.15" fill="#fff" />
    <rect y="230.75" width="912" height="46.15" fill="#fff" />
    <rect y="323.05" width="912" height="46.15" fill="#fff" />
    <rect y="415.35" width="912" height="46.15" fill="#fff" />
    <rect y="507.65" width="912" height="46.15" fill="#fff" />
    <rect width="364.8" height="323.1" fill="#002868" />
    <g fill="#fff">
      {[...Array(50)].map((_, i) => {
        const x = 30.4 * (i % 6) + 15.2;
        const y = 28 * Math.floor(i / 6) + 14;
        return <circle key={i} cx={x} cy={y} r="10" />;
      })}
    </g>
  </svg>
);

const PhoneCard = ({ number, location, price, style }) => (
  <div
    className="bg-white rounded-md shadow-sm p-3 flex items-center justify-between border-[0.5px] border-gray-300"
    style={style}
  >
    <div className="flex items-center">
      <USFlagSVG />
      <span className="text-gray-800 text-sm font-semibold">{number}</span>
    </div>
    <div className="flex items-center">
      <span className="text-gray-600 text-xs bg-gray-100 px-2 py-0.5 rounded mr-2">
        {location}
      </span>
      <span className="text-gray-400 text-xs">{price}</span>
    </div>
  </div>
);

const PhoneNumberComponent = () => {
  const backgroundNumbers = [
    { number: "(415) 968-1851", opacity: 0.7, top: "10%", left: "10%" },
    { number: "(628) 555-0113", opacity: 0.3, top: "20%", left: "30%" },
    { number: "(510) 555-0198", opacity: 0.9, top: "30%", left: "50%" },
    { number: "(650) 555-0123", opacity: 0.7, top: "40%", left: "70%" },
    { number: "(925) 555-0147", opacity: 0.3, top: "50%", left: "20%" },
    { number: "(707) 555-0176", opacity: 0.5, top: "60%", left: "40%" },
    { number: "(408) 555-0152", opacity: 0.8, top: "70%", left: "60%" },
    { number: "(669) 555-0194", opacity: 0.8, top: "80%", left: "80%" },
    { number: "(831) 555-0183", opacity: 0.5, top: "90%", left: "40%" },
    { number: "(209) 555-0121", opacity: 0.8, top: "30%", left: "80%" },
    { number: "(916) 555-0110", opacity: 0.9, top: "50%", left: "50%" },
    { number: "(415) 555-0178", opacity: 0.7, top: "10%", left: "70%" },
    { number: "(510) 555-0167", opacity: 0.8, top: "75%", left: "15%" },
  ];

  return (
    <div className="relative w-full h-full  p-8 font-sans overflow-hidden">
      {/* Background Layer */}
      <div className="absolute inset-0">
        {backgroundNumbers.map((item, index) => (
          <div
            key={index}
            className="text-gray-300 text-sm absolute"
            style={{
              opacity: item.opacity,
              top: item.top,
              left: item.left,
              transform: "translate(-50%, -50%)",
              whiteSpace: "nowrap",
            }}
          >
            {item.number}
          </div>
        ))}
      </div>

      {/* Foreground Phone Cards */}
      <div className="relative flex flex-col items-center">
        <PhoneCard
          number="(415) 968-1851"
          location="Mill Valley, CA"
          price="$15/month"
          style={{ marginBottom: "-20px", zIndex: 3 }}
        />
        <PhoneCard
          number="(628) 555-0113"
          location="Oakland, CA"
          price="$15/month"
          style={{ marginBottom: "-20px", marginLeft: "12px", zIndex: 2 }}
        />
        <PhoneCard
          number="(510) 555-0198"
          location="Oakland, CA"
          price="$18/month"
          style={{ zIndex: 1, marginLeft: "18px" }}
        />
      </div>
    </div>
  );
};
const PurchaseNumber = ({
  inbound,
  outbound,
  either,
  preselectedNumber,
  setPaywall,
}) => {
  const { user } = getUserData();
  const navigate = useNavigate();
  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      area_code: "415",
      country_code: "US",
      phone_type: "inbound",
    },
  });

  const [state, setState] = useState({
    availableNumbers: [],
    numberToBuy: null,
    confirmPurchase: false,
    purchasing: false,
    numbersLoading: true,
    countryCodeOptions: ["US", "CA"],
    purchaseSuccess: SHOW_CONFETTI_DEMO,
    showConfetti: SHOW_CONFETTI_DEMO,
    error: null,
  });
  const areaCode = watch("area_code");
  const countryCode = watch("country_code");
  useEffect(() => {
    const getNumbers = async () => {
      setState((prevState) => ({
        ...prevState,
        numbersLoading: true,
        error: null,
      }));
      try {
        const numbers = await getAvailableNumbers(
          user.id,
          watch("area_code"),
          watch("country_code"),
        );
        setState((prevState) => ({
          ...prevState,
          availableNumbers: numbers || [],
          numbersLoading: false,
        }));
      } catch (error) {
        console.error("Error fetching available numbers:", error);
        setState((prevState) => ({
          ...prevState,
          numbersLoading: false,
          error: "Failed to fetch available numbers. Please try again.",
        }));
      }
    };
    getNumbers();
  }, [user.id, areaCode, countryCode]);

  useEffect(() => {
    if (preselectedNumber) {
      setState((prevState) => ({
        ...prevState,
        numberToBuy: preselectedNumber,
        confirmPurchase: true,
      }));
      setValue("phone_number", preselectedNumber);
    }
    if (inbound) {
      setValue("phone_type", "inbound");
    }
    if (outbound) {
      setValue("phone_type", "outbound");
    }
  }, [inbound, outbound, preselectedNumber, setValue]);

  const handleNumberSelect = (number) => {
    setState((prevState) => ({
      ...prevState,
      numberToBuy: number,
      confirmPurchase: true,
      error: null,
    }));
    setValue("phone_number", number);
  };

  const onConfirmPurchase = async (formData) => {
    if (state.purchasing) return;

    setState((prevState) => ({ ...prevState, purchasing: true, error: null }));
    try {
      const isPreselected = preselectedNumber !== null;
      const result = await purchaseNumber(
        formData.phone_number?.phone_number ?? formData.phone_number,
        user.id,
        "inbound",
        isPreselected,
        watch("area_code"),
        formData.phone_number.isoCountry,
      );

      if (result.status === "error") {
        throw new Error(result.message);
      }

      setState((prevState) => ({
        ...prevState,
        purchasing: false,
        purchaseSuccess: true,
        showConfetti: true,
      }));

      setTimeout(() => {
        setState((prevState) => ({ ...prevState, showConfetti: false }));
      }, 5000);
    } catch (error) {
      console.error("Error purchasing number:", error);
      if (error.message === "User has not set up payment info.") {
        toast.error("No Payment Profile setup.");
        navigate(`/dashboard?page=billing-details`);
      } else if (error.message === "Payment Failed") {
        toast.error(
          "Your payment method failed! Try updating your payment method and try again.",
        );
        navigate(`/dashboard?page=billing-details`);
      } else {
        setState((prevState) => ({
          ...prevState,
          purchasing: false,
          error: `An error occurred while purchasing the number: ${error.message}`,
        }));
      }
    }
  };

  const Confetti = useCallback(() => {
    return (
      <div className="confetti-container">
        {[...Array(100)].map((_, index) => (
          <div
            key={index}
            className="confetti"
            style={{
              backgroundColor: [
                "#FF6B6B",
                "#4ECDC4",
                "#45B7D1",
                "#FED766",
                "#2AB7CA",
              ][Math.floor(Math.random() * 5)],
              transform: `rotate(${Math.random() * 360}deg)`,
              left: `${50 + (Math.random() - 0.5) * 100}%`,
              top: `${50 + (Math.random() - 0.5) * 100}%`,
              animationDuration: `${Math.random() * 3 + 2}s`,
              animationDelay: `${Math.random() * 2}s`,
            }}
          />
        ))}
      </div>
    );
  }, []);

  const {
    availableNumbers,
    numberToBuy,
    confirmPurchase,
    purchasing,
    numbersLoading,
    countryCodeOptions,
    purchaseSuccess,
    showConfetti,
    error,
  } = state;

  return (
    <div className="absolute w-full h-full bg-white z-50 overflow-none">
      <header className="bg-white shadow">
        {/* <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold text-gray-900">Purchase Inbound number</h1>
        </div> */}
      </header>
      <main className="max-w-full h-full mx-auto py-6 sm:px-6 lg:px-8">
        {error && (
          <div
            className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
            role="alert"
          >
            <strong className="font-bold">Error: </strong>
            <span className="block sm:inline">{error}</span>
          </div>
        )}

        {purchaseSuccess ? (
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Purchased Successfully!
            </h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-4xl py-4 leading-6 font-medium text-gray-900">
                  {numberToBuy?.friendly_name}
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500 mx-auto">
                  {numberToBuy?.location}
                </p>
              </div>
            </div>
            <p className="text-gray-600 mb-8 mx-auto">
              Purchase and configure a new inbound phone number. ($15/mo.
              subscription using your stored payment method).
            </p>
            <button
              onClick={() => {
                if (setPaywall) {
                  setPaywall(false);
                } else {
                  navigate(0);
                }
              }}
              className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              VIEW ALL NUMBERS
            </button>
          </div>
        ) : !confirmPurchase ? (
          <>
            <div className="w-full flex border-b pb-4">
              <div className="text-gray-600 mb-8 flex flex-col">
                <div className="text-4xl text-black font-bold">
                  Purchase inbound phone number.
                </div>
                <div className="flex flex-wrap w-1/2 pt-2">
                  Purchase and configure a new inbound phone number. ($15/mo.
                  subscription using your stored payment).
                </div>
              </div>
              <PhoneNumberComponent />
            </div>
            <div className="flex flex-row">
              <div className="w-1/2  pr-12">
                <div className="bg-white overflow-hidden sm:rounded-lg mb-8 pt-8 flex flex-col">
                  <div className="px-4 py-5 sm:p-6">
                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                      <div className="sm:col-span-3 flex">
                        <label
                          htmlFor="area_code"
                          className="block text-sm font-medium text-gray-700 my-auto bg-gray-50 border border-gray-100 px-2 py-2 rounded-tl-md rounded-bl-md"
                        >
                          AREA CODE
                        </label>
                        <div className=" my-auto">
                          <input
                            type="text"
                            id="area_code"
                            {...register("area_code")}
                            className=" px-2 py-2  block w-full sm:text-sm border-r border-t border-b border-gray-100 rounded-md"
                            placeholder="415"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3 flex">
                        <label
                          htmlFor="country_code"
                          className=" text-sm my-auto font-medium text-gray-700 border border-gray-100 px-2 py-2 rounded-tl-md rounded-bl-md"
                        >
                          COUNTRY
                        </label>
                        <div className="mt-1">
                          <select
                            id="country_code"
                            {...register("country_code")}
                            className="  px-2 py-3 my-auto text-sm  w-full rounded-md border-r border-t border-b border-gray-100 rounded-md"
                          >
                            {countryCodeOptions.map((option) => (
                              <option key={option} value={option}>
                                {option === "US" ? "United States" : "Canada"}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white overflow-hidden sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      AVAILABLE NUMBERS
                    </h3>
                  </div>
                  <div className="border-t ">
                    {numbersLoading ? (
                      <div className="text-center py-12">
                        <AiOutlineLoading className="inline-block animate-spin text-4xl text-indigo-600" />
                      </div>
                    ) : (
                      <div className="rounded-none py-2 my-2">
                        {availableNumbers.length > 0 ? (
                          availableNumbers.map((number, index) => (
                            <div
                              key={index}
                              className="px-4 py-8 bg-gray-50 sm:px-6 my-4 border rounded-md border-gray-200 "
                            >
                              <div className="flex items-center justify-between">
                                <div className="flex justify-between">
                                  {/* <img src="/api/placeholder/24/16" alt="US Flag" className="h-4 w-6 mr-3" /> */}

                                  <div className="text-xl font-medium text-gray-900 truncate font-mono border-r pr-4">
                                    {number?.friendly_name}
                                  </div>

                                  <div className="text-sm text-gray-500 px-12">
                                    {number?.location}
                                  </div>
                                  <div className="px-2 text-xs p-2 font-semibold border-l text-gray-900 pl-12">
                                    $15/month
                                  </div>
                                </div>
                                <div className="flex items-center">
                                  <button
                                    onClick={() => handleNumberSelect(number)}
                                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                  >
                                    PURCHASE
                                  </button>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <li className="px-4 py-12 text-center text-sm text-gray-500">
                            No available numbers for the given area and country
                            code.
                          </li>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-1/2 flex flex-col">
                <div className="mx-auto flex flex-col border border-gray-100 bg-gray-50 px-10 py-6 mt-12 w-1/2 text-sm">
                  <div className="text-md font-semibold font-mono">
                    Need a Different Country?
                  </div>
                  <div className="font-light">
                    For other countries, please "Bring Your Own Twilio" under
                    "Add-Ons"
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="bg-white  overflow-hidden sm:rounded-lg w-full h-full flex">
            <div className="w-2/3 px-48">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Confirm Purchase
              </h3>
              <div className=" p-4 mb-6 flex w-full justify-between border-b py-8">
                <p className="text-4xl font-bold">
                  {numberToBuy?.friendly_name}
                </p>
                <p className="text-gray-600 text-xl">{numberToBuy?.location}</p>
              </div>
              <div className="mb-6 px-6 py-6 border border-indigo-400 bg-indigo-50 flex justify-between rounded-md w-full">
                <p className="font-semibold my-auto border-r px-2">
                  Payment Monthly
                </p>
                <p className="text-2xl font-bold">$15/month</p>
                <IoCheckmark className="my-auto rounded-full bg-indigo-400 text-2xl text-white p-1" />
              </div>
              <div className="mb-6 my-12 px-4 py-2 flex justify-between w-full border-t border-gray-300">
                <p className="font-semibold my-auto">Total Due Today</p>
                <p className="text-2xl font-bold">$15</p>
              </div>
              <button
                onClick={handleSubmit(onConfirmPurchase)}
                disabled={purchasing}
                className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {purchasing ? (
                  <AiOutlineLoading className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" />
                ) : null}
                {purchasing ? "Processing..." : "CONFIRM PURCHASE"}
              </button>
              <p className="mt-4 text-sm text-gray-500">
                By confirming, you agree to the purchase using your stored
                payment method in accordance with Bland AI's{" "}
                <a href="#" className="text-indigo-600 hover:text-indigo-500">
                  terms
                </a>
                .
              </p>
            </div>
            <div className="w-1/2 bg-gray-300"></div>
          </div>
        )}
      </main>
      {showConfetti && <Confetti />}
      <style jsx>{`
        .confetti-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          pointer-events: none;
          z-index: 1000;
          overflow: hidden;
        }
        .confetti {
          position: absolute;
          width: 10px;
          height: 10px;
          animation: explode 5s ease-in-out forwards;
        }
        @keyframes explode {
          0% {
            transform: scale(0) rotate(0deg);
            opacity: 0;
          }
          10% {
            opacity: 1;
          }
          35%,
          65% {
            transform: scale(1) rotate(360deg);
            opacity: 1;
          }
          100% {
            transform: scale(1.5) rotate(720deg);
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

export default PurchaseNumber;
