import styled from "styled-components";
import Modal from "components/core/Modal";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../utils/funcs/browser/getUserData";
import { accountItems } from "utils/data/menus/accountItems";
import * as Icons from "@radix-ui/react-icons";
import { IconButton } from "@radix-ui/themes";

export default function Avatar({ pageTitle }) {
  const [showMenuItems, setShowMenuItems] = useState(false);
  const navigate = useNavigate();
  const user = getUserData();

  const handleNavigation = (link) => {
    if (link) {
      navigate(link);
      setShowMenuItems(false);
    }
  };

  return (
    <>
      <Wrapper>
        <h1>{pageTitle}</h1>
        {user && (
          <AvatarWrapper
            onClick={() => setShowMenuItems(true)}
            style={{ width: "100%" }}
          >
            <Initials>
              {user.user.first_name[0]}
              {user.user.last_name[0]}
            </Initials>
            <NameBalance>
              <p>{user.user.first_name}</p>
              <p className="text-xs text-secondary">
                {parseFloat(user.current_balance).toFixed(2)} credits
              </p>
            </NameBalance>
            <div style={{ marginLeft: "auto", display: "flex", gap: "8px" }}>
              <IconButton
                variant="surface"
                color="gray"
                size={"2"}
                radius="full"
                style={{ cursor: "pointer", zIndex: "100000" }}
                onClick={(event) => {
                  event.stopPropagation();
                  window.open("https://university.bland.ai/", "_blank");
                }}
              >
                <Icons.ReaderIcon />
              </IconButton>
              <IconButton
                variant="surface"
                color="gray"
                size={"2"}
                radius="full"
                style={{ cursor: "pointer", zIndex: "100000" }}
                onClick={(event) => {
                  event.stopPropagation();
                  window.open("https://discord.gg/at9uP8S6w9", "_blank");
                }}
              >
                <Icons.DiscordLogoIcon />
              </IconButton>
            </div>
          </AvatarWrapper>
        )}
      </Wrapper>
      <Modal open={showMenuItems} onClose={() => setShowMenuItems(false)}>
        <AvatarMenu>
          {accountItems.map((item, index) => (
            <Item
              key={index}
              onClick={() => {
                if (item.name === "Logout") {
                  localStorage.clear();
                  navigate("/login");
                } else {
                  handleNavigation(item.link);
                }
              }}
            >
              <span>{item.name}</span>
              <span>{item.icon}</span>
            </Item>
          ))}
        </AvatarMenu>
      </Modal>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 100;
  width: 100%;
  background-color: white;
  position: relative;
`;

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
  cursor: pointer;
`;

const Initials = styled.div`
  display: flex;
  height: 35px;
  width: 100%;
  max-width: 35px;
  min-width: 35px;
  border-radius: 100%;
  border: 1px solid #eeeeee;
  align-items: center;
  justify-content: center;
  color: #686767;
  background-color: #fbfbfa;
`;

const AvatarMenu = styled.div`
  width: 100%;
  height: auto;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
  text-align: left;
  padding: 10px 12px;
  margin: 1px 0;
  border-radius: 5px;
  justify-content: space-between;
  transition:
    background-color 200ms ease-in-out,
    transform 100ms ease-in-out;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #fbfbfa;
  }
`;

const NameBalance = styled.div`
  display: flex;
  row-gap: -4px;
  flex-direction: column;
  font-family: "Roboto Mono", monospace;
  letter-spacing: -0.54px;
`;
