import { useEffect, useState } from "react";
import { parseCSV } from "utils/tools/parseCsv";
import FileInput from "components/core/FileInput";
import csvIcon from "../../../../assets/icons/csv-import.svg";
import styled from "styled-components";
import Table from "components/core/Table";
import { splitArrayIntoChunks } from "utils/tools/splitArrayIntoChunks";
import { toast } from "react-toastify";

export default function CallData({ data, setData }) {
  const [file, setFile] = useState(null);
  const [showTable, setShowTable] = useState(false);

  const pageSize = 50;

  useEffect(() => {
    // if there is saved call data, set it to data.
    const paginated_data = splitArrayIntoChunks(data, pageSize);
    setData(paginated_data);
    setShowTable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanData = (phoneNumbers) => {
    let cleanPaginatedData = [];
    let errorNumbers = 0;
    let numbers = phoneNumbers.map((obj) => {
      obj =
        Object.keys(obj).reduce(
          (c, k) => ((c[k.toLowerCase()] = obj[k]), c),
          {},
        ) || obj;
      let number = obj["phone_number"] || null;

      if (number) {
        if (number.includes("(") || number.includes(")")) {
          errorNumbers += 1;
          console.log(number, " is error");
          return null;
        }

        if (number.includes("+")) return number;
        if (number.length === 10 && !number.includes("+")) return `+1${number}`;
        if (number.length === 11 && !number.includes("+")) return `+${number}`;
      }
    });

    if (errorNumbers > 0)
      toast.error(
        `${errorNumbers.toString()} numbers are invalid or incorrectly formatted.`,
      );
    numbers.forEach((doc) => {
      if (doc && doc !== null) {
        cleanPaginatedData.push({
          phone_number: doc,
        });
      }
    });

    return cleanPaginatedData;
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    try {
      const { data, paginated_data } = await parseCSV(file, pageSize);
      //const cleanPgData = cleanData(paginated_data);
      // if (!cleanPgData) return;

      setFile(file);
      setShowTable(true);
      setData(paginated_data);
    } catch (error) {
      console.error("An error occurred while parsing CSV:", error);
    }
  };
  return (
    <>
      <Wrapper>
        <FileInput
          label="Call Data"
          description={`Upload a CSV file. The first row should be the headers of
               the table, and your headers should not include any special
               characters other than hyphens (-) or underscores (_). The column
               containing phone numbers must have a header labeled as "phone_number".`}
          text={file ? file.name : "Upload CSV"}
          iconSrc={csvIcon}
          onChange={handleFileChange}
          exampleCsv={true}
        />
        {showTable && data.length > 0 && <Table data={data} />}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;
