export const getStartAndEndDate = (grouping, value) => {
  const validGroupings = ["hour", "day", "week", "month"];
  const errorObj = { startDate: null, endDate: null, error: null };

  // Validate grouping
  if (!validGroupings.includes(grouping)) {
    errorObj.error =
      "Invalid grouping. Must be 'hour', 'day', 'week', or 'month'.";
    return errorObj;
  }

  // Validate value
  if (typeof value !== "number" || value <= 0) {
    errorObj.error = "Value must be a positive number.";
    return errorObj;
  }

  const now = new Date();
  let startDate = new Date(now);

  switch (grouping) {
    case "hour":
      startDate.setHours(now.getHours() - value);
      break;
    case "day":
      startDate.setDate(now.getDate() - value);
      break;
    case "week":
      startDate.setDate(now.getDate() - value * 7);
      break;
    case "month":
      startDate.setMonth(now.getMonth() - value);
      break;
  }

  // Ensure start date does not exceed one month difference
  const oneMonthAgo = new Date(now);
  oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  if (startDate < oneMonthAgo) {
    startDate = oneMonthAgo;
  }

  // Convert to ISO timestamp in seconds
  const startTimestamp = Math.floor(startDate.getTime() / 1000);
  const endTimestamp = Math.floor(now.getTime() / 1000);

  return {
    startDate: startTimestamp,
    endDate: endTimestamp,
    error: null,
  };
};

export const convertTimestampToDateTime = (isoTimestamp) => {
  // Validate input
  if (typeof isoTimestamp !== "number" || isoTimestamp <= 0) {
    return {
      date: null,
      time: null,
      dateTimeStr: null,
      error: "Invalid timestamp. Must be a positive number.",
    };
  }

  const date = new Date(isoTimestamp * 1000); // Convert to milliseconds

  // Format date
  const month = date.getMonth() + 1; // getMonth() is zero-based
  const day = date.getDate();
  const year = date.getFullYear();
  const dateString = `${month}/${day}/${year}`;

  // Format time in 12-hour clock format with AM/PM
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const timeString = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${ampm}`;

  return {
    date: dateString,
    time: timeString,
    dateTimeStr: `${dateString} ${timeString}`,
    error: null,
  };
};

export const convertTimestampMMDDYYYY = (isoTimestamp) => {
  // Validate input
  if (typeof isoTimestamp !== "number" || isoTimestamp <= 0) {
    return {
      date: null,
      time: null,
      dateTimeStr: null,
      error: "Invalid timestamp. Must be a positive number.",
    };
  }

  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = new Date(isoTimestamp * 1000); // Convert to milliseconds
  const month = months[date.getMonth()]; // Get month abbreviation
  const day = String(date.getDate()).padStart(2, "0");
  const year = date.getFullYear();

  const formattedDate = `${month || "--"} ${day || "--"} ${year || "--"}`;

  return {
    date: formattedDate,
    time: date.toTimeString().slice(0, 8), // Extract HH:MM:SS from the time part
    dateTimeStr: `${formattedDate} ${date.toTimeString().slice(0, 8)}`,
    error: null,
  };
};
