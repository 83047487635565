import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

export default function Pagination({
  currentPage,
  totalPages,
  setCurrentPage,
  records,
}) {
  const totalPageAmt = totalPages === 0 ? 1 : totalPages;
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const handlePrevPage = () => {
    setCurrentPage(parseInt(currentPage) - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(parseInt(currentPage) + 1);
  };

  const handlePageChange = (e) => {
    const page = parseInt(e.target.value);
    setCurrentPage(page);
  };

  useEffect(() => {
    setSearchParams((params) => {
      params.set("currPage", currentPage);
      return params;
    });
  }, [currentPage, navigate, searchParams, setSearchParams]);

  return (
    <Container>
      <NextPrevButton onClick={handlePrevPage} disabled={currentPage === 1}>
        Prev
      </NextPrevButton>
      <p>Page</p>
      <PageInput
        type="number"
        value={currentPage}
        onChange={handlePageChange}
        min="1"
        max={totalPageAmt}
      />
      <p> of {totalPageAmt}</p>
      <NextPrevButton
        onClick={handleNextPage}
        disabled={currentPage === totalPageAmt}
      >
        Next
      </NextPrevButton>
      <p style={{ color: "#686767" }}>
        {records} record{records !== 1 && "s"}
      </p>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  column-gap: 6px;
`;

const NextPrevButton = styled.button`
  margin: 0 0.25rem;
  height: 30px;
  padding: 0px 12px;
  color: #686767;
  border-radius: 0.375rem;
  border: 1px solid #eeeeee;
  &:hover {
    background-color: #fbfbfa;
  }
  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

const PageInput = styled.input`
  width: 3rem;
  display: flex;
  text-align: center;
  border: 1px solid #eeeeee;
  border-radius: 0.375rem;
  height: 30px;
  padding: 0px 0px;
  outline: none;
`;
