import { AiOutlineLoading } from "react-icons/ai";

// Passes loading state from parent component into this.
export const Loading = ({ loading, color }) =>
  loading &&
  (color === "white" ? (
    <div className="flex flex-col h-full w-full">
      <AiOutlineLoading className="mx-auto text-white animate-spin my-auto text-2xl" />
    </div>
  ) : (
    <div className="flex flex-col h-full w-full">
      <AiOutlineLoading className="mx-auto my-auto text-gray-800 animate-spin my-auto text-4xl" />
    </div>
  ));
