import { useRef, useState, useEffect } from "react";

export default function WebhookActiveUrl({ user }) {
  const [updating, setUpdating] = useState(false);
  const [fetchingWebhookURL, setFetchingWebhookURL] = useState(false);
  const [message, setMessage] = useState({ type: "", text: "" });
  const [isInvalid, setIsInvalid] = useState(false);
  const [isUnsaved, setIsUnsaved] = useState(false);
  const inputRef = useRef();

  const [webhookDeliveryUrl, setWebhookDeliveryUrl] = useState("Loading ...");
  const [originalUrl, setOriginalUrl] = useState("");

  useEffect(() => {
    if (fetchingWebhookURL === false) {
      setFetchingWebhookURL(true);
      fetch("/api/user/getCurrentWebhook", {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("auth"),
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.webhook_url) {
            setWebhookDeliveryUrl(data.webhook_url);
            setOriginalUrl(data.webhook_url);
          } else {
            setWebhookDeliveryUrl("");
            setOriginalUrl("");
          }
        });
    }
  }, [fetchingWebhookURL]);

  const validateUrl = (url) => {
    if (url.length === 0) {
      return "URL cannot be empty";
    }
    if (url.length > 500) {
      return "URL must not exceed 500 characters";
    }
    if (!url.startsWith("https://")) {
      return "URL must start with https://";
    }
    try {
      const urlObject = new URL(url);
      const domainParts = urlObject.hostname.split(".");
      if (
        domainParts.length < 2 ||
        domainParts[domainParts.length - 1].length < 2
      ) {
        return "URL must have a valid domain with TLD";
      }
    } catch (error) {
      return "Invalid URL format";
    }
    return null;
  };

  const handleInputChange = (e) => {
    if (updating) return;
    const newUrl = e.target.value;
    setWebhookDeliveryUrl(newUrl);
    const validationError = validateUrl(newUrl);
    setIsInvalid(!!validationError);
    const newIsUnsaved = !validationError && newUrl !== originalUrl;
    setIsUnsaved(newIsUnsaved);

    if (validationError) {
      setMessage({ type: "error", text: validationError });
    } else if (newIsUnsaved) {
      setMessage({ type: "warning", text: "Remember to save your changes!" });
    } else {
      setMessage({ type: "", text: "" });
    }
  };

  const handleUpdateWebhook = async () => {
    setMessage({ type: "", text: "" });
    const validationError = validateUrl(webhookDeliveryUrl);
    if (validationError) {
      setMessage({ type: "error", text: validationError });
      setIsInvalid(true);
      setIsUnsaved(false);
      return;
    }

    setUpdating(true);
    try {
      const response = await fetch("/api/user/updateCurrentWebhook", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("auth"),
        },
        body: JSON.stringify({ webhook_url: webhookDeliveryUrl }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.error || "Failed to update webhook URL");
      }

      setOriginalUrl(webhookDeliveryUrl);
      setIsInvalid(false);
      setIsUnsaved(false);
      setMessage({ type: "success", text: "Webhook URL saved successfully" });
    } catch (error) {
      setMessage({ type: "error", text: `Request failed: ${error.message}` });
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold text-gray-900">
        Webhook Delivery URL
      </h2>
      <div
        className={`relative ${
          isInvalid
            ? "border-red-500"
            : isUnsaved
              ? "border-blue-500"
              : "border-gray-200"
        } border rounded-md focus-within:border-blue-500 transition-colors duration-200`}
      >
        <input
          ref={inputRef}
          value={webhookDeliveryUrl}
          onChange={handleInputChange}
          placeholder="Enter webhook URL"
          className="w-full px-3 py-2 text-sm text-gray-900 placeholder-gray-400 bg-transparent rounded-md focus:outline-none"
        />
      </div>
      {message.text && (
        <div
          className={`px-3 py-2 text-sm rounded-md ${
            message.type === "error"
              ? "bg-red-50 text-red-700"
              : message.type === "success"
                ? "bg-green-50 text-green-700"
                : message.type === "warning"
                  ? "bg-yellow-50 text-yellow-700"
                  : "bg-blue-50 text-blue-700"
          }`}
        >
          {message.text}
        </div>
      )}
      <div className="flex items-center justify-between">
        <p className="text-xs text-gray-500">
          New webhook events will be delivered to this address. Only HTTPS
          webhooks may be used.
        </p>
        <button
          onClick={handleUpdateWebhook}
          disabled={updating || !isUnsaved || isInvalid}
          className={`px-3 py-1.5 text-sm font-medium rounded-md transition-colors duration-200 ${
            updating || !isUnsaved || isInvalid
              ? "bg-gray-100 text-gray-400 cursor-not-allowed"
              : "bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          }`}
        >
          {updating ? "Updating..." : "Save Changes"}
        </button>
      </div>
    </div>
  );
}
