import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import Input from "components/core/Input";
import { useFormContext } from "react-hook-form";
import { getUserData } from "utils/funcs/browser/getUserData";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import { RxSpeakerLoud } from "react-icons/rx";
import { FaSearch, FaTimes } from "react-icons/fa";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LightTooltip from "./ToolTipDesc";

export default function Voice({ fieldName }) {
  const { setValue } = useFormContext();
  const [isVisible, setIsVisible] = useState(false);
  const [options, setOptions] = useState({ tab1: [], tab2: [] });
  const [activeTab, setActiveTab] = useState("tab1");
  const [isLoading, setIsLoading] = useState(false);
  const [latency, setLatency] = useState();
  const wrapperRef = useRef(null);
  const user = getUserData();
  const api_key = getApiKey();
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    if (user && user.voice_options) {
      const tab1Options = user.voice_options
        .filter((voice) => voice.tags?.includes("Bland Curated"))
        .map((voice) => ({ id: voice.id, name: voice.name }));

      const tab2Options = user.voice_options
        .filter((voice) => !voice.tags?.includes("Bland Curated"))
        .map((voice) => ({ id: voice.id, name: voice.name }));
      setOptions({ tab1: tab1Options, tab2: tab2Options });
    }
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [wrapperRef]);

  const fetchTTSAudio = async (voiceId) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://us.api.bland.ai/v1/voices/` + voiceId + "/sample",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: api_key,
          },
          body: JSON.stringify({
            text: "Hey this is Blandie, how can I help today?",
            response_type: "stream",
          }),
        },
      );

      let latency = null;

      if (response.ok) {
        latency = response.headers.get("x-latency");
        setLatency(latency);
      }
      const mediaSource = new MediaSource();
      const audio = new Audio();
      audio.src = URL.createObjectURL(mediaSource);

      await new Promise((resolve, reject) => {
        mediaSource.addEventListener(
          "sourceopen",
          () => {
            const sourceBuffer = mediaSource.addSourceBuffer("audio/mpeg");
            const reader = response.body.getReader();

            const read = () => {
              reader
                .read()
                .then(({ done, value }) => {
                  if (done) {
                    if (sourceBuffer.updating) {
                      sourceBuffer.addEventListener(
                        "updateend",
                        () => {
                          mediaSource.endOfStream();
                          resolve();
                        },
                        { once: true },
                      );
                    } else {
                      mediaSource.endOfStream();
                      resolve();
                    }
                    return;
                  }
                  // Check if the sourceBuffer is updating before appending the buffer
                  if (!sourceBuffer.updating) {
                    sourceBuffer.appendBuffer(value);
                    read();
                  } else {
                    // If the sourceBuffer is updating, wait for the update to finish before reading the next chunk
                    sourceBuffer.addEventListener("updateend", read, {
                      once: true,
                    });
                  }
                })
                .catch(reject);
            };

            read();
          },
          { once: true },
        );
      });
      audio.play();
      setIsLoading(false);

      return audio;
    } catch (error) {
      console.error("Failed to fetch TTS audio:", error);
    }
  };

  const handleFocus = useCallback(() => {
    setIsVisible(true);
  }, []);

  const handleSelect = useCallback(
    (value) => {
      setValue(fieldName, value);
      setIsVisible(false);
    },
    [fieldName, setValue],
  );

  const handleTabClick = useCallback((tab, event) => {
    event.preventDefault();
    event.stopPropagation();
    setActiveTab(tab);
  }, []);

  const handleSearch = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const clearSearch = useCallback(() => {
    setSearchTerm("");
  }, []);

  const filteredOptions = useMemo(() => {
    return options[activeTab].filter((option) =>
      option.name.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }, [options, activeTab, searchTerm]);

  const description = (
    <span>
      The voice of the AI agent to use. Accepts any form of voice ID, including
      custom voice clones and voice presets.
      <br />
      Default voices can be referenced directly by their name instead of an id.
      <br />
      Usage example: voice: "nat"
      <br />
      Bland Curated voices:
      <br />
      <b> - nat</b> <br />
      <b> - mason</b> <br />
      <b> - ryan</b> <br />
      <b> - adriana</b> <br />
      <b> - tina</b> <br />
      <b> - matt</b> <br />
      <b> - evelyn</b>
    </span>
  );

  return (
    <div ref={wrapperRef} className="relative">
      <Input
        autoComplete="off"
        label={
          <span>
            Voice
            <LightTooltip title={description} placement="right-start" arrow>
              <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
            </LightTooltip>
          </span>
        }
        fieldName={fieldName}
        onFocus={handleFocus}
        className="w-full"
        style={{ cursor: "pointer" }}
      />
      {isVisible && (
        <div className="absolute z-10 w-full bg-white border border-gray-100 rounded-md shadow-sm max-h-68 overflow-auto">
          <div className="flex border-b">
            <button
              className={`flex-1 py-2 ${
                activeTab === "tab1" ? "" : "bg-gray-100"
              }`}
              onClick={(e) => handleTabClick("tab1", e)}
            >
              Bland Curated + Cloned Voices
            </button>
            <button
              className={`flex-1 py-2 ${
                activeTab === "tab2" ? "" : "bg-gray-100"
              }`}
              onClick={(e) => handleTabClick("tab2", e)}
            >
              Public Voices
            </button>
          </div>
          <div className="relative p-2">
            <FaSearch className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search voices..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-full pl-10 pr-8 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {searchTerm && (
              <FaTimes
                className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 cursor-pointer"
                onClick={clearSearch}
              />
            )}
          </div>
          {filteredOptions.length === 0 ? (
            <p className="text-center py-4 text-gray-500">No voices found</p>
          ) : (
            <ul style={{ maxHeight: "300px", overflow: "scroll" }}>
              {filteredOptions.map((option, index) => (
                <React.Fragment key={option.id}>
                  <li
                    onClick={() => handleSelect(option.name)}
                    className="flex items-center justify-between w-full p-3 cursor-pointer hover:bg-gray-100"
                  >
                    <span>{option.name}</span>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        fetchTTSAudio(option.name);
                      }}
                      className="flex flex-col items-center cursor-pointer"
                    >
                      <RxSpeakerLoud className="text-indigo-600" size={14} />
                      <span className="text-xs text-indigo-600">Play</span>
                    </div>
                  </li>
                  {index < filteredOptions.length - 1 && <hr />}
                </React.Fragment>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
}
