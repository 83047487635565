// to day time month and year

export const toDtmy = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let hours = date.getHours();
  let minutes = date.getMinutes();

  // Convert hours to 12-hour format
  let period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Adjust 0 to 12 for 12-hour format

  minutes = minutes < 10 ? "0" + minutes : minutes;

  // Format the date string
  let formattedDate = `${month}/${day}/${year} (${hours}:${minutes} ${period})`;

  return formattedDate;
};
