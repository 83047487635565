import axios from "axios";

export const deleteInbound = async (api_key, encrypted_key, phone_number) => {
  const headers = {
    authorization: api_key,
    encrypted_key,
  };
  phone_number = encodeURIComponent(phone_number);
  try {
    const { data } = await axios.post(
      `${"https://us.api.bland.ai"}/v1/inbound/` + phone_number + "/delete",
      {},
      { headers },
    );
    if (data.status === "success") {
      return { success: true, data };
    } else {
      return { success: false, message: data.message };
    }
  } catch (error) {
    console.error("Error in deleteInbound:", error);
    return {
      success: false,
      message: error.response?.data?.message || error.message,
    };
  }
};
