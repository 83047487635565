import axios from "axios";

export const renameVoice = async (voice, apiKey, userId) => {
  try {
    const { data } = await axios.post(
      "https://cloning.bland.ai/rename",
      {
        voice_id: voice.voicea_id,
        name: voice.name,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: apiKey,
          user_id: userId,
        },
      },
    );
    return data;
  } catch (error) {
    console.error("Error renaming voice:", error);
  }
};
