import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BiCheckCircle, BiCopy } from "react-icons/bi";
import { vs2015 } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { KeyValuesToObj } from "utils/formatting/KeyValuesToObj";
import SyntaxHighlighter from "react-syntax-highlighter/dist/esm/default-highlight";
import styled from "styled-components";
import mixpanel from "mixpanel-browser";

export default function CodePreview({ apiKey, collapseCode }) {
  const [copied, setCopied] = useState(false);
  const [language, setLanguage] = useState("javascript");

  const { watch } = useFormContext();
  const {
    request_data,
    transfer_list,
    metadata,
    voice_settings,
    from,
    dynamic_data,
    analysis_schema,
    transfer_phone_number,
    ...formState
  } = watch();

  const requestData = KeyValuesToObj(request_data);
  const analysisSchema = KeyValuesToObj(analysis_schema);
  const transferList = KeyValuesToObj(transfer_list);
  const metaData = KeyValuesToObj(metadata);
  const voiceSettings = voice_settings?.length > 0 ? voice_settings : {};
  const fromNumber = from?.length <= 0 ? null : from;
  const transferPhone =
    transfer_phone_number?.length <= 0 ? null : transfer_phone_number;
  // const tools =
  //   formState?.calendly_url?.length > 0
  //     ? [
  //         {
  //           name: "Calendly",
  //           description: "Books an appointment on behalf of the caller.",
  //           url: formState?.calendly_url,
  //           speech: "Wait one moment while I book an appointment for you.",
  //           timezone: "America/New_York",
  //         },
  //       ]
  //     : [];

  const calendly =
    formState?.calendly_url?.length > 0
      ? {
          url: formState?.calendly_url,
          timezone: formState?.calendly_timezone,
        }
      : {};

  const data = JSON.stringify(
    {
      phone_number: `+${formState.phone_number}`,
      from: fromNumber,
      task: formState.task,
      model: formState.model,
      language: formState.language,
      voice: formState.voice,
      voice_settings: voiceSettings,
      pathway_id: formState.pathway_id,
      local_dialing: formState.local_dialing,
      max_duration: formState.max_duration,
      answered_by_enabled: formState.answered_by_enabled,
      wait_for_greeting: formState.wait_for_greeting,
      record: formState.record,
      amd: formState.amd || false,
      interruption_threshold: parseFloat(formState.interruption_threshold),
      voicemail_message: formState.voicemail_message,
      temperature: parseFloat(formState.temperature),
      transfer_phone_number: transferPhone,
      transfer_list: transferList,
      metadata: metaData,
      pronunciation_guide: formState.pronunciation_guide,
      start_time: formState.start_time,
      request_data: requestData,
      tools: [],
      dynamic_data: dynamic_data,
      analysis_preset: formState.analysis_preset || null,
      analysis_schema: analysisSchema,
      webhook: formState.webhook || null,
      calendly: calendly,
    },
    null,
    2,
  );

  const customSyntaxStyle = {
    width: "100%",
    background: "none",
    backgroundColor: "none",
    padding: "0px",
    margin: "0px",
    fontSize: "12px",
  };

  const codeStrings = {
    bash: `curl -X POST 'https://us.api.bland.ai/v1/calls'
-H 'Content-Type: application/json' 
-H 'Authorization: '${apiKey}'
-d '${data}'
      `,

    javascript: `// Headers  
const headers = {
   'Authorization': '${apiKey}'
};

// Data
const data = ${data}

// API request
await axios.post('https://us.api.bland.ai/v1/calls', data, {headers});
`,

    python: `# Headers
headers = {
   'Authorization': '${apiKey}'
}

# Data
data = ${data}

# API request 
requests.post('https://us.api.bland.ai/v1/calls', json=data, headers=headers)
      `,
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    }
  }, [copied]);

  return (
    <Wrapper collapseCode={collapseCode}>
      {!collapseCode && (
        <CodeWrapper collapseCode={collapseCode}>
          <CodeHeader>
            <CodeSelector
              label="Language"
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
            >
              <option value="javascript">JavaScript</option>
              <option value="python">Python</option>
              <option value="bash">cURL</option>
            </CodeSelector>
            <div
              className="text-white py-2 px-4 rounded flex items-center hover:bg-gray-800 cursor-pointer"
              onClick={() => {
                setCopied(true);
                mixpanel.track("Copied the code on the call page");
              }}
            >
              {!copied ? (
                <BiCopy className="mr-2" />
              ) : (
                <BiCheckCircle className="mr-2" />
              )}
              {!copied ? "Copy Code" : "Copied!"}
            </div>
          </CodeHeader>
          <SyntaxHighlighter
            wrapLongLines={true}
            language={language}
            style={vs2015}
            customStyle={customSyntaxStyle}
          >
            {codeStrings[language].toString()}
          </SyntaxHighlighter>
        </CodeWrapper>
      )}
    </Wrapper>
  );
}
const Wrapper = styled.div`
  position: relative;
  width: ${({ collapseCode }) => (collapseCode ? "0px" : "100%")};
  height: auto;
  min-width: ${({ collapseCode }) =>
    collapseCode ? "0px" : "500px"}; /* Adjusted */
  margin-left: auto; /* Added */
  ::selection {
    color: lightblue;
  }
`;

const CodeWrapper = styled.div`
  width: ${({ collapseCode }) =>
    collapseCode ? "0px" : "calc(100% - 30px)"}; /* Adjusted */
  position: sticky;
  top: 0px;
  border-radius: 12px;
  padding: ${({ collapseCode }) =>
    collapseCode ? "0px" : "20px 30px"}; /* Adjusted */
  background-color: #1e1e1e;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-left: ${({ collapseCode }) =>
    collapseCode ? "0px" : "20px"}; /* Adjusted */
`;

const CodeSelector = styled.select`
  background-color: transparent;
  color: white;
  outline: none;
  &:hover {
    cursor: pointer;
  }
`;

const CodeHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
