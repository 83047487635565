import Input from "components/core/Input";

export default function Pathway({ fieldName }) {
  return (
    <Input
      fieldName={fieldName}
      label="Pathway ID"
      alertType="warning"
      alertText="Heads up! Setting the pathway ID will set the prompt, first sentence, temperature and model to null."
      placeholder="Pathway ID"
    />
  );
}
