import Input from "components/core/Input";
import LightTooltip from "./ToolTipDesc";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"; // Import the question mark icon

export default function VoicemailSMS({ fieldName }) {
  const description = (
    <span>
      When the AI encounters a voicemail, it will send a SMS to the user with
      the message below, after the beep.
    </span>
  );
  return (
    <Input
      fieldName={fieldName}
      label={
        <span>
          Voicemail SMS Message
          <LightTooltip title={description} placement="right-start" arrow>
            <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
          </LightTooltip>
        </span>
      }
      type="text"
      placeholder="Hey there, I'm from BlandAI, and just gave you a ring. Let me know when you're free to chat!"
    />
  );
}
