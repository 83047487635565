import axios from "axios";

export const endActiveCall = async (callId, apiKey) => {
  try {
    const response = await axios.post(
      `https://us.api.bland.ai/end`,
      { call_id: callId },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: apiKey,
        },
      },
    );
    if (response.data) {
      console.log("Call ended successfully:", response.data);
      return { is_call_ended: true, data: response.data };
    } else {
      console.error("HTTP error ending call:", response.data);
      return { is_call_ended: false, error: "No data in response" };
    }
  } catch (error) {
    console.error("Error ending call in endActiveCall():", error);
    return { is_call_ended: false, error: error.message };
  }
};
