import { useState } from "react";
import VoiceCard from "./VoiceCard";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";

function BlandVoices({ voices }) {
  const [sampleText, setSampleText] = useState(
    "Hey there, this is Blandie. How are you doing today?",
  );
  const [tempSampleText, setTempSampleText] = useState(sampleText);

  const [showCustomizeModal, setShowCustomizeModal] = useState(false);

  const filteredVoices = voices.filter((voice) => voice.public === true);

  const handleSave = () => {
    setSampleText(tempSampleText);
    setShowCustomizeModal(false);
    toast.success("TTS Text updated! Play any sample to hear it!");
  };

  const handleCancel = () => {
    setTempSampleText(sampleText);
    setShowCustomizeModal(false);
  };

  return (
    <>
      <>
        <div className="flex items-center justify-between">
          <h1 className="">Community's Top Rated Voices</h1>
          <div className="flex items-center space-x-2">
            <button
              className="rounded-md bg-indigo-100 text-blue-800 px-6 py-4 hover:bg-indigo-300 hover:text-blue-900 shadow-lg transition ease-in-out duration-300"
              onClick={() => {
                setTempSampleText(sampleText);
                setShowCustomizeModal(true);
              }}
            >
              Customize TTS Text
            </button>
          </div>
        </div>

        {filteredVoices.length > 0 ? (
          <div className="pt-3 flex flex-row flex-wrap mx-auto">
            {[...filteredVoices].map((value, index) => (
              <VoiceCard
                key={index}
                data={value}
                text={sampleText}
                isPublicTab={true}
              />
            ))}
          </div>
        ) : (
          <p>Error: Couldn't get voice data.</p>
        )}
      </>
      <Dialog
        open={showCustomizeModal}
        onClose={() => setShowCustomizeModal(false)}
      >
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 z-[5001]" />
        <Dialog.Panel>
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[5002] border border-gray-300 bg-white p-8 rounded-lg shadow-lg w-[500px] space-y-4">
            <Dialog.Title className="font-semibold text-gray-900">
              Customize TTS Sample Text
            </Dialog.Title>
            <Dialog.Description className="text-lg text-gray-600">
              Enter the text you want to hear from our collection of voices!
            </Dialog.Description>
            <textarea
              type="text"
              value={tempSampleText}
              onChange={(e) => setTempSampleText(e.target.value)}
              className="w-full h-40 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 resize-y"
            />
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCancel}
                className="px-4 py-2 font-medium text-gray-600 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                className="px-4 py-2 font-medium text-white bg-indigo-500 border border-transparent rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>
  );
}

export default BlandVoices;
