import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Label from "./Label";
import { Zap } from "lucide-react";
import { Badge, Flex } from "@radix-ui/themes";

export const ToolsDropdown = ({
  fieldName,
  api_key,
  return_type = "value",
}) => {
  const [tools, setTools] = useState(null);
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const fetchAllTools = async () => {
    try {
      const response = await fetch(`https://us.api.bland.ai/v1/tools`, {
        method: "GET",
        headers: {
          authorization: `${api_key}`,
          accept: "application/json",
        },
      });

      if (response.ok && response.status === 200) {
        const data = await response.json();
        const toolsFromData = data?.tools;
        if (toolsFromData) {
          setTools(
            toolsFromData?.map((tool) => ({
              id: tool.tool_id,
              name: tool?.tool?.name,
            })),
          );
        }
      }
    } catch (error) {
      console.error("Error fetching tools:", error);
      return [];
    }
  };

  useEffect(() => {
    fetchAllTools();
  }, []);

  return (
    <div>
      <Label>Tools</Label>
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, value } }) => (
          <div className="w-full">
            <Select
              onCloseAutoFocus={(e) => e.preventDefault()}
              className="text-md"
              style={{ outline: "none" }}
              value={value || ""}
              onValueChange={onChange}
            >
              <SelectTrigger
                style={{ fontSize: 12 }}
                className="w-full p-6 pt-7 pb-7 ml-0 pl-3 h-[35px] text-md"
              >
                <SelectValue
                  autoFocus={false}
                  style={{ fontSize: 14 }}
                  placeholder="Select a tool"
                />
              </SelectTrigger>

              <SelectContent>
                {tools?.map((tool) => (
                  <SelectItem className="text-md" value={tool.id}>
                    {tool.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        )}
      />
    </div>
  );
};
