import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import Label from "components/core/Label";

export function BatchDropdown({ loading, label, currBatch, options }) {
  const [open, setOpen] = useState(false);
  const [currOption, setCurrOption] = useState();
  const charLimit = 39;
  const navigate = useNavigate();

  useEffect(() => {
    !loading && setCurrOption(currBatch?.label);
  }, [currBatch, loading]);

  const handleSelect = (option, e) => {
    e.stopPropagation();
    setOpen(false);
    console.log(option?.label);
    setCurrOption(option?.label);
    navigate(`?page=batch-call&batch-id=${option?.batch_id}`);
  };

  return (
    <Wrapper>
      {loading ? null : (
        <>
          {label && <Label>{label}</Label>}
          <DropdownBar onClick={() => setOpen(!open)}>
            <>
              <p>
                {options?.length > 0
                  ? currOption?.length > charLimit
                    ? `${currOption.substring(0, charLimit)}...`
                    : currOption?.length > 0
                      ? currOption
                      : "Untitled Batch"
                  : "No batches yet"}
              </p>
              {open && options?.length > 0 && (
                <Menu>
                  {options.map((option, index) => (
                    <Option
                      key={index}
                      onClick={(e) => handleSelect(option, e)}
                    >
                      <Batch>
                        {option.label?.length > charLimit
                          ? `${option.label.substring(0, charLimit)}...`
                          : option.label?.length > 0
                            ? `${option.label}`
                            : "Untitled Batch"}
                      </Batch>
                      <Campaign>
                        {option.campaign_id
                          ? `Campaign: ${option.campaign_id}`
                          : "Untitled Campaign"}
                      </Campaign>
                    </Option>
                  ))}
                </Menu>
              )}
            </>
          </DropdownBar>
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;
`;

const DropdownBar = styled.div`
  text-align: left;
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  width: 300px;
  padding: 4px 12px;
  cursor: pointer;
  height: 45px !important;
  position: relative;
  display: flex;
  justify-content: start;
  align-items: center;
  &:focus {
    outline: none;
    border: 1px solid #eeeeee;
  }
  &::placeholder {
    color: #a8a8a1;
  }
`;

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
  background-color: white;
  position: absolute;
  top: 50px;
  width: 300px;
  height: auto;
  max-height: 300px;
  overflow-y: scroll;
  left: 0;
`;

const Option = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  padding: 6px 12px;
  border-radius: 3px;
  height: auto;
  padding: 9px 12px;
  cursor: pointer;
  white-space: nowrap;
  &:hover {
    background-color: #fbfbfa;
  }
`;

const Batch = styled.p`
  user-select: none;
`;

const Campaign = styled.p`
  font-size: 1.2rem;
  user-select: none;
`;
