import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { generateEncryptedKey } from "utils/funcs/byot/generateEncryptedKey";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import { toast } from "react-toastify";
import AccountSid from "../../../../Parameters/AccountSid";
import AuthToken from "../../../../Parameters/AuthToken";
import "react-toastify/dist/ReactToastify.css";

const GenerateKey = () => {
  const [submitting, setSubmitting] = useState(false);
  const [encryptedKey, setEncryptedKey] = useState("");
  const apiKey = getApiKey();
  const methods = useForm();

  const onSubmit = async (formData) => {
    setSubmitting(true);
    const { account_sid, auth_token } = formData;
    try {
      const response = await generateEncryptedKey(
        apiKey,
        account_sid,
        auth_token,
      );
      if (response.status === "success") {
        toast.success("Encrypted key generated successfully!");
        setEncryptedKey(response.encrypted_key);
      } else {
        toast.error("Failed to generate key");
      }
    } catch (error) {
      console.error(error);
      toast.error("An error occurred while generating the key");
    }
    setSubmitting(false);
  };

  useEffect(() => {
    methods.setValue("encrypted_key", encryptedKey);
  }, [encryptedKey, methods]);

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-6">Generate Encrypted Key</h2>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} className="space-y-6">
          {!encryptedKey ? (
            <>
              <p className="text-gray-600 mb-4">
                Enter your Twilio "Account SID" and "Auth Token." We'll generate
                an Encrypted Key that you can use in your API requests.
              </p>
              <div className="space-y-4">
                <AccountSid fieldName="account_sid" />
                <div className="text-xs text-gray-500">
                  Hint: Account SID typically starts with "AC" and is 34
                  characters long.
                </div>
                <AuthToken fieldName="auth_token" />
                <div className="text-xs text-gray-500">
                  Hint: Auth Token is typically 32 characters long.
                </div>
              </div>
              <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mb-4">
                <p className="text-sm text-blue-700">
                  The Encrypted Key will only be displayed to you for security
                  and cannot be retrieved later without regenerating.
                </p>
              </div>
              <button
                type="submit"
                disabled={submitting}
                className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
                  submitting ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                {submitting ? "Generating..." : "Generate Key"}
              </button>
            </>
          ) : (
            <>
              <div>
                <label
                  htmlFor="encrypted_key"
                  className="block text-sm font-medium text-gray-700"
                >
                  Encrypted Key
                </label>
                <input
                  type="text"
                  id="encrypted_key"
                  {...methods.register("encrypted_key")}
                  readOnly
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <p className="text-sm text-yellow-700">
                  Save this! The Encrypted Key will only be displayed to you for
                  security and cannot be retrieved later without regenerating.
                </p>
              </div>
            </>
          )}
        </form>
      </FormProvider>
    </div>
  );
};

export default GenerateKey;
