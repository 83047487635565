import Switch from "components/core/Switch";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styled } from "@mui/material/styles";
import LightTooltip from "./ToolTipDesc";

export default function WaitGreeting({ fieldName }) {
  const longText = (
    <span>
      By default, the agent starts talking as soon as the call connects. <br />
      <br />
      When <strong>wait_for_greeting</strong> is set to <strong>true</strong>,
      the agent will wait for the call recipient to speak first before
      responding.
    </span>
  );
  return (
    <Tooltip>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Switch sx={{ m: 5 }} fieldName={fieldName} label="Wait For Greeting" />
        <LightTooltip title={longText} placement="right-start" arrow>
          <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
        </LightTooltip>
      </div>
    </Tooltip>
  );
}
