export function convertToMinutesAndSeconds(fraction) {
  if (fraction == null) return "0m 0s";
  const totalSeconds = Math.round(fraction * 60);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  return `${String(minutes).padStart(1, "0")}m ${String(seconds).padStart(
    2,
    "0",
  )}s`;
}
