import styled from "styled-components";

export default function SectionHeader({ children }) {
  return <Header>{children}</Header>;
}

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
