import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { useReactFlow } from "reactflow";

const GlobalNodeOptions = ({
  isGlobal,
  setIsGlobal,
  globalPathwayLabel,
  setGlobalPathwayLabel,
  globalPathwayDescription,
  setGlobalPathwayDescription,
  isForwardingEnabled,
  setIsForwardingEnabled,
  forwardingNode,
  setForwardingNode,
  enableGlobalAutoReturn,
  setEnableGlobalAutoReturn,
  prevNodePathwayLabel,
  setPrevNodePathwayLabel,
  prevNodePathwayDescription,
  setPrevNodePathwayDescription,
  isChildOfGlobalNode,
  enableReturnToPrevBeforeGlobal,
  setEnableReturnToPrevBeforeGlobal,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [usePrevNodePathway, setUsePrevNodePathway] = useState(
    Boolean(prevNodePathwayLabel) || false,
  );
  const { getNodes } = useReactFlow();
  const nodes = getNodes();

  useEffect(() => {
    setIsOpen(isGlobal);
  }, [isGlobal]);

  return (
    <div className="border p-4 rounded-md shadow-sm">
        {isChildOfGlobalNode ? (
        <>
          <div className="flex items-center justify-between">
            <h3 className="text-xl">Global Node Child Settings <span className="text-sm text-gray-500
            ">{
                enableReturnToPrevBeforeGlobal ? "(Return to Previous Node Enabled)" : ""
                }
            </span></h3>
            <Switch
              checked={enableReturnToPrevBeforeGlobal}
              onChange={setEnableReturnToPrevBeforeGlobal}
              className={`${
                enableReturnToPrevBeforeGlobal ? "bg-indigo-600" : "bg-gray-200"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span className="sr-only">Enable Return to Previous Node</span>
              <span
                className={`${
                  enableReturnToPrevBeforeGlobal ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
          </div>
          <p className="text-lg text-gray-600 mt-1">
            Enable to go back to the previous node before the global node, once the dialogue from this node is generated.
          </p>
        </>
      ) : (
                <>
      <div className="flex items-center justify-between">
        <h3 className="text-xl">Global Node</h3>
        <Switch
          checked={isGlobal}
          onChange={setIsGlobal}
          className={`${
            isGlobal ? "bg-indigo-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">Global Node</span>
          <span
            className={`${
              isGlobal ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>
      <p className="text-lg text-gray-600 mt-1">
        Toggle this if you want to make this node accessible from any other
        node. Global nodes have a hidden pathway that links all other nodes, to
        this node.
      </p>
      {isOpen && (
        <div className="mt-4">
          <p className="text-gray-500 text-lg"></p>
          <div className="space-y-2 mt-2">
            <label className="block mb-1 font-medium">
              Global Node Pathway Label (Condition to path to this node)
            </label>
            <input
              value={globalPathwayLabel}
              onChange={(e) => setGlobalPathwayLabel(e.target.value)}
              type="text"
              className="border border-gray-300 p-2 rounded-md w-full"
              placeholder="Pathway Label e.g. 'User wants to speak to an agent'"
            />
          </div>

          <label className="block mb-1 mt-4 font-medium">
            Global Node Pathway Description (Additional Description for when to
            choose this pathway)
          </label>
          <textarea
            value={globalPathwayDescription}
            onChange={(e) => setGlobalPathwayDescription(e.target.value)}
            type="text"
            className="border border-gray-300 p-2 rounded-md w-full min-h-[80px]"
            placeholder="Use this to provide more context for when to choose this pathway. May include examples of user input that should trigger this pathway. e.g. 'Only choose this pathway if the user explicitly asks to speak to an agent, or if the user is frustrated with the bot's responses. E.g. 'I want to speak to a human' or 'I want to talk to an agent."
          />

          <div className="mt-8">
            <div className="flex items-center space-x-4 mt-2">
              <Switch
                checked={enableGlobalAutoReturn}
                onChange={(checked) => {
                  setEnableGlobalAutoReturn(checked);
                  if (checked) {
                    setUsePrevNodePathway(false);
                  }
                }}
                className={`${
                  enableGlobalAutoReturn ? "bg-indigo-600" : "bg-gray-200"
                } relative inline-flex h-6 w-[56px] items-center rounded-full 2xl:w-[44px]`}
              >
                <span className="sr-only">
                  Automatically Go Back to Previous Node
                </span>
                <span
                  className={`${
                    enableGlobalAutoReturn ? "translate-x-5" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <label className="text-xl font-medium">
                Automatically Go Back to Previous Node{" "}
                <span className="text-gray-500 text-[11px] italics">
                  (After the dialogue from this node is generated, automatically
                  go back to the previous node that triggered this global node.
                  Toggling this off will unlock the circle at the bottom of the
                  node, allowing you to create pathway labels from this node to
                  other nodes, as well as the previous node it came from.)
                </span>
              </label>
            </div>

            {!enableGlobalAutoReturn && (
              <div className="flex items-center space-x-4 mt-2">
                <Switch
                  checked={usePrevNodePathway}
                  onChange={setUsePrevNodePathway}
                  className={`${
                    usePrevNodePathway ? "bg-indigo-600" : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span className="sr-only">
                    Create Pathway Label to Previous Node
                  </span>
                  <span
                    className={`${
                      usePrevNodePathway ? "translate-x-6" : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
                <label className="text-xl font-medium">
                  Create Pathway Label to Previous Node{" "}
                  <span className="text-gray-500 text-[11px] italics">
                    (This will allow you to create a pathway label from this
                    node to the previous node that triggered this global node.)
                  </span>
                </label>
              </div>
            )}

            {usePrevNodePathway && (
              <div className="ml-8">
                <label className="block mb-1 mt-4 font-medium text-[12px]">
                  Previous Node Pathway Label (Condition to path to the previous
                  node, before coming to this global node)
                </label>
                <input
                  value={prevNodePathwayLabel}
                  onChange={(e) => setPrevNodePathwayLabel(e.target.value)}
                  type="text"
                  className="border border-gray-300 p-2 rounded-md w-full"
                  placeholder="Pathway Label e.g. 'User wants to speak to an agent'"
                />

                <label className="block mb-1 mt-4 font-medium text-[12px]">
                  Previous Node Pathway Description (Additional Description for
                  when to choose this pathway)
                </label>
                <textarea
                  value={prevNodePathwayDescription}
                  onChange={(e) =>
                    setPrevNodePathwayDescription(e.target.value)
                  }
                  type="text"
                  className="border border-gray-300 p-2 rounded-md w-full min-h-[80px]"
                  placeholder="Use this to provide more context for when to choose this pathway. May include examples of user input that should trigger this pathway. e.g. 'Only choose this pathway if the user explicitly asks to speak to an agent, or if the user is frustrated with the bot's responses. E.g. 'I want to speak to a human' or 'I want"
                />
              </div>
            )}

            <div className="flex items-center space-x-4 mt-2">
              <Switch
                checked={isForwardingEnabled}
                onChange={setIsForwardingEnabled}
                className={`${
                  isForwardingEnabled ? "bg-indigo-600" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Enable Forwarding</span>
                <span
                  className={`${
                    isForwardingEnabled ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <label className="text-xl font-medium">
                Redirect to another node{" "}
                <span className="text-gray-500 text-[11px] italics">
                  (Redirect to another node, which will generate the response.
                  This ignores the prompt written in this node.)
                </span>
              </label>
            </div>

            {isForwardingEnabled && (
              <div className="mt-4">
                <label className="block mb-1 font-medium">
                  Redirect to Node
                </label>
                <select
                  value={forwardingNode}
                  onChange={(e) => setForwardingNode(e.target.value)}
                  className="border border-gray-300 p-2 rounded-md w-full"
                >
                  <option value="">Select Node</option>
                  {nodes.map((node) => (
                    <option key={node.id} value={node.id}>
                      {node.data.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
      )}
      </>
            )}
    </div>
  );
};

export default GlobalNodeOptions;
