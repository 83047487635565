import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { parseCSV } from "utils/tools/parseCsv";
import FileInput from "components/core/FileInput";
import csvIcon from "../../../assets/icons/csv-import.svg";
import styled from "styled-components";
import Table from "components/core/Table";
import { splitArrayIntoChunks } from "utils/tools/splitArrayIntoChunks";
import { toast } from "react-toastify";
import EditableSpreadsheet from "./EditableSpreadsheet";

export default function CallData({ fieldName }) {
  const [file, setFile] = useState(null);
  const [data, setData] = useState();
  const [showTable, setShowTable] = useState(false);
  const { watch, setValue } = useFormContext();
  const savedCallData = watch("call_data");
  const pageSize = 50;

  useEffect(() => {
    const data = savedCallData?.map((row, index) => {
      return {
        ...Object.fromEntries(
          Object.entries(row).filter(
            ([key, _]) => key !== "" && key !== null && key !== undefined,
          ),
        ),
      };
    });

    const paginated_data = splitArrayIntoChunks(data, pageSize);
    setData(paginated_data);
    setValue(fieldName, data);
    setShowTable(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, setValue]);

  // const cleanData = (phoneNumbers) => {
  //    let cleanPaginatedData = [];
  //    let numbers = phoneNumbers.map((obj) => {
  //       obj = Object.keys(obj).reduce((c, k) => ((c[k.toLowerCase()] = obj[k]), c), {}) || obj;
  //       let number = obj["phone_number"] || null;

  //       if (number) {
  //          if (number.includes("+")) return number;
  //          if ((number.length === 10) && !number.includes("+")) return `+1${number}`
  //          if ((number.length === 11) && !number.includes("+")) return `+${number}`
  //       };

  //       return number;
  //    });

  //    numbers.forEach(doc => {
  //       if (doc) {
  //          cleanPaginatedData.push({
  //             phone_number: doc
  //          })
  //       };
  //    });

  //    return cleanPaginatedData;
  // };

  // const fileParser = async (data) => {
  //    const data = [];
  //    for (const element of data) {
  //       const keys = Object.keys(element);
  //       const values = Object.values(element);

  //    }
  // }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFile(file);
    setShowTable(true);

    try {
      const { data, paginated_data } = await parseCSV(file, pageSize);
      console.log({
        paginated_data,
        data,
      });

      console.log({ paginated_data });
      setData(paginated_data?.map((row, index) => ({ id: index, ...row })));
      setValue(fieldName, paginated_data);
    } catch (error) {
      console.error("An error occurred while parsing CSV:", error);
    }
  };

  return (
    <>
      <Wrapper>
        <FileInput
          label="Call Data"
          description={`Upload a CSV file. The first row should be the headers of
               the table, and your headers should not include any special
               characters other than hyphens (-) or underscores (_). The column
               containing phone numbers must have a header labeled as "phone_number".`}
          text={file ? file.name : "Upload CSV"}
          iconSrc={csvIcon}
          fieldName={fieldName}
          onChange={handleFileChange}
          exampleCsv={true}
        />

        {data?.length > 0 && (
          <EditableSpreadsheet data={data} setData={setData} />
        )}

        {/* {showTable && data.length > 0 && <Table data={data} fieldName={fieldName} />} */}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;
