import styled from "styled-components";

const Label = ({ children }) => {
  return (
    <StyledLabel className="font-medium text-[#676775]">{children}</StyledLabel>
  );
};

export default Label;

const StyledLabel = styled.label`
  font-size: 1.2rem;
`;
