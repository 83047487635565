import {
  Badge,
  Box,
  Button,
  DropdownMenu,
  Flex,
  Switch,
  Table,
  Text,
} from "@radix-ui/themes";
import { PageTitle } from "components/core/PageTitle";
import { useCallback, useState, useRef, useEffect } from "react";
import * as Icons from "@radix-ui/react-icons";
import { getUserData } from "utils/funcs/browser/getUserData";
import { useNavigate } from "react-router-dom";

const LEVEL_FILTERS = [
  { label: "Info", value: "info" },
  { label: "Performance", value: "performance" },
  { label: "Error", value: "error" },
];

const CATEGORY_FILTERS = [
  { label: "Queue", value: "queue" },
  { label: "Call", value: "call" },
];

export const EventStreamTable = ({ callId = null }) => {
  const { user, api_key } = getUserData();
  const ws = useRef(null);
  const [events, setEvents] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState(null); // Filter by category
  const [levelFilter, setLevelFilter] = useState(null); // Filter by level
  const [listening, setListening] = useState(true);
  const [callIdHook, setCallIdHook] = useState(callId);
  // const [wsUri, setWsUri] = useState(`wss://temp-repo-deployment-production.up.railway.app?user_id=${user?.id}`);
  const [wsUri, setWsUri] = useState(
    `wss://temp-repo-deployment-production.up.railway.app/api/realtime/logs?user_id=${user?.id}&api_key=${api_key}`,
  );
  const navigate = useNavigate();
  // let wsUri = `ws://localhost:8000?user_id=${user?.id}`;

  const logStateChange = useCallback(() => {
    console.log("WebSocket readyState:", ws.current.readyState);
  }, []);

  function formatTimestamp(timestamp) {
    const dateObj = new Date(timestamp); // Parse timestamp into Date object
    // Format timestamp as needed
    return `${dateObj.toLocaleDateString()} ${dateObj.toLocaleTimeString()}`;
  }

  useEffect(() => {
    if (listening) {
      console.log("Initializing WebSocket connection...");
      //ws.current = new WebSocket('wss://temp-repo-deployment-production.up.railway.app');
      //let wsUri = `ws://localhost:8000?user_id=${user?.id}`;
      if (callIdHook) {
        //wsUri = `${wsUri}&call_id=${callIdHook}`
        ws.current = new WebSocket(`${wsUri}&call_id=${callIdHook}`);
      } else {
        ws.current = new WebSocket(wsUri);
      }

      // WebSocket event listeners
      ws.current.onopen = () => {
        console.log("WebSocket is open now.");
      };

      ws.current.onmessage = (event) => {
        try {
          let json = JSON.parse(event.data);
          json = {
            category: json?.payload?.new?.category,
            level: json?.payload?.new?.log_level,
            call_id: json?.payload?.new?.call_id,
            message: json?.payload?.new?.message,
            timestamp: json?.payload?.new?.created_at,
          };
          // Only update state if the event matches the current filters
          // if (
          //     (!categoryFilter || json.category === categoryFilter) &&
          //     (!levelFilter || json.level === levelFilter)
          // ) {
          //     setEvents(prevEvents => {
          //         const newEvents = [json, ...prevEvents];
          //         return newEvents.slice(0, 250); // Limit to 250 events
          //     });
          // }

          setEvents((prevEvents) => {
            const newEvents = [json, ...prevEvents];
            return newEvents.slice(0, 250); // Limit to 250 events
          });
        } catch (error) {
          console.error("Error parsing JSON:", error);
        }
      };

      ws.current.onerror = (error) => {
        console.error("WebSocket error observed:", error);
      };

      ws.current.onclose = (event) => {
        console.log("WebSocket is closed now.", event);
      };

      ws.current.addEventListener("open", logStateChange);
      ws.current.addEventListener("close", logStateChange);
      ws.current.addEventListener("error", logStateChange);

      // Cleanup WebSocket on component unmount
      return () => {
        if (ws.current) {
          ws.current.removeEventListener("open", logStateChange);
          ws.current.removeEventListener("close", logStateChange);
          ws.current.removeEventListener("error", logStateChange);
          ws.current.close();
        }
      };
    }
  }, [logStateChange, categoryFilter, levelFilter, listening, callIdHook]);

  const toggleListening = () => {
    setListening((prevListening) => !prevListening);
  };

  // Filtered events based on category and level filters
  const filteredEvents = events.filter((event) => {
    if (categoryFilter && event.category !== categoryFilter) return false;
    if (levelFilter && event.level !== levelFilter) return false;
    return true;
  });

  return (
    <Box style={{ maxHeight: "100%", overflowX: "scroll" }}>
      <Flex gap="2" align="center" style={{ marginTop: 7.5 }}>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button
              variant={categoryFilter ? "surface" : "outline"}
              color={"iris"}
              size="1"
              style={{ cursor: "pointer" }}
            >
              {!categoryFilter
                ? "Filter by Category"
                : "Category: " +
                  CATEGORY_FILTERS.find((doc) => doc.value === categoryFilter)
                    .label}
              <DropdownMenu.TriggerIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            size="1"
            highContrast={true}
            style={{ width: "100%" }}
          >
            {CATEGORY_FILTERS.map((group, index) => (
              <DropdownMenu.Item
                key={index}
                onClick={() => {
                  if (categoryFilter === group.value) {
                    return setCategoryFilter(null);
                  }
                  setCategoryFilter(group.value);
                }}
                style={{
                  cursor: "pointer",
                  background: categoryFilter === group.value && "#eeeeee",
                }}
              >
                {group.label}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <Button
              variant={levelFilter ? "surface" : "outline"}
              color={"iris"}
              size="1"
              style={{ cursor: "pointer" }}
            >
              {!levelFilter
                ? "Filter by Status"
                : "Status: " +
                  LEVEL_FILTERS.find((doc) => doc.value === levelFilter).label}
              <DropdownMenu.TriggerIcon />
            </Button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            size="1"
            highContrast={true}
            style={{ width: "100%" }}
          >
            {LEVEL_FILTERS.map((group, index) => (
              <DropdownMenu.Item
                key={index}
                onClick={() => {
                  if (levelFilter === group.value) {
                    return setLevelFilter(null);
                  }
                  setLevelFilter(group.value);
                }}
                style={{
                  cursor: "pointer",
                  background: levelFilter === group.value && "#eeeeee",
                }}
              >
                {group.label}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
        {callIdHook && (
          <Badge>
            Call ID: {callIdHook}
            <Icons.CrossCircledIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                const currentUrlQp = new URLSearchParams(
                  window.location.search,
                );
                currentUrlQp.delete("call_id", currentUrlQp.get("call_id"));
                setCallIdHook(null);
                navigate({ search: currentUrlQp.toString() });

                // ws.current.close();
                // ws.current = new WebSocket(wsUri);
              }}
              height={12}
              width={12}
              strokeWidth={2}
            />
          </Badge>
        )}
        {(categoryFilter || levelFilter) && (
          <Button
            onClick={() => {
              setLevelFilter(null);
              setCategoryFilter(null);
            }}
            size={"1"}
            variant="solid"
            color="iris"
            style={{ cursor: "pointer" }}
          >
            Clear Filters
          </Button>
        )}

        <div style={{ marginLeft: "auto" }}>
          <Text as="label" size="2">
            <Flex gap="2">
              Listening for Logs{" "}
              <Switch
                size="1"
                defaultChecked={true}
                checked={listening}
                onClick={toggleListening}
                style={{ cursor: "pointer" }}
              />
            </Flex>
          </Text>
        </div>
      </Flex>
      <Table.Root
        size="2"
        variant="surface"
        layout="fixed"
        style={{ marginTop: 25, borderRadius: 1 }}
      >
        <Table.Header style={{ borderRadius: 1 }}>
          <Table.Row style={{ borderRadius: 1 }}>
            <Table.ColumnHeaderCell>
              <PageTitle style={{ fontSize: 12, fontWeight: "500" }}>
                Id
              </PageTitle>
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>
              <PageTitle style={{ fontSize: 12, fontWeight: "500" }}>
                Category
              </PageTitle>
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>
              <PageTitle style={{ fontSize: 12, fontWeight: "500" }}>
                Status
              </PageTitle>
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>
              <PageTitle style={{ fontSize: 12, fontWeight: "500" }}>
                Message
              </PageTitle>
            </Table.ColumnHeaderCell>
            <Table.ColumnHeaderCell>
              <PageTitle style={{ fontSize: 12, fontWeight: "500" }}>
                Timestamp
              </PageTitle>
            </Table.ColumnHeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {filteredEvents.length > 0 &&
            filteredEvents.map((event, index) => (
              <Table.Row key={index}>
                <Table.RowHeaderCell
                  style={{ fontSize: 12, fontWeight: "450" }}
                >
                  {event.call_id}
                </Table.RowHeaderCell>
                <Table.Cell>
                  {event?.category === "call" && (
                    <Badge size={"1"} variant="solid" color="iris">
                      {event?.category?.toUpperCase()}
                    </Badge>
                  )}
                  {event?.category === "queue" && (
                    <Badge size={"1"} variant="soft" color="iris">
                      {event?.category?.toUpperCase()}
                    </Badge>
                  )}
                </Table.Cell>
                <Table.Cell>
                  <Badge
                    variant="surface"
                    color={getColorForLevel(event.level)}
                  >
                    {event.level}
                  </Badge>
                </Table.Cell>
                <Table.Cell style={{ fontSize: 12, fontWeight: "450" }}>
                  {event.message}
                </Table.Cell>
                <Table.Cell style={{ fontSize: 12, fontWeight: "450" }}>
                  {formatTimestamp(event.timestamp)}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table.Root>
    </Box>
  );
};

// Helper function to get color based on log level
function getColorForLevel(level) {
  switch (level) {
    case "info":
      return "yellow";
    case "error":
      return "red";
    case "performance":
      return "green";
    default:
      return "defaultColor"; // Define default color or handle undefined cases
  }
}
