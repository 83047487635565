import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { PiSpinnerGapLight } from "react-icons/pi";

const PublishConfirmModal = ({ isOpen, onClose, onConfirm, isPublishing }) => {
  const [versionName, setVersionName] = useState("");

  const handleConfirm = () => {
    const finalVersionName =
      versionName.trim() ||
      `Published Version - ${new Date().toLocaleString()}`;
    onConfirm(finalVersionName);
  };

  return (
    <Transition appear show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Publish Version
              </Dialog.Title>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Publishing will update this as the new version, and the
                  previous published version will be saved as a separate
                  version, which you can use to update later if needed.
                </p>
              </div>

              <div className="mt-4">
                <input
                  type="text"
                  className="w-full p-2 border rounded-md"
                  placeholder="Enter version name (Optional)"
                  value={versionName}
                  onChange={(e) => setVersionName(e.target.value)}
                />
              </div>

              <div className="mt-4 flex justify-end space-x-4">
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 border border-transparent rounded-md hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
                  onClick={handleConfirm}
                  disabled={isPublishing}
                >
                  {isPublishing ? (
                    <PiSpinnerGapLight className="animate-spin w-5 h-5" />
                  ) : (
                    "Confirm Publish"
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PublishConfirmModal;
