import React from "react";
import { Copy } from "lucide-react";

const TemplatePathwayCard = ({
  id,
  name,
  description,
  setIsTemplateDuplicate,
  setCurrId,
  setOpen,
}) => {
  const handleDuplicate = (e) => {
    e.stopPropagation();
    setCurrId(id);
    setIsTemplateDuplicate(true);
    setOpen(true);
  };

  return (
    <div className="group relative bg-white border-l-4 border-l-indigo-500 border-y border-r border-gray-200 rounded-md p-6 hover:shadow-md transition-all duration-200 cursor-pointer overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-indigo-50 to-purple-50 opacity-0 group-hover:opacity-100 transition-opacity duration-200" />

      <div className="relative z-10">
        <h3 className="text-lg font-semibold text-gray-900 mb-2 group-hover:text-indigo-600 transition-colors duration-200">
          {name}
        </h3>

        <p className="text-sm text-gray-500 mb-6 line-clamp-2">{description}</p>

        <button
          className="mt-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors duration-200"
          onClick={handleDuplicate}
        >
          <Copy className="w-4 h-4 mr-2" />
          Duplicate Template
        </button>
      </div>
    </div>
  );
};

export default TemplatePathwayCard;
