// HallucinationContent.js
import React from "react";
import { Button } from "@/components/ui/button";
import { Check, Plus } from "lucide-react";

const HallucinationContent = ({
  message,
  alternativeResponses,
  selectedResponseIndex,
  setSelectedResponseIndex,
  customResponse,
  setCustomResponse,
  handleAddToFinetuningExample,
  hallucinationExampleAdded,
}) => {
  return (
    <div className="relative space-y-4 bg-white hover:bg-white w-[600px] overflow-x-clip">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Original Response:
        </label>
        <div className="p-3 bg-gray-50 rounded-lg text-gray-800">
          {message.content?.trim()}
        </div>
      </div>
      <h3 className="text-lg font-semibold text-gray-800 mb-2">
        Suggested alternative responses:
      </h3>
      {alternativeResponses.map((response, index) => (
        <Button
          key={index}
          onClick={() => setSelectedResponseIndex(index)}
          className={`flex w-[600px] flex-wrap break-words bg-white hover:bg-white h-auto p-4 text-left justify-start items-start rounded-lg transition-all duration-200 ease-in-out ${
            selectedResponseIndex === index
              ? "border-2 border-indigo-500"
              : "border border-gray-200"
          }`}
        >
          <div className="flex items-start">
            <div className="flex-shrink-0 mt-1 mr-2">
              {selectedResponseIndex === index && (
                <Check className="h-5 w-5 text-blue-500" />
              )}
            </div>
            <div className="text-gray-800 break-words whitespace-normal">
              {response}
            </div>
          </div>
        </Button>
      ))}
      <Button
        onClick={() => setSelectedResponseIndex(null)}
        className={`h-auto p-4 text-left justify-start w-full rounded-lg transition-all duration-200 ease-in-out ${
          selectedResponseIndex === null
            ? "bg-indigo-50 hover:bg-indigo-100 border-2 border-indigo-500"
            : "bg-white hover:bg-white border border-gray-200"
        }`}
      >
        <div className="flex items-start w-full">
          <div className="flex-shrink-0 mt-1 mr-2">
            {selectedResponseIndex === null && (
              <Check className="h-5 w-5 text-blue-500" />
            )}
          </div>
          <div className="text-gray-800">Other</div>
        </div>
      </Button>
      {selectedResponseIndex === null && (
        <div className="mt-4">
          <p className="text-gray-800 mb-2">Enter your expected response:</p>
          <textarea
            value={customResponse}
            onChange={(e) => setCustomResponse(e.target.value)}
            placeholder={message.content?.trim()}
            rows={3}
            className="mt-2 w-full p-4 border border-gray-300 focus:outline-none focus:border-indigo-500 rounded-md shadow-sm resize-none text-gray-800"
          />
        </div>
      )}

      <Button
        onClick={handleAddToFinetuningExample}
        disabled={
          selectedResponseIndex === null &&
          customResponse.trim() === "" &&
          hallucinationExampleAdded
        }
        className="mt-4 bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition-all duration-200 ease-in-out"
      >
        {hallucinationExampleAdded ? (
          <>
            <Check className="mr-2 h-4 w-4" />
            Added to Fine-tuning Examples
          </>
        ) : (
          <>
            <Plus className="mr-2 h-4 w-4" />
            Add to Node's Fine-tuning Example
          </>
        )}
      </Button>
    </div>
  );
};

export default HallucinationContent;
