export const getAllSavedFromLocalStorage = () => {
  return JSON.parse(window.localStorage.getItem("campaigns") || "[]");
};

export const getSavedFromLocalStorage = (local_id) => {
  const savedCampaigns = getAllSavedFromLocalStorage();
  return (
    savedCampaigns.find(
      (campaign) => campaign._local_storage_id === local_id,
    ) || null
  );
};

export const setSavedToLocalStorage = (data) => {
  console.log({ id: data._local_storage_id });
  let savedCampaigns = getAllSavedFromLocalStorage();
  const exists = savedCampaigns.find(
    (doc) => doc._local_storage_id === data._local_storage_id,
  );
  if (exists) {
    savedCampaigns = savedCampaigns.map((campaign) => {
      if (campaign._local_storage_id === data._local_storage_id) {
        return data;
      } else {
        return campaign;
      }
    });
  } else {
    savedCampaigns.push(data);
  }

  window.localStorage.setItem("campaigns", JSON.stringify([...savedCampaigns]));
  return;
};

export const removeCampaignFromLocalStorage = (id) => {
  const savedCampaigns = getAllSavedFromLocalStorage();
  const newCampaigns = savedCampaigns.filter(
    (campaign) => campaign?._local_storage_id !== id,
  );
  window.localStorage.setItem("campaigns", JSON.stringify(newCampaigns));
  return;
};
