import React from "react";
import styled from "styled-components";
import PhoneIcon from "../../../../assets/icons/phone-icon.svg";
import WaitIcon from "../../../../assets/icons/wait-icon.svg";
import Button from "components/core/Button";
import TrashIcon from "../../../../assets/icons/trash-icon.svg";

const SequenceSidebar = ({
  campaignName,
  sequences,
  addSequence,
  focusedSeqId,
  setFocusedSeqId,
  setWaitDuration,
  removeSequence,
}) => {
  return (
    <SidebarWrapper>
      {sequences.map((sequence, index) => (
        <SequenceItem key={index}>
          <SequenceNumber>
            {sequence.type === "send_call" || sequence.type === "follow_up" ? (
              <img height={12} width={12} src={PhoneIcon} alt="" />
            ) : (
              <img height={12} width={12} src={WaitIcon} alt="" />
            )}
          </SequenceNumber>

          {index < sequences.length - 1 && <SequenceLine />}

          {sequence.type === "send_call" || sequence.type === "follow_up" ? (
            <SequenceContent style={{ width: "100%" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <p className="font-bold">
                  {sequence.type === "send_call"
                    ? "Initial Call"
                    : "Follow Up Call"}
                </p>
                {sequence?.type === "follow_up" && (
                  <div
                    onClick={() => removeSequence(sequence.seqId)}
                    style={{
                      borderRadius: 2,
                      background: "#eef0f2",
                      padding: 4,
                      cursor: "pointer",
                    }}
                  >
                    <img src={TrashIcon} height={12} width={12} alt="" />
                  </div>
                )}
              </div>

              <SequenceBodyContent
                onClick={() => setFocusedSeqId(sequence.seqId)}
                focused={sequence.seqId === focusedSeqId}
              >
                <p style={{ fontSize: 12, fontWeight: "300" }}>Call</p>
                {sequence.type === "send_call" && (
                  <p style={{ fontWeight: "450" }}>
                    {campaignName || "Untitled Campaign"}
                  </p>
                )}
                {sequence.type === "follow_up" && (
                  <p style={{ fontWeight: "450" }}>
                    {sequence?.seqName || "Untitled Step"}
                  </p>
                )}
              </SequenceBodyContent>
            </SequenceContent>
          ) : (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="font-bold">Wait</p>

              <SequenceContent
                style={{
                  background: "#f8f9fa",
                  padding: "8px 16px 8px 13px",
                  justifyContent: "center",
                  borderRadius: "5px",
                  marginTop: "5px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    columnGap: "6px",
                  }}
                >
                  <p style={{ fontWeight: "400", marginRight: "5px" }}>
                    Wait for{" "}
                  </p>
                  <DayInput
                    defaultValue={1}
                    value={sequence.wait_duration}
                    onChange={(e) => setWaitDuration(sequence.seqId, e)}
                  />
                  <p style={{ fontWeight: "400", marginLeft: "5px" }}>
                    {" "}
                    day then
                  </p>
                </div>
              </SequenceContent>
            </div>
          )}
        </SequenceItem>
      ))}

      <Button
        onClick={addSequence}
        appearance={"outline"}
        style={{
          height: "30px",
          color: "grey",
          fontSize: 12,
          marginBottom: "50px",
        }}
      >
        Add Step
      </Button>
    </SidebarWrapper>
  );
};

const DayInput = styled.input`
  border: 1px solid darkgrey;
  border-radius: 4px;
  width: auto;
  max-width: 45px;
  height: 25px;
  font-size: 12px;
  text-align: center;
  background: transparent;
  color: #333;
  font-weight: bold;
  &:focus {
    outline: none;
    border-color: #4361ee;
    border-width: 2px;
  }
`;

const SidebarWrapper = styled.div`
  width: 35%;
  height: 500%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  border-right: 1px solid #eeeeee;
  min-width: 240px;
  max-width: 440px;
  padding: 17px;
  gap: 25px;
`;

const SequenceItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start; // Changed from center to flex-start to align items to the top
  position: relative;
  cursor: pointer;
`;

const SequenceNumber = styled.span`
  font-size: 1.2em;
  font-weight: bold;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #eeeeee;
  margin-right: 10px;
  zindex: 20;
  flex-shrink: 0; // Added to prevent resizing with screen size changes
`;

const SequenceLine = styled.div`
  position: absolute;
  top: 30px; // Changed from 50% to 15px to align the line with the center of the circle
  bottom: 0;
  left: 15px;
  width: 1px;
  height: calc(
    100%
  ); // Adjusted height to ensure the line spans the correct distance
  background-color: #eeeeee;
`;

const SequenceContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const SequenceBodyContent = styled.div`
  margin-top: 8px;
  padding: 8px 16px 8px 13px;
  border-radius: 2px;
  background-color: ${(props) => (props.focused ? "transparent" : "#f8f9fa")};
  border: ${(props) =>
    props.focused ? "1px solid #4361ee" : "1px solid transparent"};
  width: 100%; // Added to ensure the width does not change based on the children width
`;

export default SequenceSidebar;
