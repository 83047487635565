import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";
import { testWebhook } from "utils/funcs/phone-numbers/inbound/testWebhook";
import Button from "components/core/Button";
import Input from "components/core/Input";
import styled from "styled-components";
import LightTooltip from "./ToolTipDesc";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function Webhook({ fieldName }) {
  const { getValues } = useFormContext();
  const [loading, setLoading] = useState();

  const onTest = async () => {
    const url = getValues(fieldName);
    setLoading(true);
    try {
      const { success, res } = await testWebhook(url);
      if (success) {
        console.log({ res });
        toast.success("Webhook test sent!");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error sending webhook test");
      toast("Error sending webhook test. Please try again.", {
        type: "error",
      });
    }
  };
  const description = (
    <span>
      When the call ends, we’ll send the call details in a POST request to the
      URL you specify here.
    </span>
  );

  return (
    <Wrapper>
      <Input
        fieldName={fieldName}
        label={
          <span>
            Webhook URL
            <LightTooltip title={description} placement="right-start" arrow>
              <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
            </LightTooltip>
          </span>
        }
        placeholder="https://mywebhook.com/abc123"
      />
      <Button onClick={onTest} loading={loading}>
        Test Webhook
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  align-items: end;
`;
