import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import CodeIcon from "../../../../assets/icons/CodeIcon.svg";
import LangIcon from "../../../../assets/icons/LangIcon.svg";
import CloseIcon from "../../../../assets/icons/close-icon-black.svg";
import { useFormContext } from "react-hook-form";
import NetworkIcon from "../../../../assets/icons/NetworkIcon.svg";
import ToolIcon from "../../../../assets/icons/pink-tool-icon.svg";
import VoiceIcon from "../../../../assets/icons/voiceicon.svg";
import PlayIcon from "../../../../assets/icons/play-icon.svg";

export default function VoicesDropdown({
  keypairs,
  fieldname,
  nested_options,
  label,
  apiKey,
}) {
  const { setValue, getValues } = useFormContext();
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [latency, setLatency] = useState();
  const [focusedTab, setFocusedTab] = useState("bland_curated");
  const [isLoading, setIsLoading] = useState(false);

  const fetchTTSAudio = async (voiceId) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://us.api.bland.ai/v1/voices/` + voiceId + "/sample",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: apiKey,
          },
          body: JSON.stringify({
            text: "Hey this is Blandie, how can I help today?",
            response_type: "stream",
          }),
        },
      );

      let latency = null;

      if (response.ok) {
        latency = response.headers.get("x-latency");
        setLatency(latency);
      }
      const mediaSource = new MediaSource();
      const audio = new Audio();
      audio.src = URL.createObjectURL(mediaSource);

      await new Promise((resolve, reject) => {
        mediaSource.addEventListener(
          "sourceopen",
          () => {
            const sourceBuffer = mediaSource.addSourceBuffer("audio/mpeg");
            const reader = response.body.getReader();

            const read = () => {
              reader
                .read()
                .then(({ done, value }) => {
                  if (done) {
                    if (sourceBuffer.updating) {
                      sourceBuffer.addEventListener(
                        "updateend",
                        () => {
                          mediaSource.endOfStream();
                          resolve();
                        },
                        { once: true },
                      );
                    } else {
                      mediaSource.endOfStream();
                      resolve();
                    }
                    return;
                  }
                  // Check if the sourceBuffer is updating before appending the buffer
                  if (!sourceBuffer.updating) {
                    sourceBuffer.appendBuffer(value);
                    read();
                  } else {
                    // If the sourceBuffer is updating, wait for the update to finish before reading the next chunk
                    sourceBuffer.addEventListener("updateend", read, {
                      once: true,
                    });
                  }
                })
                .catch(reject);
            };

            read();
          },
          { once: true },
        );
      });
      audio.play();
      setIsLoading(false);

      return audio;
    } catch (error) {
      console.error("Failed to fetch TTS audio:", error);
    }
  };

  const handleOptionClick = (optionValue) => {
    setValue(`${fieldname}.value`, optionValue);
    if (focusedTab === "payload") {
      setValue(`${fieldname}.using_key`, true);
    }
    setIsOpen(false); // Close dropdown on option select
  };

  const handleOptionClickNonPayload = (optionValue) => {
    setValue(`${fieldname}.value`, optionValue);
    setValue(`${fieldname}.using_key`, false);
    setIsOpen(false); // Close dropdown on option select
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <DropdownContainer ref={dropdownRef}>
      <InputContainer>
        <Label style={{ marginBottom: 10 }}>{label}</Label>
        <StyledOption
          onClick={() => setIsOpen(!isOpen)}
          style={{ border: "1px solid #eeeeee" }}
        >
          <IconContainer>
            {getValues(`${fieldname}.using_key`) ? (
              <img src={CodeIcon} style={{ height: 16, width: 16 }} alt="" />
            ) : (
              <img src={VoiceIcon} style={{ height: 16, width: 16 }} alt="" />
            )}
          </IconContainer>

          <div>
            {getValues(`${fieldname}.using_key`) && (
              <p style={{ fontSize: 10, fontWeight: "300" }}>Key</p>
            )}
            <p style={{ fontWeight: "550", fontSize: 13 }}>
              {getValues(`${fieldname}.using_key`)
                ? getValues(`${fieldname}.value`)
                : nested_options.find(
                    (doc) => doc.value === getValues(`${fieldname}.value`),
                  )?.label}
            </p>
          </div>
        </StyledOption>
      </InputContainer>

      {isOpen && (
        <DropdownContent>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <DropdownHOption
              selected={focusedTab === "payload"}
              onClick={() => setFocusedTab("payload")}
              style={{ borderRight: "1px solid #eeeeee" }}
            >
              <p style={{ textAlign: "center" }}>Payload</p>
            </DropdownHOption>
            <DropdownHOption
              selected={focusedTab === "bland_curated"}
              onClick={() => setFocusedTab("bland_curated")}
            >
              <p style={{ textAlign: "center" }}>Bland Curated</p>
            </DropdownHOption>
            <DropdownHOption
              selected={focusedTab === "your_voices"}
              onClick={() => setFocusedTab("your_voices")}
            >
              <p style={{ textAlign: "center" }}>All Voices</p>
            </DropdownHOption>
          </div>

          {focusedTab === "payload" && (
            <>
              {keypairs?.map((doc, ind) => (
                <StyledOption
                  key={ind}
                  style={{
                    borderBottomLeftRadius: ind === keypairs.length - 1 ? 5 : 0,
                    borderBottomRightRadius:
                      ind === keypairs.length - 1 ? 5 : 0,
                    borderBottom:
                      ind === keypairs.length - 1
                        ? "1px solid #eeeeee"
                        : "none",
                  }}
                  onMouseDown={() => handleOptionClick(Object.keys(doc)[0])} // Set input value on option click
                >
                  <IconContainer>
                    <img
                      src={CodeIcon}
                      style={{ height: 16, width: 16 }}
                      alt=""
                    />
                  </IconContainer>

                  <div>
                    <p style={{ fontWeight: "550", fontSize: 14 }}>
                      {Object.keys(doc)[0]}
                    </p>
                    <p style={{}}>
                      {typeof Object.values(doc)[0] === "boolean"
                        ? Object.values(doc)[0]
                          ? "true"
                          : "false"
                        : typeof Object.values(doc)[0] === "number"
                          ? Object.values(doc)[0]
                          : Object.values(doc)[0]?.substring(0, 100)}{" "}
                      {Object.values(doc)[0]?.length >= 100 && "..."}
                    </p>
                  </div>
                </StyledOption>
              ))}
            </>
          )}

          {focusedTab === "bland_curated" && (
            <>
              {nested_options
                ?.filter((doc) => doc.type === "bland_curated")
                ?.map((doc, ind) => (
                  <StyledOption
                    key={ind}
                    style={{
                      borderBottomLeftRadius:
                        ind === keypairs.length - 1 ? 5 : 0,
                      borderBottomRightRadius:
                        ind === keypairs.length - 1 ? 5 : 0,
                      borderBottom:
                        ind === keypairs.length - 1
                          ? "1px solid #eeeeee"
                          : "none",
                    }}
                    onClick={() => handleOptionClickNonPayload(doc.value)} // Set input value on option click
                  >
                    <IconContainer>
                      <img
                        src={VoiceIcon}
                        style={{ height: 16, width: 16 }}
                        alt=""
                      />
                      {/*tab_name?.toLowerCase() === "languages" ? (
                                            <img src={LangIcon} style={{ height: 16, width: 16 }} alt="" />
                                        ) : tab_name?.toLowerCase() === "tools" ? (
                                            <img src={ToolIcon} style={{ height: 16, width: 16 }} alt="" />
                                        ):(
                                            <img src={NetworkIcon} style={{ height: 16, width: 16 }} alt="" />
                                        )*/}
                    </IconContainer>

                    <div>
                      <p style={{ fontWeight: "550", fontSize: 14 }}>
                        {doc.label}
                      </p>
                      <p style={{}}>{doc.value}</p>
                    </div>

                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        fetchTTSAudio(doc.value);
                      }}
                      style={{ marginLeft: "auto", zIndex: 20000 }}
                    >
                      <img
                        src={PlayIcon}
                        style={{ height: 16, width: 16 }}
                        alt=""
                      />
                    </div>
                  </StyledOption>
                ))}
            </>
          )}

          {focusedTab === "your_voices" && (
            <>
              {nested_options
                ?.filter((doc) => doc.type === "user_voices")
                ?.map((doc, ind) => (
                  <StyledOption
                    key={ind}
                    style={{
                      borderBottomLeftRadius:
                        ind === keypairs.length - 1 ? 5 : 0,
                      borderBottomRightRadius:
                        ind === keypairs.length - 1 ? 5 : 0,
                      borderBottom:
                        ind === keypairs.length - 1
                          ? "1px solid #eeeeee"
                          : "none",
                    }}
                    onClick={() => handleOptionClickNonPayload(doc.value)} // Set input value on option click
                  >
                    <IconContainer>
                      <img
                        src={VoiceIcon}
                        style={{ height: 16, width: 16 }}
                        alt=""
                      />
                      {/*tab_name?.toLowerCase() === "languages" ? (
                                            <img src={LangIcon} style={{ height: 16, width: 16 }} alt="" />
                                        ) : tab_name?.toLowerCase() === "tools" ? (
                                            <img src={ToolIcon} style={{ height: 16, width: 16 }} alt="" />
                                        ):(
                                            <img src={NetworkIcon} style={{ height: 16, width: 16 }} alt="" />
                                        )*/}
                    </IconContainer>

                    <div>
                      <p style={{ fontWeight: "550", fontSize: 14 }}>
                        {doc.label}
                      </p>
                      <p style={{}}>{doc.value}</p>
                    </div>

                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        fetchTTSAudio(doc.value);
                      }}
                      style={{ marginLeft: "auto", zIndex: 20000 }}
                    >
                      <img
                        src={PlayIcon}
                        style={{ height: 16, width: 16 }}
                        alt=""
                      />
                    </div>
                  </StyledOption>
                ))}
            </>
          )}
        </DropdownContent>
      )}
    </DropdownContainer>
  );
}

const DropdownHOption = styled.div`
  height: 40px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  border-radius-top-right: 0px;
  border-radius-top-left: 0px;
  display: flex;
  width: 50%;
  cursor: pointer;
  background: ${({ selected }) => (selected ? "#e5e5e5" : "#fff")};
  &:hover {
    background-color: #f0f0f0; /* Change this to your desired hover background color */
  }
`;

const StyledOption = styled.div`
  border-top: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  cursor: pointer;
  padding: 10px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 25px;
  border-top-left-radius: ${({ ind }) => (ind === 0 ? 5 : 0)}px;
  border-top-right-radius: ${({ ind }) => (ind === 0 ? 5 : 0)}px;
  &:hover {
    background-color: #f0f0f0; /* Change this to your desired hover background color */
  }
`;

const InputContainer = styled.div`
  padding: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const Label = styled.p`
  margin: 0px;
  padding: 0px;
  color: #000;
  font-size: 13px;
  font-weight: 500;
`;

const DropdownContainer = styled.div`
  width: 100%;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 14px;
`;

const IconContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px;
`;

const DropdownContent = styled.div`
  width: 100%;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  margin-top: 5px; /* Add a little space between the input and dropdown */
  margin-bottom: 10px;
`;
