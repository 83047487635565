import { useFieldArray, useFormContext } from "react-hook-form";
import { objectToKeyValue } from "utils/formatting/objToKeyValues";
import { useEffect } from "react";
import styled from "styled-components";
import Input from "components/core/Input";
import Label from "components/core/Label";
import Button from "components/core/Button";

export default function KeyValues({
  fieldName,
  requestIndex,
  nestedFieldName,
}) {
  const { control, getValues, setValue } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `${fieldName}[${requestIndex}].${nestedFieldName}`,
  });

  useEffect(() => {
    const fieldPath = `${fieldName}[${requestIndex}].${nestedFieldName}`;
    const currFieldValue = getValues(fieldPath);
    const formattedFieldsValues = objectToKeyValue(currFieldValue);
    setValue(fieldPath, formattedFieldsValues);
  }, [fieldName, getValues, nestedFieldName, requestIndex, setValue]);

  const onAddHeader = () => {
    append({ key: "", value: "" });
  };

  return (
    <Wrapper>
      {fields.length > 0 ? (
        <>
          <Label>
            {nestedFieldName.charAt(0).toUpperCase() + nestedFieldName.slice(1)}
          </Label>
          <KeyValueWrapper>
            {fields.map((param, index) => (
              <KeyValueGrid key={param.id}>
                <KeyValueItem>
                  <Input
                    fieldName={`${fieldName}[${requestIndex}].${nestedFieldName}[${index}].key`}
                    placeholder="Key"
                    style={{ borderRadius: "0px", border: "none" }}
                  />
                </KeyValueItem>
                <KeyValueItem>
                  <Input
                    fieldName={`${fieldName}[${requestIndex}].${nestedFieldName}[${index}].value`}
                    placeholder="Value"
                    style={{
                      borderRadius: "0px",
                      border: "none",
                      width: "100%",
                    }}
                  />
                </KeyValueItem>
                <KeyValueItem>
                  <Button
                    onClick={() => remove(index)}
                    appearance={"outline"}
                    style={{
                      border: "none",
                      borderRadius: "0px",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    -
                  </Button>
                </KeyValueItem>
              </KeyValueGrid>
            ))}
          </KeyValueWrapper>
          <Button
            onClick={onAddHeader}
            appearance="outline"
            style={{ width: "auto !important" }}
          >
            + Key/Value
          </Button>
        </>
      ) : (
        <Empty>
          <Label>
            {nestedFieldName.charAt(0).toUpperCase() + nestedFieldName.slice(1)}
          </Label>
          <Button onClick={onAddHeader} style={{ width: "auto !important" }}>
            + Key/Value
          </Button>
        </Empty>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: start;
`;

const KeyValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid #eeeeee;
  gap: 1px;
`;

const KeyValueGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 45px;
  background-color: #fbfbfa;
  grid-gap: 1px;
  width: 100%;
`;

const KeyValueItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: end;
  box-sizing: border-box;
  outline: 1px solid #eeeeee;
`;

const Empty = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: start;
  width: 100%;
  justify-content: start;
`;
