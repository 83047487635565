import React, { useState } from "react";
import { Switch } from "@headlessui/react";
import {
  Trash2,
  Plus,
  ChevronDown,
  ChevronUp,
  AlertCircle,
} from "lucide-react";

const ExtractInfo = ({
  extractVars,
  setExtractVars,
  useExtractVars,
  setUseExtractVars,
  validationErrors,
  setValidationErrors,
  isWebHook = false,
}) => {
  const [isVariablesSectionOpen, setIsVariablesSectionOpen] = useState(false);

  const addVariable = () => {
    setIsVariablesSectionOpen(true);
    setExtractVars([...extractVars, ["", "", ""]]);
  };

  const removeVariable = (index) => {
    setExtractVars(extractVars.filter((_, i) => i !== index));
    setValidationErrors(
      validationErrors.filter((error) => error.index !== index),
    );
  };

  const updateVariable = (index, position, value) => {
    setExtractVars(
      extractVars.map((item, i) => {
        if (i === index) {
          const updatedItem = [...item];
          updatedItem[position] = value;
          return updatedItem;
        }
        return item;
      }),
    );
    // Clear error for this specific field when updated
    setValidationErrors((prev) =>
      prev.filter(
        (error) => !(error.index === index && error.field === position),
      ),
    );
  };

  const getErrorMessage = (index) => {
    const errors = validationErrors.filter((error) => error.index === index);
    if (errors.length === 3) {
      return "All fields are required";
    }
    return errors
      .map((error) => {
        switch (error.field) {
          case 0:
            return "Name is required";
          case 1:
            return "Type is required";
          case 2:
            return "Description is required";
          default:
            return "";
        }
      })
      .join(", ");
  };

  return (
    <div className="border p-6 rounded-lg shadow-sm">
      <div className="flex items-center justify-between">
        <h2 className="text-xl">Extract Call Info into Variables</h2>
        <Switch
          checked={useExtractVars}
          onChange={() => setUseExtractVars(!useExtractVars)}
          className={`${
            useExtractVars ? "bg-indigo-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full`}
        >
          <span className="sr-only">
            Enable Extract Call Info into Variables
          </span>
          <span
            className={`${
              useExtractVars ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>

      <p className="text-lg text-gray-600 mt-1">
        {isWebHook
          ? "Extract information from the call into variables, and use them in your webhook parameters. Gets run before the webhook is called."
          : "Run Dialogue Extraction before Next Node Dialogue. This will lead to higher latency, but allow variables to be used in next nodes dialogue."}
      </p>

      {useExtractVars && (
        <div className="mt-4">
          <div className="flex justify-between items-center">
            <button
              onClick={() => setIsVariablesSectionOpen(!isVariablesSectionOpen)}
              className="flex items-center gap-2 text-2xl font-medium focus:outline-none"
            >
              <h3 className="text-xl">Variables</h3>
              {extractVars.length > 0 && (
                <span className="ml-2 bg-indigo-500 text-white text-sm font-semibold px-2 py-1 rounded-full">
                  {extractVars.length}
                </span>
              )}
              {isVariablesSectionOpen ? (
                <ChevronUp className="h-6 w-6" />
              ) : (
                <ChevronDown className="h-6 w-6" />
              )}
              {validationErrors.length > 0 && (
                <div className="relative group">
                  <AlertCircle className="h-6 w-6 text-red-500 ml-2" />
                  <span className="absolute left-full ml-2 top-1/2 transform -translate-y-1/2 bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded hidden group-hover:block whitespace-nowrap">
                    Some variables are missing fields
                  </span>
                </div>
              )}
            </button>
            <button
              onClick={addVariable}
              className="bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition-colors flex items-center gap-2"
            >
              <Plus className="h-5 w-5" />
              Add Variable
            </button>
          </div>
          {isVariablesSectionOpen && (
            <div className="space-y-4 mt-4">
              {extractVars.map((item, index) => (
                <div
                  key={index}
                  className="space-y-3 p-4 bg-gray-50 rounded-lg"
                >
                  <div className="flex items-center gap-4">
                    <input
                      type="text"
                      value={item[0]}
                      placeholder="Variable Name (e.g. email/age)"
                      onChange={(e) => updateVariable(index, 0, e.target.value)}
                      className={`flex-1 border ${
                        validationErrors.some(
                          (error) => error.index === index && error.field === 0,
                        )
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md p-2`}
                    />
                    <select
                      value={item[1]}
                      onChange={(e) =>
                        updateVariable(index, 1, e.target.value.toLowerCase())
                      }
                      className={`w-1/3 border ${
                        validationErrors.some(
                          (error) => error.index === index && error.field === 1,
                        )
                          ? "border-red-500"
                          : "border-gray-300"
                      } rounded-md p-2`}
                    >
                      <option value="">Select Type</option>
                      <option value="string">String</option>
                      <option value="integer">Integer</option>
                      <option value="boolean">Boolean</option>
                    </select>
                    <button
                      onClick={() => removeVariable(index)}
                      className="p-2 rounded-md bg-red-100 hover:bg-red-200 transition-colors"
                      aria-label="Remove variable"
                    >
                      <Trash2 className="h-5 w-5 text-red-600" />
                    </button>
                  </div>
                  <textarea
                    placeholder="Variable Description (e.g. Extract the user's email address from the conversation)"
                    value={item[2]}
                    onChange={(e) => updateVariable(index, 2, e.target.value)}
                    className={`w-full h-24 border ${
                      validationErrors.some(
                        (error) => error.index === index && error.field === 2,
                      )
                        ? "border-red-500"
                        : "border-gray-300"
                    } rounded-md p-2 resize-none`}
                  />
                  {validationErrors.some((error) => error.index === index) && (
                    <p className="text-red-500 text-sm mt-1">
                      {getErrorMessage(index)}
                    </p>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ExtractInfo;
