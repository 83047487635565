import React, { useState, useEffect, useRef } from "react";
import { HighlightWithinTextarea } from "react-highlight-within-textarea";
import "draft-js/dist/Draft.css";

const StaticTextArea = ({ value, onChange, placeholder, className }) => {
  const [internalValue, setInternalValue] = useState(value || "");
  const highlightRegex = /{{.*?}}/g;

  useEffect(() => {
    setInternalValue(value || "");
  }, [value]);

  const handleChange = (newValue) => {
    setInternalValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const textareaRef = useRef(null);

  // enable entire div to be clickable to focus textarea
  const handleDivClick = () => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  return (
    <div
      onClick={handleDivClick}
      className={`relative ${className}`}
      style={{
        minHeight: "150px",
        maxHeight: "300px",
        overflow: "auto",
        resize: "vertical",
        padding: "0.5rem",
        cursor: "text",
      }}
    >
      <HighlightWithinTextarea
        ref={textareaRef}
        value={typeof internalValue === "string" ? internalValue : ""}
        onChange={handleChange}
        placeholder={placeholder}
        highlight={[
          {
            highlight: highlightRegex,
            className: "bg-blue-100",
          },
        ]}
      />
    </div>
  );
};

export default StaticTextArea;
