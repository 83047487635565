import { useState, useEffect, useRef, useContext } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { Menu } from "@headlessui/react";
import { FaCaretDown } from "react-icons/fa";
import FlowContext from "../contextFlow";
import RequestData from "components/Dashboard/Parameters/RequestData";
import CustomDeployment from "components/Dashboard/Parameters/CustomDeployment";
import Switch from "components/core/Switch";

function useMaxHeight(offset = 80) {
  const [maxHeight, setMaxHeight] = useState("80vh");

  useEffect(() => {
    function updateMaxHeight() {
      setMaxHeight(`${window.innerHeight - offset}px`);
    }

    window.addEventListener("resize", updateMaxHeight);
    updateMaxHeight();

    return () => window.removeEventListener("resize", updateMaxHeight);
  }, [offset]);

  return maxHeight;
}

function TestPathwayButton({
  elements,
  setChatInstanceCount,
  setShowChatInterface,
  chatStartNode,
  chatRequestData,
  setChatStartNode,
  setChatRequestData,
  chatEndpoint,
  setChatEndpoint,
  enableQA,
  setEnableQA,
  versions,
  setChatVersion,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { triggerUpdate, selectedVersion } = useContext(FlowContext);
  const dropdownRef = useRef(null);
  const maxHeight = useMaxHeight();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  const handleStartTest = () => {
    const formValues = methods.getValues();
    setChatStartNode(formValues.selectedNode?.id);
    setChatRequestData(formValues.requestData);
    setChatEndpoint(formValues.endpoint);
    setChatInstanceCount((prevCount) => prevCount + 1);
    setEnableQA(formValues.enableQA);
    console.log("formvalues", formValues);

    setChatVersion(formValues.version.version_number);
    const newNodes = elements.nodes.map((node) => ({
      ...node,
      data: {
        ...node.data,
        active: node.id === formValues.selectedNode?.id,
      },
    }));
    triggerUpdate({ nodes: newNodes }, true);
    setIsOpen(false);
  };

  const methods = useForm({
    defaultValues: {
      selectedNode:
        elements.nodes.find((node) => node.id === chatStartNode) ||
        elements.nodes.find((node) => node.data.isStart) ||
        elements.nodes[0],
      requestData: chatRequestData || [],
      endpoint: chatEndpoint || null,
      enableQA: enableQA || false,
      version: selectedVersion ?? versions?.[0] ?? null,
    },
  });

  useEffect(() => {
    // Update the form's version field whenever selectedVersion changes
    methods.setValue("version", selectedVersion);
  }, [selectedVersion, methods]);

  return (
    <FormProvider {...methods}>
      <div className="relative inline-block text-left" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-[12px] font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
        >
          Test Pathway
        </button>

        {isOpen && (
          <div
            className="p-2 origin-top-right absolute right-0 mt-2 w-[300px] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-[1000] overflow-y-auto"
            style={{ maxHeight: maxHeight }}
          >
            <div className="p-1">
              <label
                htmlFor="node-select"
                className="block pt-2 px-2 text-[13px] text-[#676775] font-medium"
              >
                Start Node
              </label>
              <p className="px-2 pt-4 text-[12px] text-secondary">
                Choose which Node to start testing from. Default node will be
                the Start Node.
              </p>
              <Controller
                name="selectedNode"
                control={methods.control}
                render={({ field }) => (
                  <Menu as="div" className="relative mt-1 mb-2">
                    <Menu.Button className="flex items-center w-full px-4 py-2 text-[13px] border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                      <span className="flex-grow text-left">
                        {field.value
                          ? field.value.data.name || "Unnamed Node"
                          : "Select Node"}
                      </span>
                      <FaCaretDown className="w-4 h-4" />
                    </Menu.Button>
                    <Menu.Items className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 overflow-auto ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {elements.nodes.map((node) => (
                        <Menu.Item key={node.id}>
                          {({ active }) => (
                            <div
                              className={`${
                                active ? "bg-indigo-50" : "bg-white"
                              } ${
                                field.value?.id === node.id
                                  ? "bg-indigo-100"
                                  : ""
                              } group flex items-center w-full px-4 py-2 text-sm cursor-pointer`}
                              onClick={() => field.onChange(node)}
                            >
                              {node.data.name || "Unnamed Node"}
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Menu>
                )}
              />

              <label
                htmlFor="version-select"
                className="block pt-2 px-2 text-[13px] text-[#676775] font-medium"
              >
                Version
              </label>
              <Controller
                name="version"
                control={methods.control}
                render={({ field }) => (
                  <Menu as="div" className="relative mt-1 mb-2">
                    <Menu.Button className="flex items-center w-full px-4 py-2 text-[13px] border border-gray-300 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                      <span className="flex-grow text-left">
                        {field.value
                          ? field.value.name ||
                            `Version ${field.value.version_number}`
                          : "Select Version"}
                      </span>
                      <FaCaretDown className="w-4 h-4" />
                    </Menu.Button>
                    <Menu.Items className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg max-h-60 overflow-auto ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {versions.map((version, index) => (
                        <Menu.Item key={index}>
                          {({ active }) => (
                            <div
                              className={`${
                                active ? "bg-indigo-50" : "bg-white"
                              } ${
                                field.value?.version_number ===
                                version.version_number
                                  ? "bg-indigo-100"
                                  : ""
                              } group flex justify-between items-center w-full px-4 py-3 text-sm cursor-pointer`}
                              onClick={() => field.onChange(version)}
                            >
                              <span>
                                {version.name ||
                                  `Version ${version.version_number}`}
                              </span>
                              {version.is_latest && (
                                <span className="px-2 py-1 bg-green-100 text-green-800 text-xs rounded-full">
                                  Latest
                                </span>
                              )}
                              {version.version_number === 0 && (
                                <span className="px-2 py-1 bg-indigo-100 text-indigo-800 text-xs rounded-full">
                                  Published
                                </span>
                              )}
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Menu>
                )}
              />

              <div className="p-2">
                <RequestData fieldName="requestData" short />
              </div>
              <div>
                <CustomDeployment fieldName="endpoint" isPathway />
              </div>
              <div className="mt-4">
                <Switch
                  fieldName="enableQA"
                  label="Run Unit Test (Check instruction following)"
                />
              </div>

              <button
                onClick={handleStartTest}
                className="mt-4 w-full bg-indigo-500 text-white rounded-md px-4 py-2"
              >
                Test Pathway
              </button>
            </div>
          </div>
        )}
      </div>
    </FormProvider>
  );
}

export default TestPathwayButton;
