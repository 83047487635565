import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchTranscript } from "../../../utils/funcs/call-logs/fetchTranscript";
import { getUserData } from "../../../utils/funcs/browser/getUserData";
import { Loading } from "../../core/Loading";
import { DataCard, KeyValue, KeyValues } from "./Styles";

export default function Summary({ summary, modal }) {
  return (
    <>
      <DataCard
        modal={modal}
        style={{
          maxHeight: 399,
          overflow: "scroll",
        }}
      >
        <h2 className="font-bold">Summary</h2>
        <p
          style={{
            fontSize: 18,
          }}
        >
          {summary}
        </p>
      </DataCard>
    </>
  );
}
