import { Dialog, Switch, Transition } from "@headlessui/react";
import ReactPhoneInput from "components/core/PhoneInput";
import { Fragment, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CiSquareRemove } from "react-icons/ci";
import { toast } from "react-toastify";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import { getUserData } from "utils/funcs/browser/getUserData";

const ShareModal = ({ graphID, setShowShareModal, graphName }) => {
  const main_user = getUserData();
  const [copied, setCopied] = useState(false);
  const [link, setLink] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const formMethods = useForm();
  const [accessUsers, setAccessUsers] = useState([]);
  const [isGeneralAccess, setIsGeneralAccess] = useState(false);

  useEffect(() => {
    const fetchAccessUsers = async () => {
      const response = await fetch(
        `https://us.api.bland.ai/v1/pathway/${graphID}/access`,
        {
          method: "GET",
          headers: {
            Authorization: main_user.api_key,
          },
        },
      );
      const data = await response.json();

      if (data.status === "success") {
        setAccessUsers(data.data);
      }
    };
    fetchAccessUsers();
  }, [graphID]);

  useEffect(() => {
    if (graphID) {
      setLink(
        `${window.location.origin}/dashboard?page=convo-pathways&id=${graphID}`,
      );
    }
  }, [graphID]);

  const deleteAccess = async (phone_number) => {
    const response = await fetch(
      `https://us.api.bland.ai/v1/pathway/${graphID}/access`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          authorization: main_user.api_key,
        },
        body: JSON.stringify({
          phone_number: phone_number,
        }),
      },
    );
    const data = await response.json();

    if (data.status === "success") {
      toast.success(data.message, {
        position: "bottom-right",
      });
      setAccessUsers(
        accessUsers.filter((u) => u.phone_number !== phone_number),
      );
    } else {
      toast.error("Failed to remove user", {
        position: "bottom-right",
      });
    }
  };

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <FormProvider {...formMethods}>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 w-[500px]"
          onClose={() => {
            setIsOpen(false);
            setShowShareModal(false);
            formMethods.reset();
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-30" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="max-w-[600px] w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all min-h-[300px] max-h-[400px] py-10 ">
                  <Dialog.Title
                    as="h3"
                    className="text-[20px] font-light leading-[12px] text-gray-900"
                  >
                    Share Pathway - {graphName}
                  </Dialog.Title>
                  <div className="mt-4">
                    <p className="text-[12px] text-gray-500 mb-8">
                      Grant others access to your pathway by creating a link
                      here. Through this link, they'll be able to edit it from
                      their own account.
                    </p>

                    {/* add a phone number input */}
                    <div className="flex justify-between items-center gap-4">
                      <ReactPhoneInput fieldName="phone" />

                      <div className="flex items-center mt-2">
                        <button
                          onClick={() => {
                            const sharePathway = async () => {
                              if (!formMethods.getValues().phone) {
                                toast.warn(
                                  "Please enter a valid phone number",
                                  {
                                    position: "bottom-right",
                                  },
                                );
                                return;
                              }
                              // use phone numebr from formMethods.getValues().phone
                              const response = await fetch(
                                `https://us.api.bland.ai/v1/pathway/${graphID}/access`,
                                {
                                  method: "POST",
                                  headers: {
                                    "Content-Type": "application/json",
                                    Authorization: main_user.api_key,
                                  },
                                  body: JSON.stringify({
                                    phone_number:
                                      "+" + formMethods.getValues().phone,
                                  }),
                                },
                              );
                              const data = await response.json();
                              if (data.status === "success") {
                                toast.success(data.message, {
                                  position: "bottom-right",
                                });
                                formMethods.reset();
                                setAccessUsers([
                                  ...accessUsers,
                                  {
                                    name: data.data.name,
                                    phone_number: data.data.phone_number,
                                  },
                                ]);
                              } else {
                                toast.error("Failed to share pathway", {
                                  position: "bottom-right",
                                });
                              }
                            };
                            sharePathway();
                          }}
                          type="button"
                          className="inline-flex justify-center rounded-md border border-transparent bg-indigo-500 px-6 py-1.5 text-[14px] font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                        >
                          Add
                        </button>
                      </div>
                      {/* <input
                                        type="text"
                                        placeholder="Enter email or group"
                                        className="mt-1 w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                    /> */}
                    </div>
                  </div>

                  <div className="mt-4">
                    <p className="text-[12px] font-semibold text-gray-900">
                      People with access
                    </p>
                    <div className="mt-1">
                      {/* You can list people here dynamically */}
                      {accessUsers.map((user) => (
                        <div className="flex justify-between">
                          <p key={user.phone_number}>{user.name}</p>
                          <CiSquareRemove
                            className="text-indigo-500 cursor-pointer"
                            onClick={() => deleteAccess(user.phone_number)}
                            size={14}
                          />
                        </div>
                      ))}
                      <p className="capitalize">
                        {" "}
                        {main_user.user.first_name} {main_user.user.last_name}{" "}
                        (you)
                      </p>
                    </div>
                  </div>

                  {/* <div className="mt-4">
                                    <p className="text-[12px] font-semibold text-gray-900">General access</p>
                                    <Switch.Group as="div" className="flex items-center justify-between">
                                        <span className="flex-grow flex flex-col">
                                            <Switch.Label as="span" className="text-[12px] text-gray-900" passive>
                                                Anyone with the link
                                            </Switch.Label>
                                            <Switch.Description as="span" className="text-[12px] text-gray-500">
                                                Anyone on the internet with the link can edit
                                            </Switch.Description>
                                        </span>
                                        <Switch
                                            checked={isGeneralAccess}
                                            onChange={() => {
                                                setIsGeneralAccess(!isGeneralAccess);
                                            }}
                                            className={`${true ? 'bg-indigo-600' : 'bg-gray-200'
                                                } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                                        >
                                            <span
                                                className={`${true ? 'translate-x-6' : 'translate-x-1'
                                                    } inline-block w-4 h-4 transform rounded-full bg-white transition-transform`}
                                            />
                                        </Switch>
                                    </Switch.Group>
                                </div> */}

                  <div className="flex justify-between items-center">
                    <div className="mt-4">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-[12px] font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                        onClick={copyLink}
                      >
                        {copied ? "Link Copied" : "Copy Link"}
                      </button>
                    </div>

                    <div className="mt-4">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-[12px] font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={() => setIsOpen(false)}
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </FormProvider>
  );
};

export default ShareModal;
