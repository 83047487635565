import React, { useState, useEffect, useRef } from "react";
import { RxCross2 } from "react-icons/rx";
import {
  AiOutlineExclamationCircle,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { IoMdSend } from "react-icons/io";
import { MdOutlineOpenInFull, MdOutlineCloseFullscreen } from "react-icons/md";
import FineTuneModal from "./fine-tune/FineTuneModal";
import { getApiKey } from "utils/funcs/browser/getApiKey";

const ChatInterface = ({
  onClose,
  id,
  callID,
  setCallID,
  setShowChat,
  chatRequestData,
  chatStartNode,
  chatEndpoint,
  chatConversationHistory,
  setChatConversationHistory,
  currentNodeName,
  chatVersion,
}) => {
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const isAssistantMessageCompleteRef = useRef(true);
  const chatContainerRef = useRef(null);
  const [size, setSize] = useState({ width: 360, height: 500 });

  const [editingMessage, setEditingMessage] = useState(null);

  // ... (previous useEffect hooks and functions)

  const handleEditClick = (message) => {
    setEditingMessage(message);
  };

  const handleEditClose = () => {
    setEditingMessage(null);
  };

  const messageContainsPromptInfo = (message) => {
    return message.promptInfo;
  };

  const apiKey = getApiKey();

  useEffect(() => {
    const fetchWebhookURL = async () => {
      let webhookURL = `wss://stream.aws.dc5.bland.ai/ws/connect/blandshared`;
      try {
        let wsEndpoint = chatEndpoint;
        if (!wsEndpoint) {
          wsEndpoint = "https://us.api.bland.ai";
        }

        if (!apiKey) {
          return;
        }

        // trim trailing slash
        wsEndpoint = wsEndpoint.replace(/\/$/, "");
        // Fetch the placement group
        const response = await fetch(`${wsEndpoint}/placement/current`, {
          method: "GET",
          headers: {
            authorization: apiKey,
          },
        });

        const result = await response.json();
        if (!response.ok || !result.data || !result.data.placement_group) {
          throw new Error("Failed to fetch placement group");
        }

        let placementGroup = result.data.placement_group;

        if (!placementGroup || placementGroup == "bland") {
          placementGroup = "blandshared";
        }

        // console.log(
        //   "Connecting to WebSocket",
        //   `wss://stream.aws.dc5.bland.ai/ws/connect/${placementGroup}`,
        // );
        webhookURL = `wss://stream.aws.dc5.bland.ai/ws/connect/${placementGroup}`;
      } catch (error) {
        console.error("Failed to find group", error);
      }

      const newSocket = new WebSocket(`${webhookURL}?pathway_chat=${id}`);
      console.log("Connected to WebSocket");

      newSocket.addEventListener("open", async () => {
        console.log("Websocket Initialized, sending setup message");
        newSocket.send(
          JSON.stringify({
            type: "setup",
            payload: {
              requestData: chatRequestData,
              startNode: chatStartNode,
              chat_history: chatConversationHistory,
              chatVersion: chatVersion,
            },
          }),
        );

        if (chatConversationHistory) {
          setMessages(chatConversationHistory);
        }
      });

      setChatConversationHistory(null);

      newSocket.addEventListener("message", (event) => {
        const message = JSON.parse(event.data);
        if (message.type === "callID") {
          //   console.log("callID", message.payload);
          setCallID(message.payload);
          setShowChat(true);
        } else if (message.type === "text") {
          if (isLoading) setIsLoading(false);
          if (isAssistantMessageCompleteRef.current) {
            setMessages((prevMessages) => [
              ...prevMessages,
              {
                role: "assistant",
                content: message.payload,
                promptInfo: message.promptInfo,
              },
            ]);
            isAssistantMessageCompleteRef.current = false;
          } else {
            setMessages((prevMessages) => {
              const updatedMessages = [...prevMessages];
              const lastMessageIndex = updatedMessages.length - 1;
              updatedMessages[lastMessageIndex].content += message.payload;
              updatedMessages[lastMessageIndex].promptInfo = message.promptInfo;
              return updatedMessages;
            });
          }
        } else if (message.type === "done") {
          isAssistantMessageCompleteRef.current = true;
        }
      });

      setSocket(newSocket);

      return () => {
        newSocket.close();
      };
    };

    fetchWebhookURL();
  }, [id]);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const sendMessage = () => {
    if (socket && inputMessage.trim()) {
      socket.send(JSON.stringify({ type: "text", payload: inputMessage }));
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "user", content: inputMessage },
      ]);
      setInputMessage("");
    }
  };

  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div
      className={`fixed ${isFullscreen ? "inset-0 left-[50px]" : "bottom-0 left-[100px]"} m-4 bg-white text-black shadow-lg rounded-lg overflow-hidden z-50 flex flex-col`}
      style={{
        width: isFullscreen ? "97%" : `${size.width}px`,
        height: isFullscreen ? "100%" : `${size.height}px`,
        minWidth: "300px",
        minHeight: "400px",
      }}
    >
      <div className="flex items-center justify-between p-4 border-b">
        <h2 className="text-xl font-semibold">Chat with your Pathways Agent</h2>
        <div className="flex items-center space-x-2">
          {currentNodeName && (
            <div className="px-3 py-1 bg-blue-100 border-2 border-blue-500 rounded-md flex items-center">
              <span className="text-sm text-blue-600 truncate max-w-[150px]">
                {currentNodeName}
              </span>
            </div>
          )}
          <button
            onClick={toggleFullscreen}
            className="text-gray-500 hover:text-gray-700"
          >
            {isFullscreen ? (
              <MdOutlineCloseFullscreen size={20} />
            ) : (
              <MdOutlineOpenInFull size={20} />
            )}
          </button>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <RxCross2 size={20} />
          </button>
        </div>
      </div>
      <div className="flex-grow overflow-auto p-4" ref={chatContainerRef}>
        {isLoading ? (
          <div className="flex justify-center items-center h-full gap-2">
            Connecting...
            <AiOutlineLoading3Quarters className="animate-spin text-2xl text-blue-500" />
          </div>
        ) : (
          messages.map((msg, index) => (
            <div
              key={index}
              className={`mb-4 ${msg.role === "user" ? "flex justify-end" : "flex flex-col justify-start"}`}
            >
              <div
                className={`max-w-md mx-2 p-3 rounded-lg ${
                  msg.role === "user"
                    ? "bg-gray-100 text-gray-800"
                    : "bg-blue-500 text-white"
                } relative group`}
              >
                <p className="text-[12px]">{msg.content}</p>
              </div>
              {msg.role === "assistant" && messageContainsPromptInfo(msg) && (
                <button
                  onClick={() => handleEditClick(msg)}
                  className="text-left text-xs text-gray-500 hover:text-gray-700 mt-1 ml-2"
                >
                  Bad reply? Click to fine-tune
                </button>
              )}
            </div>
          ))
        )}
      </div>
      <div className="p-4 border-t">
        <div className="flex items-center space-x-2">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            className="flex-grow border border-gray-300 rounded-full px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Type your message..."
            onKeyDown={(e) => e.key === "Enter" && sendMessage()}
          />
          <button
            onClick={sendMessage}
            className="bg-blue-500 text-white p-2 rounded-full hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <IoMdSend size={20} />
          </button>
        </div>
      </div>
      {/* <div
        className="absolute bottom-4 right-0 w-4 h-4 cursor-se-resize"
        onMouseDown={startResize}
      /> */}
      {editingMessage && (
        <FineTuneModal
          isOpen={!!editingMessage}
          onClose={handleEditClose}
          message={editingMessage}
          graphID={id} // Pass the graphID
          callID={callID} // Pass the callID
        />
      )}
    </div>
  );
};

export default ChatInterface;
