import React, { useContext, useEffect, useState } from "react";
import { useReactFlow } from "reactflow";
import { toast } from "react-toastify";
import FlowContext from "../contextFlow";
import { EditIcon } from "assets/icons/navigationIcons";
import { Button } from "@/components/ui/button";
import EditAssistantResponseModal from "./assistantResponseModal";
import FlagResponseModal from "./flagResponseModal";
import { AlertTriangle, CheckCircle, Edit, Flag, FlagIcon } from "lucide-react";
import FlagEditModal from "./flagResponseModal";
import { getUserData } from "utils/funcs/browser/getUserData";

const DecisionLogs = ({
  enableQA,
  graphID,
  decision,
  edges,
  nodes,
  setChatConversationHistory,
  setChatStartNode,
  callID,
}) => {
  const user = getUserData();
  const initialDecision = { ...decision };
  const { triggerUpdate } = useContext(FlowContext);
  const [openEditDecision, setOpenEditDecision] = useState(false);
  const [editedDecision, setEditedDecision] = useState(decision);
  const [editedField, setEditedField] = useState(null);
  const [isFlagEditModalOpen, setIsFlagEditModalOpen] = useState(false);
  const [flagStatus, setFlagStatus] = useState(null);
  const [failedTests, setFailedTests] = useState([]);
  const [isCheckingFlag, setIsCheckingFlag] = useState(false);

  const checkResponseFlag = async () => {
    setIsCheckingFlag(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BLAND_ENDPOINT}/v1/pathway/qa`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: user.api_key,
          },
          body: JSON.stringify({
            chat_history: decision["Conversation History"],
            prompt: decision["Global Prompt"],
            response: decision["Assistant Response"],
            unitTests: decision["unitTests"],
          }),
        },
      );

      if (!response.ok) {
        console.log("Error checking response flag:", response.statusText);
        setFlagStatus(false);
      }

      const data = await response.json();
      setFlagStatus(data.flag);
      setFailedTests(data.failedTests || []);
    } catch (error) {
      console.error("Error checking response flag:", error);
      toast.error("Failed to check response flag");
    } finally {
      setIsCheckingFlag(false);
    }
  };

  const handleConfirmFlag = () => {
    setIsFlagEditModalOpen(true);
  };

  useEffect(() => {
    if (enableQA) {
      checkResponseFlag();
    }
  }, []);

  let filteredEdges = edges.filter(
    (edge) => edge.source === decision["Previous Node ID"],
  );

  nodes.forEach((node) => {
    if (node.data.globalLabel) {
      filteredEdges.push({
        id: node.id,
        data: {
          label: node.data.globalLabel,
        },
      });
    }
  });
  const excludeKeys = [
    "Conversation History",
    "Previous Node ID",
    "Current Node ID",
    "Global Prompt",
    "Dialogue Example",
    "unitTests"
  ];

  const handleChange = (key, value) => {
    if (!editedField || editedField === key) {
      setEditedDecision({
        ...editedDecision,
        [key]: value,
      });
      if (value === "true" || value === "false") {
        value = JSON.parse(value);
      }
      if (value === initialDecision[key]) {
        setEditedField(null);
      } else {
        setEditedField(key);
      }
    }
  };

  const handleSave = () => {
    let isChangeMade = false;
    const decisionKeyMapping = {
      "Assistant Response": {
        nodeDataKey: "dialogueExamples",
        nodeIdKey: "Current Node ID",
      },
      "Chosen Pathway": {
        nodeDataKey: "pathwayExamples",
        nodeIdKey: "Previous Node ID",
      },
      "Condition Achieved": {
        nodeDataKey: "conditionExamples",
        nodeIdKey: "Previous Node ID",
      },
    };

    let updatedNodes = [...nodes];

    Object.keys(editedDecision).forEach((key) => {
      if (
        editedDecision[key] !== initialDecision[key] &&
        key in decisionKeyMapping
      ) {
        const { nodeDataKey, nodeIdKey } = decisionKeyMapping[key];

        if (nodeDataKey && nodeIdKey) {
          updatedNodes = updatedNodes.map((node) => {
            if (node.id === initialDecision[nodeIdKey]) {
              isChangeMade = true;
              return {
                ...node,
                data: {
                  ...node.data,
                  [nodeDataKey]: [
                    ...(node.data[nodeDataKey] || []),
                    {
                      "Conversation History": decision["Conversation History"],
                      [key]: editedDecision[key],
                    },
                  ],
                },
              };
            } else {
              return node;
            }
          });
        }
      }
    });

    triggerUpdate({ nodes: updatedNodes }, false);

    setOpenEditDecision(false);
    if (!isChangeMade) {
      toast.warn("No changes made to the decision.");
      return;
    }
    toast.success(
      "Fine-tuning done successfully. Press Save to apply changes.",
      {
        position: "bottom-right",
      },
    );
  };

  const renderDecisionIcon = (value) => {
    if (typeof value === "boolean") {
      return value ? (
        <svg
          className="w-4 h-4 text-green-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M5 13l4 4L19 7"
          />
        </svg>
      ) : (
        <svg
          className="w-4 h-4 text-red-500"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      );
    }
    return null;
  };
  const renderFieldIcon = (key) => {
    switch (key) {
      case "Condition Achieved":
        return (
          <svg
            className="w-5 h-5 text-blue-500 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
      case "Chosen Pathway":
        return (
          <svg
            className="w-5 h-5 text-green-500 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 5l7 7-7 7M5 5l7 7-7 7"
            />
          </svg>
        );
      case "Assistant Response":
        return (
          <svg
            className="w-5 h-5 text-purple-500 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
            />
          </svg>
        );
      case "Current Node Name":
        return (
          <svg
            className="w-5 h-5 text-yellow-500 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
      case "Previous Node Name":
        return (
          <svg
            className="w-5 h-5 text-indigo-500 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
            />
          </svg>
        );
      default:
        return (
          <svg
            className="w-5 h-5 text-gray-500 mr-2"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        );
    }
  };

  return (
    <>
      <div className="bg-gray-50 rounded-sm shadow-md p-4 border border-gray-200">
        <div className="flex items-center justify-between mb-3">
          <span className="bg-blue-200 text-gray-900 text-xs font-bold px-2 py-1 rounded-sm uppercase">
            Pathway Decision Info
          </span>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setOpenEditDecision(true)}
            className="flex items-center space-x-2"
          >
            <span>Fine-Tune Decisions</span>
            <EditIcon className="w-3 h-3" />
          </Button>
        </div>
        <p className="text-xs text-gray-500 mb-3">
          Click on the Flag/Edit button to report issues or modify the
          assistant's response
        </p>
        <div className="space-y-2">
          <div className="space-y-3">
            {Object.keys(decision)
              .filter((key) => !excludeKeys.includes(key))
              .map((key) => (
                <div
                  key={key}
                  className="border border-gray-200 bg-white p-2 rounded-md shadow-sm"
                >
                  <div className="flex items-center mb-2">
                    <div className="flex items-center min-w-[150px]">
                      {renderFieldIcon(key)}
                      <span className="font-medium text-gray-700 capitalize mr-2 text-sm">
                        {key}:
                      </span>
                    </div>
                    <span className="text-gray-900 flex items-center text-xs flex-grow">
                      {renderDecisionIcon(decision[key])}
                      <span className="ml-2 font-mono bg-gray-100 px-2 py-1 rounded">
                        {typeof decision[key] === "boolean"
                          ? decision[key].toString()
                          : decision[key]}
                      </span>
                    </span>
                  </div>
                  {key === "Assistant Response" && (
                    <div className="mt-2 flex items-center justify-between">
                      <div className="flex items-center">
                        {isCheckingFlag ? (
                          <span className="text-sm text-gray-500">
                            Checking...
                          </span>
                        ) : flagStatus === true ? (
                          <div className="flex flex-col space-y-2">
                            <div className="flex items-center space-x-2">
                              <AlertTriangle className="w-4 h-4 text-yellow-500" />
                              <span className="text-sm text-yellow-600">
                                Potential issue detected
                              </span>
                              <Button
                                variant="outline"
                                size="sm"
                                onClick={handleConfirmFlag}
                              >
                                <FlagIcon className="w-4 h-4 mr-2" />
                                Flag
                              </Button>
                            </div>
                            {failedTests.length > 0 && (
                              <div className="text-sm text-red-600">
                                <span className="font-semibold">Failed unit tests:</span>
                                <ul className="list-disc list-inside ml-2">
                                  {failedTests.map((test, index) => (
                                    <li key={index}>{test}</li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        ) : flagStatus === false ? (
                          <div className="flex items-center space-x-2">
                            <CheckCircle className="w-4 h-4 text-green-500" />
                            <span className="text-sm text-green-500">
                              Response looks good
                            </span>
                          </div>
                        ) : null}
                      </div>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => setIsFlagEditModalOpen(true)}
                        className="flex items-center space-x-2"
                      >
                        <Flag className="w-4 h-4" />
                        <Edit className="w-4 h-4" />
                        <span className="text-sm font-medium">
                          Flag/Edit Response
                        </span>
                      </Button>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
      {openEditDecision && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center overflow-y-auto">
          <div className="bg-white border border-blue-50 rounded-sm shadow-xl p-6 m-4 max-w-3xl w-full max-h-[90vh] overflow-y-auto">
            <h2 className="text-2xl font-bold mb-4 text-gray-800 border-b pb-2">
              Fine-tune Pathway Decision
            </h2>
            <p className="mb-6 text-sm text-gray-600 leading-relaxed">
              Below are the decisions the agent has made. Edit incorrect
              decisions to improve the agent's decision making. You can also
              tweak correct decisions if needed. Note: Only 1 fine-tuning
              improvement can be made per decision; other fields will be
              disabled when one field is changed.
            </p>
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Condition Achieved (Prev Node -{" "}
                  {decision["Previous Node Name"]}):
                </label>
                <div className="relative">
                  <select
                    className="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 border focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md bg-white shadow-sm"
                    value={editedDecision["Condition Achieved"].toString()}
                    onChange={(e) =>
                      handleChange("Condition Achieved", e.target.value)
                    }
                    disabled={
                      editedField && editedField !== "Condition Achieved"
                    }
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                  {/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div> */}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Chosen Pathway (Prev Node - {decision["Previous Node Name"]}):
                </label>
                <div className="relative">
                  <select
                    className="block w-full pl-3 pr-10 py-2 text-sm border border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 rounded-md bg-white shadow-sm"
                    value={editedDecision["Chosen Pathway"]}
                    onChange={(e) =>
                      handleChange("Chosen Pathway", e.target.value)
                    }
                    disabled={editedField && editedField !== "Chosen Pathway"}
                  >
                    {filteredEdges.map((edge) => (
                      <option key={edge.id} value={edge.data.label}>
                        {edge.data.label}
                      </option>
                    ))}
                    <option value="none of the above">None of the above</option>
                  </select>
                  {/* <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div> */}
                </div>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Assistant Response (Current Node -{" "}
                  {decision["Current Node Name"]}):
                </label>
                <textarea
                  className="w-full border border-gray-500 px-3 py-2 text-sm rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 bg-white"
                  rows="4"
                  value={editedDecision["Assistant Response"]}
                  onChange={(e) =>
                    handleChange("Assistant Response", e.target.value)
                  }
                  disabled={editedField && editedField !== "Assistant Response"}
                />
              </div>
            </div>
            <div className="mt-6 flex justify-end space-x-3">
              <button
                className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={() => setOpenEditDecision(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      <FlagEditModal
        isOpen={isFlagEditModalOpen}
        onClose={() => setIsFlagEditModalOpen(false)}
        decision={decision}
        nodes={nodes}
        setChatConversationHistory={setChatConversationHistory}
        setChatStartNode={setChatStartNode}
        triggerUpdate={triggerUpdate}
        callID={callID}
        graphID={graphID}
        flaggedBySystem={flagStatus === true}
      />
    </>
  );
};

export default DecisionLogs;
