import Input from "components/core/Input";
import LightTooltip from "./ToolTipDesc";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export default function TransferNumber({ fieldName }) {
  const description = (
    <span>
      A phone number that the agent can transfer to under specific conditions -
      such as being asked to speak to a human or supervisor.
      <br></br>
      <br></br>
      <b>For best results:</b>
      <br></br>
      Specify conditions that the agent should transfer to a human under
      (examples are great!) In the task, refer to the action solely as
      “transfer” or “transferring”. Alternate phrasing such as “swap” or
      “switch” can mislead the agent, causing the action to be ignored.
    </span>
  );
  return (
    <Input
      fieldName={fieldName}
      label={
        <span>
          Transfer number
          <LightTooltip title={description} placement="right-start" arrow>
            <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
          </LightTooltip>
        </span>
      }
      type="tel"
      placeholder="555-555-5555"
    />
  );
}
