import React from "react";
import {
  X,
  Phone,
  PhoneOutgoing,
  Clock,
  DollarSign,
  AlertCircle,
  Calendar,
  User,
  Hash,
  FileText,
  Check,
  Tags,
  ChevronRight,
} from "lucide-react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Variables from "components/Dashboard/CallLogs/Variables";

export const pathwayTags = (pathway_tags) => {
  const tags = pathway_tags || null;

  if (!tags || tags.length === 0) return null;
  const uniqueTags = [];
  const seenTags = new Set();

  tags.forEach((tag) => {
    if (!seenTags.has(tag.name)) {
      uniqueTags.push(tag);
      seenTags.add(tag.name);
    }
  });

  return uniqueTags?.reverse();
};

const CallDetailsSlider = ({ callData, onClose }) => {
  return (
    <DetailsWrapper
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
    >
      <div
        style={{ zIndex: 1000000 }}
        className="p-4 border-b border-gray-200 flex justify-between items-center sticky top-0 bg-white"
      >
        <h2 className="text-xl font-semibold text-gray-800">Call Details</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <X size={24} />
        </button>
      </div>
      <DetailsContent>
        <StatusBanner status={callData.status} />
        <InfoSection>
          {callData?.pathway_tags && callData?.pathway_tags !== "-" && (
            <TagsItem
              icon={<Tags size={16} />}
              label="Pathway Tags"
              tags={pathwayTags(callData?.pathway_tags)}
            />
          )}
          <InfoItem
            icon={<Calendar size={16} />}
            label="Created On"
            value={callData.created_at}
          />
          <InfoItem icon={<Phone size={16} />} label="To" value={callData.to} />
          <InfoItem
            icon={<Phone size={16} />}
            label="From"
            value={callData.from}
          />
          <InfoItem
            icon={<Clock size={16} />}
            label="Call Length"
            value={callData.call_length}
          />
          <InfoItem
            icon={<PhoneOutgoing size={16} />}
            label="Direction"
            value={callData.inbound}
          />
          <InfoItem
            icon={<PhoneOutgoing size={16} />}
            label="Transferred To"
            value={callData.transferred_to}
          />
          <InfoItem
            icon={<DollarSign size={16} />}
            label="Cost"
            value={callData.cost}
          />
        </InfoSection>
        <InfoSection>
          <InfoItem
            icon={<Hash size={16} />}
            label="Batch ID"
            value={callData.batch_id}
          />
          <InfoItem
            icon={<Hash size={16} />}
            label="Pathway ID"
            value={callData.pathway_id}
          />
          <InfoItem
            icon={<FileText size={16} />}
            label="Pathway Name"
            value={callData.pathway_name}
          />
          <InfoItem
            icon={<User size={16} />}
            label="Answered By"
            value={callData.answered_by}
          />
          <InfoItem
            icon={<User size={16} />}
            label="Call Ended By"
            value={callData.call_ended_by}
          />
        </InfoSection>
        <InfoSection>
          <InfoItem
            icon={<AlertCircle size={16} />}
            label="Error Message"
            value={callData.error_message}
            isError
          />
        </InfoSection>
        <InfoSection>
          <Variables focusedVariables={callData.variables} modal />
        </InfoSection>
      </DetailsContent>
    </DetailsWrapper>
  );
};

const DetailsWrapper = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 60;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const DetailsContent = styled.div`
  padding: 24px;
  overflow-y: auto;
  flex-grow: 1;
`;

const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
`;

const InfoItem = ({ icon, label, value, isError = false }) => (
  <div className="flex items-center gap-3">
    {icon && (
      <span
        className={`text-gray-400 ${isError && value !== "-" ? "text-red-500" : ""}`}
      >
        {icon}
      </span>
    )}
    <span className="font-medium text-gray-700 min-w-[120px]">{label}:</span>
    <span
      className={`text-gray-600 break-all ${isError && value !== "-" ? "text-red-500" : ""}`}
    >
      {value}
    </span>
  </div>
);

const TagsItem = ({ icon, label, tags, isError = false }) => (
  <div className="flex items-center gap-3" style={{ flexWrap: "wrap" }}>
    {icon && (
      <span className={`text-gray-400 ${isError ? "text-red-500" : ""}`}>
        {icon}
      </span>
    )}
    <span className="font-medium text-gray-700 min-w-[120px]">{label}:</span>
    <div className="flex flex-wrap gap-2 items-center">
      {tags?.map((tag, id) => (
        <>
          <span
            key={id}
            className="bg-gray-100 text-gray-800 text-xs px-4 py-1 rounded-full flex items-center gap-2"
          >
            <div
              style={{
                height: 10,
                width: 10,
                borderRadius: "50%",
                background: tag.color,
              }}
            />
            <p>{tag.name}</p>
          </span>
          {/* {tags?.length > 0 && id !== tags?.length - 1 && (
            <ChevronRight size={15} />
          )} */}
        </>
      ))}
    </div>
  </div>
);

const StatusBanner = ({ status }) => (
  <div
    className={`mb-6 p-3 rounded-md flex items-center gap-2 ${
      status === "Completed"
        ? "bg-green-100 text-green-700"
        : "bg-yellow-100 text-yellow-700"
    }`}
  >
    {status === "Completed" ? <Check size={20} /> : <Clock size={20} />}
    <span className="font-medium">{status}</span>
  </div>
);

export default CallDetailsSlider;
