import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { STRIPE_PUBLIC_KEY } from "config/stripe";
import CheckoutForm from "./CheckoutForm";
import { Loading } from "components/core/Loading";

export default function Checkout({ clientSecret, amount }) {
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  const loading = clientSecret ? false : true;

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        clientSecret && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm amount={amount} />
          </Elements>
        )
      )}
    </>
  );
}
