import axios from "axios";
import { getAuthToken } from "../browser/getAuthToken";

export const fetchTranscript = async (callId) => {
  try {
    const token = getAuthToken();
    const headers = {
      authorization: `${token}`,
    };
    const { data } = await axios.post(
      "/api/user/transcript",
      { call_id: callId },
      { headers },
    );
    if (data) {
      return data;
    }
  } catch (err) {
    console.error("Error fetching transcript", err);
    return;
  }
};

export const fetchAndUpdateTranscriptStatus = async (callId, txId, status) => {
  try {
    const token = getAuthToken();
    const headers = {
      authorization: `${token}`,
    };

    const { data } = await axios.post(
      "/api/user/transcript_status",
      { callId, txId, status },
      { headers },
    );

    if (data) return data;
  } catch (error) {
    console.error("");
    return;
  }
};
