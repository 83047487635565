import axios from "axios";

export const deleteVoice = async (voice, apiKey, userId) => {
  try {
    const { data } = await axios.post(
      "https://cloning.bland.ai/delete",
      {
        voice_id: voice.voicea_id,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: apiKey,
          user_id: userId,
        },
      },
    );
    return data;
  } catch (error) {
    console.error("Error deleting voice:", error);
  }
};
