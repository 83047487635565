import React, { useState, useEffect, useContext } from "react";
import { Dialog } from "@headlessui/react";
import FlowContext from "../contextFlow";
import { getUserData } from "utils/funcs/browser/getUserData";
import AlertBox from "components/core/AlertBox";
import { Loading } from "components/core/Loading";

const SMSNodeModal = ({ id, data, setIsOpen }) => {
  const [name, setName] = useState(data.name || "");
  const [message, setMessage] = useState(data.message || "");
  const [fromNumber, setFromNumber] = useState(data.fromNumber || "");
  const [hasAccess, setHasAccess] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const maxCharCount = 306;

  const user = getUserData();
  const { triggerUpdate, elements } = useContext(FlowContext);

  useEffect(() => {
    const checkAccess = async () => {
      try {
        const response = await fetch(`/api/convo_pathway/sms_access`, {
          method: "GET",
          headers: {
            authorization: user.api_key,
          },
        });

        if (!response.ok) {
          console.error(
            "Failed to check enterprise access:",
            response.statusText,
          );
          return;
        }
        const access = await response.json();
        console.log("access", access);
        setHasAccess(access.enabled);
      } catch (error) {
        console.error("Failed to check enterprise access:", error);
      } finally {
        setIsLoading(false);
      }
    };

    checkAccess();
  }, [user.api_key]);

  const handleSave = () => {
    const updatedNodes = elements.nodes.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          data: { name, message, fromNumber, isStart: data.isStart ?? false },
          type: "SMS",
        };
      }
      return el;
    });

    setIsOpen(false);
    triggerUpdate({ nodes: updatedNodes }, false);
  };

  if (isLoading) {
    return (
      <Dialog.Description className="flex justify-center items-center h-40">
        <Loading loading={isLoading} />
      </Dialog.Description>
    );
  }

  if (!hasAccess) {
    return (
      <Dialog.Description className="space-y-4">
        <AlertBox
          type="warning"
          text="This feature is currently for enterprise users only. Contact us here to get on our enterprise plan"
        />
        <button
          className="px-4 py-3 bg-indigo-500 text-white hover:bg-indigo-800 rounded-md"
          onClick={() =>
            window.open("https://app.bland.ai/enterprise", "_blank")
          }
        >
          Contact Us
        </button>
      </Dialog.Description>
    );
  }

  return (
    <Dialog.Description className="space-y-4">
      <div className="space-y-2">
        <label
          htmlFor="name"
          className="block text-[12px] font-medium text-gray-700"
        >
          Name
        </label>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full px-2 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-[12px]"
          placeholder="Enter node name"
        />
      </div>

      <div className="space-y-2">
        <label
          htmlFor="fromNumber"
          className="block text-[12px] font-medium text-gray-700"
        >
          From Number (Optional)
        </label>
        <input
          type="text"
          id="fromNumber"
          value={fromNumber}
          onChange={(e) => setFromNumber(e.target.value)}
          className="w-full px-2 py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-[12px]"
          placeholder="Enter sender's phone number (optional)"
        />
      </div>

      <div className="space-y-2">
        <label
          htmlFor="message"
          className="block text-[12px] font-medium text-gray-700"
        >
          SMS Message
        </label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => setMessage(e.target.value.slice(0, maxCharCount))}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          rows={4}
          placeholder="Enter your SMS message here"
        ></textarea>
        <div className="text-right text-sm text-gray-500">
          {message.length}/{maxCharCount} characters
        </div>
      </div>

      <button
        onClick={handleSave}
        className="bg-indigo-500 text-white py-2 px-4 rounded-md mt-4"
      >
        Save
      </button>
    </Dialog.Description>
  );
};

export default SMSNodeModal;
