import RangeInput from "components/core/RangeInput";
import { useFormContext } from "react-hook-form";
import ProgressBar from "./fillbar";
import styled from "styled-components";
import { getUserData } from "utils/funcs/browser/getUserData";
import Tabs from "components/core/Tabs";
import { PageTitle } from "components/core/PageTitle";
import Button from "components/core/Button";
import PulsingLight from "../../../../assets/gifs/PulsingLight.gif";
import ServerIcon from "../../../../assets/icons/server-icon.svg";
import ServerIconPriv from "../../../../assets/icons/priv-server-icon.svg";
import StaticTable from "./static_table";
import FineTuneIcon from "../../../../assets/icons/ft-icon.svg";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import { getUser } from "utils/funcs/auth/getUser";
import axios from "axios";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";
import Modal from "components/core/Modal";
import CloseIcon from "../../../../assets/icons/close-icon-black.svg";

const RATE_LIMITS = [
  {
    value: "start",
    label: "Start",
    hourly: "100",
    hourly_val: 100,
    daily_val: 500,
    daily: "500",
    concurrent: "10",
    price: "Default",
    rate_limit_template: 5,
  },
  {
    value: "build",
    label: "Build",
    daily: "2,000",
    daily_val: 2000,
    hourly: "400",
    hourly_val: 400,
    concurrent: "50",
    price: "$299",
    rate_limit_template: 6,
  },
  {
    value: "scale",
    label: "Scale",
    daily: "5,000",
    daily_val: 5000,
    hourly_val: 1000,
    hourly: "1,000",
    concurrent: "100",
    price: "$499",
    rate_limit_template: 7,
  },
  {
    value: "enterprise",
    label: "Enterprise",
    daily: "Unlimited",
    daily_val: 30000,
    hourly_val: 10000,
    hourly: "Unlimited",
    concurrent: "Unlimited",
    price: "Contact Us",
    rate_limit_template: [2, 3],
  },
];

export const ServerAndLimits = () => {
  const [user, setUser] = useState(getUserData());
  const { setValue, getValues, watch } = useFormContext();
  const daily_rate_limit = watch("daily_rate_limit");
  const hourly_rate_limit = watch("hourly_rate_limit");
  const [user_rate_limit_template, set_user_rate_limit_template] = useState(
    user?.user?.rate_limit_template || null,
  );
  const [loadingIndex, setLoadingIndex] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [prePurchaseModal, setPrePurchaseModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [focusedPlan, setFocusedPlan] = useState({
    rateLimit: null,
    index: null,
  });

  // const [allCalls, setAllCalls] = useState([]);
  const [usageData, setUsageData] = useState({
    hourUsage: 0,
    dayUsage: 0,
    hourStr: "",
    dayStr: "",
  });

  const refreshUserData = async () => {
    const updatedUser = await getUser();
    localStorage.setItem("user", JSON.stringify(updatedUser));
    set_user_rate_limit_template(
      updatedUser?.user?.user?.rate_limit_template || null,
    );
    setUser(updatedUser);
  };

  const handleSubscribeToPlan = async (rateLimit, index) => {
    setLoadingIndex(index);
    if (rateLimit.rate_limit_template === user_rate_limit_template) {
      return await handleCancelFromPlan(index);
    }

    let type;
    type =
      rateLimit.rate_limit_template === 6
        ? "subscribe_build"
        : rateLimit.rate_limit_template === 7
          ? "subscribe_scale"
          : "";

    console.log({ type, rl: rateLimit.rate_limit_template });

    const response = await axios({
      url: "/api/infrastructure/subscribe",
      method: "POST",
      headers: {
        authorization: getAuthToken(),
        "content-type": "application/json",
      },
      data: {
        user_id: user?.user?.user?.id,
        type,
      },
      validateStatus: false,
    });

    if (response?.data) {
      if (response.status === 400) {
        setLoadingIndex(-1);

        if (response?.data?.message === "not_complete_billing") {
          window.location.href = "/dashboard?page=billing-details";
          return toast.error("Payment Method not set up.");
        }

        if (response?.data?.message === "payment_failed") {
          return toast.error("Payment Failed. Try again later.");
        }
      }

      if ((response.status === 200) & (response?.data?.status === "success")) {
        await refreshUserData();
        setLoadingIndex(-1);
        return toast.success("Successfully Scaled Up.");
      }
    }

    return setLoadingIndex(-1);
  };

  const handleCancelFromPlan = async (index) => {
    try {
      // 6 = Bland Build Infrastructure
      // 7 = Bland Scale Infrastructure
      let search_name;
      if (user_rate_limit_template === 6) {
        search_name = "Build Infrastructure";
      } else if (user_rate_limit_template === 7) {
        search_name = "Scale Infrastructure";
      } else {
        return;
      }

      const subscriptions = user?.user?.subscriptions;
      if (!subscriptions || subscriptions?.length === 0) return;

      const sub = subscriptions?.find(
        (doc) => doc.item === search_name && doc.status === "active",
      );
      if (!sub) return console.log("no active found");

      const response = await axios({
        url: "/api/infrastructure/cancel",
        method: "POST",
        headers: {
          authorization: getAuthToken(),
          "content-type": "application/json",
        },
        data: {
          sub_id: sub.id,
          user_id: user?.user?.user?.id,
        },
        validateStatus: false,
      });

      await refreshUserData();
      setLoadingIndex(-1);
      return toast.success("Canceled Subscription.");
    } catch (error) {
      setLoadingIndex(-1);
      return toast.error("Unexpected Error.");
    }
  };

  const fetchAllCalls = async () => {
    const data = await fetch(`https://us.api.bland.ai/v1/calls?limit=100`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: getApiKey(),
      },
    });
    const callData = await data.json();
    localStorage.setItem("calls", JSON.stringify(callData?.calls || {}));
    return callData?.calls;
  };

  const handleCalculateRateLimitUsage = async () => {
    try {
      setLoading(true);
      let calls;
      calls = localStorage.getItem("calls") || null;

      if (!calls || calls?.length === 0) {
        calls = JSON.stringify((await fetchAllCalls()) || {});
        console.log({ calls, fetch: true });
      }

      const parsedCalls = JSON.parse(calls || {});
      if (Object.keys(parsedCalls).length === 0)
        return console.log("CALLS_EMPTY");
      if (!user_rate_limit_template)
        return console.log("NO_USER_RL_TEMPLATE_FOUND");
      const template = RATE_LIMITS?.find((doc) => {
        if (user_rate_limit_template === 2 || user_rate_limit_template === 3)
          return doc.value === "enterprise";
        else return doc.rate_limit_template === user_rate_limit_template;
      });
      if (!template) return console.log("NO_TEMPLATE_FOUND");

      const today = new Date().toISOString().slice(0, 10); // Get today's date in YYYY-MM-DD format
      const todayCalls = parsedCalls?.filter(
        (call) => call.created_at.slice(0, 10) === today,
      );

      function filterCallsForCurrentHour(calls) {
        const now = new Date();
        const currentYear = now.getUTCFullYear();
        const currentMonth = now.getUTCMonth(); // Note: getUTCMonth() returns 0-11
        const currentDate = now.getUTCDate();
        const currentHour = now.getUTCHours();

        return calls.filter((call) => {
          const callDate = new Date(call.created_at);
          return (
            callDate.getUTCFullYear() === currentYear &&
            callDate.getUTCMonth() === currentMonth &&
            callDate.getUTCDate() === currentDate &&
            callDate.getUTCHours() === currentHour
          );
        });
      }

      const hourCalls = filterCallsForCurrentHour(todayCalls);
      const dayUsage = Number(
        ((todayCalls?.length / template.daily_val) * 100).toFixed(2),
      );
      const hourUsage = Number(
        ((hourCalls?.length / template.hourly_val) * 100).toFixed(2),
      );

      return setUsageData({
        hourUsage,
        dayUsage,
        dayStr: `${todayCalls?.length} of ${template.daily} Calls`,
        hourStr: `${hourCalls?.length} of ${template.hourly} Calls`,
      });
    } catch (error) {}
  };

  const checkIfDisabled = (rl, index) => {
    // index === 0;
    /* rl = {
            value: "start",
            label: "Start",
            hourly: "100",
            hourly_val: 100,
            daily_val: 500,
            daily: "500",
            concurrent: "1",
            price: "Default",
            rate_limit_template: 5
        }*/

    let state = "Contact Us";
    let isDisabled = false;

    if (!user_rate_limit_template)
      return console.log("NO_USER_RL_TEMPLATE_FOUND");
    const user_act_rl_template = RATE_LIMITS?.find((doc) => {
      if (user_rate_limit_template === 2 || user_rate_limit_template === 3)
        return doc.value === "enterprise";
      else return doc.rate_limit_template === user_rate_limit_template;
    });

    if (!user_act_rl_template) return console.log("NO_TEMPLATE_FOUND");

    if (index === 3 && user_rate_limit_template === 2) {
      return { state: "On Enterprise Plan", isDisabled: true };
    }

    if (
      Array.isArray(user_act_rl_template.rate_limit_template) &&
      index === 3
    ) {
      return { state: "Contact Us", isDisabled: false };
    }

    if (
      // check to see if matches current index
      rl.rate_limit_template === user_act_rl_template.rate_limit_template
    ) {
      if (rl.value === "start") {
        // user is on base plan, cant cancel.
        state = "Base Plan";
        isDisabled = true;
      } else {
        // if (rl.rate_limit_template?.includes())
        // user isnt on base plan, can cancel
        state = "Cancel Plan";
        isDisabled = false;
      }
    } else {
      if (
        index !== 3 &&
        Array.isArray(user_act_rl_template.rate_limit_template)
      ) {
        return { state: "Lower Scale", isDisabled: false };
      }
      if (user_act_rl_template.rate_limit_template > rl.rate_limit_template) {
        state = "Lower Scale Plan";
        isDisabled = true;
      } else if (
        user_act_rl_template.rate_limit_template < rl.rate_limit_template
      ) {
        state = "Increase Scale";
        isDisabled = false;
      }
    }

    return { state, isDisabled };
  };

  // const { hourUsage, dayUsage, dayStr, hourStr } = handleCalculateRateLimitUsage();

  useEffect(() => {
    handleCalculateRateLimitUsage().finally(() => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    refreshUserData();
  }, []);

  // if (usageData.hourUsage === null || usageData.hourUsage === NaN || usageData.hourUsage === undefined || usageData.dayUsage === null || usageData.dayUsage === NaN || usageData.dayUsage === undefined) return <div />;
  if (!user_rate_limit_template) return <div />;
  if (loading) return <div />;

  return (
    <Wrapper style={{ display: "flex", flexDirection: "column", gap: 10 }}>
      <PageTitle style={{ fontSize: 16, fontWeight: "550" }}>
        Account Usage
      </PageTitle>

      {usageData.hourUsage === null ||
      usageData.hourUsage === NaN ||
      usageData.hourUsage === undefined ||
      usageData.dayUsage === null ||
      usageData.dayUsage === NaN ||
      usageData.dayUsage === undefined ? (
        <div />
      ) : (
        <div
          style={{
            padding: 10,
            borderRadius: 1,
            border: "2px solid #dee2e6",
            background: "#f8f9fa",
            display: "flex",
            flexDirection: "column",
            gap: 10,
            marginTop: 5,
          }}
        >
          <BarBox style={{ marginTop: 0 }}>
            <div style={{ display: "flex" }}>
              <Description
                style={{
                  padding: "2px 6px",
                  background: "#eeeeee",
                  borderRadius: 3,
                  fontSize: 11,
                  fontWeight: 550,
                }}
              >
                Daily Rate Limit
              </Description>
            </div>
            <ProgressBar percent={usageData.dayUsage} />
            <p
              style={{
                fontSize: 10,
                fontWeight: "500",
                color: "darkgrey",
                marginTop: -5,
                marginLeft: 2,
              }}
            >
              {usageData.dayStr} ({usageData.dayUsage}%)
            </p>
          </BarBox>

          <BarBox>
            <div style={{ display: "flex" }}>
              <Description
                style={{
                  padding: "2px 6px",
                  background: "#eeeeee",
                  borderRadius: 3,
                  fontSize: 11,
                  fontWeight: 550,
                }}
              >
                Hourly Rate Limit
              </Description>
            </div>
            <ProgressBar percent={usageData.hourUsage} />
            <p
              style={{
                fontSize: 10,
                fontWeight: "500",
                color: "darkgrey",
                marginTop: -5,
                marginLeft: 2,
              }}
            >
              {usageData.hourStr} ({usageData.hourUsage}%)
            </p>
          </BarBox>
        </div>
      )}

      <div style={{ marginTop: 10 }}>
        <Tabs
          relative
          tabs={[
            {
              item: "Limits",
              panel: (
                <Container>
                  {RATE_LIMITS.map((rateLimit, index) => (
                    <Box
                      key={index}
                      style={{
                        border:
                          (user_rate_limit_template === 2 ||
                            user_rate_limit_template === 3 ||
                            user_rate_limit_template === 8) &&
                          rateLimit?.value === "enterprise"
                            ? "1px solid #3a86ff"
                            : "1px solid #eeeeee",

                        boxShadow:
                          (user_rate_limit_template === 2 ||
                            user_rate_limit_template === 3 ||
                            user_rate_limit_template === 8) &&
                          rateLimit?.value === "enterprise"
                            ? "0px 0px 10px 0px rgba(58,134,255,0.5)"
                            : "none",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                      >
                        <PageTitle style={{ fontSize: 16, fontWeight: "550" }}>
                          {rateLimit.label}
                        </PageTitle>
                        {(rateLimit.rate_limit_template ===
                          user_rate_limit_template ||
                          user_rate_limit_template === 2 ||
                          user_rate_limit_template === 3) && (
                          <img src={PulsingLight} height={14} width={14} />
                        )}
                      </div>
                      <div
                        id="daily-row"
                        style={{
                          borderTop: "2px solid #eeeeee",
                          borderLeft: "2px solid #eeeeee",
                          borderRight: "2px solid #eeeeee",
                          marginTop: 25,
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            padding: 10,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              padding: "2px 6px",
                              background: "#eeeeee",
                            }}
                          >
                            <PageTitle
                              style={{
                                fontSize: 9,
                                fontWeight: "450",
                                color: "grey",
                              }}
                            >
                              Daily Cap
                            </PageTitle>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p
                              style={{
                                color: "#000",
                                fontSize: 12,
                                fontWeight: "450",
                              }}
                            >
                              {rateLimit.daily}&nbsp;
                            </p>
                            <p
                              style={{
                                color: "grey",
                                fontSize: 10,
                                fontWeight: "400",
                              }}
                            >
                              calls
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        id="hourly-row"
                        style={{
                          borderTop: "1px solid #eeeeee",
                          borderLeft: "2px solid #eeeeee",
                          borderRight: "2px solid #eeeeee",
                          marginTop: 0,
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            padding: 10,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              padding: "2px 6px",
                              background: "#eeeeee",
                            }}
                          >
                            <PageTitle
                              style={{
                                fontSize: 9,
                                fontWeight: "450",
                                color: "grey",
                              }}
                            >
                              Hourly Cap
                            </PageTitle>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p
                              style={{
                                color: "#000",
                                fontSize: 12,
                                fontWeight: "450",
                              }}
                            >
                              {rateLimit.hourly}&nbsp;
                            </p>
                            <p
                              style={{
                                color: "grey",
                                fontSize: 10,
                                fontWeight: "400",
                              }}
                            >
                              calls
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        id="concurrency-row"
                        style={{
                          borderTop: "1px solid #eeeeee",
                          borderLeft: "2px solid #eeeeee",
                          borderRight: "2px solid #eeeeee",
                          marginTop: 0,
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            padding: 10,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              padding: "2px 6px",
                              background: "#eeeeee",
                            }}
                          >
                            <PageTitle
                              style={{
                                fontSize: 9,
                                fontWeight: "450",
                                color: "grey",
                              }}
                            >
                              Concurrency
                            </PageTitle>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p
                              style={{
                                color: "#000",
                                fontSize: 12,
                                fontWeight: "450",
                              }}
                            >
                              {rateLimit.concurrent}&nbsp;
                            </p>
                            <p
                              style={{
                                color: "grey",
                                fontSize: 10,
                                fontWeight: "400",
                              }}
                            >
                              calls
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        id="concurrency-row"
                        style={{
                          borderTop: "1px solid #eeeeee",
                          borderLeft: "2px solid #eeeeee",
                          borderRight: "2px solid #eeeeee",
                          borderBottom: "2px solid #eeeeee",
                          marginTop: 0,
                          overflow: "hidden",
                        }}
                      >
                        <div
                          style={{
                            padding: 10,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{
                              padding: "2px 6px",
                              background: "#eeeeee",
                            }}
                          >
                            <PageTitle
                              style={{
                                fontSize: 9,
                                fontWeight: "450",
                                color: "grey",
                              }}
                            >
                              Cost
                            </PageTitle>
                          </div>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <p
                              style={{
                                color: "#000",
                                fontSize: 12,
                                fontWeight: "450",
                              }}
                            >
                              {rateLimit.price}&nbsp;
                            </p>
                            <p
                              style={{
                                color: "grey",
                                fontSize: 10,
                                fontWeight: "400",
                              }}
                            >
                              {rateLimit.price === "Default" ||
                              rateLimit.price === "Contact Us"
                                ? ""
                                : "/ month"}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div style={{ marginTop: 20 }}>
                        <Button
                          loading={loadingIndex === index}
                          onClick={() => {
                            if (index === 3) {
                              window.location.href = "/enterprise";
                            } else {
                              setFocusedPlan({
                                rateLimit,
                                index,
                              });

                              if (
                                rateLimit.rate_limit_template ===
                                user_rate_limit_template
                              ) {
                                setCancelModal(true);
                              } else {
                                setPrePurchaseModal(true);
                              }
                              // handleSubscribeToPlan(rateLimit, index)
                            }
                          }}
                          disabled={
                            checkIfDisabled(rateLimit, index).isDisabled
                          }
                          appearance={
                            checkIfDisabled(rateLimit, index)?.isDisabled &&
                            "outline"
                          }
                          style={{ borderRadius: 2 }}
                        >
                          <p>{checkIfDisabled(rateLimit, index).state}</p>
                        </Button>
                      </div>
                    </Box>
                  ))}
                </Container>
              ),
            },
            {
              item: "Dedicated Infra",
              panel: (
                <InfraContainer>
                  <StaticTable />
                  <PageTitle
                    style={{ fontSize: 10, fontWeight: "550", marginTop: 0 }}
                  >
                    Fine-Tuning
                  </PageTitle>
                  <div
                    style={{
                      width: "100%",
                      height: 2,
                      background: "#eeeeee",
                      marginTop: -20,
                    }}
                  />

                  <div
                    style={{
                      padding: "0px",
                      border: "none",
                      borderRadius: 1,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: -15,
                    }}
                  >
                    <div
                      id="image-box"
                      style={{
                        padding: "5px 5px",
                        background: "#eeeeee",
                        height: 24,
                        width: 24,
                      }}
                    >
                      <img
                        src={FineTuneIcon}
                        style={{ height: 14, width: 14 }}
                      />
                    </div>

                    <div style={{ marginLeft: "25px", maxWidth: "75%" }}>
                      <PageTitle style={{ fontWeight: "500", fontSize: 16 }}>
                        Automatic Fine-Tuning
                      </PageTitle>
                      <p
                        style={{
                          fontSize: 13,
                          fontWeight: "400",
                          color: "grey",
                        }}
                      >
                        Automatically have Bland fine-tune Inference, STT and
                        TTS models based on your call recordings and data on
                        Bland.
                      </p>
                    </div>

                    <Button
                      onClick={() => {
                        window.location.href = "/enterprise";
                      }}
                      style={{
                        borderRadius: 6,
                        height: 30,
                        width: 60,
                        marginLeft: "auto",
                      }}
                    >
                      <p style={{ fontSize: 13, fontWeight: "600" }}>Enable</p>
                    </Button>
                  </div>
                </InfraContainer>
              ),
            },
          ]}
        />
      </div>

      <Modal open={prePurchaseModal} onClose={() => setPrePurchaseModal(false)}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p style={{ margin: 0, padding: 0, fontWeight: "500", fontSize: 16 }}>
            Confirm Purchase
          </p>
          <img
            onClick={() => setPrePurchaseModal(false)}
            style={{ cursor: "pointer" }}
            src={CloseIcon}
          />
        </div>
        <p style={{ color: "grey", fontSize: 13, fontWeight: "450" }}>
          This is a non-refundable purchase.
        </p>

        <div
          style={{
            border: "1px solid #eeeeee",
            borderRadius: 2,
            marginTop: 15,
            padding: "10px 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={ServerIcon} height={18} width={18} />

            <div style={{ marginLeft: 20 }}>
              <p style={{ fontSize: 14, fontWeight: "500" }}>
                {focusedPlan?.rateLimit?.label} Infrastructure
              </p>
              <p style={{ fontSize: 11, fontWeight: "450", color: "grey" }}>
                {focusedPlan?.rateLimit?.daily} calls per day |{" "}
                {focusedPlan?.rateLimit?.hourly} calls per hour
              </p>
            </div>

            <div style={{ marginLeft: "auto" }}>
              <p style={{ fontSize: 14, fontWeight: "500" }}>
                {focusedPlan?.rateLimit?.price}/m
              </p>
            </div>
          </div>
        </div>

        <Button
          onClick={() => {
            handleSubscribeToPlan(focusedPlan?.rateLimit, focusedPlan?.index);
            setPrePurchaseModal(false);
            setFocusedPlan({ rateLimit: null, index: null });
          }}
          style={{ borderRadius: 6, height: 35, marginTop: 20 }}
        >
          <p style={{ fontSize: 14, fontWeight: "550" }}>Confirm Purchase</p>
        </Button>
      </Modal>

      <Modal open={cancelModal} onClose={() => setCancelModal(false)}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p style={{ margin: 0, padding: 0, fontWeight: "500", fontSize: 16 }}>
            Cancel Plan
          </p>
          <img
            onClick={() => setPrePurchaseModal(false)}
            style={{ cursor: "pointer" }}
            src={CloseIcon}
          />
        </div>
        <p style={{ color: "grey", fontSize: 13, fontWeight: "450" }}>
          You will not be able to undo this once complete. Your plan will
          automatically be downgraded to START plan.
        </p>

        <div
          style={{
            border: "1px solid #eeeeee",
            borderRadius: 2,
            marginTop: 15,
            padding: "10px 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img src={ServerIcon} height={18} width={18} />

            <div style={{ marginLeft: 20 }}>
              <p style={{ fontSize: 14, fontWeight: "500" }}>
                {focusedPlan?.rateLimit?.label} Infrastructure
              </p>
              <p style={{ fontSize: 11, fontWeight: "450", color: "grey" }}>
                {focusedPlan?.rateLimit?.daily} calls per day |{" "}
                {focusedPlan?.rateLimit?.hourly} calls per hour
              </p>
            </div>

            <div style={{ marginLeft: "auto" }}>
              <p style={{ fontSize: 14, fontWeight: "500" }}>Cancel</p>
            </div>
          </div>
        </div>

        <Button
          onClick={() => {
            setLoadingIndex(focusedPlan?.index);
            handleCancelFromPlan(focusedPlan?.index);
            setCancelModal(false);
            setFocusedPlan({ rateLimit: null, index: null });
          }}
          style={{
            borderRadius: 6,
            height: 35,
            marginTop: 20,
            background: "#e63946",
          }}
        >
          <p style={{ fontSize: 14, fontWeight: "550" }}>
            Cancel {focusedPlan?.rateLimit?.label} Plan
          </p>
        </Button>
      </Modal>
    </Wrapper>
  );
};

/*

                                <div id='shared-box' style={{
                                    border: '2px solid #eeeeee',
                                    padding: 20,
                                    marginTop: 20
                                }}>
                                    <div id='container' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div id='image-box' style={{
                                            padding: "5px 5px",
                                            background: "#eeeeee"
                                        }}>
                                            <img src={ServerIcon} style={{ height: 14, width: 14 }} />
                                        </div>

                                        <div id='text-container' style={{ marginLeft: 20 }}>
                                            <PageTitle style={{ fontWeight: 550, fontSize: 16 }}>Bland Public Server</PageTitle>
                                        </div>

                                        {true && (
                                            <img src={PulsingLight} height={16} width={16} style={{marginLeft:'auto'}}/>
                                        )}
                                    </div>
                                </div>
                                <div id='dedicated-box' style={{
                                    border: '2px solid #eeeeee',
                                    padding: 20,
                                    marginBottom:20
                                }}>
                                    <div id='container' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <div id='image-box' style={{
                                            padding: "5px 5px",
                                            background: "#eeeeee"
                                        }}>
                                            <img src={ServerIconPriv} style={{ height: 14, width: 14 }} />
                                        </div>

                                        <div id='text-container' style={{ marginLeft: 20 }}>
                                            <PageTitle style={{ fontWeight: 550, fontSize: 16 }}>Private Server</PageTitle>
                                        </div>
                                    </div>
                                </div>
*/

const Wrapper = styled.div`
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const InfraContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 25px;
  column-gap: 20px;
  gap: 20px;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 10px);
  height: 300px;
  border-radius: 1px;
  border: 2px solid #eeeeee;
  overflow-x: clip;
  padding: 20px;
`;

const Description = styled.p`
  margin: 0px;
  padding: 0px;
  color: darkgrey;
  font-size: 11px;
  font-weight: 400;
`;

const BarBox = styled.div`
  padding: 17px 15px;
  border-radius: 1px;
  border: 2px solid #eeeeee;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #fff;
`;
