import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";
import { toDtmy } from "utils/formatting/toDtmy";

function formatTimestamp(timeframe, timestamp) {
  // Parse the timestamp into a Date object
  const date = new Date(timestamp);

  // Helper function to format hours, minutes, and seconds into 12-hour format with AM/PM
  function format12HourTime(date) {
    let hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? "0" + minutes : minutes;
    const secondsStr = seconds < 10 ? "0" + seconds : seconds;
    return `${hours}:${minutesStr}:${secondsStr} ${ampm}`;
  }

  switch (timeframe.toLowerCase()) {
    case "hour":
    case "day":
      // Return the time in 12-hour format with seconds
      return format12HourTime(date);
    case "week":
      // Return the date part of the timestamp in YYYY-MM-DD format
      return date.toISOString().split("T")[0];
    default:
      throw new Error('Invalid timeframe. Use "hour", "day", or "week".');
  }
}

const ActivityBarGraph = ({
  data,
  timeframe,
  CustomLabel,
  toolTipLabel = "Total Calls",
  showxLabel = true,
  displayYAxis = false,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    if (chartRef && chartRef.current && data) {
      const labels = data.map((entry) => entry.time);
      const counts = data.map((entry) => entry.count);
      const ctx = chartRef.current.getContext("2d");

      const zeroLinePlugin = {
        id: "zeroLinePlugin",
        afterDatasetsDraw: (chart) => {
          const meta = chart.getDatasetMeta(0);
          const dataSet = chart.config.data.datasets[0].data;

          meta.data.forEach((d, index) => {
            if (dataSet[index] === 0) {
              const view = d;
              const context = chart.ctx;

              const startX = view.x - view.width / 2;
              context.beginPath();
              context.strokeStyle = "#aaaaaa";
              context.moveTo(startX, view.y);
              context.lineTo(startX + view.width, view.y);
              context.stroke();
            }
          });
        },
      };

      new Chart(ctx, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Count",
              data: counts,
              backgroundColor: "rgba(63,206,141,255)",
              borderColor: "rgba(63,206,141,255)",
              borderWidth: 1,
              minBarLength: 1,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  const label = context.dataset.label || "";
                  const value = context.raw;
                  return `${value} ${toolTipLabel}`;
                },
              },
            },
          },
          scales: {
            x: {
              display: false,
              title: {
                display: false,
                text: "Time",
              },
              grid: {
                display: false,
              },
            },
            y: {
              display: displayYAxis,
              title: {
                display: false,
                text: "Count",
              },
              grid: {
                display: false,
              },
            },
          },
        },
      });

      return () => {
        if (chartRef.current) {
          Chart.getChart(chartRef.current).destroy();
        }
      };
    }
  }, [data]);

  return (
    <div style={{ maxHeight: 320 }}>
      <div style={{ padding: "5px", marginTop: 50 }}>
        <canvas ref={chartRef} />
      </div>
      {showxLabel === true && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            marginTop: 10,
          }}
        >
          {data?.length > 0 && (
            <>
              <p
                style={{
                  textAlign: "left",
                  color: "darkgrey",
                  fontSize: 10,
                  fontWeight: "450",
                }}
              >
                {formatTimestamp(timeframe, data[0]?.time)}
              </p>
              <p
                style={{
                  textAlign: "right",
                  color: "darkgrey",
                  fontSize: 10,
                  fontWeight: "450",
                }}
              >
                {formatTimestamp(timeframe, data[data?.length - 1 || 0]?.time)}
              </p>
            </>
          )}
        </div>
      )}

      {showxLabel === false && CustomLabel && CustomLabel}
    </div>
  );
};

export default ActivityBarGraph;
