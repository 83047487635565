import ActionBar from "components/core/ActionBar";
import Button from "components/core/Button";
import { PageTitle } from "components/core/PageTitle";
import { PageWrapper } from "components/core/PageWrapper";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import BoxGroup from "./components/Analytics";
import CampaignTable from "./components/CampaignTable";
import TableView from "../CallLogs/TableView/TableView";
import { useEffect, useState } from "react";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import axios from "axios";
import EditIcon from "../../../assets/icons/edit-icon.svg";
import MenuIcon from "../../../assets/icons/menu-icon.svg";
import RepeatIcon from "../../../assets/icons/refresh-icon.svg";
import TrashIcon from "../../../assets/icons/trash-icon.svg";
import { toast } from "react-toastify";
import {
  getAllSavedFromLocalStorage,
  getSavedFromLocalStorage,
  removeCampaignFromLocalStorage,
} from "./utils/localStorage";
import {
  AlertDialog,
  DropdownMenu,
  Button as RdButton,
  Select,
} from "@radix-ui/themes";
import Modal from "components/core/Modal";
import CloseIcon from "assets/icons/close-icon-black.svg";

export default function Campaigns() {
  const apiKey = getApiKey();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [localCampaigns, setLocalCampaigns] = useState([]);
  const [loadingExport, setLoadingExport] = useState(false);
  const [exportModal, setExportModal] = useState({
    open: false,
    campaign_id: null,
  });

  const getLocalDateTimeFromTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  const getPrimaryColorStatus = (status) => {
    switch (status) {
      case "active":
        return "#206cd7";
      case "scheduled":
        return "#596171";
      case "completed":
        return "#24730a";
      case "stopped":
        return "#ef233c";
      default:
        return;
    }
  };

  const [stopCampaignModal, setStopCampaignModal] = useState({
    open: false,
    campaign_id: null,
    campaign_name: "",
  });

  const stopCampaign = async (campaign_id, campaign_name) => {
    try {
      const response = await axios.post(
        "https://us.api.bland.ai/v1/campaign/stop",
        {
          campaign_id,
        },
        {
          headers: {
            authorization: apiKey,
            "Content-Type": "application/json",
          },
        },
      );

      if (
        response.status === 200 &&
        response.data &&
        response.data.status === "success"
      ) {
        window.location.reload();
        return toast.success(`${campaign_name} has been stopped!`);
      }

      return toast.error(`Failed to stop ${campaign_name}. Please try again.`);
    } catch (error) {
      console.error(error);
      return toast.error(`An error occurred while stopping ${campaign_name}.`);
    }
  };

  const getBackgroundColorStatus = (status) => {
    switch (status) {
      case "active":
        return "#caf5fc";
      case "scheduled":
        return "##eaeff1";
      case "completed":
        return "#d0fbb3";
      case "stopped":
        return "#f8c7cc";
      default:
        return;
    }
  };

  const getAnswerScore = ({
    answered_by,
    call_ended_by,
    analysis_did_human_answer_phone,
    completed,
    queue_status,
    call_length,
  }) => {
    if (
      answered_by?.toLowerCase() === "machine_end_beep" &&
      (call_ended_by?.toLowerCase() === "assistant" ||
        call_ended_by?.toLowerCase() === "user")
    )
      return false;
    if (
      call_ended_by?.toLowerCase() === "user" &&
      analysis_did_human_answer_phone
    )
      return true;
    let answeredByInt = answered_by === "human" ? 1 : 0;
    let callEndedByInt = call_ended_by ? 1 : 0;
    let adhapInt = analysis_did_human_answer_phone ? 1 : 0;
    let completedInt = completed ? 1 : 0;
    let queueStatusInt = queue_status === "complete" ? 1 : 0;
    let callLengthInt = call_length && call_length > 0.1 ? 1 : 0;

    if (
      !call_length &&
      queue_status === "started" &&
      answered_by === "no-answer"
    )
      return false;

    let avg =
      (answeredByInt +
        callEndedByInt +
        adhapInt +
        completedInt +
        queueStatusInt +
        callLengthInt) /
      6;

    return avg > 0.5 ? true : false;
  };

  const deleteCampaign = async (campaign_id, campaign_name) => {
    try {
      const response = await axios.post(
        `https://us.api.bland.ai/v1/campaigns/delete`,
        {
          campaign_id,
        },
        {
          headers: {
            authorization: apiKey,
            "Content-Type": "application/json",
          },
          validateStatus: false,
        },
      );

      if (
        response.status === 200 &&
        response.data &&
        response.data.status === "success"
      ) {
        window.location.reload();
        return toast.success(`${campaign_name} has been deleted!`);
      }

      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  // Function to convert JSON to CSV
  function convertJsonToCsv(jsonData) {
    const csvRows = [];

    // Extract headers from the first object
    const headers = Object.keys(jsonData[0]);
    csvRows.push(headers.join(","));

    // Extract values from each object and format as CSV row
    jsonData.forEach((row) => {
      const values = headers.map((header) => row[header]);
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n");
  }
  // Function to initiate download
  function downloadCsv(data, filename) {
    const csv = convertJsonToCsv(data);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  const handleGetExport = async (filter, campaignId) => {
    try {
      setLoadingExport(true);
      const response = await fetch(
        "https://us.api.bland.ai/v1/campaigns/export",
        {
          method: "POST",
          headers: {
            authorization: apiKey,
            "content-type": "application/json",
          },
          body: JSON.stringify({
            filter,
            campaignId,
          }),
        },
      );

      setExportModal({ open: false, campaign_id: null });
      if (response.ok && response.status === 200) {
        const data = await response.json();
        if (data?.data?.length > 0) {
          const exportData = data?.data;
          setLoadingExport(false);
          setExportModal({ open: false, campaign_id: null });
          downloadCsv(exportData, "campaign_export.csv");
        }
      }

      return setLoadingExport(false);
    } catch (error) {
      return setLoadingExport(false);
    }
  };

  const handleGetCampaigns = async () => {
    try {
      const response = await axios.get("https://us.api.bland.ai/v1/campaigns", {
        headers: {
          authorization: apiKey,
          cache: "no-cache",
        },
      });

      if (response.status === 200 && response.data?.campaigns) {
        const formattedData = [];
        let campaigns = response?.data?.campaigns;
        campaigns.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        );

        for (const campaign of campaigns) {
          let obj = {
            "Campaign Details": (
              <div>
                <p>{campaign.campaign_name}</p>
                {/* <p style={{ fontSize: 10 }}>Created on {getLocalDateTimeFromTimestamp(campaign?.created_at)} | {campaign?.sequences_length} Sequence{campaign.sequences_length > 1 && "s"}</p> */}
              </div>
            ),
            Status: (
              <div
                style={{ display: "flex", flexDirection: "row", width: "auto" }}
              >
                <div
                  style={{
                    padding: "1px 10px",
                    width: "auto",
                    backgroundColor: getBackgroundColorStatus(
                      campaign?.status || "active",
                    ),
                    borderRadius: "3px",
                  }}
                >
                  <p
                    style={{
                      fontSize: 10,
                      fontWeight: "550",
                      color: `${getPrimaryColorStatus(campaign?.status || "active")}`,
                    }}
                  >
                    {campaign?.status}
                  </p>
                </div>
              </div>
            ),
            Report: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    borderRight: "1px solid #eeeeee",
                    width: "33.3%",
                    overflow: "clip",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    paddingRight: 10,
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: "500" }}>
                    {campaign?.count}
                  </p>
                  <p style={{ fontSize: 10, fontWeight: "400" }}>Calls Sent</p>
                </div>

                <div
                  style={{
                    borderRight: "1px solid #eeeeee",
                    width: "33.3%",
                    overflow: "clip",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    paddingLeft: "10px",
                    paddingRight: 10,
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: "500" }}>
                    {campaign?.human_answered}
                  </p>
                  <p style={{ fontSize: 10, fontWeight: "400" }}>Answered</p>
                </div>

                <div
                  style={{
                    borderRight: "1px solid #eeeeee",
                    width: "33.3%",
                    overflow: "clip",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    paddingLeft: "10px",
                    paddingRight: 10,
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: "500" }}>
                    {campaign?.count - campaign?.human_answered}
                  </p>
                  <p style={{ fontSize: 10, fontWeight: "400" }}>
                    Not Answered
                  </p>
                </div>

                <div
                  style={{
                    width: "33.3%",
                    overflow: "clip",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    paddingLeft: "10px",
                    paddingRight: 10,
                    justifyContent: "center",
                  }}
                >
                  <p style={{ fontSize: 18, fontWeight: "500" }}>
                    {campaign?.transferred_count || 0}
                  </p>
                  <p style={{ fontSize: 10, fontWeight: "400" }}>Transferred</p>
                </div>
              </div>
            ),
            Actions: (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: "10px",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  outline
                  onClick={() => {
                    setExportModal({
                      open: true,
                      campaign_id: campaign?.campaign_id,
                    });
                  }}
                >
                  Export
                </Button>

                <Button
                  outline
                  onClick={() => {
                    setStopCampaignModal({
                      open: true,
                      campaign_id: campaign.campaign_id,
                      campaign_name: campaign.campaign_name,
                    });
                  }}
                >
                  Stop Campaign
                </Button>

                <Button
                  onClick={(e) => {
                    e.stopPropogation();
                    deleteCampaign(
                      campaign.campaign_id,
                      campaign.campaign_name,
                    );
                  }}
                  appearance={"outline"}
                >
                  <img src={TrashIcon} height={16} width={16} alt="" />
                </Button>
              </div>
            ),
          };

          formattedData.push(obj);
        }

        setCampaigns(campaigns);
        setTableData(formattedData);
      }
    } catch (error) {
      return;
    }
  };

  const loadLocalCampaigns = () => {
    const savedCampaigns = getAllSavedFromLocalStorage();
    setLocalCampaigns(savedCampaigns);
  };

  useEffect(() => {
    handleGetCampaigns();
    loadLocalCampaigns();
  }, []);

  return (
    <PageWrapper>
      <Modal
        open={stopCampaignModal.open}
        height={"30%"}
        onClose={() => {
          setStopCampaignModal({
            open: false,
            campaign_id: null,
            campaign_name: "",
          });
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <p style={{ fontWeight: "500", fontSize: 16 }}>
            Are you sure you want to stop the campaign "
            {stopCampaignModal.campaign_name}"?
          </p>
          <div style={{ display: "flex", gap: "10px" }}>
            <Button
              onClick={() => {
                stopCampaign(
                  stopCampaignModal.campaign_id,
                  stopCampaignModal.campaign_name,
                );
                setStopCampaignModal({
                  open: false,
                  campaign_id: null,
                  campaign_name: "",
                });
              }}
              style={{ borderRadius: 6, height: 30 }}
            >
              Yes, Stop Campaign
            </Button>
            <Button
              outline
              onClick={() => {
                setStopCampaignModal({
                  open: false,
                  campaign_id: null,
                  campaign_name: "",
                });
              }}
              style={{ borderRadius: 6, height: 30 }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
      <ActionBar top spaceBetween>
        <PageTitle>Campaigns</PageTitle>

        <Button
          onClick={() => navigate("/dashboard?page=batch-call")}
          style={{ marginLeft: "auto", marginRight: "10px" }}
        >
          New Batch Call
        </Button>
        <Button onClick={() => navigate("/dashboard?page=create-campaign")}>
          Create Campaign
        </Button>
      </ActionBar>

      <Wrapper>
        <p
          className="font-bold"
          style={{ marginBottom: "-15px", fontSize: 16 }}
        >
          Call Analytics
        </p>
        <BoxGroup
          data={[
            {
              key: "Total Sent",
              value:
                campaigns?.reduce((acc, curr) => acc + curr?.count, 0) || 0,
              borderColor: "#7678ed",
              background: "#e2eafc",
            },
            {
              key: "Answer Rate",
              value: `${
                (
                  ((campaigns?.reduce(
                    (acc, curr) => acc + curr?.human_answered,
                    0,
                  ) || 0) /
                    (campaigns?.reduce((acc, curr) => acc + curr?.count, 0) ||
                      0)) *
                  100
                )?.toFixed(2) || "0"
              }%`,
              borderColor: "#5b8e7d",
              background: "#f6fff8",
            },
            {
              key: "Not Answered",
              value: `${campaigns?.reduce((acc, curr) => acc + curr?.count, 0) - campaigns?.reduce((acc, curr) => acc + curr?.human_answered, 0) || 0}`,
              borderColor: "#eeba0b",
              background: "#fffae5",
            },
            {
              key: "Bounced",
              value: "0",
              borderColor: "#ff686b",
              background: "#fae0e4",
            },
          ]}
        />

        {localCampaigns?.length > 0 && (
          <CampaignsContainer style={{}}>
            <div style={{}}>
              <p className="font-bold" style={{ fontSize: 14 }}>
                Draft Campaigns
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  marginTop: "15px",
                }}
              >
                {localCampaigns?.map((campaign, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      border: "1px solid #e5e5e5",
                      borderRadius: "4px",
                      padding: "7px 14px",
                    }}
                  >
                    <div>
                      <p className="font-bold" style={{ fontWeight: "500" }}>
                        {campaign.campaign_name} {campaign?._local_storage_id}
                      </p>
                      <p style={{ fontSize: 10, marginTop: "5px" }}>
                        {campaign.call_data?.length || 0} Phone Numbers in
                        Campaign.
                      </p>
                    </div>

                    <div
                      style={{
                        marginLeft: "auto",
                        display: "flex",
                        flexDirection: "row",
                        columnGap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        onClick={() => {
                          removeCampaignFromLocalStorage(
                            campaign._local_storage_id,
                          );
                          loadLocalCampaigns();
                          toast.info("Campaign draft has been removed.");
                        }}
                        src={TrashIcon}
                        height={16}
                        width={16}
                        alt=""
                        style={{ cursor: "pointer" }}
                      />
                      <img
                        onClick={() => {
                          navigate(
                            `/dashboard?page=create-campaign&campaign=${campaign?._local_storage_id}`,
                          );
                        }}
                        src={EditIcon}
                        height={16}
                        width={16}
                        alt=""
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </CampaignsContainer>
        )}

        {tableData.length > 0 && (
          <CampaignsContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <p className="font-bold" style={{ fontSize: 14 }}>
                Campaigns
              </p>
              <img
                onClick={() => {
                  handleGetCampaigns();
                  return toast.success("Refreshed campaign data.");
                }}
                style={{ cursor: "pointer" }}
                src={RepeatIcon}
                height={14}
                width={14}
                alt=""
              />
            </div>

            {/*<TableView
                            tableCalls={tableData || []}
                        />*/}

            <TableView tableCalls={tableData || []} />
          </CampaignsContainer>
        )}

        <Modal
          open={exportModal.open}
          height={"50%"}
          onClose={() => {
            setExportModal({ open: false, campaign_id: null });
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <p style={{ fontWeight: "500", fontSize: 14 }}>
              Export Call Logs to CSV
            </p>
            <img
              onClick={() => {
                setExportModal({ open: false, campaign_id: null });
              }}
              src={CloseIcon}
              style={{ cursor: "pointer" }}
            />
          </div>

          <select
            id="export-type"
            style={{
              border: "1px solid #eeeeee",
              borderRadius: 4,
              padding: "7px 4px",
              width: "100%",
              marginTop: 10,
            }}
          >
            <option value={"ALL"}>All Calls</option>
            <option value={"NOT_ANSWERED"}>Not Answered Calls</option>
            <option value={"ANSWERED"}>Answered Calls</option>
          </select>

          <Button
            loading={loadingExport}
            onClick={() => {
              const element = document.getElementById("export-type");
              console.log(element.value, exportModal.campaign_id, "el-val");

              const filter = element?.value ?? "ALL";
              const campaignId = exportModal.campaign_id;

              handleGetExport(filter, campaignId);
            }}
            style={{ borderRadius: 6, height: 30, marginTop: 10 }}
          >
            Export Calls to CSV
          </Button>
        </Modal>
      </Wrapper>
    </PageWrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  height: 100%;
  width: 100%;
  position: relative;
`;

const CampaignsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: scroll;
`;
