import { Editor } from "@monaco-editor/react";
import { EyeClosedIcon } from "@radix-ui/react-icons";
import { Badge, Button, Spinner } from "@radix-ui/themes";
import ActionBar from "components/core/ActionBar";
import { PageTitle } from "components/core/PageTitle";
import { PageWrapper } from "components/core/PageWrapper";
import {
  Copy,
  Edit,
  EyeIcon,
  KeyRound,
  Minus,
  Plus,
  PlusCircle,
  RefreshCcw,
  Search,
  Trash2,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import { objectToKeyValue } from "utils/formatting/objToKeyValues";
import { toDtmy } from "utils/formatting/toDtmy";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import { getAuthToken } from "utils/funcs/browser/getAuthToken";

export default function Secrets() {
  const [secrets, setSecrets] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState({
    id: null,
    loading: false,
  });
  const [page, setPage] = useState("view_all");
  const apiKey = getApiKey();
  const navigate = useNavigate();
  const [keyValue, setKeyValue] = useState({
    key: "",
    value: "",
  });
  const [refreshSecretName, setRefreshSecretName] = useState("");
  const [config, setConfig] = useState({
    url: "",
    method: "GET",
    headers: [],
    response: "",
    body: "",
    refresh_interval: 60,
  });
  const [loadingById, setLoadingById] = useState(false);
  const [isStatic, setIsStatic] = useState(false);
  const [selectedType, setSelectedType] = useState("static");

  const loadAllSecrets = async () => {
    try {
      const response = await fetch("https://us.api.bland.ai/v2/secrets", {
        method: "GET",
        headers: {
          Authorization: apiKey,
          accept: "application/json",
        },
      });

      if (response.ok && response.status === 200) {
        const data = await response.json();
        setSecrets(data?.data?.secrets);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoadingDelete({ id, loading: true });
      const response = await fetch(`https://us.api.bland.ai/v2/secrets/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: apiKey,
          accept: "application/json",
        },
      });

      if (response.ok && response.status === 200) {
        const data = await response.json();
        setSecrets(secrets.filter((secret) => secret.id !== id));
      } else {
        toast.error("Failed to delete secret: " + response.status, {
          position: "bottom-right",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingDelete({ id, loading: false });
    }
  };

  const getSecretById = async (id) => {
    try {
      setLoadingById(true);
      const response = await fetch(`https://us.api.bland.ai/v2/secrets/${id}`, {
        method: "GET",
        headers: {
          Authorization: apiKey,
        },
      });

      if (response.ok && response.status === 200) {
        const data = await response.json();
        setIsStatic(data?.data?.secret?.static || false);
        if (data?.data?.secret?.static) {
          setKeyValue({
            key: data?.data?.secret?.name,
            value: "",
          });
          setSelectedType("static");
        } else {
          setConfig({
            url: data?.data?.secret?.config?.url,
            method: data?.data?.secret?.config?.method,
            headers: objectToKeyValue(data?.data?.secret?.config?.headers),
            response: data?.data?.secret?.config?.response,
            body: data?.data?.secret?.config?.body,
            refresh_interval: data?.data?.secret?.config?.refresh_interval,
          });
          setRefreshSecretName(data?.data?.secret?.name);
          setSelectedType("refresh");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingById(false);
    }
  };

  useEffect(() => {
    loadAllSecrets();
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("view") === "create") {
      setPage("create");
      // reset everything
      setIsStatic(false);
      setSelectedType("static");
      setKeyValue({
        key: "",
        value: "",
      });
      setConfig({
        url: "",
        method: "GET",
        headers: [],
        response: "",
        body: "",
        refresh_interval: 60,
      });
      setRefreshSecretName("");
    } else {
      setPage("view_all");
    }

    if (queryParams.get("id")) {
      getSecretById(queryParams.get("id"));
    } else {
    }
  }, [window.location.search]);

  // useEffect(() => {
  //   loadAllSecrets();
  // }, []);

  if (secrets === null || loadingById) {
    return <div />;
  }

  return (
    <PageWrapper>
      <ActionBar top spaceBetween>
        <PageTitle
          onClick={() => {
            window.location.href = "/dashboard?page=secrets";
          }}
        >
          Secrets
        </PageTitle>
        {page === "view_all" && (
          <Button
            onClick={() => {
              navigate("/dashboard?page=secrets&view=create");
            }}
            color="iris"
            variant="solid"
            style={{ cursor: "pointer" }}
          >
            New Secret
            <Plus size={14} className="ml-1" />
          </Button>
        )}
      </ActionBar>

      {page === "create" && (
        <CreateSecretConfig
          navigate={navigate}
          keyValue={keyValue}
          setKeyValue={setKeyValue}
          config={config}
          setConfig={setConfig}
          refreshSecretName={refreshSecretName}
          setRefreshSecretName={setRefreshSecretName}
          isStatic={isStatic}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
        />
      )}

      {page === "view_all" && secrets?.length === 0 && (
        <SecretsNullState navigate={navigate} />
      )}

      {page === "view_all" && secrets?.length > 0 && (
        <ViewAllSecrets
          secrets={secrets}
          handleDelete={handleDelete}
          loadingDelete={loadingDelete}
          navigate={navigate}
        />
      )}
    </PageWrapper>
  );
}

const SecretsNullState = ({ navigate }) => {
  return (
    <div className="flex items-center justify-center min-h-[100%] bg-gradient-to-br from-gray-50 to-gray-100 p-4">
      <div className="w-full max-w-[50%] max-w-[500px] min-w-[300px] space-y-8 text-center">
        <div className="space-y-2">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900">
            Variable Secrets
          </h2>
          <p className="text-lg text-gray-600">
            Securely manage your API keys and sensitive variables
          </p>
        </div>

        <div className="relative">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-200" />
          </div>
          <div className="relative flex justify-center">
            <span className="bg-gradient-to-br from-gray-50 to-gray-100 px-3">
              <KeyRound size={32} className="text-gray-400" />
            </span>
          </div>
        </div>

        <div className="bg-white shadow-xl rounded-xl p-8 space-y-6">
          <p className="text-gray-600" style={{ fontSize: 14 }}>
            You haven't added any secrets yet. Get started by securely storing
            your first API key or sensitive variable.
          </p>

          <Button
            className="w-full bg-iris-900 hover:bg-iris-800 text-white"
            size="lg"
            color="iris"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/dashboard?page=secrets&view=create");
            }}
          >
            <PlusCircle size={14} className="mr-2" />
            Add Your First Secret
          </Button>
        </div>

        <p className="text-sm text-gray-500">
          Need help? Check out our{" "}
          <a href="#" className="text-blue-600 hover:underline">
            documentation
          </a>{" "}
          or{" "}
          <a href="#" className="text-blue-600 hover:underline">
            contact support
          </a>
          .
        </p>
      </div>
    </div>
  );
};

const CreateSecretConfig = ({
  navigate,
  keyValue,
  setKeyValue,
  config,
  setConfig,
  refreshSecretName,
  setRefreshSecretName,
  isStatic,
  selectedType,
  setSelectedType,
}) => {
  const [saveLoading, setSaveLoading] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [secretMasked, setSecretMasked] = useState(true);

  // Function to add a new header
  const addHeader = () => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      headers: [...prevConfig.headers, { key: "", value: "" }],
    }));
  };
  // Function to remove a header by index
  const removeHeader = (index) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      headers: prevConfig.headers.filter((_, i) => i !== index),
    }));
  };

  // Function to update a header
  const updateHeader = (index, field, value) => {
    setConfig((prevConfig) => ({
      ...prevConfig,
      headers: prevConfig.headers.map((header, i) =>
        i === index ? { ...header, [field]: value } : header,
      ),
    }));
  };

  const handleUpdate = async () => {
    try {
      setSaveLoading(true);
      let payload = {};

      if (selectedType === "static") {
        payload = {
          name: keyValue?.key,
          secret: keyValue?.value,
        };
      } else if (selectedType === "refresh") {
        payload = validatedDataForRefreshSecret();
      }

      const response = await fetch(
        `https://us.api.bland.ai/v2/secrets/${new URLSearchParams(window.location.search).get("id")}`,
        {
          method: "PATCH",
          headers: {
            Authorization: getApiKey(),
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        },
      );

      if (response.ok && response.status === 200) {
        const data = await response.json();
        toast.success("Secret updated successfully", {
          position: "bottom-right",
        });
        navigate("/dashboard?page=secrets");
      }
    } catch (error) {
      toast.error("Failed to update secret", {
        position: "bottom-right",
      });
    } finally {
      setSaveLoading(false);
    }
  };

  const handleSave = async () => {
    if (new URLSearchParams(window.location.search).get("id")) {
      return handleUpdate();
    }
    try {
      if (
        selectedType === "refresh" &&
        (refreshSecretName === "" ||
          config.url === "" ||
          config.response === "$." ||
          config.response === "")
      ) {
        toast.error("Please fill in all required fields", {
          position: "bottom-right",
        });
        return;
      }
      setSaveLoading(true);
      let payload = {};

      if (selectedType === "static") {
        payload = {
          name: keyValue?.key,
          secret: keyValue?.value,
        };
      } else if (selectedType === "refresh") {
        payload = validatedDataForRefreshSecret();
      }

      const response = await fetch("https://us.api.bland.ai/v2/secrets", {
        method: "POST",
        headers: {
          Authorization: getApiKey(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok && response.status === 200) {
        await response.json();
        toast.success("Secret created successfully", {
          position: "bottom-right",
        });
        navigate("/dashboard?page=secrets");
      }
    } catch (error) {
      toast.error("Failed to create secret", {
        position: "bottom-right",
      });
    } finally {
      setSaveLoading(false);
    }
  };

  const handleTestAndParseForToken = async () => {
    try {
      if (config.url === "") {
        toast.error("Please enter a URL first", {
          position: "top-right",
        });
        return;
      }

      if (config.response === "$.") {
        toast.error("Please enter a response path first", {
          position: "top-right",
        });
        return;
      }
      setTestLoading(true);
      const transformedHeaders = config.headers.reduce((acc, header) => {
        acc[header.key] = header.value;
        return acc;
      }, {});
      const payload = {
        url: config.url,
        method: config.method,
        headers: transformedHeaders,
      };

      if (config.method === "POST") {
        payload.body = JSON.parse(config.body);
      }

      const response = await fetch("/api/tools/run", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: getAuthToken(),
        },
        body: JSON.stringify(payload),
      });

      if (response.ok && response.status === 200) {
        const data = await response.json();
        if (data?.statusCode === 200 && data?.responseData) {
          const parse_path = config.response.split("$.")[1];
          const parse_value = data?.responseData?.[parse_path];
          toast.success(parse_value, {
            position: "bottom-right",
          });
        } else {
          toast.error("Failed to parse response", {
            position: "bottom-right",
          });
        }
      }
    } catch (error) {
      toast.error("Failed to test API", {
        position: "bottom-right",
      });
    } finally {
      setTestLoading(false);
    }
  };

  const validatedDataForRefreshSecret = () => {
    const transformedHeaders = config.headers.reduce((acc, header) => {
      acc[header.key] = header.value;
      return acc;
    }, {});

    const payload = {
      name: refreshSecretName,
      config: {
        url: config.url,
        method: config.method,
        refresh_interval: config.refresh_interval,
        headers: transformedHeaders,
        response: config.response,
      },
    };

    if (config.method === "POST") {
      payload.config.body = config.body;
    }

    return payload;
  };

  return (
    <div
      className="min-h-[auto] bg-white shadow-md max-w-[1000px] min-w-[482px] mx-auto"
      style={{
        border: "1px solid #e5e5e5",
        borderRadius: 7,
      }}
    >
      {/* Main Content */}
      <div style={{ borderRadius: 7 }}>
        <div className="p-12 bg-white" style={{ borderRadius: 7 }}>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 20,
            }}
          >
            <h2 className="text-2xl font-semibold text-gray-900">
              Choose Secret Type
            </h2>
          </div>
          <div className="space-y-6">
            {new URLSearchParams(window.location.search).get("id") ? (
              <>
                {isStatic ? (
                  <div
                    className={`flex items-center space-x-4 p-4 rounded-xl cursor-pointer transition-all duration-200 ${selectedType === "static" ? "bg-blue-50 ring-1 ring-blue-500" : "hover:bg-gray-100"}`}
                    onClick={() => setSelectedType("static")}
                  >
                    <div
                      className={`w-10 h-10 rounded-full flex items-center justify-center ${selectedType === "static" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-500"}`}
                    >
                      <KeyRound size={12} />
                    </div>
                    <div>
                      <p className="font-semibold text-gray-900">
                        Static Variable
                      </p>
                      <p className="text-sm text-gray-500">
                        For API keys or passwords
                      </p>
                    </div>
                  </div>
                ) : (
                  <div
                    className={`flex items-center space-x-4 p-4 rounded-xl cursor-pointer transition-all duration-200 ${selectedType === "refresh" ? "bg-green-50 ring-1 ring-green-500" : "hover:bg-gray-100"}`}
                    onClick={() => setSelectedType("refresh")}
                  >
                    <div
                      className={`w-10 h-10 rounded-full flex items-center justify-center ${selectedType === "refresh" ? "bg-green-500 text-white" : "bg-gray-200 text-gray-500"}`}
                    >
                      <RefreshCcw size={12} />
                    </div>
                    <div>
                      <p className="font-semibold text-gray-900">
                        Refresh Token
                      </p>
                      <p className="text-sm text-gray-500">
                        For auto-refreshing access tokens or API keys
                      </p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div
                  className={`flex items-center space-x-4 p-4 rounded-xl cursor-pointer transition-all duration-200 ${selectedType === "static" ? "bg-blue-50 ring-1 ring-blue-500" : "hover:bg-gray-100"}`}
                  onClick={() => setSelectedType("static")}
                >
                  <div
                    className={`w-10 h-10 rounded-full flex items-center justify-center ${selectedType === "static" ? "bg-blue-500 text-white" : "bg-gray-200 text-gray-500"}`}
                  >
                    <KeyRound size={12} />
                  </div>
                  <div>
                    <p className="font-semibold text-gray-900">
                      Static Variable
                    </p>
                    <p className="text-sm text-gray-500">
                      For API keys or passwords
                    </p>
                  </div>
                </div>

                <div
                  className={`flex items-center space-x-4 p-4 rounded-xl cursor-pointer transition-all duration-200 ${selectedType === "refresh" ? "bg-green-50 ring-1 ring-green-500" : "hover:bg-gray-100"}`}
                  onClick={() => setSelectedType("refresh")}
                >
                  <div
                    className={`w-10 h-10 rounded-full flex items-center justify-center ${selectedType === "refresh" ? "bg-green-500 text-white" : "bg-gray-200 text-gray-500"}`}
                  >
                    <RefreshCcw size={12} />
                  </div>
                  <div>
                    <p className="font-semibold text-gray-900">Refresh Token</p>
                    <p className="text-sm text-gray-500">
                      For auto-refreshing access tokens or API keys
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div style={{ height: 1, background: "#e5e5e5", width: "100%" }} />

        {selectedType === "static" && (
          <div
            className="mx-auto bg-gray-50 p-12 shadow-md"
            style={{ borderRadius: 7 }}
          >
            <h2 className="text-xl font-semibold text-gray-900">
              Static Variable
            </h2>

            <KeyValueWrapper style={{ marginTop: 15 }}>
              <KeyValueGrid>
                <StyledInput
                  placeholder="Secret Name"
                  value={keyValue?.key}
                  onChange={(e) =>
                    setKeyValue({ ...keyValue, key: e.target.value })
                  }
                />
                <StyledInput
                  placeholder="Secret Value"
                  value={keyValue?.value}
                  type={secretMasked ? "password" : "text"}
                  onChange={(e) =>
                    setKeyValue({ ...keyValue, value: e.target.value })
                  }
                />
                <div
                  onClick={() => setSecretMasked(!secretMasked)}
                  className="flex items-center justify-center"
                  style={{
                    height: 38,
                    width: 38,
                    cursor: "pointer",
                    border: "1px solid #e5e5e5",
                    borderRadius: 4,
                  }}
                >
                  {secretMasked ? (
                    <EyeIcon size={14} />
                  ) : (
                    <EyeClosedIcon size={14} />
                  )}
                </div>
              </KeyValueGrid>
            </KeyValueWrapper>

            <Button
              size={"3"}
              color="iris"
              style={{
                cursor: "pointer",
                padding: "0px 15px",
                marginTop: 20,
                width: "100%",
              }}
              onClick={handleSave}
              variant={"solid"}
              disabled={keyValue?.key === "" || keyValue?.value === ""}
            >
              {saveLoading ? <Spinner size="16" /> : "Save Variable"}
            </Button>
          </div>
        )}

        {selectedType === "refresh" && (
          <div
            className="mx-auto bg-gray-50 p-12 shadow-md"
            style={{ borderRadius: 7 }}
          >
            <h2 className="text-xl font-semibold text-gray-900">
              Refresh Token
            </h2>

            <div style={{ marginTop: 15 }}>
              <p
                style={{
                  fontSize: 10,
                  fontWeight: "500",
                  color: "grayText",
                  marginBottom: 5,
                }}
              >
                Secret Name
              </p>
              <StyledInput
                placeholder="Secret Name"
                value={refreshSecretName}
                onChange={(e) => setRefreshSecretName(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>

            <div style={{ marginTop: 15 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: 10,
                    fontWeight: "500",
                    color: "grayText",
                  }}
                >
                  Refresh URL
                </p>

                <p
                  onClick={handleTestAndParseForToken}
                  style={{
                    marginLeft: "auto",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  {testLoading ? <Spinner size="3" /> : "Test API"}
                </p>
              </div>
              <StyledInput
                placeholder="URL"
                className="w-full mt-3"
                value={config.url}
                onChange={(e) => setConfig({ ...config, url: e.target.value })}
              />
            </div>

            <div style={{ marginTop: 15 }}>
              <p
                style={{
                  fontSize: 10,
                  fontWeight: "500",
                  color: "grayText",
                  marginBottom: 5,
                }}
              >
                HTTP Method
              </p>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                }}
              >
                <Button
                  variant={config.method === "GET" ? "solid" : "outline"}
                  color="violet"
                  size="2"
                  style={{ borderRadius: 0, cursor: "pointer" }}
                  onClick={() => {
                    setConfig({ ...config, method: "GET", body: "" });
                  }}
                >
                  GET
                </Button>
                <Button
                  variant={config.method === "POST" ? "solid" : "outline"}
                  color="violet"
                  size="2"
                  style={{ borderRadius: 0, cursor: "pointer" }}
                  onClick={() => setConfig({ ...config, method: "POST" })}
                >
                  POST
                </Button>
              </div>
            </div>

            {config.method === "POST" && (
              <div style={{ marginTop: 15 }}>
                <p
                  style={{
                    fontSize: 10,
                    fontWeight: "500",
                    color: "grayText",
                    marginBottom: 5,
                  }}
                >
                  JSON Payload
                </p>

                <div
                  style={{
                    padding: 5,
                    background: "white",
                    borderRadius: 4,
                    border: "1px solid #e5e5e5",
                  }}
                >
                  <Editor
                    height="200px"
                    defaultLanguage={"json"}
                    value={config.body}
                    onChange={(value) => setConfig({ ...config, body: value })}
                    options={{
                      minimap: { enabled: false },
                      lineNumbers: "off",
                      roundedSelection: false,
                      scrollBeyondLastLine: false,
                      readOnly: false,
                      fontSize: 12,
                      fontWeight: "500",
                      foldingHighlight: false,
                      automaticLayout: true,
                      formatOnPaste: true,
                      formatOnType: true,
                      inlayHints: {
                        padding: true,
                      },
                      folding: false,
                      scrollbar: {
                        vertical: "hidden",
                      },
                    }}
                  />
                </div>
              </div>
            )}

            <div style={{ marginTop: 15 }}>
              <p
                style={{
                  fontSize: 10,
                  fontWeight: "500",
                  color: "grayText",
                  marginBottom: 5,
                }}
              >
                Headers
              </p>
              {config.headers.map((header, index) => (
                <div key={index} className="flex items-center space-x-2 mb-2">
                  <StyledInput
                    value={header.key}
                    onChange={(e) => updateHeader(index, "key", e.target.value)}
                    placeholder="Header Name"
                    className="w-1/2"
                  />
                  <StyledInput
                    value={header.value}
                    onChange={(e) =>
                      updateHeader(index, "value", e.target.value)
                    }
                    placeholder="Header Value"
                    className="w-1/2"
                  />
                  <Button
                    type="button"
                    variant="ghost"
                    size="2"
                    color="red"
                    onClick={() => removeHeader(index)}
                    style={{
                      cursor: "pointer",
                      padding: 12,
                    }}
                  >
                    <Minus size={16} />
                  </Button>
                </div>
              ))}
              <Button
                type="button"
                variant="soft"
                size={"1"}
                color={"violet"}
                onClick={addHeader}
                style={{
                  cursor: "pointer",
                  marginTop: config.headers.length > 0 ? 5 : 0,
                }}
              >
                Add Header
                <Plus size={13} className="ml-1" />
              </Button>
            </div>

            <div style={{ marginTop: 15 }}>
              <p
                style={{
                  fontSize: 10,
                  fontWeight: "500",
                  color: "grayText",
                }}
              >
                Refresh Interval (minutes)
              </p>
              <StyledInput
                id="interval"
                type={"number"}
                value={config.refresh_interval}
                onChange={(e) => {
                  if (e.target.value > 1440) return;
                  if (e.target.value < 1) return;
                  if (isNaN(e.target.value)) return;
                  if (!/^\d+$/.test(e.target.value)) return;
                  setConfig({
                    ...config,
                    refresh_interval: parseInt(e.target.value),
                  });
                }}
                placeholder="60"
                min={1}
                max={1440}
                className="w-full mt-3"
              />
            </div>

            <div style={{ marginTop: 15 }}>
              <p
                style={{
                  fontSize: 10,
                  fontWeight: "500",
                  color: "grayText",
                }}
              >
                Extract Secret From Response Body
              </p>

              <p
                style={{
                  fontSize: 10,
                  fontWeight: "500",
                  color: "darkgray",
                  marginTop: 2.5,
                  marginBottom: 5,
                }}
              >{`$. is the root of the response body. For example, to parse {"token": "sk..."} you would set $.token -- an example with a nested value {"user": {"token": "sk..."}} would be $.user.token`}</p>

              <StyledInput
                placeholder="$.token"
                value={config.response}
                onChange={(e) =>
                  setConfig({ ...config, response: e.target.value })
                }
                style={{ width: "100%" }}
              />
            </div>

            <Button
              size={"3"}
              color="iris"
              style={{
                cursor: "pointer",
                padding: "0px 15px",
                marginTop: 20,
                width: "100%",
              }}
              loading={saveLoading}
              onClick={handleSave}
            >
              Save Refresh Token
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const ViewAllSecrets = ({ secrets, handleDelete, loadingDelete, navigate }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showValues, setShowValues] = useState({});

  const filteredSecrets = secrets.filter((secret) =>
    secret.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const toggleShowValue = (id) => {
    setShowValues((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div className="min-h-screen bg-gray-50 py-16">
      <div className="max-w-[70%] mx-auto px-4 sm:px-0 lg:px-0">
        <div className="mb-12 space-y-1">
          <h1 className="text-4xl font-bold text-gray-900 tracking-tight">
            Secret Manager
          </h1>
          <p className="text-lg text-gray-600">
            Secure and manage your sensitive data
          </p>
        </div>

        <div
          className="bg-white shadow-lg border border-gray-200"
          style={{ borderRadius: 4 }}
        >
          <div className="p-6 flex justify-between items-center border-b border-gray-200">
            <div className="flex items-center flex-grow max-w-md">
              <Search size={14} className="text-gray-400 mr-4" />
              <StyledInput
                type="text"
                placeholder="Search by name..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="flex-grow"
                style={{ padding: 4, fontSize: 12 }}
              />
            </div>
          </div>
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead className="bg-gray-50 border-b border-gray-200">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">
                    Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">
                    Type
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">
                    Value
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">
                    Last Refreshed
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">
                    Created At
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {filteredSecrets.map((secret) => (
                  <tr
                    key={secret.id}
                    className="hover:bg-gray-50 transition-colors duration-150"
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {secret.name}
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          columnGap: 5,
                          marginTop: 2.5,
                        }}
                      >
                        <div className="text-xs text-gray-500">
                          <code
                            style={{ fontSize: 10 }}
                          >{`{{SECRET.${secret.name}}}`}</code>
                        </div>
                        <div>
                          <Copy
                            size={10}
                            color="gray"
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <Badge color={secret.static ? "blue" : "green"}>
                        {secret.static ? (
                          <KeyRound size={12} className="mr-1" />
                        ) : (
                          <RefreshCcw size={12} className="mr-1" />
                        )}
                        <p style={{ fontWeight: "500", fontSize: 12 }}>
                          {secret.static ? "Static" : "Refresh"}
                        </p>
                      </Badge>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center space-x-2">
                        <span className="font-mono text-sm text-gray-500">
                          {showValues[secret.id]
                            ? secret.value
                            : "••••••••••••"}
                        </span>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {toDtmy(new Date(secret.secret_last_updated_at))}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-500">
                        {toDtmy(new Date(secret.created_at))}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <div className="flex space-x-4">
                        <Button
                          variant="ghost"
                          size="sm"
                          className="text-gray-400 hover:text-gray-600"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            navigate(
                              `/dashboard?page=secrets&view=create&id=${secret.id}`,
                            );
                          }}
                        >
                          <Edit size={16} color="#4361ee" />
                        </Button>
                        <Button
                          onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            handleDelete(secret.id);
                          }}
                          variant="ghost"
                          size="sm"
                          className="text-gray-400 hover:text-red-600"
                          color="red"
                          style={{ cursor: "pointer" }}
                        >
                          {loadingDelete.id === secret.id &&
                          loadingDelete.loading ? (
                            <Spinner style={{ height: 16, width: 16 }} />
                          ) : (
                            <Trash2 size={16} color="#ef233c" />
                          )}
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div
          className="mt-12 flex justify-between items-center"
          style={{ flexWrap: "wrap", gap: 10 }}
        >
          <p className="text-sm text-gray-600">
            Showing {filteredSecrets.length} of {secrets.length} secrets
          </p>
        </div>
      </div>
    </div>
  );
};

const KeyValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0px;
  overflow: hidden;
  gap: 5px;
`;

const KeyValueGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 38px;
  grid-gap: 10px;
  width: 100%;
`;

const StyledInput = styled.input`
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  padding: 8px;
  font-size: 13px;
  color: ${({ disabled }) => (disabled ? "grey" : "black")};
  letter-spacing: 0.125px;
  &:focus {
    outline: none;
  }
`;
