import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { endActiveCall } from "utils/funcs/send-call/endActiveCall";
import { getActiveCallLogs } from "utils/funcs/send-call/getActiveCallLogs";
import { PageTitle } from "components/core/PageTitle";
import Button from "components/core/Button";
import ActionBar from "components/core/ActionBar";
import LoadSpinner from "components/core/LoadSpinner";
import { toast } from "react-toastify";
import { CheckCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";

export default function ActiveCall({ callId, apiKey }) {
  const { setValue } = useFormContext();
  const [transcript, setTranscript] = useState([]);
  const [isEnding, setIsEnding] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [elipses, setElipses] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setElipses((elipses) => (elipses + 1) % 4);
    }, 500);
    return () => clearInterval(intervalId);
  }, []);

  const handleEnd = async () => {
    setIsEnding(true);
    try {
      const { is_call_ended } = await endActiveCall(callId, apiKey);
      if (is_call_ended) {
        setIsEnded(true);
      } else {
        toast.error("Failed to end the call. Please try again.");
      }
    } catch (error) {
      console.error("Error ending call:", error);
      toast.error("An error occurred while ending the call.");
    } finally {
      setIsEnding(false);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const fetchCallTranscript = async () => {
        const transcript = await getActiveCallLogs(callId, apiKey);
        setTranscript(transcript);
      };
      fetchCallTranscript();
    }, 2000);
    return () => clearInterval(intervalId);
  }, [apiKey, callId]);

  const handleClose = () => {
    setValue("isCallActive", false);
  };

  const handleViewCallLog = () => {
    navigate(`/dashboard?page=call-logs&ref_c_id=${callId}`);
  };

  if (isEnded) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <CheckCircle className="text-green-500 w-16 h-16 mb-4" />
        <h2 className="text-2xl font-bold mb-4">Call Ended Successfully</h2>
        <div className="flex space-x-4">
          <Button onClick={handleViewCallLog} appearance="outline">
            View Call Logs
          </Button>
          <Button onClick={handleClose} appearance="outline">
            Close
          </Button>
        </div>
      </div>
    );
  }
  return (
    <>
      <ActionBar top spaceBetween>
        <PageTitle>Transcript</PageTitle>
        <div className="flex flex-row gap-4">
          <Button
            appearance="outline"
            onClick={handleClose}
            className="bg-dark font-medium text-white px-4 py-2 rounded border border-black shadow-sm transition-all hover:bg-black hover:shadow-lg self-center"
          >
            Close
          </Button>
          <Button
            onClick={handleEnd}
            disabled={isEnding}
            className="bg-dark font-medium text-white px-4 py-2 rounded border border-black shadow-sm transition-all hover:bg-black hover:shadow-lg self-center"
          >
            {isEnding ? (
              <span className="flex items-center">
                <LoadSpinner /> Ending Call...
              </span>
            ) : (
              "End Call"
            )}
          </Button>
        </div>
      </ActionBar>
      <div className="w-full flex flex-col overflow-y-scroll relative">
        {transcript?.length === 0 ? (
          <>
            <div className="text-center text-secondary">
              Waiting for call to answer {Array(elipses + 1).join(".")}
            </div>
            <div className="text-center text-muted pt-20">
              Transcripts will appear here shortly
            </div>
            <div className="flex justify-center pt-10">
              <LoadSpinner />
            </div>
          </>
        ) : (
          transcript?.map((line, index) => (
            <div key={index} className="rounded p-2 my-1 text-secondary">
              <span
                className={`text-black rounded px-2 py-1 text-center uppercase mr-2 ${
                  line?.user === "assistant" ? "bg-tertiary" : "bg-light"
                }`}
              >
                {line?.user}
              </span>{" "}
              {line?.text}
            </div>
          ))
        )}
      </div>
    </>
  );
}
