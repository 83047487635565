import Select from "components/core/Select";
import { useFormContext } from "react-hook-form";
import { useState } from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline"; // Import the question mark icon
import LightTooltip from "./ToolTipDesc";

const languageMap = {
  en: "English",
  auto: "Auto Detect (English & Spanish)",
  "en-US": "English (US)",
  "en-GB": "English (UK)",
  "en-AU": "English (Australia)",
  "en-NZ": "English (New Zealand)",
  "en-IN": "English (India)",
  es: "Spanish",
  "es-419": "Spanish (Latin America)",
  fr: "French",
  "fr-CA": "French (Canada)",
  de: "German",
  el: "Greek",
  hi: "Hindi",
  "hi-Latn": "Hindi (Latin script)",
  ja: "Japanese",
  ko: "Korean",
  "ko-KR": "Korean (Korea)",
  pt: "Portuguese",
  "pt-BR": "Portuguese (Brazil)",
  zh: "Chinese (Mandarin, Simplified)",
  "zh-CN": "Chinese (Mandarin, Simplified, China)",
  "zh-Hans": "Chinese (Mandarin, Simplified, Hans)",
  "zh-TW": "Chinese (Mandarin, Traditional)",
  "zh-Hant": "Chinese (Mandarin, Traditional, Hant)",
  it: "Italian",
  nl: "Dutch",
  pl: "Polish",
  ru: "Russian",
  sv: "Swedish",
  "sv-SE": "Swedish (Sweden)",
  da: "Danish",
  "da-DK": "Danish (Denmark)",
  fi: "Finnish",
  id: "Indonesian",
  ms: "Malay",
  tr: "Turkish",
  uk: "Ukrainian",
  bg: "Bulgarian",
  cs: "Czech",
  ro: "Romanian",
  sk: "Slovak",
};

export default function Language({ fieldName }) {
  const { watch } = useFormContext();
  const currentLanguage = watch("language");
  const longText = (
    <span>
      Select a supported language of your choice.
      <br /> Optimizes every part of our API for that language - transcription,
      speech, and other inner workings.
      <br />
      <br />
      Supported Languages and their codes:
      {Object.keys(languageMap).map((key) => (
        <div key={key}>
          <strong>{key}</strong> - {languageMap[key]}
        </div>
      ))}
      <br></br>
    </span>
  );

  return (
    <Select
      value={currentLanguage}
      fieldName={fieldName}
      label={
        <span>
          Language
          <LightTooltip title={longText} placement="right-start" arrow>
            <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
          </LightTooltip>
        </span>
      }
    >
      {Object.keys(languageMap).map((key) => (
        <option key={key} value={key}>
          {languageMap[key]}
        </option>
      ))}
    </Select>
  );
}

