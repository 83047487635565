import { VscLoading } from "react-icons/vsc";

const LoadSpinner = () => {
  return (
    <div className="w-full h-full flex">
      <VscLoading className="animate-spin mx-auto my-auto text-3xl text-gray-800" />
    </div>
  );
};
export default LoadSpinner;
