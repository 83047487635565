import React from "react";
import { DataCard, KeyValue, KeyValues } from "./Styles";

export default function Variables({ focusedVariables, modal }) {
  const renderVariables = () => {
    if (focusedVariables == null) {
      return <p>No variables to display</p>;
    }

    let parsedVariables;
    if (typeof focusedVariables === "string") {
      try {
        parsedVariables = JSON.parse(focusedVariables);
      } catch (error) {
        console.error("Error parsing focusedVariables as string:", error);
        return <p>Error: Unable to parse variables</p>;
      }
    } else if (
      typeof focusedVariables === "object" &&
      focusedVariables !== null
    ) {
      parsedVariables = focusedVariables;
    } else {
      return <p>Error: Invalid variable format</p>;
    }

    if (
      parsedVariables &&
      typeof parsedVariables.parsedVariables === "string"
    ) {
      try {
        parsedVariables = JSON.parse(parsedVariables.parsedVariables);
      } catch (error) {
        console.error("Error parsing parsedVariables.parsedVariables:", error);
        return <p>Error: Unable to parse nested variables</p>;
      }
    }

    if (typeof parsedVariables !== "object" || parsedVariables === null) {
      return <p>Error: Invalid parsed variables</p>;
    }

    return (
      <KeyValues>
        {Object.entries(parsedVariables).map(([key, value]) => (
          <KeyValue key={key}>
            <p>{key}:</p>
            <p>
              {value === null
                ? "null"
                : typeof value === "object"
                  ? JSON.stringify(value)
                  : String(value)}
            </p>
          </KeyValue>
        ))}
      </KeyValues>
    );
  };

  return (
    <DataCard modal={modal}>
      <h2 className="font-bold">Variables</h2>
      <div>{renderVariables()}</div>
    </DataCard>
  );
}
