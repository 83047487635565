// src/DateRangePicker.js
import React, { useEffect, useState } from "react";
import {
  format,
  addMonths,
  subMonths,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  isSameDay,
  isBefore,
  isAfter,
  addDays,
} from "date-fns";
import { Box, Button, Card, DropdownMenu, Flex } from "@radix-ui/themes";
import { convertTimestampToDateTime } from "../utils/utils";
import * as Icons from "@radix-ui/react-icons";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button as ShadcnButton } from "@/components/ui/button";
import { CalendarIcon, Group } from "lucide-react";

const DateRangePicker = ({
  range,
  range_options,
  onSelectGrouping,
  onSetRange,
}) => {
  const [showCal, setShowCal] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [hoveredDate, setHoveredDate] = useState(null);

  useEffect(() => {
    if (range.start && range.end) {
      setStartDate(new Date(range.start * 1000)); // Convert timestamp to milliseconds
      setEndDate(new Date(range.end * 1000)); // Convert timestamp to milliseconds
    }
  }, [range.start, range.end]);

  const handleDateClick = (date) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(date);
      setEndDate(null);
      onSetRange(date / 1000, null);
    } else if (
      startDate &&
      !endDate &&
      (isAfter(date, startDate) || isSameDay(date, startDate))
    ) {
      setEndDate(date / 1000);
      onSetRange(startDate / 1000, date / 1000);
      setShowCal(false);
    }
  };

  const handleDateMouseEnter = (date) => {
    if (startDate && !endDate) {
      setHoveredDate(date);
    }
  };

  const handleDateMouseLeave = () => {
    setHoveredDate(null);
  };

  const renderHeader = () => {
    const monthFormat = "MMMM yyyy";

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          width: "100%",
        }}
      >
        <button onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}>
          <Icons.ArrowLeftIcon height={13} width={13} />
        </button>
        <p style={{ fontSize: 13, fontWeight: "550" }}>
          {format(currentMonth, monthFormat)}
        </p>
        <button onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}>
          <Icons.ArrowRightIcon height={13} width={13} />
        </button>
      </div>
    );
  };

  const renderDaysOfWeek = () => {
    const daysOfWeek = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    return (
      <div style={{ display: "flex" }}>
        {daysOfWeek.map((day) => (
          <div
            key={day}
            style={{ width: "40px", textAlign: "center", padding: "5px" }}
          >
            <p style={{ fontWeight: "450", fontSize: 12 }}>{day}</p>
          </div>
        ))}
      </div>
    );
  };

  const renderCalendar = () => {
    const startOfMonthDate = startOfMonth(currentMonth);
    const endOfMonthDate = endOfMonth(currentMonth);
    const startOfWeekDate = startOfWeek(startOfMonthDate);
    const endOfWeekDate = endOfWeek(endOfMonthDate);

    const dates = eachDayOfInterval({
      start: startOfWeekDate,
      end: endOfWeekDate,
    });

    return dates.map((date) => {
      const isInRange =
        startDate &&
        (isSameDay(date, startDate) ||
          (endDate && isBefore(date, endDate) && isAfter(date, startDate)) ||
          isSameDay(date, endDate));
      const isHoveredInRange =
        startDate &&
        !endDate &&
        hoveredDate &&
        ((isBefore(date, hoveredDate) && isAfter(date, startDate)) ||
          isSameDay(date, hoveredDate));

      return (
        <div
          key={date}
          onClick={() => handleDateClick(date)}
          onMouseEnter={() => handleDateMouseEnter(date)}
          onMouseLeave={handleDateMouseLeave}
          style={{
            width: "40px",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0px",
            border: "1px solid #eeeeee",
            borderRadius: 0,
            backgroundColor: isInRange
              ? "#3f37c9"
              : isHoveredInRange
                ? "#b8c0ff"
                : "#fff",
            color: isInRange || isHoveredInRange ? "#fff" : "#000",
            cursor: "pointer",
          }}
        >
          <p style={{ fontWeight: "450", fontSize: 12 }}>{format(date, "d")}</p>
        </div>
      );
    });
  };

  return (
    <div>
      <Flex gap="3" align="center">
        <DropdownMenu.Root className="w-auto">
          <DropdownMenu.Trigger>
            <ShadcnButton variant="outline" align="center">
              <p style={{ fontSize: "11px", fontWeight: "500" }}>
                Group by:{" "}
                {
                  range_options?.find((doc) => doc.value === range.grouping)
                    ?.label
                }
              </p>
            </ShadcnButton>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content
            size="1"
            highContrast={true}
            style={{ width: "100%" }}
          >
            {range_options.map((group, index) => (
              <DropdownMenu.Item
                key={index}
                onClick={() => onSelectGrouping(group.value)}
                style={{ cursor: "pointer" }}
              >
                {group.label}
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
        {/* <Button color='iris' onClick={() => setShowCal(!showCal)} size={"1"} variant='soft' style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0, cursor: 'pointer' }}>
                    {convertTimestampToDateTime(startDate / 1000).date ?? "--:--:----"} - {convertTimestampToDateTime(endDate / 1000).date ?? "--:--:----"}
                </Button> */}

        <Popover>
          <PopoverTrigger asChild>
            <ShadcnButton variant="outline" align="center">
              <CalendarIcon
                height={"12px"}
                width={"12px"}
                style={{ marginRight: "10px" }}
              />
              <p style={{ fontSize: "11px", fontWeight: "500" }}>
                {convertTimestampToDateTime(startDate / 1000).date ??
                  "--:--:----"}{" "}
                -{" "}
                {convertTimestampToDateTime(endDate / 1000).date ||
                  "--:--:----"}
              </p>
            </ShadcnButton>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="range"
              numberOfMonths={1}
              selected={{
                from: startDate,
                to: endDate,
              }}
              onSelect={(range) => {
                if (range) {
                  onSetRange(
                    Math.round(
                      (new Date(range.from).getTime() +
                        new Date(range.from).getTimezoneOffset() * 60000) /
                        1000,
                    ),
                    Math.round(
                      (new Date(range.to).getTime() +
                        new Date(range.to).getTimezoneOffset() * 60000) /
                        1000,
                    ),
                  );
                } else {
                  const now = new Date();
                  onSetRange(
                    Math.round(
                      (now.getTime() + now.getTimezoneOffset() * 60000) / 1000,
                    ),
                    Math.round(
                      (now.getTime() + now.getTimezoneOffset() * 60000) / 1000,
                    ),
                  );
                }
              }}
            />
          </PopoverContent>
        </Popover>
      </Flex>

      {showCal && (
        <Box maxWidth={320} style={{ marginTop: 10 }}>
          <Card
            style={{
              maxWidth: 320,
              alignItems: "center",
              justifyItems: "center",
              display: "flex",
              flexDirection: "column",
              padding: 15,
              background: "#eeeeee",
            }}
          >
            {renderHeader()}
            {renderDaysOfWeek()}
            <div
              style={{ display: "flex", flexWrap: "wrap", maxWidth: "280px" }}
            >
              {renderCalendar()}
            </div>
          </Card>
        </Box>
      )}
    </div>
  );
};

export default DateRangePicker;
