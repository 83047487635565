import ActionBar from "components/core/ActionBar";
import Button from "components/core/Button";
import Modal from "components/core/Modal";
import { PageTitle } from "components/core/PageTitle";
import { PageWrapper } from "components/core/PageWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "../../../assets/icons/close-icon-black.svg";
import DeleteIcon from "../../../assets/icons/trash-grey-fig.svg";

import styled from "styled-components";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import Label from "components/core/Label";
import { toast } from "react-toastify";
import { borderRadius } from "@mui/system";
const EVENT_BASE_URL = "https://us.api.bland.ai/api/events";

export default function AllEvents() {
  const navigate = useNavigate();
  const apiKey = getApiKey();
  const [events, setEvents] = useState([]);
  const [createNewModal, setCreateNewModal] = useState(false);
  const [event_name, setEvent_name] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingEvents, setLoadingEvents] = useState(false);
  const [displayEventsDescription, setDisplayEventsDescription] =
    useState(false);
  const [sidebar, setSidebar] = useState(false);

  const handleDeleteEvent = async (event_id) => {
    try {
      const response = await fetch(`${EVENT_BASE_URL}/${event_id}/delete`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: apiKey,
        },
      });

      if (response.ok && response.status === 200) {
        const data = await response.json();
        await handleGetAllEvents();
        setLoadingEvents(false);
        return toast.success("Event removed successfully.");
      }

      return;
    } catch (error) {
      return toast.error("Error removing event.");
    }
  };

  const handleCreateNewEvent = async () => {
    try {
      setLoading(true);
      const response = await fetch(EVENT_BASE_URL, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          authorization: apiKey,
        },
        body: JSON.stringify({
          event_name: event_name,
        }),
      });

      if (response.ok && response.status === 200) {
        const data = await response.json();
        setLoading(false);
        setCreateNewModal(false);
        return navigate(
          `/dashboard?page=events&eventId=${data?.event?.[0]?.id}`,
        );
      }

      setLoading(false);
      setCreateNewModal(false);
    } catch (error) {
      setLoading(false);
      setCreateNewModal(false);
      return toast.error("Failed to create new event.");
    }
  };

  const handleGetAllEvents = async () => {
    try {
      setLoadingEvents(true);
      const response = await fetch(EVENT_BASE_URL, {
        method: "GET",
        headers: {
          authorization: apiKey,
        },
      });

      if (response.ok && response.status === 200) {
        const data = await response.json();
        if (data && data?.events?.length > 0) {
          setEvents(data.events);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleGetAllEvents().finally(() => {
      setLoadingEvents(false);
    });
  }, []);

  if (loadingEvents) return <div />;

  return (
    <PageWrapper>
      <ActionBar top spaceBetween>
        <div onClick={() => navigate("/dashboard?page=all-events")}>
          <PageTitle>Events</PageTitle>
        </div>

        <Button
          onClick={() => {
            setSidebar(false);
            setCreateNewModal(true);
          }}
          style={{ borderRadius: 6, height: 30 }}
        >
          <p>Create New Event</p>
        </Button>
      </ActionBar>

      <>
        {((events.length === 0 && !loading && !loadingEvents) ||
          displayEventsDescription) && (
          <div
            style={{
              background: "#f8f9fa",
              border: "1px solid #e5e5e5",
              borderRadius: 10,
              padding: 25,
              width: sidebar ? "65%" : "100%",
              transition: "transform 0.3s ease-in-out",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: 10,
              }}
            >
              <p style={{ fontSize: 16, fontWeight: "500" }}>
                No events have been created yet.
              </p>
              <p
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: "300",
                  textAlign: "center",
                }}
              >
                Create your first event. Events act as a way to construct calls
                easily from incoming data.
              </p>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  columnGap: 10,
                }}
              >
                <Button
                  onClick={() => setCreateNewModal(true)}
                  appearance={"outline"}
                  style={{ borderRadius: 6, height: 30 }}
                >
                  <p>Create New Event</p>
                </Button>
                <Button
                  onClick={() => setSidebar(true)}
                  appearance={"outline"}
                  style={{ borderRadius: 6, height: 30 }}
                >
                  <p>Learn about Events</p>
                </Button>
              </div>
            </div>
          </div>
        )}
      </>

      {events?.length !== 0 && (
        <Container>
          {events?.map((event, index) => (
            <Item
              onClick={() =>
                navigate(`/dashboard?page=events&eventId=${event.id}`)
              }
              key={index}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <p
                  style={{
                    fontSize: 22,
                    fontWeight: "400",
                    paddingBottom: "10%",
                  }}
                >
                  {event?.event_name?.toUpperCase()}
                </p>
                <IconContainer
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteEvent(event.id);
                  }}
                  style={{
                    height: 22,
                    width: 22,
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  <img src={DeleteIcon} alt="" style={{}} />
                </IconContainer>
              </div>
              <div
                style={{
                  paddingBottom: "10%",
                  fontSize: 12,
                  fontWeight: "200",
                }}
              >
                {EVENT_BASE_URL}/{event?.id}/handler
              </div>
            </Item>
          ))}
        </Container>
      )}

      <Modal
        open={createNewModal}
        onClose={() => setCreateNewModal(false)}
        height={"50%"}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <p style={{ fontWeight: "500", fontSize: 14 }}>New Event</p>
          <img
            onClick={() => setCreateNewModal(false)}
            src={CloseIcon}
            style={{ cursor: "pointer" }}
          />
        </div>

        <InputContainer style={{ marginTop: 20 }}>
          <Label>Event Name</Label>
          <StyledInput
            style={{ marginTop: 10 }}
            type="text"
            onChange={(e) => setEvent_name(e.target.value)}
            value={event_name}
          />
        </InputContainer>

        <Button
          loading={loading}
          onClick={() => handleCreateNewEvent()}
          style={{ borderRadius: 6, marginTop: 20 }}
        >
          <p>Configure Event</p>
        </Button>
      </Modal>

      {true && (
        <>
          <SidebarContainer>
            <Overlay isOpen={sidebar} onChange={() => setSidebar(false)} />
            <Sidebar isOpen={sidebar}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <p style={{ fontSize: 14, fontWeight: "450" }}>
                  Events Overview
                </p>
                <img
                  onClick={() => setSidebar(!sidebar)}
                  src={CloseIcon}
                  style={{ cursor: "pointer", height: 14, width: 14 }}
                  alt=""
                />
              </div>

              <div
                style={{
                  color: "grey",
                  fontSize: 13,
                  fontWeight: "300",
                  marginTop: 20,
                }}
              >
                <div>
                  <h1 className="text-2xl font-bold mb-4 text-gray-800">
                    Creating Your First Event with Bland AI
                  </h1>

                  <h2 className="text-xl font-semibold mb-3 text-gray-700">
                    Overview:
                  </h2>
                  <p className="mb-4 text-gray-600">
                    Events in Bland AI are powerful triggers that enable you to
                    construct and manage AI-driven phone calls based on incoming
                    data. By leveraging events, you can streamline the process
                    of setting up and customizing calls without the need to
                    modify your code frequently.
                  </p>

                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      background: "#e5e5e5",
                      marginBottom: 10,
                    }}
                  />

                  <h2 className="text-xl font-semibold mb-3 text-gray-700">
                    What are Events?
                  </h2>
                  <p className="mb-4 text-gray-600">
                    Events are configurable triggers that respond to specific
                    incoming data. When an event is triggered, it sends a
                    subjective JSON payload to Bland AI. This payload contains
                    all the necessary information to dynamically adjust call
                    settings within the Bland AI portal. This approach allows
                    you to integrate Bland AI into your systems with minimal
                    effort and maximum flexibility.
                  </p>

                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      background: "#e5e5e5",
                      marginBottom: 10,
                    }}
                  />

                  <h2 className="text-xl font-semibold mb-3 text-gray-700">
                    Advantages of Using Events:
                  </h2>
                  <ul className="list-disc list-inside mb-4 text-gray-600">
                    <li className="mb-2">
                      <strong className="text-gray-800">
                        Simplicity and Efficiency:
                      </strong>
                      <ul className="list-disc list-inside ml-4">
                        <li>
                          Integrate Bland AI into your systems only once. After
                          the initial setup, you can manage and adjust call
                          settings directly from the Bland AI portal.
                        </li>
                        <li>
                          Reduce the need for repetitive coding or complex
                          configurations. Events handle the dynamic aspects of
                          your calls, allowing for quick and easy adjustments.
                        </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <strong className="text-gray-800">
                        Flexibility and Customization:
                      </strong>
                      <ul className="list-disc list-inside ml-4">
                        <li>
                          Easily tailor your AI phone calls to specific
                          scenarios or data triggers. The JSON payload can be
                          customized to include any relevant information that
                          influences call behavior.
                        </li>
                        <li>
                          Adjust call parameters, messages, and behaviors in
                          real-time through the portal, ensuring your calls are
                          always up-to-date and relevant.
                        </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <strong className="text-gray-800">Scalability:</strong>
                      <ul className="list-disc list-inside ml-4">
                        <li>
                          As your needs grow, events can be scaled to handle
                          increasing volumes of data and call triggers. The
                          system is designed to accommodate varying levels of
                          complexity and data input.
                        </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <strong className="text-gray-800">
                        Centralized Management:
                      </strong>
                      <ul className="list-disc list-inside ml-4">
                        <li>
                          Manage all your call settings and configurations in
                          one centralized portal. This unified approach
                          simplifies the oversight and maintenance of your
                          AI-driven communication strategies.
                        </li>
                      </ul>
                    </li>
                  </ul>

                  <div
                    style={{
                      width: "100%",
                      height: 1,
                      background: "#e5e5e5",
                      marginBottom: 10,
                    }}
                  />

                  <h2 className="text-xl font-semibold mb-3 text-gray-700">
                    Getting Started:
                  </h2>
                  <ol className="list-decimal list-inside mb-4 text-gray-600">
                    <li className="mb-2">
                      <strong>Create Your First Event:</strong>
                      <ul className="list-disc list-inside ml-4">
                        <li>Log in to the Bland AI portal.</li>
                        <li>
                          Navigate to the "Events" tab and click on "Create
                          Event."
                        </li>
                        <li>
                          Define the trigger conditions for your event. Specify
                          the incoming data parameters that will activate the
                          event.
                        </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <strong>Configure the JSON Payload:</strong>
                      <ul className="list-disc list-inside ml-4">
                        <li>
                          Design your JSON payload to include all necessary
                          information for the call. This can include variables
                          like caller details, message content, and call
                          parameters.
                        </li>
                        <li>
                          Ensure the payload is structured according to Bland
                          AI’s specifications for seamless integration.
                        </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <strong>Adjust Call Settings:</strong>
                      <ul className="list-disc list-inside ml-4">
                        <li>
                          Use the Bland AI portal to customize the call settings
                          associated with your event. This includes selecting
                          scripts, setting call priorities, and defining
                          responses.
                        </li>
                        <li>
                          Test your event setup to ensure it behaves as expected
                          when triggered.
                        </li>
                      </ul>
                    </li>
                    <li className="mb-2">
                      <strong>Monitor and Optimize:</strong>
                      <ul className="list-disc list-inside ml-4">
                        <li>
                          Continuously monitor the performance of your events
                          through the portal’s analytics tools. Gather insights
                          to refine and optimize your call strategies over time.
                        </li>
                      </ul>
                    </li>
                  </ol>

                  <p className="text-gray-600">
                    By utilizing events, you can harness the full potential of
                    Bland AI's capabilities, making your AI phone calls more
                    responsive, customizable, and efficient. Get started today
                    and experience the ease and flexibility of event-driven call
                    management.
                  </p>

                  <Button
                    onClick={() => {
                      setSidebar(false);
                      setCreateNewModal(true);
                    }}
                    style={{ marginTop: 20, borderRadius: 6 }}
                  >
                    <p>Create New Event</p>
                  </Button>

                  <div style={{ height: 55, background: "transparent" }} />
                </div>
              </div>
            </Sidebar>
          </SidebarContainer>
        </>
      )}
    </PageWrapper>
  );
}

const SidebarContainer = styled.div``;

const Sidebar = styled.div`
  position: fixed;
  right: 0;
  top: 55px;
  height: 100%;
  width: 300px;
  border-left: 1px solid #eeeeee;
  background: white;
  padding: 25px 25px 25px 25px;
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s ease-in-out;
  z-index: 5000;
  overflow-y: scroll;

  /* Custom scrollbar styles */
  ::-webkit-scrollbar {
    width: 8px; /* Adjust the width for vertical scrollbar */
    height: 8px; /* Adjust the height for horizontal scrollbar */
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Optional: background of the track */
  }

  ::-webkit-scrollbar-thumb {
    background-color: #eeeeee; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Roundness of the scrollbar thumb */
    border: 2px solid transparent; /* Space around thumb */
    background-clip: padding-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #cccccc; /* Color of the scrollbar thumb on hover */
  }

  /* Firefox scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: #eeeeee #f1f1f1;

  /* Additional styles for the component */
  /* Adjust these as needed */
  padding: 16px;
  box-sizing: border-box;
`;

const Overlay = styled.div`
  background: #00000099;
  z-index: 500;
`;

const InputContainer = styled.div`
  padding: 15px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  font-size: 14px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 0px;

  @media (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const IconContainer = styled.div`
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 5px;
  background: #fff;

  &:hover {
    background: #eeeeee;
  }
`;

const Item = styled.div`
  cursor: pointer;
  position: relative;
  background-color: #fff;
  border: 0.1px solid #e5e5e5;
  color: #000;
  padding: 20px;
  text-align: flex-start;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Faint box shadow */
  overflow: hidden; /* Ensure the pseudo-element does not overflow */

  &::after {
    justify-items: center;
    align-items: center;
    display: flex;
    font-size: 12px;
    font-weight: 350px;
    content: "";
    position: absolute;
    padding: 20px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 25%;
    background-color: #eeeeee;
  }
`;
