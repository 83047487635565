import { Dialog, Switch, Tab } from "@headlessui/react";
import { useContext, useState } from "react";
import FlowContext from "../contextFlow";

const PressButtonModal = ({ id, data, setIsOpen }) => {
  const { triggerUpdate, elements } = useContext(FlowContext);
  const [name, setName] = useState(data.name || "");
  const [selectedTab, setSelectedTab] = useState(
    data.digitPrompt ? 2 : data.sequence ? 1 : 0,
  );
  const [digit, setDigit] = useState(data.digit || null);
  const [sequence, setSequence] = useState(data.sequence || "");
  const [digitPrompt, setDigitPrompt] = useState(data.digitPrompt || "");

  const numpadOptions = [
    ...Array(10).keys(),
    { key: "* (star)", value: "*" },
    { key: "# (pound)", value: "#" },
  ];

  const handleSave = () => {
    const updatedNodes = elements.nodes.map((el) => {
      if (el.id === id) {
        const updatedData = {
          name,
          ...(selectedTab === 0 && digit ? { digit } : {}),
          ...(selectedTab === 1 && sequence ? { sequence } : {}),
          ...(selectedTab === 2 && digitPrompt ? { digitPrompt } : {}),
          isStart: data.isStart ?? false,
        };

        return {
          ...el,
          data: updatedData,
          type: "Press Button",
        };
      }
      return el;
    });

    setIsOpen(false);
    triggerUpdate({ nodes: updatedNodes }, false);
  };

  return (
    <Dialog.Description className="space-y-6">
      <div>
        <label className="block font-medium text-gray-700 mb-2">Name:</label>
        <input
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="border border-gray-300 p-2 rounded-md w-full"
        />
      </div>

      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Tab.List className="flex space-x-1 rounded-xl bg-indigo-100 p-1">
          <Tab
            className={({ selected }) =>
              selected
                ? "bg-indigo-500 text-white rounded-lg py-2.5 px-5"
                : "text-gray-900 rounded-lg py-2.5 px-5"
            }
          >
            Single Digit
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? "bg-indigo-500 text-white rounded-lg py-2.5 px-5"
                : "text-gray-900 rounded-lg py-2.5 px-5"
            }
          >
            Sequence of Digits
          </Tab>
          <Tab
            className={({ selected }) =>
              selected
                ? "bg-indigo-500 text-white rounded-lg py-2.5 px-5"
                : "text-gray-900 rounded-lg py-2.5 px-5"
            }
          >
            Agent Decision
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="space-y-2">
            <label className="block text-[12px] font-medium text-gray-950">
              Button Digit to Press:
            </label>
            <select
              value={digit}
              onChange={(e) => setDigit(e.target.value)}
              className="border border-gray-300 text-gray-900 rounded-lg focus:ring-teal-500 focus:border-teal-500 block w-full p-2.5"
            >
              <option value="">Select a Digit</option>
              {numpadOptions.map((option) => (
                <option
                  key={option.key || option}
                  value={option.value || option}
                >
                  {option.key || option}
                </option>
              ))}
            </select>
            <label className="block text-gray-700 text-sm mt-2">
              Specify a single digit to be pressed automatically.
            </label>
          </Tab.Panel>
          <Tab.Panel className="space-y-2">
            <label className="block text-[12px] font-medium text-gray-950">
              Sequence of Digits:
            </label>
            <input
              type="text"
              value={sequence}
              onChange={(e) => setSequence(e.target.value)}
              className="border border-gray-300 p-2 rounded-md w-full"
            />
            <label className="block text-gray-700 text-sm mt-2">
              Specify a sequence of digits to be pressed in order.
            </label>
          </Tab.Panel>
          <Tab.Panel className="space-y-2">
            <label className="block text-[12px] font-medium text-gray-950">
              Agent Decision:
            </label>
            <textarea
              value={digitPrompt}
              onChange={(e) => setDigitPrompt(e.target.value)}
              className="border border-gray-300 p-2 rounded-md w-full"
              rows="4"
            ></textarea>
            <label className="block text-gray-700 text-sm mt-2">
              Provide a prompt for the agent to decide the action based on the
              current conversation context.
            </label>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      <button
        onClick={handleSave}
        className="bg-indigo-500 text-white py-2 px-4 rounded-md mt-4"
      >
        Save
      </button>
    </Dialog.Description>
  );
};

export default PressButtonModal;
