import { Badge, Card } from "@radix-ui/themes";
import { TrendingUp } from "lucide-react";
import {
  Label,
  Pie,
  PieChart,
  Bar,
  BarChart,
  XAxis,
  CartesianGrid,
  Cell,
} from "recharts";
import { LineChart, Line, YAxis, Tooltip, Legend } from "recharts";
import {
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
import { CardDescription, CardTitle } from "@/components/ui/card";

export const RecyclePieChart = ({
  chartData,
  chartTitle,
  chartRange,
  innerMetric,
  innerMetricLabel,
  chartLegend = false,
}) => {
  return (
    <div style={{}}>
      <div style={{}}>
        <CardTitle style={{ fontSize: 17 }}>{chartTitle}</CardTitle>
        <CardDescription style={{ marginTop: 5, fontSize: 12 }}>
          {chartRange}
        </CardDescription>
      </div>

      <ChartContainer
        config={{}}
        className="mx-auto aspect-square"
        style={{ marginTop: "15px" }}
      >
        <PieChart style={{ outline: "none" }}>
          <ChartTooltip
            cursor={false}
            content={<ChartTooltipContent indicator={"line"} />}
          />

          <Pie
            style={{ outline: "none" }}
            data={chartData}
            dataKey="value"
            nameKey="name"
            innerRadius={60}
            strokeWidth={5}
          >
            {chartData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                stroke={entry.color}
                fill={entry.color}
              />
            ))}
            <Label
              content={({ viewBox }) => {
                if (viewBox && "cx" in viewBox && "cy" in viewBox) {
                  return (
                    <text
                      x={viewBox.cx}
                      y={viewBox.cy}
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      <tspan
                        x={viewBox.cx}
                        y={viewBox.cy}
                        className="fill-foreground text-3xl font-bold"
                      >
                        {innerMetric}
                      </tspan>
                      <tspan
                        x={viewBox.cx}
                        y={(viewBox.cy || 0) + 24}
                        className="fill-muted-foreground"
                      >
                        {innerMetricLabel}
                      </tspan>
                    </text>
                  );
                }
              }}
            />
          </Pie>
        </PieChart>
      </ChartContainer>
    </div>
  );
};

export const RecycleChart = ({
  chartData,
  chartTitle,
  chartRange,
  xAxisTicks,
  axisPoints,
  footerTitle,
  footerDescription,
  showFooter = false,
  xAxisFirstLast = false,
  chartDefaultType = "bar",
  chartType = "bar",
  multiTypeChart = false,
  chartWidth = "100%",
  chartHeight = 180,
  width = 300,
  box = true,
}) => {
  const xAxisTicksTmp = chartData?.flatMap((item) => item.time);
  return (
    <>
      {box ? (
        <Card size={"1"}>
          <div style={{ marginBottom: "10px" }}>
            <CardTitle style={{ fontSize: 17 }}>{chartTitle}</CardTitle>
            <CardDescription style={{ marginTop: 5, fontSize: 12 }}>
              {chartRange}
            </CardDescription>
          </div>

          <ChartContainer
            config={{
              visitors: "nukk",
            }}
            className="mx-auto aspect-square"
          >
            <BarChart data={chartData}>
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator={"line"} />}
              />
              <CartesianGrid
                strokeWidth={0.5}
                stroke="#F0F0F0"
                vertical={false}
              />
              <XAxis
                dataKey={"time"}
                allowDataOverflow={true}
                tickLine={false}
                axisLine={false}
                tickMargin={5}
                tick={{ fontSize: 10, fontWeight: "500" }}
                tickFormatter={(value) => {
                  return value.split(",")[0];
                }}
                ticks={[...new Set(xAxisTicksTmp)]} // Ensure ticks are unique and reset on rerenders
              />
              {axisPoints &&
                axisPoints.map((ap, key_) => (
                  <Bar key={key_} dataKey={ap.key} fill={ap.color} radius={2} />
                ))}
            </BarChart>
          </ChartContainer>

          {showFooter && (
            <div style={{}}>
              <CardTitle>{footerTitle}</CardTitle>
              <CardDescription style={{ marginTop: 5 }}>
                {footerDescription}
              </CardDescription>
            </div>
          )}
        </Card>
      ) : (
        <>
          <div style={{ marginBottom: "10px" }}>
            <CardTitle style={{}}>{chartTitle}</CardTitle>
            <CardDescription style={{ marginTop: 3 }}>
              {chartRange}
            </CardDescription>
          </div>

          <ChartContainer
            config={{
              visitors: "nukk",
            }}
            className="mx-auto aspect-square"
          >
            <BarChart data={chartData}>
              <ChartTooltip
                cursor={false}
                content={<ChartTooltipContent indicator={"line"} />}
              />
              <CartesianGrid
                strokeWidth={0.5}
                stroke="#F0F0F0"
                vertical={false}
              />
              <XAxis
                dataKey={"time"}
                tickLine={false}
                tickMargin={5}
                axisLine={false}
                tick={{ fontSize: 10, fontWeight: "500" }}
                ticks={[
                  chartData[0]?.time,
                  chartData[chartData.length - 1]?.time,
                ]} // Show only first and last ticks
              />
              {axisPoints &&
                axisPoints.map((ap, key_) => (
                  <Bar key={key_} dataKey={ap.key} fill={ap.color} radius={2} />
                ))}
            </BarChart>
          </ChartContainer>

          {showFooter && (
            <div style={{}}>
              <CardTitle>{footerTitle}</CardTitle>
              <CardDescription style={{ marginTop: 5 }}>
                {footerDescription}
              </CardDescription>
            </div>
          )}
        </>
      )}
    </>
  );
};

export const RecycleStackedBarChart = ({
  chartData,
  chartTitle,
  chartRange,
  xAxisTicks,
  axisPoints,
  footerTitle,
  footerDescription,
  showFooter = false,
  xAxisFirstLast = false,
  chartDefaultType = "bar",
  chartType = "bar",
  multiTypeChart = false,
  chartWidth = "100%",
  chartHeight = 180,
  width = 300,
  box = true,
}) => {
  const xAxisTicksTmp = chartData?.flatMap((item) => item.period);
  return (
    <>
      {box ? (
        <Card size={"1"}>
          <div style={{ marginBottom: "10px" }}>
            <CardTitle style={{ fontSize: 17 }}>{chartTitle}</CardTitle>
            <CardDescription style={{ marginTop: 5, fontSize: 12 }}>
              {chartRange}
            </CardDescription>
          </div>

          <ChartContainer
            config={{
              calls: {
                label: "Calls",
              },
              call_length_sum: {
                label: "Call Length",
              },
              cost: {
                label: "Cost",
              },
            }}
            className="mx-auto aspect-w-16 aspect-h-9"
            style={{ maxHeight: 400, width: "100%" }}
          >
            <BarChart accessibilityLayer data={chartData}>
              <ChartTooltip
                wrapperStyle={{
                  width: 150,
                }}
                cursor={true}
                labelFormatter={(value) => {
                  const date = new Date(value);
                  return date.toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                  });
                }}
                content={<ChartTooltipContent indicator={"line"} />}
              />
              <ChartLegend
                style={{ fontSize: 12 }}
                content={<ChartLegendContent />}
              />
              <CartesianGrid
                strokeWidth={0.5}
                stroke="#F0F0F0"
                vertical={false}
              />
              <XAxis
                dataKey={"period"}
                allowDataOverflow={true}
                tickLine={false}
                axisLine={false}
                tickMargin={5}
                tick={{ fontSize: 10, fontWeight: "500" }}
                tickFormatter={(value) => {
                  const date = new Date(value);
                  return date.toLocaleDateString("en-US", {
                    month: "short",
                    day: "numeric",
                  });
                }}
                ticks={[...new Set(xAxisTicksTmp)]} // Ensure ticks are unique and reset on rerenders
              />
              <Bar
                stackId="b"
                dataKey="calls"
                fill="#3E63DD"
                radius={[0, 0, 4, 4]}
              />
              <Bar
                stackId="b"
                dataKey="call_length_sum"
                fill="#3B9EFF"
                radius={0}
              />
              <Bar
                stackId="b"
                dataKey="cost"
                fill="#4CCCE6"
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ChartContainer>

          {showFooter && (
            <div style={{}}>
              <CardTitle>{footerTitle}</CardTitle>
              <CardDescription style={{ marginTop: 5 }}>
                {footerDescription}
              </CardDescription>
            </div>
          )}
        </Card>
      ) : (
        <>
          <div style={{ marginBottom: "10px" }}>
            <CardTitle style={{}}>{chartTitle}</CardTitle>
            <CardDescription style={{ marginTop: 3 }}>
              {chartRange}
            </CardDescription>
          </div>

          <ChartContainer
            config={{
              visitors: "nukk",
            }}
            className="mx-auto aspect-square"
          >
            <BarChart data={chartData}>
              <ChartTooltip
                cursor={true}
                content={<ChartTooltipContent indicator={"line"} />}
              />
              <CartesianGrid
                strokeWidth={0.5}
                stroke="#F0F0F0"
                vertical={false}
              />
              <XAxis
                dataKey={"time"}
                tickLine={false}
                tickMargin={5}
                axisLine={false}
                tick={{ fontSize: 10, fontWeight: "500" }}
                ticks={[
                  chartData[0]?.time,
                  chartData[chartData.length - 1]?.time,
                ]} // Show only first and last ticks
              />
              {axisPoints &&
                axisPoints.map((ap, key_) => (
                  <Bar key={key_} dataKey={ap.key} fill={ap.color} radius={2} />
                ))}
            </BarChart>
          </ChartContainer>

          {showFooter && (
            <div style={{}}>
              <CardTitle>{footerTitle}</CardTitle>
              <CardDescription style={{ marginTop: 5 }}>
                {footerDescription}
              </CardDescription>
            </div>
          )}
        </>
      )}
    </>
  );
};

export const MultiLineChart = ({ chartData, chartRange }) => {
  const data = chartData?.map((item) => ({
    ...item,
    p10: item.p10,
    p50: item.p50,
    p90: item.p90,
    p99: item.p99,
  }));

  return (
    <Card size={"1"}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <div style={{ marginBottom: "10px" }}>
          <CardTitle style={{ fontSize: 17 }}>
            {"Agent Response Time"}
          </CardTitle>
          <CardDescription style={{ marginTop: 5, fontSize: 12 }}>
            {chartRange}
          </CardDescription>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            columnGap: 30,
            gap: 10,
          }}
        >
          <div>
            <Badge variant="surface">
              <p>P10</p>
            </Badge>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: 5,
                marginTop: 5,
              }}
            >
              <p style={{ fontSize: 13, fontWeight: "500" }}>
                {(
                  data
                    ?.flatMap((item) => item.p10)
                    ?.reduce((acc, curr) => acc + curr, 0) /
                  data?.flatMap((items) => items.p10).length
                )?.toFixed(0) || "-"}
              </p>
              <p style={{ fontSize: 13, fontWeight: "500", color: "gray" }}>
                ms
              </p>
            </div>
          </div>

          <div>
            <Badge variant="surface" color="green">
              <p>P50</p>
            </Badge>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: 5,
                marginTop: 5,
              }}
            >
              <p style={{ fontSize: 13, fontWeight: "500" }}>
                {(
                  data
                    ?.flatMap((item) => item.p50)
                    ?.reduce((acc, curr) => acc + curr, 0) /
                  data?.flatMap((items) => items.p50).length
                )?.toFixed(0) || "-"}{" "}
              </p>
              <p style={{ fontSize: 13, fontWeight: "500", color: "gray" }}>
                ms
              </p>
            </div>
          </div>

          <div>
            <Badge variant="surface" color="yellow">
              <p>P90</p>
            </Badge>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: 5,
                marginTop: 5,
              }}
            >
              <p style={{ fontSize: 13, fontWeight: "500" }}>
                {(
                  data
                    ?.flatMap((item) => item.p90)
                    ?.reduce((acc, curr) => acc + curr, 0) /
                  data?.flatMap((items) => items.p90).length
                )?.toFixed(0) || "-"}{" "}
              </p>
              <p style={{ fontSize: 13, fontWeight: "500", color: "gray" }}>
                ms
              </p>
            </div>
          </div>

          <div>
            <Badge variant="surface" color="red">
              <p>P99</p>
            </Badge>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: 5,
                marginTop: 5,
              }}
            >
              <p style={{ fontSize: 13, fontWeight: "500" }}>
                {(
                  data
                    ?.flatMap((item) => item.p99)
                    ?.reduce((acc, curr) => acc + curr, 0) /
                  data?.flatMap((items) => items.p99).length
                )?.toFixed(0) || "-"}
              </p>
              <p style={{ fontSize: 13, fontWeight: "500", color: "gray" }}>
                ms
              </p>
            </div>
          </div>
        </div>
      </div>

      <ChartContainer config={{}} style={{ marginTop: 10 }}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            allowDataOverflow={false}
            tick={{ fontSize: 10, fontWeight: "500" }}
            tickFormatter={(value) => {
              return new Date(value).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
              });
            }}
            tickMargin={5}
            tickLine={false}
            dataKey="period"
          />
          <YAxis
            tick={{ fontSize: 10, fontWeight: "500" }}
            width={25}
            tickLine={false}
            tickFormatter={(value) => {
              return `${(value / 1000)?.toFixed(1) || "-"}s`;
            }}
          />
          <ChartTooltip
            wrapperStyle={{ width: 150 }}
            itemStyle={{ fontSize: "20px" }}
            labelStyle={{ fontSize: "20px" }}
            contentStyle={{ fontSize: "20px" }}
            cursorStyle={{ fontSize: "20px" }}
            cursor={true}
            content={<ChartTooltipContent indicator={"line"} />}
            labelFormatter={(value) => {
              const date = new Date(value);
              return date.toLocaleDateString("en-US", {
                month: "long",
                day: "numeric",
              });
            }}
          >
            {(props) => (
              <div style={{ fontSize: "20px", fontWeight: "600" }}>
                <p style={{ fontSize: "20px" }}>
                  {props.payload[0].dataKey + "fdsf"}: {props.payload[0].value}{" "}
                  ms
                </p>
              </div>
            )}
          </ChartTooltip>
          <Legend />
          <Line
            type="linear"
            dataKey="p10"
            stroke="#8884d8"
            dot={false}
            strokeWidth={2.5}
          />
          <Line
            type="linear"
            dataKey="p50"
            stroke="#82ca9d"
            dot={false}
            strokeWidth={2.5}
          />
          <Line
            type="linear"
            dataKey="p90"
            stroke="#fca311"
            dot={false}
            strokeWidth={2.5}
          />
          <Line
            type="linear"
            dataKey="p99"
            stroke="#ef233c"
            dot={false}
            strokeWidth={2.5}
          />
        </LineChart>
      </ChartContainer>
    </Card>
  );
};
