import ReactModal from "react-modal";

export default function Modal({
  children,
  open,
  onClose,
  height,
  maxWidth = "500px",
}) {
  const h = height === "auto" ? "auto" : "500px";
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      border: "solid",
      borderRadius: "2px",
      borderColor: "#dddddd",
      width: "100%",
      height: "auto",
      maxHeight: h,
      maxWidth: maxWidth,
      padding: "25px",
      overflow: "scroll",
      zIndex: "6000",
    },
    overlay: {
      backgroundColor: "#00000044",
      zIndex: "5000",
    },
  };

  return (
    <ReactModal isOpen={open} onRequestClose={onClose} style={customStyles}>
      {children}
    </ReactModal>
  );
}
