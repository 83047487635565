import Switch from "components/core/Switch";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styled } from "@mui/material/styles";
import LightTooltip from "./ToolTipDesc";

export default function AMD({ fieldName }) {
  const longText = (
    <span>
      If <strong>amd</strong> is set to <strong>true</strong> or{" "}
      <strong>voicemail_action</strong> is set to <strong>ignore</strong>, then
      this will still work for voicemails, but it will not hang up for IVR
      systems.
    </span>
  );

  return (
    <Tooltip>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Switch sx={{ m: 5 }} fieldName={fieldName} label="AMD" />
        <LightTooltip title={longText} placement="right-start" arrow>
          <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
        </LightTooltip>
      </div>
    </Tooltip>
  );
}
