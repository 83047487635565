import { useFormContext } from "react-hook-form";
import { ErrorText } from "./ErrorText";
import { ErrorMessage } from "@hookform/error-message";
import Label from "./Label";
import styled from "styled-components";
import AlertBox from "./AlertBox";

export default function Input({
  fieldName,
  label,
  description,
  controller,
  alertType,
  alertText,
  ...props
}) {
  const {
    formState: { errors },
    register,
  } = useFormContext();

  return (
    <div className="flex flex-col gap-2 w-full">
      {label && <Label>{label}</Label>}
      {description && (
        <p className="text-secondary mb-2" style={{ fontSize: 12 }}>
          {description}
        </p>
      )}
      {!controller ? (
        <StyledInput {...register(fieldName)} {...props} />
      ) : (
        <StyledInput {...props} />
      )}
      {alertType && <AlertBox type={alertType} text={alertText} />}
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => <ErrorText message={message} />}
      />
    </div>
  );
}

const StyledInput = styled.input`
  text-align: left;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  width: 100%;
  padding: 0px 12px;
  background-color: #fbfbfa;
  height: 35px !important;
  cursor: pointer;
  &::placeholder {
    color: #a8a8a1;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: text;
  }
  &:disabled {
    opacity: 45%;
    cursor: not-allowed;
  }
`;
