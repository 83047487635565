import ActionBar from "components/core/ActionBar";
import { PageTitle } from "components/core/PageTitle";
import { PageWrapper } from "components/core/PageWrapper";
import styled from "styled-components";
import { Sidebar } from "../Infrastructure/components/sidebar";
import { useEffect, useState } from "react";
import CallAnalytics from "./components/CallAnalytics";
import DateRangePicker from "./components/CustomRangePicker";
import { Card, Flex, Grid, Spinner, Theme } from "@radix-ui/themes";
import {
  convertTimestampMMDDYYYY,
  convertTimestampToDateTime,
  getStartAndEndDate,
} from "./utils/utils";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import * as Icons from "@radix-ui/react-icons";
import {
  AudioLinesIcon,
  PhoneCallIcon,
  SpendIcon,
} from "assets/icons/navigationIcons";
import ActivityBarGraph from "../CallLogs/ActivityBarGraph";
import { EventStreamTable } from "./components/EventStreamTable";
import { useLocation, useNavigate } from "react-router-dom";
import { ExpandedAnalytics } from "./ExpandedAnalytics";
import { LiveCallsTable } from "./components/LiveCallsTable";
import { formatNumberWithCommas } from "../CallLogs/CallActivityBox";
import CallAnalysis from "./components/CallAnalysis";
import {
  MultiLineChart,
  RecycleChart,
  RecyclePieChart,
  RecycleStackedBarChart,
} from "./components/Chart";
import { toDtmy } from "utils/formatting/toDtmy";
import { CardDescription, CardTitle } from "@/components/ui/card";
import { ErrorLogTable } from "./components/ErrorLogsTable";
import PathwayHeatmap from "../ConvoPathways/HeatMap";
import AnalysisPresetConfigurator from "../Analysis/AnalysisPresetConfigurator";
// import DateTimeRangePicker from "./components/test-two";
const ANALYTICS_URI = "https://us.api.bland.ai/api/analytics";
const BASE_ENDPOINT = "https://us.api.bland.ai";

function formatMinutesToMMSS(minutes) {
  // Calculate total seconds from the minutes float
  const totalSeconds = Math.floor(minutes * 60);

  // Get the minutes part
  const mins = Math.floor(totalSeconds / 60);

  // Get the seconds part
  const secs = totalSeconds % 60;

  // Format seconds with leading zero if necessary
  const formattedSecs = secs.toString().padStart(2, "0");

  // Return just the seconds if mins is 0, otherwise return MM:SS
  return mins === 0 ? `${formattedSecs}s` : `${mins}m ${formattedSecs}s`;
}

const SIDETABS = [
  { label: "Call Analytics", value: "calls" },
  { label: "Call Analysis", value: "call_analysis" },
  { label: "Realtime Logs", value: "realtime_logs" },
  { label: "Live Calls", value: "live_calls" },
  { label: "Pathway Analytics", value: "pathway_analytics" },
  { label: "Analysis Presets", value: "analysis_presets" },

  // { label: "Billing & Spend", value: "billing" },
  // { label: "Latency", value: "latency" },
  { label: "Calls", value: "expanded-calls" },
];

const GROUPINGS = [
  { label: "Hour", value: "hour" },
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Analytics() {
  const [mainLoading, setMainLoading] = useState(true);
  const [selected, setSelected] = useState(SIDETABS[0]);
  const [latency, setLatency] = useState([]);
  const [flatMetics, setFlatMetric] = useState({
    unknown_count: null,
    voicemail_count: null,
    transferred_count: null,
    answered_count: null,
    avg_call_durr: null,
  });

  const convertReadable = (timestamp) => {
    return new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "short",
      day: "2-digit",
    }).format(timestamp);
  };

  const [charts, setCharts] = useState([
    {
      name: "calls",
      label: "Calls",
      chartData: [],
    },
    {
      name: "cost",
      label: "Credit Spend",
      chartData: [],
    },
    {
      name: "call_length_sum",
      label: "Call Duration",
      chartData: [],
    },
    {
      name: "call_length_avg",
      label: "Avg. Call Duration",
      chartData: [],
    },
    {
      name: "total_latency",
      label: "Average Call Duration",
      chartData: [],
    },
    {
      name: "performance_metrics",
      label: "Performance Metrics",
      chartData: [],
    },
    {
      name: "error_details",
      label: "Error Logs",
      chartData: [],
    },
  ]);

  const location = useLocation();
  const query = useQuery();
  const navigate = useNavigate();
  const qpCallId = query.get("call_id") || null;
  const apiKey = getApiKey();
  const [allChartData, setAllChartData] = useState([]);
  const [range, setRange] = useState({
    start: null,
    end: null,
    grouping: "day",
  });

  const get_queries = () => {
    const { startDate, endDate, error } = getStartAndEndDate("week", 1);
    let q = [
      {
        start_ts: range.start?.toString() ?? startDate,
        end_ts: range.end?.toString() ?? endDate,
        grouping: range.grouping,
        metric: "calls",
        count: false,
      },
      {
        start_ts: range.start?.toString() || startDate,
        end_ts: range.end?.toString() || endDate,
        grouping: range.grouping,
        metric: "call_length_sum",
        count: false,
      },
      {
        start_ts: range.start?.toString() || startDate,
        end_ts: range.end?.toString() || endDate,
        grouping: range.grouping,
        metric: "cost",
        count: false,
      },
      // {
      //     "start_ts": range.start?.toString() || startDate,
      //     "end_ts": range.end?.toString() || endDate,
      //     "grouping": range.grouping,
      //     "metric": "call_length_avg",
      //     "count": false
      // },
      {
        start_ts: range.start?.toString() || startDate,
        end_ts: range.end?.toString() || endDate,
        grouping: "day",
        metric: "answered_count",
        count: false,
      },
      {
        start_ts: range.start?.toString() || startDate,
        end_ts: range.end?.toString() || endDate,
        grouping: "day",
        metric: "transferred_count",
        count: false,
      },
      {
        start_ts: range.start?.toString() || startDate,
        end_ts: range.end?.toString() || endDate,
        grouping: "day",
        metric: "voicemail_count",
        count: false,
      },
      {
        start_ts: range.start?.toString() || startDate,
        end_ts: range.end?.toString() || endDate,
        grouping: "day",
        metric: "unknown_count",
        count: false,
      },
      {
        start_ts: range.start?.toString() || startDate,
        end_ts: range.end?.toString() || endDate,
        grouping: "day",
        metric: "avg_call_duration",
        count: false,
      },
      {
        start_ts: range.start?.toString() || startDate,
        end_ts: range.end?.toString() || endDate,
        grouping: range.grouping,
        metric: "performance_metrics",
        count: false,
      },
      {
        start_ts: range.start?.toString() || startDate,
        end_ts: range.end?.toString() || endDate,
        grouping: "day",
        metric: "error_details",
        count: false,
      },
    ];

    return q;
  };

  const post_processing = (data) => {
    // Start with the current state of charts
    let updatedCharts = charts;
    let allData = [];

    // Iterate through each object in the data array
    const fm = {
      ac: null,
      vc: null,
      tc: null,
      cd: null,
      uc: null,
    };
    for (const obj of data) {
      switch (obj.metric) {
        case "answered_count":
          fm.ac = obj?.data?.[0].value || 0;
          break;
        case "voicemail_count":
          fm.vc = obj?.data?.[0].value || 0;
          break;
        case "transferred_count":
          fm.tc = obj?.data?.[0].value || 0;
          break;
        case "avg_call_duration":
          fm.cd = obj?.data?.[0].value || 0;
          break;
        case "unknown_count":
          fm.uc = obj?.data?.[0].value || 0;
          break;
        default:
          break;
      }

      // Map through the charts to find the matching chart and update its data
      updatedCharts = updatedCharts.map((chart) => {
        if (chart.name === obj.metric) {
          // Update the chartData of the matching chart object
          return {
            ...chart,
            chartData: obj?.data?.map((item) => ({
              ...item,
              period: new Date(item.period).toLocaleString("en-US", {
                timeZone: "UTC",
              }),
            })), // Convert obj.data[index].period to local time zone
          };
        } else {
          return chart; // Return the unchanged chart object if no match
        }
      });

      // Collect data for calls, cost, and call_length_sum per shared period
      const combinedData = {};

      // Iterate through each object in the data array again to populate combinedData
      for (const obj of data) {
        if (["calls", "cost", "call_length_sum"].includes(obj.metric)) {
          for (const item of obj.data) {
            const period = new Date(item.period).toLocaleString("en-US", {
              timeZone: "UTC",
            });
            if (!combinedData[period]) {
              combinedData[period] = { period };
            }
            combinedData[period][obj.metric] = item.value;
          }
        }
      }

      // Clear allData array to ensure no duplicates
      allData = [];

      // Push combined data to allData array
      for (const period in combinedData) {
        allData.push(combinedData[period]);
      }
    }

    // Update the state with the accumulated updated charts array
    setCharts(updatedCharts);
    if (allData.length > 0) {
      setAllChartData(allData);
    }

    setFlatMetric({
      answered_count: fm.ac,
      voicemail_count: fm.vc,
      transferred_count: fm.tc,
      avg_call_durr: fm.cd,
      unknown_count: fm.uc,
    });
  };

  const getPercentage = (value) => {
    const total = charts
      ?.find((doc) => doc.name === "calls")
      .chartData?.reduce((acc, curr) => acc + curr.value, 0);
    return ((value / total) * 100).toFixed(2) + "%" || "-";
  };

  const load_analytics = async () => {
    try {
      setMainLoading(true);
      const response = await fetch(ANALYTICS_URI, {
        method: "POST",
        headers: {
          authorization: apiKey,
          "content-type": "application/json",
        },
        body: JSON.stringify({
          queries: get_queries() ?? [],
        }),
      });

      if (response.ok && response.status === 200) {
        const json = await response.json();
        const resData = json.data;
        if (json && json?.status === "success" && resData) {
          post_processing(resData);
        }
      }

      setMainLoading(false);
    } catch (error) {
      setMainLoading(false);
    }
  };

  const load_latency = async () => {
    try {
      const response = await fetch(`${BASE_ENDPOINT}/v1/infra/latency`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok && response.status === 200) {
        const json = await response.json();
        if (json?.latency && json?.latency?.length > 0) {
          setLatency(json?.latency);
        }
      }

      return;
    } catch (error) {}
  };

  useEffect(() => {
    load_latency();
  }, []);

  useEffect(() => {
    const { startDate, endDate, error } = getStartAndEndDate("week", 1);
    if (error) return;

    setRange((prevState) => ({
      ...prevState,
      start: startDate,
      end: endDate,
    }));
  }, []);

  useEffect(() => {
    if (!range.end || !range.start) return;
    load_analytics();
  }, [range.end, range.start, range.grouping]);

  const queryParamHandler = () => {
    const tab = query.get("tab");
    switch (tab) {
      case "realtime_logs":
        setSelected({ label: "Realtime Logs", value: "realtime_logs" });
        break;
      case "latency":
        setSelected({ label: "Latency", value: "latency" });
        break;
      case "calls":
        setSelected({ label: "Call Analytics", value: "calls" });
        break;
      case "live_calls":
        setSelected({ label: "Live Calls", value: "live_calls" });
        break;
      case "live_calls":
        setSelected({ label: "Pathway Analytics", value: "pathway_analytics" });
        break;
      case "analysis_presets":
        setSelected({ label: "Analysis Presets", value: "analysis_presets" });
        break;
      default:
        return;
    }
  };

  const updateQueryParam = (newTabValue) => {
    if (
      newTabValue === "calls" &&
      charts?.flatMap((doc) => doc?.chartData)?.length === 0
    ) {
      load_analytics();
    }

    const params = new URLSearchParams(location.search);
    params.set("tab", newTabValue);
    navigate({ search: params.toString() });
  };

  useEffect(() => {
    queryParamHandler();
  }, [location.search]);

  return (
    <PageWrapper>
      <ActionBar top>
        <PageTitle>Analytics</PageTitle>
      </ActionBar>

      <Sidebar
        label={"Built-in Insights"}
        options={SIDETABS?.filter((doc) => !doc.value.includes("expanded"))}
        onChange={(value) => {
          const val = SIDETABS?.find((doc) => doc.value === value);
          updateQueryParam(val.value);
          setSelected(val);
        }}
        selected={selected.value}
      />

      <Wrapper>
        <PageTitle style={{ fontWeight: 550, fontSize: 22 }}>
          {selected.label}
        </PageTitle>
        {selected.value === "latency" && (
          <Card style={{ marginTop: 43.5, padding: 0 }}>
            <div style={{ background: "#0000330f", padding: "25px 20px" }}>
              <PageTitle style={{ fontWeight: "525" }}>
                Performance Insights
              </PageTitle>
              <p>
                Monitor and track latency for Audio (TTS), Text (Inference), and
                Total Latency.
              </p>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                padding: "10px 20px",
                marginTop: 15,
              }}
            >
              <div style={{ marginTop: 0 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <IconContainer>
                    <AudioLinesIcon />
                  </IconContainer>
                  <div style={{ marginLeft: 15 }}>
                    <PageTitle style={{ fontSize: 16, fontWeight: "450" }}>
                      Total Latency (Median)
                    </PageTitle>
                    <p
                      style={{ color: "grey", fontWeight: "450", fontSize: 12 }}
                    >
                      {latency?.[latency?.length - 1]?.median_total * 1000} ms
                      on avg
                    </p>
                  </div>
                </div>

                <ActivityBarGraph
                  toolTipLabel={"Latency (ms)"}
                  showxLabel={false}
                  data={latency?.map((doc) => ({
                    time: doc?.day,
                    count: (doc?.median_total * 1000)?.toFixed(0),
                  }))}
                  CustomLabel={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 10,
                          color: "grey",
                          fontWeight: "500",
                        }}
                      >
                        {latency?.[0]?.day}
                      </p>
                      <p
                        style={{
                          fontSize: 10,
                          color: "grey",
                          fontWeight: "500",
                        }}
                      >
                        {latency?.[latency?.length - 1]?.day}
                      </p>
                    </div>
                  }
                />
              </div>
              <div
                style={{
                  background: "#eeeeee",
                  width: "100%",
                  height: 1,
                  marginTop: 15,
                }}
              />
              <div style={{ marginTop: 15 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <IconContainer>
                    <AudioLinesIcon />
                  </IconContainer>
                  <div style={{ marginLeft: 15 }}>
                    <PageTitle style={{ fontSize: 16, fontWeight: "450" }}>
                      Audio Latency (Median)
                    </PageTitle>
                    <p
                      style={{ color: "grey", fontWeight: "450", fontSize: 12 }}
                    >
                      {latency?.[latency?.length - 1]?.median_audio * 1000} ms
                      on avg
                    </p>
                  </div>
                </div>

                <ActivityBarGraph
                  toolTipLabel={"Latency (ms)"}
                  showxLabel={false}
                  data={latency?.map((doc) => ({
                    time: doc?.day,
                    count: (doc?.median_audio * 1000)?.toFixed(0),
                  }))}
                  CustomLabel={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 10,
                          color: "grey",
                          fontWeight: "500",
                        }}
                      >
                        {latency?.[0]?.day}
                      </p>
                      <p
                        style={{
                          fontSize: 10,
                          color: "grey",
                          fontWeight: "500",
                        }}
                      >
                        {latency?.[latency?.length - 1]?.day}
                      </p>
                    </div>
                  }
                />
              </div>
              <div
                style={{
                  background: "#eeeeee",
                  width: "100%",
                  height: 1,
                  marginTop: 15,
                }}
              />
              <div style={{ marginTop: 15 }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 10,
                  }}
                >
                  <IconContainer>
                    <AudioLinesIcon />
                  </IconContainer>
                  <div style={{ marginLeft: 15 }}>
                    <PageTitle style={{ fontSize: 16, fontWeight: "450" }}>
                      Text Latency (Median)
                    </PageTitle>
                    <p
                      style={{ color: "grey", fontWeight: "450", fontSize: 12 }}
                    >
                      {latency?.[latency?.length - 1]?.median_text * 1000} ms on
                      avg
                    </p>
                  </div>
                </div>

                <ActivityBarGraph
                  toolTipLabel={"Latency (ms)"}
                  showxLabel={false}
                  data={latency?.map((doc) => ({
                    time: doc?.day,
                    count: (doc?.median_text * 1000)?.toFixed(0),
                  }))}
                  CustomLabel={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        marginTop: 10,
                      }}
                    >
                      <p
                        style={{
                          fontSize: 10,
                          color: "grey",
                          fontWeight: "500",
                        }}
                      >
                        {latency?.[0]?.day}
                      </p>
                      <p
                        style={{
                          fontSize: 10,
                          color: "grey",
                          fontWeight: "500",
                        }}
                      >
                        {latency?.[latency?.length - 1]?.day}
                      </p>
                    </div>
                  }
                />
              </div>
            </div>
          </Card>
        )}
        {(selected.value === "calls" ||
          selected.value.includes("expanded")) && (
          <div style={{ marginTop: 7.5 }}>
            <DateRangePicker
              range={range}
              range_options={GROUPINGS}
              onSelectGrouping={(value) => {
                setRange((prevState) => ({ ...prevState, grouping: value }));
              }}
              onSetRange={(start, end) => {
                setRange((prevState) => ({ ...prevState, start, end }));
              }}
            />
          </div>
        )}
        {selected.value === "calls" && (
          <Grid
            width={"auto"}
            columns={{ initial: "1", xs: "1", sm: "2", md: "3" }}
            gap={"3"}
            style={{ marginBottom: 20, marginTop: "15px" }}
          >
            <Card style={{ padding: "20px" }}>
              <CardTitle style={{ fontSize: 14 }}>Total Calls</CardTitle>
              <CardDescription style={{ marginTop: 5, fontSize: 11 }}>
                {new Date(range.end * 1000).getDate() -
                  new Date(range.start * 1000).getDate() ===
                0
                  ? "Today"
                  : `Last ${new Date(range.end * 1000).getDate() - new Date(range.start * 1000).getDate()} days`}
              </CardDescription>
              <CardTitle style={{ marginTop: 15, fontSize: 20 }}>
                {mainLoading ? (
                  <Spinner size="3" />
                ) : (
                  formatNumberWithCommas(
                    charts
                      ?.find((doc) => doc.name === "calls")
                      .chartData?.reduce((acc, curr) => acc + curr.value, 0) ||
                      0,
                  ) || 0
                )}
              </CardTitle>
            </Card>

            <Card style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: 25,
                    background: "#02c39a",
                    marginRight: 10,
                  }}
                />
                <CardTitle style={{ fontSize: 14 }}>Answered</CardTitle>
              </div>
              <CardDescription style={{ marginTop: 5, fontSize: 11 }}>
                {new Date(range.end * 1000).getDate() -
                  new Date(range.start * 1000).getDate() ===
                0
                  ? "Today"
                  : `Last ${new Date(range.end * 1000).getDate() - new Date(range.start * 1000).getDate()} days`}
              </CardDescription>
              <CardTitle style={{ marginTop: 15, fontSize: 20 }}>
                {mainLoading ? (
                  <Spinner size="3" />
                ) : (
                  <>
                    {formatNumberWithCommas(flatMetics.answered_count || 0) ||
                      0}
                    <CardDescription style={{ marginTop: 5, fontSize: 11 }}>
                      {getPercentage(flatMetics.answered_count || 0)}
                    </CardDescription>
                  </>
                )}
              </CardTitle>
            </Card>

            <Card style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: 25,
                    background: "#f4d35e",
                    marginRight: 10,
                  }}
                />
                <CardTitle style={{ fontSize: 14 }}>Voicemail</CardTitle>
              </div>
              <CardDescription style={{ marginTop: 5, fontSize: 11 }}>
                {new Date(range.end * 1000).getDate() -
                  new Date(range.start * 1000).getDate() ===
                0
                  ? "Today"
                  : `Last ${new Date(range.end * 1000).getDate() - new Date(range.start * 1000).getDate()} days`}
              </CardDescription>
              <CardTitle style={{ marginTop: 15, fontSize: 20 }}>
                {mainLoading ? (
                  <Spinner size="3" />
                ) : (
                  <>
                    {formatNumberWithCommas(flatMetics.voicemail_count || 0) ||
                      0}
                    <CardDescription style={{ marginTop: 5, fontSize: 11 }}>
                      {getPercentage(flatMetics.voicemail_count || 0)}
                    </CardDescription>
                  </>
                )}
              </CardTitle>
            </Card>

            <Card style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: 25,
                    background: "#ced4da",
                    marginRight: 10,
                  }}
                />
                <CardTitle style={{ fontSize: 14 }}>Unknown</CardTitle>
              </div>
              <CardDescription style={{ marginTop: 5, fontSize: 11 }}>
                {new Date(range.end * 1000).getDate() -
                  new Date(range.start * 1000).getDate() ===
                0
                  ? "Today"
                  : `Last ${new Date(range.end * 1000).getDate() - new Date(range.start * 1000).getDate()} days`}
              </CardDescription>
              <CardTitle style={{ marginTop: 15, fontSize: 20 }}>
                {mainLoading ? (
                  <Spinner size="3" />
                ) : (
                  <>
                    {formatNumberWithCommas(flatMetics.unknown_count || 0) || 0}
                    <CardDescription style={{ marginTop: 5, fontSize: 11 }}>
                      {getPercentage(flatMetics.unknown_count || 0)}
                    </CardDescription>
                  </>
                )}
              </CardTitle>
            </Card>

            <Card style={{ padding: "20px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    height: 10,
                    width: 10,
                    borderRadius: 25,
                    background: "#f77f00",
                    marginRight: 10,
                  }}
                />
                <CardTitle style={{ fontSize: 14 }}>Transferred</CardTitle>
              </div>
              <CardDescription style={{ marginTop: 5, fontSize: 11 }}>
                {new Date(range.end * 1000).getDate() -
                  new Date(range.start * 1000).getDate() ===
                0
                  ? "Today"
                  : `Last ${new Date(range.end * 1000).getDate() - new Date(range.start * 1000).getDate()} days`}
              </CardDescription>
              <CardTitle style={{ marginTop: 15, fontSize: 20 }}>
                {mainLoading ? (
                  <Spinner size="3" />
                ) : (
                  <>
                    {formatNumberWithCommas(
                      flatMetics.transferred_count || 0,
                    ) || 0}
                    <CardDescription style={{ marginTop: 5, fontSize: 11 }}>
                      {getPercentage(flatMetics.transferred_count || 0)}
                    </CardDescription>
                  </>
                )}
              </CardTitle>
            </Card>

            <Card style={{ padding: "20px" }}>
              <CardTitle style={{ fontSize: 14 }}>Avg Call Duration</CardTitle>
              <CardDescription style={{ marginTop: 5, fontSize: 11 }}>
                {new Date(range.end * 1000).getDate() -
                  new Date(range.start * 1000).getDate() ===
                0
                  ? "Today"
                  : `Last ${new Date(range.end * 1000).getDate() - new Date(range.start * 1000).getDate()} days`}
              </CardDescription>
              <CardTitle
                style={{
                  marginTop: 15,
                  fontSize: 20,
                  textTransform: "initial",
                }}
              >
                {mainLoading ? (
                  <Spinner size="3" />
                ) : (
                  formatMinutesToMMSS(flatMetics?.avg_call_durr || 0) || 0
                )}
              </CardTitle>
            </Card>
          </Grid>
        )}
        {selected.value?.includes("expanded") && (
          <ExpandedAnalytics
            apiKey={apiKey}
            chartTitle={"Total Calls"}
            query={[
              {
                start_ts: range.start?.toString(),
                end_ts: range.end?.toString(),
                grouping: range.grouping,
                metric: "call_data",
                count: false,
              },
            ]}
            chartData={
              charts.find(
                (val) =>
                  selected.label?.toLowerCase() === val.label?.toLowerCase(),
              ).chartData
            }
          />
        )}
        {selected.value === "calls" && (
          <div style={{}}>
            <Grid
              columns={{ initial: "1", xs: "1", sm: "1", md: "1" }}
              gap={"3"}
              width={"auto"}
              style={{ marginTop: "15px" }}
            >
              {/* <Card size={"1"}>
                                <RecyclePieChart
                                    chartData={[
                                        {
                                            name: "Answered",
                                            value: (
                                                flatMetics.answered_count /
                                                charts?.find(doc => doc.name === "calls").chartData?.reduce((acc, curr) => acc + curr.value, 0)
                                            ) * 100,
                                            color: "#02c39a"
                                        },
                                        {
                                            name: "Voicemail",
                                            value: (
                                                flatMetics.voicemail_count /
                                                charts?.find(doc => doc.name === "calls").chartData?.reduce((acc, curr) => acc + curr.value, 0)
                                            ) * 100,
                                            color: "#f4d35e"
                                        },
                                        {
                                            name: "Transferred",
                                            value: (
                                                flatMetics.transferred_count /
                                                charts?.find(doc => doc.name === "calls").chartData?.reduce((acc, curr) => acc + curr.value, 0)
                                            ) * 100,
                                            color: "#f77f00"
                                        },
                                        {
                                            name: "Unknown",
                                            value: (
                                                flatMetics.unknown_count /
                                                charts?.find(doc => doc.name === "calls").chartData?.reduce((acc, curr) => acc + curr.value, 0)
                                            ) * 100,
                                            color: "#ced4da"
                                        }
                                    ]}
                                    chartTitle={"Pickup Rate"}
                                    chartRange={`${convertTimestampMMDDYYYY(range.start).date} - ${convertTimestampMMDDYYYY(range.end).date}`}
                                    innerMetric={`${flatMetics.answered_count|| ""}`}
                                    innerMetricLabel={"Answered"}
                                />
                            </Card> */}

              {allChartData.length > 0 && (
                <RecycleStackedBarChart
                  chartData={allChartData}
                  chartTitle={"Usage Insights"}
                  chartHeight={"300px"}
                  chartRange={`${convertTimestampMMDDYYYY(range.start).date} - ${convertTimestampMMDDYYYY(range.end).date}`}
                  showFooter={false}
                  footerTitle={"Lorem Ipsum"}
                  footerDescription={`By ${range.grouping}`}
                />
              )}

              {charts?.find((item) => item.name === "performance_metrics")
                ?.chartData?.length > 0 && (
                <MultiLineChart
                  chartRange={`${convertTimestampMMDDYYYY(range.start).date} - ${convertTimestampMMDDYYYY(range.end).date}`}
                  chartData={
                    charts.find((item) => item.name === "performance_metrics")
                      ?.chartData
                  }
                />
              )}

              {charts?.find((item) => item.name === "error_details")?.chartData
                ?.length > 0 && (
                <ErrorLogTable
                  chartRange={`${convertTimestampMMDDYYYY(range.start).date} - ${convertTimestampMMDDYYYY(range.end).date}`}
                  logs={
                    charts?.find((item) => item.name === "error_details")
                      ?.chartData
                  }
                  logsPerPage={25}
                />
              )}

              {/* <RecycleChart
                                chartData={charts?.find(doc => doc.name === "calls").chartData?.map(item => ({ time: convertReadable(new Date(item.period)), value: item.value }))}
                                chartTitle={"Calls"}
                                chartHeight={230}
                                chartRange={`${convertTimestampMMDDYYYY(range.start).date} - ${convertTimestampMMDDYYYY(range.end).date}`}
                                width={500}
                                showFooter={true}
                                footerTitle={`${formatNumberWithCommas(charts?.find(doc => doc.name === "calls").chartData?.reduce((acc, curr) => acc + curr.value, 0) || 0)
                                    } calls sent`}
                                footerDescription={`By ${range.grouping}`}
                                axisPoints={[{
                                    key: "value", color: "#5151CD"
                                }]}
                                xAxisTicks={charts?.find(doc => doc.name === "calls").chartData?.flatMap(item => item.period)}
                            />

                            <RecycleChart
                                chartData={charts?.find(doc => doc.name === "call_length_sum").chartData?.map(item => ({ time: convertReadable(new Date(item.period)), value: item.value }))}
                                chartTitle={"Minute Usage"}
                                chartHeight={230}
                                chartRange={`${convertTimestampMMDDYYYY(range.start).date} - ${convertTimestampMMDDYYYY(range.end).date}`}
                                width={500}
                                showFooter={true}
                                footerTitle={`${formatNumberWithCommas(charts?.find(doc => doc.name === "call_length_sum").chartData?.reduce((acc, curr) => acc + curr.value, 0)?.toFixed(0) || 0)
                                    } minutes`}
                                footerDescription={`By ${range.grouping}`}
                                axisPoints={[{
                                    key: "value", color: "#5151CD"
                                }]}
                            />

                            <RecycleChart
                                chartData={charts?.find(doc => doc.name === "cost").chartData?.map(item => ({ time: convertReadable(new Date(item.period)), value: item.value }))}
                                chartTitle={"Credit Usage"}
                                chartHeight={230}
                                chartRange={`${convertTimestampMMDDYYYY(range.start).date} - ${convertTimestampMMDDYYYY(range.end).date}`}
                                width={500}
                                showFooter={true}
                                footerTitle={`${charts?.find(doc => doc.name === "cost").chartData?.reduce((acc, curr) => acc + curr.value, 0)?.toFixed(2) || 0
                                    } credits used`}
                                footerDescription={`By ${range.grouping}`}
                                axisPoints={[{
                                    key: "value", color: "#5151CD"
                                }]}
                            /> */}

              {/* <Card style={{ borderRadius: 3 }}>
                                <CallAnalytics
                                    data={charts?.find(doc => doc.name === "call_length_avg")}
                                    grouping="week"
                                    icon={<AudioLinesIcon />}
                                    tooltip={"Minutes"}
                                    value_label={`min on avg`}
                                    calc_type="avg"
                                    viewBtn={false}
                                />
                            </Card> */}
            </Grid>
          </div>
        )}
        {selected.value === "call_analysis" && (
          <div style={{ marginTop: "7.5px" }}>
            <CallAnalysis />
          </div>
        )}
        {selected.value === "realtime_logs" && (
          <EventStreamTable callId={qpCallId} />
        )}
        {selected.value === "live_calls" && (
          <LiveCallsTable callId={qpCallId} />
        )}
        {selected.value === "pathway_analytics" && (
          <PathwayHeatmap callId={qpCallId} />
        )}
        {selected.value === "analysis_presets" && (
          <AnalysisPresetConfigurator />
        )}{" "}
      </Wrapper>
    </PageWrapper>
  );
}

const ChartBox = styled.div`
  width: 100%;
  padding: 20px;
  border: 2px solid #eeeeee;
  border-radius: 1px;
  background: #fbfafb;
`;

const Wrapper = styled.div`
  height: calc(100% - 54px);
  margin-top: 50px;
  top: 0px;
  left: 360px;
  overflow-y: scroll;
  position: absolute;
  width: calc(100% - 395px);
  padding: 20px;

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const IconContainer = styled.div`
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  padding: 5px;
  color: #e5e7eb;
`;
