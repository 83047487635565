import React, { useState, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ErrorText } from "./ErrorText";
import Label from "./Label";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button } from "@/components/ui/button";
import { getUserData } from "utils/funcs/browser/getUserData";
import { UserIcon } from "lucide-react";

export default function ReactPhoneInput({
  fieldName,
  label = null,
  hideLabel = false,
  description = null,
  hideAutoFormatToggle = false,
  required = true,
  showAutoFill = true,
}) {
  const user = getUserData();
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const [formatInput, setFormatInput] = useState(true);
  const [storedPhoneNumber, setStoredPhoneNumber] = useState(null);

  useEffect(() => {
    const storedNumber = user?.user?.phone_number;
    if (storedNumber) {
      setStoredPhoneNumber(storedNumber);
    }
  }, [user]);

  const handleAutoFill = () => {
    if (storedPhoneNumber) {
      setValue(fieldName, storedPhoneNumber);
    }
  };

  return (
    <div className="flex flex-col gap-2 w-full">
      {!hideLabel && (
        <Label className="text-sm font-medium w-full">
          {label ? label : "Phone number"}
        </Label>
      )}
      {description && (
        <p className="text-xs text-gray-500 font-normal">{description}</p>
      )}
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, value } }) =>
          formatInput ? (
            <PhoneInput
              value={typeof value === "number" ? value.toString() : value}
              country="us"
              onChange={onChange}
              inputProps={{
                required: required,
              }}
              inputStyle={{
                width: "100%",
                height: "35px",
                border: "1px solid #eeeeee",
                backgroundColor: "#fbfbfa",
              }}
            />
          ) : (
            <input
              type="text"
              value={typeof value === "number" ? value.toString() : value}
              onChange={onChange}
              className="w-full h-9 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
            />
          )
        }
      />
      {showAutoFill && storedPhoneNumber && (
        <Button
          onClick={handleAutoFill}
          type="button"
          variant="outline"
          className="mt-2 text-sm"
        >
          <UserIcon className="w-4 h-4 mr-2" />
          Use my phone number
        </Button>
      )}
      {!hideAutoFormatToggle && (
        <button
          type="button"
          onClick={() => setFormatInput((prev) => !prev)}
          className="text-sm text-blue-500 mt-2"
        >
          Toggle auto-formatting
        </button>
      )}
      <ErrorMessage
        errors={errors}
        name={fieldName}
        render={({ message }) => <ErrorText message={message} />}
      />
    </div>
  );
}
