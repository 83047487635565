import Button from "components/core/Button";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function ActionCard({
  title,
  description,
  beta,
  link,
  highlight,
}) {
  const navigate = useNavigate();
  return (
    <Card highlight={highlight} onClick={() => navigate(link)}>
      <TitleWrapper>
        <h5 className="font-medium text-black">{title}</h5>
        <p className="text-secondary">{description}</p>
      </TitleWrapper>
      <Button style={{ position: "absolute", bottom: "15px", right: "15px" }}>
        Get started
      </Button>
    </Card>
  );
}

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  border: ${({ highlight }) =>
    highlight ? "2px solid blue" : "1px solid #eeeeee"};
  border-radius: 5px;
  padding: 30px;
  position: relative;
  height: 100%;
  min-height: 250px;
  transition: all 0.3s ease-in-out;
  &:hover {
    box-shadow: 10px 5px 20px rgba(0, 0, 0, 0.1);
    transform: scale(1.02);
    cursor: pointer;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
