import { useParams } from "react-router-dom";
import styled from "styled-components";
import React, { useEffect, useState } from "react";
import AppDetailsComponent from "./SlugPage";
import { Header } from "./components/Header";
import { getApiKey } from "utils/funcs/browser/getApiKey";

export default function ToolSlug() {
  const [tool, setTool] = useState(null);
  const { id } = useParams();

  const loadTool = async () => {
    const res = await fetch(`/api/tools/showcase?id=${id}`, {
      method: "POST",
      body: JSON.stringify({
        action: "get",
      }),
      headers: {
        "Content-Type": "application/json",
        authorization: getApiKey(),
      },
    });
    const data = await res.json();
    console.log({ allToolData: data.data });
    setTool(data.data);
  };

  useEffect(() => {
    loadTool();
  }, [id]);

  if (!tool) {
    return <div />;
  }

  return (
    <Container>
      <Header />
      <div style={{ padding: "20px 70px" }}>
        <AppDetailsComponent app={tool} />
      </div>
    </Container>
  );
}

const Container = styled.div`
  margin: 0 auto;
`;
