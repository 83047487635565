import Input from "components/core/Input";
import { useFormContext } from "react-hook-form";

export default function StartTime({ fieldName }) {
  const { setValue, watch } = useFormContext();
  const currStartTime = watch(fieldName);

  const onChange = (e) => {
    setValue(fieldName, e.target.value);
  };

  const toLocalDateTimeString = (currStartTime) => {
    if (!currStartTime) {
      const date = new Date();
      const formattedDate = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}T${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
      return formattedDate;
    } else if (currStartTime) {
      const date = new Date(currStartTime);
      const formattedDate = `${date.getFullYear()}-${("0" + (date.getMonth() + 1)).slice(-2)}-${("0" + date.getDate()).slice(-2)}T${("0" + date.getHours()).slice(-2)}:${("0" + date.getMinutes()).slice(-2)}`;
      return formattedDate;
    }
  };

  return (
    <Input
      label="Start Time"
      fieldName={"start_time"}
      type="datetime-local"
      value={toLocalDateTimeString(currStartTime)}
      onChange={onChange}
    />
  );
}
