export const Container2xl = ({ children }) => {
  return (
    <div className="max-w-screen-2xl w-full mx-auto flex flex-col">
      {children}
    </div>
  );
};

export const ContainerXl = ({ children }) => {
  return (
    <div className="max-w-screen-xl w-full mx-auto flex flex-col">
      {children}
    </div>
  );
};

export const ContainerL = ({ children }) => {
  return (
    <div className="max-w-screen-lg w-full mx-auto flex flex-col">
      {children}
    </div>
  );
};

export const ContainerM = ({ children }) => {
  return (
    <div className="max-w-screen-md w-full mx-auto flex flex-col">
      {children}
    </div>
  );
};

export const ContainerS = ({ children }) => {
  return (
    <div className="max-w-[350px] w-full mx-auto flex flex-col">{children}</div>
  );
};

export const ContainerXs = ({ children }) => {
  return (
    <div className="max-w-xs w-full mx-auto flex flex-col">{children}</div>
  );
};
