import { PageWrapper } from "components/core/PageWrapper";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getUserData } from "utils/funcs/browser/getUserData";
import {
  getCreditPurchases,
  purchaseCredits,
} from "utils/funcs/purchase-credits/purchaseCredits";
import { PageTitle } from "components/core/PageTitle";
import ActionBar from "components/core/ActionBar";
import Button from "components/core/Button";
import Input from "components/core/Input";
import styled from "styled-components";
import Modal from "components/core/Modal";
import Checkout from "components/StripeElements/CheckoutForm/Checkout";
import Checkbox from "components/core/Checkbox";
import check from "assets/icons/check-icon.svg";
import "react-toastify/dist/ReactToastify.css";
import DownloadIcon from "../../../assets/icons/download-icon.svg";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export default function PurchaseCredits() {
  const { api_key, current_balance, user } = getUserData();
  const [openCheckoutModal, setOpenCheckoutModal] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [clickedIndex, setClickedIndex] = useState();
  const [loading, setLoading] = useState(false);
  const [creditPurchases, setCreditPurchases] = useState(null);

  const methods = useForm({
    defaultValues: {
      amount: 50,
      save_details: true,
    },
  });

  const currAmount = methods.watch("amount");

  const options = [
    {
      amount: 10,
      description:
        "110+ minutes of call time. Priority queue. Reduced latency.",
    },
    {
      amount: 20,
      description:
        "220+ minutes of call time. Priority queue. Reduced latency.",
    },
    {
      amount: 50,
      description:
        "Almost 9 hours of call time. Priority queue. Reduced latency.",
    },
  ];

  const onSubmit = async (formData) => {
    setLoading(true);
    const secret = await purchaseCredits(
      formData.amount,
      api_key,
      formData.save_details,
    );
    if (secret) {
      setClientSecret(secret);
      setLoading(false);
    }
  };

  const generateInvoicePDF = async (purchase) => {
    console.log({ purchase });
    const content = `
      <html id="invoice">
         <head>
            <meta charset="utf-8" />
            <title>BLAND INVOICE</title>
      
            <style>
               .invoice-box {
                  width: 794px;
                  margin: auto;
                  padding: 30px;
                  border: 1px solid #eee;
                  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
                  font-size: 16px;
                  line-height: 24px;
                  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
                  color: #555;
               }
      
               .invoice-box table {
                  width: 100%;
                  line-height: inherit;
                  text-align: left;
               }
      
               .invoice-box table td {
                  padding: 5px;
                  vertical-align: top;
               }
      
               .invoice-box table tr td:nth-child(2) {
                  text-align: right;
               }
      
               .invoice-box table tr.top table td {
                  padding-bottom: 20px;
               }
      
               .invoice-box table tr.top table td.title {
                  font-size: 45px;
                  line-height: 45px;
                  color: #333;
               }
      
               .invoice-box table tr.information table td {
                  padding-bottom: 40px;
               }
      
               .invoice-box table tr.heading td {
                  background: #eee;
                  border-bottom: 1px solid #ddd;
                  font-weight: bold;
               }
      
               .invoice-box table tr.details td {
                  padding-bottom: 20px;
               }
      
               .invoice-box table tr.item td {
                  border-bottom: 1px solid #eee;
               }
      
               .invoice-box table tr.item.last td {
                  border-bottom: none;
               }
      
               .invoice-box table tr.total td:nth-child(2) {
                  border-top: 2px solid #eee;
                  font-weight: bold;
               }
   
      
               /** RTL **/
               .invoice-box.rtl {
                  direction: rtl;
                  font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
               }
      
               .invoice-box.rtl table {
                  text-align: right;
               }
      
               .invoice-box.rtl table tr td:nth-child(2) {
                  text-align: left;
               }
            </style>
         </head>
      
         <body>
            <div class="invoice-box">
               <table cellpadding="0" cellspacing="0">
                  <tr class="top">
                     <td colspan="2">
                        <table>
                           <tr>      
                              <td>
                                 <h1><b>Credit Invoice: #${purchase?.id}</b></h1><br />
                                 Created: ${new Date(purchase?.created_at)?.toDateString()}<br />
                                 Status: Paid
                              </td>
                           </tr>
                        </table>
                     </td>
                  </tr>
      
                  <tr class="information">
                     <td colspan="2">
                        <table>
                           <tr>
                              <td>
                                 Bland AI<br />
                                 292 Ivy St,<br />
                                 San Francisco, CA 94102
                              </td>
      
                              <td>
                                 ${user?.first_name} ${user?.last_name}<br />
                                 ${user?.email || user?.phone_number}
                              </td>
                           </tr>
                        </table>
                     </td>
                  </tr>
      
                  <tr class="heading">
                     <td>Item</td>
      
                     <td>Price</td>
                  </tr>
      
                  <tr class="item last">
                     <td>Credit Top Up (${purchase?.amount?.toFixed(0)} credits)</td>
      
                     <td>${purchase?.amount?.toFixed(2)}</td>
                  </tr>
      
                  <tr class="total">
                     <td></td>
      
                     <td>Total: $${purchase?.amount?.toFixed(2)}</td>
                  </tr>
               </table>
            </div>
         </body>
      </html>`;

    const iframe = document.createElement("iframe");
    document.body.appendChild(iframe);
    const iframeDocument = iframe.contentWindow.document;

    iframeDocument.open();
    iframeDocument.write(content);
    iframeDocument.close();

    // // Wait for iframe content to fully load
    // await new Promise(resolve => iframe.onload = resolve);

    // Use html2canvas to capture the HTML content inside the iframe
    let canvas = await html2canvas(iframeDocument.body);

    let imgData = canvas.toDataURL("image/png");
    let pdf = new jsPDF();

    // Adjust the height and width of the PDF to match the captured content
    let pdfWidth = pdf.internal.pageSize.getWidth();
    let pdfHeight = (canvas.height * pdfWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("invoice.pdf");

    // Remove the iframe
    document.body.removeChild(iframe);
  };

  const handleGetCreditPurchases = async () => {
    try {
      const purchases = await getCreditPurchases();
      if (purchases && Array.isArray(purchases)) {
        purchases.sort(
          (a, b) => new Date(b.created_at) - new Date(a.created_at),
        );
        return setCreditPurchases(purchases);
      }
    } catch (e) {}
  };

  useEffect(() => {
    handleGetCreditPurchases();
  }, []);

  return (
    <PageWrapper style={{ height: "100%", backgroundColor: "#fbfbfa" }}>
      <ActionBar top>
        <PageTitle>Purchase Credits</PageTitle>
      </ActionBar>
      <FormProvider {...methods}>
        <FormWrapper>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Header>
              <h1 className="font-bold">
                Add more API credits to your account.{" "}
              </h1>
              <p className="text-secondary">
                Bland bills at $0.09/minute, pro-rated to the exact second, and
                only for call time (dial time excluded). Your current balance:
                <span className="font-bold">
                  {" " + parseFloat(current_balance).toFixed(2)} credits
                </span>
              </p>
              <p className="text-secondary"></p>
            </Header>
            {currAmount && currAmount > 0 && (
              <Value>
                <Check>
                  <img
                    src={check}
                    alt="check-point-icon"
                    width={15}
                    height={20}
                  />
                  <p>Send multiple calls at once</p>
                </Check>
                <Check>
                  <img
                    src={check}
                    alt="check-point-icon"
                    width={15}
                    height={20}
                  />
                  <p>{`Extended context window (5000 characters)`}</p>
                </Check>
                <Check>
                  <img
                    src={check}
                    alt="check-point-icon"
                    width={15}
                    height={20}
                  />
                  <p>Access to beta features on release</p>
                </Check>
              </Value>
            )}
            <Amount>
              <Options>
                {options.map((amt, index) => (
                  <>
                    <Option
                      onClick={() => methods.setValue("amount", amt.amount)}
                      key={index}
                    >
                      <div>
                        <h3 className="font-bold">${amt.amount}</h3>
                        <p className="text-secondary">{amt.description}</p>
                      </div>
                      <Button
                        submit
                        loading={loading && clickedIndex === index}
                        onClick={() => {
                          setOpenCheckoutModal(true);
                          setClickedIndex(index);
                        }}
                      >
                        Purchase
                      </Button>
                    </Option>
                  </>
                ))}
              </Options>
              <Buy>
                <CustomPurchase>
                  <Input
                    fieldName="amount"
                    type="number"
                    min="1"
                    max="10000"
                    label="Custom Amount ($)"
                    style={{ backgroundColor: "white" }}
                  />
                  <Button
                    submit
                    loading={loading && clickedIndex === "custom"}
                    onClick={() => {
                      setOpenCheckoutModal(true);
                      setClickedIndex("custom");
                    }}
                    disabled={currAmount < 1}
                  >
                    Purchase {currAmount} credits (${currAmount})
                  </Button>
                </CustomPurchase>
              </Buy>
              <Checkbox
                fieldName="save_details"
                label="Save payment method after checkout"
              />
            </Amount>
            <Modal
              height="auto"
              open={openCheckoutModal}
              onClose={() => setOpenCheckoutModal(false)}
            >
              <Confirm>
                <Checkout clientSecret={clientSecret} amount={currAmount} />
              </Confirm>
            </Modal>
          </Form>

          {creditPurchases && creditPurchases?.length > 0 && (
            <PurchaseLog>
              <p
                className="font-bold"
                style={{ fontSize: 20, fontWeight: "550" }}
              >
                Purchase History
              </p>
              <p
                style={{
                  marginTop: "-10px",
                  color: "grey",
                  marginBottom: "10px",
                }}
              >
                View and download invoices for all your credit top ups.
              </p>

              {creditPurchases.map((purchase, key) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <p style={{ fontWeight: "450", fontSize: 15 }}>
                      Credit Top up
                    </p>
                    <p
                      style={{
                        fontWeight: "350",
                        fontSize: 12,
                        marginTop: 2.5,
                      }}
                    >
                      ${purchase.amount?.toFixed(2)} •{" "}
                      {purchase.amount?.toFixed(0)} Credits
                    </p>
                  </div>

                  <div
                    onClick={() => generateInvoicePDF(purchase)}
                    style={{
                      marginLeft: "auto",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img
                      src={DownloadIcon}
                      alt=""
                      style={{ alignSelf: "center" }}
                    />
                    <p
                      style={{
                        textAlign: "right",
                        fontWeight: "350",
                        fontSize: 12,
                        color: "#3B36DF",
                      }}
                    >
                      Invoice
                    </p>
                  </div>
                </div>
              ))}
            </PurchaseLog>
          )}
        </FormWrapper>
      </FormProvider>
    </PageWrapper>
  );
}

const FormWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
`;

const PurchaseLog = styled.div`
  padding: 15px 25px 25px 25px;
  border-radius: 5px;
  width: 45%;
  max-width: 400px;
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-height: 650px;
  overflow: scroll;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-width: 650px;
  margin: auto;
`;

const Amount = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 100%;
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
`;

const Option = styled.div`
  border: 1px solid #eeeeee;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 30px 20px;
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
`;

const Value = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  row-gap: 6px;
  width: 100%;
`;

const Buy = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const Confirm = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  row-gap: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 100%;
`;

const CustomPurchase = styled.div`
  display: flex;
  flex-direction: row;
  align-items: end;
  column-gap: 9px;
`;

const Check = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
`;
