import axios from "axios";
import { useEffect, useState } from "react";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import { getStartAndEndDate } from "../utils/utils";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { minWidth } from "@mui/system";
import { Card, Skeleton } from "@radix-ui/themes";
import { PageTitle } from "components/core/PageTitle";
import { BarChart } from "./MiniBarChart";
import * as Icons from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { RecycleChart, RecyclePieChart } from "./Chart";
import { CardDescription, CardTitle } from "@/components/ui/card";

function determineType(value) {
  if (typeof value === "string") {
    if (value === "false") {
      return false;
    } else if (value === "true") {
      return true;
    } else {
      return `"${value}"`; // Wrap other strings in double quotes
    }
  } else if (typeof value === "boolean" || typeof value === "number") {
    return value; // Return boolean or number directly
  } else {
    return null; // Handle other types or return an appropriate default
  }
}

export default function CallAnalysis({}) {
  const [tableData, setTableData] = useState([]);
  const [totalsData, setTotalsData] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const loadAnalysisTableData = async () => {
    try {
      setLoading(true);
      const { startDate, endDate, error } = getStartAndEndDate("month", 1);
      const response = await axios({
        method: "GET",
        url: "/api/analysis/get",
        params: {
          p_start_ts: startDate,
          p_end_ts: endDate,
        },
        headers: {
          Authorization: getApiKey(),
        },
        validateStatus: false,
      });

      if (response.status === 200) {
        if (response.data.status === "success" && response.data.data) {
          const data = response.data.data;
          console.log(
            data?.map((doc) => ({ ...doc?.analysis })),
            "data",
          );
          getTotalsForKeyValues(data?.map((doc) => ({ ...doc?.analysis })));
          //transformTableData(data);
        }
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const transformTableData = (data) => {
    const transformed = [];
    for (const element of data) {
      const analysisObject = element?.analysis;
      const obj = {};
      obj["Call ID"] = element.c_id;
      obj["Created At"] = element.created_at;

      for (const [key, value] of Object.entries(analysisObject)) {
        obj[key] = value;
      }

      transformed.push(obj);
    }

    setTableData(transformed);
  };

  const getTotalsForKeyValues = (records) => {
    const counts = {};

    records.forEach((record) => {
      Object.keys(record).forEach((key) => {
        if (key === "call_id") return; // Ignore call_id

        const value = record[key];

        // Initialize the key in the counts object if it doesn't exist
        if (!counts[key]) {
          counts[key] = {};
        }

        // Convert null to string 'null' to use as key in the counts object
        const valueKey = value === null ? "null" : value.toString();

        // Initialize the value count if it doesn't exist
        if (!counts[key][valueKey]) {
          counts[key][valueKey] = 0;
        }

        // Increment the count for the value
        counts[key][valueKey]++;
      });
    });

    setTotalsData(counts);
  };

  function getShade(baseHex, value) {
    if (
      typeof baseHex !== "string" ||
      !/^#([0-9A-F]{3}){1,2}$/i.test(baseHex)
    ) {
      throw new Error("Invalid hex color");
    }
    if (typeof value !== "number" || value < 0 || value > 1000) {
      if (value > 1000) return baseHex;
      throw new Error("Value must be a number between 0 and 1000");
    }

    // Normalize the hex color
    if (baseHex.length === 4) {
      baseHex = "#" + [...baseHex.slice(1)].map((x) => x + x).join("");
    }

    // Convert hex to RGB
    let r = parseInt(baseHex.slice(1, 3), 16);
    let g = parseInt(baseHex.slice(3, 5), 16);
    let b = parseInt(baseHex.slice(5, 7), 16);

    // Define lower and upper limits for shading factor
    const minFactor = 0.8; // Minimum brightness factor (80% of original color)
    const maxFactor = 0.2; // Maximum brightness factor (20% of original color)

    // Calculate the shading factor within the defined limits
    let factor = minFactor + (maxFactor - minFactor) * (value / 1000);

    // Adjust the color intensity
    r = Math.round(r * (1 - factor) + 255 * factor);
    g = Math.round(g * (1 - factor) + 255 * factor);
    b = Math.round(b * (1 - factor) + 255 * factor);

    // Ensure RGB values are within the valid range
    r = Math.min(255, Math.max(0, r));
    g = Math.min(255, Math.max(0, g));
    b = Math.min(255, Math.max(0, b));

    // Convert RGB back to hex
    const toHex = (c) => c.toString(16).padStart(2, "0");
    const shadedHex = `#${toHex(r)}${toHex(g)}${toHex(b)}`;

    return shadedHex;
  }
  useEffect(() => {
    loadAnalysisTableData();
  }, []);

  return (
    <Wrapper>
      <BoxWrapper>
        {totalsData ? (
          <>
            {Object.keys(totalsData).map((key, ind) => {
              const boxTitle = key;
              const nestedObj = totalsData[key];
              const values = [];
              Object.keys(nestedObj).forEach((val, yInd) => {
                let obj = {};
                obj[val] = nestedObj[val];
                obj["name"] = val;
                obj["value"] = nestedObj[val];
                obj["color"] = getShade("#5151CD", nestedObj[val]);
                obj["label"] = `${val.substring(0, 10)}...`;
                console.log(obj, "obk");
                values.push(obj);
              });

              console.log({ values }, "values");

              return (
                <Box style={{ overflowY: "scroll" }}>
                  <div
                    style={{
                      marginBottom: "10px",
                      padding: "20px 20px 0px 20px",
                    }}
                  >
                    <CardTitle style={{}}>
                      {boxTitle?.replaceAll("_", " ")?.toUpperCase()}
                    </CardTitle>
                    <CardDescription style={{ marginTop: 3 }}>
                      {boxTitle}
                    </CardDescription>
                  </div>

                  <div
                    style={{
                      height: 1,
                      width: "100%",
                      background: "#eeeeee",
                      marginTop: "20px",
                    }}
                  />

                  <RecyclePieChart chartData={values} chartLegend={true} />

                  <div
                    style={{ height: 1, width: "100%", background: "#eeeeee" }}
                  />

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 0,
                      padding: 0,
                    }}
                  >
                    {values?.map((val, k) => (
                      <div
                        key={k}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          width: "100%",
                          borderBottom:
                            k + 1 === values?.length
                              ? "none"
                              : "1px solid #eeeeee",
                          padding: "5px 7px 5px 7px",
                        }}
                      >
                        <div
                          style={{
                            height: 5,
                            width: 5,
                            background: val.color,
                            marginRight: 5,
                            borderRadius: "2px",
                          }}
                        />
                        <p
                          style={{
                            fontSize: 10,
                            fontWeight: "500",
                            maxWidth: "60%",
                            overflow: "hidden",
                          }}
                        >
                          {Object.keys(val)?.[0]}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            columnGap: 5,
                            marginLeft: "auto",
                          }}
                        >
                          <p style={{ fontSize: 10, fontWeight: "400" }}>
                            {Object.values(val)?.[0]}
                          </p>
                          <Icons.ArrowRightIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate(
                                `/dashboard?page=call-logs&currPage=1&analysis_schema={"key":"${boxTitle}", "value":${determineType(Object.keys(val)?.[0])}}`,
                              );
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </Box>
              );
            })}
          </>
        ) : (
          <>
            <Skeleton height={"400px"} />
            <Skeleton height={"400px"} />
            <Skeleton height={"400px"} />
          </>
        )}
      </BoxWrapper>
    </Wrapper>
  );
}

const BoxWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  gap: 16px;
`;

const Wrapper = styled.div`
  width: 100%;
`;

const Box = styled.div`
  border-radius: 4px;
  border: 1px solid #eeeeee;
  min-height: 400px;
  max-height: 800px;
  overflow-y: scroll;

  /* Hide scrollbar for all browsers */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
`;
