import axios from "axios";

export const insertInbound = async (api_key, encrypted_key, numbers) => {
  const headers = {
    authorization: api_key,
    encrypted_key,
  };
  try {
    const { data } = await axios.post(
      `${"https://us.api.bland.ai"}/v1/inbound/insert`,
      { numbers },
      { headers },
    );
    return data;
  } catch (error) {
    console.log("upload inbound", error);
    return error.message;
  }
};
