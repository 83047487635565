import { Mixpanel } from "components/Mixpanel";
import { useRef, useState, useEffect } from "react";
import { FaCheck, FaCopy, FaEye, FaEyeSlash } from "react-icons/fa";

export default function WebhookSigningSecret({ user }) {
  const [hidden, setHidden] = useState(true);
  const [copied, setCopied] = useState(false);
  const [generating, setGenerating] = useState(false);
  const textRef = useRef();
  const [hiddenWebhookSigningSecret, setHiddenWebhookSigningSecret] = useState(
    "*".repeat(64),
  );

  const handleRevealClick = () => setHidden(!hidden);
  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(hiddenWebhookSigningSecret)
      .then(() => setCopied(true));
  };

  const GenerateNewWebhookSigningSecret = async () => {
    setGenerating(true);
    const headers = {
      Authorization: localStorage.getItem("auth"),
    };

    try {
      const data = await fetch("/api/user/newWebhookSecret", {
        method: "POST",
        headers: headers,
      }).then((res) => res.json());

      if (data?.webhook_signing_secret) {
        Mixpanel.track("Generated new webhook signing secret");
        setHiddenWebhookSigningSecret(data.webhook_signing_secret);
        setHidden(false);
        setCopied(false);
        setGenerating(false);
      }
    } catch (error) {
      console.error(error);
      setGenerating(false);
    }
  };

  useEffect(() => {
    if (hidden === true) {
      setHiddenWebhookSigningSecret("*".repeat(64));
    }
  }, [hidden]);

  return (
    <div className="flex flex-col space-y-7">
      <div className="flex flex-col space-y-3">
        <h2 className="text-2xl font-bold">Webhook Signing Secret</h2>
        <div className="flex justify-between items-center p-3 bg-gray-50 border border-gray-200 rounded-md">
          <div ref={textRef} className="text-black opacity-50 cursor-default">
            {hiddenWebhookSigningSecret}
          </div>
          <div className="flex space-x-2 text-black opacity-60">
            <button onClick={handleRevealClick} className="p-1">
              {hidden ? <FaEye /> : <FaEyeSlash />}
            </button>
            <button onClick={handleCopyClick} className="p-1">
              {copied ? <FaCheck /> : <FaCopy />}
            </button>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <p className="text-lg">
            Never share this publicly. Warning: Once generated, the secret will
            only be viewable once and cannot be retrieved later without
            replacing it.
          </p>
          <button
            onClick={GenerateNewWebhookSigningSecret}
            className="text-black font-medium text-xs hover:underline"
          >
            {generating ? "Replacing..." : "Replace Secret"}
          </button>
        </div>
      </div>
    </div>
  );
}
