import Button from "components/core/Button";
import Label from "components/core/Label";
import React from "react";
import { useFieldArray, useFormContext, Controller } from "react-hook-form";
import { PlusIcon, TrashIcon } from "@radix-ui/react-icons";

const AnalysisSchemaCo = ({ fieldName, label = true }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName, // Corrected from fieldName to name
  });

  return (
    <div>
      {label ? (
        <>
          <h4 className="font-semibold">Analysis Schema</h4>
          <p
            style={{
              marginTop: "5px",
              color: "GrayText",
              fontSize: 10,
              marginBottom: "10px",
            }}
          >
            The analysis schema in Bland AI is a feature that allows you to
            define a JSON schema for extracting specific information from a
            call. This schema helps in retrieving structured data such as email
            addresses, names, appointment times, or any other custom data you
            need from the call.
          </p>
        </>
      ) : (
        <>
          <Label>Analysis Schema</Label>
          <p
            style={{
              marginTop: "10px",
              color: "GrayText",
              fontSize: 10,
              marginBottom: "10px",
            }}
          >
            The analysis schema in Bland AI is a feature that allows you to
            define a JSON schema for extracting specific information from a
            call. This schema helps in retrieving structured data such as email
            addresses, names, appointment times, or any other custom data you
            need from the call.
          </p>
        </>
      )}
      {fields.map((field, index) => (
        <div key={field.id} className="flex space-x-0">
          <Controller
            name={`${fieldName}.${index}.key`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <input
                {...field}
                type="text"
                placeholder="Analysis (Did human answer phone)"
                className="border p-2 w-[50%]"
                style={{ outline: "none" }}
              />
            )}
          />
          <Controller
            name={`${fieldName}.${index}.value`}
            control={control}
            defaultValue="string"
            render={({ field }) => (
              <input
                {...field}
                type="text"
                placeholder="Description (True if human answered and spoke to agent, False if not)"
                className="border p-2 w-[50%]"
                style={{ outline: "none" }}
              />
            )}
          />
          <button
            type="button"
            onClick={() => remove(index)}
            className="text-white p-2 w-[40px] flex items-center justify-center border border-[#e8e8e8]"
          >
            <p style={{ color: "#000" }}>-</p>
          </button>
        </div>
      ))}
      <div style={{ display: "flex", marginTop: "10px" }}>
        <Button
          appearance={"outline"}
          onClick={() => append({ key: "", value: "string" })}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              columnGap: "5px",
            }}
          >
            <PlusIcon />
            <span>Add Analysis</span>
          </div>
        </Button>
      </div>
    </div>
  );
};

export default AnalysisSchemaCo;
