import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { format } from "date-fns";
import { Download, AlertCircle, ChevronDown, ChevronUp } from "lucide-react";
import { getUserData } from "utils/funcs/browser/getUserData";

const PaymentHistory = () => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const user = getUserData();

  useEffect(() => {
    const fetchInvoices = async () => {
      console.log(user);
      try {
        const response = await fetch("/api/billing/list_invoices", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("auth"),
          },
          body: JSON.stringify({ user_id: user.user.id }),
        });

        if (!response.ok) {
          throw new Error(
            "Unable to retrieve invoice information at this time.",
          );
        }

        const data = await response.json();
        setInvoices(data.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchInvoices();
  }, [user.id]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Wrapper>
      <Header onClick={toggleExpanded}>
        <h5 className="text-lg font-semibold text-gray-900">Payment History</h5>
        {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
      </Header>
      <Content $isExpanded={isExpanded}>
        {loading ? (
          <LoadingMessage>Loading payment history...</LoadingMessage>
        ) : error ? (
          <ErrorMessage>
            <AlertCircle size={20} />
            <span>{error}</span>
            <p>
              Please try again later or contact support if the problem persists.
            </p>
          </ErrorMessage>
        ) : invoices.length === 0 ? (
          <NoInvoicesMessage>No payment history available.</NoInvoicesMessage>
        ) : (
          <Table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Amount</th>
                <th>Status</th>
                <th>Invoice</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((invoice) => (
                <tr key={invoice.id}>
                  <td>
                    {format(new Date(invoice.created * 1000), "MMM dd, yyyy")}
                  </td>
                  <td>${(invoice.amount_paid / 100).toFixed(2)}</td>
                  <td>
                    {invoice.status.charAt(0).toUpperCase() +
                      invoice.status.slice(1)}
                  </td>
                  <td>
                    <a
                      href={invoice.invoice_pdf}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <DownloadButton>
                        <Download size={16} />
                        PDF
                      </DownloadButton>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Content>
    </Wrapper>
  );
};

export default PaymentHistory;

const Wrapper = styled.div`
  // Removed border and border-radius
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }
`;

const Content = styled.div`
  max-height: ${({ $isExpanded }) => ($isExpanded ? "1000px" : "0")};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 0.75rem;
    text-align: left;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
  }

  th {
    font-weight: 600;
    color: ${({ theme }) => theme.colors.text};
  }

  td {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0.75rem;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.radii.sm};
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: ${({ theme }) => theme.colors.muted};
  }
`;

const LoadingMessage = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-style: italic;
  padding: 1rem 0;
`;

const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 0;
  background-color: ${({ theme }) => theme.colors.error.bg};
  color: ${({ theme }) => theme.colors.error.text};

  svg {
    color: ${({ theme }) => theme.colors.error.text};
  }

  p {
    font-size: ${({ theme }) => theme.fontSizes.sm};
    text-align: center;
  }
`;

const NoInvoicesMessage = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  padding: 1rem 0;
`;
