import axios from "axios";

export const getVoice = async (voice, apiKey, rating) => {
  try {
    const { data } = await axios.get(
      `https://us.api.bland.ai/v1/voices/` + voice.id,
      {
        headers: {
          "Content-Type": "application/json",
          authorization: apiKey,
        },
      },
    );
    return data;
  } catch (error) {
    console.error("Error retrieving voice:", error);
  }
};
