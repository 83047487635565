import React from "react";
import { Dialog } from "@headlessui/react";
import { FolderIcon } from "lucide-react";

const MovePathwayDialog = ({
  movingPathwayId,
  isOpen,
  onClose,
  folders,
  onMove,
}) => {
  const handleOnMove = (folderId) => {
    const pathwayId = movingPathwayId;
    onClose();
    onMove(pathwayId, folderId);
  };

  const renderFolders = (folderList, level = 0) => {
    return folderList.map((folder) => (
      <div key={folder.id} className={`ml-${level * 4}`}>
        <button
          onClick={() => handleOnMove(folder.id)}
          className="flex items-center w-full text-left p-2 hover:bg-gray-100 rounded"
        >
          <FolderIcon className="mr-2" size={16} />
          <span className="truncate">{folder.name}</span>
        </button>
        {folder.children &&
          Array.isArray(folder.children) &&
          renderFolders(
            folder.children.filter((child) => child.type === "folder"),
            level + 1,
          )}
      </div>
    ));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-sm rounded bg-white p-6 shadow-xl">
          <Dialog.Title className="text-lg font-medium mb-4">
            Move Pathway
          </Dialog.Title>
          <div className="max-h-60 overflow-y-auto">
            <button
              onClick={() => handleOnMove(null)}
              className="flex items-center w-full text-left p-2 hover:bg-gray-100 rounded mb-2"
            >
              <FolderIcon className="mr-2" size={16} />
              <span className="truncate">All Pathways</span>
            </button>
            {renderFolders(folders)}
          </div>
          <button
            type="button"
            className="inline-flex justify-center mt-4 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            onClick={onClose}
          >
            Cancel
          </button>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

export default MovePathwayDialog;
