import { useEffect, useState } from "react";
import styled from "styled-components";
import RatingsButton from "./RatingsButton";
import VoiceCard from "./VoiceCard";
import { Dialog } from "@headlessui/react";
import { toast } from "react-toastify";
import { getUserData } from "utils/funcs/browser/getUserData";
import Modal from "components/core/Modal";
import Button from "components/core/Button";
import { getApiKey } from "utils/funcs/browser/getApiKey";

export default function UserVoices({ voices, setShowCloneModal }) {
  const { user } = getUserData();
  const [sampleText, setSampleText] = useState(
    "Hey there, this is Blandie. How are you doing today?",
  );
  const [tempSampleText, setTempSampleText] = useState(sampleText);
  const [publishModal, setPublishModal] = useState(false);
  const [showCustomizeModal, setShowCustomizeModal] = useState(false);
  const [active_pv, setActive_pv] = useState(null); // this is to hold the voice that is being published (for modal ref).
  const [loadingPublish, setLoadingPublish] = useState(false);

  const curatedVoicesList = voices.filter(
    (voice) => voice?.tags?.includes("Bland Curated") && voice?.user_id == null,
  );

  const privateVoicesList = voices.filter(
    (voice) => voice.user_id != null && !voice.name.includes("Public -"),
  );

  const handleSave = () => {
    setSampleText(tempSampleText);
    setShowCustomizeModal(false);
    toast.success("TTS Text updated! Play any sample to hear it!");
  };

  const handleCancel = () => {
    setTempSampleText(sampleText);
    setShowCustomizeModal(false);
  };

  const handlePublishVoice = async () => {
    try {
      if (!active_pv) return;
      setLoadingPublish(true);
      const response = await fetch(
        `https://us.api.bland.ai/v1/voices/${active_pv.id}/publish`,
        {
          method: "POST",
          headers: {
            authorization: getApiKey(),
          },
        },
      );

      if (response !== 200 && !response.ok) {
        setActive_pv(null);
        setLoadingPublish(false);
        setPublishModal(false);
        return toast.error("Error publishing voice.");
      }

      setLoadingPublish(false);
      setPublishModal(false);
      toast.success("Your voice has been published successfully.");
      window.location.reload();
      return;
    } catch (error) {
      setPublishModal(false);
      setLoadingPublish(false);
      return toast.success("Error publishing voice");
    }
  };

  //    useEffect(() => {
  //       if (voices.length > 0) {
  //          const formattedVoices = voices.map((voice) => ({
  //             //Voice: voice.voice,
  //             Name: voice.name,
  //             ID: voice.voice_id,
  //             Ratings: voice.ratings,
  //             Action: <RatingsButton voice={voice} />,
  //          }));
  //          setVoiceData(formattedVoices);
  //       }
  //    }, [voices]);

  return (
    <>
      {voices.length > 0 ? (
        <>
          <>
            <div className="flex items-center justify-between">
              <h1 className="">Curated Bland Voices</h1>
              <div className="flex items-center space-x-2">
                <button
                  className="rounded-md bg-indigo-100 text-blue-800 px-6 py-4 hover:bg-indigo-300 hover:text-blue-900 shadow-lg transition ease-in-out duration-300"
                  onClick={() => {
                    setTempSampleText(sampleText);
                    setShowCustomizeModal(true);
                  }}
                >
                  Customize TTS Text
                </button>
              </div>
            </div>
            <div className="pt-3 flex flex-row mx-auto flex-wrap">
              {[...curatedVoicesList].map((value, index) => (
                <VoiceCard
                  key={index}
                  data={value}
                  text={sampleText}
                  isPublicTab={false}
                />
              ))}
            </div>
            <h2 className="pt-8 pb-4">Private & Cloned Voices</h2>

            {voices.length > 0 ? (
              <div className="pt-3 flex flex-row flex-wrap mx-auto">
                {[...privateVoicesList].map((value, index) => {
                  // basically we need to set a boolean based on whether the voice is theirs and cloned;
                  // so maybe something like userCreated:boolean (meaning they created) -- if they created,
                  // we need to show something on Voice card. Voice card is a resused component.

                  const hasBeenMadePublic = voices?.find((doc) => {
                    const possible_name = doc?.name
                      ?.split("Public -")?.[1]
                      ?.trim();
                    console.log({ doc, foo: "bar" });
                    if (possible_name === value.name) {
                      return true;
                    }

                    return false;
                  })
                    ? true
                    : false || false;

                  return (
                    <VoiceCard
                      userCreated={user.id === value.user_id}
                      key={index}
                      data={value}
                      text={sampleText}
                      isPublicTab={false}
                      hasBeenMadePublic={hasBeenMadePublic}
                      onPublishBtn={(voiceData) => {
                        setPublishModal(!publishModal);
                        setActive_pv(voiceData);
                      }}
                    />
                  );
                })}
              </div>
            ) : (
              <p>Error: Couldn't get voice data.</p>
            )}
          </>
          <Dialog
            open={showCustomizeModal}
            onClose={() => setShowCustomizeModal(false)}
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30 z-[5001]" />
            <Dialog.Panel>
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-[5002] border border-gray-300 bg-white p-8 rounded-lg shadow-lg w-[500px] space-y-4">
                <Dialog.Title className="font-semibold text-gray-900">
                  Customize TTS Sample Text
                </Dialog.Title>
                <Dialog.Description className="text-lg text-gray-600">
                  Enter the text you want to hear from our collection of voices!
                </Dialog.Description>
                <textarea
                  type="text"
                  value={tempSampleText}
                  onChange={(e) => setTempSampleText(e.target.value)}
                  className="w-full h-40 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500 resize-y"
                />
                <div className="flex justify-end space-x-4">
                  <button
                    onClick={handleCancel}
                    className="px-4 py-2 font-medium text-gray-600 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleSave}
                    className="px-4 py-2 font-medium text-white bg-indigo-500 border border-transparent rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>

          <Modal
            open={publishModal}
            maxWidth="40%"
            onClose={() => setPublishModal(false)}
          >
            <p
              style={{
                fontSize: "18px",
                fontWeight: "500",
                margin: 0,
                padding: 0,
              }}
            >
              You are publishing a Voice.
            </p>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "450",
                margin: 0,
                padding: 0,
                marginTop: "5px",
                color: "#adb5bd",
              }}
            >
              By publishing a voice, you are making this voice public to use by
              any user on Bland.
            </p>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                columnGap: "15px",
                alignItems: "center",
                marginTop: "25px",
              }}
            >
              <div style={{ width: "50%" }}>
                <Button
                  onClick={() => {
                    setPublishModal(false);
                    setActive_pv(null);
                  }}
                  appearance={"outline"}
                >
                  Cancel
                </Button>
              </div>

              <div style={{ width: "50%" }}>
                <Button
                  loading={loadingPublish}
                  onClick={() => handlePublishVoice()}
                  type={"button"}
                >
                  Publish
                </Button>
              </div>
            </div>
          </Modal>
        </>
      ) : (
        <Wrapper>
          <p>No voice clones uploaded yet.</p>
          <Link onClick={() => setShowCloneModal(true)}>
            Upload Voice Clone
          </Link>
        </Wrapper>
      )}
    </>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 5px;
`;

const Link = styled.p`
  color: #433dff;
  font-weight: 500;
  cursor: pointer;
`;
