import axios from "axios";

export const rateVoice = async (voice, apiKey, rating) => {
  console.log(rating);
  try {
    const { data } = await axios.post(
      `https://us.api.bland.ai/v1/voices/` + voice.id + "/rate",
      {
        rating: rating,
      },
      {
        headers: {
          "Content-Type": "application/json",
          authorization: apiKey,
        },
      },
    );
    return data;
  } catch (error) {
    console.error("Error rating voice:", error);
  }
};
