import React from "react";
import styled from "styled-components";

const BoxWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 10px;
  gap: 10px;
`;

const Box = styled.div`
  flex: 0 1 auto;
  padding: 10px 15px;
  width: 160px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const BoxGroup = ({ data }) => {
  return (
    <BoxWrapper>
      {data.map((item, index) => (
        <Box
          key={index}
          style={{ border: `2px solid #EEEEEE`, background: "#FEFEFE" }}
        >
          <div
            style={{ color: "#343a40", fontSize: "14px", fontWeight: "500" }}
          >
            {item.key}
          </div>
          <div
            style={{
              marginTop: "10px",
              fontSize: "24px",
              fontWeight: "bold",
              color: "#343a40",
            }}
          >
            {item.value}
          </div>
        </Box>
      ))}
    </BoxWrapper>
  );
};

export default BoxGroup;
