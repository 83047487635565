import Switch from "components/core/Switch";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styled } from "@mui/material/styles";
import LightTooltip from "./ToolTipDesc";

export default function AnsweredBy({ fieldName }) {
  const longText = (
    <span>
      If this is set to <strong>true</strong>, we process the audio from the
      start of the call to determine if it was answered by a human or a
      voicemail. <br />
      <br />
      In the call details or webhook response, you’ll see the{" "}
      <strong>answered_by</strong> field with the value <strong>human</strong>,{" "}
      <strong>unknown</strong> or <strong>voicemail</strong>.
    </span>
  );
  return (
    <Tooltip>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Switch sx={{ m: 5 }} fieldName={fieldName} label="Answered By" />
        <LightTooltip title={longText} placement="right-start" arrow>
          <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
        </LightTooltip>
      </div>
    </Tooltip>
  );
}
