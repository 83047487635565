import axios from "axios";
import { getAuthToken } from "../browser/getAuthToken";

export const purchaseNumber = async (
  phone_number,
  user_id,
  type,
  preselected,
  area_code,
  country_code,
) => {
  const token = getAuthToken();
  const headers = {
    authorization: `${token}`,
  };
  try {
    const response = await axios.post(
      "/api/numbers/purchase",
      { phone_number, user_id, type, preselected, area_code, country_code },
      { headers, validateStatus: false },
    );

    if (response.status === 200) {
      return response.data;
    } else {
      console.log("Error", response);
      return { status: "error", message: response.data?.message };
    }
  } catch (error) {
    console.log("33 PMENT", error);
    return { status: "error", message: error };
  }
};
