import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useReactFlow } from "reactflow";

const ExamplesSection = ({ id, examples, setExamples, type }) => {
  const { getEdges, getNodes } = useReactFlow();

  const edges = getEdges();
  const nodes = getNodes();

  let filteredEdges = edges.filter((edge) => edge.source === id);

  nodes.forEach((node) => {
    if (node.data.globalLabel) {
      filteredEdges.push({
        id: node.id,
        data: {
          label: node.data.globalLabel,
        },
      });
    }
  });

  const nameMap = {
    "Assistant Response": "Dialogue",
    "Chosen Pathway": "Pathway",
    "Condition Achieved": "Condition",
  };
  const [newExample, setNewExample] = useState({
    "Conversation History": "",
    [type]: "",
  });

  const addExample = () => {
    setExamples([...examples, newExample]);
    setNewExample({ "Conversation History": "", [type]: "" });
  };

  const updateExample = (index, field, value) => {
    const updatedExamples = examples.map((example, i) =>
      i === index ? { ...example, [field]: value } : example,
    );
    setExamples(updatedExamples);
  };

  const deleteExample = (index) => {
    const updatedExamples = examples.filter((_, i) => i !== index);
    setExamples(updatedExamples);
  };

  return (
    <div className="space-y-4">
      <div className="font-semibold">{nameMap[type]} Examples:</div>
      {examples.map((example, index) => (
        <div key={index} className="flex items-center space-x-2">
          <input
            type="text"
            value={
              typeof example["Conversation History"] === "string"
                ? example["Conversation History"]
                : JSON.stringify(example["Conversation History"])
            }
            onChange={(e) =>
              updateExample(index, "Conversation History", e.target.value)
            }
            placeholder="Conversation History"
            className="border border-gray-300 p-2 rounded-md flex-grow"
          />

          {type === "Chosen Pathway" ? (
            <select
              value={example[type] || ""}
              onChange={(e) => updateExample(index, type, e.target.value)}
              className="border border-gray-300 p-2 rounded-md flex-grow"
            >
              <option value="">Select pathway</option> {/* Default option */}
              {filteredEdges?.map((edge) => (
                <option key={edge.id} value={edge.data.label}>
                  {edge.data.label}
                </option>
              ))}
            </select>
          ) : (
            <input
              type="text"
              value={example[type]}
              onChange={(e) => updateExample(index, type, e.target.value)}
              placeholder={`Expected ${type}`}
              className="border border-gray-300 p-2 rounded-md flex-grow"
            />
          )}

          <button onClick={() => deleteExample(index)}>
            <AiOutlineClose />
          </button>
        </div>
      ))}
      <button
        onClick={addExample}
        className="bg-indigo-500 text-white py-2 px-4 rounded-md"
      >
        Add {nameMap[type]} Example
      </button>
    </div>
  );
};

export default ExamplesSection;
