import axios from "axios";
import { addPlus, sanitizePhone } from "utils/formatting/phone";

// FOR SIGN UP
export const sendOtp = async (phone, token) => {
  const sanitizedPhone = sanitizePhone(phone);
  const phoneE64 = addPlus(sanitizedPhone);

  try {
    const response = await axios.post("/api/signup/sendOtp", {
      phoneNumber: phoneE64,
      token: token,
    });
    if (response.data.status === "pending") {
      // handle success case
      return { code_sent: true };
    }
  } catch (error) {
    // Handle error case
    console.error("Error sending code to user:", error);
    return { error: error };
  }
};
