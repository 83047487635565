import React from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { TfiHelpAlt } from "react-icons/tfi";
import { DiscordIcon } from "assets/icons/navigationIcons";

function HelpPanel() {
  return (
    <div className="fixed bottom-0 right-0 mb-8 mr-8">
      <Popup
        trigger={
          <button className="focus:outline-none">
            <TfiHelpAlt size={32} className="text-indigo-500" />
          </button>
        }
        position="top right"
        closeOnEscape
        closeOnDocumentClick
        contentStyle={{ width: "400px" }}
        arrow={true}
      >
        <div className="bg-white rounded-lg shadow-lg p-4">
          <h3 className="text-2xl font-medium leading-6 text-gray-900">
            Need Help?
          </h3>
          <p className="mt-2 text-lg text-gray-500">
            If you need assistance or have any questions, feel free to check out
            our documentation about pathways or join our Discord community.
          </p>
          <div className="mt-4 flex">
            <a
              href="https://discord.com/invite/QvxDz8zcKe"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-lg font-medium text-white hover:bg-indigo-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-indigo-500"
            >
              <div className="flex items-center gap-2">
                Join Discord{" "}
                <span>
                  {" "}
                  <DiscordIcon />
                </span>
              </div>
            </a>
            <a
              href="https://docs.bland.ai/tutorials/pathways"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-gray-600 px-4 py-2 text-lg font-medium text-white hover:bg-gray-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-gray-500"
            >
              View Documentation
            </a>
          </div>
        </div>
      </Popup>
    </div>
  );
}

export default HelpPanel;
