import { PageWrapper } from "components/core/PageWrapper";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";
import ActionBar from "components/core/ActionBar";
import styled from "styled-components";
import Input from "components/core/Input";
import Button from "components/core/Button";
import Settings from "./components/Settings";
import { getApiKey } from "utils/funcs/browser/getApiKey";
import { PageTitle } from "components/core/PageTitle";
import AlertBox from "components/core/AlertBox";
import Textarea from "components/core/Textarea";
import { RxSpeakerLoud } from "react-icons/rx";
import TemplateModal from "./components/TemplateModal";
import Select from "react-select";
import ToolIcon from "../../../assets/icons/tool-icon.svg";
import PlusIcon from "../../../assets/icons/plus-icon.svg";
import { KeyValuesToObj } from "utils/formatting/KeyValuesToObj";
import ReactPhoneInput from "components/core/PhoneInput";
import ActiveCall from "../SendCall/ActiveCall";
import ModalInput from "./components/Input";
import { toast } from "react-toastify";
import CheckIcon from "../../../assets/icons/check-icon.svg";
import TrashIcon from "../../../assets/icons/trash-icon.svg";
import FilterIcon from "../../../assets/icons/filter-icon.svg";
import Table from "./components/VariableTable";
import RepeatIcon from "../../../assets/icons/repeat-icon.svg";
import KeyValues from "./components/KeyValues";

const PromptTemplatesData = [
  {
    value: "example_prompt",
    label: "B2B SaaS Sales - Outbound",
    prompt:
      "You are Jordan, a Senior Sales Representative at CloudInnovate, focusing on businesses interested in enhancing their project management with cloud-based solutions. Your mission is to introduce them to CloudInnovate’s comprehensive suite designed to improve collaboration, efficiency, and project tracking.",
  },
  {
    value: "example_prompt_two",
    label: "Online Order",
    prompt:
      "You are taking a drive thru order from a customer. Find out everything that they want like a drive thru cashier. Continue until they say they're done. Repeat the full order back to them after that, and ask if that's correct. If they confirm that it's correct, then and only then will you place their order using the PlaceOrder tool. After you place it, tell them their order total and to pull forward. Their order price is {{order_price}}",
  },
];

export default function Testing() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [messages, setMessages] = useState([]);
  const [displayMessages, setDisplayMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showSettings, setShowSettings] = useState(true);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [promptTemplate, setPromptTemplate] = useState(null);
  const [toolModalOpen, setToolOpenModal] = useState(false);
  const [sendCallModal, setSendCallModal] = useState(false);
  const [dynamicDataModal, setDynamicDataModal] = useState(false);
  const [loadingSendCall, setLoadingSendCall] = useState(false);
  const [dynamicData, setDynamicData] = useState([]);
  const [testDnLoading, setTestDnLoading] = useState(false);
  const [testDnResponse, setTestDnResponse] = useState([]);
  const [dnEnabled, setDnEnabled] = useState(true);

  const methods = useForm({
    defaultValues: {
      callId: null,
      isCallActive: false,
      temperature: 0.5,
      wait_for_greeting: false,
      interruption_threshold: 100, // 100 is the default. 500 is utterly insane and will make people think the AI is unbelievably slow.
      phone_number: "",
      model: "base",
      language: "en",
      task: "",
      user_input: "",
      voice: "nat",
      first_sentance: "",
      request_data: [],
      tools: {
        url: "",
        properties: {},
      },
    },
  });

  const callId = methods.watch("callId");
  const isCallActive = methods.watch("isCallActive");
  const currModel = methods.watch("model");
  const apiKey = getApiKey();

  const [isLoading, setIsLoading] = useState(false);
  const [latency, setLatency] = useState(null);

  const firstSentence = methods.watch("first_sentence");
  const tools = methods.watch("tools");
  let rqPromptStr = `You've been provided with the following data to assist you in your task:`;

  useEffect(() => {
    setDisplayMessages((prevMessages) => {
      const firstMessageIndex = prevMessages.findIndex(
        (msg) => msg.role === "first message",
      );
      const hasFirstMessage = firstMessageIndex !== -1;

      if (firstSentence) {
        if (hasFirstMessage) {
          return prevMessages.map((msg, index) => {
            if (index === firstMessageIndex) {
              return { role: "first message", content: firstSentence };
            }
            return msg;
          });
        } else {
          return [
            { role: "first message", content: firstSentence },
            ...prevMessages,
          ];
        }
      } else {
        if (hasFirstMessage) {
          return prevMessages.filter((_, index) => index !== firstMessageIndex);
        } else {
          return prevMessages;
        }
      }
    });
  }, [firstSentence]);

  const handleSendCall = async () => {
    setLoadingSendCall(true);
    const data = methods.getValues();
    const headers = {
      Authorization: apiKey,
    };
    let callObject = {
      phone_number: data.phone_number,
      task: data.task,
      request_data: data.request_data,
      temperature: Number(data.temperature),
      model: data.model,
      language: data.language,
      voice: data.voice,
      wait_for_greeting: data.wait_for_greeting,
      interruption_threshold: Number(data.interruption_threshold) || 100,
    };
    if (
      dynamicData.filter((doc) => doc.url.length > 0).length >= 1 &&
      dnEnabled
    ) {
      const dynamicDataFixed = dynamicData.map((doc) => {
        let x = {
          ...doc,
        };

        if (doc.method === "POST") {
          x.body = KeyValuesToObj(doc?.body || []);
        }

        x.headers = KeyValuesToObj(doc?.headers || []);
        return x;
      });
      callObject["dynamic_data"] = dynamicDataFixed;
    }

    try {
      const response = await axios.post(
        `https://us.api.bland.ai/v1/calls`,
        callObject,
        {
          headers,
        },
      );

      if (response.status === 200 && response.data.status === "success") {
        const callId = response.data.call_id;
        methods.setValue("callId", callId);
        methods.setValue("isCallActive", true);
        setSendCallModal(false);
      }
    } catch (err) {
    } finally {
      setLoadingSendCall(false);
    }
  };

  const handleSelectPromptTemplate = (object) => {
    try {
      setPromptTemplate(object);
    } catch (error) {
      console.log({ error });
    } finally {
      methods.setValue("task", object?.prompt || "template");
      setTemplateModalOpen(false);
    }
  };

  const fetchTTSAudio = async (text) => {
    try {
      const voiceId = methods.watch().voice;

      setIsLoading(true);
      const response = await fetch(
        `https://us.api.bland.ai/v1/voices/` + voiceId + "/sample",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: apiKey,
          },
          body: JSON.stringify({ text, response_type: "stream" }),
        },
      );

      let latency = null;

      if (response.ok) {
        latency = response.headers.get("x-latency");
        setLatency(latency);
      }
      const mediaSource = new MediaSource();
      const audio = new Audio();
      audio.src = URL.createObjectURL(mediaSource);

      await new Promise((resolve, reject) => {
        mediaSource.addEventListener(
          "sourceopen",
          () => {
            const sourceBuffer = mediaSource.addSourceBuffer("audio/mpeg");
            const reader = response.body.getReader();

            const read = () => {
              reader
                .read()
                .then(({ done, value }) => {
                  if (done) {
                    if (sourceBuffer.updating) {
                      sourceBuffer.addEventListener(
                        "updateend",
                        () => {
                          mediaSource.endOfStream();
                          resolve();
                        },
                        { once: true },
                      );
                    } else {
                      mediaSource.endOfStream();
                      resolve();
                    }
                    return;
                  }
                  // Check if the sourceBuffer is updating before appending the buffer
                  if (!sourceBuffer.updating) {
                    sourceBuffer.appendBuffer(value);
                    read();
                  } else {
                    // If the sourceBuffer is updating, wait for the update to finish before reading the next chunk
                    sourceBuffer.addEventListener("updateend", read, {
                      once: true,
                    });
                  }
                })
                .catch(reject);
            };

            read();
          },
          { once: true },
        );
      });
      audio.play();
      setIsLoading(false);

      return audio;
    } catch (error) {
      console.error("Failed to fetch TTS audio:", error);
    }
  };

  const handleMessageSubmit = async (formData) => {
    // setShowSettings(false);
    formData.temperature = Number(formData.temperature);
    if (!formData.user_input) {
      return;
    }
    const updatedMessagesTruth = [
      ...messages,
      { role: "user", content: formData.user_input },
    ];
    const updatedMessages = [
      ...displayMessages,
      { role: "user", content: formData.user_input },
    ];

    let messagesToSend = [
      ...displayMessages,
      { role: "user", content: formData.user_input },
    ];

    messagesToSend = messagesToSend.map((message) =>
      message.role === "first message"
        ? { ...message, role: "assistant" }
        : message,
    );

    setMessages(messagesToSend);
    setDisplayMessages(updatedMessages);

    try {
      setLoading(true);
      const headers = {
        authorization: apiKey,
      };
      const response = await axios.post(
        `https://us.api.bland.ai/chat/text`,
        {
          callDetails: {
            language: formData.language.toUpperCase(),
            task: `${formData.task} ${formData.request_data.length > 0 && rqPromptStr + JSON.stringify(formData.request_data)}`,
            params: "",
          },
          messageArray: messagesToSend,
          model: formData.model,
          temperature: formData.temperature,
        },
        { headers },
      );

      if (response.data) {
        if (response.data.model !== "turbo") {
          const assistantMessage = `action: ${response.data.action}: ${response.data.action_input}`;
          const toDispMessage = `${response.data.action_input}`;
          setDisplayMessages([
            ...updatedMessages,
            { role: "assistant", content: toDispMessage },
          ]);
          setMessages([
            ...updatedMessagesTruth,
            { role: "assistant", content: assistantMessage },
          ]);
        } else {
          const assistantMessage = `action: ${response.data.action}: ${response.data.content}`;
          const toDispMessage = `${response.data.content}`;
          setDisplayMessages([
            ...updatedMessages,
            { role: "assistant", content: toDispMessage },
          ]);
          setMessages([
            ...updatedMessagesTruth,
            { role: "assistant", content: assistantMessage },
          ]);
        }
      }
    } catch (error) {
      console.log("Error sending message:", error);
    } finally {
      methods.setValue("user_input", "");
      setLoading(false);
    }
  };

  const regenerateResponse = async (formData) => {
    try {
      setLoading(true);
      const data = methods.getValues();
      const response = await axios.post(
        `https://us.api.bland.ai/chat/regenerate`,
        {
          callDetails: {
            params: "",
            task: data.task,
          },
          messageArray: messages,
          model: data.model,
        },
      );

      if (response.data && response.data?.regeneratedMessageArray) {
        const regeneratedMsgs = response.data?.regeneratedMessageArray;
        const formattedMsgs = regeneratedMsgs.map((doc, index) => {
          if (doc.role === "assistant") {
            return {
              role: "assistant",
              content: doc.content.action_input,
            };
          } else {
            return doc;
          }
        });

        setDisplayMessages((prevDisplayMessages) => {
          const lastMsgIndex = prevDisplayMessages.length - 1;
          return prevDisplayMessages.map((msg, index) => {
            if (index === lastMsgIndex) {
              return formattedMsgs[formattedMsgs.length - 1];
            }
            return msg;
          });
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      return toast.error("Error generating new message.");
    }
  };

  // const handleRegenerateMessages = async (formData) => {
  //    try {
  //       setLoading(true);
  //       const response = await axios.post(
  //          `https://us.api.bland.ai/chat/regenerate`,
  //          {
  //             callDetails: {
  //                language: formData.language,
  //                task: formData.task,
  //                params: "",
  //             },
  //             messageArray: messages,
  //             model: formData.model,
  //          }
  //       );
  //       if (response.data && response.data.regeneratedMessageArray) {
  //          const regeneratedMessages = response.data.regeneratedMessageArray;
  //          // Transform the messages for display
  //          const transformedMessages = regeneratedMessages.map((msg, index) => {
  //             if (msg.role === "assistant" && regeneratedMessages.length === index + 1) {
  //                let content = msg.content.action_input;
  //                return { role: "assistant", content };
  //             } else {
  //                return msg;
  //             };
  //             // if (msg.role === "assistant" && regeneratedMessages.length === index + 1) {
  //             //    // Format the assistant message for display
  //             //    return {
  //             //       role: "assistant",
  //             //       content: `${msg.content} (Regenerated)`
  //             //    };
  //             // } else {
  //             //    // User messages are displayed as is
  //             //    return msg;
  //             // }
  //          });

  //          console.log({ transformedMessages, regeneratedMessages })
  //          setMessages(regeneratedMessages);
  //          setDisplayMessages(transformedMessages);
  //       };

  //    } catch (error) {
  //       console.error("Error regenerating messages:", error);
  //    } finally {
  //       setLoading(false);
  //    }
  // };

  const handleClearMessages = () => {
    setMessages([]);
    setDisplayMessages([]);
  };

  const handleUpdateDynamicData = (id, key, value, method) => {
    // update headers
    console.log({
      method: value,
    });

    if (method === "POST") {
    }
    setDynamicData((prevDynamicData) => {
      return prevDynamicData.map((data) => {
        if (data.id === id) {
          return { ...data, [key]: value };
        } else {
          return data;
        }
      });
    });
  };

  const handleTestDynamicData = async (id) => {
    try {
      setTestDnLoading(true);
      let dynamicObj = dynamicData.find((doc) => doc.id === id);
      if (dynamicObj?.method === "POST") {
        dynamicObj.body = KeyValuesToObj(dynamicObj?.body || []);
      }

      console.log({
        dynamicObj,
        laberl: "dynamic",
      });

      if (!dynamicObj) return toast.error("Issue sending test request.");

      const response = await axios.post(
        "https://us.api.bland.ai/dynamic_data/test",
        {
          dynamic_data: [
            {
              ...dynamicObj,
              headers: KeyValuesToObj(dynamicObj?.headers || []),
            },
          ],
        },
        {
          headers: {
            Authorization: apiKey,
          },
          validateStatus: () => true,
        },
      );

      const data = {
        id: id,
        name: dynamicObj.response_data[0].name,
        ts: new Date().getTime(),
        statusCode: response.status,
        statusText: response.data.status,
        message: response.data?.message || null,
        dynamic_data: response.data?.dynamic_data_response || null,
        variables: response.data?.variables || null,
        response_data: response?.data?.url_responses || null,
      };

      setTestDnResponse((prevResponses) => {
        const existingIndex = prevResponses.findIndex(
          (response) => response.id === id,
        );
        if (existingIndex !== -1) {
          prevResponses[existingIndex] = data;
        } else {
          prevResponses.push(data);
        }
        return [...prevResponses];
      });
      setTestDnLoading(false);
    } catch (error) {
      setTestDnLoading(false);
      toast.error("Issue with your dynamic data req");
    }
  };

  const cleanString = (str) => {
    return str.replace(/[^a-zA-Z0-9 $.,:"]/g, "");
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth <= 1169) {
        setShowSettings(false);
      } else if (window.innerWidth > 1169) {
        setShowSettings(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log({ ...dynamicData });
  }, [dynamicData]);

  if (windowWidth < 884) {
    return (
      <PageWrapper>
        <ActionBar top spaceBetween>
          <PageTitle>Playground</PageTitle>
        </ActionBar>
        <Wrapper style={{ justifyContent: "center", alignItems: "center" }}>
          <p className="font-bold">
            This experience was optimized for browser.
          </p>
        </Wrapper>
      </PageWrapper>
    );
  }

  return (
    <>
      <PageWrapper>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(handleMessageSubmit)}>
            <ActionBar top spaceBetween>
              <PageTitle>Playground</PageTitle>
              <ActionButtons>
                <Button appearance="outline" onClick={handleClearMessages}>
                  Clear Chat
                </Button>
                <Button onClick={() => setSendCallModal(true)}>
                  Send Call
                </Button>
                <Button onClick={() => setShowSettings(!showSettings)}>
                  <img src={FilterIcon} alt="" height={18} width={18} />
                </Button>
              </ActionButtons>
            </ActionBar>

            {showSettings && !isCallActive && <Settings apiKey={apiKey} />}

            <Wrapper>
              <PromptColumn
                style={{ overflow: "scroll", paddingBottom: "10px" }}
              >
                <p className="font-bold" style={{ fontSize: "20px" }}>
                  System
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    columnGap: "5px",
                    flexWrap: "wrap",
                    gap: "5px",
                    marginTop: "10px",
                  }}
                >
                  <ToolButton
                    type="button"
                    onClick={() => setDynamicDataModal(true)}
                  >
                    <img src={ToolIcon} alt="" />
                    <p
                      className="font-bold"
                      style={{ color: "grey", fontWeight: "550", fontSize: 14 }}
                    >
                      Dynamic Data
                    </p>
                  </ToolButton>
                </div>

                {dynamicData.filter((doc) => doc.url.length > 0).length >=
                  1 && (
                  <div
                    style={{
                      marginTop: "15px",
                      border: "1px solid grey",
                      padding: "10px",
                      borderRadius: "6px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                        columnGap: "15px",
                        gap: "5px",
                      }}
                    >
                      <img src={CheckIcon} height={14} width={14} alt="" />

                      <div>
                        <p
                          className="font-bold"
                          style={{ fontSize: 15, marginLeft: "5px" }}
                        >
                          Dynamic data has been set.
                        </p>
                        <p className="" style={{ marginLeft: "5px" }}>
                          Dynamic Data {dnEnabled ? "enabled" : "disabled"}.
                        </p>
                      </div>

                      <SwitchWrapper
                        style={{
                          marginTop: "10px",
                          height: "21px",
                          marginLeft: "auto",
                        }}
                        onClick={() => setDnEnabled(!dnEnabled)}
                      >
                        <SwitchBody on={dnEnabled} style={{ height: "21px" }}>
                          <Flicker
                            on={dnEnabled}
                            style={{ height: "17px", width: "15px" }}
                          />
                        </SwitchBody>
                      </SwitchWrapper>
                    </div>
                  </div>
                )}

                <textarea
                  {...methods.register("task")}
                  placeholder="Enter your prompt here."
                  style={{
                    overflow: "auto",
                    resize: "none",
                    height: "auto",
                    maxHeight: "100%",
                    width: "100%",
                    marginTop: "10px",
                    border: "none",
                    outline: "none",
                    boxShadow: "none",
                  }}
                  onInput={(e) => {
                    e.target.style.height = "auto";
                    e.target.style.height = e.target.scrollHeight + "px";
                  }}
                  onFocus={(e) => (e.target.style.boxShadow = "none")}
                />
              </PromptColumn>

              {isCallActive ? (
                <ActiveCall callId={callId} apiKey={apiKey} />
              ) : (
                <Messages
                  style={{
                    marginRight: showSettings ? "328px" : 0,
                    marginLeft: "30px",
                  }}
                >
                  {displayMessages.map((msg, index) => (
                    <Message
                      key={index}
                      role={msg.role}
                      style={{
                        width:
                          msg.role === "first message" && index === 0 && "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          columnGap: "5px",
                        }}
                      >
                        <p style={{ fontWeight: "550", fontSize: 14 }}>
                          {msg.role.charAt(0).toUpperCase() + msg.role.slice(1)}
                        </p>
                        <RxSpeakerLoud
                          className="text-2xl"
                          size={14}
                          color={msg.role === "assistant" ? "#3730a3" : "#fff"}
                          style={{ cursor: "pointer" }}
                          onClick={() => fetchTTSAudio(msg.content)}
                        />
                        {msg.role === "assistant" &&
                          displayMessages.length === index + 1 && (
                            <div>
                              {loading ? (
                                <p>...</p>
                              ) : (
                                <img
                                  onClick={() => regenerateResponse({})}
                                  src={RepeatIcon}
                                  height={14}
                                  width={14}
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                />
                              )}
                            </div>
                          )}
                      </div>
                      <p style={{ fontWeight: "400" }}>{msg.content}</p>
                    </Message>
                  ))}
                </Messages>
              )}
              <ActionBar bottom>
                <UserInput>
                  <Input
                    fieldName="user_input"
                    placeholder="Send a message..."
                    disabled={loading}
                    autofocus
                  />
                  <Button
                    submit
                    onClick={handleMessageSubmit}
                    disabled={loading} // Disable button during loading
                    loading={loading}
                  >
                    Send
                  </Button>
                </UserInput>
              </ActionBar>
            </Wrapper>

            <TemplateModal
              id="dynamicDataModal"
              height={"auto"}
              modalWidth={"50%"}
              show={dynamicDataModal}
              handleClose={() => setDynamicDataModal(!dynamicDataModal)}
              title={"Dynamic Data"}
            >
              <p
                style={{ fontSize: 13, marginBottom: "15px", marginTop: "5px" }}
              >
                Interact with the real world by connecting your agent to
                external APIs.
              </p>
              <Button
                onClick={() => {
                  return setDynamicData((prevDynamicData) => [
                    ...prevDynamicData,
                    {
                      url: "",
                      cache: true,
                      method: "GET",
                      headers: [
                        { key: "Content-Type", value: "application/json" },
                      ],
                      response_data: [{ name: "", context: "", data: "" }],
                      id: Math.random().toString(36).substring(7),
                    },
                  ]);
                }}
                style={{ borderRadius: "5px", height: "35px" }}
              >
                Add API Call
              </Button>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  marginTop: "15px",
                  paddingBottom: "10px",
                }}
              >
                {dynamicData.length > 0 &&
                  dynamicData.map((dnFetch, key) => {
                    return (
                      <DynamicDataFetchBox key={key}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                            marginBottom: "15px",
                          }}
                        >
                          <p className="font-bold"> API Call {key + 1}</p>

                          <img
                            onClick={() => {
                              setDynamicData((prevDynamicData) =>
                                prevDynamicData.filter(
                                  (item) => item.id !== dnFetch.id,
                                ),
                              );
                            }}
                            src={TrashIcon}
                            height={20}
                            width={20}
                            alt=""
                            style={{ cursor: "pointer" }}
                          />
                        </div>

                        {/*
                                    methodDropdown={true}
                                    methodDropdownOnChange={(event) => handleUpdateDynamicData(dnFetch.id, "method", event.currentTarget.value)}
                                 */}

                        <ModalInput
                          value={dnFetch.url}
                          submitBtnLoading={testDnLoading}
                          submitBtnOnClick={() =>
                            handleTestDynamicData(dnFetch.id)
                          }
                          submitBtnLabel={"Test"}
                          submitBtn={true}
                          methodDropdown={true}
                          methodDropdownOnChange={(event) =>
                            handleUpdateDynamicData(
                              dnFetch.id,
                              "method",
                              event.currentTarget.value,
                            )
                          }
                          placeholder={
                            "https://api.coindesk.com/v1/bpi/currentprice.json"
                          }
                          onChange={(event) =>
                            handleUpdateDynamicData(
                              dnFetch.id,
                              "url",
                              event.target.value,
                            )
                          }
                        />

                        <SwitchWrapper
                          style={{
                            marginLeft: "auto",
                            marginRight: "0px",
                            marginTop: "15px",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p className="font-bold" style={{ color: "#676775" }}>
                            Cache / Save Response
                          </p>
                          <SwitchBody
                            on={dnFetch.cache}
                            onClick={() => {
                              setDynamicData((prevDynamicData) =>
                                prevDynamicData.map((item) => {
                                  if (item.id === dnFetch.id) {
                                    return { ...item, cache: !item.cache };
                                  }
                                  return item;
                                }),
                              );
                            }}
                          >
                            <Flicker on={dnFetch.cache} />
                          </SwitchBody>
                        </SwitchWrapper>

                        {testDnResponse.find(
                          (doc) => doc.id === dnFetch.id,
                        ) && (
                          <div style={{ marginTop: "15px" }}>
                            {testDnResponse.find(
                              (doc) => doc.id === dnFetch.id,
                            ) && (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  width: "100%",
                                }}
                              >
                                <p
                                  className="font-bold"
                                  style={{ color: "grey" }}
                                >
                                  Status
                                </p>
                                <p
                                  className="font-bold"
                                  style={{
                                    color:
                                      testDnResponse.find(
                                        (doc) => doc.id === dnFetch.id,
                                      ).statusCode === 200
                                        ? "#06d6a0"
                                        : "#ef233c",
                                  }}
                                >
                                  {
                                    testDnResponse.find(
                                      (doc) => doc.id === dnFetch.id,
                                    ).statusCode
                                  }
                                </p>
                              </div>
                            )}

                            {(() => {
                              const response = testDnResponse.find(
                                (doc) => doc.id === dnFetch.id,
                              );
                              if (!response) return <div />;

                              const {
                                variables,
                                dynamic_data,
                                name,
                                statusText,
                                statusCode,
                              } = response;
                              if (statusCode !== 200 && response?.message) {
                                return (
                                  <div
                                    style={{
                                      background: "#f5cac3",
                                      border: "1px solid #ef233c",
                                      borderRadius: 4,
                                      padding: 8,
                                      width: "100%",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "#ef233c",
                                        fontWeight: "550",
                                      }}
                                    >
                                      The dynamic data test failed.
                                    </p>
                                    <p
                                      style={{
                                        color: "#ef233c",
                                        fontWeight: "400",
                                      }}
                                    >
                                      {response?.message}
                                    </p>
                                  </div>
                                );
                              }

                              return (
                                <div
                                  style={{
                                    marginTop: "5px",
                                    width: "100%",
                                    overflow: "clip",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      gap: "10px",
                                    }}
                                  >
                                    <Table
                                      variables={variables}
                                      tableName="Variables"
                                    />

                                    {dynamic_data.map((d, x) => (
                                      <div
                                        key={x}
                                        style={{
                                          border: "1px solid #adb5bd",
                                          padding: "6px",
                                          borderRadius: "4px",
                                          overflow: "scroll",
                                        }}
                                      >
                                        <p className="font-bold">
                                          Prompt: {d.name}
                                        </p>
                                        <p>{cleanString(d.prompt)}</p>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              );
                            })()}
                          </div>
                        )}

                        {dnFetch?.method === "POST" && (
                          <div
                            style={{
                              marginTop: "15px",
                              display: "flex",
                              flexDirection: "column",
                              gap: "15px",
                              borderTop: "1px solid #eeeeee",
                            }}
                          >
                            <p
                              style={{
                                color: "#676775",
                                fontSize: 13,
                                fontWeight: "525",
                                marginTop: "15px",
                              }}
                            >
                              Request Data
                            </p>
                            <KeyValues
                              externalOnChange={true}
                              onChange={(event, key, type) => {
                                const obj = [...dnFetch.headers];
                                obj[key][type] = event.target.value;
                                handleUpdateDynamicData(
                                  dnFetch.id,
                                  "headers",
                                  obj,
                                );
                              }}
                              addFn={() => {
                                const obj = [...dnFetch.headers];
                                obj.push({ key: "", value: "" });
                                handleUpdateDynamicData(
                                  dnFetch.id,
                                  "headers",
                                  obj,
                                );
                              }}
                              removeFn={(key) => {
                                const obj = [...dnFetch.headers];
                                obj.splice(key, 1);
                                handleUpdateDynamicData(
                                  dnFetch.id,
                                  "headers",
                                  obj,
                                );
                              }}
                              obj={
                                dnFetch?.headers || [
                                  {
                                    key: "Content-Type",
                                    value: "application/json",
                                  },
                                ]
                              }
                              nestedKey={"headers"}
                            />

                            <KeyValues
                              nestedKey={"body"}
                              externalOnChange={true}
                              onChange={(event, key, type) => {
                                const obj = [
                                  ...(dnFetch.body || [{ key: "", value: "" }]),
                                ];
                                obj[key][type] = event.target.value;
                                handleUpdateDynamicData(
                                  dnFetch.id,
                                  "body",
                                  obj,
                                );
                              }}
                              addFn={() => {
                                const obj = [
                                  ...(dnFetch.body || [{ key: "", value: "" }]),
                                ];
                                obj.push({ key: "", value: "" });
                                handleUpdateDynamicData(
                                  dnFetch.id,
                                  "body",
                                  obj,
                                );
                              }}
                              removeFn={(key) => {
                                const obj = [
                                  ...(dnFetch.body || [{ key: "", value: "" }]),
                                ];
                                obj.splice(key, 1);
                                handleUpdateDynamicData(
                                  dnFetch.id,
                                  "body",
                                  obj,
                                );
                              }}
                              obj={dnFetch?.body || [{ key: "", value: "" }]}
                            />
                          </div>
                        )}

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            flexWrap: "wrap",
                            columnGap: "10px",
                            gap: "10px",
                            marginTop: "15px",
                            borderTop: "1px solid #eeeeee",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                              marginTop: "15px",
                            }}
                          >
                            <p
                              style={{
                                color: "#676775",
                                fontSize: 13,
                                fontWeight: "525",
                              }}
                            >
                              Response Data
                            </p>

                            <div
                              onClick={() => {
                                const newData = [...dnFetch.response_data];
                                newData.push({
                                  name: "",
                                  context: "",
                                  data: "",
                                  id: Math.random().toString(36).substring(7),
                                });
                                handleUpdateDynamicData(
                                  dnFetch.id,
                                  "response_data",
                                  newData,
                                );
                              }}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                columnGap: "5px",
                                cursor: "pointer",
                              }}
                            >
                              <p
                                style={{
                                  color: "#676775",
                                  fontSize: 13,
                                  fontWeight: "525",
                                }}
                              >
                                Add Data
                              </p>
                              <img
                                src={PlusIcon}
                                height={16}
                                width={16}
                                style={{ cursor: "pointer" }}
                                alt=""
                              />
                            </div>
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              marginTop: "0px",
                              gap: "10px",
                            }}
                          >
                            {dnFetch.response_data.map((data, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    columnGap: "5px",
                                    gap: "10px",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    flexWrap: "wrap",
                                  }}
                                >
                                  {index !== 0 && (
                                    <div
                                      style={{
                                        width: "100%",
                                        height: 1,
                                        background: "lightgrey",
                                        marginTop: "10px",
                                        marginBottom: "5px",
                                      }}
                                    />
                                  )}
                                  <ModalInput
                                    value={
                                      dnFetch.response_data.find(
                                        (doc) => doc.id === data.id,
                                      ).name
                                    }
                                    label={"Name"}
                                    onChange={(event) => {
                                      const newData = [
                                        ...dnFetch.response_data,
                                      ];
                                      newData[index].name = event.target.value;
                                      handleUpdateDynamicData(
                                        dnFetch.id,
                                        "response_data",
                                        newData,
                                      );
                                    }}
                                    placeholder={"bitcoin_price"}
                                  />

                                  <ModalInput
                                    value={
                                      dnFetch.response_data.find(
                                        (doc) => doc.id === data.id,
                                      ).data
                                    }
                                    label={"Data"}
                                    onChange={(event) => {
                                      const newData = [
                                        ...dnFetch.response_data,
                                      ];
                                      newData[index].data = event.target.value;
                                      handleUpdateDynamicData(
                                        dnFetch.id,
                                        "response_data",
                                        newData,
                                      );
                                    }}
                                    placeholder={"$.bpi.USD.rate"}
                                  />

                                  <ModalInput
                                    value={
                                      dnFetch.response_data.find(
                                        (doc) => doc.id === data.id,
                                      ).context
                                    }
                                    label={"Context"}
                                    onChange={(event) => {
                                      const newData = [
                                        ...dnFetch.response_data,
                                      ];
                                      newData[index].context =
                                        event.target.value;
                                      handleUpdateDynamicData(
                                        dnFetch.id,
                                        "response_data",
                                        newData,
                                      );
                                    }}
                                    placeholder={
                                      "Current price of Bitcoin in USD is ${{bitcoin_price}}"
                                    }
                                  />

                                  <Button
                                    onClick={() => {
                                      const newData = [
                                        ...dnFetch.response_data,
                                      ];
                                      const updatedData = newData.filter(
                                        (item) => item.id !== data.id,
                                      );
                                      handleUpdateDynamicData(
                                        dnFetch.id,
                                        "response_data",
                                        updatedData,
                                      );
                                    }}
                                    style={{
                                      width: "100%",
                                      backgroundColor: "#e9ecef",
                                      borderRadius: "6px",
                                      height: "34px",
                                      marginTop: "5px",
                                      "&:hover": { backgroundColor: "#000" },
                                    }}
                                  >
                                    <p style={{ color: "#ef233c" }}>
                                      Remove Data
                                    </p>
                                  </Button>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </DynamicDataFetchBox>
                    );
                  })}
              </div>
            </TemplateModal>

            <TemplateModal
              height={"auto"}
              modalWidth={400}
              show={sendCallModal}
              handleClose={() => setSendCallModal(!sendCallModal)}
              title={"Send Call"}
            >
              <p
                style={{ fontSize: 13, marginBottom: "15px", marginTop: "5px" }}
              >
                Interact with the real world mid-call using custom tools. Custom
                tools allow your agent to interact with any web API mid-call.
              </p>
              <ReactPhoneInput fieldName={"phone_number"} />

              <Button
                loading={loadingSendCall}
                onClick={() => handleSendCall()}
                style={{ marginTop: "15px" }}
              >
                Send Call
              </Button>
            </TemplateModal>

            <TemplateModal
              modalWidth={325}
              show={templateModalOpen}
              handleClose={() => setTemplateModalOpen(!templateModalOpen)}
              title={"Prompt Templates"}
            >
              <div style={{ marginTop: "10px" }}>
                <p
                  className="font-bold"
                  style={{
                    fontWeight: "450",
                    marginBottom: "5px",
                    marginTop: "5",
                    color: "darkgrey",
                  }}
                >
                  Available Templates
                </p>

                <Select
                  name="Templates"
                  className="basic-single"
                  styles={{
                    menuList: (base, state) => ({
                      ...base,
                      maxHeight: "200px",
                      overflow: "scroll",
                      marginTop: "-5px",
                    }),
                    control: (base, state) => ({
                      ...base,
                      height: "25px",
                    }),
                    valueContainer: (base, state) => ({
                      ...base,
                    }),
                    container: (base, state) => ({
                      ...base,
                    }),
                    option: (base, state) => ({
                      ...base,
                      padding: "5px 10px 5px 10px",
                      height: "30px",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "#fff",
                      color: "#000",
                      ":hover": {
                        background: "grey",
                      },
                    }),
                  }}
                  placeholder={"Select Template"}
                  isSearchable={false}
                  value={promptTemplate}
                  onChange={(value) => handleSelectPromptTemplate(value)}
                  options={PromptTemplatesData}
                />
              </div>
            </TemplateModal>
          </Form>
        </FormProvider>
      </PageWrapper>
    </>
  );
}

/*

                           <PromptTemplates onClick={() => setTemplateModalOpen(true)} type="button">
                              <img src={FolderIcon} alt="" />
                              <p className="font-bold" style={{ color: "grey", fontWeight: '550', fontSize: 14, marginTop: "2px" }}>Prompt Templates</p>
                           </PromptTemplates>
*/

/*
                           <ToolButton type="button" onClick={() => setToolOpenModal(true)}>
                              <img src={ToolIcon} alt="" />
                              <p className="font-bold" style={{ color: "grey", fontWeight: '550', fontSize: 14, marginTop: "2px" }}>Custom Tool</p>
                           </ToolButton>
*/

const DynamicDataFetchBox = styled.div`
  border: 1px solid lightgrey;
  padding: 10px;
  border-radius: 6px;
`;

const ToolButton = styled.button`
  border: 1.5px solid #adb5bd;
  padding: 3.5px 7px 3.5px 7px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  elevation: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  &:hover {
    background-color: #e9ecef;
  }
`;

const PromptTemplates = styled.button`
  border: 1.5px solid #adb5bd;
  padding: 3.5px 7px 3.5px 7px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  elevation: 5px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  background-color: transparent;
  &:hover {
    background-color: #e9ecef;
  }
`;

const PromptColumn = styled.div`
  width: 100%;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  padding: 12px;
  max-width: 30%;
  overflow: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
  &::-webkit-scrollbar {
    width: 0px; /* For Chrome, Safari, and Opera */
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 0px;
  height: 90vh;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const Messages = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  height: 100%;
  width: 100%;
  overflow: scroll;
`;

const Message = styled.div`
  background-color: ${({ role }) =>
    role === "user" ? "#433DFF" : role === "assistant" ? "#eeeeee" : "#a2d2ff"};
  color: ${({ role }) => (role === "user" ? "white" : "black")};
  padding: 12px;
  border-radius: 5px;
  margin: ${({ role }) =>
    role === "user" ? "0px 0px 0px 50%" : "0px 50% 0px 0px"};
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 9px;
`;

const UserInput = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 9px;
  width: 100%;
  overflow: visible;
`;

const Hint = styled.div`
  position: absolute;
  color: white;
  top: -60px;
`;

const SwitchWrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
`;

const SwitchBody = styled.div`
  transition: background-color 0.1s ease;
  background-color: ${({ on }) => (on ? "#433DFF" : "#f0f0ed")};
  position: relative;
  height: 25px;
  width: 50px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
`;

const Flicker = styled.p`
  border-radius: 100px;
  width: 20px;
  height: 20px;
  background-color: white;
  position: absolute;
  transition: transform 0.15s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  right: ${({ on }) => (on ? "3px" : "auto")};
`;
