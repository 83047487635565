import axios from "axios";
import { getAuthToken } from "../browser/getAuthToken";

export const getUser = async () => {
  const authToken = getAuthToken();

  try {
    const headers = {
      Authorization: `${authToken}`,
    };
    const response = await axios.get("api/user/get", { headers });
    if (response.data) {
      return { user: response.data };
    } else {
      throw new Error("No response data in getting user from client");
    }
  } catch (error) {
    console.error("Error getting user data from client:", error);
    return { error: error.message };
  }
};
