import React from "react";
import { Button } from "@/components/ui/button";
import { ChevronRight } from "lucide-react";

const StepOne = ({ message, handleIssueTypeSelect }) => {
  return (
    <div className="flex flex-col space-y-4 w-full px-8 pb-2">
      <div className="mb-6 space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Prompt:
          </label>
          <div className="p-3 bg-gray-50 rounded-lg text-gray-800">
            {message.promptInfo?.["Current Node Prompt/Text"] || ""}
          </div>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Response:
          </label>
          <div className="p-3 bg-gray-50 rounded-lg text-gray-800">
            {message.content?.trim()}
          </div>
        </div>
      </div>
      {["Hallucination", "Wrong Node"].map((type) => (
        <Button
          key={type}
          onClick={() =>
            handleIssueTypeSelect(type.toLowerCase().replace(" ", "_"))
          }
          className="h-16 text-lg bg-white hover:bg-gray-50 text-gray-800 border border-gray-200 rounded-lg shadow-sm transition-all duration-200 ease-in-out transform hover:scale-105"
        >
          <span className="flex-grow text-left">{type}</span>
          <ChevronRight className="h-5 w-5 text-gray-400" />
        </Button>
      ))}
    </div>
  );
};

export default StepOne;
