import Switch from "components/core/Switch";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styled } from "@mui/material/styles";
import LightTooltip from "./ToolTipDesc";

export default function Record({ fieldName }) {
  const longText = (
    <span>
      To record your phone call, set <strong>record</strong> to{" "}
      <strong>true</strong>. When your call completes, you can access through
      the <strong>recording_url</strong> field in the call details or your
      webhook.
    </span>
  );
  return (
    <Tooltip>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Switch sx={{ m: 5 }} fieldName={fieldName} label="Record" />
        <LightTooltip title={longText} placement="right-start" arrow>
          <HelpOutlineIcon color="primary" style={{ paddingLeft: "4px" }} />
        </LightTooltip>
      </div>
    </Tooltip>
  );
}
