import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { toDtmy } from "utils/formatting/toDtmy";
import { convertToMinutesAndSeconds } from "utils/formatting/secsToMins";
import { useSearchParams } from "react-router-dom";
import { Badge, Button, IconButton, Slider } from "@radix-ui/themes";
import * as Icons from "@radix-ui/react-icons";
import { Copy } from "lucide-react";
import { toast } from "react-toastify";
import { Box } from "@mui/material";
import { pathwayTags } from "components/Dashboard/ConvoPathways/Components/CallDetailsSlider";

const getColor = (disposition_tag) => {
  if (disposition_tag === "COMPLETED_ACTION") {
    return "gray";
  } else if (disposition_tag === "NO_CONTACT_MADE") {
    return "gray";
  } else if (disposition_tag === "Voicemail") {
    return "gray";
  } else {
    return "gray";
  }
};

export const AdvancedTable = ({
  tableCalls,
  recordCount,
  pageCount,
  currPage,
  selected,
  onOpenModal,
  onChangeSelected,
  onExpandedClicked,
  focusedTranscriptCId,
  loading = false,
  pageSizeP = 25,
}) => {
  const [currentPage, setCurrentPage] = useState(currPage || 0);
  const [pageSize, setPageSize] = useState(pageSizeP || 25);
  const [totalPages, setTotalPages] = useState(pageCount || null);
  const [totalRecords, setTotalRecords] = useState(recordCount || null);
  const [chunkedData, setChunkedData] = useState([]);
  const [audioStates, setAudioStates] = useState({});
  const audioRefs = useRef({});

  const fetchRecording = async (recordingUrl) => {
    try {
      const res = await fetch("/api/logs/recording", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ recordingUrl }),
      });

      if (res.status === 200) {
        const audioBlob = await res.blob();
        return URL.createObjectURL(audioBlob);
      } else {
        console.error("Failed to fetch the audio file");
        return null;
      }
    } catch (error) {
      console.error("Error fetching recording:", error);
      return null;
    }
  };

  const handlePlay = async (event, params) => {
    event.stopPropagation();
    const cId = params.row.c_id;
    const recUrl = params?.row?.recording_url?.replace(".mp3", ".wav");
    // let isLongerThan15 = false;

    // // Check if the recording is longer than 15 seconds without playing it
    // const audio = new Audio(recUrl);
    // audio.addEventListener('loadedmetadata', () => {
    //     const duration = audio.duration;
    //     if (duration > 15) {
    //         isLongerThan15 = true;
    //     }
    // });

    // // Wait for the metadata to load before checking isLongerThan15
    // audio.addEventListener('loadedmetadata', () => {
    //     if (isLongerThan15) {
    //         window.open(recUrl, "_blank");
    //         return;
    //     }
    // });

    if (!audioStates[cId]) {
      setAudioStates((prev) => ({
        ...prev,
        [cId]: {
          isLoading: true,
          isPlaying: false,
          currentTime: 0,
          duration: 0,
          hasStartedPlaying: false,
        },
      }));

      const audioUrl = await fetchRecording(recUrl);

      if (audioUrl) {
        setAudioStates((prev) => ({
          ...prev,
          [cId]: {
            url: audioUrl,
            isLoading: false,
            isPlaying: true,
            currentTime: 0,
            duration: 0,
            hasStartedPlaying: true,
          },
        }));
        if (audioRefs.current[cId]) {
          audioRefs.current[cId].play();
        }
      } else {
        setAudioStates((prev) => ({
          ...prev,
          [cId]: {
            isLoading: false,
            isPlaying: false,
            currentTime: 0,
            duration: 0,
            hasStartedPlaying: false,
          },
        }));
      }
    } else {
      const newIsPlaying = !audioStates[cId].isPlaying;
      setAudioStates((prev) => ({
        ...prev,
        [cId]: {
          ...prev[cId],
          isPlaying: newIsPlaying,
          hasStartedPlaying: true,
        },
      }));

      if (audioRefs.current[cId]) {
        if (newIsPlaying) {
          audioRefs.current[cId].play();
        } else {
          audioRefs.current[cId].pause();
        }
      }
    }
  };

  const handleTimeUpdate = (cId) => {
    const audio = audioRefs.current[cId];
    if (audio) {
      setAudioStates((prev) => ({
        ...prev,
        [cId]: {
          ...prev[cId],
          currentTime: audio.currentTime,
          duration: audio.duration,
        },
      }));
    }
  };

  const handleSliderChange = (event, cId) => {
    const newTime = parseFloat(event.target.value);
    if (audioRefs.current[cId]) {
      audioRefs.current[cId].currentTime = newTime;
      setAudioStates((prev) => ({
        ...prev,
        [cId]: { ...prev[cId], currentTime: newTime },
      }));
    }
  };

  const handleDownload = async (event, params) => {
    event.stopPropagation();
    const cId = params.row.c_id;
    const recUrl = params?.row?.recording_url?.replace(".mp3", ".wav");
    const audioUrl = await fetchRecording(recUrl);

    if (audioUrl) {
      const link = document.createElement("a");
      link.href = audioUrl;
      link.download = `recording_${cId}.wav`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const getRowClassName = (params) => {
    return params.row.c_id === focusedTranscriptCId ? "focused-row" : "";
  };

  const columns = [
    {
      field: "",
      headerName: "",
      sortable: false,
      filter: false,
      width: 50,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <IconButton
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              onExpandedClicked(params.row);
            }}
            size="1"
            variant="surface"
            color="gray"
            style={{ cursor: "pointer" }}
          >
            <Icons.ZoomInIcon />
          </IconButton>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created On",
      sortable: true,
      width: 140,
      disableColumnMenu: true,
    },
    {
      field: "inbound",
      headerName: "Direction",
      sortable: false,
      filter: false,
      disableColumnMenu: true,
    },
    {
      field: "to",
      headerName: "To",
      sortable: false,
      filter: true,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: "from",
      headerName: "From",
      sortable: false,
      filter: true,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: "transferred_to",
      headerName: "Transferred To",
      sortable: false,
      filter: false,
      width: 130,
      disableColumnMenu: true,
    },
    {
      field: "recording_url",
      headerName: "Recording",
      sortable: false,
      filter: false,
      width: 200,
      renderCell: (params) => {
        const cId = params.row.c_id;
        const audioState = audioStates[cId] || {};

        return params.row.recording_url ? (
          <AudioControlsWrapper
            onClick={(e) => e.stopPropagation()}
            className="audio-controls"
          >
            <IconButton
              onClick={(e) => {
                const audio = new Audio();
                audio.src = params.row.recording_url;
                audio.addEventListener("loadedmetadata", () => {
                  const duration = audio.duration;
                  if (duration > 60 * 10) {
                    window.open(params.row.recording_url, "_blank");
                    return;
                  } else handlePlay(e, params);
                });
              }}
              size="1"
              variant="surface"
              color="green"
              style={{ cursor: "pointer" }}
              disabled={audioState.isLoading}
            >
              {audioState.isLoading ? (
                <SpinningIcon />
              ) : audioState.isPlaying ? (
                <Icons.PauseIcon />
              ) : (
                <Icons.PlayIcon />
              )}
            </IconButton>
            {(audioState.isPlaying || audioState.hasStartedPlaying) && (
              <SliderWrapper>
                <Slider
                  value={[audioState.currentTime || 0]}
                  max={audioState.duration || 100}
                  step={0.1}
                  onValueChange={(value) =>
                    handleSliderChange({ target: { value: value[0] } }, cId)
                  }
                />
              </SliderWrapper>
            )}
            <IconButton
              onClick={(e) => handleDownload(e, params)}
              size="1"
              variant="surface"
              color="blue"
              style={{ cursor: "pointer" }}
            >
              <Icons.DownloadIcon />
            </IconButton>
            <audio
              ref={(el) => (audioRefs.current[cId] = el)}
              src={audioState.url}
              onTimeUpdate={() => handleTimeUpdate(cId)}
              onEnded={() =>
                setAudioStates((prev) => ({
                  ...prev,
                  [cId]: { ...prev[cId], isPlaying: false },
                }))
              }
            />
          </AudioControlsWrapper>
        ) : (
          <p>-</p>
        );
      },
      disableColumnMenu: true,
    },
    {
      field: "completed",
      headerName: "Status",
      sortable: false,
      filter: false,
      width: 120,
      disableColumnMenu: true,
    },
    {
      field: "cost",
      headerName: "Cost",
      sortable: true,
      filter: false,
      width: 150,
      disableColumnMenu: true,
    },
    {
      field: "call_length",
      headerName: "Call Length",
      sortable: true,
      filter: false,
      width: 150,
      disableColumnMenu: true,
    },
    // {
    //   field: "disposition_tag",
    //   headerName: "Disposition Tag",
    //   sortable: true,
    //   filter: false,
    //   width: 210,
    //   disableColumnMenu: true,
    //   renderCell: (params) => {
    //     return params.row.disposition_tag ? (
    //       <Badge radius="large" color={getColor(params.row.disposition_tag)}>
    //         {params.row.disposition_tag}
    //       </Badge>
    //     ) : (
    //       <p>-</p>
    //     );
    //   },
    // },
    {
      field: "summary",
      headerName: "Summary",
      sortable: false,
      filter: false,
      renderCell: (params) => {
        const handleClick = (e) => {
          e.stopPropagation();
          const cId = params.row.c_id;
          onOpenModal("summary", cId);
        };
        return params.row.summary ? (
          <Button
            onClick={handleClick}
            size="1"
            variant="surface"
            style={{ cursor: "pointer" }}
          >
            View
          </Button>
        ) : (
          <p>-</p>
        );
      },
      disableColumnMenu: true,
    },
    {
      field: "Pathway Tags",
      headerName: "Pathway Tags",
      sortable: false,
      filter: false,
      width: 210,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "nowrap",
              columnGap: 5,
              gap: 5,
              padding: 5,
              overflowX: "scroll",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {params?.row?.pathway_tags !== "-" &&
              pathwayTags(params?.row?.pathway_tags)?.map((tag) => (
                <Badge size={1} color="gray" radius="large">
                  <div
                    style={{
                      height: 10,
                      width: 10,
                      borderRadius: "50%",
                      background: tag.color || "#000",
                    }}
                  />
                  <p style={{ fontSize: 12, fontWeight: 500 }}>{tag.name}</p>
                </Badge>
              ))}
          </div>
        );
      },
    },
    {
      field: "pathway_id",
      headerName: "Pathway ID",
      width: 280,
      filter: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const isClickable = params.row?.pathway_id !== "-";

        const handleClick = (e) => {
          e.preventDefault();
          window.open(
            `${window.location.origin}/dashboard?page=convo-pathways&id=${params.value}`,
            "_blank",
          );
        };

        const handleCopy = async (e) => {
          e.stopPropagation();

          try {
            await navigator.clipboard.writeText(params.value);
            toast.success("Pathway ID copied to clipboard");
          } catch (err) {
            toast.error("Failed to copy Pathway ID");
          }
        };

        return (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Button
              size="1"
              onClick={handleClick}
              variant="ghost"
              disabled={params.value === "-"}
              style={{
                textDecoration: isClickable ? "underline" : "",
                cursor: isClickable ? "pointer" : "auto",
                flexGrow: 1,
                justifyContent: "flex-start",
              }}
            >
              {params.value}
            </Button>
            {isClickable && (
              <Button
                size="1"
                variant="ghost"
                onClick={handleCopy}
                style={{
                  padding: "4px",
                  minWidth: "auto",
                }}
              >
                <Copy size={16} />
              </Button>
            )}
          </div>
        );
      },
    },
    {
      field: "pathway_name",
      headerName: "Pathway Name",
      width: 190,
      filter: true,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const isClickable = params.row?.pathway_id !== "-";
        const handleClick = (e) => {
          e.preventDefault();
          window.open(
            `${window.location.origin}/dashboard?page=convo-pathways&id=${params.row.pathway_id}`,
            "_blank",
          );
        };
        return (
          <Button
            size="1"
            onClick={handleClick}
            variant="ghost"
            disabled={!isClickable}
            style={{
              textDecoration: isClickable ? "underline" : "",
              cursor: isClickable ? "pointer" : "auto",
            }}
          >
            {params.value}
          </Button>
        );
      },
    },
    {
      field: "pathway_logs",
      headerName: "Pathway Logs",
      sortable: false,
      filter: false,
      renderCell: (params) => {
        const handleClick = (e) => {
          e.stopPropagation();
          const cId = params.row.c_id;
          onOpenModal("pathway_logs", cId);
        };
        return params.row.pathway_logs === "N/A" ? (
          <p>-</p>
        ) : (
          <Button
            onClick={handleClick}
            size="1"
            variant="surface"
            style={{ cursor: "pointer" }}
          >
            View
          </Button>
        );
      },
      disableColumnMenu: true,
    },
    {
      field: "transcript",
      headerName: "Transcript",
      filter: false,
      sortable: false,
      renderCell: (params) => {
        const handleClick = (e) => {
          e.stopPropagation();
          const cId = params.row.c_id;
          onOpenModal("transcripts", cId);
        };
        return (
          <Button
            onClick={handleClick}
            size="1"
            variant="surface"
            style={{ cursor: "pointer" }}
          >
            View
          </Button>
        );
      },
      disableColumnMenu: true,
    },
    {
      field: "variables",
      headerName: "Variables",
      sortable: false,
      filter: false,
      renderCell: (params) => {
        const handleClick = (e) => {
          e.stopPropagation();
          const cId = params.row.c_id;
          onOpenModal("variables", cId);
        };
        return (
          <Button
            onClick={handleClick}
            size="1"
            variant="surface"
            style={{ cursor: "pointer" }}
          >
            View
          </Button>
        );
      },
      disableColumnMenu: true,
    },
    {
      field: "c_id",
      headerName: "Call ID",
      width: 260,
      filter: true,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "error_message",
      headerName: "Error Message",
      sortable: false,
      filter: false,
      width: 260,
      disableColumnMenu: true,
    },
    {
      field: "answered_by",
      headerName: "Answered By",
      sortable: true,
      filter: true,
      width: 120,
      disableColumnMenu: true,
    },
    {
      field: "call_ended_by",
      headerName: "Call Ended By",
      sortable: true,
      filter: true,
      width: 120,
      disableColumnMenu: true,
    },
    {
      field: "batch_id",
      headerName: "Batch ID",
      sortable: false,
      filter: true,
      width: 260,
      disableColumnMenu: true,
    },
  ];

  // useEffect(() => {
  //     if (tableCalls && !currPage) {
  //         const startIndex = (currentPage - 1) * pageSize;
  //         const endIndex = Math.min(startIndex + pageSize, tableCalls.length);
  //         setChunkedData(
  //             tableCalls
  //                 .slice(startIndex, endIndex)
  //                 .map((row, index) => ({ "": startIndex + index + 1, ...row }))
  //         );
  //     } else {
  //         setChunkedData(tableCalls.map((row, index) => ({ "": index + 1, ...row })));
  //     }
  // }, [tableCalls, currentPage, pageSize, currPage]);

  useEffect(() => {
    setTotalPages(pageCount || Math.ceil(tableCalls.length / pageSize));
    setTotalRecords(recordCount || tableCalls.length);
  }, [tableCalls, pageCount, recordCount, pageSize]);

  const [searchParams, setSearchParams] = useSearchParams();

  const handlePrevPage = () => {
    const int = parseInt(currentPage) - 1;
    setCurrentPage(int);
    setSearchParams((params) => {
      params.set("currPage", int);
      return params;
    });
  };

  const handleNextPage = () => {
    const int = parseInt(currentPage) + 1;
    setCurrentPage(int);
    setSearchParams((params) => {
      params.set("currPage", int);
      return params;
    });
  };

  return (
    <Box
      sx={{
        height: "calc(100vh - 150px)",
        maxHeight: "1015px",
        width: "calc(100vw - 100px)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DataGrid
        getRowHeight={() => "auto"}
        columns={columns}
        rows={tableCalls?.map((doc, ind) => ({
          ...doc,
          inbound: doc.inbound ? "inbound" : "outbound",
          call_length: convertToMinutesAndSeconds(doc.call_length),
          created_at: toDtmy(new Date(doc.created_at)),
          id: ind,
          cost: `$${doc?.price ? parseFloat(doc.price)?.toFixed(2) : "0.00"}`,
          completed: doc.completed ? "Completed" : "In Progress",
          pathway_logs: doc?.pathway_logs
            ? JSON.stringify(doc?.pathway_logs || {})
            : "N/A",
          variables: doc?.variables
            ? JSON.stringify(doc?.variables || {})
            : "N/A",
          metadata: doc?.metadata ? JSON.stringify(doc?.metadata || {}) : "N/A",
          batch_id: doc?.batch_id || "-",
          pathway_id: doc?.pathway_id || "-",
          pathway_name: doc?.pathway_name || "-",
          answered_by: doc?.answered_by || "-",
          error_message: doc?.error_message || "-",
          call_ended_by: doc?.call_ended_by || "-",
        }))}
        headerHeight={40}
        pageSize={pageSize}
        rowsPerPageOptions={[pageSize]}
        getRowClassName={getRowClassName}
        sx={{
          flexGrow: 1,
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: 600,
            fontSize: "13px",
          },
          "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
            {
              outline: "none !important",
            },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none !important",
            background: "transparent !important",
          },
          "& .MuiDataGrid-cell": {
            fontSize: "12px",
          },

          "& .MuiDataGrid-row:hover": {
            background: "#FAFAFA !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "#f5f5f5",
          },
          "& .MuiDataGrid-virtualScroller": {
            marginTop: "0!important",
            overflow: "scroll", // Allow scrolling
            scrollbarWidth: "none", // For Firefox
            "&::-webkit-scrollbar": {
              display: "none", // For Chrome, Safari, and Opera
            },
          },
          "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
            borderRight: "1px solid #303030",
            borderRightColor: "#f0f0f0",
          },
          "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
            borderBottom: "1px solid #303030",
            borderBottomColor: "#f0f0f0",
          },
          borderRadius: "4px",
        }}
        autoPageSize={false}
        paginationMode={"server"}
        loading={loading}
        pagination={true}
        rowCount={recordCount}
        showCellRightBorder={false}
        showColumnRightBorder={false}
        page={currPage}
        onPageChange={(page, details) => {
          if (page + 1 > currentPage) {
            handleNextPage();
          } else {
            handlePrevPage();
          }
        }}
        checkboxSelection={true}
        onSelectionModelChange={onChangeSelected}
        onRowClick={(params, event) => {
          // Prevent row selection when clicking on audio controls
          if (event.target.closest(".audio-controls")) {
            event.stopPropagation();
          }
        }}
      />
    </Box>
  );
};

const AudioControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

const SliderWrapper = styled.div`
  flex-grow: 1;
  margin: 0 10px;
`;

// Add this new styled component for the spinning loader
const SpinningIcon = styled(Icons.ReloadIcon)`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: spin 1s linear infinite;
`;

export default AdvancedTable;
