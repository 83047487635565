import axios from "axios";
import { getAuthToken } from "../browser/getAuthToken";
import { getUserData } from "../browser/getUserData";

export const purchaseCredits = async (amount, api_key, save_details) => {
  try {
    const token = getAuthToken();
    const headers = {
      "Content-Type": "application/json",
      authorization: token,
    };
    const user = getUserData();

    const response = await axios.post(
      "/api/pay/payment",
      {
        option: amount,
        saveDetails: save_details || true,
        api_key: api_key,
        confirmed: false,
        referrer_code: user?.referrer_code,
      },
      {
        headers: headers,
      },
    );
    if (response.status === 200) {
      console.log(
        `Success creating client secret to purchase ${amount} credits:`,
        response,
      );
      return response.data.clientSecret;
    } else {
      console.error(
        `HTTP error creating client secret to purchase ${amount} credits:`,
        response.data,
      );
      return response.data;
    }
  } catch (error) {
    console.error(
      `Error creating client secret to purchase ${amount} credits:`,
      error,
    );
    return error;
  }
};

export const getCreditPurchases = async () => {
  try {
    const token = getAuthToken();
    const user = getUserData();

    const headers = {
      "Content-Type": "application/json",
      authorization: token,
    };

    const response = await axios.post(
      "/api/pay/purchases",
      {
        userId: user?.user?.id,
      },
      {
        headers: headers,
      },
    );

    if (response.status === 200) {
      return response.data?.creditPurchases;
    }

    return null;
  } catch (err) {
    console.error("ERROR TRYING TO GET CREDIT PURCHASES");
    return null;
  }
};
