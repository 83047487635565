import { Switch } from "@headlessui/react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { getUserData } from "../../../../utils/funcs/browser/getUserData";
import { useEffect, useState, useCallback } from "react";

export default function WarmTransferOptions({
  warmTransferFields,
  setWarmTransferFields,
}) {
  const [isWarmTransferDisabled, setIsWarmTransferDisabled] = useState(false);

  const updateField = useCallback(
    (field, value) => {
      setWarmTransferFields((prev) => ({ ...prev, [field]: value }));
    },
    [setWarmTransferFields],
  );

  useEffect(() => {
    const fetchUserData = async () => {
      const userData = await getUserData();
      console.log(userData);
      if (
        userData &&
        (userData?.user.placement_group === "bland" ||
          userData?.user.placement_group === "blandshared")
      ) {
        setIsWarmTransferDisabled(true);
        updateField("isEnabled", false);
      }
    };

    fetchUserData();
  }, [updateField]);

  return (
    <div className="border p-4 rounded-md shadow-sm">
      <div className="flex items-center justify-between">
        <h3 className="text-xl">Warm Transfer</h3>
        <Switch
          checked={warmTransferFields.isEnabled}
          onChange={(value) => updateField("isEnabled", value)}
          disabled={isWarmTransferDisabled}
          className={`${
            warmTransferFields.isEnabled ? "bg-indigo-600" : "bg-gray-200"
          } relative inline-flex h-6 w-11 items-center rounded-full ${
            isWarmTransferDisabled ? "opacity-50 cursor-not-allowed" : ""
          }`}
        >
          <span className="sr-only">Enable Warm Transfer</span>
          <span
            className={`${
              warmTransferFields.isEnabled ? "translate-x-6" : "translate-x-1"
            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
          />
        </Switch>
      </div>
      <p className="text-base text-gray-600 mt-2">
        Enable warm transfer options to configure how the call is transferred to
        a live agent.
      </p>
      {isWarmTransferDisabled && (
        <p className="text-sm text-red-500 mt-2">
          Warm transfers are not available for your account type.
        </p>
      )}
      {warmTransferFields.isEnabled && !isWarmTransferDisabled && (
        <p className="text-sm text-gray-600 mt-2">
          When a customer requests a transfer, a proxy agent (a second AI agent)
          calls the transfer number. The live agent can talk to the customer,
          ask questions about the call, and then merge the calls. The agent
          merges the calls and introduces the two parties to each other.
        </p>
      )}
      {warmTransferFields.isEnabled && !isWarmTransferDisabled && (
        <div className="mt-6 space-y-6">
          <div>
            <div className="flex items-center justify-between">
              <Label
                htmlFor="use-custom-proxy-number"
                className="text-sm font-medium"
              >
                Proxy Agent Number
              </Label>
              <Switch
                checked={warmTransferFields.useCustomFromNumber}
                onChange={(value) => updateField("useCustomFromNumber", value)}
                className={`${
                  warmTransferFields.useCustomFromNumber
                    ? "bg-indigo-600"
                    : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Use Custom Proxy Number</span>
                <span
                  className={`${
                    warmTransferFields.useCustomFromNumber
                      ? "translate-x-6"
                      : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
            <p className="text-sm text-gray-500 mt-1">
              The phone number that the proxy agent will call from. A default
              number is used if not enabled.
            </p>
            {warmTransferFields.useCustomFromNumber && (
              <Input
                id="proxy-number"
                value={warmTransferFields.fromNumber}
                onChange={(e) => updateField("fromNumber", e.target.value)}
                placeholder="Enter the proxy agent's phone number"
                className="mt-2"
              />
            )}
          </div>
          <div>
            <div className="flex items-center justify-between">
              <Label htmlFor="agent-prompt" className="text-sm font-medium">
                Live Agent Briefing Prompt
              </Label>
              <div className="flex items-center space-x-2">
                <Switch
                  checked={warmTransferFields.isAgentPromptStatic}
                  onChange={(value) =>
                    updateField("isAgentPromptStatic", value)
                  }
                  className={`${
                    warmTransferFields.isAgentPromptStatic
                      ? "bg-indigo-600"
                      : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span className="sr-only">Use Static Text</span>
                  <span
                    className={`${
                      warmTransferFields.isAgentPromptStatic
                        ? "translate-x-6"
                        : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
                <Label htmlFor="agent-prompt-static" className="text-sm">
                  Static Text
                </Label>
              </div>
            </div>
            <p className="text-sm text-gray-500 mt-1">
              This prompt is used to brief the live agent about the call before
              merging.
            </p>
            <textarea
              id="agent-prompt"
              value={warmTransferFields.agentPrompt}
              onChange={(e) => updateField("agentPrompt", e.target.value)}
              placeholder={
                warmTransferFields.isAgentPromptStatic
                  ? "Enter the exact text to brief the live agent"
                  : "Enter the prompt to brief the live agent"
              }
              className="border border-gray-300 p-2 rounded-md w-full mt-1 min-h-[80px]"
            />
          </div>

          <div>
            <div className="flex items-center justify-between">
              <Label htmlFor="use-hold-music" className="text-sm font-medium">
                Hold Music
              </Label>
              <Switch
                checked={warmTransferFields.useCustomHoldMusic}
                onChange={(value) => updateField("useCustomHoldMusic", value)}
                className={`${
                  warmTransferFields.useCustomHoldMusic
                    ? "bg-indigo-600"
                    : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Use Hold Music</span>
                <span
                  className={`${
                    warmTransferFields.useCustomHoldMusic
                      ? "translate-x-6"
                      : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
            </div>
            <p className="text-sm text-gray-500 mt-1">
              When enabled, the user will hear hold music while the proxy agent
              briefs the live agent and waits for them to be ready.
            </p>
            {warmTransferFields.useCustomHoldMusic && (
              <Input
                id="hold-music-url"
                value={warmTransferFields.holdMusicUrl}
                onChange={(e) => updateField("holdMusicUrl", e.target.value)}
                placeholder="Enter URL for hold music"
                className="mt-2"
              />
            )}
          </div>
          <div>
            <div className="flex items-center justify-between">
              <Label
                htmlFor="merge-call-prompt"
                className="text-sm font-medium"
              >
                Merge Call Prompt
              </Label>
              <div className="flex items-center space-x-2">
                <Switch
                  checked={warmTransferFields.isMergeCallPromptStatic}
                  onChange={(value) =>
                    updateField("isMergeCallPromptStatic", value)
                  }
                  className={`${
                    warmTransferFields.isMergeCallPromptStatic
                      ? "bg-indigo-600"
                      : "bg-gray-200"
                  } relative inline-flex h-6 w-11 items-center rounded-full`}
                >
                  <span className="sr-only">Use Static Text</span>
                  <span
                    className={`${
                      warmTransferFields.isMergeCallPromptStatic
                        ? "translate-x-6"
                        : "translate-x-1"
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                  />
                </Switch>
                <Label htmlFor="merge-call-prompt-static" className="text-sm">
                  Static Text
                </Label>
              </div>
            </div>
            <p className="text-sm text-gray-500 mt-1">
              This prompt generates the message said to both the user and the
              live agent when merging the call. If set as static text, the exact
              sentence will be used.
            </p>
            <textarea
              id="merge-call-prompt"
              value={warmTransferFields.mergeCallPrompt}
              onChange={(e) => updateField("mergeCallPrompt", e.target.value)}
              placeholder={
                warmTransferFields.isMergeCallPromptStatic
                  ? "Enter the exact text to be said when merging the call"
                  : "Enter the prompt to be said when merging the call"
              }
              className="border border-gray-300 p-2 rounded-md w-full mt-1 min-h-[80px]"
            />
          </div>

          <p className="text-sm text-gray-500 mt-4">
            Note: You can reference variables from any call in these prompts.
          </p>
        </div>
      )}
    </div>
  );
}
