import axios from "axios";
import { getAuthToken } from "../browser/getAuthToken";

export const verifyUser = async () => {
  const authToken = getAuthToken();
  console.log("Auth token:", authToken);
  if (authToken) {
    try {
      const response = await axios.get("/api/user/verify", {
        headers: { Authorization: `${authToken}` },
      });
      if (response.data.status) {
        // Once the data is successfully fetched, update the response accordingly.
        return { is_user_verified: true };
      } else {
        // If data is falsy, it means verification failed but there was no error in fetching.
        return { is_user_verified: false };
      }
    } catch (error) {
      console.log("Error verifying user:", error);
      return { is_user_verified: null };
    }
  }
};
