import { useNavigate } from "react-router-dom";

export const LoginCat = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-row gap-1 mx-auto">
      Already have an account?
      <p
        onClick={() => {
          navigate("/login");
        }}
        className="font-bold hover:cursor-pointer decoration-solid"
      >
        Login
      </p>
    </div>
  );
};
