// typography.js
export const fontSizes = {
  xs: "1rem", // x-small
  sm: "1.2rem", // small
  base: "1.3rem", // p
  lg: "1.5rem", // h6
  xl: "1.6rem", // h5
  "2xl": "1.8rem", // h4
  "3xl": "2.0rem", // h3
  "4xl": "2.2rem", // h2
  "5xl": "2.5rem", // h1
  "6xl": "3.0rem", // h0
};
