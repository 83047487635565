import Button from "components/core/Button";

export const Promotion = () => {
  return (
    <div className="w-full h-full p-8 hidden lg:block">
      <div className="rounded-md w-full h-full bg-grayblue flex flex-col justify-between p-16">
        <h1 className="text-7xl font-medium">
          <span className="text-white">
            Enterprise-grade <br />
            solutions
          </span>{" "}
          <span className="text-white">
            for AI
            <br /> phone calls
          </span>
        </h1>
        <div className="flex flex-col gap-6">
          <p className="text-white">
            Work with a dedicated Bland AI machine learning engineer to create a
            custom phone agent and automate all your organization's phone calls.
          </p>
          <a href="https://app.bland.ai/enterprise">
            <Button appearance={"white"}>Enterprise inquiry</Button>
          </a>
        </div>
      </div>
    </div>
  );
};
