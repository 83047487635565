import { replaceNestedPlaceholders } from "./replaceVariables";

export const flattenJSON = (data) => {
  let result = [];

  function recurse(current) {
    if (typeof current !== "object" || current === null) {
      return; // Ignore non-object values at the root level
    }

    for (let key in current) {
      if (current.hasOwnProperty(key)) {
        if (typeof current[key] === "object" && current[key] !== null) {
          // Recursively process nested objects
          recurse(current[key]);
        } else {
          // Add non-object key-value pairs to the result array
          result.push({ [key]: current[key] });
        }
      }
    }
  }

  // Start recursion with the initial data
  recurse(data);
  return result;
};

const arrayToObject = (array) => {
  const obj = {};
  array?.forEach((item) => {
    const [key, value] = Object.entries(item)[0];
    obj[key] = value;
  });

  return { ...obj };
};

export const parse_data = (schema, raw_payload) => {
  const fpayload = flattenJSON(raw_payload);
  let call_data = {};
  let objFlat = {};

  if (Array.isArray(fpayload)) {
    objFlat = arrayToObject(fpayload) || {};
  }

  Object.keys(schema).forEach((key, index) => {
    if (Array.isArray(schema[key]) && schema[key]?.length > 0) {
      if (
        key === "request_data" ||
        key === "analysis_schema" ||
        key === "metadata" ||
        key === "transfer_list"
      ) {
        schema[key]?.forEach((doc, mInd) => {
          if (doc?.using_key) {
            var obk = {};
            obk[doc.key] = objFlat[doc.value] || "";
            //call_data["request_data"] = call_data["request_data"] || {};
            call_data[key] = {
              ...call_data[key],
              ...obk,
            };
          } else {
            call_data[key] = {
              ...call_data[key],
              [doc.key]: doc.value,
            };
          }
        });
      }
    } else {
      if (schema[key]?.using_key) {
        const parse_key = schema[key].value;
        call_data[key] = raw_payload[parse_key];
      } else {
        try {
          call_data[key] = schema[key]?.value;
        } catch (error) {
          console.log(error.message);
        }
      }
    }

    // add logic to replace placeholders

    // console.log("call data key", call_data[key]);
    // console.log("raw payload", raw_payload);

    call_data[key] = replaceNestedPlaceholders(call_data[key], raw_payload);

    // console.log("after replace", call_data[key]);
  });

  if (Object.keys(call_data)?.find((doc) => doc === "tools")) {
    if (typeof call_data["tools"] !== "string") {
      call_data["tools"] = [];
    } else if (call_data["tools"] === "") {
      call_data["tools"] = [];
    } else {
      call_data["tools"] = [call_data["tools"]];
    }
  }

  function removeNullOrUndefined(obj) {
    const cleanedObj = {};
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined) {
        cleanedObj[key] = obj[key];
      }
    }
    return cleanedObj;
  }

  return removeNullOrUndefined(call_data);
};
