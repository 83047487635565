import styled from "styled-components";
import Button from "./Button";
import phones from "assets/images/3dphone.jpg";
import Modal from "./Modal";
import { useState } from "react";

export default function ColumnCard({
  title,
  description,
  link,
  button,
  modalButton,
  modalComponent,
}) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Card>
        <Left>
          <Title>
            <h1 className="font-bold">{title}</h1>
            <p className="text-secondary">{description}</p>
            {link && (
              <a href={link} target="_blank" rel="noreferrer">
                Learn More
              </a>
            )}
          </Title>
          {modalButton && (
            <Button onClick={() => setOpenModal(true)}>{modalButton}</Button>
          )}
          {button && button}
        </Left>
        <Right>
          <img
            src={phones}
            alt="phone-graphic"
            width="100%"
            style={{ objectFit: "cover", height: "100%" }}
          />
        </Right>
      </Card>
      <Modal height="auto" open={openModal} onClose={() => setOpenModal(false)}>
        {modalComponent}
      </Modal>
    </>
  );
}

const Card = styled.div`
  display: flex;
  flex-direction: row;
  height: 600px;
  border: 1px solid #eeeeee;
  border-radius: 12px;
  overflow: hidden;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
  justify-content: space-between;
  background-color: #fbfbfa;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fbfbfa;
  width: 100%;
`;
