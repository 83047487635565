import { ErrorIcon, InfoIcon, WarningIcon } from "assets/icons/alertIcons";
import styled from "styled-components";

export default function AlertBox({ type, text }) {
  let icon = null;
  const mt = { marginTop: "3px" };

  switch (type) {
    case "info":
      icon = <InfoIcon style={mt} />;
      break;
    case "warning":
      icon = <WarningIcon style={mt} />;
      break;
    case "error":
      icon = <ErrorIcon style={mt} />;
      break;
    default:
      console.log("No type used or recongized for AlertBox.");
  }

  return (
    <Wrapper type={type}>
      {icon}
      <p>{text}</p>
    </Wrapper>
  );
}

const colors = {
  info: {
    bg: "#DAEBFF",
    outline: "#B9DEFF",
    text: "#146DD6",
  },
  warning: {
    bg: "#FFFBEB",
    outline: "#FFECA2",
    text: "#B08C00",
  },
  error: {
    bg: "#FFD8D8",
    outline: "#FFAAAA",
    text: "#D11919",
  },
};

const Wrapper = styled.div`
  background-color: ${({ type }) =>
    type === "info"
      ? colors?.info?.bg
      : type === "warning"
        ? colors?.warning?.bg
        : type === "error"
          ? colors?.error?.bg
          : "transparent"};
  border: 1px solid
    ${({ type }) =>
      type === "info"
        ? colors?.info?.outline
        : type === "warning"
          ? colors?.warning?.outline
          : type === "error"
            ? colors?.error?.outline
            : "#eeeeee"};
  color: ${({ type }) =>
    type === "info"
      ? colors?.info?.text
      : type === "warning"
        ? colors?.warning?.text
        : type === "error"
          ? colors?.error?.text
          : "#eeeeee"};
  padding: 12px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 16px 1fr;
  align-items: start;
  gap: 9px;
`;
