import axios from "axios";
import { getAuthToken } from "../browser/getAuthToken";

export const fetchCalls = async (
  currPage,
  pageSize,
  filters = null,
  asQp = null,
) => {
  try {
    const token = getAuthToken();
    const headers = {
      authorization: `${token}`,
    };
    let path = `/api/user/calls?page=${currPage}&pageSize=${pageSize}`;

    if (filters && filters?.length > 0) {
      for (const filter of filters) {
        if (filter.column === "To") {
          path = path + "&to_number=" + filter.value;
        } else if (filter.column === "From") {
          path = path + "&from_number=" + filter.value;
        } else if (filter.column === "Answered By") {
          path = path + "&answered_by=" + filter.value;
        } else if (filter.column === "Batch ID") {
          path = path + "&batch_id=" + filter.value;
        } else if (filter.column === "Call Length") {
          if (filter.operator === "gt") {
            path = path + "&duration_gt=" + Number(filter.value) || 0;
          } else if (filter.operator === "lt") {
            path = path + "&duration_lt=" + Number(filter.value) || 0;
          }
        } else if (filter.column === "Call ID") {
          path = path + "&call_id=" + filter.value;
        } else if (filter.column === "Direction") {
          path =
            path +
            "&inbound=" +
            (filter.value?.toLowerCase() === "inbound" ? true : false);
        } else if (filter.column === "Error Message") {
          path = path + "&error_message=" + filter.value;
        } else if (filter.column === "Cost") {
          if (filter.operator === "gt") {
            path = path + "&cost_gt=" + Number(filter.value) || 0;
          } else if (filter.operator === "lt") {
            path = path + "&cost_lt=" + Number(filter.value) || 0;
          }
        } else if (filter.column === "Call Ended By") {
          path = path + "&call_ended_by=" + filter.value;
        }
      }
    }

    if (asQp) {
      path = path + "&analysis_schema=" + asQp;
    }

    console.log(path, asQp);

    // Include the page and size in the request.
    const { data } = await axios.get(path, { headers });
    if (data) {
      console.log({
        success: true,
        calls: data.calls,
        totalCalls: data.totalCalls,
        totalPages: data.totalPages,
      });
      return {
        success: true,
        calls: data.calls,
        totalCalls: data.totalCalls,
        totalPages: data.totalPages,
      };
    }
  } catch (error) {
    console.error("Fetch calls error", error);
    return {
      success: false,
      calls: null,
      totalPages: null,
    };
  }
};
