// WrongNodeContent.js
import React from "react";
import { Button } from "@/components/ui/button";
import { Check, Plus } from "lucide-react";

const WrongNodeContent = ({
  message,
  isConditionCorrect,
  setIsConditionCorrect,
  selectedNode,
  setSelectedNode,
  nodeOptions,
  handleAddToFinetuningExample,
  wrongNodeExampleAdded,
}) => {
  return (
    <div className="flex flex-col space-y-6">
      <div>
        <h3 className="text-lg font-semibold text-gray-800 mb-2">
          Was the condition at this node chosen correctly?
        </h3>
        <p className="text-sm text-gray-600 mb-4">
          The condition determines whether the conversation should continue
          along this path or choose a different route.
        </p>
        <div className={`p-3 rounded-lg mb-4 bg-gray-100`}>
          <strong>Selected Condition:</strong>{" "}
          <span
            className={`${message.promptInfo["Condition Achieved"] === true ? "text-green-500" : "text-red-500"}`}
          >
            {String(message.promptInfo["Condition Achieved"])}
          </span>
        </div>
        <div className="flex space-x-4">
          <Button
            onClick={() => setIsConditionCorrect(false)}
            className={`flex-1 ${
              isConditionCorrect === false
                ? "bg-red-500 text-white hover:bg-red-600"
                : "bg-white text-gray-800 hover:bg-red-200"
            }`}
          >
            No
          </Button>
          <Button
            onClick={() => setIsConditionCorrect(true)}
            className={`flex-1 ${
              isConditionCorrect === true
                ? "bg-green-500 text-white hover:bg-green-600"
                : "bg-white text-gray-800 hover:bg-green-200"
            }`}
          >
            Yes
          </Button>
        </div>
      </div>

      {isConditionCorrect === true && (
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">
            Please select the correct route it should have picked
          </h3>

          <div className="p-3 rounded-lg mb-4 bg-gray-100 text-gray-800">
            <strong>Selected Route:</strong>{" "}
            {message.promptInfo["Chosen Pathway"]}
          </div>

          <div className="space-y-2">
            {nodeOptions.map((option) => (
              <Button
                key={option.id}
                onClick={() => setSelectedNode(option.id)}
                className={`bg-white hover:bg-white h-auto p-4 text-left justify-start items-start w-full rounded-lg transition-all duration-200 ease-in-out ${
                  selectedNode === option.id
                    ? "border-2 border-indigo-500"
                    : "border border-gray-200"
                }`}
              >
                <div className="flex items-start">
                  <div className="flex-shrink-0 mt-1 mr-2">
                    {selectedNode === option.id && (
                      <Check className="h-5 w-5 text-blue-500" />
                    )}
                  </div>
                  <div className="text-gray-800">{option.label}</div>
                  <div className="text-gray-500 ml-2">
                    {option.node_name && `(Node Name: ${option.node_name})`}
                  </div>
                </div>
              </Button>
            ))}
          </div>
        </div>
      )}

      <div className="flex">
        <Button
          onClick={handleAddToFinetuningExample}
          disabled={
            isConditionCorrect === true &&
            !selectedNode &&
            wrongNodeExampleAdded
          }
          className="inline-flex items-center mt-4 bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md transition-all duration-200 ease-in-out"
        >
          {wrongNodeExampleAdded ? (
            <>
              <Check className="mr-2 h-4 w-4" />
              Added to Fine-tuning Examples
            </>
          ) : (
            <>
              <Plus className="mr-2 h-4 w-4" />
              Add to Node's {isConditionCorrect ? "Pathway" : "Condition"}{" "}
              Fine-tuning Example
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default WrongNodeContent;
