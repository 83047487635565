import Papa from "papaparse";
import { splitArrayIntoChunks } from "./splitArrayIntoChunks";

export const parseCSV = (file, pageSize) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      complete: function (results) {
        const parsedData = results?.data?.map((row, index) => {
          return {
            ...Object.fromEntries(
              Object.entries(row).filter(
                ([key]) => key !== "" && key !== null && key !== undefined,
              ),
            ),
          };
        });
        const paginatedData = splitArrayIntoChunks(parsedData, pageSize);
        if (parsedData && paginatedData) {
          resolve({ data: parsedData, paginated_data: paginatedData });
        } else {
          reject(new Error("Failed to parse CSV or generate paginated data."));
        }
      },
      header: true,
      skipEmptyLines: "greedy",
      error: function (error) {
        reject(
          new Error("An error occurred while parsing CSV: " + error.message),
        );
      },
    });
  });
};
