import styled from "styled-components";

export default function Profile({ user }) {
  return (
    <AvatarWrapper>
      <Initials>
        {user.user.first_name[0]}
        {user.user.last_name[0]}
      </Initials>
      <PlanNumber>
        <Name>
          {user.user.first_name} {user.user.last_name}
        </Name>
        <div className="flex flex-row gap-5">
          <p className="text-secondary">
            {!user?.user?.paid && "Premium"}
            {user?.user?.paid && "Free"}
          </p>
          <p>{user?.user?.phone_number}</p>
        </div>
      </PlanNumber>
    </AvatarWrapper>
  );
}

const Name = styled.h1`
  letter-spacing: -0.5px;
  font-weight: 500;
`;

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
`;

const Initials = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 100%;
  font-size: 30px;
  border: 1px solid #eeeeee;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #686767;
  background-color: #fbfbfa;
`;

const PlanNumber = styled.div`
  display: flex;
  flex-direction: column;
  letter-spacing: -0.54px;
  align-items: center;
`;
