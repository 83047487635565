import React, { useState, useEffect, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { X } from "lucide-react";
import { getUserData } from "../../utils/funcs/browser/getUserData.js";
import AlertBox from "components/core/AlertBox.js";
import PathwayModal from "./modal.js";

const ShowcaseCreationForm = ({ isOpen, onClose, onSubmit, initialData }) => {
  const [title, setTitle] = useState(initialData?.title || "");
  const [description, setDescription] = useState(
    initialData?.description || "",
  );
  const [author, setAuthor] = useState(initialData?.author || "");
  const [selectedTags, setSelectedTags] = useState(initialData?.tags || []);
  const [selectedPathway, setSelectedPathway] = useState(
    initialData?.pathway_id || "",
  );
  const [userPathways, setUserPathways] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const availableTags = [
    "Customer Service",
    "AI",
    "Support",
    "Sales",
    "Booking",
    "FAQ",
    "Knowledge Base",
    "Lead Qualification",
    "CRM",
  ];

  useEffect(() => {
    const user = getUserData();
    console.log("user", user);
    if (user) {
      setAuthor(`${user.user.first_name} ${user.user.last_name}` || "");
      fetchUserPathways(window.localStorage.getItem("auth"));
    }
  }, []);

  const fetchUserPathways = async (token) => {
    try {
      const response = await fetch(`/api/convo_pathway/get`, {
        method: "GET",
        headers: {
          authorization: token,
        },
      });
      console.log("resp", response);
      if (response.ok) {
        const data = await response.json();
        console.log("data", data);
        setUserPathways(data.data || []);
      }
    } catch (error) {
      console.error("Error fetching user pathways:", error);
    }
  };

  const handleTagToggle = (tag) => {
    if (selectedTags.includes(tag)) {
      setSelectedTags(selectedTags.filter((t) => t !== tag));
    } else if (selectedTags.length < 3) {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit({
      title,
      description,
      author,
      tags: selectedTags,
      pathway_id: selectedPathway,
    });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[80vh] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-[18px] font-medium leading-6 text-gray-900 flex justify-between items-center"
                >
                  Create Showcase Pathway
                  <button
                    type="button"
                    className="text-gray-400 hover:text-gray-500"
                    onClick={onClose}
                  >
                    <X className="h-6 w-6" />
                  </button>
                </Dialog.Title>
                <form onSubmit={handleSubmit} className="mt-4">
                  <div className="space-y-4">
                    {/* Title Field */}
                    <div>
                      <label
                        htmlFor="title"
                        className="block text-[12px] font-medium text-gray-700"
                      >
                        Title
                      </label>
                      <p className="text-[10px] text-gray-500">
                        Give your showcase pathway a descriptive and engaging
                        title.
                      </p>
                      <input
                        type="text"
                        id="title"
                        className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-[12px]"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        required
                      />
                    </div>
                    {/* Description Field */}
                    <div>
                      <label
                        htmlFor="description"
                        className="block text-[12px] font-medium text-gray-700"
                      >
                        Description
                      </label>
                      <p className="text-[10px] text-gray-500">
                        You can input any reference to yourself or a link to a
                        video of a walkthrough the pathway!
                      </p>
                      <textarea
                        id="description"
                        rows={3}
                        className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-[12px]"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        required
                      />
                    </div>
                    {/* Author Field */}
                    <div>
                      <label
                        htmlFor="author"
                        className="block text-[12px] font-medium text-gray-700"
                      >
                        Author
                      </label>
                      <p className="text-[10px] text-gray-500">
                        This is automatically filled with your username but can
                        be edited if needed.
                      </p>
                      <input
                        type="text"
                        id="author"
                        className="mt-2 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-[12px]"
                        value={author}
                        onChange={(e) => setAuthor(e.target.value)}
                        required
                      />
                    </div>
                    {/* Tags Field */}
                    <div>
                      <label className="block text-[12px] font-medium text-gray-700">
                        Tags (max 3)
                      </label>
                      <p className="text-[10px] text-gray-500">
                        Select up to three tags that best describe your showcase
                        pathway.
                      </p>
                      <div className="mt-2 flex flex-wrap gap-2">
                        {availableTags.map((tag) => (
                          <button
                            key={tag}
                            type="button"
                            className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-[11px] font-medium ${
                              selectedTags.includes(tag)
                                ? "bg-indigo-100 text-indigo-800"
                                : "bg-gray-100 text-gray-800"
                            }`}
                            onClick={() => handleTagToggle(tag)}
                          >
                            {tag}
                          </button>
                        ))}
                      </div>
                    </div>
                    {/* Pathway Selection Field */}
                    <div>
                      <label
                        htmlFor="pathway"
                        className="block text-[12px] font-medium text-gray-700"
                      >
                        Select Pathway
                      </label>
                      <p className="text-[10px] text-gray-500">
                        Choose the pathway you want to showcase from your
                        available pathways.
                      </p>
                      <select
                        id="pathway"
                        className="mt-1 p-2 block w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-[12px]"
                        value={selectedPathway}
                        onChange={(e) => setSelectedPathway(e.target.value)}
                        required
                      >
                        <option value="">Select a pathway</option>
                        {userPathways.map((pathway) => (
                          <option key={pathway.id} value={pathway.id}>
                            {pathway.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <AlertBox
                      type="warning"
                      text="Make sure you have no sensitive information/API keys in the pathway you select!"
                    />
                  </div>
                  {/* Submit Button */}
                  <div className="mt-6 flex justify-between items-center">
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-[12px] font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Showcase Pathway
                    </button>
                    <button
                      disabled={!selectedPathway}
                      type="button"
                      className="inline-flex justify-center rounded-md border border-gray-300 px-4 py-2 text-[12px] font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-50 disabled:cursor-not-allowed"
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      Preview Showcase
                    </button>

                    {openModal && (
                      <PathwayModal
                        isOpen={openModal}
                        onClose={() => setOpenModal(false)}
                        pathway={{
                          title,
                          description,
                          author,
                          tags: selectedTags,
                          pathway_id: selectedPathway,
                        }}
                      />
                    )}
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ShowcaseCreationForm;
