import Button from "components/core/Button";
import Input from "components/core/Input";
import styled from "styled-components";
import { FormProvider, useForm } from "react-hook-form";
import { getUserData } from "utils/funcs/browser/getUserData";
import { renameVoice } from "utils/funcs/voices/renameVoice";

export default function RenameVoice({ voice }) {
  const methods = useForm();
  const isValid = methods.formState.isValid;

  const { user, api_key } = getUserData();

  const formReady = isValid;

  const onSubmit = async (formData) => {
    try {
      await renameVoice(formData.new_voice_name, user, api_key);
    } catch (err) {
      console.error("Error renaming voice", renameVoice);
    }
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex justify-between w-full items-center mb-4">
          <h2 className="text-xl font-bold">Rename Voice</h2>
        </div>
        <Input
          fieldName="new_voice_name"
          label="New Voice Name"
          placeholder={"Renaming voice: " + voice?.name}
        />
        <Button submit disabled={!formReady}>
          Confirm
        </Button>
      </Form>
    </FormProvider>
  );
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;
