import styled from "styled-components";
import { DataCard, KeyValue, KeyValues } from "./Styles";
import { useEffect, useState } from "react";
import { getUserData } from "utils/funcs/browser/getUserData";
import { fetchTranscript } from "utils/funcs/call-logs/fetchTranscript";
import PhoneOutboundIcon from "../../../assets/icons/phone-outgoing.svg";
import PhoneInboundIcon from "../../../assets/icons/phone-incoming.svg";
import LoadSpinner from "components/core/LoadSpinner";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card, CardTitle } from "@/components/ui/card";
import * as Icons from "@radix-ui/react-icons";
import { toast } from "react-toastify";
import {
  ExternalLink,
  LucideExternalLink,
  LucideTriangleAlert,
  LucideZap,
  PhoneCallIcon,
  Sparkle,
  Sparkles,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Badge, Flex } from "@radix-ui/themes";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { pathwayTags } from "../ConvoPathways/Components/CallDetailsSlider";

const StyledDialogContent = styled(DialogContent)`
  max-width: 600px;
`;

const DetailItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export default function Call({ call, modal, loading, onClose }) {
  const { user } = getUserData();
  const [view, setView] = useState("details");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <DataCard
        modal={modal}
        style={{
          borderRadius: "1px",
          height: "600px",
          maxHeight: "80vh",
          overflowY: "scroll",
          padding: 10,
        }}
      >
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <LoadSpinner />
          </div>
        ) : (
          <div style={{}}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <CardTitle style={{ fontSize: 20 }}>Call Details</CardTitle>
              <Icons.Cross1Icon
                style={{ cursor: "pointer" }}
                onClick={onClose}
              />
            </div>

            <Flex
              gap={"10px"}
              direction={"row"}
              width={"100%"}
              display={"flex"}
              flexShrink={"0"}
              style={{ marginTop: "15px" }}
            >
              <Button
                onClick={() => setView("details")}
                style={{
                  background: view === "details" ? "#5B5BD6" : "transparent",
                }}
                variant={view === "details" ? "default" : "ghost"}
              >
                <PhoneCallIcon
                  height={12}
                  width={12}
                  style={{ marginRight: "5px" }}
                />
                <p style={{ fontSize: 12 }}>Call</p>
              </Button>
              <Button
                onClick={() => setView("analysis")}
                style={{
                  background: view === "analysis" ? "#5B5BD6" : "transparent",
                }}
                variant={view === "analysis" ? "default" : "ghost"}
              >
                <Sparkles
                  height={12}
                  width={12}
                  style={{ marginRight: "5px" }}
                />
                <p style={{ fontSize: 12 }}>Analysis</p>
              </Button>
            </Flex>

            {view === "details" ? (
              <>
                <Flex
                  gap={"20px"}
                  direction={"row"}
                  width={"100%"}
                  display={"flex"}
                  flexShrink={"0"}
                  style={{
                    marginTop: "15px",
                    flexDirection: window.innerWidth < 699 ? "column" : "row",
                  }}
                >
                  <div
                    style={{
                      minWidth: "50%",
                      width: window.innerWidth < 699 ? "100%" : "auto",
                    }}
                  >
                    <Card id="details-card" className="w-full" style={{}}>
                      <p
                        style={{
                          fontWeight: "550",
                          fontSize: "14px",
                          padding: "12px 10px",
                          background: "#FAFAFA",
                          borderTopLeftRadius: "4px",
                          borderTopRightRadius: "4px",
                        }}
                      >
                        Details
                      </p>
                      <div
                        style={{
                          height: 2,
                          width: "100%",
                          background: "#eeeeee",
                        }}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          padding: "10px",
                          borderBottom: "1px solid #f3f3f3",
                        }}
                      >
                        <p style={{ fontWeight: "450", fontSize: "11px" }}>
                          Call ID
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            columnGap: "5px",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "11px",
                              textAlign: "right",
                            }}
                          >
                            ID
                          </p>
                          <Icons.CopyIcon
                            onClick={() => {
                              navigator.clipboard.writeText(call?.call?.c_id);
                              toast.success("Copied to clipboard");
                            }}
                            height={10}
                            width={10}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          padding: "10px",
                          borderBottom: "1px solid #f3f3f3",
                        }}
                      >
                        <p style={{ fontWeight: "450", fontSize: "11px" }}>
                          To
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            columnGap: "5px",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "11px",
                              textAlign: "right",
                            }}
                          >
                            {call?.call?.to}
                          </p>
                          <Icons.CopyIcon
                            onClick={() => {
                              navigator.clipboard.writeText(call?.call?.to);
                              toast.success("Copied to clipboard");
                            }}
                            height={10}
                            width={10}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          padding: "10px",
                          borderBottom: "1px solid #f3f3f3",
                        }}
                      >
                        <p style={{ fontWeight: "450", fontSize: "11px" }}>
                          From
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            columnGap: "5px",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "11px",
                              textAlign: "right",
                            }}
                          >
                            {call?.call?.from}
                          </p>
                          <Icons.CopyIcon
                            onClick={() => {
                              navigator.clipboard.writeText(call?.call?.from);
                              toast.success("Copied to clipboard");
                            }}
                            height={10}
                            width={10}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          padding: "10px",
                          borderBottom: "1px solid #f3f3f3",
                        }}
                      >
                        <p style={{ fontWeight: "450", fontSize: "11px" }}>
                          Duration
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            columnGap: "5px",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "11px",
                              textAlign: "right",
                            }}
                          >
                            {call?.call?.call_length}
                          </p>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          padding: "10px",
                          borderBottom: "1px solid #f3f3f3",
                        }}
                      >
                        <p style={{ fontWeight: "450", fontSize: "11px" }}>
                          Cost
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            columnGap: "5px",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "11px",
                              textAlign: "right",
                            }}
                          >
                            {call?.call?.cost}
                          </p>
                        </div>
                      </div>

                      {call?.call?.pathway_tags &&
                        call?.call?.pathway_tags !== "-" && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                              padding: "10px",
                              borderBottom: "1px solid #f3f3f3",
                            }}
                          >
                            <p style={{ fontWeight: "450", fontSize: "11px" }}>
                              Pathway Tags
                            </p>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                columnGap: "5px",
                              }}
                            >
                              {pathwayTags(call?.call?.pathway_tags)?.map(
                                (tag) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      alignItems: "center",
                                      columnGap: "7px",
                                    }}
                                  >
                                    <span
                                      key={tag?.name}
                                      className="bg-gray-100 text-gray-800 text-xs px-4 py-1 rounded-full flex items-center gap-2"
                                    >
                                      <div
                                        style={{
                                          height: 10,
                                          width: 10,
                                          borderRadius: "50%",
                                          background: tag?.color,
                                        }}
                                      />
                                      <p>{tag?.name}</p>
                                    </span>
                                  </div>
                                ),
                              )}
                            </div>
                          </div>
                        )}

                      {call?.call?.record && call?.call?.recording_url && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: "10px",
                            borderBottom: "1px solid #f3f3f3",
                          }}
                        >
                          <p style={{ fontWeight: "450", fontSize: "11px" }}>
                            Recording
                          </p>
                          <Button
                            onClick={() => {
                              window.open(call?.call?.recording_url, "_blank");
                            }}
                          >
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "11px",
                                marginRight: "5px",
                              }}
                            >
                              View
                            </p>
                            <ExternalLink
                              height={11}
                              width={11}
                              color="white"
                            />
                          </Button>
                        </div>
                      )}

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          padding: "10px",
                          borderBottom: "1px solid #f3f3f3",
                        }}
                      >
                        <p style={{ fontWeight: "450", fontSize: "11px" }}>
                          Created At
                        </p>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            columnGap: "5px",
                          }}
                        >
                          <p
                            style={{
                              fontWeight: "400",
                              fontSize: "11px",
                              textAlign: "right",
                            }}
                          >
                            {call?.call?.created_at}
                          </p>
                        </div>
                      </div>
                    </Card>

                    <Card
                      id="latency-card"
                      className="w-full"
                      style={{ marginTop: "10px" }}
                    >
                      <p
                        style={{
                          fontWeight: "550",
                          fontSize: "14px",
                          padding: "12px 10px",
                          background: "#FAFAFA",
                          borderTopLeftRadius: "4px",
                          borderTopRightRadius: "4px",
                        }}
                      >
                        Latency
                      </p>
                      <div
                        style={{
                          height: 2,
                          width: "100%",
                          background: "#eeeeee",
                        }}
                      />

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          padding: "10px",
                          borderBottom: "1px solid #f3f3f3",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            columnGap: "10px",
                          }}
                        >
                          <LucideZap height={15} width={15} color="#33B074" />
                          <p style={{ fontWeight: "400", fontSize: "11px" }}>
                            LLM Latency
                          </p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            columnGap: "25px",
                            marginLeft: "auto",
                            padding: "10px",
                          }}
                        >
                          <div id="latency-card-llm-slowest">
                            <p
                              style={{
                                fontWeight: "500",
                                fontSize: "11px",
                                textAlign: "center",
                                color: "#ef233c",
                              }}
                            >
                              {call?.perfLatency?.llm?.slowest} ms
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "11px",
                                textAlign: "center",
                              }}
                            >
                              Slowest
                            </p>
                          </div>

                          <div id="latency-card-llm-fastest">
                            <p
                              style={{
                                fontWeight: "500",
                                fontSize: "11px",
                                textAlign: "center",
                                color: "#33B074",
                              }}
                            >
                              {call?.perfLatency?.llm?.fastest} ms
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "11px",
                                textAlign: "center",
                              }}
                            >
                              Fastest
                            </p>
                          </div>

                          <div id="latency-card-llm-average">
                            <p
                              style={{
                                fontWeight: "500",
                                fontSize: "11px",
                                textAlign: "center",
                              }}
                            >
                              {call?.perfLatency?.llm?.average} ms
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "11px",
                                textAlign: "center",
                              }}
                            >
                              Average
                            </p>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          padding: "10px",
                          borderBottom: "1px solid #f3f3f3",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            columnGap: "10px",
                          }}
                        >
                          <LucideZap height={15} width={15} color="#33B074" />
                          <p style={{ fontWeight: "400", fontSize: "11px" }}>
                            TTS Latency
                          </p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            columnGap: "25px",
                            marginLeft: "auto",
                            padding: "10px",
                          }}
                        >
                          <div id="latency-card-tts-slowest">
                            <p
                              style={{
                                fontWeight: "500",
                                fontSize: "11px",
                                textAlign: "center",
                                color: "#ef233c",
                              }}
                            >
                              {call?.perfLatency?.tts?.slowest} ms
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "11px",
                                textAlign: "center",
                              }}
                            >
                              Slowest
                            </p>
                          </div>

                          <div id="latency-card-tts-fastest">
                            <p
                              style={{
                                fontWeight: "500",
                                fontSize: "11px",
                                textAlign: "center",
                                color: "#33B074",
                              }}
                            >
                              {call?.perfLatency?.tts?.fastest} ms
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "11px",
                                textAlign: "center",
                              }}
                            >
                              Fastest
                            </p>
                          </div>

                          <div id="latency-card-tts-average">
                            <p
                              style={{
                                fontWeight: "500",
                                fontSize: "11px",
                                textAlign: "center",
                              }}
                            >
                              {call?.perfLatency?.tts?.average} ms
                            </p>
                            <p
                              style={{
                                fontWeight: "400",
                                fontSize: "11px",
                                textAlign: "center",
                              }}
                            >
                              Average
                            </p>
                          </div>
                        </div>
                      </div>
                    </Card>

                    {(call?.errors?.length > 0 ||
                      (call?.call?.error_message &&
                        call?.call?.error_message !== "-")) && (
                      <Card
                        id="errors-card"
                        className="w-full"
                        style={{ marginTop: "10px" }}
                      >
                        <p
                          style={{
                            fontWeight: "550",
                            fontSize: "14px",
                            padding: "12px 10px",
                            background: "#FAFAFA",
                            borderTopLeftRadius: "4px",
                            borderTopRightRadius: "4px",
                          }}
                        >
                          Errors
                        </p>

                        {call?.call?.error_message && (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              columnGap: "10px",
                              padding: "10px",
                              borderBottom: "1px solid #f3f3f3",
                            }}
                          >
                            <LucideTriangleAlert
                              style={{ flexShrink: 0 }}
                              height={15}
                              width={15}
                              color="red"
                            />
                            <p style={{ fontWeight: "400", fontSize: "11px" }}>
                              {call?.call?.error_message}
                            </p>
                          </div>
                        )}

                        {call?.errors?.map((error, index) => (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              columnGap: "10px",
                              padding: "10px",
                              borderBottom: "1px solid #f3f3f3",
                            }}
                          >
                            <LucideTriangleAlert
                              style={{ flexShrink: 0 }}
                              height={15}
                              width={15}
                              color="#ffbd00"
                            />
                            <p style={{ fontWeight: "400", fontSize: "11px" }}>
                              [{error?.category}] [{error?.log_level}]{" "}
                              {error?.message}
                            </p>
                          </div>
                        ))}
                      </Card>
                    )}
                  </div>

                  <div
                    style={{ width: window.innerWidth < 699 ? "100%" : "50%" }}
                  >
                    <Tabs
                      defaultValue="summary"
                      className="w-full"
                      style={{ width: "100%" }}
                    >
                      <TabsList className="h-[30px]" style={{ width: "100%" }}>
                        <TabsTrigger
                          value="summary"
                          style={{
                            width: "100%",
                            height: "100%",
                            fontSize: 12,
                            fontWeight: "500",
                          }}
                        >
                          Summary
                        </TabsTrigger>
                        <TabsTrigger
                          value="transcripts"
                          style={{
                            width: "100%",
                            height: "100%",
                            fontSize: 12,
                            fontWeight: "500",
                          }}
                        >
                          Transcripts
                        </TabsTrigger>
                      </TabsList>
                      <TabsContent
                        value="summary"
                        style={{
                          marginTop: "15px",
                          maxHeight: 350,
                          overflowY: "scroll",
                        }}
                      >
                        <Card style={{}}>
                          {call?.call?.summary ? (
                            <>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "0px",
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: "550",
                                    fontSize: "14px",
                                    padding: "12px 10px",
                                    background: "#FAFAFA",
                                    borderTopLeftRadius: "4px",
                                    borderTopRightRadius: "4px",
                                  }}
                                >
                                  Summary
                                </p>
                                <div
                                  style={{
                                    height: 2,
                                    width: "100%",
                                    background: "#eeeeee",
                                  }}
                                />
                              </div>

                              <p
                                style={{
                                  fontWeight: "400",
                                  fontSize: "13px",
                                  padding: "12px 10px",
                                }}
                              >
                                {call?.call?.summary}
                              </p>
                            </>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                padding: 30,
                                borderBottom: "2px solid #f3f3f3",
                              }}
                            >
                              <LucideTriangleAlert
                                height={15}
                                width={15}
                                color="black"
                              />
                              <p
                                style={{ fontWeight: "500", fontSize: "15px" }}
                              >
                                No summary found
                              </p>
                            </div>
                          )}
                        </Card>
                      </TabsContent>
                      <TabsContent
                        value="transcripts"
                        style={{
                          marginTop: "15px",
                          maxHeight: 350,
                          overflowY: "scroll",
                        }}
                      >
                        {call?.transcripts?.length > 0 ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              gap: "10px",
                            }}
                          >
                            {call?.transcripts?.map((transcript, index) => (
                              <div
                                key={index}
                                style={{
                                  padding: "10px",
                                  borderRadius: "3px",
                                  background:
                                    transcript?.user === "assistant"
                                      ? "#2667ff"
                                      : transcript?.user === "agent-action"
                                        ? "transparent"
                                        : "#f8f8f8",
                                  border:
                                    transcript?.user === "agent-action"
                                      ? "1px solid #ff9f1c"
                                      : "none",
                                  alignSelf:
                                    transcript?.user === "assistant"
                                      ? "flex-end"
                                      : transcript?.user === "user"
                                        ? "flex-start"
                                        : "center",
                                  width:
                                    transcript?.user === "agent-action"
                                      ? "100%"
                                      : "90%",
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: "550",
                                    fontSize: "13px",
                                    color:
                                      transcript?.user === "assistant"
                                        ? "#fff"
                                        : "#000",
                                  }}
                                >
                                  {transcript?.user?.toUpperCase()}
                                </p>
                                <p
                                  style={{
                                    fontWeight: "400",
                                    fontSize: "13px",
                                    marginTop: "5px",
                                    color:
                                      transcript?.user === "assistant"
                                        ? "#fff"
                                        : "#000",
                                  }}
                                >
                                  {transcript?.text}
                                </p>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <Card style={{}} className="w-full">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                padding: 30,
                                borderBottom: "2px solid #f3f3f3",
                              }}
                            >
                              <LucideTriangleAlert
                                height={15}
                                width={15}
                                color="black"
                              />
                              <p
                                style={{ fontWeight: "500", fontSize: "15px" }}
                              >
                                No transcripts found
                              </p>
                            </div>
                          </Card>
                        )}
                      </TabsContent>
                    </Tabs>
                  </div>
                </Flex>

                <Accordion
                  type="single"
                  collapsible
                  style={{ marginTop: "20px" }}
                >
                  <AccordionItem
                    value="variables"
                    style={{ borderBottom: "none" }}
                  >
                    <AccordionTrigger
                      style={{
                        background: "#FAFAFA",
                        borderRadius: "4px",
                        padding: "15px",
                      }}
                    >
                      <CardTitle style={{ fontSize: 14, fontWeight: "550" }}>
                        Variables
                      </CardTitle>
                    </AccordionTrigger>
                    <AccordionContent style={{ border: "1px solid #FAFAFA" }}>
                      {call?.call?.variables === "-" ||
                      call?.call?.variables === "N/A" ? (
                        <p
                          style={{
                            fontWeight: "400",
                            fontSize: "13px",
                            padding: "12px 10px",
                          }}
                        >
                          No variables found
                        </p>
                      ) : (
                        <>
                          {/* <p style={{ fontWeight: '550', fontSize: '14px', padding: "12px 10px", background: "#FAFAFA", borderTopLeftRadius: '4px', borderTopRightRadius: '4px' }}>Variables</p>
                                            <div style={{ height: 2, width: '100%', background: "#eeeeee" }} /> */}
                          {Object.keys(
                            JSON.parse(
                              JSON.parse(call?.call?.variables || "{}") || "{}",
                            ) || {},
                          )?.map((key, index) => (
                            <div
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                columnGap: "10px",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "100%",
                                padding: "10px",
                                borderBottom: "1px solid #f3f3f3",
                              }}
                            >
                              <p
                                style={{ fontWeight: "450", fontSize: "11px" }}
                              >
                                {key}
                              </p>
                              <p
                                style={{ fontWeight: "400", fontSize: "11px" }}
                              >
                                {JSON.stringify(
                                  JSON.parse(
                                    JSON.parse(call?.call?.variables || "{}"),
                                  )?.[key],
                                )}
                              </p>
                            </div>
                          ))}
                        </>
                      )}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </>
            ) : (
              <Flex
                direction={"column"}
                gap={"15px"}
                width={"100%"}
                style={{ marginTop: "15px" }}
              >
                {call?.quick_analysis && (
                  <Card className="w-[full] p-6 grid gap-6">
                    <div className="flex items-center gap-4">
                      <div className="bg-primary rounded-md p-3 flex items-center justify-center">
                        <Sparkles className="w-6 h-6 text-primary-foreground" />
                      </div>
                      <div>
                        <p style={{ fontSize: 14, fontWeight: "bold" }}>
                          Quick Analysis
                        </p>
                        <p className="text-muted-foreground text-sm">
                          Sentiment:{" "}
                          {call?.quick_analysis?.sentiment?.toUpperCase()}
                        </p>
                      </div>
                    </div>
                    <div className="grid gap-4">
                      <div>
                        <p style={{ fontSize: 12, fontWeight: "450" }}>
                          Topics Discussed
                        </p>
                        <ul className="mt-2 space-y-1 text-sm text-muted-foreground">
                          {call?.quick_analysis?.topics?.length > 0 ? (
                            call?.quick_analysis?.topics?.map(
                              (topic, index) => <li key={index}>{topic}</li>,
                            )
                          ) : (
                            <li>No topics discussed</li>
                          )}
                        </ul>
                      </div>
                      <div>
                        <p style={{ fontSize: 12, fontWeight: "450" }}>
                          Issues Raised
                        </p>
                        <ul className="mt-2 space-y-1 text-sm text-muted-foreground">
                          {call?.quick_analysis?.caller_issues?.length > 0 ? (
                            call?.quick_analysis?.caller_issues?.map(
                              (issue, index) => <li key={index}>{issue}</li>,
                            )
                          ) : (
                            <li>No issues raised</li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </Card>
                )}

                {call?.call?.analysis && (
                  <Card style={{}}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0px",
                      }}
                    >
                      <p
                        style={{
                          fontWeight: "550",
                          fontSize: "14px",
                          padding: "12px 10px",
                          background: "#FAFAFA",
                          borderTopLeftRadius: "4px",
                          borderTopRightRadius: "4px",
                        }}
                      >
                        Analysis
                      </p>
                      <div
                        style={{
                          height: 2,
                          width: "100%",
                          background: "#eeeeee",
                        }}
                      />
                    </div>

                    {Object.keys(call?.call?.analysis || {})?.map(
                      (key, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            columnGap: "10px",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                            padding: "10px",
                            borderBottom: "1px solid #f3f3f3",
                          }}
                        >
                          <p style={{ fontWeight: "450", fontSize: "11px" }}>
                            {key}
                          </p>
                          <p style={{ fontWeight: "400", fontSize: "11px" }}>
                            {JSON.stringify(call?.call?.analysis?.[key])}
                          </p>
                        </div>
                      ),
                    )}
                  </Card>
                )}
              </Flex>
            )}
          </div>
        )}
      </DataCard>
    </>
  );
}

const Option = styled.div`
  border: 1px solid #eeeeee;
  padding: 4px 12px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({ f }) => (f ? "#f8f8f8" : "transparent")};
`;

const Label = styled.label`
  font-size: 11px;
  font-weight: 500;
  color: #000;
`;

const Value = styled.p`
  font-size: 14px;
  font-weight: 550;
  color: #555;
  margin: 0;
  padding: 0;
`;

const DataGrid = styled.div`
  display: grid;
  grid-template-columns: 30% 1px 65%;
  gap: 10px;
`;

const DataItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-height: 340px;
  overflow-x: scroll;
  overflow-y: scroll;
`;

const VerticalLine = styled.div`
  border-left: 1px solid #eeeeee;
  height: 340px;
  margin-top: -19px;
`;

const StatusBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: ${({ status }) =>
    status === true ? "#d8f3dc" : "#fefee3"};
  color: ${({ status }) => (status === true ? "#2d6a4f" : "#ffbd00")};
  font-size: 12px;
  font-weight: 550;
`;
