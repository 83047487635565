import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DataCard, KeyValue, KeyValues } from "./Styles";

export default function Metadata({ calls, modal }) {
  const [searchParams] = useSearchParams();
  const callId = searchParams.get("callId");
  const [call, setCall] = useState();
  const [metadataLength, setMetadataLength] = useState([]);

  useEffect(() => {
    if (calls) {
      const matchedCall = calls?.find((call) => call.c_id === callId);
      setCall(matchedCall);
      const length = Object.keys(matchedCall?.metadata ?? {})?.length;
      setMetadataLength(length);
    }
  }, [callId, calls]);

  useEffect(() => {
    console.log("matched call", call);
  }, [call]);

  const renderMetadata = () => {
    const parsedMetadata = call?.metadata || {};
    return (
      <KeyValues>
        {Object.entries(parsedMetadata).map(([key, value]) => (
          <KeyValue key={key}>
            <p>{key}:</p>
            <p>{typeof value === "object" ? JSON.stringify(value) : value}</p>
          </KeyValue>
        ))}
      </KeyValues>
    );
  };
  return (
    <>
      {metadataLength > 0 && (
        <DataCard modal={modal}>
          <h2 className="font-bold">Metadata</h2>
          <div>{renderMetadata()}</div>
        </DataCard>
      )}
    </>
  );
}
