import styled from "styled-components";
import Label from "./Label";
import CsvExampleIcon from "../../assets/icons/csv-example.svg";
import { ToastContainer, toast } from "react-toastify";

export default function FileInput({
  fieldName,
  onChange,
  iconSrc,
  text,
  label,
  description,
  exampleCsv = false,
  ...props
}) {
  return (
    <div className="flex flex-col gap-2">
      <Label>{label}</Label>
      <Description>{description}</Description>
      <div style={{ display: "flex", flexDirection: "row", columnGap: 8 }}>
        <UploadButton
          onClick={() => {
            document.getElementById("csv-upload").click();
          }}
        >
          <img src={iconSrc} alt="" />
          <p className="whitespace-nowrap">{text}</p>
        </UploadButton>
        {exampleCsv && (
          <UploadButton
            onClick={() => {
              const exampleData = [
                ["phone_number"],
                ["+12345678900"],
                ["+19876543210"],
              ];
              let csvContent =
                "data:text/csv;charset=utf-8," +
                exampleData.map((e) => e.join(",")).join("\n");
              var encodedUri = encodeURI(csvContent);
              var link = document.createElement("a");
              link.setAttribute("href", encodedUri);
              link.setAttribute("download", "example.csv");
              document.body.appendChild(link);
              link.click();
            }}
          >
            <img src={CsvExampleIcon} alt="" />
            <p className="whitespace-nowrap">Download Starter CSV</p>
          </UploadButton>
        )}
        {exampleCsv && (
          <UploadButton
            onClick={() => {
              const exampleData2 = [
                [
                  "phone_number",
                  "business",
                  "service",
                  "date",
                  "previous_customer",
                  "city",
                  "persons_name",
                ], // Declaration Columns
                [
                  "+1234567890",
                  "Example Coffee Service",
                  "social media posting",
                  "September 4th",
                  "Yes",
                  "Denver",
                  "Jake H",
                ], // Adding more data
                [
                  "+1234567890",
                  "Wonderful Bakery",
                  "Window Cleaning",
                  "December 20th",
                  "No",
                  "Fresno",
                  "Moses K",
                ], // Adding more data
                [
                  "+1234567890",
                  "Fitzone Gym",
                  "Videography",
                  "October 30th",
                  "No",
                  "San Diego",
                  "John F",
                ], // Adding more data
                [
                  "+1234567890",
                  "Fresh Bites Deli",
                  "Social Media Posting",
                  "January 10th",
                  "Yes",
                  "San Francisco",
                  "Steve J",
                ], // Adding more data
                [
                  "+1234567890",
                  "Urban Eats Resturant",
                  "Videography",
                  "Febuary 9th",
                  "Yes",
                  "New York",
                  "Tim Cc",
                ], // Adding more data
              ];
              let csvContent2 =
                "data:text/csv;charset=utf-8," +
                exampleData2.map((e) => e.join(",")).join("\n");
              var encodedUri2 = encodeURI(csvContent2);
              var link2 = document.createElement("a");
              link2.setAttribute("href", encodedUri2);
              link2.setAttribute("download", "bland_complex.csv");
              document.body.appendChild(link2);
              link2.click();
            }}
          >
            <img src={CsvExampleIcon} alt="" />
            <p className="whitespace-nowrap">Download Complex CSV</p>
          </UploadButton>
        )}
      </div>
      <input
        hidden
        type="file"
        accept="text/csv"
        id="csv-upload"
        onChange={onChange}
        {...props}
      />
    </div>
  );
}

const UploadButton = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 9px;
  align-items: center;
  justify-content: start;
  padding: 9px 12px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  cursor: pointer;
  width: fit-content;
  overflow: hidden;
  &:hover {
    background-color: #0000000d;
  }
`;

const Description = styled.p`
  margin-bottom: 12px;
  font-size: 1.2rem;
`;
