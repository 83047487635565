import { useNavigate } from "react-router-dom";
import logo from "../../assets/brand/bland-logo-black.svg";

const BlandLogo = () => {
  const navigate = useNavigate();

  return (
    <button onClick={() => navigate("/home")}>
      <img alt="" src={logo} className="w-12" />
    </button>
  );
};
export default BlandLogo;
