// CONVERTS:

// [
//    {
//       "key": "Hello",
//       "value": "Blandy"
//     },
//     {
//       "key": "Voice",
//       "value": "Agent"
//     }
//  ]

// TO

// {
// "Hello" : "Blandy",
// "Voice" : "Agent"
// }

export const KeyValuesToObj = (keyValues) => {
  if (!Array.isArray(keyValues) && typeof keyValues !== "object") {
    //console.error("keyValues is not an array", keyValues);
    return {};
  }
  // if keyValues is an object already, leave it as is
  if (!Array.isArray(keyValues)) {
    // means its an object or null perhaps
    return keyValues;
  }
  return keyValues.reduce((acc, param) => {
    acc[param.key] = param.value;
    return acc;
  }, {});
};
