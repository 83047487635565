import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
);

// Function to generate a random color
const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

// Function to truncate labels
const truncateLabel = (label, maxLength) => {
  return label.length > maxLength
    ? label.substring(0, maxLength) + "..."
    : label;
};

export const BarChart = ({ data, maxLabelLength = 10 }) => {
  const labels = data.map((item) => Object.keys(item)[0]);
  const values = data.map((item) => Object.values(item)[0]);
  const truncatedLabels = labels.map((label) =>
    truncateLabel(label, maxLabelLength),
  );

  const chartData = {
    labels: truncatedLabels,
    datasets: [
      {
        label: "Data",
        data: values,
        backgroundColor: "rgba(63,206,141,255)",
        borderColor: "rgba(63,206,141,255)",
        borderWidth: 1,
        borderRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        labels: {
          generateLabels: (chart) => {
            return chartData.labels.map((label, index) => {
              return {
                text: labels[index], // Use original labels for legend
                fillStyle: chartData.datasets[0].backgroundColor[index],
              };
            });
          },
        },
      },
      title: {
        display: false,
        text: "Bar Chart",
      },
    },
    scales: {
      x: {
        ticks: {
          callback: function (value, index) {
            return truncatedLabels[index];
          },
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};
