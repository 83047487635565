import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineStar } from "react-icons/ai";
import { AiFillStar } from "react-icons/ai";
import { toast } from "react-toastify";
import { RxSpeakerLoud } from "react-icons/rx";
import { rateVoice } from "../../../utils/funcs/voices/rateVoice";
import { getUserData } from "../../../utils/funcs/browser/getUserData";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { getVoice } from "utils/funcs/voices/getVoice";
import { GoPerson } from "react-icons/go";
import Button from "components/core/Button";
import Modal from "components/core/Modal";

export default function VoiceCard({
  index,
  data: voice,
  text,
  isPublicTab,
  hasBeenMadePublic = false,
  onPublishBtn = () => {},
  userCreated = false,
}) {
  const [voiceData, setVoiceData] = useState(voice);
  const [latency, setLatency] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [userRating, setUserRating] = useState(null); // Store the user's rating
  const [hoverRating, setHoverRating] = useState(0);

  const { api_key } = getUserData();

  useEffect(() => {
    const rated = localStorage.getItem(`rating_${voiceData.id}`);
    if (rated || rated === 0) {
      setUserRating(parseInt(rated)); // Set the user's rating from localStorage
    }
  }, [voiceData.id]);

  const handleRatingClick = async (rating) => {
    const newRating = rating === userRating ? null : rating; // Toggle the rating
    setUserRating(newRating);
    localStorage.setItem(`rating_${voiceData.id}`, newRating);

    try {
      await rateVoice(voiceData, api_key, newRating);
      const { voice } = await getVoice(voiceData, api_key, null);
      setVoiceData(voice);
    } catch (error) {
      console.error("Failed to set ratings:", error);
    }
  };

  const addToLibrary = async () => {
    try {
      const response = await fetch(
        `https://us.api.bland.ai/v1/voices/library/add/` + voiceData.id,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: api_key,
          },
        },
      );

      if (response.ok) {
        toast.success("Voice added to library");
      } else {
        toast.error("Failed to add voice to library.");
      }
    } catch (error) {
      console.error("Failed to add voice to library:", error);
    }
  };

  const fetchTTSAudio = async (voiceId) => {
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://us.api.bland.ai/v1/voices/` + voiceId + "/sample",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: api_key,
          },
          body: JSON.stringify({ text, response_type: "stream" }),
        },
      );

      let latency = null;

      if (response.ok) {
        latency = response.headers.get("x-latency");
        setLatency(latency);
      }
      const mediaSource = new MediaSource();
      const audio = new Audio();
      audio.src = URL.createObjectURL(mediaSource);

      await new Promise((resolve, reject) => {
        mediaSource.addEventListener(
          "sourceopen",
          () => {
            const sourceBuffer = mediaSource.addSourceBuffer("audio/mpeg");
            const reader = response.body.getReader();

            const read = () => {
              reader
                .read()
                .then(({ done, value }) => {
                  if (done) {
                    if (sourceBuffer.updating) {
                      sourceBuffer.addEventListener(
                        "updateend",
                        () => {
                          mediaSource.endOfStream();
                          resolve();
                        },
                        { once: true },
                      );
                    } else {
                      mediaSource.endOfStream();
                      resolve();
                    }
                    return;
                  }
                  // Check if the sourceBuffer is updating before appending the buffer
                  if (!sourceBuffer.updating) {
                    sourceBuffer.appendBuffer(value);
                    read();
                  } else {
                    // If the sourceBuffer is updating, wait for the update to finish before reading the next chunk
                    sourceBuffer.addEventListener("updateend", read, {
                      once: true,
                    });
                  }
                })
                .catch(reject);
            };

            read();
          },
          { once: true },
        );
      });
      audio.play();
      setIsLoading(false);

      return audio;
    } catch (error) {
      console.error("Failed to fetch TTS audio:", error);
    }
  };

  return (
    <div
      key={index}
      className="bg-white border border-gray-300 hover:border hover:border-indigo-300 hover:shadow-xl shadow-sm cursor-pointer duration-300 rounded-lg w-1/5 mx-4 my-2 flex-none"
    >
      <div className="px-4 pt-8">
        <div className="w-full flex">
          <div className="flex flex-col items-start w-full overflow-hidden">
            <h3
              className="font-semibold capitalize line-clamp-1"
              style={{ maxWidth: "70%" }}
            >
              {voiceData?.name}
            </h3>
            <p className="text-lg text-gray-800 mt-2 w-full line-clamp-1">
              {voiceData?.description ?? "No description"}
            </p>

            {userCreated && (
              <div style={{ marginTop: "10px" }}>
                <Button
                  disabled={hasBeenMadePublic}
                  type={"button"}
                  onClick={() => onPublishBtn(voiceData)}
                  appearance={"outline"}
                  style={{
                    padding: "0px 10px",
                    height: 25,
                    borderRadius: "4px",
                    border: hasBeenMadePublic && "1px solid #06d6a0",
                  }}
                >
                  <p style={{ fontSize: 12, padding: 0, margin: 0 }}>
                    {hasBeenMadePublic ? "Public" : "Publish"}
                  </p>
                </Button>
              </div>
            )}
            {/*<p className="text-base text-gray-500 mt-2">Longer description</p>*/}
          </div>
          <div className="flex flex-col justify-start items-end w-full gap-2">
            <div className="flex flex-col space-x-2 items-end">
              <span className="text-lg flex items-center">
                {voiceData.average_rating ? (
                  <>
                    {voiceData.average_rating} Stars
                    <AiFillStar className="ml-1 text-yellow-500" size={14} />
                  </>
                ) : (
                  "No ratings yet."
                )}{" "}
                |{" "}
                <>
                  {voiceData?.total_ratings}{" "}
                  <GoPerson className="ml-1" size={14} />
                </>
              </span>

              <div>
                {[1, 2, 3, 4, 5].map((star) => (
                  <button
                    key={star}
                    onClick={() => handleRatingClick(star)}
                    onMouseEnter={() => setHoverRating(star)}
                    onMouseLeave={() => setHoverRating(0)}
                    className="p-1 rounded-full"
                  >
                    {star <= (hoverRating || userRating) ? (
                      <AiFillStar className="text-yellow-500" />
                    ) : (
                      <AiOutlineStar />
                    )}
                  </button>
                ))}
              </div>
            </div>
            <button
              className="text-base text-indigo-800 border border-indigo-800 rounded-full px-3 py-1 mt-2 hover:bg-indigo-600 hover:text-white transition-all duration-300"
              title={voiceData?.id}
              onClick={() => {
                navigator.clipboard.writeText(voiceData?.id);
                toast.success("voice ID copied to clipboard");
              }}
            >
              Copy ID
            </button>
          </div>
        </div>

        <p className="text-gray-600 font-light mt-2 overflow-x-auto overflow-y-hidden text-nowrap">
          {[...(voiceData?.tags ?? [])].map((currTag, index) => {
            const colorClass =
              index % 2 === 0 ? "bg-purple-100" : "bg-indigo-100";
            const textClass =
              index % 2 === 0 ? "text-purple-800" : "text-blue-800";
            return (
              <span
                key={index}
                className={`text-sm ${textClass} mr-2 rounded-2xl py-2 px-6 ${colorClass}`}
              >
                {currTag}
              </span>
            );
          })}
        </p>
        <hr class="border-t-2 border-gray-200 mt-5 mx-[-1rem]" />
      </div>
      <div className="flex divide-x divide-gray-400">
        <div className="cursor-pointer relative inline-flex w-0 flex-1 flex-col items-center justify-center rounded-br-lg border border-transparent py-2.5 text-sm font-medium text-gray-700 hover:text-gray-500">
          <button
            className="flex items-center text-lg text-black opacity-90 transition-all duration-300 space-x-4 py-2.5 hover:text-indigo-900"
            onClick={async () => await fetchTTSAudio(voiceData.id)}
            disabled={isLoading} // Disable the button while loading
          >
            {isLoading ? (
              <AiOutlineLoading3Quarters
                className="animate-spin text-2xl"
                size={16}
                color={"#3730a3"}
              />
            ) : (
              <>
                <RxSpeakerLoud
                  className="text-2xl"
                  size={16}
                  color={"#3730a3"}
                />
                <span>Play</span>
              </>
            )}
          </button>
          {latency && (
            <div className="absolute bottom-0 mt-10 w-full text-center">
              <p className="text-sm text-black opacity-90 self-end">
                {latency} ms
              </p>
            </div>
          )}
        </div>
        <div className="flex-1 cursor-pointer relative inline-flex w-0 items-center justify-center rounded-br-lg py-2.5 text-sm font-medium hover:text-gray-500">
          <button
            className={`flex items-center text-lg text-black opacity-90 transition-all duration-300 space-x-4  ${
              isPublicTab
                ? "text-gray-700 hover:text-indigo-900"
                : "text-gray-300"
            }`}
            onClick={addToLibrary}
            disabled={!isPublicTab}
          >
            {isPublicTab ? (
              <span>Add to Library</span>
            ) : (
              <span>Added to library</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
