import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import styled from "styled-components";
import { ErrorText } from "components/core/ErrorText";
import Label from "components/core/Label";
import AlertBox from "components/core/AlertBox";

export default function TimeInput({
  fieldName,
  label,
  description,
  controller,
  alertType,
  alertText,
  ...props
}) {
  return (
    <div className="flex flex-col gap-2 w-full">
      {label && <Label>{label}</Label>}
      {description && (
        <p className="text-secondary mb-2" style={{ fontSize: 12 }}>
          {description}
        </p>
      )}
      <StyledInput {...props} />
      {alertType && <AlertBox type={alertType} text={alertText} />}
    </div>
  );
}

const StyledInput = styled.input`
  text-align: left;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  width: 100%;
  padding: 0px 12px;
  background-color: #fbfbfa;
  height: 35px !important;
  cursor: pointer;
  &::placeholder {
    color: #a8a8a1;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: text;
  }
  &:disabled {
    opacity: 45%;
    cursor: not-allowed;
  }
`;
